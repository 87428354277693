body {
    /* background: #006cff; */
    color: white;
  font-size: 1.2rem;
  }
  a {
  color: white;
  }
        
  .content-area {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 5% 20px;
    text-align: center;
    box-sizing: border-box; 
  }
        
  #preloader-background, #circle-loader1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit- flex;
    display: -ms- flex;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
        
  #preloader-background {
    background: white;
    
    height: 90vh;
  }
  
  #circle-loader1 {
    width: 120px;
    height: 120px;
    border-style: solid;
    border-width: 5px;
    border-top-color: #0080ff;
    border-right-color: #0080ff;
    border-bottom-color: #d5eaff;
    border-left-color: #d5eaff;
    border-radius: 50%;
    -webkit-animation: spinLoader 2s linear infinite;
    animation: spinLoader 2s linear infinite;
  }
  
  @-webkit-keyframes spinLoader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  @keyframes spinLoader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  
  #centre-logo {
    position: fixed;
    width: 70px;
    height: 70px;
    padding-bottom: 10px;  
  }
  
  .animatePageIn {
    position: relative;
    -webkit-animation-name: animatePageIn;
    -webkit-animation-duration: 3s;
    animation-name: animatePageIn;
    animation-duration: 3s;
  }
  @-webkit-keyframes animatePageIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  @keyframes animatePageIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  
  #pageContent {
    display: none;
  }
  