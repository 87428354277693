:root {
  --gradient: linear-gradient(to right, rgb(255, 64, 62) 0%, rgb(255, 129, 27) 100%);
  --gradient-sec: linear-gradient(to right, rgb(57, 26, 166) 0%, rgb(6, 163, 245) 100%);
  --primary: #CC0D39;
  --secondary: #000;
  --primary-hover: #f11b4d;
  --primary-dark: #3c0411;
  --rgba-primary-1: rgba(204, 13, 57, 0.1);
  --rgba-primary-2: rgba(204, 13, 57, 0.2);
  --rgba-primary-3: rgba(204, 13, 57, 0.3);
  --rgba-primary-4: rgba(204, 13, 57, 0.4);
  --rgba-primary-5: rgba(204, 13, 57, 0.5);
  --rgba-primary-6: rgba(204, 13, 57, 0.6);
  --rgba-primary-7: rgba(204, 13, 57, 0.7);
  --rgba-primary-8: rgba(204, 13, 57, 0.8);
  --rgba-primary-9: rgba(204, 13, 57, 0.9);
  --font-family-base: DM Sans, sans-serif;
  --grey: #f7f7f7;
  --border-radius-base: 20px;
  --border-color: #D7D7D7;
  --font-family-title: Lufga, sans-serif;
  --title: #000;
  --headings-font-weight: 600;
  --headings-font-weight2: 400;
  --headings-font-weight3: 300;
  --white: var(--title);
  --light: #FFFAF3;
}

/*!
 * Bootstrap  v5.3.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #CC0D39;
  --bs-secondary: #000;
  --bs-success: #31A56D;
  --bs-info: #0194D9;
  --bs-warning: #E79600;
  --bs-danger: #D23636;
  --bs-light: #FFFAF3;
  --bs-dark: #444444;
  --bs-primary-rgb: 204, 13, 57;
  --bs-secondary-rgb: 0, 0, 0;
  --bs-success-rgb: 49, 165, 109;
  --bs-info-rgb: 1, 148, 217;
  --bs-warning-rgb: 231, 150, 0;
  --bs-danger-rgb: 210, 54, 54;
  --bs-light-rgb: 255, 250, 243;
  --bs-dark-rgb: 68, 68, 68;
  --bs-primary-text: #0f0d37;
  --bs-secondary-text: #6c757d;
  --bs-success-text: #429b46;
  --bs-info-text: #087990;
  --bs-warning-text: #996505;
  --bs-danger-text: #c21d4e;
  --bs-light-text: #6c757d;
  --bs-dark-text: #495057;
  --bs-primary-bg-subtle: #d0cfda;
  --bs-secondary-bg-subtle: #f8f9fa;
  --bs-success-bg-subtle: #ddf3de;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #ffeece;
  --bs-danger-bg-subtle: #fcd3e0;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a19fb5;
  --bs-secondary-border-subtle: #e9ecef;
  --bs-success-border-subtle: #bae7bc;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffdc9c;
  --bs-danger-border-subtle: #faa7c0;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 94, 98, 111;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "DM Sans", sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #5E626F;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(94, 98, 111, 0.75);
  --bs-secondary-color-rgb: 94, 98, 111;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(94, 98, 111, 0.5);
  --bs-tertiary-color-rgb: 94, 98, 111;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-heading-color: #000;
  --bs-link-color: #CC0D39;
  --bs-link-color-rgb: 204, 13, 57;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #a30a2e;
  --bs-link-hover-color-rgb: 163, 10, 46;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #ffeece;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #D7D7D7;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.75rem;
  --bs-border-radius-lg: 1rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
  --bs-emphasis-color: #000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #ffeece;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440;
}

[data-bs-theme=dark] {
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #f8f9fa;
  --bs-emphasis-color-rgb: 248, 249, 250;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-emphasis-color: #ffffff;
  --bs-primary-text: #71708f;
  --bs-secondary-text: #dee2e6;
  --bs-success-text: #98da9b;
  --bs-info-text: #6edff6;
  --bs-warning-text: #ffcb6b;
  --bs-danger-text: #f77ca1;
  --bs-light-text: #f8f9fa;
  --bs-dark-text: #dee2e6;
  --bs-primary-bg-subtle: #04030e;
  --bs-secondary-bg-subtle: #212529;
  --bs-success-bg-subtle: #112712;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332202;
  --bs-danger-bg-subtle: #300714;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #0b0a29;
  --bs-secondary-border-subtle: #495057;
  --bs-success-border-subtle: #327435;
  --bs-info-border-subtle: #055160;
  --bs-warning-border-subtle: #664303;
  --bs-danger-border-subtle: #91163b;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: #fff;
  --bs-link-color: #71708f;
  --bs-link-hover-color: #a19fb5;
  --bs-link-color-rgb: 113, 112, 143;
  --bs-link-hover-color-rgb: 161, 159, 181;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color, inherit);
}


.ApplyButton {
  border: 1px solid rgb(230, 230, 230);
  background-color: rgb(255, 215, 105);
  color: black !important;
  width: 440px;
  height: 45px;
  border-radius: 8px;
}


.codeClss {
  border: 1px dotted black;
  width: 130px;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.coup_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  padding: 5px;
}

.font-medium {
  height: 50px;
  border-radius: 5px;
  width: 460px;
  text-align: center !important;
}

.unlocked_coupons {
  border: 5px solid rgb(243, 242, 242);
  background-color: rgb(243, 242, 242);
  padding: 10px;
  height: 170px;
}

.coupondetails {

  padding: 5px;
  margin-left: 25px;
}

.msgCoupon {
  border: 1px solid red;
}


h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 1.75rem;
  }
}

h3,
.h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 1.125rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 300;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.75rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgba(151, 151, 159, 0.39);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: var(--bs-border-width) 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #f5cfd7;
  --bs-table-border-color: #ddbac2;
  --bs-table-striped-bg: #e9c5cc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ddbac2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3bfc7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #cccccc;
  --bs-table-border-color: #b8b8b8;
  --bs-table-striped-bg: #c2c2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8b8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdbdbd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d6ede2;
  --bs-table-border-color: #c1d5cb;
  --bs-table-striped-bg: #cbe1d7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d5cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6dbd1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cceaf7;
  --bs-table-border-color: #b8d3de;
  --bs-table-striped-bg: #c2deeb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d3de;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdd8e4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #faeacc;
  --bs-table-border-color: #e1d3b8;
  --bs-table-striped-bg: #eedec2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1d3b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7d8bd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f6d7d7;
  --bs-table-border-color: #ddc2c2;
  --bs-table-striped-bg: #eacccc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ddc2c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4c7c7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #FFFAF3;
  --bs-table-border-color: #e6e1db;
  --bs-table-striped-bg: #f2eee7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6e1db;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece7e1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #444444;
  --bs-table-border-color: #575757;
  --bs-table-striped-bg: #4d4d4d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #575757;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #525252;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(151, 151, 159, 0.39);
}

.form-control,
.woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account input[type=password],
.login input[type=text],
.login input[type=password],
.woocommerce-ResetPassword.lost_reset_password input[type=text],
.checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel],
.woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond input[type=email] {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .form-control,
  .woocommerce-EditAccountForm.edit-account input[type=text],
  .woocommerce-EditAccountForm.edit-account input[type=email],
  .woocommerce-EditAccountForm.edit-account input[type=password],
  .login input[type=text],
  .login input[type=password],
  .woocommerce-ResetPassword.lost_reset_password input[type=text],
  .checkout.woocommerce-checkout input[type=text],
  .checkout.woocommerce-checkout input[type=email],
  .checkout.woocommerce-checkout input[type=tel],
  .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout textarea,
  .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .select2-choice,
  .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type=text],
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type=email],
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel],
  .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
  .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce #review_form #respond input[type=text],
  .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond input[type=email] {
    transition: none;
  }
}

.form-control[type=file],
.woocommerce-EditAccountForm.edit-account input[type=file][type=text],
.woocommerce-EditAccountForm.edit-account input[type=file][type=email],
.woocommerce-EditAccountForm.edit-account input[type=file][type=password],
.login input[type=file][type=text],
.login input[type=file][type=password],
.woocommerce-ResetPassword.lost_reset_password input[type=file][type=text],
.checkout.woocommerce-checkout input[type=file][type=text],
.checkout.woocommerce-checkout input[type=file][type=email],
.checkout.woocommerce-checkout input[type=file][type=tel],
.checkout.woocommerce-checkout [type=file].select2-choice,
.checkout.woocommerce-checkout textarea[type=file],
.woocommerce form .form-row input[type=file].input-text,
.woocommerce form .form-row [type=file].select2-selection,
.woocommerce form .form-row [type=file].select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea[type=file],
.woocommerce .woocommerce-checkout.checkout form .form-row select[type=file],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=file],
.woocommerce-cart-form .table tr .quantity input[type=file],
.woocommerce-cart-form .table tr td.actions .coupon [type=file].input-text,
.woocommerce #review_form #respond input[type=file][type=text],
.woocommerce #review_form #respond textarea[type=file],
.woocommerce #review_form #respond input[type=file][type=email] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]),
.woocommerce-EditAccountForm.edit-account input[type=file][type=text]:not(:disabled):not([readonly]),
.woocommerce-EditAccountForm.edit-account input[type=file][type=email]:not(:disabled):not([readonly]),
.woocommerce-EditAccountForm.edit-account input[type=file][type=password]:not(:disabled):not([readonly]),
.login input[type=file][type=text]:not(:disabled):not([readonly]),
.login input[type=file][type=password]:not(:disabled):not([readonly]),
.woocommerce-ResetPassword.lost_reset_password input[type=file][type=text]:not(:disabled):not([readonly]),
.checkout.woocommerce-checkout input[type=file][type=text]:not(:disabled):not([readonly]),
.checkout.woocommerce-checkout input[type=file][type=email]:not(:disabled):not([readonly]),
.checkout.woocommerce-checkout input[type=file][type=tel]:not(:disabled):not([readonly]),
.checkout.woocommerce-checkout [type=file].select2-choice:not(:disabled):not([readonly]),
.checkout.woocommerce-checkout textarea[type=file]:not(:disabled):not([readonly]),
.woocommerce form .form-row input[type=file].input-text:not(:disabled):not([readonly]),
.woocommerce form .form-row [type=file].select2-selection:not(:disabled):not([readonly]),
.woocommerce form .form-row [type=file].select2-choice:not(:disabled):not([readonly]),
.woocommerce .woocommerce-checkout.checkout form .form-row select[type=file]:not(:disabled):not([readonly]),
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=file]:not(:disabled):not([readonly]),
.woocommerce-cart-form .table tr .quantity input[type=file]:not(:disabled):not([readonly]),
.woocommerce-cart-form .table tr td.actions .coupon [type=file].input-text:not(:disabled):not([readonly]),
.woocommerce #review_form #respond input[type=file][type=text]:not(:disabled):not([readonly]),
.woocommerce #review_form #respond textarea[type=file]:not(:disabled):not([readonly]),
.woocommerce #review_form #respond input[type=file][type=email]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus,
.woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.login input[type=text]:focus,
.login input[type=password]:focus,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout textarea:focus,
.woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .select2-choice:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond input[type=email]:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #e6869c;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(204, 13, 57, 0.25);
}

.form-control::-webkit-date-and-time-value,
.woocommerce-EditAccountForm.edit-account input[type=text]::-webkit-date-and-time-value,
.woocommerce-EditAccountForm.edit-account input[type=email]::-webkit-date-and-time-value,
.woocommerce-EditAccountForm.edit-account input[type=password]::-webkit-date-and-time-value,
.login input[type=text]::-webkit-date-and-time-value,
.login input[type=password]::-webkit-date-and-time-value,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout input[type=text]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout input[type=email]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout input[type=tel]::-webkit-date-and-time-value,
.checkout.woocommerce-checkout .select2-choice::-webkit-date-and-time-value,
.checkout.woocommerce-checkout textarea::-webkit-date-and-time-value,
.woocommerce form .form-row input.input-text::-webkit-date-and-time-value,
.woocommerce form .form-row .select2-selection::-webkit-date-and-time-value,
.woocommerce form .form-row .select2-choice::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-date-and-time-value,
.woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-date-and-time-value,
.woocommerce-cart-form .table tr .quantity input::-webkit-date-and-time-value,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-date-and-time-value,
.woocommerce #review_form #respond input[type=text]::-webkit-date-and-time-value,
.woocommerce #review_form #respond textarea::-webkit-date-and-time-value,
.woocommerce #review_form #respond input[type=email]::-webkit-date-and-time-value {
  height: 1.6em;
}

.form-control::-webkit-datetime-edit,
.woocommerce-EditAccountForm.edit-account input[type=text]::-webkit-datetime-edit,
.woocommerce-EditAccountForm.edit-account input[type=email]::-webkit-datetime-edit,
.woocommerce-EditAccountForm.edit-account input[type=password]::-webkit-datetime-edit,
.login input[type=text]::-webkit-datetime-edit,
.login input[type=password]::-webkit-datetime-edit,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::-webkit-datetime-edit,
.checkout.woocommerce-checkout input[type=text]::-webkit-datetime-edit,
.checkout.woocommerce-checkout input[type=email]::-webkit-datetime-edit,
.checkout.woocommerce-checkout input[type=tel]::-webkit-datetime-edit,
.checkout.woocommerce-checkout .select2-choice::-webkit-datetime-edit,
.checkout.woocommerce-checkout textarea::-webkit-datetime-edit,
.woocommerce form .form-row input.input-text::-webkit-datetime-edit,
.woocommerce form .form-row .select2-selection::-webkit-datetime-edit,
.woocommerce form .form-row .select2-choice::-webkit-datetime-edit,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-datetime-edit,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::-webkit-datetime-edit,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::-webkit-datetime-edit,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::-webkit-datetime-edit,
.woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-datetime-edit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-datetime-edit,
.woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-datetime-edit,
.woocommerce-cart-form .table tr .quantity input::-webkit-datetime-edit,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-datetime-edit,
.woocommerce #review_form #respond input[type=text]::-webkit-datetime-edit,
.woocommerce #review_form #respond textarea::-webkit-datetime-edit,
.woocommerce #review_form #respond input[type=email]::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.login input[type=text]::placeholder,
.login input[type=password]::placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout textarea::placeholder,
.woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .select2-choice::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond input[type=email]::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled,
.woocommerce-EditAccountForm.edit-account input[type=text]:disabled,
.woocommerce-EditAccountForm.edit-account input[type=email]:disabled,
.woocommerce-EditAccountForm.edit-account input[type=password]:disabled,
.login input[type=text]:disabled,
.login input[type=password]:disabled,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:disabled,
.checkout.woocommerce-checkout input[type=text]:disabled,
.checkout.woocommerce-checkout input[type=email]:disabled,
.checkout.woocommerce-checkout input[type=tel]:disabled,
.checkout.woocommerce-checkout .select2-choice:disabled,
.checkout.woocommerce-checkout textarea:disabled,
.woocommerce form .form-row input.input-text:disabled,
.woocommerce form .form-row .select2-selection:disabled,
.woocommerce form .form-row .select2-choice:disabled,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:disabled,
.woocommerce .woocommerce-checkout.checkout form .form-row select:disabled,
.woocommerce .woocommerce-checkout.checkout form .form-row input:disabled,
.woocommerce-cart-form .table tr .quantity input:disabled,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:disabled,
.woocommerce #review_form #respond input[type=text]:disabled,
.woocommerce #review_form #respond textarea:disabled,
.woocommerce #review_form #respond input[type=email]:disabled {
  background-color: var(--bs-form-control-disabled-bg);
  opacity: 1;
}

.form-control::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type=text]::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type=email]::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type=password]::file-selector-button,
.login input[type=text]::file-selector-button,
.login input[type=password]::file-selector-button,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::file-selector-button,
.checkout.woocommerce-checkout input[type=text]::file-selector-button,
.checkout.woocommerce-checkout input[type=email]::file-selector-button,
.checkout.woocommerce-checkout input[type=tel]::file-selector-button,
.checkout.woocommerce-checkout .select2-choice::file-selector-button,
.checkout.woocommerce-checkout textarea::file-selector-button,
.woocommerce form .form-row input.input-text::file-selector-button,
.woocommerce form .form-row .select2-selection::file-selector-button,
.woocommerce form .form-row .select2-choice::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input::file-selector-button,
.woocommerce-cart-form .table tr .quantity input::file-selector-button,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::file-selector-button,
.woocommerce #review_form #respond input[type=text]::file-selector-button,
.woocommerce #review_form #respond textarea::file-selector-button,
.woocommerce #review_form #respond input[type=email]::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .form-control::file-selector-button,
  .woocommerce-EditAccountForm.edit-account input[type=text]::file-selector-button,
  .woocommerce-EditAccountForm.edit-account input[type=email]::file-selector-button,
  .woocommerce-EditAccountForm.edit-account input[type=password]::file-selector-button,
  .login input[type=text]::file-selector-button,
  .login input[type=password]::file-selector-button,
  .woocommerce-ResetPassword.lost_reset_password input[type=text]::file-selector-button,
  .checkout.woocommerce-checkout input[type=text]::file-selector-button,
  .checkout.woocommerce-checkout input[type=email]::file-selector-button,
  .checkout.woocommerce-checkout input[type=tel]::file-selector-button,
  .checkout.woocommerce-checkout .select2-choice::file-selector-button,
  .checkout.woocommerce-checkout textarea::file-selector-button,
  .woocommerce form .form-row input.input-text::file-selector-button,
  .woocommerce form .form-row .select2-selection::file-selector-button,
  .woocommerce form .form-row .select2-choice::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row textarea::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row select::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::file-selector-button,
  .woocommerce .woocommerce-checkout.checkout form .form-row input::file-selector-button,
  .woocommerce-cart-form .table tr .quantity input::file-selector-button,
  .woocommerce-cart-form .table tr td.actions .coupon .input-text::file-selector-button,
  .woocommerce #review_form #respond input[type=text]::file-selector-button,
  .woocommerce #review_form #respond textarea::file-selector-button,
  .woocommerce #review_form #respond input[type=email]::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-EditAccountForm.edit-account input[type=password]:hover:not(:disabled):not([readonly])::file-selector-button,
.login input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.login input[type=password]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout input[type=tel]:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout .select2-choice:hover:not(:disabled):not([readonly])::file-selector-button,
.checkout.woocommerce-checkout textarea:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce form .form-row input.input-text:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce form .form-row .select2-selection:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce form .form-row .select2-choice:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-cart-form .table tr .quantity input:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce #review_form #respond input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce #review_form #respond textarea:hover:not(:disabled):not([readonly])::file-selector-button,
.woocommerce #review_form #respond input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control,
.checkout.woocommerce-checkout textarea.select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row textarea.select2-selection,
.woocommerce form .form-row textarea.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce-cart-form .table tr td.actions .coupon textarea.input-text,
.woocommerce #review_form #respond textarea {
  min-height: calc(1.6em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.6em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.6em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.6em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select,
select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {

  .form-select,
  select {
    transition: none;
  }
}

.form-select:focus,
select:focus {
  border-color: #e6869c;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(204, 13, 57, 0.25);
}

.form-select[multiple],
select[multiple],
.form-select[size]:not([size="1"]),
select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled,
select:disabled {
  background-color: var(--bs-form-control-disabled-bg);
}

.form-select:-moz-focusring,
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

[data-bs-theme=dark] .form-select,
[data-bs-theme=dark] select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-form-control-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #e6869c;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(204, 13, 57, 0.25);
}

.form-check-input:checked {
  background-color: #CC0D39;
  border-color: #CC0D39;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #CC0D39;
  border-color: #CC0D39;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e6869c'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.woocommerce #respond .btn-check[disabled]+input#submit,
.woocommerce .btn-check[disabled]+a.button,
.woocommerce .btn-check[disabled]+button.button,
.woocommerce .btn-check[disabled]+input.button,
.woocommerce .btn-check[disabled]+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-check[disabled]+a.checkout-button,
.woocommerce-cart-form .table tr .btn-check[disabled]+.button,
.woocommerce #review_form #respond .form-submit .btn-check[disabled]+input,
.woocommerce .widget_shopping_cart .buttons .btn-check[disabled]+a,
.woocommerce.widget_shopping_cart .buttons .btn-check[disabled]+a,
.btn-check[disabled]+.wp-block-button__link,
.btn-check:disabled+.btn,
.woocommerce #respond .btn-check:disabled+input#submit,
.woocommerce .btn-check:disabled+a.button,
.woocommerce .btn-check:disabled+button.button,
.woocommerce .btn-check:disabled+input.button,
.woocommerce .btn-check:disabled+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:disabled+a.checkout-button,
.woocommerce-cart-form .table tr .btn-check:disabled+.button,
.woocommerce #review_form #respond .form-submit .btn-check:disabled+input,
.woocommerce .widget_shopping_cart .buttons .btn-check:disabled+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:disabled+a,
.btn-check:disabled+.wp-block-button__link {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(204, 13, 57, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(204, 13, 57, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #CC0D39;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #f0b6c4;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #CC0D39;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #f0b6c4;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating::before:not(.form-control:disabled) {
  position: absolute;
  top: var(--bs-border-width);
  left: var(--bs-border-width);
  width: calc(100% - (calc(calc(0.4em + 0.1875rem) + calc(0.8em + 0.375rem))));
  height: 1.875em;
  content: "";
  background-color: var(--bs-form-control-bg);
  border-radius: 0.375rem;
}

.form-floating>.form-control,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password],
.login .form-floating>input[type=text],
.login .form-floating>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text],
.checkout.woocommerce-checkout .form-floating>input[type=text],
.checkout.woocommerce-checkout .form-floating>input[type=email],
.checkout.woocommerce-checkout .form-floating>input[type=tel],
.checkout.woocommerce-checkout .form-floating>.select2-choice,
.checkout.woocommerce-checkout .form-floating>textarea,
.woocommerce form .form-row .form-floating>input.input-text,
.woocommerce form .form-row .form-floating>.select2-selection,
.woocommerce form .form-row .form-floating>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input,
.woocommerce-cart-form .table tr .quantity .form-floating>input,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text,
.woocommerce #review_form #respond .form-floating>input[type=text],
.woocommerce #review_form #respond .form-floating>textarea,
.woocommerce #review_form #respond .form-floating>input[type=email],
.form-floating>.form-control-plaintext,
.form-floating>.form-select,
.form-floating>select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email],
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password],
.login .form-floating>input[type=text],
.login .form-floating>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text],
.checkout.woocommerce-checkout .form-floating>input[type=text],
.checkout.woocommerce-checkout .form-floating>input[type=email],
.checkout.woocommerce-checkout .form-floating>input[type=tel],
.checkout.woocommerce-checkout .form-floating>.select2-choice,
.checkout.woocommerce-checkout .form-floating>textarea,
.woocommerce form .form-row .form-floating>input.input-text,
.woocommerce form .form-row .form-floating>.select2-selection,
.woocommerce form .form-row .form-floating>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input,
.woocommerce-cart-form .table tr .quantity .form-floating>input,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text,
.woocommerce #review_form #respond .form-floating>input[type=text],
.woocommerce #review_form #respond .form-floating>textarea,
.woocommerce #review_form #respond .form-floating>input[type=email],
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]::placeholder,
.login .form-floating>input[type=text]::placeholder,
.login .form-floating>input[type=password]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]::placeholder,
.checkout.woocommerce-checkout .form-floating>input[type=text]::placeholder,
.checkout.woocommerce-checkout .form-floating>input[type=email]::placeholder,
.checkout.woocommerce-checkout .form-floating>input[type=tel]::placeholder,
.checkout.woocommerce-checkout .form-floating>.select2-choice::placeholder,
.checkout.woocommerce-checkout .form-floating>textarea::placeholder,
.woocommerce form .form-row .form-floating>input.input-text::placeholder,
.woocommerce form .form-row .form-floating>.select2-selection::placeholder,
.woocommerce form .form-row .form-floating>.select2-choice::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input::placeholder,
.woocommerce-cart-form .table tr .quantity .form-floating>input::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text::placeholder,
.woocommerce #review_form #respond .form-floating>input[type=text]::placeholder,
.woocommerce #review_form #respond .form-floating>textarea::placeholder,
.woocommerce #review_form #respond .form-floating>input[type=email]::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]:focus,
.login .form-floating>input[type=text]:focus,
.login .form-floating>input[type=password]:focus,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]:focus,
.checkout.woocommerce-checkout .form-floating>input[type=text]:focus,
.checkout.woocommerce-checkout .form-floating>input[type=email]:focus,
.checkout.woocommerce-checkout .form-floating>input[type=tel]:focus,
.checkout.woocommerce-checkout .form-floating>.select2-choice:focus,
.checkout.woocommerce-checkout .form-floating>textarea:focus,
.woocommerce form .form-row .form-floating>input.input-text:focus,
.woocommerce form .form-row .form-floating>.select2-selection:focus,
.woocommerce form .form-row .form-floating>.select2-choice:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:focus,
.woocommerce-cart-form .table tr .quantity .form-floating>input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:focus,
.woocommerce #review_form #respond .form-floating>input[type=text]:focus,
.woocommerce #review_form #respond .form-floating>textarea:focus,
.woocommerce #review_form #respond .form-floating>input[type=email]:focus,
.form-floating>.form-control:not(:placeholder-shown),
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]:not(:placeholder-shown),
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]:not(:placeholder-shown),
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]:not(:placeholder-shown),
.login .form-floating>input[type=text]:not(:placeholder-shown),
.login .form-floating>input[type=password]:not(:placeholder-shown),
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]:not(:placeholder-shown),
.checkout.woocommerce-checkout .form-floating>input[type=text]:not(:placeholder-shown),
.checkout.woocommerce-checkout .form-floating>input[type=email]:not(:placeholder-shown),
.checkout.woocommerce-checkout .form-floating>input[type=tel]:not(:placeholder-shown),
.checkout.woocommerce-checkout .form-floating>.select2-choice:not(:placeholder-shown),
.checkout.woocommerce-checkout .form-floating>textarea:not(:placeholder-shown),
.woocommerce form .form-row .form-floating>input.input-text:not(:placeholder-shown),
.woocommerce form .form-row .form-floating>.select2-selection:not(:placeholder-shown),
.woocommerce form .form-row .form-floating>.select2-choice:not(:placeholder-shown),
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:not(:placeholder-shown),
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:not(:placeholder-shown),
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:not(:placeholder-shown),
.woocommerce-cart-form .table tr .quantity .form-floating>input:not(:placeholder-shown),
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:not(:placeholder-shown),
.woocommerce #review_form #respond .form-floating>input[type=text]:not(:placeholder-shown),
.woocommerce #review_form #respond .form-floating>textarea:not(:placeholder-shown),
.woocommerce #review_form #respond .form-floating>input[type=email]:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]:-webkit-autofill,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]:-webkit-autofill,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]:-webkit-autofill,
.login .form-floating>input[type=text]:-webkit-autofill,
.login .form-floating>input[type=password]:-webkit-autofill,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]:-webkit-autofill,
.checkout.woocommerce-checkout .form-floating>input[type=text]:-webkit-autofill,
.checkout.woocommerce-checkout .form-floating>input[type=email]:-webkit-autofill,
.checkout.woocommerce-checkout .form-floating>input[type=tel]:-webkit-autofill,
.checkout.woocommerce-checkout .form-floating>.select2-choice:-webkit-autofill,
.checkout.woocommerce-checkout .form-floating>textarea:-webkit-autofill,
.woocommerce form .form-row .form-floating>input.input-text:-webkit-autofill,
.woocommerce form .form-row .form-floating>.select2-selection:-webkit-autofill,
.woocommerce form .form-row .form-floating>.select2-choice:-webkit-autofill,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>textarea:-webkit-autofill,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:-webkit-autofill,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:-webkit-autofill,
.woocommerce-cart-form .table tr .quantity .form-floating>input:-webkit-autofill,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:-webkit-autofill,
.woocommerce #review_form #respond .form-floating>input[type=text]:-webkit-autofill,
.woocommerce #review_form #respond .form-floating>textarea:-webkit-autofill,
.woocommerce #review_form #respond .form-floating>input[type=email]:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select,
.form-floating>select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]:focus~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]:focus~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]:focus~label,
.login .form-floating>input[type=text]:focus~label,
.login .form-floating>input[type=password]:focus~label,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]:focus~label,
.checkout.woocommerce-checkout .form-floating>input[type=text]:focus~label,
.checkout.woocommerce-checkout .form-floating>input[type=email]:focus~label,
.checkout.woocommerce-checkout .form-floating>input[type=tel]:focus~label,
.checkout.woocommerce-checkout .form-floating>.select2-choice:focus~label,
.checkout.woocommerce-checkout .form-floating>textarea:focus~label,
.woocommerce form .form-row .form-floating>input.input-text:focus~label,
.woocommerce form .form-row .form-floating>.select2-selection:focus~label,
.woocommerce form .form-row .form-floating>.select2-choice:focus~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:focus~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:focus~label,
.woocommerce-cart-form .table tr .quantity .form-floating>input:focus~label,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:focus~label,
.woocommerce #review_form #respond .form-floating>input[type=text]:focus~label,
.woocommerce #review_form #respond .form-floating>textarea:focus~label,
.woocommerce #review_form #respond .form-floating>input[type=email]:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]:not(:placeholder-shown)~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]:not(:placeholder-shown)~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]:not(:placeholder-shown)~label,
.login .form-floating>input[type=text]:not(:placeholder-shown)~label,
.login .form-floating>input[type=password]:not(:placeholder-shown)~label,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]:not(:placeholder-shown)~label,
.checkout.woocommerce-checkout .form-floating>input[type=text]:not(:placeholder-shown)~label,
.checkout.woocommerce-checkout .form-floating>input[type=email]:not(:placeholder-shown)~label,
.checkout.woocommerce-checkout .form-floating>input[type=tel]:not(:placeholder-shown)~label,
.checkout.woocommerce-checkout .form-floating>.select2-choice:not(:placeholder-shown)~label,
.checkout.woocommerce-checkout .form-floating>textarea:not(:placeholder-shown)~label,
.woocommerce form .form-row .form-floating>input.input-text:not(:placeholder-shown)~label,
.woocommerce form .form-row .form-floating>.select2-selection:not(:placeholder-shown)~label,
.woocommerce form .form-row .form-floating>.select2-choice:not(:placeholder-shown)~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:not(:placeholder-shown)~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:not(:placeholder-shown)~label,
.woocommerce-cart-form .table tr .quantity .form-floating>input:not(:placeholder-shown)~label,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:not(:placeholder-shown)~label,
.woocommerce #review_form #respond .form-floating>input[type=text]:not(:placeholder-shown)~label,
.woocommerce #review_form #respond .form-floating>textarea:not(:placeholder-shown)~label,
.woocommerce #review_form #respond .form-floating>input[type=email]:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label,
.form-floating>select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]:-webkit-autofill~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]:-webkit-autofill~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]:-webkit-autofill~label,
.login .form-floating>input[type=text]:-webkit-autofill~label,
.login .form-floating>input[type=password]:-webkit-autofill~label,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]:-webkit-autofill~label,
.checkout.woocommerce-checkout .form-floating>input[type=text]:-webkit-autofill~label,
.checkout.woocommerce-checkout .form-floating>input[type=email]:-webkit-autofill~label,
.checkout.woocommerce-checkout .form-floating>input[type=tel]:-webkit-autofill~label,
.checkout.woocommerce-checkout .form-floating>.select2-choice:-webkit-autofill~label,
.checkout.woocommerce-checkout .form-floating>textarea:-webkit-autofill~label,
.woocommerce form .form-row .form-floating>input.input-text:-webkit-autofill~label,
.woocommerce form .form-row .form-floating>.select2-selection:-webkit-autofill~label,
.woocommerce form .form-row .form-floating>.select2-choice:-webkit-autofill~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:-webkit-autofill~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:-webkit-autofill~label,
.woocommerce-cart-form .table tr .quantity .form-floating>input:-webkit-autofill~label,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:-webkit-autofill~label,
.woocommerce #review_form #respond .form-floating>input[type=text]:-webkit-autofill~label,
.woocommerce #review_form #respond .form-floating>textarea:-webkit-autofill~label,
.woocommerce #review_form #respond .form-floating>input[type=email]:-webkit-autofill~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating>.form-control:disabled~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=text]:disabled~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=email]:disabled~label,
.woocommerce-EditAccountForm.edit-account .form-floating>input[type=password]:disabled~label,
.login .form-floating>input[type=text]:disabled~label,
.login .form-floating>input[type=password]:disabled~label,
.woocommerce-ResetPassword.lost_reset_password .form-floating>input[type=text]:disabled~label,
.checkout.woocommerce-checkout .form-floating>input[type=text]:disabled~label,
.checkout.woocommerce-checkout .form-floating>input[type=email]:disabled~label,
.checkout.woocommerce-checkout .form-floating>input[type=tel]:disabled~label,
.checkout.woocommerce-checkout .form-floating>.select2-choice:disabled~label,
.checkout.woocommerce-checkout .form-floating>textarea:disabled~label,
.woocommerce form .form-row .form-floating>input.input-text:disabled~label,
.woocommerce form .form-row .form-floating>.select2-selection:disabled~label,
.woocommerce form .form-row .form-floating>.select2-choice:disabled~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>select:disabled~label,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-floating>input:disabled~label,
.woocommerce-cart-form .table tr .quantity .form-floating>input:disabled~label,
.woocommerce-cart-form .table tr td.actions .coupon .form-floating>.input-text:disabled~label,
.woocommerce #review_form #respond .form-floating>input[type=text]:disabled~label,
.woocommerce #review_form #respond .form-floating>textarea:disabled~label,
.woocommerce #review_form #respond .form-floating>input[type=email]:disabled~label {
  color: #6c757d;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=text],
.woocommerce-EditAccountForm.edit-account .input-group>input[type=email],
.woocommerce-EditAccountForm.edit-account .input-group>input[type=password],
.login .input-group>input[type=text],
.login .input-group>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .input-group>input[type=text],
.checkout.woocommerce-checkout .input-group>input[type=text],
.checkout.woocommerce-checkout .input-group>input[type=email],
.checkout.woocommerce-checkout .input-group>input[type=tel],
.checkout.woocommerce-checkout .input-group>.select2-choice,
.checkout.woocommerce-checkout .input-group>textarea,
.woocommerce form .form-row .input-group>input.input-text,
.woocommerce form .form-row .input-group>.select2-selection,
.woocommerce form .form-row .input-group>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input,
.woocommerce-cart-form .table tr .quantity .input-group>input,
.woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text,
.woocommerce #review_form #respond .input-group>input[type=text],
.woocommerce #review_form #respond .input-group>textarea,
.woocommerce #review_form #respond .input-group>input[type=email],
.input-group>.form-select,
.input-group>select,
.input-group>.form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=password]:focus,
.login .input-group>input[type=text]:focus,
.login .input-group>input[type=password]:focus,
.woocommerce-ResetPassword.lost_reset_password .input-group>input[type=text]:focus,
.checkout.woocommerce-checkout .input-group>input[type=text]:focus,
.checkout.woocommerce-checkout .input-group>input[type=email]:focus,
.checkout.woocommerce-checkout .input-group>input[type=tel]:focus,
.checkout.woocommerce-checkout .input-group>.select2-choice:focus,
.checkout.woocommerce-checkout .input-group>textarea:focus,
.woocommerce form .form-row .input-group>input.input-text:focus,
.woocommerce form .form-row .input-group>.select2-selection:focus,
.woocommerce form .form-row .input-group>.select2-choice:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input:focus,
.woocommerce-cart-form .table tr .quantity .input-group>input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text:focus,
.woocommerce #review_form #respond .input-group>input[type=text]:focus,
.woocommerce #review_form #respond .input-group>textarea:focus,
.woocommerce #review_form #respond .input-group>input[type=email]:focus,
.input-group>.form-select:focus,
.input-group>select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.input-group .btn,
.input-group .woocommerce #respond input#submit,
.woocommerce #respond .input-group input#submit,
.input-group .woocommerce a.button,
.woocommerce .input-group a.button,
.input-group .woocommerce button.button,
.woocommerce .input-group button.button,
.input-group .woocommerce input.button,
.woocommerce .input-group input.button,
.input-group .woocommerce #place_order,
.woocommerce .input-group #place_order,
.input-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .input-group a.checkout-button,
.input-group .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .input-group .button,
.input-group .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .input-group input,
.input-group .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .input-group a,
.input-group .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .input-group a,
.input-group .wp-block-button__link {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus,
.input-group .woocommerce #respond input#submit:focus,
.woocommerce #respond .input-group input#submit:focus,
.input-group .woocommerce a.button:focus,
.woocommerce .input-group a.button:focus,
.input-group .woocommerce button.button:focus,
.woocommerce .input-group button.button:focus,
.input-group .woocommerce input.button:focus,
.woocommerce .input-group input.button:focus,
.input-group .woocommerce #place_order:focus,
.woocommerce .input-group #place_order:focus,
.input-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .input-group a.checkout-button:focus,
.input-group .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .input-group .button:focus,
.input-group .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .input-group input:focus,
.input-group .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .input-group a:focus,
.input-group .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .input-group a:focus,
.input-group .wp-block-button__link:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
}

.input-group-lg>.form-control,
.woocommerce-EditAccountForm.edit-account .input-group-lg>input[type=text],
.woocommerce-EditAccountForm.edit-account .input-group-lg>input[type=email],
.woocommerce-EditAccountForm.edit-account .input-group-lg>input[type=password],
.login .input-group-lg>input[type=text],
.login .input-group-lg>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .input-group-lg>input[type=text],
.checkout.woocommerce-checkout .input-group-lg>input[type=text],
.checkout.woocommerce-checkout .input-group-lg>input[type=email],
.checkout.woocommerce-checkout .input-group-lg>input[type=tel],
.checkout.woocommerce-checkout .input-group-lg>.select2-choice,
.checkout.woocommerce-checkout .input-group-lg>textarea,
.woocommerce form .form-row .input-group-lg>input.input-text,
.woocommerce form .form-row .input-group-lg>.select2-selection,
.woocommerce form .form-row .input-group-lg>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-lg>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-lg>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-lg>input,
.woocommerce-cart-form .table tr .quantity .input-group-lg>input,
.woocommerce-cart-form .table tr td.actions .coupon .input-group-lg>.input-text,
.woocommerce #review_form #respond .input-group-lg>input[type=text],
.woocommerce #review_form #respond .input-group-lg>textarea,
.woocommerce #review_form #respond .input-group-lg>input[type=email],
.input-group-lg>.form-select,
.input-group-lg>select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn,
.woocommerce #respond .input-group-lg>input#submit,
.woocommerce .input-group-lg>a.button,
.woocommerce .input-group-lg>button.button,
.woocommerce .input-group-lg>input.button,
.woocommerce .input-group-lg>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .input-group-lg>a.checkout-button,
.woocommerce-cart-form .table tr .input-group-lg>.button,
.woocommerce #review_form #respond .form-submit .input-group-lg>input,
.woocommerce .widget_shopping_cart .buttons .input-group-lg>a,
.woocommerce.widget_shopping_cart .buttons .input-group-lg>a,
.input-group-lg>.wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.input-group-sm>.form-control,
.woocommerce-EditAccountForm.edit-account .input-group-sm>input[type=text],
.woocommerce-EditAccountForm.edit-account .input-group-sm>input[type=email],
.woocommerce-EditAccountForm.edit-account .input-group-sm>input[type=password],
.login .input-group-sm>input[type=text],
.login .input-group-sm>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .input-group-sm>input[type=text],
.checkout.woocommerce-checkout .input-group-sm>input[type=text],
.checkout.woocommerce-checkout .input-group-sm>input[type=email],
.checkout.woocommerce-checkout .input-group-sm>input[type=tel],
.checkout.woocommerce-checkout .input-group-sm>.select2-choice,
.checkout.woocommerce-checkout .input-group-sm>textarea,
.woocommerce form .form-row .input-group-sm>input.input-text,
.woocommerce form .form-row .input-group-sm>.select2-selection,
.woocommerce form .form-row .input-group-sm>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-sm>textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-sm>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group-sm>input,
.woocommerce-cart-form .table tr .quantity .input-group-sm>input,
.woocommerce-cart-form .table tr td.actions .coupon .input-group-sm>.input-text,
.woocommerce #review_form #respond .input-group-sm>input[type=text],
.woocommerce #review_form #respond .input-group-sm>textarea,
.woocommerce #review_form #respond .input-group-sm>input[type=email],
.input-group-sm>.form-select,
.input-group-sm>select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn,
.woocommerce #respond .input-group-sm>input#submit,
.woocommerce .input-group-sm>a.button,
.woocommerce .input-group-sm>button.button,
.woocommerce .input-group-sm>input.button,
.woocommerce .input-group-sm>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .input-group-sm>a.checkout-button,
.woocommerce-cart-form .table tr .input-group-sm>.button,
.woocommerce #review_form #respond .form-submit .input-group-sm>input,
.woocommerce .widget_shopping_cart .buttons .input-group-sm>a,
.woocommerce.widget_shopping_cart .buttons .input-group-sm>a,
.input-group-sm>.wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.input-group-lg>.form-select,
.input-group-lg>select,
.input-group-sm>.form-select,
.input-group-sm>select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.woocommerce-EditAccountForm.edit-account .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=text],
.woocommerce-EditAccountForm.edit-account .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=email],
.woocommerce-EditAccountForm.edit-account .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=password],
.login .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=text],
.login .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=text],
.checkout.woocommerce-checkout .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=text],
.checkout.woocommerce-checkout .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=email],
.checkout.woocommerce-checkout .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=tel],
.checkout.woocommerce-checkout .input-group:not(.has-validation)>.form-floating:not(:last-child)>.select2-choice,
.checkout.woocommerce-checkout .input-group:not(.has-validation)>.form-floating:not(:last-child)>textarea,
.woocommerce form .form-row .input-group:not(.has-validation)>.form-floating:not(:last-child)>input.input-text,
.woocommerce form .form-row .input-group:not(.has-validation)>.form-floating:not(:last-child)>.select2-selection,
.woocommerce form .form-row .input-group:not(.has-validation)>.form-floating:not(:last-child)>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group:not(.has-validation)>.form-floating:not(:last-child)>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group:not(.has-validation)>.form-floating:not(:last-child)>input,
.woocommerce-cart-form .table tr .quantity .input-group:not(.has-validation)>.form-floating:not(:last-child)>input,
.woocommerce-cart-form .table tr td.actions .coupon .input-group:not(.has-validation)>.form-floating:not(:last-child)>.input-text,
.woocommerce #review_form #respond .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=text],
.woocommerce #review_form #respond .input-group:not(.has-validation)>.form-floating:not(:last-child)>textarea,
.woocommerce #review_form #respond .input-group:not(.has-validation)>.form-floating:not(:last-child)>input[type=email],
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.woocommerce-EditAccountForm.edit-account .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=text],
.woocommerce-EditAccountForm.edit-account .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=email],
.woocommerce-EditAccountForm.edit-account .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=password],
.login .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=text],
.login .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=text],
.checkout.woocommerce-checkout .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=text],
.checkout.woocommerce-checkout .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=email],
.checkout.woocommerce-checkout .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=tel],
.checkout.woocommerce-checkout .input-group.has-validation>.form-floating:nth-last-child(n+3)>.select2-choice,
.checkout.woocommerce-checkout .input-group.has-validation>.form-floating:nth-last-child(n+3)>textarea,
.woocommerce form .form-row .input-group.has-validation>.form-floating:nth-last-child(n+3)>input.input-text,
.woocommerce form .form-row .input-group.has-validation>.form-floating:nth-last-child(n+3)>.select2-selection,
.woocommerce form .form-row .input-group.has-validation>.form-floating:nth-last-child(n+3)>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group.has-validation>.form-floating:nth-last-child(n+3)>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group.has-validation>.form-floating:nth-last-child(n+3)>input,
.woocommerce-cart-form .table tr .quantity .input-group.has-validation>.form-floating:nth-last-child(n+3)>input,
.woocommerce-cart-form .table tr td.actions .coupon .input-group.has-validation>.form-floating:nth-last-child(n+3)>.input-text,
.woocommerce #review_form #respond .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=text],
.woocommerce #review_form #respond .input-group.has-validation>.form-floating:nth-last-child(n+3)>textarea,
.woocommerce #review_form #respond .input-group.has-validation>.form-floating:nth-last-child(n+3)>input[type=email],
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.form-floating:not(:first-child)>.form-control,
.woocommerce-EditAccountForm.edit-account .input-group>.form-floating:not(:first-child)>input[type=text],
.woocommerce-EditAccountForm.edit-account .input-group>.form-floating:not(:first-child)>input[type=email],
.woocommerce-EditAccountForm.edit-account .input-group>.form-floating:not(:first-child)>input[type=password],
.login .input-group>.form-floating:not(:first-child)>input[type=text],
.login .input-group>.form-floating:not(:first-child)>input[type=password],
.woocommerce-ResetPassword.lost_reset_password .input-group>.form-floating:not(:first-child)>input[type=text],
.checkout.woocommerce-checkout .input-group>.form-floating:not(:first-child)>input[type=text],
.checkout.woocommerce-checkout .input-group>.form-floating:not(:first-child)>input[type=email],
.checkout.woocommerce-checkout .input-group>.form-floating:not(:first-child)>input[type=tel],
.checkout.woocommerce-checkout .input-group>.form-floating:not(:first-child)>.select2-choice,
.checkout.woocommerce-checkout .input-group>.form-floating:not(:first-child)>textarea,
.woocommerce form .form-row .input-group>.form-floating:not(:first-child)>input.input-text,
.woocommerce form .form-row .input-group>.form-floating:not(:first-child)>.select2-selection,
.woocommerce form .form-row .input-group>.form-floating:not(:first-child)>.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>.form-floating:not(:first-child)>select,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>.form-floating:not(:first-child)>input,
.woocommerce-cart-form .table tr .quantity .input-group>.form-floating:not(:first-child)>input,
.woocommerce-cart-form .table tr td.actions .coupon .input-group>.form-floating:not(:first-child)>.input-text,
.woocommerce #review_form #respond .input-group>.form-floating:not(:first-child)>input[type=text],
.woocommerce #review_form #respond .input-group>.form-floating:not(:first-child)>textarea,
.woocommerce #review_form #respond .input-group>.form-floating:not(:first-child)>input[type=email],
.input-group>.form-floating:not(:first-child)>.form-select,
.input-group>.form-floating:not(:first-child)>select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-success-text);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=text]:valid,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=text]:valid,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=email]:valid,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=email]:valid,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=password]:valid,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=password]:valid,
.was-validated .login input[type=text]:valid,
.login .was-validated input[type=text]:valid,
.was-validated .login input[type=password]:valid,
.login .was-validated input[type=password]:valid,
.was-validated .woocommerce-ResetPassword.lost_reset_password input[type=text]:valid,
.woocommerce-ResetPassword.lost_reset_password .was-validated input[type=text]:valid,
.was-validated .checkout.woocommerce-checkout input[type=text]:valid,
.checkout.woocommerce-checkout .was-validated input[type=text]:valid,
.was-validated .checkout.woocommerce-checkout input[type=email]:valid,
.checkout.woocommerce-checkout .was-validated input[type=email]:valid,
.was-validated .checkout.woocommerce-checkout input[type=tel]:valid,
.checkout.woocommerce-checkout .was-validated input[type=tel]:valid,
.was-validated .checkout.woocommerce-checkout .select2-choice:valid,
.checkout.woocommerce-checkout .was-validated .select2-choice:valid,
.was-validated .checkout.woocommerce-checkout textarea:valid,
.checkout.woocommerce-checkout .was-validated textarea:valid,
.was-validated .woocommerce form .form-row input.input-text:valid,
.woocommerce form .form-row .was-validated input.input-text:valid,
.was-validated .woocommerce form .form-row .select2-selection:valid,
.woocommerce form .form-row .was-validated .select2-selection:valid,
.was-validated .woocommerce form .form-row .select2-choice:valid,
.woocommerce form .form-row .was-validated .select2-choice:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:valid,
.was-validated .woocommerce-cart-form .table tr .quantity input:valid,
.woocommerce-cart-form .table tr .quantity .was-validated input:valid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:valid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:valid,
.was-validated .woocommerce #review_form #respond input[type=text]:valid,
.woocommerce #review_form #respond .was-validated input[type=text]:valid,
.was-validated .woocommerce #review_form #respond textarea:valid,
.woocommerce #review_form #respond .was-validated textarea:valid,
.was-validated .woocommerce #review_form #respond input[type=email]:valid,
.woocommerce #review_form #respond .was-validated input[type=email]:valid,
.form-control.is-valid,
.woocommerce-EditAccountForm.edit-account input.is-valid[type=text],
.woocommerce-EditAccountForm.edit-account input.is-valid[type=email],
.woocommerce-EditAccountForm.edit-account input.is-valid[type=password],
.login input.is-valid[type=text],
.login input.is-valid[type=password],
.woocommerce-ResetPassword.lost_reset_password input.is-valid[type=text],
.checkout.woocommerce-checkout input.is-valid[type=text],
.checkout.woocommerce-checkout input.is-valid[type=email],
.checkout.woocommerce-checkout input.is-valid[type=tel],
.checkout.woocommerce-checkout .is-valid.select2-choice,
.checkout.woocommerce-checkout textarea.is-valid,
.woocommerce form .form-row input.is-valid.input-text,
.woocommerce form .form-row .is-valid.select2-selection,
.woocommerce form .form-row .is-valid.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-valid,
.woocommerce-cart-form .table tr .quantity input.is-valid,
.woocommerce-cart-form .table tr td.actions .coupon .is-valid.input-text,
.woocommerce #review_form #respond input.is-valid[type=text],
.woocommerce #review_form #respond textarea.is-valid,
.woocommerce #review_form #respond input.is-valid[type=email] {
  border-color: var(--bs-success);
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2331A56D' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=text]:valid:focus,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=text]:valid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=email]:valid:focus,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=email]:valid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=password]:valid:focus,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=password]:valid:focus,
.was-validated .login input[type=text]:valid:focus,
.login .was-validated input[type=text]:valid:focus,
.was-validated .login input[type=password]:valid:focus,
.login .was-validated input[type=password]:valid:focus,
.was-validated .woocommerce-ResetPassword.lost_reset_password input[type=text]:valid:focus,
.woocommerce-ResetPassword.lost_reset_password .was-validated input[type=text]:valid:focus,
.was-validated .checkout.woocommerce-checkout input[type=text]:valid:focus,
.checkout.woocommerce-checkout .was-validated input[type=text]:valid:focus,
.was-validated .checkout.woocommerce-checkout input[type=email]:valid:focus,
.checkout.woocommerce-checkout .was-validated input[type=email]:valid:focus,
.was-validated .checkout.woocommerce-checkout input[type=tel]:valid:focus,
.checkout.woocommerce-checkout .was-validated input[type=tel]:valid:focus,
.was-validated .checkout.woocommerce-checkout .select2-choice:valid:focus,
.checkout.woocommerce-checkout .was-validated .select2-choice:valid:focus,
.was-validated .checkout.woocommerce-checkout textarea:valid:focus,
.checkout.woocommerce-checkout .was-validated textarea:valid:focus,
.was-validated .woocommerce form .form-row input.input-text:valid:focus,
.woocommerce form .form-row .was-validated input.input-text:valid:focus,
.was-validated .woocommerce form .form-row .select2-selection:valid:focus,
.woocommerce form .form-row .was-validated .select2-selection:valid:focus,
.was-validated .woocommerce form .form-row .select2-choice:valid:focus,
.woocommerce form .form-row .was-validated .select2-choice:valid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:valid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:valid:focus,
.was-validated .woocommerce-cart-form .table tr .quantity input:valid:focus,
.woocommerce-cart-form .table tr .quantity .was-validated input:valid:focus,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:valid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:valid:focus,
.was-validated .woocommerce #review_form #respond input[type=text]:valid:focus,
.woocommerce #review_form #respond .was-validated input[type=text]:valid:focus,
.was-validated .woocommerce #review_form #respond textarea:valid:focus,
.woocommerce #review_form #respond .was-validated textarea:valid:focus,
.was-validated .woocommerce #review_form #respond input[type=email]:valid:focus,
.woocommerce #review_form #respond .was-validated input[type=email]:valid:focus,
.form-control.is-valid:focus,
.woocommerce-EditAccountForm.edit-account input.is-valid[type=text]:focus,
.woocommerce-EditAccountForm.edit-account input.is-valid[type=email]:focus,
.woocommerce-EditAccountForm.edit-account input.is-valid[type=password]:focus,
.login input.is-valid[type=text]:focus,
.login input.is-valid[type=password]:focus,
.woocommerce-ResetPassword.lost_reset_password input.is-valid[type=text]:focus,
.checkout.woocommerce-checkout input.is-valid[type=text]:focus,
.checkout.woocommerce-checkout input.is-valid[type=email]:focus,
.checkout.woocommerce-checkout input.is-valid[type=tel]:focus,
.checkout.woocommerce-checkout .is-valid.select2-choice:focus,
.checkout.woocommerce-checkout textarea.is-valid:focus,
.woocommerce form .form-row input.is-valid.input-text:focus,
.woocommerce form .form-row .is-valid.select2-selection:focus,
.woocommerce form .form-row .is-valid.select2-choice:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-valid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-valid:focus,
.woocommerce-cart-form .table tr .quantity input.is-valid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .is-valid.input-text:focus,
.woocommerce #review_form #respond input.is-valid[type=text]:focus,
.woocommerce #review_form #respond textarea.is-valid:focus,
.woocommerce #review_form #respond input.is-valid[type=email]:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .checkout.woocommerce-checkout textarea:valid,
.checkout.woocommerce-checkout .was-validated textarea:valid,
.was-validated .woocommerce form .form-row textarea.select2-selection:valid,
.woocommerce form .form-row .was-validated textarea.select2-selection:valid,
.was-validated .woocommerce form .form-row textarea.select2-choice:valid,
.woocommerce form .form-row .was-validated textarea.select2-choice:valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:valid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon textarea.input-text:valid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated textarea.input-text:valid,
.was-validated .woocommerce #review_form #respond textarea:valid,
.woocommerce #review_form #respond .was-validated textarea:valid,
textarea.form-control.is-valid,
.checkout.woocommerce-checkout textarea.is-valid,
.woocommerce form .form-row textarea.is-valid.select2-selection,
.woocommerce form .form-row textarea.is-valid.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-valid,
.woocommerce-cart-form .table tr td.actions .coupon textarea.is-valid.input-text,
.woocommerce #review_form #respond textarea.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid,
.was-validated select:valid,
.form-select.is-valid,
select.is-valid {
  border-color: var(--bs-success);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.was-validated select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2331A56D' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.was-validated select:valid:focus,
.form-select.is-valid:focus,
select.is-valid:focus {
  border-color: var(--bs-success);
  box-shadow: 0 0 0 0rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.6em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-success);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-success-text);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: var(--bs-success-text);
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group>.form-control:not(:focus):valid,
.was-validated .woocommerce-EditAccountForm.edit-account .input-group>input[type=text]:not(:focus):valid,
.woocommerce-EditAccountForm.edit-account .was-validated .input-group>input[type=text]:not(:focus):valid,
.was-validated .woocommerce-EditAccountForm.edit-account .input-group>input[type=email]:not(:focus):valid,
.woocommerce-EditAccountForm.edit-account .was-validated .input-group>input[type=email]:not(:focus):valid,
.was-validated .woocommerce-EditAccountForm.edit-account .input-group>input[type=password]:not(:focus):valid,
.woocommerce-EditAccountForm.edit-account .was-validated .input-group>input[type=password]:not(:focus):valid,
.was-validated .login .input-group>input[type=text]:not(:focus):valid,
.login .was-validated .input-group>input[type=text]:not(:focus):valid,
.was-validated .login .input-group>input[type=password]:not(:focus):valid,
.login .was-validated .input-group>input[type=password]:not(:focus):valid,
.was-validated .woocommerce-ResetPassword.lost_reset_password .input-group>input[type=text]:not(:focus):valid,
.woocommerce-ResetPassword.lost_reset_password .was-validated .input-group>input[type=text]:not(:focus):valid,
.was-validated .checkout.woocommerce-checkout .input-group>input[type=text]:not(:focus):valid,
.checkout.woocommerce-checkout .was-validated .input-group>input[type=text]:not(:focus):valid,
.was-validated .checkout.woocommerce-checkout .input-group>input[type=email]:not(:focus):valid,
.checkout.woocommerce-checkout .was-validated .input-group>input[type=email]:not(:focus):valid,
.was-validated .checkout.woocommerce-checkout .input-group>input[type=tel]:not(:focus):valid,
.checkout.woocommerce-checkout .was-validated .input-group>input[type=tel]:not(:focus):valid,
.was-validated .checkout.woocommerce-checkout .input-group>.select2-choice:not(:focus):valid,
.checkout.woocommerce-checkout .was-validated .input-group>.select2-choice:not(:focus):valid,
.was-validated .checkout.woocommerce-checkout .input-group>textarea:not(:focus):valid,
.checkout.woocommerce-checkout .was-validated .input-group>textarea:not(:focus):valid,
.was-validated .woocommerce form .form-row .input-group>input.input-text:not(:focus):valid,
.woocommerce form .form-row .was-validated .input-group>input.input-text:not(:focus):valid,
.was-validated .woocommerce form .form-row .input-group>.select2-selection:not(:focus):valid,
.woocommerce form .form-row .was-validated .input-group>.select2-selection:not(:focus):valid,
.was-validated .woocommerce form .form-row .input-group>.select2-choice:not(:focus):valid,
.woocommerce form .form-row .was-validated .input-group>.select2-choice:not(:focus):valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select:not(:focus):valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group>select:not(:focus):valid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input:not(:focus):valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group>input:not(:focus):valid,
.was-validated .woocommerce-cart-form .table tr .quantity .input-group>input:not(:focus):valid,
.woocommerce-cart-form .table tr .quantity .was-validated .input-group>input:not(:focus):valid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text:not(:focus):valid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-group>.input-text:not(:focus):valid,
.was-validated .woocommerce #review_form #respond .input-group>input[type=text]:not(:focus):valid,
.woocommerce #review_form #respond .was-validated .input-group>input[type=text]:not(:focus):valid,
.was-validated .woocommerce #review_form #respond .input-group>textarea:not(:focus):valid,
.woocommerce #review_form #respond .was-validated .input-group>textarea:not(:focus):valid,
.was-validated .woocommerce #review_form #respond .input-group>input[type=email]:not(:focus):valid,
.woocommerce #review_form #respond .was-validated .input-group>input[type=email]:not(:focus):valid,
.input-group>.form-control:not(:focus).is-valid,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=text]:not(:focus).is-valid,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=email]:not(:focus).is-valid,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=password]:not(:focus).is-valid,
.login .input-group>input[type=text]:not(:focus).is-valid,
.login .input-group>input[type=password]:not(:focus).is-valid,
.woocommerce-ResetPassword.lost_reset_password .input-group>input[type=text]:not(:focus).is-valid,
.checkout.woocommerce-checkout .input-group>input[type=text]:not(:focus).is-valid,
.checkout.woocommerce-checkout .input-group>input[type=email]:not(:focus).is-valid,
.checkout.woocommerce-checkout .input-group>input[type=tel]:not(:focus).is-valid,
.checkout.woocommerce-checkout .input-group>.select2-choice:not(:focus).is-valid,
.checkout.woocommerce-checkout .input-group>textarea:not(:focus).is-valid,
.woocommerce form .form-row .input-group>input.input-text:not(:focus).is-valid,
.woocommerce form .form-row .input-group>.select2-selection:not(:focus).is-valid,
.woocommerce form .form-row .input-group>.select2-choice:not(:focus).is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select:not(:focus).is-valid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input:not(:focus).is-valid,
.woocommerce-cart-form .table tr .quantity .input-group>input:not(:focus).is-valid,
.woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text:not(:focus).is-valid,
.woocommerce #review_form #respond .input-group>input[type=text]:not(:focus).is-valid,
.woocommerce #review_form #respond .input-group>textarea:not(:focus).is-valid,
.woocommerce #review_form #respond .input-group>input[type=email]:not(:focus).is-valid,
.was-validated .input-group>.form-select:not(:focus):valid,
.was-validated .input-group>select:not(:focus):valid,
.input-group>.form-select:not(:focus).is-valid,
.input-group>select:not(:focus).is-valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.input-group>.form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-danger-text);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=text]:invalid,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=text]:invalid,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=email]:invalid,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=email]:invalid,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=password]:invalid,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=password]:invalid,
.was-validated .login input[type=text]:invalid,
.login .was-validated input[type=text]:invalid,
.was-validated .login input[type=password]:invalid,
.login .was-validated input[type=password]:invalid,
.was-validated .woocommerce-ResetPassword.lost_reset_password input[type=text]:invalid,
.woocommerce-ResetPassword.lost_reset_password .was-validated input[type=text]:invalid,
.was-validated .checkout.woocommerce-checkout input[type=text]:invalid,
.checkout.woocommerce-checkout .was-validated input[type=text]:invalid,
.was-validated .checkout.woocommerce-checkout input[type=email]:invalid,
.checkout.woocommerce-checkout .was-validated input[type=email]:invalid,
.was-validated .checkout.woocommerce-checkout input[type=tel]:invalid,
.checkout.woocommerce-checkout .was-validated input[type=tel]:invalid,
.was-validated .checkout.woocommerce-checkout .select2-choice:invalid,
.checkout.woocommerce-checkout .was-validated .select2-choice:invalid,
.was-validated .checkout.woocommerce-checkout textarea:invalid,
.checkout.woocommerce-checkout .was-validated textarea:invalid,
.was-validated .woocommerce form .form-row input.input-text:invalid,
.woocommerce form .form-row .was-validated input.input-text:invalid,
.was-validated .woocommerce form .form-row .select2-selection:invalid,
.woocommerce form .form-row .was-validated .select2-selection:invalid,
.was-validated .woocommerce form .form-row .select2-choice:invalid,
.woocommerce form .form-row .was-validated .select2-choice:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:invalid,
.was-validated .woocommerce-cart-form .table tr .quantity input:invalid,
.woocommerce-cart-form .table tr .quantity .was-validated input:invalid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:invalid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:invalid,
.was-validated .woocommerce #review_form #respond input[type=text]:invalid,
.woocommerce #review_form #respond .was-validated input[type=text]:invalid,
.was-validated .woocommerce #review_form #respond textarea:invalid,
.woocommerce #review_form #respond .was-validated textarea:invalid,
.was-validated .woocommerce #review_form #respond input[type=email]:invalid,
.woocommerce #review_form #respond .was-validated input[type=email]:invalid,
.form-control.is-invalid,
.woocommerce-EditAccountForm.edit-account input.is-invalid[type=text],
.woocommerce-EditAccountForm.edit-account input.is-invalid[type=email],
.woocommerce-EditAccountForm.edit-account input.is-invalid[type=password],
.login input.is-invalid[type=text],
.login input.is-invalid[type=password],
.woocommerce-ResetPassword.lost_reset_password input.is-invalid[type=text],
.checkout.woocommerce-checkout input.is-invalid[type=text],
.checkout.woocommerce-checkout input.is-invalid[type=email],
.checkout.woocommerce-checkout input.is-invalid[type=tel],
.checkout.woocommerce-checkout .is-invalid.select2-choice,
.checkout.woocommerce-checkout textarea.is-invalid,
.woocommerce form .form-row input.is-invalid.input-text,
.woocommerce form .form-row .is-invalid.select2-selection,
.woocommerce form .form-row .is-invalid.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-invalid,
.woocommerce-cart-form .table tr .quantity input.is-invalid,
.woocommerce-cart-form .table tr td.actions .coupon .is-invalid.input-text,
.woocommerce #review_form #respond input.is-invalid[type=text],
.woocommerce #review_form #respond textarea.is-invalid,
.woocommerce #review_form #respond input.is-invalid[type=email] {
  border-color: var(--bs-danger);
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23D23636'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D23636' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=text]:invalid:focus,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=text]:invalid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=email]:invalid:focus,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=email]:invalid:focus,
.was-validated .woocommerce-EditAccountForm.edit-account input[type=password]:invalid:focus,
.woocommerce-EditAccountForm.edit-account .was-validated input[type=password]:invalid:focus,
.was-validated .login input[type=text]:invalid:focus,
.login .was-validated input[type=text]:invalid:focus,
.was-validated .login input[type=password]:invalid:focus,
.login .was-validated input[type=password]:invalid:focus,
.was-validated .woocommerce-ResetPassword.lost_reset_password input[type=text]:invalid:focus,
.woocommerce-ResetPassword.lost_reset_password .was-validated input[type=text]:invalid:focus,
.was-validated .checkout.woocommerce-checkout input[type=text]:invalid:focus,
.checkout.woocommerce-checkout .was-validated input[type=text]:invalid:focus,
.was-validated .checkout.woocommerce-checkout input[type=email]:invalid:focus,
.checkout.woocommerce-checkout .was-validated input[type=email]:invalid:focus,
.was-validated .checkout.woocommerce-checkout input[type=tel]:invalid:focus,
.checkout.woocommerce-checkout .was-validated input[type=tel]:invalid:focus,
.was-validated .checkout.woocommerce-checkout .select2-choice:invalid:focus,
.checkout.woocommerce-checkout .was-validated .select2-choice:invalid:focus,
.was-validated .checkout.woocommerce-checkout textarea:invalid:focus,
.checkout.woocommerce-checkout .was-validated textarea:invalid:focus,
.was-validated .woocommerce form .form-row input.input-text:invalid:focus,
.woocommerce form .form-row .was-validated input.input-text:invalid:focus,
.was-validated .woocommerce form .form-row .select2-selection:invalid:focus,
.woocommerce form .form-row .was-validated .select2-selection:invalid:focus,
.was-validated .woocommerce form .form-row .select2-choice:invalid:focus,
.woocommerce form .form-row .was-validated .select2-choice:invalid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row select:invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated select:invalid:focus,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row input:invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated input:invalid:focus,
.was-validated .woocommerce-cart-form .table tr .quantity input:invalid:focus,
.woocommerce-cart-form .table tr .quantity .was-validated input:invalid:focus,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-text:invalid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-text:invalid:focus,
.was-validated .woocommerce #review_form #respond input[type=text]:invalid:focus,
.woocommerce #review_form #respond .was-validated input[type=text]:invalid:focus,
.was-validated .woocommerce #review_form #respond textarea:invalid:focus,
.woocommerce #review_form #respond .was-validated textarea:invalid:focus,
.was-validated .woocommerce #review_form #respond input[type=email]:invalid:focus,
.woocommerce #review_form #respond .was-validated input[type=email]:invalid:focus,
.form-control.is-invalid:focus,
.woocommerce-EditAccountForm.edit-account input.is-invalid[type=text]:focus,
.woocommerce-EditAccountForm.edit-account input.is-invalid[type=email]:focus,
.woocommerce-EditAccountForm.edit-account input.is-invalid[type=password]:focus,
.login input.is-invalid[type=text]:focus,
.login input.is-invalid[type=password]:focus,
.woocommerce-ResetPassword.lost_reset_password input.is-invalid[type=text]:focus,
.checkout.woocommerce-checkout input.is-invalid[type=text]:focus,
.checkout.woocommerce-checkout input.is-invalid[type=email]:focus,
.checkout.woocommerce-checkout input.is-invalid[type=tel]:focus,
.checkout.woocommerce-checkout .is-invalid.select2-choice:focus,
.checkout.woocommerce-checkout textarea.is-invalid:focus,
.woocommerce form .form-row input.is-invalid.input-text:focus,
.woocommerce form .form-row .is-invalid.select2-selection:focus,
.woocommerce form .form-row .is-invalid.select2-choice:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.is-invalid:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.is-invalid:focus,
.woocommerce-cart-form .table tr .quantity input.is-invalid:focus,
.woocommerce-cart-form .table tr td.actions .coupon .is-invalid.input-text:focus,
.woocommerce #review_form #respond input.is-invalid[type=text]:focus,
.woocommerce #review_form #respond textarea.is-invalid:focus,
.woocommerce #review_form #respond input.is-invalid[type=email]:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .checkout.woocommerce-checkout textarea:invalid,
.checkout.woocommerce-checkout .was-validated textarea:invalid,
.was-validated .woocommerce form .form-row textarea.select2-selection:invalid,
.woocommerce form .form-row .was-validated textarea.select2-selection:invalid,
.was-validated .woocommerce form .form-row textarea.select2-choice:invalid,
.woocommerce form .form-row .was-validated textarea.select2-choice:invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row textarea:invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated textarea:invalid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon textarea.input-text:invalid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated textarea.input-text:invalid,
.was-validated .woocommerce #review_form #respond textarea:invalid,
.woocommerce #review_form #respond .was-validated textarea:invalid,
textarea.form-control.is-invalid,
.checkout.woocommerce-checkout textarea.is-invalid,
.woocommerce form .form-row textarea.is-invalid.select2-selection,
.woocommerce form .form-row textarea.is-invalid.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.is-invalid,
.woocommerce-cart-form .table tr td.actions .coupon textarea.is-invalid.input-text,
.woocommerce #review_form #respond textarea.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid,
.was-validated select:invalid,
.form-select.is-invalid,
select.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.was-validated select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23D23636'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D23636' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.was-validated select:invalid:focus,
.form-select.is-invalid:focus,
select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: 0 0 0 0rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.6em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-danger-text);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--bs-danger-text);
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group>.form-control:not(:focus):invalid,
.was-validated .woocommerce-EditAccountForm.edit-account .input-group>input[type=text]:not(:focus):invalid,
.woocommerce-EditAccountForm.edit-account .was-validated .input-group>input[type=text]:not(:focus):invalid,
.was-validated .woocommerce-EditAccountForm.edit-account .input-group>input[type=email]:not(:focus):invalid,
.woocommerce-EditAccountForm.edit-account .was-validated .input-group>input[type=email]:not(:focus):invalid,
.was-validated .woocommerce-EditAccountForm.edit-account .input-group>input[type=password]:not(:focus):invalid,
.woocommerce-EditAccountForm.edit-account .was-validated .input-group>input[type=password]:not(:focus):invalid,
.was-validated .login .input-group>input[type=text]:not(:focus):invalid,
.login .was-validated .input-group>input[type=text]:not(:focus):invalid,
.was-validated .login .input-group>input[type=password]:not(:focus):invalid,
.login .was-validated .input-group>input[type=password]:not(:focus):invalid,
.was-validated .woocommerce-ResetPassword.lost_reset_password .input-group>input[type=text]:not(:focus):invalid,
.woocommerce-ResetPassword.lost_reset_password .was-validated .input-group>input[type=text]:not(:focus):invalid,
.was-validated .checkout.woocommerce-checkout .input-group>input[type=text]:not(:focus):invalid,
.checkout.woocommerce-checkout .was-validated .input-group>input[type=text]:not(:focus):invalid,
.was-validated .checkout.woocommerce-checkout .input-group>input[type=email]:not(:focus):invalid,
.checkout.woocommerce-checkout .was-validated .input-group>input[type=email]:not(:focus):invalid,
.was-validated .checkout.woocommerce-checkout .input-group>input[type=tel]:not(:focus):invalid,
.checkout.woocommerce-checkout .was-validated .input-group>input[type=tel]:not(:focus):invalid,
.was-validated .checkout.woocommerce-checkout .input-group>.select2-choice:not(:focus):invalid,
.checkout.woocommerce-checkout .was-validated .input-group>.select2-choice:not(:focus):invalid,
.was-validated .checkout.woocommerce-checkout .input-group>textarea:not(:focus):invalid,
.checkout.woocommerce-checkout .was-validated .input-group>textarea:not(:focus):invalid,
.was-validated .woocommerce form .form-row .input-group>input.input-text:not(:focus):invalid,
.woocommerce form .form-row .was-validated .input-group>input.input-text:not(:focus):invalid,
.was-validated .woocommerce form .form-row .input-group>.select2-selection:not(:focus):invalid,
.woocommerce form .form-row .was-validated .input-group>.select2-selection:not(:focus):invalid,
.was-validated .woocommerce form .form-row .input-group>.select2-choice:not(:focus):invalid,
.woocommerce form .form-row .was-validated .input-group>.select2-choice:not(:focus):invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select:not(:focus):invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group>select:not(:focus):invalid,
.was-validated .woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input:not(:focus):invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .was-validated .input-group>input:not(:focus):invalid,
.was-validated .woocommerce-cart-form .table tr .quantity .input-group>input:not(:focus):invalid,
.woocommerce-cart-form .table tr .quantity .was-validated .input-group>input:not(:focus):invalid,
.was-validated .woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text:not(:focus):invalid,
.woocommerce-cart-form .table tr td.actions .coupon .was-validated .input-group>.input-text:not(:focus):invalid,
.was-validated .woocommerce #review_form #respond .input-group>input[type=text]:not(:focus):invalid,
.woocommerce #review_form #respond .was-validated .input-group>input[type=text]:not(:focus):invalid,
.was-validated .woocommerce #review_form #respond .input-group>textarea:not(:focus):invalid,
.woocommerce #review_form #respond .was-validated .input-group>textarea:not(:focus):invalid,
.was-validated .woocommerce #review_form #respond .input-group>input[type=email]:not(:focus):invalid,
.woocommerce #review_form #respond .was-validated .input-group>input[type=email]:not(:focus):invalid,
.input-group>.form-control:not(:focus).is-invalid,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=text]:not(:focus).is-invalid,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=email]:not(:focus).is-invalid,
.woocommerce-EditAccountForm.edit-account .input-group>input[type=password]:not(:focus).is-invalid,
.login .input-group>input[type=text]:not(:focus).is-invalid,
.login .input-group>input[type=password]:not(:focus).is-invalid,
.woocommerce-ResetPassword.lost_reset_password .input-group>input[type=text]:not(:focus).is-invalid,
.checkout.woocommerce-checkout .input-group>input[type=text]:not(:focus).is-invalid,
.checkout.woocommerce-checkout .input-group>input[type=email]:not(:focus).is-invalid,
.checkout.woocommerce-checkout .input-group>input[type=tel]:not(:focus).is-invalid,
.checkout.woocommerce-checkout .input-group>.select2-choice:not(:focus).is-invalid,
.checkout.woocommerce-checkout .input-group>textarea:not(:focus).is-invalid,
.woocommerce form .form-row .input-group>input.input-text:not(:focus).is-invalid,
.woocommerce form .form-row .input-group>.select2-selection:not(:focus).is-invalid,
.woocommerce form .form-row .input-group>.select2-choice:not(:focus).is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>select:not(:focus).is-invalid,
.woocommerce .woocommerce-checkout.checkout form .form-row .input-group>input:not(:focus).is-invalid,
.woocommerce-cart-form .table tr .quantity .input-group>input:not(:focus).is-invalid,
.woocommerce-cart-form .table tr td.actions .coupon .input-group>.input-text:not(:focus).is-invalid,
.woocommerce #review_form #respond .input-group>input[type=text]:not(:focus).is-invalid,
.woocommerce #review_form #respond .input-group>textarea:not(:focus).is-invalid,
.woocommerce #review_form #respond .input-group>input[type=email]:not(:focus).is-invalid,
.was-validated .input-group>.form-select:not(:focus):invalid,
.was-validated .input-group>select:not(:focus):invalid,
.input-group>.form-select:not(:focus).is-invalid,
.input-group>select:not(:focus).is-invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.input-group>.form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart-form .table tr .button,
.woocommerce #review_form #respond .form-submit input,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.wp-block-button__link {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.6;
  --bs-btn-color: #5E626F;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .btn,
  .woocommerce #respond input#submit,
  .woocommerce a.button,
  .woocommerce button.button,
  .woocommerce input.button,
  .woocommerce #place_order,
  .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart-form .table tr .button,
  .woocommerce #review_form #respond .form-submit input,
  .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons a,
  .wp-block-button__link {
    transition: none;
  }
}

.btn:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce #place_order:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce-cart-form .table tr .button:hover,
.woocommerce #review_form #respond .form-submit input:hover,
.woocommerce .widget_shopping_cart .buttons a:hover,
.woocommerce.widget_shopping_cart .buttons a:hover,
.wp-block-button__link:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check+.btn:hover,
.woocommerce #respond .btn-check+input#submit:hover,
.woocommerce .btn-check+a.button:hover,
.woocommerce .btn-check+button.button:hover,
.woocommerce .btn-check+input.button:hover,
.woocommerce .btn-check+#place_order:hover,
.woocommerce-cart .wc-proceed-to-checkout .btn-check+a.checkout-button:hover,
.woocommerce-cart-form .table tr .btn-check+.button:hover,
.woocommerce #review_form #respond .form-submit .btn-check+input:hover,
.woocommerce .widget_shopping_cart .buttons .btn-check+a:hover,
.woocommerce.widget_shopping_cart .buttons .btn-check+a:hover,
.btn-check+.wp-block-button__link:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible,
.woocommerce #respond input#submit:focus-visible,
.woocommerce a.button:focus-visible,
.woocommerce button.button:focus-visible,
.woocommerce input.button:focus-visible,
.woocommerce #place_order:focus-visible,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus-visible,
.woocommerce-cart-form .table tr .button:focus-visible,
.woocommerce #review_form #respond .form-submit input:focus-visible,
.woocommerce .widget_shopping_cart .buttons a:focus-visible,
.woocommerce.widget_shopping_cart .buttons a:focus-visible,
.wp-block-button__link:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn,
.woocommerce #respond .btn-check:focus-visible+input#submit,
.woocommerce .btn-check:focus-visible+a.button,
.woocommerce .btn-check:focus-visible+button.button,
.woocommerce .btn-check:focus-visible+input.button,
.woocommerce .btn-check:focus-visible+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:focus-visible+a.checkout-button,
.woocommerce-cart-form .table tr .btn-check:focus-visible+.button,
.woocommerce #review_form #respond .form-submit .btn-check:focus-visible+input,
.woocommerce .widget_shopping_cart .buttons .btn-check:focus-visible+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:focus-visible+a,
.btn-check:focus-visible+.wp-block-button__link {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
.woocommerce #respond .btn-check:checked+input#submit,
.woocommerce .btn-check:checked+a.button,
.woocommerce .btn-check:checked+button.button,
.woocommerce .btn-check:checked+input.button,
.woocommerce .btn-check:checked+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.checkout-button,
.woocommerce-cart-form .table tr .btn-check:checked+.button,
.woocommerce #review_form #respond .form-submit .btn-check:checked+input,
.woocommerce .widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .btn-check:checked+a,
.btn-check:checked+.wp-block-button__link,
:not(.btn-check)+.btn:active,
.woocommerce #respond :not(.btn-check)+input#submit:active,
.woocommerce :not(.btn-check)+a.button:active,
.woocommerce :not(.btn-check)+button.button:active,
.woocommerce :not(.btn-check)+input.button:active,
.woocommerce :not(.btn-check)+#place_order:active,
.woocommerce-cart .wc-proceed-to-checkout :not(.btn-check)+a.checkout-button:active,
.woocommerce-cart-form .table tr :not(.btn-check)+.button:active,
.woocommerce #review_form #respond .form-submit :not(.btn-check)+input:active,
.woocommerce .widget_shopping_cart .buttons :not(.btn-check)+a:active,
.woocommerce.widget_shopping_cart .buttons :not(.btn-check)+a:active,
:not(.btn-check)+.wp-block-button__link:active,
.btn:first-child:active,
.woocommerce #respond input#submit:first-child:active,
.woocommerce a.button:first-child:active,
.woocommerce button.button:first-child:active,
.woocommerce input.button:first-child:active,
.woocommerce #place_order:first-child:active,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:first-child:active,
.woocommerce-cart-form .table tr .button:first-child:active,
.woocommerce #review_form #respond .form-submit input:first-child:active,
.woocommerce .widget_shopping_cart .buttons a:first-child:active,
.woocommerce.widget_shopping_cart .buttons a:first-child:active,
.wp-block-button__link:first-child:active,
.btn.active,
.woocommerce #respond input.active#submit,
.woocommerce a.active.button,
.woocommerce button.active.button,
.woocommerce input.active.button,
.woocommerce .active#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.active.checkout-button,
.woocommerce-cart-form .table tr .active.button,
.woocommerce #review_form #respond .form-submit input.active,
.woocommerce .widget_shopping_cart .buttons a.active,
.woocommerce.widget_shopping_cart .buttons a.active,
.active.wp-block-button__link,
.btn.show,
.woocommerce #respond input.show#submit,
.woocommerce a.show.button,
.woocommerce button.show.button,
.woocommerce input.show.button,
.woocommerce .show#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.show.checkout-button,
.woocommerce-cart-form .table tr .show.button,
.woocommerce #review_form #respond .form-submit input.show,
.woocommerce .widget_shopping_cart .buttons a.show,
.woocommerce.widget_shopping_cart .buttons a.show,
.show.wp-block-button__link {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus-visible,
.woocommerce #respond .btn-check:checked+input#submit:focus-visible,
.woocommerce .btn-check:checked+a.button:focus-visible,
.woocommerce .btn-check:checked+button.button:focus-visible,
.woocommerce .btn-check:checked+input.button:focus-visible,
.woocommerce .btn-check:checked+#place_order:focus-visible,
.woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.checkout-button:focus-visible,
.woocommerce-cart-form .table tr .btn-check:checked+.button:focus-visible,
.woocommerce #review_form #respond .form-submit .btn-check:checked+input:focus-visible,
.woocommerce .widget_shopping_cart .buttons .btn-check:checked+a:focus-visible,
.woocommerce.widget_shopping_cart .buttons .btn-check:checked+a:focus-visible,
.btn-check:checked+.wp-block-button__link:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.woocommerce #respond :not(.btn-check)+input#submit:active:focus-visible,
.woocommerce :not(.btn-check)+a.button:active:focus-visible,
.woocommerce :not(.btn-check)+button.button:active:focus-visible,
.woocommerce :not(.btn-check)+input.button:active:focus-visible,
.woocommerce :not(.btn-check)+#place_order:active:focus-visible,
.woocommerce-cart .wc-proceed-to-checkout :not(.btn-check)+a.checkout-button:active:focus-visible,
.woocommerce-cart-form .table tr :not(.btn-check)+.button:active:focus-visible,
.woocommerce #review_form #respond .form-submit :not(.btn-check)+input:active:focus-visible,
.woocommerce .widget_shopping_cart .buttons :not(.btn-check)+a:active:focus-visible,
.woocommerce.widget_shopping_cart .buttons :not(.btn-check)+a:active:focus-visible,
:not(.btn-check)+.wp-block-button__link:active:focus-visible,
.btn:first-child:active:focus-visible,
.woocommerce #respond input#submit:first-child:active:focus-visible,
.woocommerce a.button:first-child:active:focus-visible,
.woocommerce button.button:first-child:active:focus-visible,
.woocommerce input.button:first-child:active:focus-visible,
.woocommerce #place_order:first-child:active:focus-visible,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:first-child:active:focus-visible,
.woocommerce-cart-form .table tr .button:first-child:active:focus-visible,
.woocommerce #review_form #respond .form-submit input:first-child:active:focus-visible,
.woocommerce .widget_shopping_cart .buttons a:first-child:active:focus-visible,
.woocommerce.widget_shopping_cart .buttons a:first-child:active:focus-visible,
.wp-block-button__link:first-child:active:focus-visible,
.btn.active:focus-visible,
.woocommerce #respond input.active#submit:focus-visible,
.woocommerce a.active.button:focus-visible,
.woocommerce button.active.button:focus-visible,
.woocommerce input.active.button:focus-visible,
.woocommerce .active#place_order:focus-visible,
.woocommerce-cart .wc-proceed-to-checkout a.active.checkout-button:focus-visible,
.woocommerce-cart-form .table tr .active.button:focus-visible,
.woocommerce #review_form #respond .form-submit input.active:focus-visible,
.woocommerce .widget_shopping_cart .buttons a.active:focus-visible,
.woocommerce.widget_shopping_cart .buttons a.active:focus-visible,
.active.wp-block-button__link:focus-visible,
.btn.show:focus-visible,
.woocommerce #respond input.show#submit:focus-visible,
.woocommerce a.show.button:focus-visible,
.woocommerce button.show.button:focus-visible,
.woocommerce input.show.button:focus-visible,
.woocommerce .show#place_order:focus-visible,
.woocommerce-cart .wc-proceed-to-checkout a.show.checkout-button:focus-visible,
.woocommerce-cart-form .table tr .show.button:focus-visible,
.woocommerce #review_form #respond .form-submit input.show:focus-visible,
.woocommerce .widget_shopping_cart .buttons a.show:focus-visible,
.woocommerce.widget_shopping_cart .buttons a.show:focus-visible,
.show.wp-block-button__link:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce a.button:disabled,
.woocommerce button.button:disabled,
.woocommerce input.button:disabled,
.woocommerce #place_order:disabled,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:disabled,
.woocommerce-cart-form .table tr .button:disabled,
.woocommerce #review_form #respond .form-submit input:disabled,
.woocommerce .widget_shopping_cart .buttons a:disabled,
.woocommerce.widget_shopping_cart .buttons a:disabled,
.wp-block-button__link:disabled,
.btn.disabled,
.woocommerce #respond input.disabled#submit,
.woocommerce a.disabled.button,
.woocommerce button.disabled.button,
.woocommerce input.disabled.button,
.woocommerce .disabled#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.disabled.checkout-button,
.woocommerce-cart-form .table tr .disabled.button,
.woocommerce #review_form #respond .form-submit input.disabled,
.woocommerce .widget_shopping_cart .buttons a.disabled,
.woocommerce.widget_shopping_cart .buttons a.disabled,
.disabled.wp-block-button__link,
fieldset:disabled .btn,
fieldset:disabled .woocommerce #respond input#submit,
.woocommerce #respond fieldset:disabled input#submit,
fieldset:disabled .woocommerce a.button,
.woocommerce fieldset:disabled a.button,
fieldset:disabled .woocommerce button.button,
.woocommerce fieldset:disabled button.button,
fieldset:disabled .woocommerce input.button,
.woocommerce fieldset:disabled input.button,
fieldset:disabled .woocommerce #place_order,
.woocommerce fieldset:disabled #place_order,
fieldset:disabled .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout fieldset:disabled a.checkout-button,
fieldset:disabled .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr fieldset:disabled .button,
fieldset:disabled .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit fieldset:disabled input,
fieldset:disabled .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons fieldset:disabled a,
fieldset:disabled .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons fieldset:disabled a,
fieldset:disabled .wp-block-button__link {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart-form .table tr .button,
.woocommerce #review_form #respond .form-submit input,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.wp-block-button__link {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #CC0D39;
  --bs-btn-border-color: #CC0D39;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #ad0b30;
  --bs-btn-hover-border-color: #a30a2e;
  --bs-btn-focus-shadow-rgb: 212, 49, 87;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a30a2e;
  --bs-btn-active-border-color: #990a2b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #CC0D39;
  --bs-btn-disabled-border-color: #CC0D39;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #31A56D;
  --bs-btn-border-color: #31A56D;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #50b383;
  --bs-btn-hover-border-color: #46ae7c;
  --bs-btn-focus-shadow-rgb: 42, 140, 93;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #5ab78a;
  --bs-btn-active-border-color: #46ae7c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #31A56D;
  --bs-btn-disabled-border-color: #31A56D;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0194D9;
  --bs-btn-border-color: #0194D9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #27a4df;
  --bs-btn-hover-border-color: #1a9fdd;
  --bs-btn-focus-shadow-rgb: 1, 126, 184;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #34a9e1;
  --bs-btn-active-border-color: #1a9fdd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0194D9;
  --bs-btn-disabled-border-color: #0194D9;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #E79600;
  --bs-btn-border-color: #E79600;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eba626;
  --bs-btn-hover-border-color: #e9a11a;
  --bs-btn-focus-shadow-rgb: 196, 128, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ecab33;
  --bs-btn-active-border-color: #e9a11a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E79600;
  --bs-btn-disabled-border-color: #E79600;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #D23636;
  --bs-btn-border-color: #D23636;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b32e2e;
  --bs-btn-hover-border-color: #a82b2b;
  --bs-btn-focus-shadow-rgb: 217, 84, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a82b2b;
  --bs-btn-active-border-color: #9e2929;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #D23636;
  --bs-btn-disabled-border-color: #D23636;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFFAF3;
  --bs-btn-border-color: #FFFAF3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d9d5cf;
  --bs-btn-hover-border-color: #ccc8c2;
  --bs-btn-focus-shadow-rgb: 217, 213, 207;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ccc8c2;
  --bs-btn-active-border-color: #bfbcb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFFAF3;
  --bs-btn-disabled-border-color: #FFFAF3;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #444444;
  --bs-btn-border-color: #444444;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #606060;
  --bs-btn-hover-border-color: #575757;
  --bs-btn-focus-shadow-rgb: 96, 96, 96;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: dimgray;
  --bs-btn-active-border-color: #575757;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #444444;
  --bs-btn-disabled-border-color: #444444;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  --bs-btn-color: #CC0D39;
  --bs-btn-border-color: #CC0D39;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #CC0D39;
  --bs-btn-hover-border-color: #CC0D39;
  --bs-btn-focus-shadow-rgb: 204, 13, 57;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #CC0D39;
  --bs-btn-active-border-color: #CC0D39;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #CC0D39;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #CC0D39;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #31A56D;
  --bs-btn-border-color: #31A56D;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31A56D;
  --bs-btn-hover-border-color: #31A56D;
  --bs-btn-focus-shadow-rgb: 49, 165, 109;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #31A56D;
  --bs-btn-active-border-color: #31A56D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #31A56D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #31A56D;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0194D9;
  --bs-btn-border-color: #0194D9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0194D9;
  --bs-btn-hover-border-color: #0194D9;
  --bs-btn-focus-shadow-rgb: 1, 148, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0194D9;
  --bs-btn-active-border-color: #0194D9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0194D9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0194D9;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #E79600;
  --bs-btn-border-color: #E79600;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E79600;
  --bs-btn-hover-border-color: #E79600;
  --bs-btn-focus-shadow-rgb: 231, 150, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E79600;
  --bs-btn-active-border-color: #E79600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E79600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E79600;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #D23636;
  --bs-btn-border-color: #D23636;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #D23636;
  --bs-btn-hover-border-color: #D23636;
  --bs-btn-focus-shadow-rgb: 210, 54, 54;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #D23636;
  --bs-btn-active-border-color: #D23636;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #D23636;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D23636;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #FFFAF3;
  --bs-btn-border-color: #FFFAF3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFFAF3;
  --bs-btn-hover-border-color: #FFFAF3;
  --bs-btn-focus-shadow-rgb: 255, 250, 243;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFFAF3;
  --bs-btn-active-border-color: #FFFAF3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFFAF3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFFAF3;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #444444;
  --bs-btn-border-color: #444444;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #444444;
  --bs-btn-hover-border-color: #444444;
  --bs-btn-focus-shadow-rgb: 68, 68, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #444444;
  --bs-btn-active-border-color: #444444;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #444444;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #444444;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 212, 49, 87;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg>.btn,
.woocommerce #respond .btn-group-lg>input#submit,
.woocommerce .btn-group-lg>a.button,
.woocommerce .btn-group-lg>button.button,
.woocommerce .btn-group-lg>input.button,
.woocommerce .btn-group-lg>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-lg>.button,
.woocommerce #review_form #respond .form-submit .btn-group-lg>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-lg>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-lg>a,
.btn-group-lg>.wp-block-button__link {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 1rem;
}

.btn-sm,
.btn-group-sm>.btn,
.woocommerce #respond .btn-group-sm>input#submit,
.woocommerce .btn-group-sm>a.button,
.woocommerce .btn-group-sm>button.button,
.woocommerce .btn-group-sm>input.button,
.woocommerce .btn-group-sm>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-sm>.button,
.woocommerce #review_form #respond .form-submit .btn-group-sm>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-sm>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-sm>a,
.btn-group-sm>.wp-block-button__link {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(0.375rem - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #CC0D39;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #CC0D39;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.woocommerce #respond .btn-group>input#submit,
.woocommerce .btn-group>a.button,
.woocommerce .btn-group>button.button,
.woocommerce .btn-group>input.button,
.woocommerce .btn-group>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group>.button,
.woocommerce #review_form #respond .form-submit .btn-group>input,
.woocommerce .widget_shopping_cart .buttons .btn-group>a,
.woocommerce.widget_shopping_cart .buttons .btn-group>a,
.btn-group>.wp-block-button__link,
.btn-group-vertical>.btn,
.woocommerce #respond .btn-group-vertical>input#submit,
.woocommerce .btn-group-vertical>a.button,
.woocommerce .btn-group-vertical>button.button,
.woocommerce .btn-group-vertical>input.button,
.woocommerce .btn-group-vertical>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a,
.btn-group-vertical>.wp-block-button__link {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.woocommerce #respond .btn-group>.btn-check:checked+input#submit,
.woocommerce .btn-group>.btn-check:checked+a.button,
.woocommerce .btn-group>.btn-check:checked+button.button,
.woocommerce .btn-group>.btn-check:checked+input.button,
.woocommerce .btn-group>.btn-check:checked+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-check:checked+a.checkout-button,
.woocommerce-cart-form .table tr .btn-group>.btn-check:checked+.button,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-check:checked+input,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-check:checked+a,
.btn-group>.btn-check:checked+.wp-block-button__link,
.btn-group>.btn-check:focus+.btn,
.woocommerce #respond .btn-group>.btn-check:focus+input#submit,
.woocommerce .btn-group>.btn-check:focus+a.button,
.woocommerce .btn-group>.btn-check:focus+button.button,
.woocommerce .btn-group>.btn-check:focus+input.button,
.woocommerce .btn-group>.btn-check:focus+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-check:focus+a.checkout-button,
.woocommerce-cart-form .table tr .btn-group>.btn-check:focus+.button,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-check:focus+input,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-check:focus+a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-check:focus+a,
.btn-group>.btn-check:focus+.wp-block-button__link,
.btn-group>.btn:hover,
.woocommerce #respond .btn-group>input#submit:hover,
.woocommerce .btn-group>a.button:hover,
.woocommerce .btn-group>button.button:hover,
.woocommerce .btn-group>input.button:hover,
.woocommerce .btn-group>#place_order:hover,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:hover,
.woocommerce-cart-form .table tr .btn-group>.button:hover,
.woocommerce #review_form #respond .form-submit .btn-group>input:hover,
.woocommerce .widget_shopping_cart .buttons .btn-group>a:hover,
.woocommerce.widget_shopping_cart .buttons .btn-group>a:hover,
.btn-group>.wp-block-button__link:hover,
.btn-group>.btn:focus,
.woocommerce #respond .btn-group>input#submit:focus,
.woocommerce .btn-group>a.button:focus,
.woocommerce .btn-group>button.button:focus,
.woocommerce .btn-group>input.button:focus,
.woocommerce .btn-group>#place_order:focus,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:focus,
.woocommerce-cart-form .table tr .btn-group>.button:focus,
.woocommerce #review_form #respond .form-submit .btn-group>input:focus,
.woocommerce .widget_shopping_cart .buttons .btn-group>a:focus,
.woocommerce.widget_shopping_cart .buttons .btn-group>a:focus,
.btn-group>.wp-block-button__link:focus,
.btn-group>.btn:active,
.woocommerce #respond .btn-group>input#submit:active,
.woocommerce .btn-group>a.button:active,
.woocommerce .btn-group>button.button:active,
.woocommerce .btn-group>input.button:active,
.woocommerce .btn-group>#place_order:active,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:active,
.woocommerce-cart-form .table tr .btn-group>.button:active,
.woocommerce #review_form #respond .form-submit .btn-group>input:active,
.woocommerce .widget_shopping_cart .buttons .btn-group>a:active,
.woocommerce.widget_shopping_cart .buttons .btn-group>a:active,
.btn-group>.wp-block-button__link:active,
.btn-group>.btn.active,
.woocommerce #respond .btn-group>input.active#submit,
.woocommerce .btn-group>a.active.button,
.woocommerce .btn-group>button.active.button,
.woocommerce .btn-group>input.active.button,
.woocommerce .btn-group>.active#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.active.checkout-button,
.woocommerce-cart-form .table tr .btn-group>.active.button,
.woocommerce #review_form #respond .form-submit .btn-group>input.active,
.woocommerce .widget_shopping_cart .buttons .btn-group>a.active,
.woocommerce.widget_shopping_cart .buttons .btn-group>a.active,
.btn-group>.active.wp-block-button__link,
.btn-group-vertical>.btn-check:checked+.btn,
.woocommerce #respond .btn-group-vertical>.btn-check:checked+input#submit,
.woocommerce .btn-group-vertical>.btn-check:checked+a.button,
.woocommerce .btn-group-vertical>.btn-check:checked+button.button,
.woocommerce .btn-group-vertical>.btn-check:checked+input.button,
.woocommerce .btn-group-vertical>.btn-check:checked+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-check:checked+a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-check:checked+.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-check:checked+input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-check:checked+a,
.btn-group-vertical>.btn-check:checked+.wp-block-button__link,
.btn-group-vertical>.btn-check:focus+.btn,
.woocommerce #respond .btn-group-vertical>.btn-check:focus+input#submit,
.woocommerce .btn-group-vertical>.btn-check:focus+a.button,
.woocommerce .btn-group-vertical>.btn-check:focus+button.button,
.woocommerce .btn-group-vertical>.btn-check:focus+input.button,
.woocommerce .btn-group-vertical>.btn-check:focus+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-check:focus+a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-check:focus+.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-check:focus+input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-check:focus+a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-check:focus+a,
.btn-group-vertical>.btn-check:focus+.wp-block-button__link,
.btn-group-vertical>.btn:hover,
.woocommerce #respond .btn-group-vertical>input#submit:hover,
.woocommerce .btn-group-vertical>a.button:hover,
.woocommerce .btn-group-vertical>button.button:hover,
.woocommerce .btn-group-vertical>input.button:hover,
.woocommerce .btn-group-vertical>#place_order:hover,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:hover,
.woocommerce-cart-form .table tr .btn-group-vertical>.button:hover,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:hover,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:hover,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:hover,
.btn-group-vertical>.wp-block-button__link:hover,
.btn-group-vertical>.btn:focus,
.woocommerce #respond .btn-group-vertical>input#submit:focus,
.woocommerce .btn-group-vertical>a.button:focus,
.woocommerce .btn-group-vertical>button.button:focus,
.woocommerce .btn-group-vertical>input.button:focus,
.woocommerce .btn-group-vertical>#place_order:focus,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:focus,
.woocommerce-cart-form .table tr .btn-group-vertical>.button:focus,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:focus,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:focus,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:focus,
.btn-group-vertical>.wp-block-button__link:focus,
.btn-group-vertical>.btn:active,
.woocommerce #respond .btn-group-vertical>input#submit:active,
.woocommerce .btn-group-vertical>a.button:active,
.woocommerce .btn-group-vertical>button.button:active,
.woocommerce .btn-group-vertical>input.button:active,
.woocommerce .btn-group-vertical>#place_order:active,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:active,
.woocommerce-cart-form .table tr .btn-group-vertical>.button:active,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:active,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:active,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:active,
.btn-group-vertical>.wp-block-button__link:active,
.btn-group-vertical>.btn.active,
.woocommerce #respond .btn-group-vertical>input.active#submit,
.woocommerce .btn-group-vertical>a.active.button,
.woocommerce .btn-group-vertical>button.active.button,
.woocommerce .btn-group-vertical>input.active.button,
.woocommerce .btn-group-vertical>.active#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.active.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.active.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input.active,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a.active,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a.active,
.btn-group-vertical>.active.wp-block-button__link {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}

.btn-group> :not(.btn-check:first-child)+.btn,
.woocommerce #respond .btn-group> :not(.btn-check:first-child)+input#submit,
.woocommerce .btn-group> :not(.btn-check:first-child)+a.button,
.woocommerce .btn-group> :not(.btn-check:first-child)+button.button,
.woocommerce .btn-group> :not(.btn-check:first-child)+input.button,
.woocommerce .btn-group> :not(.btn-check:first-child)+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group> :not(.btn-check:first-child)+a.checkout-button,
.woocommerce-cart-form .table tr .btn-group> :not(.btn-check:first-child)+.button,
.woocommerce #review_form #respond .form-submit .btn-group> :not(.btn-check:first-child)+input,
.woocommerce .widget_shopping_cart .buttons .btn-group> :not(.btn-check:first-child)+a,
.woocommerce.widget_shopping_cart .buttons .btn-group> :not(.btn-check:first-child)+a,
.btn-group> :not(.btn-check:first-child)+.wp-block-button__link,
.btn-group>.btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.woocommerce #respond .btn-group>input#submit:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>a.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>button.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>input.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group>#place_order:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart-form .table tr .btn-group>.button:not(:last-child):not(.dropdown-toggle),
.woocommerce #review_form #respond .form-submit .btn-group>input:not(:last-child):not(.dropdown-toggle),
.woocommerce .widget_shopping_cart .buttons .btn-group>a:not(:last-child):not(.dropdown-toggle),
.woocommerce.widget_shopping_cart .buttons .btn-group>a:not(:last-child):not(.dropdown-toggle),
.btn-group>.wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.woocommerce #respond .btn-group>input.dropdown-toggle-split#submit:first-child,
.woocommerce .btn-group>a.dropdown-toggle-split.button:first-child,
.woocommerce .btn-group>button.dropdown-toggle-split.button:first-child,
.woocommerce .btn-group>input.dropdown-toggle-split.button:first-child,
.woocommerce .btn-group>.dropdown-toggle-split#place_order:first-child,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.dropdown-toggle-split.checkout-button:first-child,
.woocommerce-cart-form .table tr .btn-group>.dropdown-toggle-split.button:first-child,
.woocommerce #review_form #respond .form-submit .btn-group>input.dropdown-toggle-split:first-child,
.woocommerce .widget_shopping_cart .buttons .btn-group>a.dropdown-toggle-split:first-child,
.woocommerce.widget_shopping_cart .buttons .btn-group>a.dropdown-toggle-split:first-child,
.btn-group>.dropdown-toggle-split.wp-block-button__link:first-child,
.btn-group>.btn-group:not(:last-child)>.btn,
.woocommerce #respond .btn-group>.btn-group:not(:last-child)>input#submit,
.woocommerce .btn-group>.btn-group:not(:last-child)>a.button,
.woocommerce .btn-group>.btn-group:not(:last-child)>button.button,
.woocommerce .btn-group>.btn-group:not(:last-child)>input.button,
.woocommerce .btn-group>.btn-group:not(:last-child)>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-group:not(:last-child)>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group>.btn-group:not(:last-child)>.button,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-group:not(:last-child)>input,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-group:not(:last-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-group:not(:last-child)>a,
.btn-group>.btn-group:not(:last-child)>.wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n+3),
.woocommerce #respond .btn-group>input#submit:nth-child(n+3),
.woocommerce .btn-group>a.button:nth-child(n+3),
.woocommerce .btn-group>button.button:nth-child(n+3),
.woocommerce .btn-group>input.button:nth-child(n+3),
.woocommerce .btn-group>#place_order:nth-child(n+3),
.woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:nth-child(n+3),
.woocommerce-cart-form .table tr .btn-group>.button:nth-child(n+3),
.woocommerce #review_form #respond .form-submit .btn-group>input:nth-child(n+3),
.woocommerce .widget_shopping_cart .buttons .btn-group>a:nth-child(n+3),
.woocommerce.widget_shopping_cart .buttons .btn-group>a:nth-child(n+3),
.btn-group>.wp-block-button__link:nth-child(n+3),
.btn-group> :not(.btn-check)+.btn,
.woocommerce #respond .btn-group> :not(.btn-check)+input#submit,
.woocommerce .btn-group> :not(.btn-check)+a.button,
.woocommerce .btn-group> :not(.btn-check)+button.button,
.woocommerce .btn-group> :not(.btn-check)+input.button,
.woocommerce .btn-group> :not(.btn-check)+#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group> :not(.btn-check)+a.checkout-button,
.woocommerce-cart-form .table tr .btn-group> :not(.btn-check)+.button,
.woocommerce #review_form #respond .form-submit .btn-group> :not(.btn-check)+input,
.woocommerce .widget_shopping_cart .buttons .btn-group> :not(.btn-check)+a,
.woocommerce.widget_shopping_cart .buttons .btn-group> :not(.btn-check)+a,
.btn-group> :not(.btn-check)+.wp-block-button__link,
.btn-group>.btn-group:not(:first-child)>.btn,
.woocommerce #respond .btn-group>.btn-group:not(:first-child)>input#submit,
.woocommerce .btn-group>.btn-group:not(:first-child)>a.button,
.woocommerce .btn-group>.btn-group:not(:first-child)>button.button,
.woocommerce .btn-group>.btn-group:not(:first-child)>input.button,
.woocommerce .btn-group>.btn-group:not(:first-child)>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-group:not(:first-child)>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group>.btn-group:not(:first-child)>.button,
.woocommerce #review_form #respond .form-submit .btn-group>.btn-group:not(:first-child)>input,
.woocommerce .widget_shopping_cart .buttons .btn-group>.btn-group:not(:first-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group>.btn-group:not(:first-child)>a,
.btn-group>.btn-group:not(:first-child)>.wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split,
.woocommerce #respond .btn-group-sm>input#submit+.dropdown-toggle-split,
.woocommerce .btn-group-sm>a.button+.dropdown-toggle-split,
.woocommerce .btn-group-sm>button.button+.dropdown-toggle-split,
.woocommerce .btn-group-sm>input.button+.dropdown-toggle-split,
.woocommerce .btn-group-sm>#place_order+.dropdown-toggle-split,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button+.dropdown-toggle-split,
.woocommerce-cart-form .table tr .btn-group-sm>.button+.dropdown-toggle-split,
.woocommerce #review_form #respond .form-submit .btn-group-sm>input+.dropdown-toggle-split,
.woocommerce .widget_shopping_cart .buttons .btn-group-sm>a+.dropdown-toggle-split,
.woocommerce.widget_shopping_cart .buttons .btn-group-sm>a+.dropdown-toggle-split,
.btn-group-sm>.wp-block-button__link+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split,
.woocommerce #respond .btn-group-lg>input#submit+.dropdown-toggle-split,
.woocommerce .btn-group-lg>a.button+.dropdown-toggle-split,
.woocommerce .btn-group-lg>button.button+.dropdown-toggle-split,
.woocommerce .btn-group-lg>input.button+.dropdown-toggle-split,
.woocommerce .btn-group-lg>#place_order+.dropdown-toggle-split,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button+.dropdown-toggle-split,
.woocommerce-cart-form .table tr .btn-group-lg>.button+.dropdown-toggle-split,
.woocommerce #review_form #respond .form-submit .btn-group-lg>input+.dropdown-toggle-split,
.woocommerce .widget_shopping_cart .buttons .btn-group-lg>a+.dropdown-toggle-split,
.woocommerce.widget_shopping_cart .buttons .btn-group-lg>a+.dropdown-toggle-split,
.btn-group-lg>.wp-block-button__link+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.woocommerce #respond .btn-group-vertical>input#submit,
.woocommerce .btn-group-vertical>a.button,
.woocommerce .btn-group-vertical>button.button,
.woocommerce .btn-group-vertical>input.button,
.woocommerce .btn-group-vertical>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a,
.btn-group-vertical>.wp-block-button__link,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.woocommerce #respond .btn-group-vertical>input#submit:not(:first-child),
.woocommerce .btn-group-vertical>a.button:not(:first-child),
.woocommerce .btn-group-vertical>button.button:not(:first-child),
.woocommerce .btn-group-vertical>input.button:not(:first-child),
.woocommerce .btn-group-vertical>#place_order:not(:first-child),
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:not(:first-child),
.woocommerce-cart-form .table tr .btn-group-vertical>.button:not(:first-child),
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:not(:first-child),
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:not(:first-child),
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:not(:first-child),
.btn-group-vertical>.wp-block-button__link:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.woocommerce #respond .btn-group-vertical>input#submit:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>a.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>button.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>input.button:not(:last-child):not(.dropdown-toggle),
.woocommerce .btn-group-vertical>#place_order:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:not(:last-child):not(.dropdown-toggle),
.woocommerce-cart-form .table tr .btn-group-vertical>.button:not(:last-child):not(.dropdown-toggle),
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input:not(:last-child):not(.dropdown-toggle),
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a:not(:last-child):not(.dropdown-toggle),
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.woocommerce #respond .btn-group-vertical>.btn-group:not(:last-child)>input#submit,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>a.button,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>button.button,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>input.button,
.woocommerce .btn-group-vertical>.btn-group:not(:last-child)>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-group:not(:last-child)>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-group:not(:last-child)>.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-group:not(:last-child)>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:last-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:last-child)>a,
.btn-group-vertical>.btn-group:not(:last-child)>.wp-block-button__link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.woocommerce #respond .btn-group-vertical>input#submit~.btn,
.woocommerce .btn-group-vertical>a.button~.btn,
.woocommerce .btn-group-vertical>button.button~.btn,
.woocommerce .btn-group-vertical>input.button~.btn,
.woocommerce .btn-group-vertical>#place_order~.btn,
.woocommerce #respond .btn-group-vertical>.btn~input#submit,
.woocommerce #respond .btn-group-vertical>input#submit~input#submit,
.woocommerce #respond .btn-group-vertical>a.button~input#submit,
.woocommerce #respond .btn-group-vertical>button.button~input#submit,
.woocommerce #respond .btn-group-vertical>input.button~input#submit,
.woocommerce #respond .btn-group-vertical>#place_order~input#submit,
.woocommerce .btn-group-vertical>.btn~a.button,
.woocommerce #respond .btn-group-vertical>input#submit~a.button,
.woocommerce .btn-group-vertical>a.button~a.button,
.woocommerce .btn-group-vertical>button.button~a.button,
.woocommerce .btn-group-vertical>input.button~a.button,
.woocommerce .btn-group-vertical>#place_order~a.button,
.woocommerce .btn-group-vertical>.btn~button.button,
.woocommerce #respond .btn-group-vertical>input#submit~button.button,
.woocommerce .btn-group-vertical>a.button~button.button,
.woocommerce .btn-group-vertical>button.button~button.button,
.woocommerce .btn-group-vertical>input.button~button.button,
.woocommerce .btn-group-vertical>#place_order~button.button,
.woocommerce .btn-group-vertical>.btn~input.button,
.woocommerce #respond .btn-group-vertical>input#submit~input.button,
.woocommerce .btn-group-vertical>a.button~input.button,
.woocommerce .btn-group-vertical>button.button~input.button,
.woocommerce .btn-group-vertical>input.button~input.button,
.woocommerce .btn-group-vertical>#place_order~input.button,
.woocommerce .btn-group-vertical>.btn~#place_order,
.woocommerce #respond .btn-group-vertical>input#submit~#place_order,
.woocommerce .btn-group-vertical>a.button~#place_order,
.woocommerce .btn-group-vertical>button.button~#place_order,
.woocommerce .btn-group-vertical>input.button~#place_order,
.woocommerce .btn-group-vertical>#place_order~#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~.btn,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond .btn-group-vertical>a.checkout-button~input#submit,
.woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~input#submit,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~a.button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~a.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~button.button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~button.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~input.button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~input.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.checkout-button~#place_order,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond .btn-group-vertical>input#submit~a.checkout-button,
.woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>input#submit~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>a.button~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>button.button~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>button.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>input.button~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>input.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .btn-group-vertical>#place_order~a.checkout-button,
.woocommerce .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>#place_order~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.button~.btn,
.woocommerce-cart-form .table tr .woocommerce #respond .btn-group-vertical>.button~input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr .btn-group-vertical>.button~input#submit,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~a.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~a.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~button.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~button.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~input.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~input.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>.button~#place_order,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>.button~#place_order,
.woocommerce-cart-form .table tr .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.button~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .btn-group-vertical>.button~a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn~.button,
.woocommerce-cart-form .table tr .woocommerce #respond .btn-group-vertical>input#submit~.button,
.woocommerce #respond .woocommerce-cart-form .table tr .btn-group-vertical>input#submit~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>a.button~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>a.button~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>button.button~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>button.button~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>input.button~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>input.button~.button,
.woocommerce-cart-form .table tr .woocommerce .btn-group-vertical>#place_order~.button,
.woocommerce .woocommerce-cart-form .table tr .btn-group-vertical>#place_order~.button,
.woocommerce-cart-form .table tr .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~.button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .btn-group-vertical>a.checkout-button~.button,
.woocommerce-cart-form .table tr .btn-group-vertical>.button~.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~.btn,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~input#submit,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~a.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~button.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~input.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~#place_order,
.woocommerce #review_form #respond .form-submit .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>input~a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond .form-submit .btn-group-vertical>input~a.checkout-button,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .btn-group-vertical>input~.button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond .form-submit .btn-group-vertical>input~.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input#submit~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>a.button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>button.button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input.button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>#place_order~input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~input,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond .form-submit .btn-group-vertical>a.checkout-button~input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .btn-group-vertical>.button~input,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond .form-submit .btn-group-vertical>.button~input,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~.btn,
.woocommerce .widget_shopping_cart .buttons #respond .btn-group-vertical>a~input#submit,
.woocommerce #respond .widget_shopping_cart .buttons .btn-group-vertical>a~input#submit,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~a.button,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~button.button,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~input.button,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~#place_order,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>a~.button,
.woocommerce-cart-form .table tr .woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~.button,
.woocommerce .widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>a~input,
.woocommerce #review_form #respond .form-submit .widget_shopping_cart .buttons .btn-group-vertical>a~input,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~.btn,
.woocommerce.widget_shopping_cart .buttons #respond .btn-group-vertical>a~input#submit,
.woocommerce.widget_shopping_cart #respond .buttons .btn-group-vertical>a~input#submit,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~a.button,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~button.button,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~input.button,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~#place_order,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>a~.button,
.woocommerce-cart-form .table tr .woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~.button,
.woocommerce.widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>a~input,
.woocommerce.widget_shopping_cart #review_form #respond .form-submit .buttons .btn-group-vertical>a~input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn~a,
.woocommerce .widget_shopping_cart .buttons #respond .btn-group-vertical>input#submit~a,
.woocommerce #respond .widget_shopping_cart .buttons .btn-group-vertical>input#submit~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a.button~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>button.button~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>input.button~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>#place_order~a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>.button~a,
.woocommerce-cart-form .table tr .woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.button~a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>input~a,
.woocommerce #review_form #respond .form-submit .widget_shopping_cart .buttons .btn-group-vertical>input~a,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn~a,
.woocommerce.widget_shopping_cart .buttons #respond .btn-group-vertical>input#submit~a,
.woocommerce.widget_shopping_cart #respond .buttons .btn-group-vertical>input#submit~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a.button~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>button.button~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>input.button~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>#place_order~a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .btn-group-vertical>.button~a,
.woocommerce-cart-form .table tr .woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.button~a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond .form-submit .btn-group-vertical>input~a,
.woocommerce.widget_shopping_cart #review_form #respond .form-submit .buttons .btn-group-vertical>input~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~a,
.btn-group-vertical>.wp-block-button__link~.btn,
.woocommerce #respond .btn-group-vertical>.wp-block-button__link~input#submit,
.woocommerce .btn-group-vertical>.wp-block-button__link~a.button,
.woocommerce .btn-group-vertical>.wp-block-button__link~button.button,
.woocommerce .btn-group-vertical>.wp-block-button__link~input.button,
.woocommerce .btn-group-vertical>.wp-block-button__link~#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.wp-block-button__link~a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.wp-block-button__link~.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.wp-block-button__link~input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.wp-block-button__link~a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.wp-block-button__link~a,
.btn-group-vertical>.btn~.wp-block-button__link,
.woocommerce #respond .btn-group-vertical>input#submit~.wp-block-button__link,
.woocommerce .btn-group-vertical>a.button~.wp-block-button__link,
.woocommerce .btn-group-vertical>button.button~.wp-block-button__link,
.woocommerce .btn-group-vertical>input.button~.wp-block-button__link,
.woocommerce .btn-group-vertical>#place_order~.wp-block-button__link,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button~.wp-block-button__link,
.woocommerce-cart-form .table tr .btn-group-vertical>.button~.wp-block-button__link,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>input~.wp-block-button__link,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>a~.wp-block-button__link,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>a~.wp-block-button__link,
.btn-group-vertical>.wp-block-button__link~.wp-block-button__link,
.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.woocommerce #respond .btn-group-vertical>.btn-group:not(:first-child)>input#submit,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>a.button,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>button.button,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>input.button,
.woocommerce .btn-group-vertical>.btn-group:not(:first-child)>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-group:not(:first-child)>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-vertical>.btn-group:not(:first-child)>.button,
.woocommerce #review_form #respond .form-submit .btn-group-vertical>.btn-group:not(:first-child)>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:first-child)>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-vertical>.btn-group:not(:first-child)>a,
.btn-group-vertical>.btn-group:not(:first-child)>.wp-block-button__link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #CC0D39;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2894, 98, 111, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .show>.nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235E626F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230f0d37'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #e6869c;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0rem rgba(204, 13, 57, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2371708f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2371708f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0rem rgba(204, 13, 57, 0.25);
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #CC0D39;
  --bs-pagination-active-border-color: #CC0D39;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 1rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.75rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge,
.woocommerce #respond input#submit .badge,
.woocommerce a.button .badge,
.woocommerce button.button .badge,
.woocommerce input.button .badge,
.woocommerce #place_order .badge,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button .badge,
.woocommerce-cart-form .table tr .button .badge,
.woocommerce #review_form #respond .form-submit input .badge,
.woocommerce .widget_shopping_cart .buttons a .badge,
.woocommerce.widget_shopping_cart .buttons a .badge,
.wp-block-button__link .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #CC0D39;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked>.progress {
  overflow: visible;
}

.progress-stacked>.progress>.progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #CC0D39;
  --bs-list-group-active-border-color: #CC0D39;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
}

.list-group-item-primary.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-primary-text);
  --bs-list-group-active-border-color: var(--bs-primary-text);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
}

.list-group-item-secondary.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-secondary-text);
  --bs-list-group-active-border-color: var(--bs-secondary-text);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
}

.list-group-item-success.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-success-text);
  --bs-list-group-active-border-color: var(--bs-success-text);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
}

.list-group-item-info.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-info-text);
  --bs-list-group-active-border-color: var(--bs-info-text);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
}

.list-group-item-warning.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-warning-text);
  --bs-list-group-active-border-color: var(--bs-warning-text);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
}

.list-group-item-danger.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-danger-text);
  --bs-list-group-active-border-color: var(--bs-danger-text);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
}

.list-group-item-light.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-light-text);
  --bs-list-group-active-border-color: var(--bs-light-text);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
}

.list-group-item-dark.list-group-item-action:active {
  --bs-list-group-active-color: var(--bs-emphasis-color);
  --bs-list-group-active-bg: var(--bs-dark-text);
  --bs-list-group-active-border-color: var(--bs-dark-text);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0rem rgba(204, 13, 57, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 30px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.6;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: #000;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme=dark] .carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.6;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1439.98) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1440) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}




.dropbtn {
  color: white;
  padding: 13px;
  font-size: 30px;
  height: 40px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 120px;

}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1 !important;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content {

  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-item {

  width: auto;
  height: 30px;
  text-align: center;
}


.dropdown-item:hover {}


.custom-toast-progress {
  background-color: rgb(166, 255, 0) !important;
}

.custom-toast-progress-background {
  background-color: rgb(243, 242, 241) !important;
  border-radius: 5px;
}


.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before,
.woocommerce #respond input.placeholder#submit::before,
.woocommerce a.placeholder.button::before,
.woocommerce button.placeholder.button::before,
.woocommerce input.placeholder.button::before,
.woocommerce .placeholder#place_order::before,
.woocommerce-cart .wc-proceed-to-checkout a.placeholder.checkout-button::before,
.woocommerce-cart-form .table tr .placeholder.button::before,
.woocommerce #review_form #respond .form-submit input.placeholder::before,
.woocommerce .widget_shopping_cart .buttons a.placeholder::before,
.woocommerce.widget_shopping_cart .buttons a.placeholder::before,
.placeholder.wp-block-button__link::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(204, 13, 57, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #ffffff !important;
  background-color: RGBA(0, 0, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(49, 165, 109, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(1, 148, 217, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(231, 150, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #ffffff !important;
  background-color: RGBA(210, 54, 54, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 250, 243, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(68, 68, 68, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #CC0D39 !important;
}

.link-primary:hover,
.link-primary:focus {
  color: #a30a2e !important;
}

.link-secondary {
  color: #000 !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: black !important;
}

.link-success {
  color: #31A56D !important;
}

.link-success:hover,
.link-success:focus {
  color: #5ab78a !important;
}

.link-info {
  color: #0194D9 !important;
}

.link-info:hover,
.link-info:focus {
  color: #34a9e1 !important;
}

.link-warning {
  color: #E79600 !important;
}

.link-warning:hover,
.link-warning:focus {
  color: #ecab33 !important;
}

.link-danger {
  color: #D23636 !important;
}

.link-danger:hover,
.link-danger:focus {
  color: #a82b2b !important;
}

.link-light {
  color: #FFFAF3 !important;
}

.link-light:hover,
.link-light:focus {
  color: #fffbf5 !important;
}

.link-dark {
  color: #444444 !important;
}

.link-dark:hover,
.link-dark:focus {
  color: #363636 !important;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.125rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(151, 151, 159, 0.39) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-emphasis {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-emphasis-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-2xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-2xl) !important;
  border-top-left-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 1.75rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
  color: #5E626F;
  font-size: 1rem;
  font-family: var(--font-family-base);
}

body.fixed {
  overflow: hidden;
  position: fixed;
}

@media only screen and (max-width: 575px) {
  body {
    font-size: 14px;
  }
}

p {
  font-family: var(--font-family-base);
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--font-family-title);
  font-weight: 600;
  color: var(--title);
  line-height: 1.4;
}

h1,
.h1 {
  font-size: 2.5rem;
  line-height: 1.2;
}

@media only screen and (max-width: 1199px) {

  h1,
  .h1 {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 767px) {

  h1,
  .h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 575px) {

  h1,
  .h1 {
    font-size: 1.7rem;
  }
}

h2,
.h2 {
  font-size: 1.75rem;
}

@media only screen and (max-width: 1199px) {

  h2,
  .h2 {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 767px) {

  h2,
  .h2 {
    font-size: 1.5rem;
  }
}

h3,
.h3 {
  font-size: 1.5rem;
}

@media only screen and (max-width: 767px) {

  h3,
  .h3 {
    font-size: 1.625rem;
  }
}

h4,
.h4 {
  font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {

  h4,
  .h4 {
    font-size: 1.25rem;
  }
}

h5,
.h5 {
  font-size: 1.125rem;
}

@media only screen and (max-width: 767px) {

  h5,
  .h5 {
    font-size: 1.125rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
}

@media only screen and (max-width: 767px) {

  h6,
  .h6 {
    font-size: 1rem;
  }
}

ul {
  padding: 0;
  margin: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}



a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

a:hover {
  text-decoration: none;
  outline: none;
  color: var(--primary);
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-blue {
  background-color: #060340 !important;
}

b,
strong,
.strong {
  font-weight: 700;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px;
}

#main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (max-width: 1280px) {

  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 575px) {

  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.bg-grey {
  background-color: var(--grey);
}

.text-black {
  color: #071c35;
}

::selection {
  color: #ffffff;
  background: var(--primary);
}

.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 100000;
}

/* Flaticon */
[class*=flaticon-] {
  font-family: "Flaticon";
}

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px;
  }
}

.gradient {
  background-image: var(--gradient);
}

.gradient-sec {
  background-image: var(--gradient-sec);
}

.show-focus-outline .menu-btn:focus,
.show-focus-outline .search-link:focus,
.show-focus-outline .quik-search-remove:focus,
.show-focus-outline button:focus,
.show-focus-outline select:focus,
.show-focus-outline input:focus,
.show-focus-outline a:focus {
  outline: 2px solid red !important;
}

@media (max-width: 991px) {
  .show-focus-outline .nav a>i:focus {
    outline: 2px solid red !important;
  }
}

.show-focus-outline li:focus-within>.mega-menu {
  opacity: 1 !important;
  visibility: visible !important;
  margin-top: 0 !important;
}

.show-focus-outline .sub-menu-down a:focus {
  outline: 2px solid #000 !important;
}

.show-focus-outline .sub-menu-down a:after:focus {
  outline: 2px solid #000 !important;
}

#pointer-dot {
  left: -4px;
  top: -4px;
  width: 12px;
  height: 12px;
  position: fixed;
  border-radius: 10px;
  z-index: 999998;
  pointer-events: none;
  transition: border-color 0.5s;
  background: #333;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 575px) {
  #pointer-dot {
    display: none;
  }
}

#pointer-ring {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  display: block;
  border: 1px solid var(--primary) !important;
  position: fixed;
  border-radius: 100px;
  z-index: 999999;
  pointer-events: none;
  transition: width 0.3s, height 0.3s;
}

@media only screen and (max-width: 575px) {
  #pointer-ring {
    display: none;
  }
}

#pointer-ring.active {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

#pointer-ring.active+#pointer-dot {
  opacity: 0;
}

.content-inner {
  padding-top: 50px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 1480px) {
  .content-inner {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .content-inner {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .content-inner {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.content-inner-1 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1480px) {
  .content-inner-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .content-inner-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .content-inner-1 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.content-inner-2 {
  padding-top: 100px;
  padding-bottom: 0;
}

@media only screen and (max-width: 1480px) {
  .content-inner-2 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .content-inner-2 {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .content-inner-2 {
    padding-top: 40px;
  }
}

.content-inner-3 {
  padding-top: 70px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .content-inner-3 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.mfp-bg {
  z-index: 99999;
}

.mfp-wrap {
  z-index: 100000;
}

.rounded {
  border-radius: var(--border-radius-base) !important;
}

.half-shape-top-w {
  position: relative;
  z-index: 2;
}

.half-shape-top-w:after {
  content: "";
  height: 50%;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: -1;
}

.half-shape-bottom-w {
  position: relative;
  z-index: 0;
}

.half-shape-bottom-w:after {
  content: "";
  height: 50%;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: -1;
}

.height-md {
  height: 370px;
}

.height-lg {
  height: 600px;
}

@media only screen and (max-width: 575px) {
  .height-lg {
    height: 400px;
  }
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-bottom {
  border-bottom: 1px solid #eee !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.row.dz-row-left {
  display: table;
}

.row.dz-row-left>[class*=col-] {
  float: left;
}

.row.dz-row-right {
  display: table;
}

.row.dz-row-right>[class*=col-] {
  float: right;
}

.nav-inline li {
  display: inline-block;
  margin-right: 15px;
  padding: 0.25rem 0 !important;
}

.lg-toolbar .lg-icon:hover {
  color: var(--primary);
}

.bg-parallax {
  background-attachment: fixed;
}

img::selection {
  background: none;
}

.text-underline {
  text-decoration: underline !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(223, 223, 223, 0.9);
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

* {
  scrollbar-color: rgba(223, 223, 223, 0.9) rgba(255, 255, 255, 0);
  scrollbar-width: thin;
}

@supports (-ms-ime-align: auto) {
  * {
    scrollbar-color: rgba(223, 223, 223, 0.9) rgba(255, 255, 255, 0);
    scrollbar-width: thin;
  }
}

.preloader-wrapper-1 {
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: all 1s ease 0s;
  background-position: center center;
  background-size: 150px;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);
  background: linear-gradient(180deg, #FEEB9D 0%, #FFFAF3 100%);
}

.preloader-wrapper-1 img {
  width: 220px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loader {
  margin: 30px auto 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.load-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-circle .circle-2 {
  width: 120px;
  height: 120px;
  position: absolute;
  border: 2px solid var(--rgba-primary-4);
  opacity: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-circle .circle-2 img {
  max-width: 55px;
}

.load-circle .circle-2::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid var(--primary);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  animation: load-circle 1s ease-in-out infinite;
}

.load-circle .circle-2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid var(--primary);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  animation: load-circle2 1s ease-in-out infinite;
}

@keyframes load-circle {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    width: 120px;
    height: 120px;
    opacity: 0;
  }

  5% {
    width: 120px;
    height: 120px;
    opacity: 1;
  }

  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}

@keyframes load-circle2 {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    width: 120px;
    height: 120px;
    opacity: 0;
  }

  5% {
    width: 120px;
    height: 120px;
    opacity: 1;
  }

  100% {
    width: 160px;
    height: 160px;
    opacity: 0;
  }
}

.preloader-wrapper-2 {
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: all 1s ease 0s;
  background-position: center center;
  background-size: 150px;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);
  background: linear-gradient(180deg, #FEEB9D 0%, #FFFAF3 100%);
}

.preloader-wrapper-2 img {
  width: 220px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.preloader-wrapper-2 .loader {
  width: 150px;
  height: 50px;
  margin: 50px auto 0;
  border-bottom: 4px solid var(--secondary);
  position: relative;
  animation: animate 3s infinite linear;
}

.preloader-wrapper-2 .loader:before {
  content: "";
  background: var(--primary);
  width: 40px;
  height: 45px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  animation: animate1 3s infinite linear;
}

@keyframes animate {

  0%,
  100% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(20deg);
  }
}

@keyframes animate1 {

  0%,
  100% {
    transform: rotate(0);
    left: 0;
  }

  50% {
    transform: rotate(200deg);
    left: calc(100% - 40px);
  }
}

.preloader-wrapper-3 {
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: all 1s ease 0s;
  background-position: center center;
  background-size: 150px;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);
  background: linear-gradient(180deg, #FEEB9D 0%, #FFFAF3 100%);
}

.preloader-wrapper-3 img {
  width: 220px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.preloader-wrapper-3 .loader {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  border: 20px solid transparent;
  border-bottom-color: var(--secondary);
  border-right-color: var(--secondary);
  transform: rotate(45deg);
  position: relative;
  animation: animate2 3.5s ease-in-out infinite;
}

.preloader-wrapper-3 .loader .ball {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: animateball 3.5s ease-in-out infinite;
}

.preloader-wrapper-3 .loader .ball:before {
  content: "";
  background: var(--primary);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translateY(-50%);
  position: absolute;
  bottom: 50%;
  right: 15px;
}

@keyframes animate2 {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes animateball {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.preloader-wrapper-4 {
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: all 1s ease 0s;
  background-position: center center;
  background-size: 150px;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);
  background: #fff;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.font-50 {
  font-size: 50px;
}

.font-55 {
  font-size: 55px;
}

.font-60 {
  font-size: 60px;
}

.font-70 {
  font-size: 70px;
}

.font-75 {
  font-size: 75px;
}

.font-80 {
  font-size: 80px;
}

.font-90 {
  font-size: 90px;
}

.font-100 {
  font-size: 100px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.p-a0 {
  padding: 0px;
}

.p-t0 {
  padding-top: 0px;
}

.p-b0 {
  padding-bottom: 0px;
}

.p-l0 {
  padding-left: 0px;
}

.p-r0 {
  padding-right: 0px;
}

.p-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.m-a0 {
  margin: 0px;
}

.m-t0 {
  margin-top: 0px;
}

.m-b0 {
  margin-bottom: 0px;
}

.m-l0 {
  margin-left: 0px;
}

.m-r0 {
  margin-right: 0px;
}

.m-lr0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.p-a5 {
  padding: 5px;
}

.p-t5 {
  padding-top: 5px;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-l5 {
  padding-left: 5px;
}

.p-r5 {
  padding-right: 5px;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.m-a5 {
  margin: 5px;
}

.m-t5 {
  margin-top: 5px;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-l5 {
  margin-left: 5px;
}

.m-r5 {
  margin-right: 5px;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-t10 {
  padding-top: 10px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-l10 {
  padding-left: 10px;
}

.p-r10 {
  padding-right: 10px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.m-a10 {
  margin: 10px;
}

.m-t10 {
  margin-top: 10px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-l10 {
  margin-left: 10px;
}

.m-r10 {
  margin-right: 10px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-t15 {
  padding-top: 15px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-l15 {
  padding-left: 15px;
}

.p-r15 {
  padding-right: 15px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.m-a15 {
  margin: 15px;
}

.m-t15 {
  margin-top: 15px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-l15 {
  margin-left: 15px;
}

.m-r15 {
  margin-right: 15px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-t20 {
  padding-top: 20px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-l20 {
  padding-left: 20px;
}

.p-r20 {
  padding-right: 20px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.m-a20 {
  margin: 20px;
}

.m-t20 {
  margin-top: 20px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-l20 {
  margin-left: 20px;
}

.m-r20 {
  margin-right: 20px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-t25 {
  padding-top: 25px;
}

.p-b25 {
  padding-bottom: 25px;
}

.p-l25 {
  padding-left: 25px;
}

.p-r25 {
  padding-right: 25px;
}

.p-lr25 {
  padding-left: 25px;
  padding-right: 25px;
}

.p-tb25 {
  padding-bottom: 25px;
  padding-top: 25px;
}

.m-a25 {
  margin: 25px;
}

.m-t25 {
  margin-top: 25px;
}

.m-b25 {
  margin-bottom: 25px;
}

.m-l25 {
  margin-left: 25px;
}

.m-r25 {
  margin-right: 25px;
}

.m-lr25 {
  margin-left: 25px;
  margin-right: 25px;
}

.m-tb25 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-t30 {
  padding-top: 30px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-l30 {
  padding-left: 30px;
}

.p-r30 {
  padding-right: 30px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.m-a30 {
  margin: 30px;
}

.m-t30 {
  margin-top: 30px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-l30 {
  margin-left: 30px;
}

.m-r30 {
  margin-right: 30px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.p-a35 {
  padding: 35px;
}

.p-t35 {
  padding-top: 35px;
}

.p-b35 {
  padding-bottom: 35px;
}

.p-l35 {
  padding-left: 35px;
}

.p-r35 {
  padding-right: 35px;
}

.p-lr35 {
  padding-left: 35px;
  padding-right: 35px;
}

.p-tb35 {
  padding-bottom: 35px;
  padding-top: 35px;
}

.m-a35 {
  margin: 35px;
}

.m-t35 {
  margin-top: 35px;
}

.m-b35 {
  margin-bottom: 35px;
}

.m-l35 {
  margin-left: 35px;
}

.m-r35 {
  margin-right: 35px;
}

.m-lr35 {
  margin-left: 35px;
  margin-right: 35px;
}

.m-tb35 {
  margin-bottom: 35px;
  margin-top: 35px;
}

.p-a40 {
  padding: 40px;
}

.p-t40 {
  padding-top: 40px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-l40 {
  padding-left: 40px;
}

.p-r40 {
  padding-right: 40px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.m-a40 {
  margin: 40px;
}

.m-t40 {
  margin-top: 40px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-l40 {
  margin-left: 40px;
}

.m-r40 {
  margin-right: 40px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.p-a45 {
  padding: 45px;
}

.p-t45 {
  padding-top: 45px;
}

.p-b45 {
  padding-bottom: 45px;
}

.p-l45 {
  padding-left: 45px;
}

.p-r45 {
  padding-right: 45px;
}

.p-lr45 {
  padding-left: 45px;
  padding-right: 45px;
}

.p-tb45 {
  padding-bottom: 45px;
  padding-top: 45px;
}

.m-a45 {
  margin: 45px;
}

.m-t45 {
  margin-top: 45px;
}

.m-b45 {
  margin-bottom: 45px;
}

.m-l45 {
  margin-left: 45px;
}

.m-r45 {
  margin-right: 45px;
}

.m-lr45 {
  margin-left: 45px;
  margin-right: 45px;
}

.m-tb45 {
  margin-bottom: 45px;
  margin-top: 45px;
}

.p-a50 {
  padding: 50px;
}

.p-t50 {
  padding-top: 50px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-l50 {
  padding-left: 50px;
}

.p-r50 {
  padding-right: 50px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.m-a50 {
  margin: 50px;
}

.m-t50 {
  margin-top: 50px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-l50 {
  margin-left: 50px;
}

.m-r50 {
  margin-right: 50px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.p-a55 {
  padding: 55px;
}

.p-t55 {
  padding-top: 55px;
}

.p-b55 {
  padding-bottom: 55px;
}

.p-l55 {
  padding-left: 55px;
}

.p-r55 {
  padding-right: 55px;
}

.p-lr55 {
  padding-left: 55px;
  padding-right: 55px;
}

.p-tb55 {
  padding-bottom: 55px;
  padding-top: 55px;
}

.m-a55 {
  margin: 55px;
}

.m-t55 {
  margin-top: 55px;
}

.m-b55 {
  margin-bottom: 55px;
}

.m-l55 {
  margin-left: 55px;
}

.m-r55 {
  margin-right: 55px;
}

.m-lr55 {
  margin-left: 55px;
  margin-right: 55px;
}

.m-tb55 {
  margin-bottom: 55px;
  margin-top: 55px;
}

.p-a60 {
  padding: 60px;
}

.p-t60 {
  padding-top: 60px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-l60 {
  padding-left: 60px;
}

.p-r60 {
  padding-right: 60px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.m-a60 {
  margin: 60px;
}

.m-t60 {
  margin-top: 60px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-l60 {
  margin-left: 60px;
}

.m-r60 {
  margin-right: 60px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.p-a65 {
  padding: 65px;
}

.p-t65 {
  padding-top: 65px;
}

.p-b65 {
  padding-bottom: 65px;
}

.p-l65 {
  padding-left: 65px;
}

.p-r65 {
  padding-right: 65px;
}

.p-lr65 {
  padding-left: 65px;
  padding-right: 65px;
}

.p-tb65 {
  padding-bottom: 65px;
  padding-top: 65px;
}

.m-a65 {
  margin: 65px;
}

.m-t65 {
  margin-top: 65px;
}

.m-b65 {
  margin-bottom: 65px;
}

.m-l65 {
  margin-left: 65px;
}

.m-r65 {
  margin-right: 65px;
}

.m-lr65 {
  margin-left: 65px;
  margin-right: 65px;
}

.m-tb65 {
  margin-bottom: 65px;
  margin-top: 65px;
}

.p-a70 {
  padding: 70px;
}

.p-t70 {
  padding-top: 70px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-l70 {
  padding-left: 70px;
}

.p-r70 {
  padding-right: 70px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.m-a70 {
  margin: 70px;
}

.m-t70 {
  margin-top: 70px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-l70 {
  margin-left: 70px;
}

.m-r70 {
  margin-right: 70px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.p-a75 {
  padding: 75px;
}

.p-t75 {
  padding-top: 75px;
}

.p-b75 {
  padding-bottom: 75px;
}

.p-l75 {
  padding-left: 75px;
}

.p-r75 {
  padding-right: 75px;
}

.p-lr75 {
  padding-left: 75px;
  padding-right: 75px;
}

.p-tb75 {
  padding-bottom: 75px;
  padding-top: 75px;
}

.m-a75 {
  margin: 75px;
}

.m-t75 {
  margin-top: 75px;
}

.m-b75 {
  margin-bottom: 75px;
}

.m-l75 {
  margin-left: 75px;
}

.m-r75 {
  margin-right: 75px;
}

.m-lr75 {
  margin-left: 75px;
  margin-right: 75px;
}

.m-tb75 {
  margin-bottom: 75px;
  margin-top: 75px;
}

.p-a80 {
  padding: 80px;
}

.p-t80 {
  padding-top: 80px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-l80 {
  padding-left: 80px;
}

.p-r80 {
  padding-right: 80px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.m-a80 {
  margin: 80px;
}

.m-t80 {
  margin-top: 80px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-l80 {
  margin-left: 80px;
}

.m-r80 {
  margin-right: 80px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.p-a85 {
  padding: 85px;
}

.p-t85 {
  padding-top: 85px;
}

.p-b85 {
  padding-bottom: 85px;
}

.p-l85 {
  padding-left: 85px;
}

.p-r85 {
  padding-right: 85px;
}

.p-lr85 {
  padding-left: 85px;
  padding-right: 85px;
}

.p-tb85 {
  padding-bottom: 85px;
  padding-top: 85px;
}

.m-a85 {
  margin: 85px;
}

.m-t85 {
  margin-top: 85px;
}

.m-b85 {
  margin-bottom: 85px;
}

.m-l85 {
  margin-left: 85px;
}

.m-r85 {
  margin-right: 85px;
}

.m-lr85 {
  margin-left: 85px;
  margin-right: 85px;
}

.m-tb85 {
  margin-bottom: 85px;
  margin-top: 85px;
}

.p-a90 {
  padding: 90px;
}

.p-t90 {
  padding-top: 90px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-l90 {
  padding-left: 90px;
}

.p-r90 {
  padding-right: 90px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.m-a90 {
  margin: 90px;
}

.m-t90 {
  margin-top: 90px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-l90 {
  margin-left: 90px;
}

.m-r90 {
  margin-right: 90px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.p-a95 {
  padding: 95px;
}

.p-t95 {
  padding-top: 95px;
}

.p-b95 {
  padding-bottom: 95px;
}

.p-l95 {
  padding-left: 95px;
}

.p-r95 {
  padding-right: 95px;
}

.p-lr95 {
  padding-left: 95px;
  padding-right: 95px;
}

.p-tb95 {
  padding-bottom: 95px;
  padding-top: 95px;
}

.m-a95 {
  margin: 95px;
}

.m-t95 {
  margin-top: 95px;
}

.m-b95 {
  margin-bottom: 95px;
}

.m-l95 {
  margin-left: 95px;
}

.m-r95 {
  margin-right: 95px;
}

.m-lr95 {
  margin-left: 95px;
  margin-right: 95px;
}

.m-tb95 {
  margin-bottom: 95px;
  margin-top: 95px;
}

.p-a100 {
  padding: 100px;
}

.p-t100 {
  padding-top: 100px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-l100 {
  padding-left: 100px;
}

.p-r100 {
  padding-right: 100px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.m-a100 {
  margin: 100px;
}

.m-t100 {
  margin-top: 100px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-l100 {
  margin-left: 100px;
}

.m-r100 {
  margin-right: 100px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media only screen and (max-width: 1200px) {
  .m-lg-t0 {
    margin-top: 0px;
  }

  .m-lg-b0 {
    margin-bottom: 0px;
  }

  .m-lg-t5 {
    margin-top: 5px;
  }

  .m-lg-b5 {
    margin-bottom: 5px;
  }

  .m-lg-t10 {
    margin-top: 10px;
  }

  .m-lg-b10 {
    margin-bottom: 10px;
  }

  .m-lg-t15 {
    margin-top: 15px;
  }

  .m-lg-b15 {
    margin-bottom: 15px;
  }

  .m-lg-t20 {
    margin-top: 20px;
  }

  .m-lg-b20 {
    margin-bottom: 20px;
  }

  .m-lg-t25 {
    margin-top: 25px;
  }

  .m-lg-b25 {
    margin-bottom: 25px;
  }

  .m-lg-t30 {
    margin-top: 30px;
  }

  .m-lg-b30 {
    margin-bottom: 30px;
  }

  .m-lg-t35 {
    margin-top: 35px;
  }

  .m-lg-b35 {
    margin-bottom: 35px;
  }

  .m-lg-t40 {
    margin-top: 40px;
  }

  .m-lg-b40 {
    margin-bottom: 40px;
  }

  .m-lg-t45 {
    margin-top: 45px;
  }

  .m-lg-b45 {
    margin-bottom: 45px;
  }

  .m-lg-t50 {
    margin-top: 50px;
  }

  .m-lg-b50 {
    margin-bottom: 50px;
  }

  .m-lg-t55 {
    margin-top: 55px;
  }

  .m-lg-b55 {
    margin-bottom: 55px;
  }

  .m-lg-t60 {
    margin-top: 60px;
  }

  .m-lg-b60 {
    margin-bottom: 60px;
  }

  .m-lg-t65 {
    margin-top: 65px;
  }

  .m-lg-b65 {
    margin-bottom: 65px;
  }

  .m-lg-t70 {
    margin-top: 70px;
  }

  .m-lg-b70 {
    margin-bottom: 70px;
  }

  .m-lg-t75 {
    margin-top: 75px;
  }

  .m-lg-b75 {
    margin-bottom: 75px;
  }

  .m-lg-t80 {
    margin-top: 80px;
  }

  .m-lg-b80 {
    margin-bottom: 80px;
  }

  .m-lg-t85 {
    margin-top: 85px;
  }

  .m-lg-b85 {
    margin-bottom: 85px;
  }

  .m-lg-t90 {
    margin-top: 90px;
  }

  .m-lg-b90 {
    margin-bottom: 90px;
  }

  .m-lg-t95 {
    margin-top: 95px;
  }

  .m-lg-b95 {
    margin-bottom: 95px;
  }

  .m-lg-t100 {
    margin-top: 100px;
  }

  .m-lg-b100 {
    margin-bottom: 100px;
  }

  .m-lg-r0 {
    margin-right: 0;
  }

  .m-lg-l0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0px;
  }

  .m-md-b0 {
    margin-bottom: 0px;
  }

  .m-md-t5 {
    margin-top: 5px;
  }

  .m-md-b5 {
    margin-bottom: 5px;
  }

  .m-md-t10 {
    margin-top: 10px;
  }

  .m-md-b10 {
    margin-bottom: 10px;
  }

  .m-md-t15 {
    margin-top: 15px;
  }

  .m-md-b15 {
    margin-bottom: 15px;
  }

  .m-md-t20 {
    margin-top: 20px;
  }

  .m-md-b20 {
    margin-bottom: 20px;
  }

  .m-md-t25 {
    margin-top: 25px;
  }

  .m-md-b25 {
    margin-bottom: 25px;
  }

  .m-md-t30 {
    margin-top: 30px;
  }

  .m-md-b30 {
    margin-bottom: 30px;
  }

  .m-md-t35 {
    margin-top: 35px;
  }

  .m-md-b35 {
    margin-bottom: 35px;
  }

  .m-md-t40 {
    margin-top: 40px;
  }

  .m-md-b40 {
    margin-bottom: 40px;
  }

  .m-md-t45 {
    margin-top: 45px;
  }

  .m-md-b45 {
    margin-bottom: 45px;
  }

  .m-md-t50 {
    margin-top: 50px;
  }

  .m-md-b50 {
    margin-bottom: 50px;
  }

  .m-md-t55 {
    margin-top: 55px;
  }

  .m-md-b55 {
    margin-bottom: 55px;
  }

  .m-md-t60 {
    margin-top: 60px;
  }

  .m-md-b60 {
    margin-bottom: 60px;
  }

  .m-md-t65 {
    margin-top: 65px;
  }

  .m-md-b65 {
    margin-bottom: 65px;
  }

  .m-md-t70 {
    margin-top: 70px;
  }

  .m-md-b70 {
    margin-bottom: 70px;
  }

  .m-md-t75 {
    margin-top: 75px;
  }

  .m-md-b75 {
    margin-bottom: 75px;
  }

  .m-md-t80 {
    margin-top: 80px;
  }

  .m-md-b80 {
    margin-bottom: 80px;
  }

  .m-md-t85 {
    margin-top: 85px;
  }

  .m-md-b85 {
    margin-bottom: 85px;
  }

  .m-md-t90 {
    margin-top: 90px;
  }

  .m-md-b90 {
    margin-bottom: 90px;
  }

  .m-md-t95 {
    margin-top: 95px;
  }

  .m-md-b95 {
    margin-bottom: 95px;
  }

  .m-md-t100 {
    margin-top: 100px;
  }

  .m-md-b100 {
    margin-bottom: 100px;
  }

  .m-md-r0 {
    margin-right: 0;
  }

  .m-md-l0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0px;
  }

  .m-sm-b0 {
    margin-bottom: 0px;
  }

  .m-sm-t5 {
    margin-top: 5px;
  }

  .m-sm-b5 {
    margin-bottom: 5px;
  }

  .m-sm-t10 {
    margin-top: 10px;
  }

  .m-sm-b10 {
    margin-bottom: 10px;
  }

  .m-sm-t15 {
    margin-top: 15px;
  }

  .m-sm-b15 {
    margin-bottom: 15px;
  }

  .m-sm-t20 {
    margin-top: 20px;
  }

  .m-sm-b20 {
    margin-bottom: 20px;
  }

  .m-sm-t25 {
    margin-top: 25px;
  }

  .m-sm-b25 {
    margin-bottom: 25px;
  }

  .m-sm-t30 {
    margin-top: 30px;
  }

  .m-sm-b30 {
    margin-bottom: 30px;
  }

  .m-sm-t35 {
    margin-top: 35px;
  }

  .m-sm-b35 {
    margin-bottom: 35px;
  }

  .m-sm-t40 {
    margin-top: 40px;
  }

  .m-sm-b40 {
    margin-bottom: 40px;
  }

  .m-sm-t45 {
    margin-top: 45px;
  }

  .m-sm-b45 {
    margin-bottom: 45px;
  }

  .m-sm-t50 {
    margin-top: 50px;
  }

  .m-sm-b50 {
    margin-bottom: 50px;
  }

  .m-sm-t55 {
    margin-top: 55px;
  }

  .m-sm-b55 {
    margin-bottom: 55px;
  }

  .m-sm-t60 {
    margin-top: 60px;
  }

  .m-sm-b60 {
    margin-bottom: 60px;
  }

  .m-sm-t65 {
    margin-top: 65px;
  }

  .m-sm-b65 {
    margin-bottom: 65px;
  }

  .m-sm-t70 {
    margin-top: 70px;
  }

  .m-sm-b70 {
    margin-bottom: 70px;
  }

  .m-sm-t75 {
    margin-top: 75px;
  }

  .m-sm-b75 {
    margin-bottom: 75px;
  }

  .m-sm-t80 {
    margin-top: 80px;
  }

  .m-sm-b80 {
    margin-bottom: 80px;
  }

  .m-sm-t85 {
    margin-top: 85px;
  }

  .m-sm-b85 {
    margin-bottom: 85px;
  }

  .m-sm-t90 {
    margin-top: 90px;
  }

  .m-sm-b90 {
    margin-bottom: 90px;
  }

  .m-sm-t95 {
    margin-top: 95px;
  }

  .m-sm-b95 {
    margin-bottom: 95px;
  }

  .m-sm-t100 {
    margin-top: 100px;
  }

  .m-sm-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .m-xs-t0 {
    margin-top: 0px;
  }

  .m-xs-b0 {
    margin-bottom: 0px;
  }

  .p-xs-b0 {
    padding-bottom: 0px;
  }

  .m-xs-t5 {
    margin-top: 5px;
  }

  .m-xs-b5 {
    margin-bottom: 5px;
  }

  .p-xs-b5 {
    padding-bottom: 5px;
  }

  .m-xs-t10 {
    margin-top: 10px;
  }

  .m-xs-b10 {
    margin-bottom: 10px;
  }

  .p-xs-b10 {
    padding-bottom: 10px;
  }

  .m-xs-t15 {
    margin-top: 15px;
  }

  .m-xs-b15 {
    margin-bottom: 15px;
  }

  .p-xs-b15 {
    padding-bottom: 15px;
  }

  .m-xs-t20 {
    margin-top: 20px;
  }

  .m-xs-b20 {
    margin-bottom: 20px;
  }

  .p-xs-b20 {
    padding-bottom: 20px;
  }

  .m-xs-t25 {
    margin-top: 25px;
  }

  .m-xs-b25 {
    margin-bottom: 25px;
  }

  .p-xs-b25 {
    padding-bottom: 25px;
  }

  .m-xs-t30 {
    margin-top: 30px;
  }

  .m-xs-b30 {
    margin-bottom: 30px;
  }

  .p-xs-b30 {
    padding-bottom: 30px;
  }

  .m-xs-t35 {
    margin-top: 35px;
  }

  .m-xs-b35 {
    margin-bottom: 35px;
  }

  .p-xs-b35 {
    padding-bottom: 35px;
  }

  .m-xs-t40 {
    margin-top: 40px;
  }

  .m-xs-b40 {
    margin-bottom: 40px;
  }

  .p-xs-b40 {
    padding-bottom: 40px;
  }

  .m-xs-t45 {
    margin-top: 45px;
  }

  .m-xs-b45 {
    margin-bottom: 45px;
  }

  .p-xs-b45 {
    padding-bottom: 45px;
  }

  .m-xs-t50 {
    margin-top: 50px;
  }

  .m-xs-b50 {
    margin-bottom: 50px;
  }

  .p-xs-b50 {
    padding-bottom: 50px;
  }

  .m-xs-t55 {
    margin-top: 55px;
  }

  .m-xs-b55 {
    margin-bottom: 55px;
  }

  .p-xs-b55 {
    padding-bottom: 55px;
  }

  .m-xs-t60 {
    margin-top: 60px;
  }

  .m-xs-b60 {
    margin-bottom: 60px;
  }

  .p-xs-b60 {
    padding-bottom: 60px;
  }

  .m-xs-t65 {
    margin-top: 65px;
  }

  .m-xs-b65 {
    margin-bottom: 65px;
  }

  .p-xs-b65 {
    padding-bottom: 65px;
  }

  .m-xs-t70 {
    margin-top: 70px;
  }

  .m-xs-b70 {
    margin-bottom: 70px;
  }

  .p-xs-b70 {
    padding-bottom: 70px;
  }

  .m-xs-t75 {
    margin-top: 75px;
  }

  .m-xs-b75 {
    margin-bottom: 75px;
  }

  .p-xs-b75 {
    padding-bottom: 75px;
  }

  .m-xs-t80 {
    margin-top: 80px;
  }

  .m-xs-b80 {
    margin-bottom: 80px;
  }

  .p-xs-b80 {
    padding-bottom: 80px;
  }

  .m-xs-t85 {
    margin-top: 85px;
  }

  .m-xs-b85 {
    margin-bottom: 85px;
  }

  .p-xs-b85 {
    padding-bottom: 85px;
  }

  .m-xs-t90 {
    margin-top: 90px;
  }

  .m-xs-b90 {
    margin-bottom: 90px;
  }

  .p-xs-b90 {
    padding-bottom: 90px;
  }

  .m-xs-t95 {
    margin-top: 95px;
  }

  .m-xs-b95 {
    margin-bottom: 95px;
  }

  .p-xs-b95 {
    padding-bottom: 95px;
  }

  .m-xs-t100 {
    margin-top: 100px;
  }

  .m-xs-b100 {
    margin-bottom: 100px;
  }

  .p-xs-b100 {
    padding-bottom: 100px;
  }
}

.max-w50 {
  max-width: 50px;
}

.max-w60 {
  max-width: 60px;
}

.max-w80 {
  max-width: 80px;
}

.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

.bg-img-fix {
  background-attachment: unset;
  background-size: cover;
}

.full-img {
  width: 100%;
}

.sticky-sm-top,
.sticky-lg-top,
.sticky-xl-top,
.sticky-md-top,
.sticky-top {
  top: 120px;
  z-index: 1;
}

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #FFFAF3;
  background-size: cover;
  background-position: center;
}

.page-title p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #7272A8;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .page-title p {
    font-size: 16px;
  }
}

.page-title h3,
.page-title .h3 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {

  .page-title h3,
  .page-title .h3 {
    font-size: 28px;
  }
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section {
  position: relative;
}

.section-head {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-head {
    margin-bottom: 20px;
  }
}

.section-head h1,
.section-head .h1,
.section-head h2,
.section-head .h2,
.section-head h3,
.section-head .h3,
.section-head h4,
.section-head .h4,
.section-head h5,
.section-head .h5,
.section-head h6,
.section-head .h6 {
  font-family: var(--font-family-title);
}

.section-head h2,
.section-head .h2 {
  font-size: 2.625rem;
  font-weight: 550;
  line-height: 1.2;
}

@media only screen and (max-width: 1480px) {

  .section-head h2,
  .section-head .h2 {
    font-size: 2.25rem;
  }
}

@media only screen and (max-width: 767px) {

  .section-head h2,
  .section-head .h2 {
    font-size: 1.625rem;
  }
}

.section-head h3,
.section-head .h3 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
}

@media only screen and (max-width: 1200px) {

  .section-head h3,
  .section-head .h3 {
    font-size: 2.15rem;
  }
}

@media only screen and (max-width: 991px) {

  .section-head h3,
  .section-head .h3 {
    font-size: 1.75rem;
  }
}

.section-head h4,
.section-head .h4 {
  font-size: 2rem;
}

@media only screen and (max-width: 575px) {

  .section-head h4,
  .section-head .h4 {
    font-size: 1.5rem;
  }
}

.section-head p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .section-head p {
    font-size: 14px;
  }
}

.section-head .pre-title {
  font-size: 18px;
  letter-spacing: 5px;
  color: #555;
}

.section-head.text-center p {
  max-width: 753px;
  margin-left: auto;
  margin-right: auto;
}

.section-head.m-b30 {
  margin-bottom: 30px;
}

.section-head .sub-title {
  text-transform: uppercase;
}

@media (min-width: 1200px) {

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.875rem;
  }
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}

.row.spno [class*=col],
.row.spno [class*=col],
.spno [class*=col],
.spno [class*=col] {
  padding-left: 0;
  padding-right: 0;
}

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px;
}

.row.sp4 [class*=col],
.row.sp4 [class*=col],
.sp4 [class*=col],
.sp4 [class*=col] {
  padding-left: 4px;
  padding-right: 4px;
}

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px;
}

.row.sp15 [class*=col],
.row.sp15 [class*=col],
.sp15 [class*=col],
.sp15 [class*=col] {
  padding-left: 7px;
  padding-right: 7px;
}

.row.sp10,
.sp10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row.sp10 [class*=col],
.row.sp10 [class*=col],
.sp10 [class*=col],
.sp10 [class*=col] {
  padding-left: 10px;
  padding-right: 10px;
}

.rounded-xl {
  border-radius: 50% !important;
  overflow: hidden;
}

.rounded-lg {
  border-radius: 20px !important;
  overflow: hidden;
}

.rounded-md {
  border-radius: 20px !important;
  overflow: hidden;
}

.rounded-sm {
  border-radius: 4px !important;
  overflow: hidden;
}

.z-index-unset {
  z-index: unset;
}

.text-maroon {
  color: #9e0168;
}

.text-orange {
  color: #ff8853;
}

.text-yellow {
  color: #ffa808;
}

.text-skyblue {
  color: #53C258;
}

.text-red {
  color: #f22462;
}

.text-green {
  color: #53C258;
}

.text-blue {
  color: #131045;
}

.bg-maroon {
  background-color: #9e0168;
}

.bg-orange {
  background-color: #ff8853;
}

.bg-yellow {
  background-color: #ffa808;
}

.bg-skyblue {
  background-color: #00aeff;
}

.bg-red {
  background-color: #f22462;
}

.bg-green {
  background-color: #53C258;
}

.bg-blue {
  background-color: #131045;
}

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85);
}

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.shadow-none {
  box-shadow: unset;
}

.flex-1 {
  flex: 1;
}

.text-justify {
  text-align: justify;
}

/*==== Shortcode Css ====*/
.sort-title {
  margin-bottom: 40px;
}

.sort-title h4:after,
.sort-title .h4:after {
  left: -160px;
}

.sort-title.text-white h4:after,
.sort-title.text-white .h4:after,
.sort-title.text-white h4:before,
.sort-title.text-white .h4:before {
  background: #fff;
}

.sort-title h4:after,
.sort-title .h4:after,
.sort-title h4:before,
.sort-title .h4:before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 1px;
  position: absolute;
  right: -160px;
  top: 50%;
  width: 150px;
  opacity: 0.15;
}

.sort-title h4,
.sort-title .h4 {
  display: inline-block;
  margin: 0;
  position: relative;
}

@media only screen and (max-width: 767px) {

  .sort-title h4:after,
  .sort-title .h4:after,
  .sort-title h4:before,
  .sort-title .h4:before {
    content: none;
  }
}

.bg-light-dark {
  background-color: var(--light-dark);
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1680px) and (min-width: 1920px) {
  .col-20 {
    width: 20%;
  }

  .col-80 {
    width: 80%;
  }
}

@media only screen and (min-width: 1680px) and (min-width: 1800px) {
  .grid-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1680px) {
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

.main-slider {
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 1480px) {

  .main-slider .btn,
  .main-slider .woocommerce #respond input#submit,
  .woocommerce #respond .main-slider input#submit,
  .main-slider .woocommerce a.button,
  .woocommerce .main-slider a.button,
  .main-slider .woocommerce button.button,
  .woocommerce .main-slider button.button,
  .main-slider .woocommerce input.button,
  .woocommerce .main-slider input.button,
  .main-slider .woocommerce #place_order,
  .woocommerce .main-slider #place_order,
  .main-slider .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .main-slider a.checkout-button,
  .main-slider .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .main-slider .button,
  .main-slider .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .main-slider input,
  .main-slider .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .main-slider a,
  .main-slider .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .main-slider a,
  .main-slider .wp-block-button__link {
    padding: 9px 18px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {

  .main-slider .left-text-bar,
  .main-slider .right-text-bar {
    display: none;
  }
}

.main-slider.style-1 {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.main-slider.style-1 .swiper-area {
  width: 68.5%;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1680px) {
  .main-slider.style-1 .swiper-area {
    width: 80%;
  }
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-1 .swiper-area {
    width: 85%;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-1 .swiper-area {
    width: 81%;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .swiper-area {
    width: 74%;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1 .swiper-area {
    width: 73%;
  }
}

.main-slider.style-1 .main-swiper-thumb {
  position: relative;
  z-index: 1;
  width: calc(50vw - 140px);
  height: auto;
  overflow: unset;
  padding-left: 40px;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-1 .main-swiper-thumb {
    width: calc(50vw - 100px);
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-1 .main-swiper-thumb {
    padding-left: 0;
    width: calc(50vw - 60px);
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .main-swiper-thumb {
    margin-left: 0;
    width: calc(90vw - 60px);
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1 .main-swiper-thumb {
    width: calc(80vw - 50px);
  }
}

.main-slider.style-1 .main-swiper-thumb .swiper-wrapper {
  height: auto;
}

.main-slider.style-1 .main-swiper-thumb .swiper-slide {
  transform: scale(0.8);
  -webkit-transition: all 0.25s;
  -ms-transition: all 0.25s;
  transition: all 0.25s;
  opacity: 0;
}

.main-slider.style-1 .main-swiper-thumb .swiper-slide.swiper-slide-active {
  opacity: 1;
  transform: scale(1);
}

.main-slider.style-1 .main-swiper-thumb .swiper-slide.swiper-slide-active .books-card {
  box-shadow: 0px 7px 24px 0px rgba(17, 12, 46, 0.15);
}

.main-slider.style-1 .main-swiper-thumb .swiper-slide.swiper-slide-active .banner-media:after {
  opacity: 0;
}

.main-slider.style-1 .main-swiper-thumb .swiper-slide.swiper-slide-active .banner-media .img-preview:after {
  opacity: 1;
}

.main-slider.style-1 .main-swiper-thumb .swiper-slide.swiper-slide-next {
  opacity: 1;
}

.main-slider.style-1 .star-1 {
  position: absolute;
  top: 13%;
  left: 45%;
  transform: translate(-43%, -10%);
  z-index: -1;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .star-1 {
    top: 30%;
    left: 70%;
  }
}

.main-slider.style-1 .star-2 {
  position: absolute;
  top: 13%;
  right: 13%;
  transform: translate(15%, -10%);
  z-index: 1;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 1199px) {

  .main-slider.style-1 .star-1,
  .main-slider.style-1 .star-2 {
    display: none;
  }
}

.main-slider.style-1 .swiper-slide {
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.main-slider.style-1 .main-swiper {
  z-index: 2;
  overflow: hidden;
  position: relative;
}

.main-slider.style-1 .main-swiper .swiper-slide {
  transition: 0.5s;
  opacity: 0 !important;
}

.main-slider.style-1 .main-swiper .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}

.main-slider.style-1 .swiper-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  padding-top: 70px;
  padding-left: 30px;
}

@media only screen and (max-width: 1680px) {
  .main-slider.style-1 .swiper-content {
    padding-top: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-1 .swiper-content {
    padding-top: 40px;
  }
}

.main-slider.style-1 .swiper-pagination-wrapper {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 20px;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .swiper-pagination-wrapper {
    display: none;
  }
}

.main-slider.style-1 .banner-content {
  padding-top: 150px;
  padding-bottom: 60px;
  height: 100%;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-1 .banner-content {
    height: 860px;
  }
}

@media (max-width: 1366px) {
  .main-slider.style-1 .banner-content {
    height: 100%;
    min-height: 750px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-1 .banner-content {
    padding-top: 100px;
    padding-bottom: 30px;
    min-height: 650px;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .banner-content {
    height: 100%;
  }
}

.main-slider.style-1 .banner-content .bottom-content {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 90px;
  z-index: 99;
  padding-left: 30px;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-1 .banner-content .bottom-content {
    bottom: 40px;
  }
}

@media (max-width: 1366px) {
  .main-slider.style-1 .banner-content .bottom-content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .banner-content .bottom-content {
    position: static;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1 .banner-content .bottom-content {
    display: none;
  }
}

.main-slider.style-1 .banner-content .bottom-content .sub-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0;
  color: var(--title);
  margin-bottom: 10px;
  display: block;
}

.main-slider.style-1 .banner-content .bottom-content .title {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  width: 55%;
  margin: 0;
  margin-bottom: 10px;
  display: block;
}

.main-slider.style-1 .banner-content .content-info {
  padding-bottom: 30px;
  z-index: 2;
  position: relative;
  height: 100%;
}

.main-slider.style-1 .banner-content .sub-title {
  color: #F0BBE3;
  line-height: 1.6;
  letter-spacing: 20px;
  font-weight: var(--headings-font-weight3);
}

.main-slider.style-1 .banner-content .title {
  color: var(--title);
  font-size: 80px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-1 .banner-content .title {
    font-size: 3.75rem;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-1 .banner-content .title {
    font-size: 2.5rem;
  }
}

.main-slider.style-1 .banner-content .text {
  color: #5E626F;
  font-size: 18px;
  font-weight: 400;
  max-width: 480px;
}

.main-slider.style-1 .banner-content .swiper-meta-items {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-1 .banner-content .swiper-meta-items {
    margin-bottom: 20px;
  }
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content {
  margin-right: 90px;
}

@media only screen and (max-width: 767px) {
  .main-slider.style-1 .banner-content .swiper-meta-items .meta-content {
    margin-right: 10px;
  }
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content span {
  display: block;
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .color-name {
  margin-bottom: 10px;
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .color-name,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .price-name {
  font-size: 14px;
  font-weight: 600;
  color: var(--title);
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .price-num {
  margin-bottom: 0px;
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 767px) {
  .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .price-num {
    font-size: 28px;
  }
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #respond input#submit,
.woocommerce #respond .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size input#submit,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce a.button,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size a.button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce button.button,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size button.button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce input.button,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size input.button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #place_order,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size #place_order,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size a.checkout-button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size input,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size a,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size a,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .wp-block-button__link {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 10px !important;
  background-color: #fff !important;
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+.btn,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #respond .btn-check:checked+input#submit,
.woocommerce #respond .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+input#submit,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+a.button,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a.button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+button.button,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+button.button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+input.button,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+input.button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+#place_order,
.woocommerce .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+#place_order,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a.checkout-button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart-form .table tr .btn-check:checked+.button,
.woocommerce-cart-form .table tr .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+.button,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #review_form #respond .form-submit .btn-check:checked+input,
.woocommerce #review_form #respond .form-submit .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+input,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce .widget_shopping_cart .buttons .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce.widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a,
.main-slider.style-1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+.wp-block-button__link {
  background-color: var(--secondary) !important;
  color: #fff;
}

.main-slider.style-1 .banner-content .swiper-meta-items .meta-content del {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: var(--headings-font-weight2);
  color: #E5CCB8;
  margin-bottom: 0;
  font-size: 18px;
  padding: 0 10px;
}

.main-slider.style-1 .banner-media {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.main-slider.style-1 .banner-media:after {
  content: attr(data-name);
  position: absolute;
  top: 62%;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  z-index: 9999;
  font-size: 130px;
  color: #fff;
  -webkit-text-stroke: 1.5px #000;
  transform: rotate(180deg) translateY(55%);
  width: auto;
  left: -65px;
  writing-mode: tb;
}

@media (max-width: 1366px) {
  .main-slider.style-1 .banner-media:after {
    top: 52%;
    font-size: 80px;
    left: -40px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-1 .banner-media:after {
    font-size: 65px;
    left: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-1 .banner-media:after {
    font-size: 65px;
    left: -40px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1 .banner-media:after {
    font-size: 45px;
    left: -20px;
  }
}

.main-slider.style-1 .banner-media .img-preview {
  width: 100%;
  position: relative;
}

.main-slider.style-1 .banner-media .img-preview:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 40px;
  width: 41px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 1;
  opacity: 0;
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1 .banner-media .img-preview:after {
    bottom: 32px;
    width: 30px;
  }
}

.main-slider.style-1 .banner-media img {
  width: 100%;
  border-radius: 40px;
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-1 .banner-content .content-btn {
    margin-bottom: 0;
  }

  .main-slider.style-1 .swiper-slide:after {
    width: 320px;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-1 .pattern {
    display: none;
  }

  .main-slider.style-1 .swiper-content {
    padding: 0px;
  }

  .main-slider.style-1 .banner-content .text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-1 .banner-content .text {
    padding-left: 0;
    padding-right: 0;
    border: 0;
    max-width: 270px;
  }

  .main-slider.style-1 .banner-content h1,
  .main-slider.style-1 .banner-content .h1 {
    font-size: 2rem;
  }

  .main-slider.style-1 .banner-content .price {
    display: none;
  }

  .main-slider.style-1 .banner-content .content-btn {
    margin-top: 30px;
  }

  .main-slider.style-1 .banner-content .swiper-meta-items {
    margin: 15px 0px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-1 .banner-content .sub-title {
    font-size: 14px;
  }

  .main-slider.style-1 .banner-content .title {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .main-slider.style-1 .banner-content .swiper-content {
    max-width: 100%;
  }

  .main-slider.style-1 .banner-content .text {
    max-width: 100%;
  }

  .main-slider.style-1 .banner-content .swiper-meta-items {
    margin: 15px 0px 10px;
  }

  .main-slider.style-1 .banner-content .content-btn {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .main-slider.style-1 .banner-content .content-btn .btn,
  .main-slider.style-1 .banner-content .content-btn .woocommerce #respond input#submit,
  .woocommerce #respond .main-slider.style-1 .banner-content .content-btn input#submit,
  .main-slider.style-1 .banner-content .content-btn .woocommerce a.button,
  .woocommerce .main-slider.style-1 .banner-content .content-btn a.button,
  .main-slider.style-1 .banner-content .content-btn .woocommerce button.button,
  .woocommerce .main-slider.style-1 .banner-content .content-btn button.button,
  .main-slider.style-1 .banner-content .content-btn .woocommerce input.button,
  .woocommerce .main-slider.style-1 .banner-content .content-btn input.button,
  .main-slider.style-1 .banner-content .content-btn .woocommerce #place_order,
  .woocommerce .main-slider.style-1 .banner-content .content-btn #place_order,
  .main-slider.style-1 .banner-content .content-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .main-slider.style-1 .banner-content .content-btn a.checkout-button,
  .main-slider.style-1 .banner-content .content-btn .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .main-slider.style-1 .banner-content .content-btn .button,
  .main-slider.style-1 .banner-content .content-btn .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .main-slider.style-1 .banner-content .content-btn input,
  .main-slider.style-1 .banner-content .content-btn .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .main-slider.style-1 .banner-content .content-btn a,
  .main-slider.style-1 .banner-content .content-btn .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .main-slider.style-1 .banner-content .content-btn a,
  .main-slider.style-1 .banner-content .content-btn .wp-block-button__link {
    font-size: 13px;
  }
}

.main-slider.style-2 .banner-content {
  height: 800px;
  padding-top: 30px;
  padding-bottom: 80px;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-2 .banner-content {
    height: 700px;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-2 .banner-content {
    height: 550px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-2 .banner-content {
    height: 100%;
    margin-bottom: 30px;
  }
}

.main-slider.style-2 .banner-content .content-info {
  padding-bottom: 45px;
  z-index: 2;
  position: relative;
}

.main-slider.style-2 .banner-content .sub-title {
  color: var(--title);
  line-height: 1.6;
  font-size: 24px;
  font-weight: 400;
}

.main-slider.style-2 .banner-content .sub-title span {
  color: var(--primary);
}

.main-slider.style-2 .banner-content .offer-title {
  color: var(--title);
  font-size: 80px;
  font-weight: 600;
  line-height: 1.2;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-2 .banner-content .offer-title {
    font-size: 70px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-2 .banner-content .offer-title {
    font-size: 49px;
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-2 .banner-content .offer-title svg {
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-2 .banner-content .offer-title svg {
    width: 40px;
  }
}

.main-slider.style-2 .banner-content .title {
  font-size: 40px;
  font-weight: 700;
}

.main-slider.style-2 .banner-content .dz-tags {
  margin-bottom: 35px;
  line-height: 1.5;
  display: inline-flex;
}

.main-slider.style-2 .banner-content .dz-tags li a {
  font-size: 1.125rem;
  margin-right: 20px;
  color: #E5CCB8;
  font-weight: var(--headings-font-weight2);
}

.main-slider.style-2 .banner-content .text {
  color: #5E626F;
  font-size: 18px;
  font-weight: 400;
  max-width: 480px;
}

.main-slider.style-2 .banner-content .bnr-content-bx {
  width: 250px;
  padding: 20px;
  position: absolute;
  top: 38%;
  right: -5vw;
  transition: all 0.8s;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 60px 0px rgba(168, 3, 3, 0.15);
  backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
}

.main-slider.style-2 .banner-content .bnr-content-bx .dz-title {
  color: var(--title);
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.main-slider.style-2 .banner-content .bnr-content-bx .dz-title span {
  font-weight: 600;
}

.main-slider.style-2 .banner-content .bnr-content-bx .meta-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: -45px;
  right: 0;
  border-color: var(--primary);
}

.main-slider.style-2 .banner-content .bnr-content-bx .price {
  margin: 0;
}

.main-slider.style-2 .banner-content .bnr-content-bx .dz-media {
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
}

.main-slider.style-2 .banner-content .bnr-content-bx .dz-info {
  position: relative;
  z-index: 1;
}

.main-slider.style-2 .banner-content .bnr-content-bx:hover {
  transform: translateY(-10px);
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-2 .banner-content .bnr-content-bx {
    width: 200px;
    padding: 15px;
    right: 1vw;
  }

  .main-slider.style-2 .banner-content .bnr-content-bx .dz-title {
    font-size: 16px;
  }

  .main-slider.style-2 .banner-content .bnr-content-bx .dz-info {
    padding-top: 12px;
  }

  .main-slider.style-2 .banner-content .bnr-content-bx:after {
    border-radius: 15px 0 0 15px;
  }

  .main-slider.style-2 .banner-content .bnr-content-bx:hover:after {
    border-radius: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-2 .banner-content .bnr-content-bx {
    display: none;
  }
}

.main-slider.style-2 .banner-content .bnr-customer-bx {
  padding: 25px;
  position: absolute;
  bottom: 12%;
  left: -3vw;
  transition: all 0.8s;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 60px 0px rgba(168, 3, 3, 0.15);
  backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  width: 270px;
  justify-content: center;
}

.main-slider.style-2 .banner-content .bnr-customer-bx ul {
  display: flex;
  padding: 0px 10px;
}

.main-slider.style-2 .banner-content .bnr-customer-bx ul .customer-image {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: -10px;
}

.main-slider.style-2 .banner-content .bnr-customer-bx ul .customer-image img {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.main-slider.style-2 .banner-content .bnr-customer-bx i {
  color: var(--primary);
  font-size: 30px;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .main-slider.style-2 .banner-content .bnr-customer-bx {
    display: none;
  }
}

.main-slider.style-2 .banner-content .swiper-meta-items {
  margin: 25px 0px 25px;
  display: flex;
  align-items: center;
}

.main-slider.style-2 .banner-content .swiper-meta-items .meta-content {
  margin-right: 90px;
}

.main-slider.style-2 .banner-content .swiper-meta-items .meta-content span {
  display: block;
}

.main-slider.style-2 .banner-content .swiper-meta-items .meta-content .color-name {
  margin-bottom: 10px;
}

.main-slider.style-2 .banner-content .swiper-meta-items .meta-content .color-name,
.main-slider.style-2 .banner-content .swiper-meta-items .meta-content .price-name {
  font-size: 14px;
  font-weight: 400;
}

.main-slider.style-2 .banner-content .swiper-meta-items .meta-content .price-num {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-family-title);
  color: var(--secondary);
}

.main-slider.style-2 .banner-content .swiper-meta-items .meta-content del {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: var(--headings-font-weight2);
  color: #E5CCB8;
  margin-bottom: 0;
  font-size: 18px;
  padding: 0 10px;
}

.main-slider.style-2 .banner-media {
  margin-right: -100px;
  margin-left: 50px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1680px) {
  .main-slider.style-2 .banner-media {
    margin-right: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-2 .banner-media {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-2 .banner-media {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-2 .banner-media {
    margin-right: 0px;
  }
}

.main-slider.style-2 .banner-media:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 73%;
  background: var(--gradient1);
  z-index: -1;
  border-radius: 125px 30px 30px 30px;
}

.main-slider.style-2 .banner-media .img-preview {
  overflow: hidden;
}

.main-slider.style-2 .banner-media .img-preview img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.main-slider.style-2 .banner-media .border-shap {
  border: 1px solid #DFDFDF;
  position: absolute;
  top: 18%;
  left: -13%;
  width: 61%;
  height: 54%;
  border-radius: 150px 0px 0px 0px;
  z-index: -1;
}

.main-slider.style-2 .banner-media .border-shap2 {
  border: 1px solid #DFDFDF;
  position: absolute;
  bottom: -7%;
  right: -7%;
  width: 50%;
  height: 35%;
  z-index: -1;
  border-radius: 0px 0px 50px 0px;
}

.main-slider.style-2 .banner-media .shap:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="413" height="44" viewBox="0 0 413 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.4829 37.8579L0 0.375V0H413V0.375L375.517 37.8579C367.707 45.6684 355.043 45.6684 347.233 37.8579L323.892 14.5171C316.082 6.70665 303.418 6.70665 295.608 14.5171L272.267 37.8579C264.457 45.6684 251.793 45.6684 243.983 37.8579L220.642 14.5171C212.832 6.70665 200.168 6.70665 192.358 14.5171L169.017 37.8579C161.207 45.6684 148.543 45.6684 140.733 37.8579L117.392 14.5171C109.582 6.70665 96.9184 6.70665 89.1079 14.5171L65.7671 37.8579C57.9566 45.6684 45.2934 45.6684 37.4829 37.8579Z" fill="%23fffaf3"/></svg>');
  position: absolute;
  width: 41.77%;
  height: 50px;
  top: 27%;
  background-size: 100%;
  right: -18%;
  background-position: top;
  background-repeat: no-repeat;
}

.main-slider.style-2 .banner-media .shap:before {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="413" height="44" viewBox="0 0 413 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.4829 37.8579L0 0.375V0H413V0.375L375.517 37.8579C367.707 45.6684 355.043 45.6684 347.233 37.8579L323.892 14.5171C316.082 6.70665 303.418 6.70665 295.608 14.5171L272.267 37.8579C264.457 45.6684 251.793 45.6684 243.983 37.8579L220.642 14.5171C212.832 6.70665 200.168 6.70665 192.358 14.5171L169.017 37.8579C161.207 45.6684 148.543 45.6684 140.733 37.8579L117.392 14.5171C109.582 6.70665 96.9184 6.70665 89.1079 14.5171L65.7671 37.8579C57.9566 45.6684 45.2934 45.6684 37.4829 37.8579Z" fill="%23fffaf3"/></svg>');
  position: absolute;
  width: 47.77%;
  height: 50px;
  bottom: 0;
  background-size: 100%;
  left: -23%;
  background-position: top;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.main-slider.style-2 .banner-media .star-list .star-1 {
  position: absolute;
  top: 50px;
  left: 100px;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-2 .banner-media .star-list .star-1 {
    top: 15px;
    left: 35px;
  }
}

.main-slider.style-2 .banner-media .star-list .star-2 {
  position: absolute;
  top: 30%;
  right: -15%;
  animation: rotate-360 20s infinite linear;
}

.main-slider.style-2 .banner-media .star-list .star-3 {
  position: absolute;
  bottom: 20%;
  left: -25%;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-2 .banner-content .content-info {
    padding-bottom: 28px;
  }

  .main-slider.style-2 .banner-content .sub-title {
    font-size: 20px;
  }

  .main-slider.style-2 .banner-content .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-2 .banner-content .content-info {
    padding-bottom: 20px;
  }

  .main-slider.style-2 .banner-content .sub-title {
    font-size: 18px;
  }

  .main-slider.style-2 .banner-content .offer-title {
    font-size: 36px;
    line-height: 1.2;
  }

  .main-slider.style-2 .banner-content .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-2 .banner-content {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .main-slider.style-2 .swiper-content {
    margin-bottom: 30px;
  }

  .main-slider.style-2 .banner-media {
    float: left;
    width: 100%;
  }
}

.main-slider.style-3:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 24%;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3:after {
    display: none;
  }
}

@media only screen and (max-width: 575px) {

  .main-slider.style-3 .content-btn .btn,
  .main-slider.style-3 .content-btn .woocommerce #respond input#submit,
  .woocommerce #respond .main-slider.style-3 .content-btn input#submit,
  .main-slider.style-3 .content-btn .woocommerce a.button,
  .woocommerce .main-slider.style-3 .content-btn a.button,
  .main-slider.style-3 .content-btn .woocommerce button.button,
  .woocommerce .main-slider.style-3 .content-btn button.button,
  .main-slider.style-3 .content-btn .woocommerce input.button,
  .woocommerce .main-slider.style-3 .content-btn input.button,
  .main-slider.style-3 .content-btn .woocommerce #place_order,
  .woocommerce .main-slider.style-3 .content-btn #place_order,
  .main-slider.style-3 .content-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .main-slider.style-3 .content-btn a.checkout-button,
  .main-slider.style-3 .content-btn .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .main-slider.style-3 .content-btn .button,
  .main-slider.style-3 .content-btn .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .main-slider.style-3 .content-btn input,
  .main-slider.style-3 .content-btn .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .main-slider.style-3 .content-btn a,
  .main-slider.style-3 .content-btn .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .main-slider.style-3 .content-btn a,
  .main-slider.style-3 .content-btn .wp-block-button__link {
    padding: 10px 20px;
  }
}

.main-slider.style-3 .main-swiper-thumb {
  position: absolute;
  right: 0;
  bottom: 60px;
  z-index: 99;
  width: 45vw;
  height: auto;
  overflow: hidden;
  padding: 20px 20px 20px 0;
}

.main-slider.style-3 .main-swiper-thumb .swiper-wrapper {
  height: auto;
}

.main-slider.style-3 .main-swiper-thumb .swiper-slide {
  opacity: 0.6;
  width: 380px;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.main-slider.style-3 .main-swiper-thumb .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.main-slider.style-3 .main-swiper-thumb .swiper-slide.swiper-slide-active .books-card {
  box-shadow: 0px 7px 24px 0px rgba(17, 12, 46, 0.15);
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .main-swiper-thumb {
    display: none;
  }
}

.main-slider.style-3 .inner-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 100px 0;
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-3 .inner-content {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .inner-content {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-3 .inner-content {
    padding-top: 30px;
  }
}

.main-slider.style-3 .banner-content {
  padding-top: 100px;
  padding-bottom: 70px;
  height: 980px;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-3 .banner-content {
    height: 880px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-3 .banner-content {
    height: 750px;
    padding-top: 75px;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .banner-content {
    height: 100%;
  }
}

.main-slider.style-3 .banner-content>* {
  height: 100%;
}

.main-slider.style-3 .banner-content .bottom-content {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 100px;
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .banner-content .bottom-content {
    position: static;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-3 .banner-content .bottom-content {
    display: none;
  }
}

.main-slider.style-3 .banner-content .bottom-content .sub-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0;
  color: var(--title);
  margin-bottom: 10px;
  display: block;
}

.main-slider.style-3 .banner-content .bottom-content .title {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  width: 55%;
  margin: 0;
  margin-bottom: 10px;
  display: block;
}

.main-slider.style-3 .banner-content .content-info {
  padding-bottom: 30px;
  z-index: 2;
  position: relative;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .main-slider.style-3 .banner-content .content-info {
    padding-bottom: 0;
  }
}

.main-slider.style-3 .banner-content .sub-title {
  color: #F0BBE3;
  line-height: 1.6;
  letter-spacing: 20px;
  font-weight: var(--headings-font-weight3);
}

.main-slider.style-3 .banner-content .title {
  color: var(--title);
  font-size: 80px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.main-slider.style-3 .banner-content .title span {
  color: var(--light-dark);
  -webkit-text-stroke: 2px #FBBB00;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-3 .banner-content .title {
    font-size: 3.75rem;
  }
}

@media only screen and (max-width: 1199px) {
  .main-slider.style-3 .banner-content .title {
    font-size: 2.5rem;
  }
}

.main-slider.style-3 .banner-content .text {
  color: var(--title);
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .main-slider.style-3 .banner-content .text {
    margin-bottom: 25px;
    font-size: 18px;
  }
}

.main-slider.style-3 .banner-content .swiper-meta-items {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.main-slider.style-3 .banner-content .swiper-meta-items .meta-content {
  margin-right: 90px;
}

@media only screen and (max-width: 767px) {
  .main-slider.style-3 .banner-content .swiper-meta-items .meta-content {
    margin-right: 10px;
  }
}

.main-slider.style-3 .banner-content .swiper-meta-items .meta-content span {
  display: block;
}

.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .color-name {
  margin-bottom: 10px;
}

.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .color-name,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .price-name {
  font-size: 14px;
  font-weight: 600;
  color: var(--title);
}

.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .price-num {
  margin-bottom: 0px;
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-family-title);
}

.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .btn,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #respond input#submit,
.woocommerce #respond .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size input#submit,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce a.button,
.woocommerce .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size a.button,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce button.button,
.woocommerce .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size button.button,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce input.button,
.woocommerce .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size input.button,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #place_order,
.woocommerce .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size #place_order,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size a.checkout-button,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .button,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size input,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size a,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size a,
.main-slider.style-3 .banner-content .swiper-meta-items .meta-content .product-size .wp-block-button__link {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 10px !important;
}

.main-slider.style-3 .banner-content .swiper-meta-items .meta-content del {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: var(--headings-font-weight2);
  color: #E5CCB8;
  margin-bottom: 0;
  font-size: 18px;
  padding: 0 10px;
}

.main-slider.style-3 .banner-media {
  position: relative;
  padding-top: 50px;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .banner-media {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-3 .banner-media {
    padding-top: 20px;
  }
}

.main-slider.style-3 .banner-media .icon-button {
  position: absolute;
  bottom: -30px;
  right: -25%;
  z-index: 9;
  width: 180px;
  height: 180px;
  transform: scale(1.2);
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-3 .banner-media .icon-button {
    transform: scale(1.1);
    right: -8%;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .banner-media .icon-button {
    bottom: 10px;
    right: 0;
    transform: scale(1);
  }
}

@media only screen and (max-width: 575px) {
  .main-slider.style-3 .banner-media .icon-button {
    bottom: -30px;
    right: -40px;
    transform: scale(0.8);
  }
}

.main-slider.style-3 .banner-media .star {
  position: absolute;
  top: 10%;
  left: -10%;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .banner-media .star {
    left: -5px;
  }
}

@media only screen and (max-width: 767px) {
  .main-slider.style-3 .banner-media .star {
    top: -5%;
  }
}

.main-slider.style-3 .banner-media .img-preview {
  margin-right: -100px;
}

@media only screen and (max-width: 1480px) {
  .main-slider.style-3 .banner-media .img-preview {
    margin-right: -60px;
  }
}

@media only screen and (max-width: 991px) {
  .main-slider.style-3 .banner-media .img-preview {
    margin-right: 0px;
  }
}

.main-slider.style-3 .banner-media .img-preview img {
  border-radius: 340px;
  border: 20px solid #fff;
}

.main-slider.style-3 .banner-media img {
  width: 100%;
}

.main-slider.style-3 .product-card {
  display: flex;
  align-items: center;
}

.main-slider.style-3 .product-card .dz-media {
  transition: 0.7s;
  border: 1px solid var(--primary);
  width: 120px;
  height: 120px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  padding: 20px;
  z-index: 0;
  overflow: visible;
  background-color: #fff;
}

.main-slider.style-3 .product-card .dz-media:after {
  content: "+";
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
  background: var(--primary);
  border-radius: 50%;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.main-slider.style-3 .product-card .dz-content .dz-title {
  font-size: 24px;
  font-weight: 400;
}

.main-slider.style-3 .product-card .dz-content .price {
  color: var(--primary);
  font-size: 20px;
  font-weight: 400;
}

.main-slider.style-3 .product-card .dz-content .price del {
  color: var(--title);
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .main-slider.style-3 .product-card {
    display: none;
  }
}

.main-slider.style-3 .collection1 {
  position: absolute;
  top: 20%;
  left: 5%;
}

.main-slider.style-3 .collection1 img {
  border-radius: 50%;
}

.main-slider.style-3 .collection2 {
  position: absolute;
  top: 55%;
  right: 45%;
}

.main-slider.style-3 .collection2 img {
  border-radius: 0 0 100px 0;
}

.main-slider.style-3 .collection3 {
  position: absolute;
  bottom: 10%;
  left: -35px;
}

.main-slider.style-3 .collection3 img {
  border-radius: 20px;
}

.main-slider.style-3 .collection1,
.main-slider.style-3 .collection2,
.main-slider.style-3 .collection3 {
  position: absolute;
  width: 155px;
  height: 155px;
}

.main-slider.style-3 .collection1 img,
.main-slider.style-3 .collection2 img,
.main-slider.style-3 .collection3 img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media only screen and (max-width: 1480px) {

  .main-slider.style-3 .collection1,
  .main-slider.style-3 .collection2,
  .main-slider.style-3 .collection3 {
    display: none;
  }
}

.main-swiper .swiper-pagination-five {
  position: static;
  top: 50%;
  left: 0;
  z-index: 9;
  width: auto !important;
  transform: translateY(-50%);
  writing-mode: vertical-rl;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-swiper .swiper-pagination-five .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  margin: 15px !important;
  color: var(--title);
  font-weight: 400;
  position: relative;
  font-size: 14px;
  opacity: 0.2;
  background-color: transparent;
  writing-mode: horizontal-tb;
}

.main-swiper .swiper-pagination-five .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: transparent;
  opacity: 1;
  font-size: 18px;
}

.main-swiper .swiper-pagination-five .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  opacity: 1;
}

.main-swiper .swiper-pagination-five .swiper-pagination-bullet:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -45px;
  background-color: var(--primary);
  width: 30px;
  height: 2px;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .main-swiper .swiper-pagination-five {
    left: auto;
    right: 0;
  }
}

@media screen and (max-width: 600px) {
  .main-swiper .swiper-pagination-five {
    writing-mode: horizontal-tb;
  }
}

@media only screen and (max-width: 991px) {
  .animation-btn a {
    top: 40% !important;
    left: 70% !important;
  }
}

@media only screen and (max-width: 575px) {
  .animation-btn a {
    top: 20% !important;
    width: 50px !important;
  }
}

.alert {
  border-radius: 0;
}

.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px;
}

.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px;
}

.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px;
}

.alert[class*=alert-] i {
  margin-right: 8px;
}

.alert[class*=alert-] ul {
  padding-left: 25px;
  margin-top: 10px;
}

.dz-alert {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 999999;
  background: #fff;
  border-color: var(--bs-primary);
  color: var(--bs-primary);
  box-shadow: 0px 30px 60px 0 rgba(0, 0, 0, 0.25);
  padding: 15px 30px;
}

.dz-alert.alert-success {
  color: var(--bs-success);
  border-color: var(--bs-success);
}

.dz-alert.alert-info {
  color: var(--bs-info);
  border-color: var(--bs-info);
}

.dz-alert.alert-danger {
  color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.dz-alert.alert-warning {
  color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.card {
  border: 0px solid #D7D7D7;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #ffffff;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D7D7D7;
  background: #ffffff;
  padding: 20px 30px;
}

.card-title {
  font-size: 24px;
  margin-bottom: 0px;
}

.card .card-body {
  padding: 20px 30px;
  background: #ffffff;
  border-radius: 15px;
}

.form-label {
  font-size: 16px;
  font-weight: 700;
  color: var(--title);
  margin-bottom: 15px;
  background: transparent;
  border-color: #D7D7D7;
  font-family: var(--font-family-title);
}

.form-control,
.woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account input[type=password],
.login input[type=text],
.login input[type=password],
.woocommerce-ResetPassword.lost_reset_password input[type=text],
.checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel],
.woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond input[type=email] {
  height: 50px;
  border: 0;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  border-radius: 10px;
  border-radius: 1px solid var(--secondary);
}

.form-control .select,
.woocommerce-EditAccountForm.edit-account input[type=text] .select,
.woocommerce-EditAccountForm.edit-account input[type=email] .select,
.woocommerce-EditAccountForm.edit-account input[type=password] .select,
.login input[type=text] .select,
.login input[type=password] .select,
.woocommerce-ResetPassword.lost_reset_password input[type=text] .select,
.checkout.woocommerce-checkout input[type=text] .select,
.checkout.woocommerce-checkout input[type=email] .select,
.checkout.woocommerce-checkout input[type=tel] .select,
.checkout.woocommerce-checkout .select2-choice .select,
.checkout.woocommerce-checkout textarea .select,
.woocommerce form .form-row input.input-text .select,
.woocommerce form .form-row .select2-selection .select,
.woocommerce form .form-row .select2-choice .select,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea .select,
.woocommerce .woocommerce-checkout.checkout form .form-row select .select,
.woocommerce .woocommerce-checkout.checkout form .form-row input .select,
.woocommerce-cart-form .table tr .quantity input .select,
.woocommerce-cart-form .table tr td.actions .coupon .input-text .select,
.woocommerce #review_form #respond input[type=text] .select,
.woocommerce #review_form #respond textarea .select,
.woocommerce #review_form #respond input[type=email] .select {
  padding: 12px 0;
}

.form-control span,
.woocommerce-EditAccountForm.edit-account input[type=text] span,
.woocommerce-EditAccountForm.edit-account input[type=email] span,
.woocommerce-EditAccountForm.edit-account input[type=password] span,
.login input[type=text] span,
.login input[type=password] span,
.woocommerce-ResetPassword.lost_reset_password input[type=text] span,
.checkout.woocommerce-checkout input[type=text] span,
.checkout.woocommerce-checkout input[type=email] span,
.checkout.woocommerce-checkout input[type=tel] span,
.checkout.woocommerce-checkout .select2-choice span,
.checkout.woocommerce-checkout textarea span,
.woocommerce form .form-row input.input-text span,
.woocommerce form .form-row .select2-selection span,
.woocommerce form .form-row .select2-choice span,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text] span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email] span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel] span,
.woocommerce .woocommerce-checkout.checkout form .form-row select span,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text span,
.woocommerce .woocommerce-checkout.checkout form .form-row input span,
.woocommerce-cart-form .table tr .quantity input span,
.woocommerce-cart-form .table tr td.actions .coupon .input-text span,
.woocommerce #review_form #respond input[type=text] span,
.woocommerce #review_form #respond textarea span,
.woocommerce #review_form #respond input[type=email] span {
  margin-top: 0;
}

.form-control::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=text]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=email]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=password]::-webkit-input-placeholder,
.login input[type=text]::-webkit-input-placeholder,
.login input[type=password]::-webkit-input-placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type=text]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type=email]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type=tel]::-webkit-input-placeholder,
.checkout.woocommerce-checkout .select2-choice::-webkit-input-placeholder,
.checkout.woocommerce-checkout textarea::-webkit-input-placeholder,
.woocommerce form .form-row input.input-text::-webkit-input-placeholder,
.woocommerce form .form-row .select2-selection::-webkit-input-placeholder,
.woocommerce form .form-row .select2-choice::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-input-placeholder,
.woocommerce-cart-form .table tr .quantity input::-webkit-input-placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-input-placeholder,
.woocommerce #review_form #respond input[type=text]::-webkit-input-placeholder,
.woocommerce #review_form #respond textarea::-webkit-input-placeholder,
.woocommerce #review_form #respond input[type=email]::-webkit-input-placeholder {
  color: #787D8C;
}

.form-control:-ms-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=text]:-ms-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=email]:-ms-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=password]:-ms-input-placeholder,
.login input[type=text]:-ms-input-placeholder,
.login input[type=password]:-ms-input-placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:-ms-input-placeholder,
.checkout.woocommerce-checkout input[type=text]:-ms-input-placeholder,
.checkout.woocommerce-checkout input[type=email]:-ms-input-placeholder,
.checkout.woocommerce-checkout input[type=tel]:-ms-input-placeholder,
.checkout.woocommerce-checkout .select2-choice:-ms-input-placeholder,
.checkout.woocommerce-checkout textarea:-ms-input-placeholder,
.woocommerce form .form-row input.input-text:-ms-input-placeholder,
.woocommerce form .form-row .select2-selection:-ms-input-placeholder,
.woocommerce form .form-row .select2-choice:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input:-ms-input-placeholder,
.woocommerce-cart-form .table tr .quantity input:-ms-input-placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:-ms-input-placeholder,
.woocommerce #review_form #respond input[type=text]:-ms-input-placeholder,
.woocommerce #review_form #respond textarea:-ms-input-placeholder,
.woocommerce #review_form #respond input[type=email]:-ms-input-placeholder {
  color: #787D8C;
}

.form-control::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.login input[type=text]::placeholder,
.login input[type=password]::placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout textarea::placeholder,
.woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .select2-choice::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond input[type=email]::placeholder {
  color: #787D8C;
}

.form-control:focus,
.woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.login input[type=text]:focus,
.login input[type=password]:focus,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout textarea:focus,
.woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .select2-choice:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond input[type=email]:focus,
.form-control:active,
.woocommerce-EditAccountForm.edit-account input[type=text]:active,
.woocommerce-EditAccountForm.edit-account input[type=email]:active,
.woocommerce-EditAccountForm.edit-account input[type=password]:active,
.login input[type=text]:active,
.login input[type=password]:active,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:active,
.checkout.woocommerce-checkout input[type=text]:active,
.checkout.woocommerce-checkout input[type=email]:active,
.checkout.woocommerce-checkout input[type=tel]:active,
.checkout.woocommerce-checkout .select2-choice:active,
.checkout.woocommerce-checkout textarea:active,
.woocommerce form .form-row input.input-text:active,
.woocommerce form .form-row .select2-selection:active,
.woocommerce form .form-row .select2-choice:active,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:active,
.woocommerce .woocommerce-checkout.checkout form .form-row select:active,
.woocommerce .woocommerce-checkout.checkout form .form-row input:active,
.woocommerce-cart-form .table tr .quantity input:active,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:active,
.woocommerce #review_form #respond input[type=text]:active,
.woocommerce #review_form #respond textarea:active,
.woocommerce #review_form #respond input[type=email]:active,
.form-control.active,
.woocommerce-EditAccountForm.edit-account input.active[type=text],
.woocommerce-EditAccountForm.edit-account input.active[type=email],
.woocommerce-EditAccountForm.edit-account input.active[type=password],
.login input.active[type=text],
.login input.active[type=password],
.woocommerce-ResetPassword.lost_reset_password input.active[type=text],
.checkout.woocommerce-checkout input.active[type=text],
.checkout.woocommerce-checkout input.active[type=email],
.checkout.woocommerce-checkout input.active[type=tel],
.checkout.woocommerce-checkout .active.select2-choice,
.checkout.woocommerce-checkout textarea.active,
.woocommerce form .form-row input.active.input-text,
.woocommerce form .form-row .active.select2-selection,
.woocommerce form .form-row .active.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.active,
.woocommerce .woocommerce-checkout.checkout form .form-row select.active,
.woocommerce .woocommerce-checkout.checkout form .form-row input.active,
.woocommerce-cart-form .table tr .quantity input.active,
.woocommerce-cart-form .table tr td.actions .coupon .active.input-text,
.woocommerce #review_form #respond input.active[type=text],
.woocommerce #review_form #respond textarea.active,
.woocommerce #review_form #respond input.active[type=email] {
  border-color: var(--primary);
  background: #FFFAF3;
}

textarea.form-control,
.checkout.woocommerce-checkout textarea.select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row textarea.select2-selection,
.woocommerce form .form-row textarea.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce-cart-form .table tr td.actions .coupon textarea.input-text,
.woocommerce #review_form #respond textarea {
  min-height: auto;
  height: unset;
}

textarea {
  height: 120px;
  resize: none;
}

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #5E626F;
  border-color: transparent;
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #ffffff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

.toggle-checkbox:checked+.toggle-switch {
  background: var(--primary);
}

.toggle-checkbox:checked+.toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: rgba(151, 151, 159, 0.39);
}

.file-upload-wrapper:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}

.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: var(--primary);
  color: #ffffff;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}

.file-upload-wrapper:hover:before {
  background: #9c0a2c;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #5E626F;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}

.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #5E626F;
  font-size: 0.875rem;
  text-decoration: none;
}

.ui-datepicker-calendar tbody td a:hover {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}

.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #f77b98;
  color: #ffffff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a>span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #5E626F;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #D23636;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #444444;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #444444;
  color: var(--primary);
}

.form-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn),
select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.label-title {
  font-weight: 500;
  color: var(--secondary);
  font-size: 16px;
  margin-bottom: 10px;
}

.form-select,
select {
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  transition: all 0.3s ease-in-out;
  background-color: #FFFAF3;
}

.form-select .dropdown-toggle,
select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
  background-color: transparent;
}

.form-select .dropdown-toggle:focus,
select .dropdown-toggle:focus {
  outline: 0 !important;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.form-select .dropdown-toggle:hover,
select .dropdown-toggle:hover,
.form-select .dropdown-toggle:active,
select .dropdown-toggle:active {
  background-color: transparent;
}

.form-select .dropdown-toggle:after,
select .dropdown-toggle:after {
  content: none;
}

.form-select.show,
select.show {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show .dropdown-toggle,
select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show.btn-group .dropdown-menu.inner,
select.show.btn-group .dropdown-menu.inner {
  display: block;
}

.form-select .dropdown-menu,
select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.form-select .dropdown-menu ul li,
select .dropdown-menu ul li {
  padding: 0;
}

.form-select .dropdown-menu ul li a,
select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
}

.form-select .dropdown-menu ul li a img,
select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}

.form-select .dropdown-menu ul li a:hover,
select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}

.form-select .dropdown-menu ul li.selected a,
select .dropdown-menu ul li.selected a {
  background-color: var(--primary);
  color: #ffffff;
}

.form-select:focus,
select:focus,
.form-select:active,
select:active,
.form-select.active,
select.active {
  border-color: var(--primary);
}

.form-select.btn-group,
select.btn-group {
  padding: 10px 20px;
  border-width: 1px;
}

.form-select.btn-group.show,
select.btn-group.show {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
  border-color: var(--primary);
}

.form-select.btn-group .form-control .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type=text] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type=email] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type=email] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type=password] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type=password] .dropdown-toggle.btn-default,
.form-select.btn-group .login input[type=text] .dropdown-toggle.btn-default,
.login .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .login input[type=password] .dropdown-toggle.btn-default,
.login .form-select.btn-group input[type=password] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-ResetPassword.lost_reset_password input[type=text] .dropdown-toggle.btn-default,
.woocommerce-ResetPassword.lost_reset_password .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type=text] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type=email] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type=email] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type=tel] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type=tel] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout .select2-choice .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group .select2-choice .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout textarea .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group textarea .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row input.input-text .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group input.input-text .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row .select2-selection .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group .select2-selection .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row .select2-choice .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group .select2-choice .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row select .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-select.btn-group select .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row input .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-cart-form .table tr .quantity input .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr .quantity .form-select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-cart-form .table tr td.actions .coupon .input-text .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr td.actions .coupon .form-select.btn-group .input-text .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond input[type=text] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond textarea .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group textarea .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond input[type=email] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group input[type=email] .dropdown-toggle.btn-default,
select.btn-group .form-control .dropdown-toggle.btn-default,
select.btn-group .woocommerce-EditAccountForm.edit-account input[type=text] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .woocommerce-EditAccountForm.edit-account input[type=email] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account select.btn-group input[type=email] .dropdown-toggle.btn-default,
select.btn-group .woocommerce-EditAccountForm.edit-account input[type=password] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account select.btn-group input[type=password] .dropdown-toggle.btn-default,
select.btn-group .login input[type=text] .dropdown-toggle.btn-default,
.login select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .login input[type=password] .dropdown-toggle.btn-default,
.login select.btn-group input[type=password] .dropdown-toggle.btn-default,
select.btn-group .woocommerce-ResetPassword.lost_reset_password input[type=text] .dropdown-toggle.btn-default,
.woocommerce-ResetPassword.lost_reset_password select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout input[type=text] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout input[type=email] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group input[type=email] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout input[type=tel] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group input[type=tel] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout .select2-choice .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group .select2-choice .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout textarea .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group textarea .dropdown-toggle.btn-default,
select.btn-group .woocommerce form .form-row input.input-text .dropdown-toggle.btn-default,
.woocommerce form .form-row select.btn-group input.input-text .dropdown-toggle.btn-default,
select.btn-group .woocommerce form .form-row .select2-selection .dropdown-toggle.btn-default,
.woocommerce form .form-row select.btn-group .select2-selection .dropdown-toggle.btn-default,
select.btn-group .woocommerce form .form-row .select2-choice .dropdown-toggle.btn-default,
.woocommerce form .form-row select.btn-group .select2-choice .dropdown-toggle.btn-default,
select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row select .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row select.btn-group select .dropdown-toggle.btn-default,
select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row input .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row select.btn-group input .dropdown-toggle.btn-default,
select.btn-group .woocommerce-cart-form .table tr .quantity input .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr .quantity select.btn-group input .dropdown-toggle.btn-default,
select.btn-group .woocommerce-cart-form .table tr td.actions .coupon .input-text .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr td.actions .coupon select.btn-group .input-text .dropdown-toggle.btn-default,
select.btn-group .woocommerce #review_form #respond input[type=text] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .woocommerce #review_form #respond textarea .dropdown-toggle.btn-default,
.woocommerce #review_form #respond select.btn-group textarea .dropdown-toggle.btn-default,
select.btn-group .woocommerce #review_form #respond input[type=email] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond select.btn-group input[type=email] .dropdown-toggle.btn-default {
  padding: 0;
}

.form-select.btn-group .dropdown-toggle .caret,
select.btn-group .dropdown-toggle .caret {
  font-size: 10px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.form-select.btn-group .dropdown-toggle .caret:before,
select.btn-group .dropdown-toggle .caret:before {
  content: "\f078";
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}

.custom-file-label:after {
  content: none !important;
}

.dz-form.radius-no .form-control,
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type=text],
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type=email],
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type=password],
.dz-form.radius-no .login input[type=text],
.login .dz-form.radius-no input[type=text],
.dz-form.radius-no .login input[type=password],
.login .dz-form.radius-no input[type=password],
.dz-form.radius-no .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dz-form.radius-no input[type=text],
.dz-form.radius-no .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dz-form.radius-no input[type=text],
.dz-form.radius-no .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dz-form.radius-no input[type=email],
.dz-form.radius-no .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dz-form.radius-no input[type=tel],
.dz-form.radius-no .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dz-form.radius-no .select2-choice,
.dz-form.radius-no .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dz-form.radius-no input.input-text,
.dz-form.radius-no .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dz-form.radius-no .select2-selection,
.dz-form.radius-no .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dz-form.radius-no .select2-choice,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no select,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no input,
.dz-form.radius-no .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dz-form.radius-no input,
.dz-form.radius-no .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dz-form.radius-no .input-text,
.dz-form.radius-no .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dz-form.radius-no input[type=text],
.dz-form.radius-no .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dz-form.radius-no input[type=email] {
  border-radius: 0;
}

.dz-form.style-1 textarea {
  height: 185px;
  padding: 15px ​20px;
}

.form-check-input {
  width: 1.125em;
  height: 1.125em;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 !important;
  margin: 4px;
}

.form-check-input.radio {
  border-radius: 50% !important;
  border: 1px solid #24262B;
}

.form-check-input.radio:checked[type=radio] {
  background-color: #0D775E;
  border: 1px solid #0D775E;
}

.input-group {
  margin-bottom: 20px;
}

.wpcf7-form .row {
  --bs-gutter-x: 20px;
}

.checkout-form {
  margin-bottom: 40px;
}

.checkout-form .form-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}

.checkout-form .form-control,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=text],
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=email],
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=password],
.checkout-form .login input[type=text],
.login .checkout-form input[type=text],
.checkout-form .login input[type=password],
.login .checkout-form input[type=password],
.checkout-form .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .checkout-form input[type=text],
.checkout-form .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .checkout-form input[type=text],
.checkout-form .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .checkout-form input[type=email],
.checkout-form .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .checkout-form input[type=tel],
.checkout-form .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .checkout-form .select2-choice,
.checkout-form .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .checkout-form textarea,
.checkout-form .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .checkout-form input.input-text,
.checkout-form .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .checkout-form .select2-selection,
.checkout-form .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .checkout-form .select2-choice,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form textarea,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form select,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form input,
.checkout-form .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .checkout-form input,
.checkout-form .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .checkout-form .input-text,
.checkout-form .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .checkout-form input[type=text],
.checkout-form .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .checkout-form textarea,
.checkout-form .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .checkout-form input[type=email] {
  background: none;
  border: 1px solid transparent;
  border-bottom: 2px solid #E1E1E1;
  border-radius: 0;
  padding: 10px 0;
}

.checkout-form .form-control::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=text]::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=email]::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=password]::placeholder,
.checkout-form .login input[type=text]::placeholder,
.login .checkout-form input[type=text]::placeholder,
.checkout-form .login input[type=password]::placeholder,
.login .checkout-form input[type=password]::placeholder,
.checkout-form .woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .checkout-form input[type=text]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type=text]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type=email]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type=tel]::placeholder,
.checkout-form .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .checkout-form .select2-choice::placeholder,
.checkout-form .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .checkout-form textarea::placeholder,
.checkout-form .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .checkout-form input.input-text::placeholder,
.checkout-form .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .checkout-form .select2-selection::placeholder,
.checkout-form .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .checkout-form .select2-choice::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form textarea::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form select::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form input::placeholder,
.checkout-form .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .checkout-form input::placeholder,
.checkout-form .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .checkout-form .input-text::placeholder,
.checkout-form .woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond .checkout-form input[type=text]::placeholder,
.checkout-form .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .checkout-form textarea::placeholder,
.checkout-form .woocommerce #review_form #respond input[type=email]::placeholder,
.woocommerce #review_form #respond .checkout-form input[type=email]::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: var(--seconday);
}

.pay-bottom {
  padding-bottom: 55px;
  border-bottom: 2px dashed #AAAAAA;
}

.pay-bottom .money-type {
  position: relative;
  text-align: center;
}

.pay-bottom .money-type .form-check {
  border: 1px solid #F0F0F0;
  border-radius: var(--border-radius-base);
  padding-left: 0;
}

.pay-bottom .money-type .form-check .form-check-input {
  position: absolute;
  right: 15px;
  top: 15px;
}

.pay-bottom .money-type .form-check .form-check-input:checked+.form-check-label {
  background-color: #FFF0DF;
  border: 1px solid var(--primary);
  border-radius: var(--border-radius-base);
}

.pay-bottom .money-type .form-check .form-check-input:checked+.form-check-label i {
  color: var(--primary);
}

.pay-bottom .money-type .form-check .form-check-label {
  width: 100%;
  margin: 0 !important;
  padding: 10px 10px;
}

.pay-bottom .money-type .form-check .payment-check i {
  font-size: 68px;
}

.input-group-text.checkout-text {
  background: none;
  border-color: transparent;
  position: relative;
  right: 60px;
}

.input-group-text.checkout-text i {
  font-size: 20px;
  font-weight: 600;
}

.form-control.default-select .btn,
.form-control.default-select .woocommerce #respond input#submit,
.woocommerce #respond .form-control.default-select input#submit,
.form-control.default-select .woocommerce a.button,
.woocommerce .form-control.default-select a.button,
.form-control.default-select .woocommerce button.button,
.woocommerce .form-control.default-select button.button,
.form-control.default-select .woocommerce input.button,
.woocommerce .form-control.default-select input.button,
.form-control.default-select .woocommerce #place_order,
.woocommerce .form-control.default-select #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] #place_order,
.login input.default-select[type=text] .btn,
.login input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .login input.default-select[type=text] input#submit,
.login input.default-select[type=text] .woocommerce a.button,
.woocommerce .login input.default-select[type=text] a.button,
.login input.default-select[type=text] .woocommerce button.button,
.woocommerce .login input.default-select[type=text] button.button,
.login input.default-select[type=text] .woocommerce input.button,
.woocommerce .login input.default-select[type=text] input.button,
.login input.default-select[type=text] .woocommerce #place_order,
.woocommerce .login input.default-select[type=text] #place_order,
.login input.default-select[type=password] .btn,
.login input.default-select[type=password] .woocommerce #respond input#submit,
.woocommerce #respond .login input.default-select[type=password] input#submit,
.login input.default-select[type=password] .woocommerce a.button,
.woocommerce .login input.default-select[type=password] a.button,
.login input.default-select[type=password] .woocommerce button.button,
.woocommerce .login input.default-select[type=password] button.button,
.login input.default-select[type=password] .woocommerce input.button,
.woocommerce .login input.default-select[type=password] input.button,
.login input.default-select[type=password] .woocommerce #place_order,
.woocommerce .login input.default-select[type=password] #place_order,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .btn,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input#submit,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce a.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce button.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] button.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce input.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #place_order,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] #place_order,
.checkout.woocommerce-checkout input.default-select[type=text] .btn,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=text] input#submit,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] a.button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] button.button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] input.button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] #place_order,
.checkout.woocommerce-checkout input.default-select[type=email] .btn,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=email] input#submit,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] a.button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] button.button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] input.button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] #place_order,
.checkout.woocommerce-checkout input.default-select[type=tel] .btn,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=tel] input#submit,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] a.button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] button.button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] input.button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] #place_order,
.checkout.woocommerce-checkout .default-select.select2-choice .btn,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout .default-select.select2-choice input#submit,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice a.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice button.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice input.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice #place_order,
.checkout.woocommerce-checkout textarea.default-select .btn,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout textarea.default-select input#submit,
.checkout.woocommerce-checkout textarea.default-select .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select a.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select button.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select input.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout textarea.default-select #place_order,
.woocommerce form .form-row input.default-select.input-text .btn,
.woocommerce form .form-row input.default-select.input-text #respond input#submit,
.woocommerce #respond form .form-row input.default-select.input-text input#submit,
.woocommerce form .form-row input.default-select.input-text a.button,
.woocommerce form .form-row input.default-select.input-text button.button,
.woocommerce form .form-row input.default-select.input-text input.button,
.woocommerce form .form-row input.default-select.input-text #place_order,
.woocommerce form .form-row .default-select.select2-selection .btn,
.woocommerce form .form-row .default-select.select2-selection #respond input#submit,
.woocommerce #respond form .form-row .default-select.select2-selection input#submit,
.woocommerce form .form-row .default-select.select2-selection a.button,
.woocommerce form .form-row .default-select.select2-selection button.button,
.woocommerce form .form-row .default-select.select2-selection input.button,
.woocommerce form .form-row .default-select.select2-selection #place_order,
.woocommerce form .form-row .default-select.select2-choice .btn,
.woocommerce form .form-row .default-select.select2-choice #respond input#submit,
.woocommerce #respond form .form-row .default-select.select2-choice input#submit,
.woocommerce form .form-row .default-select.select2-choice a.button,
.woocommerce form .form-row .default-select.select2-choice button.button,
.woocommerce form .form-row .default-select.select2-choice input.button,
.woocommerce form .form-row .default-select.select2-choice #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row textarea.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row select.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #place_order,
.form-control.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.login input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.login input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .form-control.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.checkout-button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.checkout-button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.checkout-button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=text] a.checkout-button,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=password] a.checkout-button,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.checkout-button,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=text] a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=email] a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=tel] a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=tel] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout .default-select.select2-choice a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout textarea.default-select a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row input.default-select.input-text a.checkout-button,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select.input-text a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-selection a.checkout-button,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-selection a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-choice a.checkout-button,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout select.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select a.checkout-button,
.form-control.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart-form .table tr .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart-form .table tr .button,
.login input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.login input.default-select[type=password] .woocommerce-cart-form .table tr .button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart-form .table tr .button,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart-form .table tr .button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart-form .table tr .button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .form-control.default-select .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=text] .button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=email] .button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=email] .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=password] .button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=password] .button,
.woocommerce-cart-form .table tr .login input.default-select[type=text] .button,
.login .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .login input.default-select[type=password] .button,
.login .woocommerce-cart-form .table tr input.default-select[type=password] .button,
.woocommerce-cart-form .table tr .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .button,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=text] .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=email] .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=email] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=tel] .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=tel] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout .default-select.select2-choice .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr .default-select.select2-choice .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout textarea.default-select .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr textarea.default-select .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row input.default-select.input-text .button,
.woocommerce form .form-row .woocommerce-cart-form .table tr input.default-select.input-text .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-selection .button,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-selection .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-choice .button,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-choice .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr textarea.default-select .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr select.default-select .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr input.default-select .button,
.woocommerce-cart-form .table tr .quantity input.default-select .btn,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr .quantity input.default-select input#submit,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select a.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select button.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select input.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #place_order,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select #place_order,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .quantity input.default-select a.checkout-button,
.woocommerce-cart-form .table tr .quantity input.default-select .button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .btn,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input#submit,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text button.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #place_order,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text #place_order,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.checkout-button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .button,
.form-control.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #review_form #respond .form-submit input,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #review_form #respond .form-submit input,
.login input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.login input.default-select[type=password] .woocommerce #review_form #respond .form-submit input,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce form .form-row input.default-select.input-text .woocommerce #review_form #respond .form-submit input,
.woocommerce form .form-row .default-select.select2-selection .woocommerce #review_form #respond .form-submit input,
.woocommerce form .form-row .default-select.select2-choice .woocommerce #review_form #respond .form-submit input,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .form-control.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=email] input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=password] input,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=text] input,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=password] input,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=password] input,
.woocommerce #review_form #respond .form-submit .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input,
.woocommerce-ResetPassword.lost_reset_password .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=text] input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=email] input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=email] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=tel] input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=tel] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout .default-select.select2-choice input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit .default-select.select2-choice input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout textarea.default-select input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit textarea.default-select input,
.woocommerce #review_form #respond .form-submit form .form-row input.default-select.input-text input,
.woocommerce form .form-row #review_form #respond .form-submit input.default-select.input-text input,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-selection input,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-selection input,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-choice input,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-choice input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row textarea.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit textarea.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row select.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit select.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit input.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .quantity input.default-select input,
.woocommerce-cart-form .table tr .quantity .woocommerce #review_form #respond .form-submit input.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce #review_form #respond .form-submit .default-select.input-text input,
.woocommerce #review_form #respond input.default-select[type=text] .btn,
.woocommerce #review_form #respond input.default-select[type=text] input#submit,
.woocommerce #review_form #respond input.default-select[type=text] a.button,
.woocommerce #review_form #respond input.default-select[type=text] button.button,
.woocommerce #review_form #respond input.default-select[type=text] input.button,
.woocommerce #review_form #respond input.default-select[type=text] #place_order,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=text] a.checkout-button,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=text] .button,
.woocommerce #review_form #respond input.default-select[type=text] .form-submit input,
.woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond textarea.default-select .btn,
.woocommerce #review_form #respond textarea.default-select input#submit,
.woocommerce #review_form #respond textarea.default-select a.button,
.woocommerce #review_form #respond textarea.default-select button.button,
.woocommerce #review_form #respond textarea.default-select input.button,
.woocommerce #review_form #respond textarea.default-select #place_order,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond textarea.default-select a.checkout-button,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond textarea.default-select .button,
.woocommerce #review_form #respond textarea.default-select .form-submit input,
.woocommerce #review_form #respond .form-submit textarea.default-select input,
.woocommerce #review_form #respond input.default-select[type=email] .btn,
.woocommerce #review_form #respond input.default-select[type=email] input#submit,
.woocommerce #review_form #respond input.default-select[type=email] a.button,
.woocommerce #review_form #respond input.default-select[type=email] button.button,
.woocommerce #review_form #respond input.default-select[type=email] input.button,
.woocommerce #review_form #respond input.default-select[type=email] #place_order,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=email] a.checkout-button,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=email] .button,
.woocommerce #review_form #respond input.default-select[type=email] .form-submit input,
.woocommerce #review_form #respond .form-submit input.default-select[type=email] input,
.form-control.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a,
.login input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.login input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout textarea.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce form .form-row input.default-select.input-text .woocommerce .widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-selection .woocommerce .widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce .widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce #review_form #respond textarea.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .form-control.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=text] a,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=password] a,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a,
.woocommerce-ResetPassword.lost_reset_password .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=tel] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons .default-select.select2-choice a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons textarea.default-select a,
.woocommerce .widget_shopping_cart .buttons form .form-row input.default-select.input-text a,
.woocommerce form .form-row .widget_shopping_cart .buttons input.default-select.input-text a,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-selection a,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-selection a,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-choice a,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-choice a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons textarea.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons select.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons input.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a,
.woocommerce-cart-form .table tr .quantity .woocommerce .widget_shopping_cart .buttons input.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce .widget_shopping_cart .buttons .default-select.input-text a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond textarea.default-select a,
.woocommerce #review_form #respond .widget_shopping_cart .buttons textarea.default-select a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=email] a,
.form-control.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a,
.login input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.login input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout textarea.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce form .form-row input.default-select.input-text .woocommerce.widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-selection .woocommerce.widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond textarea.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .form-control.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=text] a,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=password] a,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a,
.woocommerce-ResetPassword.lost_reset_password .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=tel] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons .default-select.select2-choice a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons textarea.default-select a,
.woocommerce.widget_shopping_cart .buttons form .form-row input.default-select.input-text a,
.woocommerce.widget_shopping_cart form .form-row .buttons input.default-select.input-text a,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-selection a,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-selection a,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-choice a,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-choice a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons textarea.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons select.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons input.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a,
.woocommerce-cart-form .table tr .quantity .woocommerce.widget_shopping_cart .buttons input.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce.widget_shopping_cart .buttons .default-select.input-text a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond textarea.default-select a,
.woocommerce.widget_shopping_cart #review_form #respond .buttons textarea.default-select a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=email] a,
.form-control.default-select .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .wp-block-button__link,
.login input.default-select[type=text] .wp-block-button__link,
.login input.default-select[type=password] .wp-block-button__link,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type=text] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type=email] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type=tel] .wp-block-button__link,
.checkout.woocommerce-checkout .default-select.select2-choice .wp-block-button__link,
.checkout.woocommerce-checkout textarea.default-select .wp-block-button__link,
.woocommerce form .form-row input.default-select.input-text .wp-block-button__link,
.woocommerce form .form-row .default-select.select2-selection .wp-block-button__link,
.woocommerce form .form-row .default-select.select2-choice .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .wp-block-button__link,
.woocommerce-cart-form .table tr .quantity input.default-select .wp-block-button__link,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .wp-block-button__link,
.woocommerce #review_form #respond input.default-select[type=text] .wp-block-button__link,
.woocommerce #review_form #respond textarea.default-select .wp-block-button__link,
.woocommerce #review_form #respond input.default-select[type=email] .wp-block-button__link {
  background: transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  padding: 15px 10px !important;
  border-color: #E1E1E1;
  padding: 10px 20px;
}

.form-control.default-select .btn:focus,
.form-control.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .form-control.default-select input#submit:focus,
.form-control.default-select .woocommerce a.button:focus,
.woocommerce .form-control.default-select a.button:focus,
.form-control.default-select .woocommerce button.button:focus,
.woocommerce .form-control.default-select button.button:focus,
.form-control.default-select .woocommerce input.button:focus,
.woocommerce .form-control.default-select input.button:focus,
.form-control.default-select .woocommerce #place_order:focus,
.woocommerce .form-control.default-select #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] #place_order:focus,
.login input.default-select[type=text] .btn:focus,
.login input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .login input.default-select[type=text] input#submit:focus,
.login input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .login input.default-select[type=text] a.button:focus,
.login input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .login input.default-select[type=text] button.button:focus,
.login input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .login input.default-select[type=text] input.button:focus,
.login input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .login input.default-select[type=text] #place_order:focus,
.login input.default-select[type=password] .btn:focus,
.login input.default-select[type=password] .woocommerce #respond input#submit:focus,
.woocommerce #respond .login input.default-select[type=password] input#submit:focus,
.login input.default-select[type=password] .woocommerce a.button:focus,
.woocommerce .login input.default-select[type=password] a.button:focus,
.login input.default-select[type=password] .woocommerce button.button:focus,
.woocommerce .login input.default-select[type=password] button.button:focus,
.login input.default-select[type=password] .woocommerce input.button:focus,
.woocommerce .login input.default-select[type=password] input.button:focus,
.login input.default-select[type=password] .woocommerce #place_order:focus,
.woocommerce .login input.default-select[type=password] #place_order:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .btn:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input#submit:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] button.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=text] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=email] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=tel] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] #place_order:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .btn:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout .default-select.select2-choice input#submit:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice a.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice button.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice input.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice #place_order:focus,
.checkout.woocommerce-checkout textarea.default-select .btn:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout textarea.default-select input#submit:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select a.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select button.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select input.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select #place_order:focus,
.woocommerce form .form-row input.default-select.input-text .btn:focus,
.woocommerce form .form-row input.default-select.input-text #respond input#submit:focus,
.woocommerce #respond form .form-row input.default-select.input-text input#submit:focus,
.woocommerce form .form-row input.default-select.input-text a.button:focus,
.woocommerce form .form-row input.default-select.input-text button.button:focus,
.woocommerce form .form-row input.default-select.input-text input.button:focus,
.woocommerce form .form-row input.default-select.input-text #place_order:focus,
.woocommerce form .form-row .default-select.select2-selection .btn:focus,
.woocommerce form .form-row .default-select.select2-selection #respond input#submit:focus,
.woocommerce #respond form .form-row .default-select.select2-selection input#submit:focus,
.woocommerce form .form-row .default-select.select2-selection a.button:focus,
.woocommerce form .form-row .default-select.select2-selection button.button:focus,
.woocommerce form .form-row .default-select.select2-selection input.button:focus,
.woocommerce form .form-row .default-select.select2-selection #place_order:focus,
.woocommerce form .form-row .default-select.select2-choice .btn:focus,
.woocommerce form .form-row .default-select.select2-choice #respond input#submit:focus,
.woocommerce #respond form .form-row .default-select.select2-choice input#submit:focus,
.woocommerce form .form-row .default-select.select2-choice a.button:focus,
.woocommerce form .form-row .default-select.select2-choice button.button:focus,
.woocommerce form .form-row .default-select.select2-choice input.button:focus,
.woocommerce form .form-row .default-select.select2-choice #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row textarea.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row select.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #place_order:focus,
.form-control.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.login input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.login input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .form-control.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=text] a.checkout-button:focus,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=password] a.checkout-button:focus,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.checkout-button:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=text] a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=email] a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=tel] a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=tel] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout .default-select.select2-choice a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout textarea.default-select a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row input.default-select.input-text a.checkout-button:focus,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select.input-text a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-selection a.checkout-button:focus,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-selection a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-choice a.checkout-button:focus,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout select.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select a.checkout-button:focus,
.form-control.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart-form .table tr .button:focus,
.login input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.login input.default-select[type=password] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart-form .table tr .button:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart-form .table tr .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .form-control.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=text] .button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=email] .button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=email] .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=password] .button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=password] .button:focus,
.woocommerce-cart-form .table tr .login input.default-select[type=text] .button:focus,
.login .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .login input.default-select[type=password] .button:focus,
.login .woocommerce-cart-form .table tr input.default-select[type=password] .button:focus,
.woocommerce-cart-form .table tr .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .button:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=text] .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=email] .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=email] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=tel] .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=tel] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout .default-select.select2-choice .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr .default-select.select2-choice .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout textarea.default-select .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr textarea.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row input.default-select.input-text .button:focus,
.woocommerce form .form-row .woocommerce-cart-form .table tr input.default-select.input-text .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-selection .button:focus,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-selection .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-choice .button:focus,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-choice .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr textarea.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr select.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr input.default-select .button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .btn:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-cart-form .table tr .quantity input.default-select input#submit:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce a.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select a.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce button.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select button.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce input.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select input.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #place_order:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select #place_order:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .quantity input.default-select a.checkout-button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .btn:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input#submit:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce a.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce button.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text button.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce input.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #place_order:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text #place_order:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.checkout-button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .button:focus,
.form-control.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #review_form #respond .form-submit input:focus,
.login input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.login input.default-select[type=password] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .form-control.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=email] input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=password] input:focus,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=text] input:focus,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=password] input:focus,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=password] input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=text] input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=email] input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=email] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=tel] input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=tel] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout .default-select.select2-choice input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit .default-select.select2-choice input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout textarea.default-select input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit textarea.default-select input:focus,
.woocommerce #review_form #respond .form-submit form .form-row input.default-select.input-text input:focus,
.woocommerce form .form-row #review_form #respond .form-submit input.default-select.input-text input:focus,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-selection input:focus,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-selection input:focus,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-choice input:focus,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-choice input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row textarea.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit textarea.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row select.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit select.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit input.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .quantity input.default-select input:focus,
.woocommerce-cart-form .table tr .quantity .woocommerce #review_form #respond .form-submit input.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce #review_form #respond .form-submit .default-select.input-text input:focus,
.woocommerce #review_form #respond input.default-select[type=text] .btn:focus,
.woocommerce #review_form #respond input.default-select[type=text] input#submit:focus,
.woocommerce #review_form #respond input.default-select[type=text] a.button:focus,
.woocommerce #review_form #respond input.default-select[type=text] button.button:focus,
.woocommerce #review_form #respond input.default-select[type=text] input.button:focus,
.woocommerce #review_form #respond input.default-select[type=text] #place_order:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=text] a.checkout-button:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=text] .button:focus,
.woocommerce #review_form #respond input.default-select[type=text] .form-submit input:focus,
.woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond textarea.default-select .btn:focus,
.woocommerce #review_form #respond textarea.default-select input#submit:focus,
.woocommerce #review_form #respond textarea.default-select a.button:focus,
.woocommerce #review_form #respond textarea.default-select button.button:focus,
.woocommerce #review_form #respond textarea.default-select input.button:focus,
.woocommerce #review_form #respond textarea.default-select #place_order:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond textarea.default-select a.checkout-button:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond textarea.default-select .button:focus,
.woocommerce #review_form #respond textarea.default-select .form-submit input:focus,
.woocommerce #review_form #respond .form-submit textarea.default-select input:focus,
.woocommerce #review_form #respond input.default-select[type=email] .btn:focus,
.woocommerce #review_form #respond input.default-select[type=email] input#submit:focus,
.woocommerce #review_form #respond input.default-select[type=email] a.button:focus,
.woocommerce #review_form #respond input.default-select[type=email] button.button:focus,
.woocommerce #review_form #respond input.default-select[type=email] input.button:focus,
.woocommerce #review_form #respond input.default-select[type=email] #place_order:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=email] a.checkout-button:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=email] .button:focus,
.woocommerce #review_form #respond input.default-select[type=email] .form-submit input:focus,
.woocommerce #review_form #respond .form-submit input.default-select[type=email] input:focus,
.form-control.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a:focus,
.login input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.login input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .form-control.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=text] a:focus,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=password] a:focus,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=tel] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons .default-select.select2-choice a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row input.default-select.input-text a:focus,
.woocommerce form .form-row .widget_shopping_cart .buttons input.default-select.input-text a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-selection a:focus,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-selection a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-choice a:focus,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-choice a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons select.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons input.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a:focus,
.woocommerce-cart-form .table tr .quantity .woocommerce .widget_shopping_cart .buttons input.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce .widget_shopping_cart .buttons .default-select.input-text a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a:focus,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond textarea.default-select a:focus,
.woocommerce #review_form #respond .widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a:focus,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.form-control.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a:focus,
.login input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.login input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .form-control.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=text] a:focus,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=password] a:focus,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=tel] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons .default-select.select2-choice a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row input.default-select.input-text a:focus,
.woocommerce.widget_shopping_cart form .form-row .buttons input.default-select.input-text a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-selection a:focus,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-selection a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-choice a:focus,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-choice a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons select.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons input.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a:focus,
.woocommerce-cart-form .table tr .quantity .woocommerce.widget_shopping_cart .buttons input.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce.widget_shopping_cart .buttons .default-select.input-text a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond textarea.default-select a:focus,
.woocommerce.widget_shopping_cart #review_form #respond .buttons textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a:focus,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=email] a:focus,
.form-control.default-select .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .wp-block-button__link:focus,
.login input.default-select[type=text] .wp-block-button__link:focus,
.login input.default-select[type=password] .wp-block-button__link:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .wp-block-button__link:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .wp-block-button__link:focus,
.checkout.woocommerce-checkout textarea.default-select .wp-block-button__link:focus,
.woocommerce form .form-row input.default-select.input-text .wp-block-button__link:focus,
.woocommerce form .form-row .default-select.select2-selection .wp-block-button__link:focus,
.woocommerce form .form-row .default-select.select2-choice .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .wp-block-button__link:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .wp-block-button__link:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .wp-block-button__link:focus,
.woocommerce #review_form #respond input.default-select[type=text] .wp-block-button__link:focus,
.woocommerce #review_form #respond textarea.default-select .wp-block-button__link:focus,
.woocommerce #review_form #respond input.default-select[type=email] .wp-block-button__link:focus {
  outline: 0 !important;
}

.form-control.default-select .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .filter-option-inner-inner,
.login input.default-select[type=text] .filter-option-inner-inner,
.login input.default-select[type=password] .filter-option-inner-inner,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type=text] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type=email] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type=tel] .filter-option-inner-inner,
.checkout.woocommerce-checkout .default-select.select2-choice .filter-option-inner-inner,
.checkout.woocommerce-checkout textarea.default-select .filter-option-inner-inner,
.woocommerce form .form-row input.default-select.input-text .filter-option-inner-inner,
.woocommerce form .form-row .default-select.select2-selection .filter-option-inner-inner,
.woocommerce form .form-row .default-select.select2-choice .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .filter-option-inner-inner,
.woocommerce-cart-form .table tr .quantity input.default-select .filter-option-inner-inner,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .filter-option-inner-inner,
.woocommerce #review_form #respond input.default-select[type=text] .filter-option-inner-inner,
.woocommerce #review_form #respond textarea.default-select .filter-option-inner-inner,
.woocommerce #review_form #respond input.default-select[type=email] .filter-option-inner-inner {
  font-weight: 600;
  font-size: 16px;
}

.form-check {
  padding-left: 0;
  padding-right: 0;
}

.form-check span {
  height: 1rem;
  margin-right: 15px;
  width: 1rem;
  display: block;
  background-color: #000;
  position: relative;
  border-radius: 50%;
  z-index: 1;
}

.form-check span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: 1px solid rgb(36, 38, 43);
  border-radius: 50%;
  opacity: 0.2;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
}

.form-check .form-check-input {
  opacity: 0;
  position: relative;
  z-index: 2;
  margin-left: 0;
  margin-top: 0;
}

.form-check .form-check-input:checked+span:after {
  transform: translate(-50%, -50%) scale(1);
}

.shop-form .form-group {
  margin-bottom: 25px;
}

.shop-form .form-group input {
  padding: 17px 20px;
  height: 50px;
  font-size: 15px;
  border: none;
  color: var(--title);
  border: 1px solid var(--secondary);
}

.shop-form .form-group input:placeholder {
  color: var(--title);
}

.shop-form .form-group .input-group-addon {
  position: absolute;
  right: 0px;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}

.shop-form .form-group .input-group-addon .coupon {
  color: #24262B;
  font-family: "DM Sans";
  font-weight: 700;
  padding: 10px 15px;
  font-size: 15px;
  position: relative;
  z-index: 2;
  border: 0;
}

.shop-form .form-group .bootstrap-select {
  width: 100% !important;
}

.shop-form .form-group .bootstrap-select .btn,
.shop-form .form-group .bootstrap-select .woocommerce #respond input#submit,
.woocommerce #respond .shop-form .form-group .bootstrap-select input#submit,
.shop-form .form-group .bootstrap-select .woocommerce a.button,
.woocommerce .shop-form .form-group .bootstrap-select a.button,
.shop-form .form-group .bootstrap-select .woocommerce button.button,
.woocommerce .shop-form .form-group .bootstrap-select button.button,
.shop-form .form-group .bootstrap-select .woocommerce input.button,
.woocommerce .shop-form .form-group .bootstrap-select input.button,
.shop-form .form-group .bootstrap-select .woocommerce #place_order,
.woocommerce .shop-form .form-group .bootstrap-select #place_order,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.checkout-button,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .button,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a,
.shop-form .form-group .bootstrap-select .wp-block-button__link {
  font-weight: 300;
  color: #000;
}

.shop-form .form-group .bootstrap-select .btn.btn-light,
.shop-form .form-group .bootstrap-select .woocommerce #respond input.btn-light#submit,
.woocommerce #respond .shop-form .form-group .bootstrap-select input.btn-light#submit,
.shop-form .form-group .bootstrap-select .woocommerce a.btn-light.button,
.woocommerce .shop-form .form-group .bootstrap-select a.btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce button.btn-light.button,
.woocommerce .shop-form .form-group .bootstrap-select button.btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce input.btn-light.button,
.woocommerce .shop-form .form-group .bootstrap-select input.btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce .btn-light#place_order,
.woocommerce .shop-form .form-group .bootstrap-select .btn-light#place_order,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.btn-light.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.btn-light.checkout-button,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .btn-light.button,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .btn-light.button,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input.btn-light,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input.btn-light,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a.btn-light,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a.btn-light,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light,
.shop-form .form-group .bootstrap-select .btn-light.wp-block-button__link {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.shop-form .form-group .bootstrap-select .btn.btn-light:hover,
.shop-form .form-group .bootstrap-select .woocommerce #respond input.btn-light#submit:hover,
.woocommerce #respond .shop-form .form-group .bootstrap-select input.btn-light#submit:hover,
.shop-form .form-group .bootstrap-select .woocommerce a.btn-light.button:hover,
.woocommerce .shop-form .form-group .bootstrap-select a.btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce button.btn-light.button:hover,
.woocommerce .shop-form .form-group .bootstrap-select button.btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce input.btn-light.button:hover,
.woocommerce .shop-form .form-group .bootstrap-select input.btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce .btn-light#place_order:hover,
.woocommerce .shop-form .form-group .bootstrap-select .btn-light#place_order:hover,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.btn-light.checkout-button:hover,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.btn-light.checkout-button:hover,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .btn-light.button:hover,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .btn-light.button:hover,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input.btn-light:hover,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input.btn-light:hover,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a.btn-light:hover,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:hover,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a.btn-light:hover,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:hover,
.shop-form .form-group .bootstrap-select .btn-light.wp-block-button__link:hover {
  background-color: rgb(245, 245, 245);
  border-color: 1px solid rgba(0, 0, 0, 0.125);
}

.shop-form .form-group .bootstrap-select .btn.btn-light:focus,
.shop-form .form-group .bootstrap-select .woocommerce #respond input.btn-light#submit:focus,
.woocommerce #respond .shop-form .form-group .bootstrap-select input.btn-light#submit:focus,
.shop-form .form-group .bootstrap-select .woocommerce a.btn-light.button:focus,
.woocommerce .shop-form .form-group .bootstrap-select a.btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce button.btn-light.button:focus,
.woocommerce .shop-form .form-group .bootstrap-select button.btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce input.btn-light.button:focus,
.woocommerce .shop-form .form-group .bootstrap-select input.btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce .btn-light#place_order:focus,
.woocommerce .shop-form .form-group .bootstrap-select .btn-light#place_order:focus,
.shop-form .form-group .bootstrap-select .woocommerce-cart .wc-proceed-to-checkout a.btn-light.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .shop-form .form-group .bootstrap-select a.btn-light.checkout-button:focus,
.shop-form .form-group .bootstrap-select .woocommerce-cart-form .table tr .btn-light.button:focus,
.woocommerce-cart-form .table tr .shop-form .form-group .bootstrap-select .btn-light.button:focus,
.shop-form .form-group .bootstrap-select .woocommerce #review_form #respond .form-submit input.btn-light:focus,
.woocommerce #review_form #respond .form-submit .shop-form .form-group .bootstrap-select input.btn-light:focus,
.shop-form .form-group .bootstrap-select .woocommerce .widget_shopping_cart .buttons a.btn-light:focus,
.woocommerce .widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:focus,
.shop-form .form-group .bootstrap-select .woocommerce.widget_shopping_cart .buttons a.btn-light:focus,
.woocommerce.widget_shopping_cart .buttons .shop-form .form-group .bootstrap-select a.btn-light:focus,
.shop-form .form-group .bootstrap-select .btn-light.wp-block-button__link:focus {
  outline: 0 !important;
}

.shop-form .btn.btn-grey,
.shop-form .woocommerce #respond input.btn-grey#submit,
.woocommerce #respond .shop-form input.btn-grey#submit,
.shop-form .woocommerce a.btn-grey.button,
.woocommerce .shop-form a.btn-grey.button,
.shop-form .woocommerce button.btn-grey.button,
.woocommerce .shop-form button.btn-grey.button,
.shop-form .woocommerce input.btn-grey.button,
.woocommerce .shop-form input.btn-grey.button,
.shop-form .woocommerce .btn-grey#place_order,
.woocommerce .shop-form .btn-grey#place_order,
.shop-form .woocommerce-cart .wc-proceed-to-checkout a.btn-grey.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .shop-form a.btn-grey.checkout-button,
.shop-form .woocommerce-cart-form .table tr .btn-grey.button,
.woocommerce-cart-form .table tr .shop-form .btn-grey.button,
.shop-form .woocommerce #review_form #respond .form-submit input.btn-grey,
.woocommerce #review_form #respond .form-submit .shop-form input.btn-grey,
.shop-form .woocommerce .widget_shopping_cart .buttons a.btn-grey,
.woocommerce .widget_shopping_cart .buttons .shop-form a.btn-grey,
.shop-form .woocommerce.widget_shopping_cart .buttons a.btn-grey,
.woocommerce.widget_shopping_cart .buttons .shop-form a.btn-grey,
.shop-form .btn-grey.wp-block-button__link {
  background: rgba(36, 38, 43, 0.5);
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .shop-form {
    margin-top: 25px;
  }
}

.dz-ajax-overlay {
  position: relative;
}

.dz-ajax-overlay .btn:before,
.dz-ajax-overlay .woocommerce #respond input#submit:before,
.woocommerce #respond .dz-ajax-overlay input#submit:before,
.dz-ajax-overlay .woocommerce a.button:before,
.woocommerce .dz-ajax-overlay a.button:before,
.dz-ajax-overlay .woocommerce button.button:before,
.woocommerce .dz-ajax-overlay button.button:before,
.dz-ajax-overlay .woocommerce input.button:before,
.woocommerce .dz-ajax-overlay input.button:before,
.dz-ajax-overlay .woocommerce #place_order:before,
.woocommerce .dz-ajax-overlay #place_order:before,
.dz-ajax-overlay .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:before,
.woocommerce-cart .wc-proceed-to-checkout .dz-ajax-overlay a.checkout-button:before,
.dz-ajax-overlay .woocommerce-cart-form .table tr .button:before,
.woocommerce-cart-form .table tr .dz-ajax-overlay .button:before,
.dz-ajax-overlay .woocommerce #review_form #respond .form-submit input:before,
.woocommerce #review_form #respond .form-submit .dz-ajax-overlay input:before,
.dz-ajax-overlay .woocommerce .widget_shopping_cart .buttons a:before,
.woocommerce .widget_shopping_cart .buttons .dz-ajax-overlay a:before,
.dz-ajax-overlay .woocommerce.widget_shopping_cart .buttons a:before,
.woocommerce.widget_shopping_cart .buttons .dz-ajax-overlay a:before,
.dz-ajax-overlay .wp-block-button__link:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  border-radius: 0;
  opacity: 0.8;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve"> <rect x="0" y="13" width="4" height="5" fill="%23fff"> <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate> <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate> </rect> <rect x="10" y="13" width="4" height="5" fill="%23fff"> <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate> <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate> </rect> <rect x="20" y="13" width="4" height="5" fill="%23fff"> <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate> <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate> </rect> </svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

.form-label {
  font-size: 16px;
  font-weight: 700;
  color: var(--title);
  margin-bottom: 10px;
  background: transparent;
  border-color: #D7D7D7;
  font-family: var(--font-family-title);
}

.form-control,
.woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account input[type=password],
.login input[type=text],
.login input[type=password],
.woocommerce-ResetPassword.lost_reset_password input[type=text],
.checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel],
.woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond input[type=email] {
  height: 50px;
  border: 1px solid var(--secondary);
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: var(--title);
  transition: all 0.3s ease-in-out;
  border-radius: 10px !important;
}

.form-control .select,
.woocommerce-EditAccountForm.edit-account input[type=text] .select,
.woocommerce-EditAccountForm.edit-account input[type=email] .select,
.woocommerce-EditAccountForm.edit-account input[type=password] .select,
.login input[type=text] .select,
.login input[type=password] .select,
.woocommerce-ResetPassword.lost_reset_password input[type=text] .select,
.checkout.woocommerce-checkout input[type=text] .select,
.checkout.woocommerce-checkout input[type=email] .select,
.checkout.woocommerce-checkout input[type=tel] .select,
.checkout.woocommerce-checkout .select2-choice .select,
.checkout.woocommerce-checkout textarea .select,
.woocommerce form .form-row input.input-text .select,
.woocommerce form .form-row .select2-selection .select,
.woocommerce form .form-row .select2-choice .select,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea .select,
.woocommerce .woocommerce-checkout.checkout form .form-row select .select,
.woocommerce .woocommerce-checkout.checkout form .form-row input .select,
.woocommerce-cart-form .table tr .quantity input .select,
.woocommerce-cart-form .table tr td.actions .coupon .input-text .select,
.woocommerce #review_form #respond input[type=text] .select,
.woocommerce #review_form #respond textarea .select,
.woocommerce #review_form #respond input[type=email] .select {
  padding: 12px 0;
}

.form-control span,
.woocommerce-EditAccountForm.edit-account input[type=text] span,
.woocommerce-EditAccountForm.edit-account input[type=email] span,
.woocommerce-EditAccountForm.edit-account input[type=password] span,
.login input[type=text] span,
.login input[type=password] span,
.woocommerce-ResetPassword.lost_reset_password input[type=text] span,
.checkout.woocommerce-checkout input[type=text] span,
.checkout.woocommerce-checkout input[type=email] span,
.checkout.woocommerce-checkout input[type=tel] span,
.checkout.woocommerce-checkout .select2-choice span,
.checkout.woocommerce-checkout textarea span,
.woocommerce form .form-row input.input-text span,
.woocommerce form .form-row .select2-selection span,
.woocommerce form .form-row .select2-choice span,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text] span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email] span,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel] span,
.woocommerce .woocommerce-checkout.checkout form .form-row select span,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text span,
.woocommerce .woocommerce-checkout.checkout form .form-row input span,
.woocommerce-cart-form .table tr .quantity input span,
.woocommerce-cart-form .table tr td.actions .coupon .input-text span,
.woocommerce #review_form #respond input[type=text] span,
.woocommerce #review_form #respond textarea span,
.woocommerce #review_form #respond input[type=email] span {
  margin-top: 0;
}

.form-control::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=text]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=email]::-webkit-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=password]::-webkit-input-placeholder,
.login input[type=text]::-webkit-input-placeholder,
.login input[type=password]::-webkit-input-placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type=text]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type=email]::-webkit-input-placeholder,
.checkout.woocommerce-checkout input[type=tel]::-webkit-input-placeholder,
.checkout.woocommerce-checkout .select2-choice::-webkit-input-placeholder,
.checkout.woocommerce-checkout textarea::-webkit-input-placeholder,
.woocommerce form .form-row input.input-text::-webkit-input-placeholder,
.woocommerce form .form-row .select2-selection::-webkit-input-placeholder,
.woocommerce form .form-row .select2-choice::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::-webkit-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::-webkit-input-placeholder,
.woocommerce-cart-form .table tr .quantity input::-webkit-input-placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::-webkit-input-placeholder,
.woocommerce #review_form #respond input[type=text]::-webkit-input-placeholder,
.woocommerce #review_form #respond textarea::-webkit-input-placeholder,
.woocommerce #review_form #respond input[type=email]::-webkit-input-placeholder {
  color: var(--title);
}

.form-control:-ms-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=text]:-ms-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=email]:-ms-input-placeholder,
.woocommerce-EditAccountForm.edit-account input[type=password]:-ms-input-placeholder,
.login input[type=text]:-ms-input-placeholder,
.login input[type=password]:-ms-input-placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:-ms-input-placeholder,
.checkout.woocommerce-checkout input[type=text]:-ms-input-placeholder,
.checkout.woocommerce-checkout input[type=email]:-ms-input-placeholder,
.checkout.woocommerce-checkout input[type=tel]:-ms-input-placeholder,
.checkout.woocommerce-checkout .select2-choice:-ms-input-placeholder,
.checkout.woocommerce-checkout textarea:-ms-input-placeholder,
.woocommerce form .form-row input.input-text:-ms-input-placeholder,
.woocommerce form .form-row .select2-selection:-ms-input-placeholder,
.woocommerce form .form-row .select2-choice:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select:-ms-input-placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input:-ms-input-placeholder,
.woocommerce-cart-form .table tr .quantity input:-ms-input-placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:-ms-input-placeholder,
.woocommerce #review_form #respond input[type=text]:-ms-input-placeholder,
.woocommerce #review_form #respond textarea:-ms-input-placeholder,
.woocommerce #review_form #respond input[type=email]:-ms-input-placeholder {
  color: var(--title);
}

.form-control::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.login input[type=text]::placeholder,
.login input[type=password]::placeholder,
.woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout textarea::placeholder,
.woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .select2-choice::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel]::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond input[type=email]::placeholder {
  color: var(--title);
}

.form-control:focus,
.woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.login input[type=text]:focus,
.login input[type=password]:focus,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout textarea:focus,
.woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .select2-choice:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond input[type=email]:focus,
.form-control:active,
.woocommerce-EditAccountForm.edit-account input[type=text]:active,
.woocommerce-EditAccountForm.edit-account input[type=email]:active,
.woocommerce-EditAccountForm.edit-account input[type=password]:active,
.login input[type=text]:active,
.login input[type=password]:active,
.woocommerce-ResetPassword.lost_reset_password input[type=text]:active,
.checkout.woocommerce-checkout input[type=text]:active,
.checkout.woocommerce-checkout input[type=email]:active,
.checkout.woocommerce-checkout input[type=tel]:active,
.checkout.woocommerce-checkout .select2-choice:active,
.checkout.woocommerce-checkout textarea:active,
.woocommerce form .form-row input.input-text:active,
.woocommerce form .form-row .select2-selection:active,
.woocommerce form .form-row .select2-choice:active,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea:active,
.woocommerce .woocommerce-checkout.checkout form .form-row select:active,
.woocommerce .woocommerce-checkout.checkout form .form-row input:active,
.woocommerce-cart-form .table tr .quantity input:active,
.woocommerce-cart-form .table tr td.actions .coupon .input-text:active,
.woocommerce #review_form #respond input[type=text]:active,
.woocommerce #review_form #respond textarea:active,
.woocommerce #review_form #respond input[type=email]:active,
.form-control.active,
.woocommerce-EditAccountForm.edit-account input.active[type=text],
.woocommerce-EditAccountForm.edit-account input.active[type=email],
.woocommerce-EditAccountForm.edit-account input.active[type=password],
.login input.active[type=text],
.login input.active[type=password],
.woocommerce-ResetPassword.lost_reset_password input.active[type=text],
.checkout.woocommerce-checkout input.active[type=text],
.checkout.woocommerce-checkout input.active[type=email],
.checkout.woocommerce-checkout input.active[type=tel],
.checkout.woocommerce-checkout .active.select2-choice,
.checkout.woocommerce-checkout textarea.active,
.woocommerce form .form-row input.active.input-text,
.woocommerce form .form-row .active.select2-selection,
.woocommerce form .form-row .active.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.active,
.woocommerce .woocommerce-checkout.checkout form .form-row select.active,
.woocommerce .woocommerce-checkout.checkout form .form-row input.active,
.woocommerce-cart-form .table tr .quantity input.active,
.woocommerce-cart-form .table tr td.actions .coupon .active.input-text,
.woocommerce #review_form #respond input.active[type=text],
.woocommerce #review_form #respond textarea.active,
.woocommerce #review_form #respond input.active[type=email] {
  border-color: var(--secondary);
  background: transparent;
}

textarea.form-control,
.checkout.woocommerce-checkout textarea.select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row textarea.select2-selection,
.woocommerce form .form-row textarea.select2-choice,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce-cart-form .table tr td.actions .coupon textarea.input-text,
.woocommerce #review_form #respond textarea {
  min-height: auto;
  height: unset;
}

textarea {
  height: 120px;
  resize: none;
}

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #5E626F;
  border-color: transparent;
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #ffffff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

.toggle-checkbox:checked+.toggle-switch {
  background: var(--primary);
}

.toggle-checkbox:checked+.toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  color: rgba(151, 151, 159, 0.39);
}

.file-upload-wrapper:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}

.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: var(--primary);
  color: #ffffff;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}

.file-upload-wrapper:hover:before {
  background: #9c0a2c;
}

.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #5E626F;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}

.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #5E626F;
  font-size: 0.875rem;
  text-decoration: none;
}

.ui-datepicker-calendar tbody td a:hover {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}

.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #f77b98;
  color: #ffffff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a>span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #5E626F;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #D23636;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #444444;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #444444;
  color: var(--primary);
}

.form-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn),
select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.label-title {
  font-weight: 500;
  color: var(--secondary);
  font-size: 16px;
  margin-bottom: 10px;
}

.form-select,
select {
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  transition: all 0.3s ease-in-out;
  background-color: #FFFAF3;
}

.form-select .dropdown-toggle,
select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
  background-color: transparent;
}

.form-select .dropdown-toggle:focus,
select .dropdown-toggle:focus {
  outline: 0 !important;
  outline: 0 !important;
  outline-offset: 0 !important;
}

.form-select .dropdown-toggle:hover,
select .dropdown-toggle:hover,
.form-select .dropdown-toggle:active,
select .dropdown-toggle:active {
  background-color: transparent;
}

.form-select .dropdown-toggle:after,
select .dropdown-toggle:after {
  content: none;
}

.form-select.show,
select.show {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show .dropdown-toggle,
select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}

.form-select.show.btn-group .dropdown-menu.inner,
select.show.btn-group .dropdown-menu.inner {
  display: block;
}

.form-select.default-select,
select.default-select {
  padding: 0 !important;
  border: 0 !important;
  background-color: #fff;
}

.form-select.default-select button,
select.default-select button {
  padding: 12px 20px;
  height: 100%;
  border: 1px solid var(--secondary) !important;
}

.form-select .dropdown-menu,
select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.form-select .dropdown-menu ul li,
select .dropdown-menu ul li {
  padding: 0;
}

.form-select .dropdown-menu ul li a,
select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
}

.form-select .dropdown-menu ul li a img,
select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}

.form-select .dropdown-menu ul li a:hover,
select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}

.form-select .dropdown-menu ul li.selected a,
select .dropdown-menu ul li.selected a {
  background-color: var(--secondary);
  color: #ffffff;
}

.form-select:focus,
select:focus,
.form-select:active,
select:active,
.form-select.active,
select.active {
  border-color: var(--primary);
}

.form-select.btn-group,
select.btn-group {
  padding: 10px 20px;
  border-width: 1px;
}

.form-select.btn-group.show,
select.btn-group.show {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
  border-color: var(--primary);
}

.form-select.btn-group .form-control .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type=text] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type=email] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type=email] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-EditAccountForm.edit-account input[type=password] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account .form-select.btn-group input[type=password] .dropdown-toggle.btn-default,
.form-select.btn-group .login input[type=text] .dropdown-toggle.btn-default,
.login .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .login input[type=password] .dropdown-toggle.btn-default,
.login .form-select.btn-group input[type=password] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-ResetPassword.lost_reset_password input[type=text] .dropdown-toggle.btn-default,
.woocommerce-ResetPassword.lost_reset_password .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type=text] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type=email] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type=email] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout input[type=tel] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group input[type=tel] .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout .select2-choice .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group .select2-choice .dropdown-toggle.btn-default,
.form-select.btn-group .checkout.woocommerce-checkout textarea .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout .form-select.btn-group textarea .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row input.input-text .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group input.input-text .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row .select2-selection .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group .select2-selection .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce form .form-row .select2-choice .dropdown-toggle.btn-default,
.woocommerce form .form-row .form-select.btn-group .select2-choice .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row select .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-select.btn-group select .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row input .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row .form-select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-cart-form .table tr .quantity input .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr .quantity .form-select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce-cart-form .table tr td.actions .coupon .input-text .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr td.actions .coupon .form-select.btn-group .input-text .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond input[type=text] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group input[type=text] .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond textarea .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group textarea .dropdown-toggle.btn-default,
.form-select.btn-group .woocommerce #review_form #respond input[type=email] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond .form-select.btn-group input[type=email] .dropdown-toggle.btn-default,
select.btn-group .form-control .dropdown-toggle.btn-default,
select.btn-group .woocommerce-EditAccountForm.edit-account input[type=text] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .woocommerce-EditAccountForm.edit-account input[type=email] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account select.btn-group input[type=email] .dropdown-toggle.btn-default,
select.btn-group .woocommerce-EditAccountForm.edit-account input[type=password] .dropdown-toggle.btn-default,
.woocommerce-EditAccountForm.edit-account select.btn-group input[type=password] .dropdown-toggle.btn-default,
select.btn-group .login input[type=text] .dropdown-toggle.btn-default,
.login select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .login input[type=password] .dropdown-toggle.btn-default,
.login select.btn-group input[type=password] .dropdown-toggle.btn-default,
select.btn-group .woocommerce-ResetPassword.lost_reset_password input[type=text] .dropdown-toggle.btn-default,
.woocommerce-ResetPassword.lost_reset_password select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout input[type=text] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout input[type=email] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group input[type=email] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout input[type=tel] .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group input[type=tel] .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout .select2-choice .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group .select2-choice .dropdown-toggle.btn-default,
select.btn-group .checkout.woocommerce-checkout textarea .dropdown-toggle.btn-default,
.checkout.woocommerce-checkout select.btn-group textarea .dropdown-toggle.btn-default,
select.btn-group .woocommerce form .form-row input.input-text .dropdown-toggle.btn-default,
.woocommerce form .form-row select.btn-group input.input-text .dropdown-toggle.btn-default,
select.btn-group .woocommerce form .form-row .select2-selection .dropdown-toggle.btn-default,
.woocommerce form .form-row select.btn-group .select2-selection .dropdown-toggle.btn-default,
select.btn-group .woocommerce form .form-row .select2-choice .dropdown-toggle.btn-default,
.woocommerce form .form-row select.btn-group .select2-choice .dropdown-toggle.btn-default,
select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row select .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row select.btn-group select .dropdown-toggle.btn-default,
select.btn-group .woocommerce .woocommerce-checkout.checkout form .form-row input .dropdown-toggle.btn-default,
.woocommerce .woocommerce-checkout.checkout form .form-row select.btn-group input .dropdown-toggle.btn-default,
select.btn-group .woocommerce-cart-form .table tr .quantity input .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr .quantity select.btn-group input .dropdown-toggle.btn-default,
select.btn-group .woocommerce-cart-form .table tr td.actions .coupon .input-text .dropdown-toggle.btn-default,
.woocommerce-cart-form .table tr td.actions .coupon select.btn-group .input-text .dropdown-toggle.btn-default,
select.btn-group .woocommerce #review_form #respond input[type=text] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond select.btn-group input[type=text] .dropdown-toggle.btn-default,
select.btn-group .woocommerce #review_form #respond textarea .dropdown-toggle.btn-default,
.woocommerce #review_form #respond select.btn-group textarea .dropdown-toggle.btn-default,
select.btn-group .woocommerce #review_form #respond input[type=email] .dropdown-toggle.btn-default,
.woocommerce #review_form #respond select.btn-group input[type=email] .dropdown-toggle.btn-default {
  padding: 0;
}

.form-select.btn-group .dropdown-toggle .caret,
select.btn-group .dropdown-toggle .caret {
  font-size: 10px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.form-select.btn-group .dropdown-toggle .caret:before,
select.btn-group .dropdown-toggle .caret:before {
  content: "\f078";
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}

.custom-file-label:after {
  content: none !important;
}

.dz-form.radius-no .form-control,
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type=text],
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type=email],
.dz-form.radius-no .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dz-form.radius-no input[type=password],
.dz-form.radius-no .login input[type=text],
.login .dz-form.radius-no input[type=text],
.dz-form.radius-no .login input[type=password],
.login .dz-form.radius-no input[type=password],
.dz-form.radius-no .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dz-form.radius-no input[type=text],
.dz-form.radius-no .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dz-form.radius-no input[type=text],
.dz-form.radius-no .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dz-form.radius-no input[type=email],
.dz-form.radius-no .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dz-form.radius-no input[type=tel],
.dz-form.radius-no .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dz-form.radius-no .select2-choice,
.dz-form.radius-no .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dz-form.radius-no input.input-text,
.dz-form.radius-no .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dz-form.radius-no .select2-selection,
.dz-form.radius-no .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dz-form.radius-no .select2-choice,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no select,
.dz-form.radius-no .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-form.radius-no input,
.dz-form.radius-no .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dz-form.radius-no input,
.dz-form.radius-no .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dz-form.radius-no .input-text,
.dz-form.radius-no .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dz-form.radius-no input[type=text],
.dz-form.radius-no .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dz-form.radius-no textarea,
.dz-form.radius-no .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dz-form.radius-no input[type=email] {
  border-radius: 0;
}

.dz-form.style-1 textarea {
  height: 185px;
  padding: 15px ​20px;
}

.form-check-input {
  width: 1.125em;
  height: 1.125em;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px !important;
  margin: 4px;
}

.form-check-input.radio {
  border-radius: 50% !important;
  border: 1px solid #24262B;
}

.form-check-input.radio:checked[type=radio] {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
}

.input-group {
  margin-bottom: 20px;
}

.wpcf7-form .row {
  --bs-gutter-x: 20px;
}

.checkout-form {
  margin-bottom: 40px;
}

.checkout-form .form-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}

.checkout-form .form-control,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=text],
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=email],
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=password],
.checkout-form .login input[type=text],
.login .checkout-form input[type=text],
.checkout-form .login input[type=password],
.login .checkout-form input[type=password],
.checkout-form .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .checkout-form input[type=text],
.checkout-form .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .checkout-form input[type=text],
.checkout-form .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .checkout-form input[type=email],
.checkout-form .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .checkout-form input[type=tel],
.checkout-form .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .checkout-form .select2-choice,
.checkout-form .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .checkout-form textarea,
.checkout-form .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .checkout-form input.input-text,
.checkout-form .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .checkout-form .select2-selection,
.checkout-form .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .checkout-form .select2-choice,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form textarea,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form select,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form input,
.checkout-form .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .checkout-form input,
.checkout-form .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .checkout-form .input-text,
.checkout-form .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .checkout-form input[type=text],
.checkout-form .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .checkout-form textarea,
.checkout-form .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .checkout-form input[type=email] {
  background: none;
  border: 1px solid transparent;
  border-bottom: 2px solid #E1E1E1;
  border-radius: 0;
  padding: 10px 0;
}

.checkout-form .form-control::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=text]::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=email]::placeholder,
.checkout-form .woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.woocommerce-EditAccountForm.edit-account .checkout-form input[type=password]::placeholder,
.checkout-form .login input[type=text]::placeholder,
.login .checkout-form input[type=text]::placeholder,
.checkout-form .login input[type=password]::placeholder,
.login .checkout-form input[type=password]::placeholder,
.checkout-form .woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .checkout-form input[type=text]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type=text]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type=email]::placeholder,
.checkout-form .checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .checkout-form input[type=tel]::placeholder,
.checkout-form .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .checkout-form .select2-choice::placeholder,
.checkout-form .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .checkout-form textarea::placeholder,
.checkout-form .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .checkout-form input.input-text::placeholder,
.checkout-form .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .checkout-form .select2-selection::placeholder,
.checkout-form .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .checkout-form .select2-choice::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row textarea::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form textarea::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form select::placeholder,
.checkout-form .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .checkout-form input::placeholder,
.checkout-form .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .checkout-form input::placeholder,
.checkout-form .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .checkout-form .input-text::placeholder,
.checkout-form .woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond .checkout-form input[type=text]::placeholder,
.checkout-form .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .checkout-form textarea::placeholder,
.checkout-form .woocommerce #review_form #respond input[type=email]::placeholder,
.woocommerce #review_form #respond .checkout-form input[type=email]::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: var(--seconday);
}

.pay-bottom {
  padding-bottom: 55px;
  border-bottom: 2px dashed #AAAAAA;
}

.pay-bottom .money-type {
  position: relative;
  text-align: center;
}

.pay-bottom .money-type .form-check {
  border: 1px solid #F0F0F0;
  border-radius: var(--border-radius-base);
  padding-left: 0;
}

.pay-bottom .money-type .form-check .form-check-input {
  position: absolute;
  right: 15px;
  top: 15px;
}

.pay-bottom .money-type .form-check .form-check-input:checked+.form-check-label {
  background-color: #FFF0DF;
  border: 1px solid var(--primary);
  border-radius: var(--border-radius-base);
}

.pay-bottom .money-type .form-check .form-check-input:checked+.form-check-label i {
  color: var(--primary);
}

.pay-bottom .money-type .form-check .form-check-label {
  width: 100%;
  margin: 0 !important;
  padding: 10px 10px;
}

.pay-bottom .money-type .form-check .payment-check i {
  font-size: 68px;
}

.input-group-text.checkout-text {
  background: none;
  border-color: transparent;
  position: relative;
  right: 60px;
}

.input-group-text.checkout-text i {
  font-size: 20px;
  font-weight: 600;
}

.form-control.default-select .btn,
.form-control.default-select .woocommerce #respond input#submit,
.woocommerce #respond .form-control.default-select input#submit,
.form-control.default-select .woocommerce a.button,
.woocommerce .form-control.default-select a.button,
.form-control.default-select .woocommerce button.button,
.woocommerce .form-control.default-select button.button,
.form-control.default-select .woocommerce input.button,
.woocommerce .form-control.default-select input.button,
.form-control.default-select .woocommerce #place_order,
.woocommerce .form-control.default-select #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] #place_order,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .btn,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input#submit,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce a.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce button.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] button.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce input.button,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input.button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #place_order,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] #place_order,
.login input.default-select[type=text] .btn,
.login input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .login input.default-select[type=text] input#submit,
.login input.default-select[type=text] .woocommerce a.button,
.woocommerce .login input.default-select[type=text] a.button,
.login input.default-select[type=text] .woocommerce button.button,
.woocommerce .login input.default-select[type=text] button.button,
.login input.default-select[type=text] .woocommerce input.button,
.woocommerce .login input.default-select[type=text] input.button,
.login input.default-select[type=text] .woocommerce #place_order,
.woocommerce .login input.default-select[type=text] #place_order,
.login input.default-select[type=password] .btn,
.login input.default-select[type=password] .woocommerce #respond input#submit,
.woocommerce #respond .login input.default-select[type=password] input#submit,
.login input.default-select[type=password] .woocommerce a.button,
.woocommerce .login input.default-select[type=password] a.button,
.login input.default-select[type=password] .woocommerce button.button,
.woocommerce .login input.default-select[type=password] button.button,
.login input.default-select[type=password] .woocommerce input.button,
.woocommerce .login input.default-select[type=password] input.button,
.login input.default-select[type=password] .woocommerce #place_order,
.woocommerce .login input.default-select[type=password] #place_order,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .btn,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input#submit,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce a.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce button.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] button.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce input.button,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input.button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #place_order,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] #place_order,
.checkout.woocommerce-checkout input.default-select[type=text] .btn,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=text] input#submit,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] a.button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] button.button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] input.button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] #place_order,
.checkout.woocommerce-checkout input.default-select[type=email] .btn,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=email] input#submit,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] a.button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] button.button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] input.button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] #place_order,
.checkout.woocommerce-checkout input.default-select[type=tel] .btn,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=tel] input#submit,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] a.button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] button.button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] input.button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] #place_order,
.checkout.woocommerce-checkout .default-select.select2-choice .btn,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout .default-select.select2-choice input#submit,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice a.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice button.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice input.button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice #place_order,
.checkout.woocommerce-checkout textarea.default-select .btn,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #respond input#submit,
.woocommerce #respond .checkout.woocommerce-checkout textarea.default-select input#submit,
.checkout.woocommerce-checkout textarea.default-select .woocommerce a.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select a.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce button.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select button.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce input.button,
.woocommerce .checkout.woocommerce-checkout textarea.default-select input.button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #place_order,
.woocommerce .checkout.woocommerce-checkout textarea.default-select #place_order,
.woocommerce form .form-row input.default-select.input-text .btn,
.woocommerce form .form-row input.default-select.input-text #respond input#submit,
.woocommerce #respond form .form-row input.default-select.input-text input#submit,
.woocommerce form .form-row input.default-select.input-text a.button,
.woocommerce form .form-row input.default-select.input-text button.button,
.woocommerce form .form-row input.default-select.input-text input.button,
.woocommerce form .form-row input.default-select.input-text #place_order,
.woocommerce form .form-row .default-select.select2-selection .btn,
.woocommerce form .form-row .default-select.select2-selection #respond input#submit,
.woocommerce #respond form .form-row .default-select.select2-selection input#submit,
.woocommerce form .form-row .default-select.select2-selection a.button,
.woocommerce form .form-row .default-select.select2-selection button.button,
.woocommerce form .form-row .default-select.select2-selection input.button,
.woocommerce form .form-row .default-select.select2-selection #place_order,
.woocommerce form .form-row .default-select.select2-choice .btn,
.woocommerce form .form-row .default-select.select2-choice #respond input#submit,
.woocommerce #respond form .form-row .default-select.select2-choice input#submit,
.woocommerce form .form-row .default-select.select2-choice a.button,
.woocommerce form .form-row .default-select.select2-choice button.button,
.woocommerce form .form-row .default-select.select2-choice input.button,
.woocommerce form .form-row .default-select.select2-choice #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row textarea.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row select.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #place_order,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .btn,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #respond input#submit,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select input#submit,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select button.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select input.button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #place_order,
.form-control.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.login input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.login input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .form-control.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.checkout-button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.checkout-button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.checkout-button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=text] a.checkout-button,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=password] a.checkout-button,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.checkout-button,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=text] a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=email] a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=tel] a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=tel] a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout .default-select.select2-choice a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout textarea.default-select a.checkout-button,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row input.default-select.input-text a.checkout-button,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select.input-text a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-selection a.checkout-button,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-selection a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-choice a.checkout-button,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout select.default-select a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.checkout-button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select a.checkout-button,
.form-control.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart-form .table tr .button,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart-form .table tr .button,
.login input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.login input.default-select[type=password] .woocommerce-cart-form .table tr .button,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart-form .table tr .button,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart-form .table tr .button,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart-form .table tr .button,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart-form .table tr .button,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .form-control.default-select .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=text] .button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=email] .button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=email] .button,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=password] .button,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=password] .button,
.woocommerce-cart-form .table tr .login input.default-select[type=text] .button,
.login .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .login input.default-select[type=password] .button,
.login .woocommerce-cart-form .table tr input.default-select[type=password] .button,
.woocommerce-cart-form .table tr .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .button,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=text] .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=text] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=email] .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=email] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=tel] .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=tel] .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout .default-select.select2-choice .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr .default-select.select2-choice .button,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout textarea.default-select .button,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr textarea.default-select .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row input.default-select.input-text .button,
.woocommerce form .form-row .woocommerce-cart-form .table tr input.default-select.input-text .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-selection .button,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-selection .button,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-choice .button,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-choice .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr textarea.default-select .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr select.default-select .button,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .button,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr input.default-select .button,
.woocommerce-cart-form .table tr .quantity input.default-select .btn,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr .quantity input.default-select input#submit,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select a.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select button.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select input.button,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #place_order,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select #place_order,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .quantity input.default-select a.checkout-button,
.woocommerce-cart-form .table tr .quantity input.default-select .button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .btn,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input#submit,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text button.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input.button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #place_order,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text #place_order,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.checkout-button,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .button,
.form-control.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #review_form #respond .form-submit input,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #review_form #respond .form-submit input,
.login input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.login input.default-select[type=password] .woocommerce #review_form #respond .form-submit input,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #review_form #respond .form-submit input,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce form .form-row input.default-select.input-text .woocommerce #review_form #respond .form-submit input,
.woocommerce form .form-row .default-select.select2-selection .woocommerce #review_form #respond .form-submit input,
.woocommerce form .form-row .default-select.select2-choice .woocommerce #review_form #respond .form-submit input,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #review_form #respond .form-submit input,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .form-control.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=email] input,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=password] input,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=text] input,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=password] input,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=password] input,
.woocommerce #review_form #respond .form-submit .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input,
.woocommerce-ResetPassword.lost_reset_password .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=text] input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=email] input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=email] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=tel] input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=tel] input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout .default-select.select2-choice input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit .default-select.select2-choice input,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout textarea.default-select input,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit textarea.default-select input,
.woocommerce #review_form #respond .form-submit form .form-row input.default-select.input-text input,
.woocommerce form .form-row #review_form #respond .form-submit input.default-select.input-text input,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-selection input,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-selection input,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-choice input,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-choice input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row textarea.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit textarea.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row select.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit select.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select input,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit input.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .quantity input.default-select input,
.woocommerce-cart-form .table tr .quantity .woocommerce #review_form #respond .form-submit input.default-select input,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce #review_form #respond .form-submit .default-select.input-text input,
.woocommerce #review_form #respond input.default-select[type=text] .btn,
.woocommerce #review_form #respond input.default-select[type=text] input#submit,
.woocommerce #review_form #respond input.default-select[type=text] a.button,
.woocommerce #review_form #respond input.default-select[type=text] button.button,
.woocommerce #review_form #respond input.default-select[type=text] input.button,
.woocommerce #review_form #respond input.default-select[type=text] #place_order,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=text] a.checkout-button,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=text] .button,
.woocommerce #review_form #respond input.default-select[type=text] .form-submit input,
.woocommerce #review_form #respond .form-submit input.default-select[type=text] input,
.woocommerce #review_form #respond textarea.default-select .btn,
.woocommerce #review_form #respond textarea.default-select input#submit,
.woocommerce #review_form #respond textarea.default-select a.button,
.woocommerce #review_form #respond textarea.default-select button.button,
.woocommerce #review_form #respond textarea.default-select input.button,
.woocommerce #review_form #respond textarea.default-select #place_order,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond textarea.default-select a.checkout-button,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond textarea.default-select .button,
.woocommerce #review_form #respond textarea.default-select .form-submit input,
.woocommerce #review_form #respond .form-submit textarea.default-select input,
.woocommerce #review_form #respond input.default-select[type=email] .btn,
.woocommerce #review_form #respond input.default-select[type=email] input#submit,
.woocommerce #review_form #respond input.default-select[type=email] a.button,
.woocommerce #review_form #respond input.default-select[type=email] button.button,
.woocommerce #review_form #respond input.default-select[type=email] input.button,
.woocommerce #review_form #respond input.default-select[type=email] #place_order,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=email] a.checkout-button,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=email] .button,
.woocommerce #review_form #respond input.default-select[type=email] .form-submit input,
.woocommerce #review_form #respond .form-submit input.default-select[type=email] input,
.form-control.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a,
.login input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.login input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout textarea.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce form .form-row input.default-select.input-text .woocommerce .widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-selection .woocommerce .widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce .widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce #review_form #respond textarea.default-select .woocommerce .widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .form-control.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=text] a,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=password] a,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a,
.woocommerce-ResetPassword.lost_reset_password .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=tel] a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons .default-select.select2-choice a,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons textarea.default-select a,
.woocommerce .widget_shopping_cart .buttons form .form-row input.default-select.input-text a,
.woocommerce form .form-row .widget_shopping_cart .buttons input.default-select.input-text a,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-selection a,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-selection a,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-choice a,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-choice a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons textarea.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons select.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons input.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a,
.woocommerce-cart-form .table tr .quantity .woocommerce .widget_shopping_cart .buttons input.default-select a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce .widget_shopping_cart .buttons .default-select.input-text a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond textarea.default-select a,
.woocommerce #review_form #respond .widget_shopping_cart .buttons textarea.default-select a,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=email] a,
.form-control.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a,
.login input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.login input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a,
.checkout.woocommerce-checkout textarea.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce form .form-row input.default-select.input-text .woocommerce.widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-selection .woocommerce.widget_shopping_cart .buttons a,
.woocommerce form .form-row .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond textarea.default-select .woocommerce.widget_shopping_cart .buttons a,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .form-control.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=text] a,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=password] a,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a,
.woocommerce-ResetPassword.lost_reset_password .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=tel] a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons .default-select.select2-choice a,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons textarea.default-select a,
.woocommerce.widget_shopping_cart .buttons form .form-row input.default-select.input-text a,
.woocommerce.widget_shopping_cart form .form-row .buttons input.default-select.input-text a,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-selection a,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-selection a,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-choice a,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-choice a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons textarea.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons select.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons input.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a,
.woocommerce-cart-form .table tr .quantity .woocommerce.widget_shopping_cart .buttons input.default-select a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce.widget_shopping_cart .buttons .default-select.input-text a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=text] a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond textarea.default-select a,
.woocommerce.widget_shopping_cart #review_form #respond .buttons textarea.default-select a,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=email] a,
.form-control.default-select .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .wp-block-button__link,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .wp-block-button__link,
.login input.default-select[type=text] .wp-block-button__link,
.login input.default-select[type=password] .wp-block-button__link,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type=text] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type=email] .wp-block-button__link,
.checkout.woocommerce-checkout input.default-select[type=tel] .wp-block-button__link,
.checkout.woocommerce-checkout .default-select.select2-choice .wp-block-button__link,
.checkout.woocommerce-checkout textarea.default-select .wp-block-button__link,
.woocommerce form .form-row input.default-select.input-text .wp-block-button__link,
.woocommerce form .form-row .default-select.select2-selection .wp-block-button__link,
.woocommerce form .form-row .default-select.select2-choice .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .wp-block-button__link,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .wp-block-button__link,
.woocommerce-cart-form .table tr .quantity input.default-select .wp-block-button__link,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .wp-block-button__link,
.woocommerce #review_form #respond input.default-select[type=text] .wp-block-button__link,
.woocommerce #review_form #respond textarea.default-select .wp-block-button__link,
.woocommerce #review_form #respond input.default-select[type=email] .wp-block-button__link {
  background: transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  padding: 15px 10px !important;
  border-color: #E1E1E1;
  padding: 10px 20px;
}

.form-control.default-select .btn:focus,
.form-control.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .form-control.default-select input#submit:focus,
.form-control.default-select .woocommerce a.button:focus,
.woocommerce .form-control.default-select a.button:focus,
.form-control.default-select .woocommerce button.button:focus,
.woocommerce .form-control.default-select button.button:focus,
.form-control.default-select .woocommerce input.button:focus,
.woocommerce .form-control.default-select input.button:focus,
.form-control.default-select .woocommerce #place_order:focus,
.woocommerce .form-control.default-select #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=text] #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=email] #place_order:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .btn:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input#submit:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce a.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce button.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] button.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce input.button:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input.button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #place_order:focus,
.woocommerce .woocommerce-EditAccountForm.edit-account input.default-select[type=password] #place_order:focus,
.login input.default-select[type=text] .btn:focus,
.login input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .login input.default-select[type=text] input#submit:focus,
.login input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .login input.default-select[type=text] a.button:focus,
.login input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .login input.default-select[type=text] button.button:focus,
.login input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .login input.default-select[type=text] input.button:focus,
.login input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .login input.default-select[type=text] #place_order:focus,
.login input.default-select[type=password] .btn:focus,
.login input.default-select[type=password] .woocommerce #respond input#submit:focus,
.woocommerce #respond .login input.default-select[type=password] input#submit:focus,
.login input.default-select[type=password] .woocommerce a.button:focus,
.woocommerce .login input.default-select[type=password] a.button:focus,
.login input.default-select[type=password] .woocommerce button.button:focus,
.woocommerce .login input.default-select[type=password] button.button:focus,
.login input.default-select[type=password] .woocommerce input.button:focus,
.woocommerce .login input.default-select[type=password] input.button:focus,
.login input.default-select[type=password] .woocommerce #place_order:focus,
.woocommerce .login input.default-select[type=password] #place_order:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .btn:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input#submit:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] button.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input.button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=text] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=text] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=email] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=email] #place_order:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .btn:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout input.default-select[type=tel] input#submit:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] a.button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] button.button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] input.button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout input.default-select[type=tel] #place_order:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .btn:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout .default-select.select2-choice input#submit:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice a.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice button.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice input.button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout .default-select.select2-choice #place_order:focus,
.checkout.woocommerce-checkout textarea.default-select .btn:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .checkout.woocommerce-checkout textarea.default-select input#submit:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce a.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select a.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce button.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select button.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce input.button:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select input.button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #place_order:focus,
.woocommerce .checkout.woocommerce-checkout textarea.default-select #place_order:focus,
.woocommerce form .form-row input.default-select.input-text .btn:focus,
.woocommerce form .form-row input.default-select.input-text #respond input#submit:focus,
.woocommerce #respond form .form-row input.default-select.input-text input#submit:focus,
.woocommerce form .form-row input.default-select.input-text a.button:focus,
.woocommerce form .form-row input.default-select.input-text button.button:focus,
.woocommerce form .form-row input.default-select.input-text input.button:focus,
.woocommerce form .form-row input.default-select.input-text #place_order:focus,
.woocommerce form .form-row .default-select.select2-selection .btn:focus,
.woocommerce form .form-row .default-select.select2-selection #respond input#submit:focus,
.woocommerce #respond form .form-row .default-select.select2-selection input#submit:focus,
.woocommerce form .form-row .default-select.select2-selection a.button:focus,
.woocommerce form .form-row .default-select.select2-selection button.button:focus,
.woocommerce form .form-row .default-select.select2-selection input.button:focus,
.woocommerce form .form-row .default-select.select2-selection #place_order:focus,
.woocommerce form .form-row .default-select.select2-choice .btn:focus,
.woocommerce form .form-row .default-select.select2-choice #respond input#submit:focus,
.woocommerce #respond form .form-row .default-select.select2-choice input#submit:focus,
.woocommerce form .form-row .default-select.select2-choice a.button:focus,
.woocommerce form .form-row .default-select.select2-choice button.button:focus,
.woocommerce form .form-row .default-select.select2-choice input.button:focus,
.woocommerce form .form-row .default-select.select2-choice #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row textarea.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row select.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select #place_order:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .btn:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #respond input#submit:focus,
.woocommerce #respond .woocommerce-checkout.checkout form .form-row input.default-select input#submit:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select button.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select input.button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select #place_order:focus,
.form-control.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.login input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.login input.default-select[type=password] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .form-control.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a.checkout-button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=text] a.checkout-button:focus,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .login input.default-select[type=password] a.checkout-button:focus,
.login .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=password] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a.checkout-button:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=text] a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=text] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=email] a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=email] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout input.default-select[type=tel] a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout input.default-select[type=tel] a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout .default-select.select2-choice a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .checkout.woocommerce-checkout textarea.default-select a.checkout-button:focus,
.checkout.woocommerce-checkout .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row input.default-select.input-text a.checkout-button:focus,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select.input-text a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-selection a.checkout-button:focus,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-selection a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce form .form-row .default-select.select2-choice a.checkout-button:focus,
.woocommerce form .form-row .woocommerce-cart .wc-proceed-to-checkout .default-select.select2-choice a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout textarea.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout select.default-select a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select a.checkout-button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart .wc-proceed-to-checkout input.default-select a.checkout-button:focus,
.form-control.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce-cart-form .table tr .button:focus,
.login input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.login input.default-select[type=password] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce-cart-form .table tr .button:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce-cart-form .table tr .button:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce-cart-form .table tr .button:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce-cart-form .table tr .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .form-control.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=text] .button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=email] .button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=email] .button:focus,
.woocommerce-cart-form .table tr .woocommerce-EditAccountForm.edit-account input.default-select[type=password] .button:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce-cart-form .table tr input.default-select[type=password] .button:focus,
.woocommerce-cart-form .table tr .login input.default-select[type=text] .button:focus,
.login .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .login input.default-select[type=password] .button:focus,
.login .woocommerce-cart-form .table tr input.default-select[type=password] .button:focus,
.woocommerce-cart-form .table tr .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .button:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=text] .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=text] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=email] .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=email] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout input.default-select[type=tel] .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr input.default-select[type=tel] .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout .default-select.select2-choice .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr .default-select.select2-choice .button:focus,
.woocommerce-cart-form .table tr .checkout.woocommerce-checkout textarea.default-select .button:focus,
.checkout.woocommerce-checkout .woocommerce-cart-form .table tr textarea.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row input.default-select.input-text .button:focus,
.woocommerce form .form-row .woocommerce-cart-form .table tr input.default-select.input-text .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-selection .button:focus,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-selection .button:focus,
.woocommerce-cart-form .table tr .woocommerce form .form-row .default-select.select2-choice .button:focus,
.woocommerce form .form-row .woocommerce-cart-form .table tr .default-select.select2-choice .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr textarea.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr select.default-select .button:focus,
.woocommerce-cart-form .table tr .woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .button:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .woocommerce-cart-form .table tr input.default-select .button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .btn:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-cart-form .table tr .quantity input.default-select input#submit:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce a.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select a.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce button.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select button.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce input.button:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select input.button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #place_order:focus,
.woocommerce .woocommerce-cart-form .table tr .quantity input.default-select #place_order:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr .quantity input.default-select a.checkout-button:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .btn:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #respond input#submit:focus,
.woocommerce #respond .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input#submit:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce a.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce button.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text button.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce input.button:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input.button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #place_order:focus,
.woocommerce .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text #place_order:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a.checkout-button:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .button:focus,
.form-control.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce #review_form #respond .form-submit input:focus,
.login input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.login input.default-select[type=password] .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce #review_form #respond .form-submit input:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce #review_form #respond .form-submit input:focus,
.woocommerce #review_form #respond .form-submit .form-control.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=text] input:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=email] input:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=email] input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-EditAccountForm.edit-account input.default-select[type=password] input:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce #review_form #respond .form-submit input.default-select[type=password] input:focus,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=text] input:focus,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .login input.default-select[type=password] input:focus,
.login .woocommerce #review_form #respond .form-submit input.default-select[type=password] input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] input:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=text] input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=email] input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=email] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout input.default-select[type=tel] input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit input.default-select[type=tel] input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout .default-select.select2-choice input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit .default-select.select2-choice input:focus,
.woocommerce #review_form #respond .form-submit .checkout.woocommerce-checkout textarea.default-select input:focus,
.checkout.woocommerce-checkout .woocommerce #review_form #respond .form-submit textarea.default-select input:focus,
.woocommerce #review_form #respond .form-submit form .form-row input.default-select.input-text input:focus,
.woocommerce form .form-row #review_form #respond .form-submit input.default-select.input-text input:focus,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-selection input:focus,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-selection input:focus,
.woocommerce #review_form #respond .form-submit form .form-row .default-select.select2-choice input:focus,
.woocommerce form .form-row #review_form #respond .form-submit .default-select.select2-choice input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row textarea.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit textarea.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row select.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit select.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-checkout.checkout form .form-row input.default-select input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row #review_form #respond .form-submit input.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr .quantity input.default-select input:focus,
.woocommerce-cart-form .table tr .quantity .woocommerce #review_form #respond .form-submit input.default-select input:focus,
.woocommerce #review_form #respond .form-submit .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text input:focus,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce #review_form #respond .form-submit .default-select.input-text input:focus,
.woocommerce #review_form #respond input.default-select[type=text] .btn:focus,
.woocommerce #review_form #respond input.default-select[type=text] input#submit:focus,
.woocommerce #review_form #respond input.default-select[type=text] a.button:focus,
.woocommerce #review_form #respond input.default-select[type=text] button.button:focus,
.woocommerce #review_form #respond input.default-select[type=text] input.button:focus,
.woocommerce #review_form #respond input.default-select[type=text] #place_order:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=text] a.checkout-button:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=text] .button:focus,
.woocommerce #review_form #respond input.default-select[type=text] .form-submit input:focus,
.woocommerce #review_form #respond .form-submit input.default-select[type=text] input:focus,
.woocommerce #review_form #respond textarea.default-select .btn:focus,
.woocommerce #review_form #respond textarea.default-select input#submit:focus,
.woocommerce #review_form #respond textarea.default-select a.button:focus,
.woocommerce #review_form #respond textarea.default-select button.button:focus,
.woocommerce #review_form #respond textarea.default-select input.button:focus,
.woocommerce #review_form #respond textarea.default-select #place_order:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond textarea.default-select a.checkout-button:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond textarea.default-select .button:focus,
.woocommerce #review_form #respond textarea.default-select .form-submit input:focus,
.woocommerce #review_form #respond .form-submit textarea.default-select input:focus,
.woocommerce #review_form #respond input.default-select[type=email] .btn:focus,
.woocommerce #review_form #respond input.default-select[type=email] input#submit:focus,
.woocommerce #review_form #respond input.default-select[type=email] a.button:focus,
.woocommerce #review_form #respond input.default-select[type=email] button.button:focus,
.woocommerce #review_form #respond input.default-select[type=email] input.button:focus,
.woocommerce #review_form #respond input.default-select[type=email] #place_order:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce #review_form #respond input.default-select[type=email] a.checkout-button:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce-cart-form .table tr .button:focus,
.woocommerce-cart-form .table tr .woocommerce #review_form #respond input.default-select[type=email] .button:focus,
.woocommerce #review_form #respond input.default-select[type=email] .form-submit input:focus,
.woocommerce #review_form #respond .form-submit input.default-select[type=email] input:focus,
.form-control.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a:focus,
.login input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.login input.default-select[type=password] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce .widget_shopping_cart .buttons a:focus,
.woocommerce .widget_shopping_cart .buttons .form-control.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=text] a:focus,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .login input.default-select[type=password] a:focus,
.login .woocommerce .widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons input.default-select[type=tel] a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons .default-select.select2-choice a:focus,
.woocommerce .widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a:focus,
.checkout.woocommerce-checkout .woocommerce .widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row input.default-select.input-text a:focus,
.woocommerce form .form-row .widget_shopping_cart .buttons input.default-select.input-text a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-selection a:focus,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-selection a:focus,
.woocommerce .widget_shopping_cart .buttons form .form-row .default-select.select2-choice a:focus,
.woocommerce form .form-row .widget_shopping_cart .buttons .default-select.select2-choice a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons select.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_shopping_cart .buttons input.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a:focus,
.woocommerce-cart-form .table tr .quantity .woocommerce .widget_shopping_cart .buttons input.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce .widget_shopping_cart .buttons .default-select.input-text a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a:focus,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond textarea.default-select a:focus,
.woocommerce #review_form #respond .widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce .widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a:focus,
.woocommerce #review_form #respond .widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.form-control.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a:focus,
.login input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.login input.default-select[type=password] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a:focus,
.checkout.woocommerce-checkout textarea.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row input.default-select.input-text .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-selection .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce form .form-row .default-select.select2-choice .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=text] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond textarea.default-select .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce #review_form #respond input.default-select[type=email] .woocommerce.widget_shopping_cart .buttons a:focus,
.woocommerce.widget_shopping_cart .buttons .form-control.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=text] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=email] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-EditAccountForm.edit-account input.default-select[type=password] a:focus,
.woocommerce-EditAccountForm.edit-account .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=text] a:focus,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .login input.default-select[type=password] a:focus,
.login .woocommerce.widget_shopping_cart .buttons input.default-select[type=password] a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] a:focus,
.woocommerce-ResetPassword.lost_reset_password .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=text] a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=email] a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=email] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout input.default-select[type=tel] a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons input.default-select[type=tel] a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout .default-select.select2-choice a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons .default-select.select2-choice a:focus,
.woocommerce.widget_shopping_cart .buttons .checkout.woocommerce-checkout textarea.default-select a:focus,
.checkout.woocommerce-checkout .woocommerce.widget_shopping_cart .buttons textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row input.default-select.input-text a:focus,
.woocommerce.widget_shopping_cart form .form-row .buttons input.default-select.input-text a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-selection a:focus,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-selection a:focus,
.woocommerce.widget_shopping_cart .buttons form .form-row .default-select.select2-choice a:focus,
.woocommerce.widget_shopping_cart form .form-row .buttons .default-select.select2-choice a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row select.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons select.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-checkout.checkout form .form-row input.default-select a:focus,
.woocommerce.widget_shopping_cart .woocommerce-checkout.checkout form .form-row .buttons input.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr .quantity input.default-select a:focus,
.woocommerce-cart-form .table tr .quantity .woocommerce.widget_shopping_cart .buttons input.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons .woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text a:focus,
.woocommerce-cart-form .table tr td.actions .coupon .woocommerce.widget_shopping_cart .buttons .default-select.input-text a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=text] a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond textarea.default-select a:focus,
.woocommerce.widget_shopping_cart #review_form #respond .buttons textarea.default-select a:focus,
.woocommerce.widget_shopping_cart .buttons #review_form #respond input.default-select[type=email] a:focus,
.woocommerce.widget_shopping_cart #review_form #respond .buttons input.default-select[type=email] a:focus,
.form-control.default-select .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .wp-block-button__link:focus,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .wp-block-button__link:focus,
.login input.default-select[type=text] .wp-block-button__link:focus,
.login input.default-select[type=password] .wp-block-button__link:focus,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type=text] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type=email] .wp-block-button__link:focus,
.checkout.woocommerce-checkout input.default-select[type=tel] .wp-block-button__link:focus,
.checkout.woocommerce-checkout .default-select.select2-choice .wp-block-button__link:focus,
.checkout.woocommerce-checkout textarea.default-select .wp-block-button__link:focus,
.woocommerce form .form-row input.default-select.input-text .wp-block-button__link:focus,
.woocommerce form .form-row .default-select.select2-selection .wp-block-button__link:focus,
.woocommerce form .form-row .default-select.select2-choice .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .wp-block-button__link:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .wp-block-button__link:focus,
.woocommerce-cart-form .table tr .quantity input.default-select .wp-block-button__link:focus,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .wp-block-button__link:focus,
.woocommerce #review_form #respond input.default-select[type=text] .wp-block-button__link:focus,
.woocommerce #review_form #respond textarea.default-select .wp-block-button__link:focus,
.woocommerce #review_form #respond input.default-select[type=email] .wp-block-button__link:focus {
  outline: 0 !important;
}

.form-control.default-select .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type=text] .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type=email] .filter-option-inner-inner,
.woocommerce-EditAccountForm.edit-account input.default-select[type=password] .filter-option-inner-inner,
.login input.default-select[type=text] .filter-option-inner-inner,
.login input.default-select[type=password] .filter-option-inner-inner,
.woocommerce-ResetPassword.lost_reset_password input.default-select[type=text] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type=text] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type=email] .filter-option-inner-inner,
.checkout.woocommerce-checkout input.default-select[type=tel] .filter-option-inner-inner,
.checkout.woocommerce-checkout .default-select.select2-choice .filter-option-inner-inner,
.checkout.woocommerce-checkout textarea.default-select .filter-option-inner-inner,
.woocommerce form .form-row input.default-select.input-text .filter-option-inner-inner,
.woocommerce form .form-row .default-select.select2-selection .filter-option-inner-inner,
.woocommerce form .form-row .default-select.select2-choice .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row textarea.default-select .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row select.default-select .filter-option-inner-inner,
.woocommerce .woocommerce-checkout.checkout form .form-row input.default-select .filter-option-inner-inner,
.woocommerce-cart-form .table tr .quantity input.default-select .filter-option-inner-inner,
.woocommerce-cart-form .table tr td.actions .coupon .default-select.input-text .filter-option-inner-inner,
.woocommerce #review_form #respond input.default-select[type=text] .filter-option-inner-inner,
.woocommerce #review_form #respond textarea.default-select .filter-option-inner-inner,
.woocommerce #review_form #respond input.default-select[type=email] .filter-option-inner-inner {
  font-weight: 600;
  font-size: 16px;
}

.form-check {
  padding-left: 0;
  padding-right: 0;
}

.form-check span {
  height: 1rem;
  margin-right: 15px;
  width: 1rem;
  display: block;
  background-color: #000;
  position: relative;
  border-radius: 50%;
  z-index: 1;
}

.form-check span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: 1px solid var(--secondary);
  border-radius: 50%;
  opacity: 0.2;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
}

.form-check .form-check-input {
  opacity: 0;
  position: relative;
  z-index: 2;
  margin-left: 0;
  margin-top: 0;
  cursor: pointer;
}

.form-check .form-check-input:checked+span:after {
  transform: translate(-50%, -50%) scale(1);
}

@-webkit-keyframes toLeftFromRight {
  49% {
    -webkit-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes toLeftFromRight {
  49% {
    -moz-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toLeftFromRight {
  49% {
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
    transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-webkit-keyframes toBottomFromTop {
  49% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes toBottomFromTop {
  49% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toBottomFromTop {
  49% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@media only screen and (max-width: 575px) {
  .input-recaptcha {
    margin-bottom: 0px;
  }
}

button:hover,
button:active,
button:focus {
  outline: 0 !important;
}

btn:hover,
btn:active,
btn:focus {
  outline: 0 !important;
}

.btn,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart-form .table tr .button,
.woocommerce #review_form #respond .form-submit input,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.wp-block-button__link {
  font-size: 16px;
  padding: 12px 30px;
  line-height: 24px;
  display: inline-flex;
  border-radius: 0;
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
  font-family: "Lufga", sans-serif;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  justify-content: center;
  border-radius: 10px;
  line-height: 1.2;
}

.btn.btn-icon,
.woocommerce #respond input.btn-icon#submit,
.woocommerce a.btn-icon.button,
.woocommerce button.btn-icon.button,
.woocommerce input.btn-icon.button,
.woocommerce .btn-icon#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.btn-icon.checkout-button,
.woocommerce-cart-form .table tr .btn-icon.button,
.woocommerce #review_form #respond .form-submit input.btn-icon,
.woocommerce .widget_shopping_cart .buttons a.btn-icon,
.woocommerce.widget_shopping_cart .buttons a.btn-icon,
.btn-icon.wp-block-button__link {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn.btn-icon i,
.woocommerce #respond input.btn-icon#submit i,
.woocommerce a.btn-icon.button i,
.woocommerce button.btn-icon.button i,
.woocommerce input.btn-icon.button i,
.woocommerce .btn-icon#place_order i,
.woocommerce-cart .wc-proceed-to-checkout a.btn-icon.checkout-button i,
.woocommerce-cart-form .table tr .btn-icon.button i,
.woocommerce #review_form #respond .form-submit input.btn-icon i,
.woocommerce .widget_shopping_cart .buttons a.btn-icon i,
.woocommerce.widget_shopping_cart .buttons a.btn-icon i,
.btn-icon.wp-block-button__link i {
  font-size: 18px;
}

.btn.btn-icon.btn-outline-secondary:hover svg>path,
.woocommerce #respond input.btn-icon.btn-outline-secondary#submit:hover svg>path,
.woocommerce a.btn-icon.btn-outline-secondary.button:hover svg>path,
.woocommerce button.btn-icon.btn-outline-secondary.button:hover svg>path,
.woocommerce input.btn-icon.btn-outline-secondary.button:hover svg>path,
.woocommerce .btn-icon.btn-outline-secondary#place_order:hover svg>path,
.woocommerce-cart .wc-proceed-to-checkout a.btn-icon.btn-outline-secondary.checkout-button:hover svg>path,
.woocommerce-cart-form .table tr .btn-icon.btn-outline-secondary.button:hover svg>path,
.woocommerce #review_form #respond .form-submit input.btn-icon.btn-outline-secondary:hover svg>path,
.woocommerce .widget_shopping_cart .buttons a.btn-icon.btn-outline-secondary:hover svg>path,
.woocommerce.widget_shopping_cart .buttons a.btn-icon.btn-outline-secondary:hover svg>path,
.btn-icon.btn-outline-secondary.wp-block-button__link:hover svg>path {
  fill: #fff;
}

.btn.btn-lg,
.woocommerce #respond input.btn-lg#submit,
.woocommerce a.btn-lg.button,
.woocommerce button.btn-lg.button,
.woocommerce input.btn-lg.button,
.woocommerce .btn-lg#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.btn-lg.checkout-button,
.woocommerce-cart-form .table tr .btn-lg.button,
.woocommerce #review_form #respond .form-submit input.btn-lg,
.woocommerce .widget_shopping_cart .buttons a.btn-lg,
.woocommerce.widget_shopping_cart .buttons a.btn-lg,
.btn-lg.wp-block-button__link,
.btn-group-lg>.btn,
.woocommerce #respond .btn-group-lg>input#submit,
.woocommerce .btn-group-lg>a.button,
.woocommerce .btn-group-lg>button.button,
.woocommerce .btn-group-lg>input.button,
.woocommerce .btn-group-lg>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-lg>.button,
.woocommerce #review_form #respond .form-submit .btn-group-lg>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-lg>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-lg>a,
.btn-group-lg>.wp-block-button__link {
  padding: 15px 35px;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 991px) {

  .btn.btn-lg,
  .woocommerce #respond input.btn-lg#submit,
  .woocommerce a.btn-lg.button,
  .woocommerce button.btn-lg.button,
  .woocommerce input.btn-lg.button,
  .woocommerce .btn-lg#place_order,
  .woocommerce-cart .wc-proceed-to-checkout a.btn-lg.checkout-button,
  .woocommerce-cart-form .table tr .btn-lg.button,
  .woocommerce #review_form #respond .form-submit input.btn-lg,
  .woocommerce .widget_shopping_cart .buttons a.btn-lg,
  .woocommerce.widget_shopping_cart .buttons a.btn-lg,
  .btn-lg.wp-block-button__link,
  .btn-group-lg>.btn,
  .woocommerce #respond .btn-group-lg>input#submit,
  .woocommerce .btn-group-lg>a.button,
  .woocommerce .btn-group-lg>button.button,
  .woocommerce .btn-group-lg>input.button,
  .woocommerce .btn-group-lg>#place_order,
  .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
  .woocommerce-cart-form .table tr .btn-group-lg>.button,
  .woocommerce #review_form #respond .form-submit .btn-group-lg>input,
  .woocommerce .widget_shopping_cart .buttons .btn-group-lg>a,
  .woocommerce.widget_shopping_cart .buttons .btn-group-lg>a,
  .btn-group-lg>.wp-block-button__link {
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 767px) {

  .btn.btn-lg,
  .woocommerce #respond input.btn-lg#submit,
  .woocommerce a.btn-lg.button,
  .woocommerce button.btn-lg.button,
  .woocommerce input.btn-lg.button,
  .woocommerce .btn-lg#place_order,
  .woocommerce-cart .wc-proceed-to-checkout a.btn-lg.checkout-button,
  .woocommerce-cart-form .table tr .btn-lg.button,
  .woocommerce #review_form #respond .form-submit input.btn-lg,
  .woocommerce .widget_shopping_cart .buttons a.btn-lg,
  .woocommerce.widget_shopping_cart .buttons a.btn-lg,
  .btn-lg.wp-block-button__link,
  .btn-group-lg>.btn,
  .woocommerce #respond .btn-group-lg>input#submit,
  .woocommerce .btn-group-lg>a.button,
  .woocommerce .btn-group-lg>button.button,
  .woocommerce .btn-group-lg>input.button,
  .woocommerce .btn-group-lg>#place_order,
  .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
  .woocommerce-cart-form .table tr .btn-group-lg>.button,
  .woocommerce #review_form #respond .form-submit .btn-group-lg>input,
  .woocommerce .widget_shopping_cart .buttons .btn-group-lg>a,
  .woocommerce.widget_shopping_cart .buttons .btn-group-lg>a,
  .btn-group-lg>.wp-block-button__link {
    padding: 12px 30px;
    font-size: 16px;
  }
}

.btn.btn-xl,
.woocommerce #respond input.btn-xl#submit,
.woocommerce a.btn-xl.button,
.woocommerce button.btn-xl.button,
.woocommerce input.btn-xl.button,
.woocommerce .btn-xl#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.btn-xl.checkout-button,
.woocommerce-cart-form .table tr .btn-xl.button,
.woocommerce #review_form #respond .form-submit input.btn-xl,
.woocommerce .widget_shopping_cart .buttons a.btn-xl,
.woocommerce.widget_shopping_cart .buttons a.btn-xl,
.btn-xl.wp-block-button__link {
  padding: 15px 20px;
  font-size: 24px;
  font-weight: 600;
}

@media only screen and (max-width: 991px) {

  .btn.btn-xl,
  .woocommerce #respond input.btn-xl#submit,
  .woocommerce a.btn-xl.button,
  .woocommerce button.btn-xl.button,
  .woocommerce input.btn-xl.button,
  .woocommerce .btn-xl#place_order,
  .woocommerce-cart .wc-proceed-to-checkout a.btn-xl.checkout-button,
  .woocommerce-cart-form .table tr .btn-xl.button,
  .woocommerce #review_form #respond .form-submit input.btn-xl,
  .woocommerce .widget_shopping_cart .buttons a.btn-xl,
  .woocommerce.widget_shopping_cart .buttons a.btn-xl,
  .btn-xl.wp-block-button__link {
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 767px) {

  .btn.btn-xl,
  .woocommerce #respond input.btn-xl#submit,
  .woocommerce a.btn-xl.button,
  .woocommerce button.btn-xl.button,
  .woocommerce input.btn-xl.button,
  .woocommerce .btn-xl#place_order,
  .woocommerce-cart .wc-proceed-to-checkout a.btn-xl.checkout-button,
  .woocommerce-cart-form .table tr .btn-xl.button,
  .woocommerce #review_form #respond .form-submit input.btn-xl,
  .woocommerce .widget_shopping_cart .buttons a.btn-xl,
  .woocommerce.widget_shopping_cart .buttons a.btn-xl,
  .btn-xl.wp-block-button__link {
    padding: 12px 30px;
    font-size: 16px;
  }
}

.btn.btn-md,
.woocommerce #respond input.btn-md#submit,
.woocommerce a.btn-md.button,
.woocommerce button.btn-md.button,
.woocommerce input.btn-md.button,
.woocommerce .btn-md#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.btn-md.checkout-button,
.woocommerce-cart-form .table tr .btn-md.button,
.woocommerce #review_form #respond .form-submit input.btn-md,
.woocommerce .widget_shopping_cart .buttons a.btn-md,
.woocommerce.widget_shopping_cart .buttons a.btn-md,
.btn-md.wp-block-button__link {
  padding: 10px 20px;
  font-size: 16px;
}

.btn.btn-sm,
.woocommerce #respond input.btn-sm#submit,
.woocommerce a.btn-sm.button,
.woocommerce button.btn-sm.button,
.woocommerce input.btn-sm.button,
.woocommerce .btn-sm#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.btn-sm.checkout-button,
.woocommerce-cart-form .table tr .btn-sm.button,
.woocommerce #review_form #respond .form-submit input.btn-sm,
.woocommerce .widget_shopping_cart .buttons a.btn-sm,
.woocommerce.widget_shopping_cart .buttons a.btn-sm,
.btn-sm.wp-block-button__link,
.btn-group-sm>.btn,
.woocommerce #respond .btn-group-sm>input#submit,
.woocommerce .btn-group-sm>a.button,
.woocommerce .btn-group-sm>button.button,
.woocommerce .btn-group-sm>input.button,
.woocommerce .btn-group-sm>#place_order,
.woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button,
.woocommerce-cart-form .table tr .btn-group-sm>.button,
.woocommerce #review_form #respond .form-submit .btn-group-sm>input,
.woocommerce .widget_shopping_cart .buttons .btn-group-sm>a,
.woocommerce.widget_shopping_cart .buttons .btn-group-sm>a,
.btn-group-sm>.wp-block-button__link {
  font-size: 13px;
  padding: 10px 15px;
}

.btn.btn-xs,
.woocommerce #respond input.btn-xs#submit,
.woocommerce a.btn-xs.button,
.woocommerce button.btn-xs.button,
.woocommerce input.btn-xs.button,
.woocommerce .btn-xs#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.btn-xs.checkout-button,
.woocommerce-cart-form .table tr .btn-xs.button,
.woocommerce #review_form #respond .form-submit input.btn-xs,
.woocommerce .widget_shopping_cart .buttons a.btn-xs,
.woocommerce.widget_shopping_cart .buttons a.btn-xs,
.btn-xs.wp-block-button__link {
  font-size: 12px;
  padding: 5px 18px;
  line-height: 20px;
}

.btn.btn-primary,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart-form .table tr .btn.button,
.woocommerce-cart-form .table tr .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr a.button,
.woocommerce-cart-form .table tr .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr button.button,
.woocommerce-cart-form .table tr .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr input.button,
.woocommerce-cart-form .table tr .woocommerce-cart .wc-proceed-to-checkout a.button.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr a.button.checkout-button,
.woocommerce-cart-form .table tr .btn-primary.button,
.woocommerce-cart-form .table tr .woocommerce a.button,
.woocommerce .woocommerce-cart-form .table tr a.button,
.woocommerce-cart-form .table tr .woocommerce button.button,
.woocommerce .woocommerce-cart-form .table tr button.button,
.woocommerce-cart-form .table tr .woocommerce input.button,
.woocommerce .woocommerce-cart-form .table tr input.button,
.woocommerce-cart-form .table tr .button,
.woocommerce #review_form #respond .form-submit input,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.wp-block-button__link {
  color: #ffffff;
}

.btn.btn-primary:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce #place_order:hover,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce-cart-form .table tr .btn.button:hover,
.woocommerce-cart-form .table tr .woocommerce a.button:hover,
.woocommerce .woocommerce-cart-form .table tr a.button:hover,
.woocommerce-cart-form .table tr .woocommerce button.button:hover,
.woocommerce .woocommerce-cart-form .table tr button.button:hover,
.woocommerce-cart-form .table tr .woocommerce input.button:hover,
.woocommerce .woocommerce-cart-form .table tr input.button:hover,
.woocommerce-cart-form .table tr .woocommerce-cart .wc-proceed-to-checkout a.button.checkout-button:hover,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-cart-form .table tr a.button.checkout-button:hover,
.woocommerce-cart-form .table tr .btn-primary.button:hover,
.woocommerce-cart-form .table tr .button:hover,
.woocommerce #review_form #respond .form-submit input:hover,
.woocommerce .widget_shopping_cart .buttons a:hover,
.woocommerce.widget_shopping_cart .buttons a:hover,
.wp-block-button__link:hover {
  color: #ffffff;
}

.btn-success,
.btn-danger,
.btn-warning {
  color: #fff;
}

.btn-success:hover,
.btn-danger:hover,
.btn-warning:hover {
  color: #fff;
}

.btn-outline-success:hover {
  color: #fff;
}

.btn-success.effect:after,
.btn-info.effect:after,
.btn-danger.effect:after,
.btn-warning.effect:after {
  background: var(--secondary);
}

.btn-rounded {
  border-radius: 30px !important;
}

.btn-link {
  text-transform: uppercase;
  font-family: var(--font-family-base);
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  padding: 5px 0 5px 0;
}

.shadow-primary {
  box-shadow: 0px 19px 29px rgba(220, 181, 145, 0.34);
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-info {
  color: #fff;
}

.btn-info:hover {
  color: #fff;
}

.btn-white {
  background-color: #fff;
  color: var(--secondary);
}

.btn-white:active,
.btn-white:hover {
  color: var(--secondary) !important;
  background-color: #fff !important;
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:after {
  background-color: var(--primary);
}

.btn-gray {
  border-color: #F6F6F6;
  background-color: #F6F6F6;
}

.btn-gray.disabled,
.btn-gray:disabled,
.btn-gray:not(:disabled):not(.disabled).active,
.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:active,
.btn-gray:focus,
.btn-gray:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #ffffff;
}

.btn-theme {
  border-color: var(--secondary);
  background-color: #fff;
  width: fit-content;
  color: var(--title);
  overflow: visible;
}

.btn-theme i {
  border: 1px solid var(--secondary);
  border-radius: 50%;
  position: absolute;
  right: -12px;
  z-index: 9999;
  background: #fff;
  width: 26px;
  height: 26px;
  line-height: 25px;
}

.btn-theme.disabled,
.btn-theme:disabled,
.btn-theme:not(:disabled):not(.disabled).active,
.btn-theme:not(:disabled):not(.disabled):active,
.btn-theme:active,
.btn-theme:focus,
.btn-theme:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #ffffff;
}

.btn-theme.disabled i,
.btn-theme:disabled i,
.btn-theme:not(:disabled):not(.disabled).active i,
.btn-theme:not(:disabled):not(.disabled):active i,
.btn-theme:active i,
.btn-theme:focus i,
.btn-theme:hover i {
  color: var(--title);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
}

.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link,
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus,
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #ffffff;
}

.btn-link-lg {
  font-weight: 700;
  font-size: 18px;
}

.btn-link-lg:hover {
  text-decoration: unset;
}

.wp-block-button {
  margin-bottom: 0.3125rem;
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button__link {
  border-radius: 60px;
}

.wp-block-button__link:after {
  content: none;
}

.bookmark-btn .form-check-label {
  height: 44px;
  width: 44px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  line-height: 44px;
  display: block;
  border: 1px solid var(--secondary);
  background: #fff;
  border-radius: 10px;
  color: var(--title);
}

.bookmark-btn .form-check-input {
  display: none;
}

.bookmark-btn .form-check-input:checked+.form-check-label {
  color: #ffffff;
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

@media only screen and (max-width: 575px) {
  .bookmark-btn .form-check-label {
    width: 40px;
    height: 40px;
    line-height: 36px;
  }
}

.bookmark-btn.style-1 .form-check-label {
  margin-left: 10px;
}

.bookmark-btn.style-1 .form-check-input:checked+.form-check-label svg path {
  stroke: #fff;
}

.bookmark-btn.style-2 .form-check-label {
  position: absolute;
  top: 30px;
  right: 30px;
}

@media only screen and (max-width: 991px) {
  .bookmark-btn.style-2 .form-check-label {
    top: 25px;
    right: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .bookmark-btn.style-2 .form-check-label {
    top: 30px;
    right: 30px;
  }
}

.panel-btn {
  display: none;
  color: var(--title);
}

@media only screen and (max-width: 1199px) {
  .panel-btn {
    display: block;
  }
}

.panel-close-btn {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  cursor: pointer;
  overflow: hidden;
  z-index: 9999;
  opacity: 0;
  background: var(--secondary);
  filter: blur(100px);
  width: 0;
}

.panel-close-btn svg {
  position: absolute;
  left: 420px;
  top: 20px;
}

.panel-close-btn.active {
  width: 100%;
  opacity: 0.8;
  filter: blur(0);
}

@media only screen and (max-width: 1199px) {
  .panel-close-btn {
    display: block;
  }
}

[class*=btn-outline],
.btn-primary,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart-form .table tr .button,
.woocommerce #review_form #respond .form-submit input,
.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a,
.wp-block-button__link,
.btn-secondary,
.btn-success,
.btn-danger,
.btn-dark,
.btn-info {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

[class*=btn-outline]:after,
.btn-primary:after,
.woocommerce #respond input#submit:after,
.woocommerce a.button:after,
.woocommerce button.button:after,
.woocommerce input.button:after,
.woocommerce #place_order:after,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:after,
.woocommerce-cart-form .table tr .button:after,
.woocommerce #review_form #respond .form-submit input:after,
.woocommerce .widget_shopping_cart .buttons a:after,
.woocommerce.widget_shopping_cart .buttons a:after,
.wp-block-button__link:after,
.btn-secondary:after,
.btn-success:after,
.btn-danger:after,
.btn-dark:after,
.btn-info:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0.8;
  -webkit-transform: translate(-105%, 0);
  transform: translate(-105%, 0);
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.5);
}

[class*=btn-outline]:hover::after,
.btn-primary:hover::after,
.woocommerce #respond input#submit:hover::after,
.woocommerce a.button:hover::after,
.woocommerce button.button:hover::after,
.woocommerce input.button:hover::after,
.woocommerce #place_order:hover::after,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover::after,
.woocommerce-cart-form .table tr .button:hover::after,
.woocommerce #review_form #respond .form-submit input:hover::after,
.woocommerce .widget_shopping_cart .buttons a:hover::after,
.woocommerce.widget_shopping_cart .buttons a:hover::after,
.wp-block-button__link:hover::after,
.btn-secondary:hover::after,
.btn-success:hover::after,
.btn-danger:hover::after,
.btn-dark:hover::after,
.btn-info:hover::after {
  opacity: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.service-btn-2 {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary);
}

.service-btn-2 .icon-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-flex;
}

.service-btn-2.light {
  background: #FCF7E2;
}

.service-btn-2:hover {
  background: var(--primary);
}

.service-btn-2:hover.light svg path {
  stroke: #fff;
}

.badge {
  padding: 5px 10px;
  font-weight: var(--headings-font-weight);
  background-color: var(--primary);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-family: var(--font-family-base);
  font-size: 12px;
  min-width: 22px;
  min-height: 22px;
  text-transform: uppercase;
  text-align: center;
}

.badge.badge-sm {
  padding: 4px 5px;
  font-size: 10px;
  min-width: 18px;
  min-height: 16px;
}

.badge:hover {
  color: #fff;
}

.badge.badge-primary {
  background-color: var(--primary);
}

.badge.badge-secondary {
  background-color: #000;
}

.badge.badge-info {
  background-color: #0194D9;
}

.badge.badge-success {
  background-color: #31A56D;
}

.badge.badge-warning {
  background-color: #E79600;
}

.badge.badge-danger {
  background-color: #D23636;
}

.badge.badge-light {
  background-color: #FFFAF3;
}

.badge.badge-circle {
  border-radius: 50%;
}

.badge.style-1 {
  font-size: 16px;
  font-weight: 900;
  min-height: unset;
  color: var(--title);
  text-transform: uppercase;
  background: transparent;
  padding: 0;
}

.bg-purple {
  background-color: #5E5CC6;
  color: #fff;
}

.bg-red {
  background-color: #EB5757;
  color: #fff;
}

.bg-orange {
  background-color: #CD9B60;
  color: #fff;
}

.bg-green {
  background-color: #24262B;
  color: #fff;
}

.table-responsive-sm {
  min-width: 48rem;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;
}

.table tr td,
.table tr th {
  padding: 10px 20px;
}

@media only screen and (max-width: 575px) {

  .table tr td,
  .table tr th {
    padding: 5px;
  }
}

.table.book-overview tr th {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  width: 200px;
}

.table-striped thead tr th {
  padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tfoot {
  border-top: 2px solid #D7D7D7;
}

.table-striped tfoot tr th {
  padding-top: 10px;
}

.table> :not(:first-child) {
  border-top: 1px solid #dee2e6;
}

.check-tbl {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  vertical-align: middle;
}

.check-tbl.style-1 thead th:not(:first-child) {
  padding-left: 15px;
}

.check-tbl.style-1 tbody tr {
  border-bottom: 1px solid rgba(94, 98, 111, 0.2);
}

.check-tbl.style-1 tbody tr td {
  padding: 30px 18px;
}

.check-tbl.style-1 tbody tr td img {
  min-width: 100px;
  height: 100px;
  border-radius: 30px;
}

.check-tbl.style-1 tbody tr td:first-child,
.check-tbl.style-1 tbody tr td:last-child {
  padding: 30px 0;
}

.check-tbl.style-1 tbody tr td.product-item-img {
  min-width: 100px;
  height: 100px;
}

@media only screen and (max-width: 1199px) {
  .check-tbl.style-1 tbody tr td {
    padding: 20px 18px;
  }

  .check-tbl.style-1 tbody tr td img {
    min-width: 80px;
    height: 80px;
  }

  .check-tbl.style-1 tbody tr td:first-child,
  .check-tbl.style-1 tbody tr td:last-child {
    padding: 20px 0;
  }

  .check-tbl.style-1 tbody tr td.product-item-img {
    min-width: 80px;
    height: 80px;
  }
}

.check-tbl.style-2 {
  border-bottom: 0;
}

.check-tbl.style-2 thead th {
  padding-left: 0px;
}

.check-tbl.style-2 tbody tr td {
  font-size: 14px;
  font-weight: 400;
}

.check-tbl.style-2 tbody tr td a {
  color: var(--secondary);
}

.check-tbl.style-2 tbody tr td i {
  background: var(--primary);
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
  padding: 3px 4px;
  margin-right: 15px;
}

.check-tbl.style-2 tbody tr td:last-child {
  text-align: unset;
}

.check-tbl.style-2 tbody tr td:first-child {
  width: 33%;
  background: #f3f3f3;
}

.check-tbl.style-2 tbody tr td.disable {
  color: #BEBEBE;
}

.check-tbl.style-2 tbody tr td.disable i {
  background: #BEBEBE;
}

.check-tbl thead th {
  font-size: 16px;
  font-weight: 700;
  padding: 0 0 20px;
  color: #24262B;
}

.check-tbl tbody tr {
  border-bottom: 1px solid rgba(94, 98, 111, 0.2);
}

.check-tbl tbody tr td {
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary);
  border: none;
  font-family: var(--font-family-title);
  padding-left: 0;
}

.check-tbl tbody tr td img {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 30px;
}

.check-tbl tbody tr td:first-child,
.check-tbl tbody tr td:last-child {
  padding: 20px 0;
}

.check-tbl tbody tr td:last-child {
  text-align: right;
}

.check-tbl tbody tr td.product-item-name {
  font-size: 18px;
  font-family: var(--font-family-title);
  padding-left: 15px;
}

.check-tbl tbody tr td.product-item-price {
  color: #5E626F;
}

@media only screen and (max-width: 1199px) {
  .check-tbl tbody tr td.product-item-name {
    font-size: 16px;
  }
}

.check-tbl tbody .product-item-price span {
  font-size: 16px;
  text-decoration: line-through;
  color: #5E626F;
  margin-right: 10px;
  font-weight: 400;
}

.check-tbl tbody .product-item-price strong {
  font-weight: 500;
  color: #24262B;
}

.check-tbl .product-item-close {
  text-align: right;
}

.check-tbl .product-item-close a {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: var(--secondary);
  display: inline-block;
  text-align: center;
  line-height: 36px;
}

.check-tbl .product-item-close a i {
  color: #fff;
  font-weight: 900;
  vertical-align: middle;
}

.product-item-img {
  width: 80px;
  height: 80px;
}

.check-tbl {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.dz-table-heading h5,
.dz-table-heading .h5 {
  padding: 20px 30px;
  background: rgba(215, 215, 215, 0.2);
}

.table-responsive-md {
  min-width: 36rem !important;
}

.dz-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative;
}

.dz-separator.m-b0 {
  margin-bottom: 0;
}

.dz-separator.style-liner {
  width: 20px;
}

.dz-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
}

.dz-separator.style-skew {
  width: 15px;
  height: 10px;
  margin-left: 1px;
  margin-right: 1px;
  -moz-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  -o-transform: skewX(-10deg);
  -ms-transform: skewX(-10deg);
  transform: skewX(-10deg);
}

.dz-separator.style-skew[class*=style-]:after,
.dz-separator.style-skew[class*=style-]:before {
  width: 80px;
  height: 4px;
  left: 20px;
  -moz-transform: translateY(-50%) skewX(-10deg);
  -webkit-transform: translateY(-50%) skewX(-10deg);
  -o-transform: translateY(-50%) skewX(-10deg);
  -ms-transform: translateY(-50%) skewX(-10deg);
  transform: translateY(-50%) skewX(-10deg);
}

.dz-separator.style-skew[class*=style-]:before {
  right: 20px;
  left: auto;
}

.dz-separator.bnr-title {
  height: 1px;
  width: 155px;
  opacity: 0.5;
}

.dz-separator.bnr-title:before,
.dz-separator.bnr-title:after {
  position: absolute;
  content: "";
  background-color: inherit;
}

.dz-separator.bnr-title:before {
  height: inherit;
  right: -80px;
  width: 25px;
}

.dz-separator.bnr-title:after {
  height: inherit;
  right: -90px;
  top: 0;
  width: 6px;
}

.dz-separator.bnr-title i {
  background-color: inherit;
  display: block;
  height: inherit;
  position: absolute;
  right: -50px;
  width: 45px;
}

.dz-separator-outer {
  overflow: hidden;
}

.dz-separator.style-1 {
  height: 3px;
  width: 60px;
  background: var(--primary);
}

.dz-separator.style-2 {
  height: 10px;
  display: block;
  width: 82px;
  position: relative;
  margin: 0;
}

.dz-separator.style-2:before,
.dz-separator.style-2:after {
  content: "";
  height: 3px;
  width: 89px;
  position: absolute;
  background: var(--primary);
  left: 0;
}

.dz-separator.style-2:after {
  width: 80%;
  bottom: 0;
}

.dz-separator.style-2:before {
  width: 100%;
  top: 0;
}

.site-filters {
  margin-bottom: 20px;
}

.site-filters .product-link {
  margin-left: auto;
}

.site-filters ul {
  margin: 0;
  list-style: none;
}

.site-filters li {
  display: inline-block;
  padding: 0;
  margin-bottom: 5px;
  margin-right: 30px;
}

.site-filters li.btn,
.site-filters .woocommerce li#place_order,
.woocommerce .site-filters li#place_order,
.site-filters .woocommerce-cart-form .table tr li.button,
.woocommerce-cart-form .table tr .site-filters li.button,
.site-filters li.wp-block-button__link {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
  border: 0;
}

.site-filters li input {
  display: none;
}

.site-filters li a {
  margin: 0;
  display: inline-flex;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--title);
  font-family: var(--font-family-base);
}

.site-filters li a span {
  font-size: 14px;
  color: #5E626F;
  margin-left: 4px;
}

.site-filters li.active a {
  color: var(--primary);
}

@media only screen and (max-width: 991px) {
  .site-filters ul li a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .site-filters {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .site-filters ul {
    margin-bottom: 10px;
  }

  .site-filters .product-link {
    margin-left: 0;
    margin-right: auto;
  }
}

.site-filters.center {
  text-align: center;
}

.site-filters.center ul {
  display: inline-block;
  margin: auto;
}

.site-filters.center [class*=btn] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .site-filters.center [class*=btn] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}

.site-filters.style-1 {
  border: 1px solid #000;
  width: fit-content;
  border-radius: 40px;
  padding: 6px;
}

.site-filters.style-1 .filters {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .site-filters.style-1 {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .site-filters.style-1 {
    padding: 4px;
  }
}

.site-filters.style-1 li {
  margin: 0;
  padding: 5px 20px;
  border-radius: 60px;
}

@media only screen and (max-width: 1199px) {
  .site-filters.style-1 li {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .site-filters.style-1 li {
    padding: 5px 8px;
  }
}

.site-filters.style-1 li a {
  font-weight: 500;
  color: var(--title);
  text-transform: capitalize;
  line-height: 20px;
  font-family: var(--font-family-base);
}

.site-filters.style-1 li.active {
  background-color: var(--secondary);
}

.site-filters.style-1 li.active a {
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .site-filters.style-1 {
    margin-bottom: 40px;
  }
}

.site-filters.style-1.light {
  background: var(--light-dark);
  border: 1px solid var(--light-dark);
}

.site-filters.style-1.light li.active {
  background: #fff;
}

.site-filters.style-1.light li.active a {
  color: var(--title);
}

.site-filters.style-2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

.site-filters.style-2 li {
  margin: 0 !important;
}

.site-filters.style-2 li a {
  padding: 23px 15px;
  font-weight: 400;
  color: #5E626F;
  text-transform: capitalize;
}

.site-filters.style-2 li.active a {
  color: var(--primary);
}

@media only screen and (max-width: 575px) {
  .site-filters.style-2 {
    margin-bottom: 40px;
  }

  .site-filters.style-2 li {
    padding: 0 !important;
  }

  .site-filters.style-2 li a {
    margin: 0 1px 5px;
    padding: 5px 10px;
  }
}

.site-filters.style-3 li {
  margin: 0 !important;
}

.site-filters.style-3 li a {
  background: #fff;
  color: #000;
  display: block;
  font-size: 15px;
  margin-bottom: 4px;
  padding: 15px 25px;
  width: 100%;
  border-radius: 10px;
  text-transform: capitalize;
}

.site-filters.style-3 li a:hover {
  background: #000;
  color: #fff;
}

.site-filters.style-3 li.active a {
  background: var(--secondary);
  border-left-color: var(--secondary);
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .site-filters.style-3 {
    margin-bottom: 40px;
  }

  .site-filters.style-3 li {
    padding: 0 !important;
  }

  .site-filters.style-3 li a {
    margin: 0 1px 5px;
    padding: 5px 10px;
  }
}

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12,
.dz-img-overlay13 {
  position: relative;
}

.dz-img-overlay1:before,
.dz-img-overlay1:after,
.dz-img-overlay2:before,
.dz-img-overlay2:after,
.dz-img-overlay3:before,
.dz-img-overlay3:after,
.dz-img-overlay4:before,
.dz-img-overlay4:after,
.dz-img-overlay5:before,
.dz-img-overlay5:after,
.dz-img-overlay6:before,
.dz-img-overlay6:after,
.dz-img-overlay7:before,
.dz-img-overlay7:after,
.dz-img-overlay8:before,
.dz-img-overlay8:after,
.dz-img-overlay9:before,
.dz-img-overlay9:after,
.dz-img-overlay11:before,
.dz-img-overlay11:after,
.dz-img-overlay12:before,
.dz-img-overlay12:after,
.dz-img-overlay13:before,
.dz-img-overlay13:after {
  content: "";
  background: #24262b;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

.dz-img-overlay1.overlay-primary,
.dz-img-overlay2.overlay-primary,
.dz-img-overlay3.overlay-primary,
.dz-img-overlay4.overlay-primary,
.dz-img-overlay5.overlay-primary,
.dz-img-overlay6.overlay-primary,
.dz-img-overlay7.overlay-primary,
.dz-img-overlay8.overlay-primary,
.dz-img-overlay9.overlay-primary,
.dz-img-overlay11.overlay-primary,
.dz-img-overlay12.overlay-primary,
.dz-img-overlay13.overlay-primary {
  background: var(--primary);
}

.dz-box:hover .dz-img-overlay1:before {
  opacity: 0.7;
}

.dz-box:hover .dz-img-overlay2:before {
  opacity: 0.9;
}

.dz-box:hover .dz-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-box:hover .dz-img-overlay4:before {
  width: 100%;
  left: 0;
}

.dz-box:hover .dz-img-overlay5:before {
  height: 100%;
  top: 0;
}

.dz-box:hover .dz-img-overlay6:before {
  height: 100%;
}

.dz-box:hover .dz-img-overlay7:before,
.dz-box:hover .dz-img-overlay7:after {
  width: 50%;
}

.dz-box:hover .dz-img-overlay8:before,
.dz-box:hover .dz-img-overlay8:after {
  width: 100%;
  opacity: 0.3;
}

.dz-box:hover .dz-img-overlay9:before,
.dz-box:hover .dz-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.dz-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.dz-img-overlay2:before:hover:before {
  opacity: 0.9;
}

.dz-img-overlay3:before {
  left: 50%;
  top: 50%;
}

.dz-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dz-img-overlay4:hover:before {
  width: 100%;
  left: 0;
}

.dz-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dz-img-overlay5:hover:before {
  height: 100%;
  top: 0;
}

.dz-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dz-img-overlay6:hover:before {
  height: 100%;
}

.dz-img-overlay7:before,
.dz-img-overlay7:after {
  height: 100%;
}

.dz-img-overlay7:after {
  left: auto;
  right: 0;
}

.dz-img-overlay7:hover:before,
.dz-img-overlay7:hover:after {
  width: 50%;
}

.dz-img-overlay8:before,
.dz-img-overlay8:after {
  height: 100%;
}

.dz-img-overlay8:after {
  left: auto;
  right: 0;
}

.dz-img-overlay8:hover:before,
.dz-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3;
}

.dz-img-overlay9:before,
.dz-img-overlay9:after,
.dz-img-overlay9:before,
.dz-img-overlay9:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dz-img-overlay9:hover:before,
.dz-img-overlay9:hover:after,
.dz-img-overlay9:hover:before,
.dz-img-overlay9:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay13,
.dz-img-overlay13 {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-img-overlay13:before,
.dz-img-overlay13:after,
.dz-img-overlay13:before,
.dz-img-overlay13:after {
  width: 100px;
  height: 100%;
  background-color: #444444;
  top: -160px;
  left: -320px;
  transform: rotate(45deg);
}

.dz-img-overlay13:hover:before,
.dz-img-overlay13:hover:after,
.dz-img-overlay13:hover:before,
.dz-img-overlay13:hover:after {
  left: auto;
  right: -320px;
  top: 160px;
}

/*  */
.overlay-secondary-light,
.overlay-secondary-middle,
.overlay-secondary-dark,
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}

.overlay-secondary-light:before,
.overlay-secondary-middle:before,
.overlay-secondary-dark:before,
.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before,
.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before,
.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before,
.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay-secondary-light .container,
.overlay-secondary-light .container-fluid,
.overlay-secondary-light .container-sm,
.overlay-secondary-light .container-md,
.overlay-secondary-light .container-lg,
.overlay-secondary-light .container-xl,
.overlay-secondary-middle .container,
.overlay-secondary-middle .container-fluid,
.overlay-secondary-middle .container-sm,
.overlay-secondary-middle .container-md,
.overlay-secondary-middle .container-lg,
.overlay-secondary-middle .container-xl,
.overlay-secondary-dark .container,
.overlay-secondary-dark .container-fluid,
.overlay-secondary-dark .container-sm,
.overlay-secondary-dark .container-md,
.overlay-secondary-dark .container-lg,
.overlay-secondary-dark .container-xl,
.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #000;
}

.overlay-secondary-light:before,
.overlay-secondary-middle:before,
.overlay-secondary-dark:before {
  background: #000;
}

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #FFF;
}

.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: var(--primary);
}

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-secondary-light:before,
.overlay-black-light:before {
  opacity: 0.4;
}

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before,
.overlay-secondary-middle:before {
  opacity: 0.7;
}

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before,
.overlay-secondary-dark:before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}

.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}

.overlay-icon a i {
  background-color: #FFF;
}

.overlay-bx:hover a>i,
.dz-media:hover .overlay-bx a>i,
.dz-box-bx:hover .overlay-bx a>i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.img-overlay-gradients {
  position: relative;
}

.img-overlay-gradients:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.img-overlay-gradients.gradients-secondary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, #000 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, #000 100%);
  background: linear-gradient(to bottom, transparent 0%, #000 100%);
}

.img-overlay-gradients.gradients-primary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: linear-gradient(to bottom, transparent 0%, var(--primary) 100%);
}

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden;
}

.overlay-shine .dz-media:before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.overlay-shine:hover .dz-media:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.dz-box.style-1 .dz-maximize {
  opacity: 0;
}

.dz-box.style-1:hover .dz-maximize {
  opacity: 1;
}

/* info box show */
.dz-info-has {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 30px 15px 40px;
  opacity: 0;
  margin-bottom: -100px;
  z-index: 2;
}

.dz-info-has.p-a15 {
  padding: 15px;
}

.dz-info-has.p-a20 {
  padding: 20px;
}

.dz-info-has h1,
.dz-info-has .h1,
.dz-info-has h2,
.dz-info-has .h2,
.dz-info-has h3,
.dz-info-has .h3,
.dz-info-has h4,
.dz-info-has .h4,
.dz-info-has h5,
.dz-info-has .h5,
.dz-info-has a,
.dz-info-has p,
.dz-info-has h6,
.dz-info-has .h6 {
  color: #fff;
}

.dz-info-has .dz-info-has-text {
  margin-bottom: 20px;
  line-height: 24px;
}

.dz-info-has.bg-white h1,
.dz-info-has.bg-white .h1,
.dz-info-has.bg-white h2,
.dz-info-has.bg-white .h2,
.dz-info-has.bg-white h3,
.dz-info-has.bg-white .h3,
.dz-info-has.bg-white h4,
.dz-info-has.bg-white .h4,
.dz-info-has.bg-white h5,
.dz-info-has.bg-white .h5,
.dz-info-has.bg-white h6,
.dz-info-has.bg-white .h6 {
  color: #3d474a;
}

.dz-info-has.bg-white h1 a,
.dz-info-has.bg-white .h1 a,
.dz-info-has.bg-white h2 a,
.dz-info-has.bg-white .h2 a,
.dz-info-has.bg-white h3 a,
.dz-info-has.bg-white .h3 a,
.dz-info-has.bg-white h4 a,
.dz-info-has.bg-white .h4 a,
.dz-info-has.bg-white h5 a,
.dz-info-has.bg-white .h5 a,
.dz-info-has.bg-white h6 a,
.dz-info-has.bg-white .h6 a {
  color: #3d474a;
}

.dz-info-has.bg-white p {
  color: #3d474a;
}

.dz-info-has.bg-primary {
  background-color: rgba(27, 188, 232, 0.9);
}

.dz-info-has.bg-green {
  background-color: rgba(106, 179, 62, 0.9);
}

.dz-info-has.bg-black {
  background-color: rgba(0, 0, 0, 0.6);
}

.dz-info-has.bg-white {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.dz-media {
  overflow: hidden;
  position: relative;
}

.dz-media:hover .dz-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

.dz-box:hover .dz-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

.overlay-bx.no-hover,
.dz-info-has.no-hover {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
  visibility: visible;
}

.owl-none .owl-nav {
  display: none;
}

.owl-nav button.owl-prev,
.owl-nav button.owl-next {
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.owl-theme .owl-nav,
.owl-theme .owl-dots {
  margin-top: 20px;
  text-align: center;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-dots {
  text-align: center;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot.active span {
  background: #869791;
}

.owl-theme.owl-dots-1 .owl-dots .owl-dot span {
  background-color: #dadada;
  width: 15px;
  height: 15px;
  margin: 5px;
}

.owl-theme.owl-dots-1 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-1 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-theme.owl-dots-2 .owl-dots {
  margin-top: 40px;
  position: unset;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto;
}

.owl-theme.owl-dots-2 .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}

.owl-theme.owl-dots-2 .owl-dots .owl-dot span {
  height: 10px;
  width: 10px;
  margin: 0;
  border-radius: 0;
}

.owl-theme.owl-dots-2 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-2 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-theme.owl-dots-3 .owl-dots {
  margin-top: 40px;
  position: unset;
  display: block;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto;
}

.owl-theme.owl-dots-3 .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}

.owl-theme.owl-dots-3 .owl-dots .owl-dot span {
  height: 8px;
  width: 8px;
  margin: 0;
  background-color: #898c90;
  border-radius: 8px;
}

.owl-theme.owl-dots-3 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-3 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-btn-top-c .owl-nav {
  text-align: center;
}

.owl-btn-top-l .owl-nav {
  text-align: left;
}

.owl-btn-top-l .owl-nav .owl-prev {
  margin-left: 0;
}

.owl-btn-top-r .owl-nav {
  position: absolute;
  top: -125px;
  right: 0;
  margin: 0;
}

.owl-btn-top-r .owl-nav .owl-next {
  margin-right: 0;
}

.owl-btn-top-lr .owl-nav {
  text-align: left;
}

.owl-btn-top-lr .owl-nav .owl-next {
  margin-right: 0;
  float: right;
}

.owl-btn-full .owl-prev,
.owl-btn-full .owl-next {
  padding: 40px 8px;
}

.owl-btn-center .owl-nav {
  justify-content: center;
}

.owl-btn-center-lr .owl-prev,
.owl-btn-center-lr .owl-next {
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
}

.owl-btn-center-lr .owl-prev {
  left: -110px;
}

.owl-btn-center-lr .owl-next {
  left: auto;
  right: -110px;
}

@media only screen and (max-width: 1480px) {
  .owl-btn-center-lr .owl-prev {
    left: -40px;
  }

  .owl-btn-center-lr .owl-next {
    right: -40px;
  }
}

@media only screen and (max-width: 1199px) {
  .owl-btn-center-lr .owl-prev {
    left: -20px;
  }

  .owl-btn-center-lr .owl-next {
    right: -20px;
  }
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

.owl-btn-1 .owl-nav button.owl-prev,
.owl-btn-1 .owl-nav button.owl-next {
  border-radius: 3px;
  line-height: 40px;
  margin: 0 20px !important;
  padding: 0;
  text-align: center;
  color: var(--primary) !important;
  font-size: 35px;
  height: auto;
  width: auto;
  background-color: transparent;
  position: relative;
}

.owl-btn-1 .owl-nav button.owl-prev:before,
.owl-btn-1 .owl-nav button.owl-next:before {
  content: "";
  position: absolute;
  font-family: "Lufga", sans-serif;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 3px;
  position: absolute;
  font-weight: 400;
  top: 8px;
  color: #444444;
}

.owl-btn-1 .owl-nav button.owl-prev:hover,
.owl-btn-1 .owl-nav button.owl-next:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  opacity: 1;
}

@media only screen and (max-width: 991px) {

  .owl-btn-1 .owl-nav button.owl-prev,
  .owl-btn-1 .owl-nav button.owl-next {
    margin: 0 10px !important;
  }
}

.owl-btn-1 .owl-nav button.owl-prev {
  padding: 0 30px 0 0px !important;
}

.owl-btn-1 .owl-nav button.owl-prev:before {
  content: "PREV";
  right: 0;
}

.owl-btn-1 .owl-nav button.owl-next {
  padding: 0 0 0 30px !important;
}

.owl-btn-1 .owl-nav button.owl-next:before {
  content: "NEXT";
  left: 0;
}

.owl-btn-1.btn-white .owl-nav .owl-prev,
.owl-btn-1.btn-white .owl-nav .owl-next {
  color: #fff;
}

.owl-btn-1.btn-white .owl-nav .owl-prev:hover,
.owl-btn-1.btn-white .owl-nav .owl-next:hover {
  color: #fff;
}

.owl-btn-2.owl-btn-rounded .owl-nav .owl-prev,
.owl-btn-2.owl-btn-rounded .owl-nav .owl-next {
  border-radius: 8px;
}

.owl-btn-2 .owl-nav .owl-prev,
.owl-btn-2 .owl-nav .owl-next {
  height: 60px;
  line-height: 60px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 60px;
  box-shadow: 0 4px 15px var(--rgba-primary-2);
}

@media only screen and (max-width: 575px) {

  .owl-btn-2 .owl-nav .owl-prev,
  .owl-btn-2 .owl-nav .owl-next {
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 18px !important;
  }
}

.owl-btn-3 .owl-nav .owl-prev,
.owl-btn-3 .owl-nav .owl-next {
  border-radius: 50px;
  height: 45px;
  line-height: 45px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 45px;
}

.owl-btn-primary .owl-nav button.owl-prev,
.owl-btn-primary .owl-nav button.owl-next {
  background-color: var(--primary);
  color: #ffffff;
  transition: all 0.5s;
}

.owl-btn-primary .owl-nav button.owl-prev:hover,
.owl-btn-primary .owl-nav button.owl-next:hover {
  background-color: #ffffff;
  color: var(--primary);
}

.owl-btn-white .owl-prev,
.owl-btn-white .owl-next {
  background-color: #ffffff;
  color: var(--primary);
  transition: all 0.5s;
}

.owl-btn-white .owl-prev:hover,
.owl-btn-white .owl-next:hover {
  background-color: var(--primary);
  color: #ffffff;
}

.owl-dots-none .owl-dots {
  display: none;
}

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-primary-full .owl-dots .owl-dot span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin: 4px;
}

.owl-dots-white-full .owl-dots .owl-dot:active span,
.owl-dots-white-full .owl-dots .owl-dot.active span,
.owl-dots-black-full .owl-dots .owl-dot:active span,
.owl-dots-black-full .owl-dots .owl-dot.active span,
.owl-dots-primary-full .owl-dots .owl-dot:active span,
.owl-dots-primary-full .owl-dots .owl-dot.active span {
  opacity: 1;
  background-color: var(--primary);
}

.owl-dots-white-big .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin: 4px;
}

.owl-dots-white-big .owl-dots .owl-dot:active span,
.owl-dots-black-big .owl-dots .owl-dot:active span,
.owl-dots-primary-big .owl-dots .owl-dot:active span {
  width: 14px;
  height: 14px;
  opacity: 1;
  margin: 0px 4px 2px 4px;
}

.owl-dots-primary-full .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  background-color: var(--primary);
}

.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span {
  background-color: #333;
}

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-white-big .owl-dots .owl-dot span {
  background-color: #f5f5f5;
}

/* owl num count */
.owl-num-count .owl-dots {
  text-align: right;
  counter-reset: li;
  justify-content: center;
  display: flex;
}

.owl-num-count .owl-dots .owl-dot {
  padding: 5px 2px !important;
  cursor: pointer;
  font-size: 18px;
  position: relative;
}

.owl-num-count .owl-dots .owl-dot span {
  width: 20px;
  height: 5px;
  border-radius: 10px;
  transition: all 0.5s;
  background-color: transparent;
}

.owl-num-count .owl-dots .owl-dot:before {
  content: "0" counter(li, decimal);
  counter-increment: li;
  font-weight: 600;
  color: #777777;
  font-size: 18px;
}

.owl-num-count .owl-dots .owl-dot.active:before {
  color: var(--primary);
}

.owl-num-count .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.dz-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;
}

.dz-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-img-effect.opacity img:hover {
  opacity: 0.8;
}

.dz-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}

.dz-img-effect.zoom-slow:hover img {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.zoom:hover img {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.dz-img-effect.shrink:hover img {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.dz-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.dz-img-effect.fade-in {
  background: #000;
}

.dz-img-effect.fade-in img {
  opacity: 0.65;
}

.dz-img-effect.fade-in:hover img {
  opacity: 1;
}

.dz-img-effect.fade-out {
  background: #000;
}

.dz-img-effect.fade-out:hover img {
  opacity: 0.7;
}

.dz-img-effect.rotate:hover img {
  -moz-transform: scale(1.3) rotate(-20deg);
  -webkit-transform: scale(1.3) rotate(-20deg);
  -o-transform: scale(1.3) rotate(-20deg);
  -ms-transform: scale(1.3) rotate(-20deg);
  transform: scale(1.3) rotate(-20deg);
}

.dz-img-effect.image-sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

.dz-img-effect.blurr img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dz-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.dz-img-effect.blurr-invert img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.dz-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}

.dz-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.dz-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.dz-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-box:hover .dz-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-accordion .accordion-item {
  border-radius: 0;
  overflow: hidden;
  background: transparent;
  margin-bottom: 20px;
  border: 0;
}

.dz-accordion .accordion-body {
  padding: 25px 30px;
  border-radius: 0;
}

.dz-accordion .accordion-collapse {
  border: 0;
}

.dz-accordion .accordion-header .accordion-button {
  font-size: 18px;
  font-weight: 400;
  border: 0px;
  border-radius: 0;
  padding: 22px 65px 22px 30px;
  transition: all 0.5s ease 0s;
  color: var(--secondary);
  background: none;
  border: 1px solid var(--secondary);
  box-shadow: none;
  border-radius: 10px;
}

.dz-accordion .accordion-header .accordion-button:after {
  content: none;
}

.dz-accordion .accordion-header .accordion-button:focus {
  box-shadow: none;
}

.dz-accordion .accordion-header .accordion-button .toggle-close {
  font-family: themify;
  font-weight: 800;
  height: 55px;
  width: 55px;
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: var(--border-radius-base);
  line-height: 57px;
  text-align: center;
  font-size: 20px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
}

.dz-accordion .accordion-header .accordion-button .toggle-close:after {
  content: "\f068";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  z-index: -1;
  color: var(--body-color);
  background-color: transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
  font-size: 13px;
}

.dz-accordion .accordion-header .accordion-button.collapsed {
  color: #13212a;
}

.dz-accordion .accordion-header .accordion-button.collapsed:after {
  opacity: 0;
}

.dz-accordion .accordion-header .accordion-button.collapsed .toggle-close:after {
  content: "\f067";
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
    font-size: 16px;
  }
}

.dz-accordion.accordion-sm .accordion-body {
  padding: 20px 0;
}

.dz-accordion.accordion-sm .accordion-body p {
  color: var(--title);
}

.dz-accordion.accordion-sm .accordion-header .accordion-button {
  padding: 18px 55px 18px 23px;
  font-weight: 500;
}

.dz-accordion.accordion-sm .accordion-header .accordion-button .toggle-close {
  color: #fff;
  height: 29px;
  width: 29px;
  line-height: 29px;
  background: var(--secondary);
  border-radius: 15px;
}

.dz-accordion.accordion-sm .accordion-header .accordion-button .toggle-close:before {
  font-size: 25px;
  line-height: 50px;
}

@media only screen and (max-width: 1199px) {
  .dz-accordion.accordion-sm .accordion-header .accordion-button {
    padding: 15px 20px 15px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-accordion.accordion-sm .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
  }
}

.dz-accordion.style-1 .accordion-item {
  margin-bottom: 0;
}

.dz-accordion.style-1 .accordion-header .accordion-button {
  background: transparent;
  padding: 20px 10px 20px 45px;
  box-shadow: none;
  color: var(--title);
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
}

.dz-accordion.style-1 .accordion-header .accordion-button .toggle-close {
  left: 0;
  height: 30px;
  width: 30px;
}

.dz-accordion.style-1 .accordion-header .accordion-button .toggle-close:after {
  color: var(--primary);
  height: 30px;
  width: 30px;
  line-height: 25px;
  font-size: 17px;
  border-radius: 30px;
  border: 2px solid var(--primary);
}

.dz-accordion.style-1 .accordion-header .accordion-button.collapsed {
  border-bottom: 0;
}

.dz-accordion.style-1 .accordion-body {
  padding: 20px 0;
}

.dz-accordion.gradient-bg .accordion-item {
  overflow: visible;
}

.dz-accordion.gradient-bg .accordion-header .accordion-button {
  background: rgb(196, 39, 128);
  /* Old browsers */
  background: -moz-linear-gradient(left, rgb(196, 39, 128) 9%, rgb(26, 29, 188) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgb(196, 39, 128) 9%, rgb(26, 29, 188) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgb(196, 39, 128) 9%, rgb(26, 29, 188) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c42780", endColorstr="#1a1dbc", GradientType=1);
  /* IE6-9 */
  box-shadow: none;
}

.dz-accordion.gradient-bg .accordion-header .accordion-button.collapsed {
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px, rgba(0, 0, 0, 0.15) 0px 3px 7px -3px;
}

.dz-accordion.rounded .accordion-header .accordion-button {
  border-radius: 40px;
  box-shadow: none;
}

.dz-accordion.rounded .accordion-header .accordion-button.collapsed {
  box-shadow: none;
  background: var(--primary);
  color: #fff;
}

.dz-accordion.rounded .accordion-header .accordion-button.collapsed .toggle-close::after {
  color: #fff;
}

.acod-content {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 40px;
  margin-top: -30px;
  transition: all 0.5s;
}

.acod-content .widget {
  padding: 25px 25px;
}

.acod-content .widget.widget_services {
  display: flex;
  flex-wrap: wrap;
}

.acod-content .widget.widget_services .form-check {
  margin-bottom: 10px;
  width: 25%;
}

.acod-content .widget.widget_services .form-check .form-check-input {
  border: 2px solid #AAAAAA;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0;
}

.acod-content .widget.widget_services .form-check .form-check-input:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.acod-content .widget.widget_services .form-check .form-check-label {
  font-family: var(--font-family-title);
  margin-left: 10px;
  font-size: 15px;
}

@media only screen and (max-width: 1680px) {
  .acod-content .widget.widget_services .form-check .form-check-input {
    margin-top: 0.3rem;
  }

  .acod-content .widget.widget_services .form-check .form-check-label {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {
  .acod-content .widget.widget_services .form-check {
    width: 33%;
  }
}

@media only screen and (max-width: 767px) {
  .acod-content .widget.widget_services .form-check {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .acod-content .widget.widget_services .form-check {
    width: 100%;
  }
}

.acod-content .widget.widget_services.style-2 .form-check {
  width: 33.33%;
}

@media only screen and (max-width: 767px) {
  .acod-content .widget.widget_services.style-2 .form-check {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .acod-content .widget.widget_services.style-2 .form-check {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .acod-content {
    max-height: 250px;
    overflow: scroll;
  }

  .acod-content .widget {
    margin-bottom: 0;
  }
}

.accordion.accordion-filter .accordion-item {
  margin-bottom: 12px;
  border-radius: 6px;
  box-shadow: none;
  overflow: hidden;
  border: 0 !important;
}

.accordion.accordion-filter .accordion-item .accordion-button {
  background: #ffffff;
  padding: 12px 20px;
  color: var(--secondary);
  font-size: 1rem;
  font-family: var(--font-family-title);
  box-shadow: none;
  font-weight: 500;
  height: 60px;
}

.accordion.accordion-filter .accordion-item .accordion-body {
  padding: 0 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.5s;
}

.accordion.accordion-filter .accordion-item .accordion-body.show {
  padding: 20px;
}

.accordion.accordion-filter .accordion-item .accordion-body ul {
  padding-left: 0px;
}

.accordion.accordion-filter .accordion-item .accordion-body ul li {
  padding: 4px 0px;
  font-size: 16px;
}

.accordion.accordion-filter .accordion-item .accordion-body ul li a {
  color: var(--secondary);
}

.accordion.accordion-filter .accordion-item .accordion-body ul li a:hover {
  color: var(--primary);
}

.accordion.accordion-filter .accordion-item .accordion-body .slider-range {
  padding: 15px 8px 75px;
}

.pagination.style-1 {
  justify-content: end;
}

.pagination.style-1 li .page-numbers,
.pagination.style-1 li .page-link {
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  color: var(--title);
  text-align: center;
  border: 0;
  padding: 0;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: var(--headings-font-weight);
  border-radius: var(--border-radius-base);
  padding: 0 10px;
  margin: 0 5px;
  display: block;
  text-transform: uppercase;
  background-color: var(--light);
}

.pagination.style-1 li .page-numbers.current,
.pagination.style-1 li .page-numbers.active,
.pagination.style-1 li .page-numbers:hover,
.pagination.style-1 li .page-link.current,
.pagination.style-1 li .page-link.active,
.pagination.style-1 li .page-link:hover {
  color: #fff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

@media only screen and (max-width: 575px) {
  .pagination.style-1 li {
    margin: 0 3px;
  }

  .pagination.style-1 li .page-numbers,
  .pagination.style-1 li .page-link {
    height: 35px;
    min-width: 35px;
    line-height: 35px;
    border-radius: var(--border-radius-base);
  }
}

.pagination.style-1 li .prev,
.pagination.style-1 li .next {
  border-color: transparent;
  font-size: 16px;
  padding: 0 20px;
}

.pagination.style-1 li .prev:hover,
.pagination.style-1 li .next:hover {
  background-color: #FFFAF3;
  box-shadow: none;
  color: var(--title);
}

@media only screen and (max-width: 575px) {

  .pagination.style-1 li .prev,
  .pagination.style-1 li .next {
    min-width: 90px;
    font-size: 14px;
  }
}

.pagination.style-1.text-center {
  justify-content: center;
}

.pagination.style-1.pagination-lg li .page-numbers,
.pagination.style-1.pagination-lg li .page-link {
  height: 80px;
  min-width: 80px;
  line-height: 80px;
  border-radius: 80px;
}

.pagination.style-1.pagination-md li .page-numbers,
.pagination.style-1.pagination-md li .page-link {
  height: 60px;
  min-width: 60px;
  line-height: 60px;
  border-radius: 40px;
}

.pagination.style-1.pagination-sm li .page-numbers,
.pagination.style-1.pagination-sm li .page-link {
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  border-radius: 40px;
}

.pagination.style-1.rounded-0 .page-item .page-link {
  border-radius: 0;
}

.pagination.style-1 .page-numbers {
  border: 1px solid #F0F0F0;
  display: inline-flex;
  margin: 0 20px;
  background-color: #ffffff;
  border-radius: var(--border-radius-base);
}

.pagination.style-1 .page-numbers .page-item .page-link {
  background-color: #ffffff;
  color: var(--secondary);
}

.pagination.style-1 .page-numbers .page-item .page-link.active,
.pagination.style-1 .page-numbers .page-item .page-link:hover {
  color: #ffffff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.pagination.style-1 .page-numbers .page-item .page-link.active .page-num,
.pagination.style-1 .page-numbers .page-item .page-link:hover .page-num {
  background-color: transparent;
}

@media only screen and (max-width: 575px) {
  .pagination.style-1 .page-numbers {
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .pagination.style-1 {
    justify-content: center;
  }
}

.dz-content-bx {
  position: relative;
  overflow: hidden;
}

.dz-content-bx .content-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dz-content-bx .dz-content-inner {
  position: relative;
}

.dz-content-bx.style-1 .inner-content {
  padding-left: 40px;
}

.dz-content-bx.style-1 .inner-content .section-head .title {
  font-size: 45px;
  font-weight: 700;
  color: #ffffff;
}

.dz-content-bx.style-1 .inner-content .section-head p {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}

@media only screen and (max-width: 1480px) {
  .dz-content-bx.style-1 .inner-content {
    padding-left: 20px;
  }

  .dz-content-bx.style-1 .inner-content .section-head .title {
    font-size: 38px;
  }

  .dz-content-bx.style-1 .inner-content .section-head p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .dz-content-bx.style-1 .inner-content {
    padding-left: 20px;
  }

  .dz-content-bx.style-1 .inner-content .section-head .title {
    font-size: 28px;
  }

  .dz-content-bx.style-1 .inner-content .section-head p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-content-bx.style-1 .inner-content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1199px) {

  .dz-content-bx.style-1 .inner-content .btn,
  .dz-content-bx.style-1 .inner-content .woocommerce #respond input#submit,
  .woocommerce #respond .dz-content-bx.style-1 .inner-content input#submit,
  .dz-content-bx.style-1 .inner-content .woocommerce a.button,
  .woocommerce .dz-content-bx.style-1 .inner-content a.button,
  .dz-content-bx.style-1 .inner-content .woocommerce button.button,
  .woocommerce .dz-content-bx.style-1 .inner-content button.button,
  .dz-content-bx.style-1 .inner-content .woocommerce input.button,
  .woocommerce .dz-content-bx.style-1 .inner-content input.button,
  .dz-content-bx.style-1 .inner-content .woocommerce #place_order,
  .woocommerce .dz-content-bx.style-1 .inner-content #place_order,
  .dz-content-bx.style-1 .inner-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .dz-content-bx.style-1 .inner-content a.checkout-button,
  .dz-content-bx.style-1 .inner-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .dz-content-bx.style-1 .inner-content .button,
  .dz-content-bx.style-1 .inner-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .dz-content-bx.style-1 .inner-content input,
  .dz-content-bx.style-1 .inner-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .dz-content-bx.style-1 .inner-content a,
  .dz-content-bx.style-1 .inner-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .dz-content-bx.style-1 .inner-content a,
  .dz-content-bx.style-1 .inner-content .wp-block-button__link {
    padding: 9px 18px;
    font-size: 14px;
  }
}

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li,
.list-check-circle li,
.list-check li,
.list-plus-circle li,
.list-arrow-left-circle li,
.list-arrow-right-circle li {
  position: relative;
  padding: 5px 5px 5px 30px;
  font-family: var(--font-family-base);
  font-size: 16px;
}

.list-circle li:before,
.list-angle-double li:before,
.list-square li:before,
.list-square-check li:before,
.list-check-circle li:before,
.list-check li:before,
.list-plus-circle li:before,
.list-arrow-left-circle li:before,
.list-arrow-right-circle li:before {
  position: absolute;
  left: 0;
  top: 8px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before,
.list-check-circle.primary li:before,
.list-check.primary li:before,
.list-plus-circle.primary li:before,
.list-arrow-left-circle.primary li:before,
.list-arrow-right-circle.primary li:before {
  color: var(--primary);
}

.list-circle.white li:before,
.list-angle-double.white li:before,
.list-square.white li:before,
.list-square-check.white li:before,
.list-check-circle.white li:before,
.list-check.white li:before,
.list-plus-circle.white li:before,
.list-arrow-left-circle.white li:before,
.list-arrow-right-circle.white li:before {
  color: #fff;
}

.list-circle.gray li:before,
.list-angle-double.gray li:before,
.list-square.gray li:before,
.list-square-check.gray li:before,
.list-check-circle.gray li:before,
.list-check.gray li:before,
.list-plus-circle.gray li:before,
.list-arrow-left-circle.gray li:before,
.list-arrow-right-circle.gray li:before {
  color: #cbcbcb;
}

ul.list-circle li {
  padding: 5px 5px 5px 22px;
}

ul.list-circle li:before {
  content: "\f111";
  font-size: 6px;
  top: 15px;
}

ul.list-angle-double li:before {
  content: "\f101";
  font-size: 18px;
}

ul.list-square li:before {
  content: "\f45c";
  top: 50%;
  transform: translateY(-50%);
}

ul.list-check li:before {
  content: "\f00c";
  font-size: 16px;
}

ul.list-check-circle li:before {
  content: "\f058";
  font-size: 18px;
  font-weight: 500;
  top: 5px;
}

ul.list-plus-circle li:before {
  content: "\f055";
  font-size: 18px;
  top: 5px;
}

ul.list-arrow-left-circle li:before,
ul.list-arrow-right-circle li:before {
  font-size: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  background-color: #888888;
  border-radius: 50px;
  text-align: center;
  line-height: 21px;
}

ul.list-arrow-right-circle li:before {
  content: "\f054";
  padding-left: 1px;
}

ul.list-arrow-left-circle li:before {
  content: "\f053";
  padding-right: 1px;
}

ul.grid-2 {
  display: flex;
  flex-wrap: wrap;
}

ul.grid-2 li {
  width: 50%;
}

@media only screen and (max-width: 575px) {
  ul.grid-2 li {
    width: 100%;
  }
}

.list-square-check li {
  padding: 10px 15px 10px 60px;
  min-height: 45px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 15px;
}

.list-square-check li:before {
  content: "\f00c";
  top: 50%;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: var(--rgba-primary-1);
  color: #000;
  text-align: center;
  padding: 0 0 0 0;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  transform: translateY(-50%);
}

.list-square-check li:hover:before {
  background-color: var(--primary);
  color: #fff;
}

.list-number-circle li {
  padding: 0px 15px 0px 70px;
  position: relative;
  margin-bottom: 30px;
}

.list-number-circle li:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  background: var(--primary);
  color: #fff;
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 600;
}

.list-number-circle li p {
  margin-bottom: 0;
}

.list-square-box li {
  padding: 0px 0 0px 25px;
  position: relative;
  margin-bottom: 30px;
}

.list-square-box li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  height: 12px;
  width: 12px;
  background: var(--primary);
}

.list-square-box li p {
  margin-bottom: 0;
  font-size: 17px;
}

.list-check-box li {
  padding: 20px 10px 20px 70px;
  position: relative;
  margin-bottom: 10px;
  color: #fff;
  background: var(--secondary);
  font-size: 24px;
  font-family: var(--font-family-title);
  border-radius: var(--border-radius-base);
  text-transform: uppercase;
  margin-right: 40px;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  font-weight: 500;
}

.list-check-box li:after {
  content: "\f058";
  font-size: 25px;
  font-weight: 500;
  position: absolute;
  left: 30px;
  color: #fff;
  font-family: "Font Awesome 5 Free";
  top: 50%;
  transform: translateY(-50%);
}

.list-check-box li:hover {
  background: var(--primary);
  margin-right: 0;
}

@media only screen and (max-width: 1200px) {
  .list-check-box li {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .list-check-box li {
    font-size: 18px;
    padding: 20px 10px 20px 55px;
  }

  .list-check-box li:after {
    left: 20px;
  }
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
}

.lg-outer {
  z-index: 1000000;
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-outer .lg-object {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.lg-backdrop {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  z-index: 999999;
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #444444;
}

.lg-next,
.lg-prev {
  background-color: var(--light);
  border-radius: 0;
  color: #999;
  padding: 10px 10px 10px;
}

.lg-next:hover,
.lg-prev:hover {
  background-color: var(--secondary);
  color: #fff;
}

.lg-outer .lg-thumb {
  width: auto !important;
}

.lg-outer .lg-thumb-item {
  border-radius: 0px;
  width: 70px !important;
  height: 70px !important;
  margin-right: 5px !important;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--secondary);
}

.lightimg {
  cursor: pointer;
}

.progress-bx {
  overflow: hidden;
}

.progress-bx .progress-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.progress-bx .progress-info .title {
  margin-bottom: 0;
}

.progress-bx .progress-info .progress-value {
  margin-bottom: 0;
}

.progress-bx .progress {
  overflow: unset;
}

.progress-bx .progress .progress-bar {
  position: relative;
  overflow: unset;
}

.scroltop {
  background: var(--secondary);
  bottom: 30px;
  color: #fff !important;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 30px;
  text-align: center;
  width: 50px;
  z-index: 999;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  border-radius: 50%;
}

.scroltop:hover i {
  -webkit-animation: toTopFromBottom 0.8s forwards;
  -moz-animation: toTopFromBottom 0.8s forwards;
  animation: toTopFromBottom 0.8s forwards;
}

@media only screen and (max-width: 575px) {
  .scroltop {
    width: 35px;
    height: 35px;
    line-height: 35px;
    right: 15px;
  }
}

.icon-up i {
  display: inline-block;
}

@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes toTopFromBottom {
  49% {
    -moz-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

.scroltop-progress2 {
  transition: all linear 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 3px;
  background-color: var(--primary);
}

.video-bx1 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  min-width: 100px;
  height: 100px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  z-index: 1;
  border-radius: 50%;
}

.video-bx1 .video-btn i {
  color: #fff;
  font-size: 30px;
}

.video-bx1.overlay:after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1480px) {
  .video-bx1 .video-btn {
    width: 90px;
    height: 90px;
  }

  .video-bx1 .video-btn svg {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .video-bx1 .video-btn {
    width: 70px;
    height: 70px;
  }

  .video-bx1 .video-btn svg {
    width: 18px;
    height: 25px;
  }
}

.video-bx2 {
  position: relative;
  min-width: 170px;
  width: 170px;
  height: 170px;
  padding: 130px 0;
  overflow: hidden;
  margin: auto;
}

.video-bx2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video-bx2 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: inline-block;
  height: 60px;
  width: 60px;
  min-width: 60px;
  line-height: 60px;
  font-size: 20px;
  background-color: #fff;
  color: var(--primary);
  z-index: 1;
}

.video-bx2 .video-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

.video-bx2 .video-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

@media only screen and (max-width: 991px) {
  .video-bx2 .video-btn {
    height: 50px;
    width: 50px;
    min-width: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .video-bx2 {
    height: 120px;
    width: 120px;
    min-width: 120px;
  }

  .video-bx2 .video-btn {
    height: 40px;
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.video-bx3 .video-btn {
  width: 50px;
  height: 50px;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 50px;
}

.video-bx3 .video-btn i {
  color: #fff;
}

@keyframes animationSignal1 {

  /*Video Popup*/
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }

  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal1 {

  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }

  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes animationSignal2 {

  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }

  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal2 {

  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }

  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

.video-wrapper {
  display: flex;
  align-items: center;
}

.video-bx2 svg {
  margin-right: 5px;
}

.video-bx2 .text {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.btn-video {
  padding: 15px 20px;
  background-color: var(--secondary);
  color: #fff;
}

.swiper-button-next,
.swiper-button-prev {
  background-image: none !important;
}

.btn-next,
.btn-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary);
  background: var(--light);
  font-size: 18px;
  letter-spacing: 2px;
  z-index: 1;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-next svg path,
.btn-prev svg path {
  stroke: var(--secondary);
}

.btn-next:hover,
.btn-prev:hover {
  background: var(--secondary);
}

.btn-next:hover svg path,
.btn-prev:hover svg path {
  stroke: #ffffff;
}

.btn-next:hover i,
.btn-prev:hover i {
  color: #ffffff;
}

.btn-next:after,
.btn-prev:after {
  content: none;
}

.btn-next i,
.btn-prev i {
  font-size: 24px;
}

@media only screen and (max-width: 575px) {

  .btn-next,
  .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
}

.swiper-btn-center-lr {
  position: relative;
}

.swiper-btn-center-lr.portfolio-project .btn-prev {
  left: 100px;
}

.swiper-btn-center-lr.portfolio-project .btn-next {
  right: 100px;
}

@media only screen and (max-width: 1199px) {
  .swiper-btn-center-lr.portfolio-project .btn-prev {
    left: 50px;
  }

  .swiper-btn-center-lr.portfolio-project .btn-next {
    right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .swiper-btn-center-lr.portfolio-project .btn-prev {
    left: 20px;
  }

  .swiper-btn-center-lr.portfolio-project .btn-next {
    right: 20px;
  }
}

.swiper-btn-center-lr .btn-next,
.swiper-btn-center-lr .btn-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-btn-center-lr .btn-prev {
  left: -100px;
}

.swiper-btn-center-lr .btn-next {
  right: -100px;
}

@media only screen and (max-width: 1500px) {
  .swiper-btn-center-lr .btn-prev {
    left: -80px;
  }

  .swiper-btn-center-lr .btn-next {
    right: -80px;
  }
}

@media only screen and (max-width: 1480px) {
  .swiper-btn-center-lr .btn-prev {
    left: -80px;
  }

  .swiper-btn-center-lr .btn-next {
    right: -80px;
  }
}

@media only screen and (max-width: 1280px) {
  .swiper-btn-center-lr .btn-prev {
    left: -30px;
  }

  .swiper-btn-center-lr .btn-next {
    right: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .swiper-btn-center-lr .btn-prev {
    left: -25px;
  }

  .swiper-btn-center-lr .btn-next {
    right: -25px;
  }
}

@media only screen and (max-width: 575px) {

  .swiper-btn-center-lr .btn-prev,
  .swiper-btn-center-lr .btn-next {
    display: none;
  }
}

.portfolio-gallery.swiper {
  position: sticky;
  top: 0;
}

.portfolio-gallery .swiper-slide .dz-media img {
  height: 100vh;
  object-fit: cover;
}

.portfolio-gallery .swiper-slide .portfolio-box.style-2 .dz-content {
  height: auto;
  padding: 40px;
}

.portfolio-gallery .swiper-slide .portfolio-box.style-2 .dz-content .product-tag {
  margin-bottom: 10px;
}

.portfolio-gallery .swiper-slide .portfolio-box.style-2 .dz-content .title {
  font-size: 35px;
  width: 80%;
}

@media only screen and (max-width: 991px) {
  .portfolio-gallery .swiper-slide .portfolio-box.style-2 .dz-content .title {
    font-size: 24px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-gallery .swiper-slide .portfolio-box.style-2 .dz-content .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-gallery .swiper-slide .portfolio-box.style-2 .dz-content {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-gallery .swiper-slide .dz-media img {
    height: 70vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-gallery .swiper-slide .dz-media img {
    height: 50vh;
    object-fit: cover;
  }
}

.portfolio-thumb.swiper {
  width: 100%;
  height: auto;
  overflow: unset;
}

.portfolio-thumb .swiper-wrapper {
  height: unset;
  flex-wrap: wrap;
  transform: unset !important;
  width: 100% !important;
  display: flex;
}

.portfolio-thumb .swiper-wrapper .swiper-slide {
  width: 50% !important;
  height: auto;
  padding: 20px;
}

@media only screen and (max-width: 1199px) {
  .portfolio-thumb .swiper-wrapper .swiper-slide {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-thumb .swiper-wrapper .swiper-slide {
    width: 50% !important;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-thumb .swiper-wrapper .swiper-slide {
    width: 100% !important;
  }
}

.portfolio-thumb .swiper-wrapper .swiper-slide img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .portfolio-thumb .swiper-wrapper .swiper-slide {
    padding: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-thumb .swiper-wrapper {
    margin: auto;
  }
}

.portfolio-gallery3 .swiper-wrapper .swiper-slide img {
  height: calc(100vh - 170px) !important;
  min-height: 600px;
  object-fit: cover;
}

@media only screen and (max-width: 1199px) {
  .portfolio-gallery3 .swiper-wrapper .swiper-slide img {
    height: calc(100vh - 200px) !important;
    min-height: auto;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-gallery3 .swiper-wrapper .swiper-slide img {
    height: 100vh;
  }
}

.portfolio-gallery3 .swiper-wrapper .swiper-slide .dz-content {
  padding: 40px;
}

.portfolio-gallery3 .swiper-wrapper .swiper-slide .dz-content .title {
  font-size: 28px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .portfolio-gallery3 .swiper-wrapper .swiper-slide .dz-content .title {
    font-size: 24px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-gallery3 .swiper-wrapper .swiper-slide .dz-content .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-gallery3 .swiper-wrapper .swiper-slide .dz-content {
    padding: 20px;
  }
}

.portfolio-gallery2 .swiper-wrapper .swiper-slide {
  width: 960px;
}

@media only screen and (max-width: 1199px) {
  .portfolio-gallery2 .swiper-wrapper .swiper-slide {
    width: 80%;
  }
}

.portfolio-gallery2 .swiper-wrapper .swiper-slide img {
  height: 80vh !important;
  min-height: 600px;
  object-fit: cover;
}

@media only screen and (max-width: 1199px) {
  .portfolio-gallery2 .swiper-wrapper .swiper-slide img {
    height: 75vh !important;
    min-height: auto;
  }
}

.portfolio-gallery2 .swiper-wrapper .swiper-slide .dz-content {
  padding: 40px;
}

.portfolio-gallery2 .swiper-wrapper .swiper-slide .dz-content .title {
  font-size: 35px;
  width: 75%;
}

@media only screen and (max-width: 991px) {
  .portfolio-gallery2 .swiper-wrapper .swiper-slide .dz-content .title {
    font-size: 24px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-gallery2 .swiper-wrapper .swiper-slide .dz-content .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-gallery2 .swiper-wrapper .swiper-slide .dz-content {
    padding: 20px;
  }
}

.project-carousel .swiper-wrapper .swiper-slide img {
  height: 50vh;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .project-carousel .swiper-wrapper .swiper-slide img {
    height: 30vh;
  }
}

.swiper-visible {
  overflow: unset;
}

.swiper-visible .swiper-slide {
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.swiper-visible .swiper-slide-active,
.swiper-visible:not(.swiper-single) .swiper-slide-next,
.swiper-visible:not(.swiper-single) .swiper-slide-visible:not(.swiper-slide-duplicate-prev) {
  opacity: 1;
}

.swiper-visible.swiper-item-4 .swiper-slide-next+.swiper-slide,
.swiper-visible.swiper-item-4 .swiper-slide-next+.swiper-slide+.swiper-slide {
  opacity: 1;
}

.widget {
  margin-bottom: 45px;
}

@media only screen and (max-width: 991px) {
  .widget {
    margin-bottom: 40px;
  }
}

.widget:last-child {
  margin-bottom: 0;
}

.widget-title {
  position: relative;
  margin-bottom: 25px;
  font-weight: 500;
}

.widget-title .title {
  margin-bottom: 0;
}

.widget_post ul li {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.widget_post ul li:last-child {
  padding-bottom: 0;
}

.widget_post ul .dz-media {
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 0;
  margin-right: 15px;
}

.widget_post ul .dz-media img {
  border-radius: 10px;
}

.widget_post ul .dz-content .name {
  margin-bottom: 0;
  font-weight: 600;
}

.widget_post ul .dz-content .time {
  font-size: 13px;
  font-weight: 400;
}

.search-bx .form-control,
.search-bx .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .search-bx input[type=text],
.search-bx .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .search-bx input[type=email],
.search-bx .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .search-bx input[type=password],
.search-bx .login input[type=text],
.login .search-bx input[type=text],
.search-bx .login input[type=password],
.login .search-bx input[type=password],
.search-bx .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .search-bx input[type=text],
.search-bx .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .search-bx input[type=text],
.search-bx .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .search-bx input[type=email],
.search-bx .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .search-bx input[type=tel],
.search-bx .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .search-bx .select2-choice,
.search-bx .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .search-bx textarea,
.search-bx .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .search-bx input.input-text,
.search-bx .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .search-bx .select2-selection,
.search-bx .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .search-bx .select2-choice,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx textarea,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx select,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx input,
.search-bx .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .search-bx input,
.search-bx .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .search-bx .input-text,
.search-bx .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .search-bx input[type=text],
.search-bx .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .search-bx textarea,
.search-bx .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .search-bx input[type=email] {
  height: 60px;
  border: 1px solid var(--secondary);
  border-radius: 10px !important;
  color: var(--title);
}

.search-bx .form-control:placeholder,
.search-bx .woocommerce-EditAccountForm.edit-account input[type=text]:placeholder,
.woocommerce-EditAccountForm.edit-account .search-bx input[type=text]:placeholder,
.search-bx .woocommerce-EditAccountForm.edit-account input[type=email]:placeholder,
.woocommerce-EditAccountForm.edit-account .search-bx input[type=email]:placeholder,
.search-bx .woocommerce-EditAccountForm.edit-account input[type=password]:placeholder,
.woocommerce-EditAccountForm.edit-account .search-bx input[type=password]:placeholder,
.search-bx .login input[type=text]:placeholder,
.login .search-bx input[type=text]:placeholder,
.search-bx .login input[type=password]:placeholder,
.login .search-bx input[type=password]:placeholder,
.search-bx .woocommerce-ResetPassword.lost_reset_password input[type=text]:placeholder,
.woocommerce-ResetPassword.lost_reset_password .search-bx input[type=text]:placeholder,
.search-bx .checkout.woocommerce-checkout input[type=text]:placeholder,
.checkout.woocommerce-checkout .search-bx input[type=text]:placeholder,
.search-bx .checkout.woocommerce-checkout input[type=email]:placeholder,
.checkout.woocommerce-checkout .search-bx input[type=email]:placeholder,
.search-bx .checkout.woocommerce-checkout input[type=tel]:placeholder,
.checkout.woocommerce-checkout .search-bx input[type=tel]:placeholder,
.search-bx .checkout.woocommerce-checkout .select2-choice:placeholder,
.checkout.woocommerce-checkout .search-bx .select2-choice:placeholder,
.search-bx .checkout.woocommerce-checkout textarea:placeholder,
.checkout.woocommerce-checkout .search-bx textarea:placeholder,
.search-bx .woocommerce form .form-row input.input-text:placeholder,
.woocommerce form .form-row .search-bx input.input-text:placeholder,
.search-bx .woocommerce form .form-row .select2-selection:placeholder,
.woocommerce form .form-row .search-bx .select2-selection:placeholder,
.search-bx .woocommerce form .form-row .select2-choice:placeholder,
.woocommerce form .form-row .search-bx .select2-choice:placeholder,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row textarea:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx textarea:placeholder,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row select:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx select:placeholder,
.search-bx .woocommerce .woocommerce-checkout.checkout form .form-row input:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-bx input:placeholder,
.search-bx .woocommerce-cart-form .table tr .quantity input:placeholder,
.woocommerce-cart-form .table tr .quantity .search-bx input:placeholder,
.search-bx .woocommerce-cart-form .table tr td.actions .coupon .input-text:placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .search-bx .input-text:placeholder,
.search-bx .woocommerce #review_form #respond input[type=text]:placeholder,
.woocommerce #review_form #respond .search-bx input[type=text]:placeholder,
.search-bx .woocommerce #review_form #respond textarea:placeholder,
.woocommerce #review_form #respond .search-bx textarea:placeholder,
.search-bx .woocommerce #review_form #respond input[type=email]:placeholder,
.woocommerce #review_form #respond .search-bx input[type=email]:placeholder {
  color: var(--title);
}

.search-bx .input-group-prepend {
  border-right: 0;
  top: 50%;
}

.search-bx .input-group-prepend .input-group-text i {
  color: #828282;
}

.search-bx button {
  position: absolute !important;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  padding: 0px;
  z-index: 99 !important;
  width: 48px;
  border: 0;
}

.search-bx button i {
  color: var(--primary);
  font-size: 24px;
}

.search-bx button svg path {
  fill: var(--primary);
}

.search-bx.style-1 {
  padding: 30px;
  background-color: #000;
  border-radius: 0.375rem;
}

.search-bx.style-1 .input-group {
  margin-bottom: 0;
}

.search-bx.style-1 button {
  border-radius: 0 0.375rem 0.375rem 0;
  padding: 0px 10px;
}

.download-file {
  background-image: var(--gradient);
  color: #ffffff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}

.download-file .title {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.download-file ul li a {
  background-color: #ffffff;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}

.download-file ul li a i:after,
.download-file ul li a i:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}

.download-file ul li a i:before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}

.download-file ul li a .text {
  display: inline;
  position: relative;
}

.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 30px;
  border-radius: 6px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.widget_contact:after {
  background: var(--gradient-sec);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  z-index: -1;
  background-size: 200%;
}

.widget_contact img {
  margin-bottom: 20px;
}

.widget_contact h4,
.widget_contact .h4 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
}

.widget_contact .phone-number {
  position: relative;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 11px;
}

.widget_contact .phone-number a {
  color: #fff;
}

.widget_contact .email {
  position: relative;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}

.widget_contact .email a {
  color: #fff;
}

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  color: #5E626F;
}

.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 1.125rem;
  margin-bottom: 0.8125rem;
  position: relative;
  padding: 0.5rem 0rem 0.5rem 0;
  font-size: 15px;
  margin-bottom: 0;
  line-height: 1.25rem;
  font-weight: 500;
  color: var(--title);
}

.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  text-transform: capitalize;
  color: var(--title);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  display: inline-block;
}

.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
  color: var(--primary);
}

.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}

.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.wp-block-latest-posts li li:before,
.wp-block-categories-list li li:before,
.wp-block-archives-list li li:before,
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu ul li li:before,
.widget_recent_entries ul li li:before,
.widget_services ul li li:before {
  top: 0.5rem;
  left: 0;
}

.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.625rem 0rem 0.625rem 1rem !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  margin-bottom: 2.5rem;
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}

.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}

.widget_recent_comments ul li a {
  font-weight: 500;
}

.widget_recent_comments ul li a:before {
  content: none;
}

.widget_recent_comments ul li:before {
  content: "\f27a";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0.625rem;
  font-size: 1.125rem;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}

.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}

.widget_calendar tr:nth-child(2n+2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: var(--primary);
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: var(--rgba-primary-9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem;
  border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}

footer:not(.style-4) .widget_calendar th,
footer:not(.style-4) .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer:not(.style-4) .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

footer:not(.style-4) .widget_calendar tr:nth-child(2n+2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--title);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
}

.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}

.wp-calendar-nav span a {
  color: #fff;
}

.textwidget img {
  border-radius: var(--border-radius-base);
}

.widget_archive select {
  width: 100%;
  padding: 12px 20px;
  border: 0.0625rem solid #CCC;
}

.wp-block-archives-dropdown select,
.wp-block-categories-dropdown select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: var(--border-radius-base);
  appearance: none;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #CCC;
}

.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}

.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

footer:not(.style-4) ul a.rsswidget,
footer:not(.style-4) ul cite {
  color: #fff;
}

footer:not(.style-4) .widget_rss ul li {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

.widget_gallery ul {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.3rem;
  margin-right: -0.3rem;
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 0.125rem;
}

.widget_gallery li img {
  display: inline-block;
  width: 100%;
}

.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}

.widget_gallery li:hover {
  opacity: 0.7;
}

.widget_gallery li a {
  display: inline-block;
}

.wp-block-tag-cloud,
.tagcloud {
  clear: both;
  display: table;
}

.wp-block-tag-cloud a,
.tagcloud a {
  position: relative;
  border: 1px solid var(--secondary);
  padding: 8px 15px;
  display: inline-block;
  margin: 0px 10px 10px 0px;
  font-size: 14px;
  color: var(--title);
  line-height: 1.4;
  border-radius: 10px;
  font-weight: 400;
}

.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-media {
  width: 120px;
  min-width: 120px;
  height: 100px;
  border-radius: 20px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-info {
  margin-left: 18px;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta {
  margin-bottom: 0;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta li {
  font-size: 13px;
  color: var(--title);
  font-weight: 500;
}

.recent-posts-entry .widget-post-bx .widget-post .title {
  font-weight: 500;
}

.service_menu_nav {
  background: #fff;
  padding: 30px 30px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

@media only screen and (max-width: 1199px) {
  .service_menu_nav {
    padding: 15px;
  }
}

.service_menu_nav ul li {
  padding: 0;
}

.service_menu_nav ul li a {
  background-color: #fff;
  display: block;
  border-radius: 6px;
  padding: 15px 25px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-size: 200%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .service_menu_nav ul li a {
    padding: 15px;
  }
}

.service_menu_nav ul li a:before {
  content: "\f061";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
  font-size: 18px;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1199px) {
  .service_menu_nav ul li a:before {
    font-size: 22px;
  }
}

.service_menu_nav ul li a:after {
  background: var(--secondary);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  content: "";
  background-size: 200%;
  opacity: 0;
  z-index: -1;
}

.service_menu_nav ul li:hover a,
.service_menu_nav ul li.current-menu-item a,
.service_menu_nav ul li.active a {
  padding-left: 20px;
  border-color: transparent;
  transform: translateX(0);
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  color: #ffffff;
}

.service_menu_nav ul li:hover a:before,
.service_menu_nav ul li.current-menu-item a:before,
.service_menu_nav ul li.active a:before {
  opacity: 1;
  right: 10px;
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {

  .service_menu_nav ul li:hover a:before,
  .service_menu_nav ul li.current-menu-item a:before,
  .service_menu_nav ul li.active a:before {
    font-size: 22px;
    right: 5px;
  }
}

.service_menu_nav ul li:hover a:after,
.service_menu_nav ul li.current-menu-item a:after,
.service_menu_nav ul li.active a:after {
  opacity: 1;
}

.dz-related-post {
  margin-bottom: 30px;
}

.author-box {
  padding: 50px 30px;
  text-align: center;
  margin-bottom: 60px;
  background: #FEEB9D;
  border-radius: 20px;
}

.author-box .author-profile-info .author-profile-pic {
  width: 200px;
  height: 200px;
  overflow: hidden;
  min-width: 200px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.author-box .author-profile-info .author-profile-pic img {
  width: 100%;
}

.author-box .author-profile-info .author-profile-content .title {
  font-size: 20px;
  margin-bottom: 0;
}

.author-box .author-profile-info .author-profile-content .sub-title {
  font-size: 13px;
  display: block;
  color: var(--primary);
  margin-bottom: 10px;
}

.author-box .author-profile-info .author-profile-content p {
  font-size: 14px;
  margin-bottom: 15px;
}

.author-box .author-profile-info .author-profile-content img {
  margin-bottom: 15px;
  width: 180px;
}

.author-box .author-profile-info .author-profile-content .social-icon {
  margin: 0 -2px;
}

.author-box .author-profile-info .author-profile-content .social-icon li {
  margin: 0 2px;
  display: inline-block;
}

.author-box .author-profile-info .author-profile-content .social-icon li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  background: var(--secondary);
  color: #fff;
  vertical-align: middle;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px;
}

.author-box .author-profile-info .author-profile-content .social-icon li a i {
  vertical-align: middle;
}

.author-box.style-1 {
  padding: 30px;
  text-align: left;
}

.author-box.style-1 .author-profile-info {
  display: flex;
  align-items: center;
}

.author-box.style-1 .author-profile-info .author-profile-pic {
  width: 150px;
  height: 150px;
  min-width: 150px;
  margin-bottom: 0;
  margin-left: unset;
  margin-right: 20px;
  border-radius: 20px;
}

.author-box.style-1 .author-profile-info .author-profile-content .title {
  margin-bottom: 8px;
}

@media only screen and (max-width: 575px) {
  .author-box.style-1 .author-profile-info {
    display: block;
    text-align: center;
  }

  .author-box.style-1 .author-profile-info .author-profile-pic {
    margin: auto;
    margin-bottom: 20px;
  }
}

.search-info {
  border-bottom: 1px solid rgba(94, 98, 111, 0.2);
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.search-info .title {
  display: flex;
}

.search-info .search-name {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-right: 15px;
  display: inline;
}

.search-info .search-result {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 0;
  display: inline;
}

.search-info .found-posts {
  color: var(--primary);
  font-weight: 500;
}

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.widget_profile .dz-media img {
  border-radius: var(--border-radius-base);
}

.widget_profile.style-1 .dz-media {
  height: 200px;
  min-width: 200px;
  width: 200px;
  border-radius: 50%;
}

.widget_profile.style-1 .dz-media img {
  height: 100%;
  object-fit: cover;
}

.widget_categories ul li {
  padding: 8px 0rem 8px 0 !important;
}

.widget_categories.style-1 .cat-item {
  padding: 10px 0 10px 26px !important;
  font-family: "Roboto";
}

.widget_categories.style-1 .cat-item:before {
  content: "\e829";
  color: var(--title);
  position: absolute;
  left: 0;
  font-family: "feather" !important;
  font-weight: 100;
  font-size: 18px;
  top: 8px;
}

.clients-box {
  border-radius: var(--border-radius-base);
  padding: 30px 30px;
  position: relative;
}

.clients-box:after {
  position: absolute;
  background-color: var(--primary);
  content: "";
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: calc(100% - 60px);
  z-index: -1;
  border-radius: inherit;
}

.clients-box .clients-logo {
  padding: 0 25px;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: all 0.5s;
  position: relative;
}

.clients-box .clients-logo img {
  margin: auto;
  transition: all 0.5s;
}

.clients-box .clients-logo .logo-hover {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 25px;
}

.clients-box .clients-logo:hover .logo-main {
  opacity: 0;
}

.clients-box .clients-logo:hover .logo-hover {
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .clients-box {
    padding: 0;
  }
}

.pagination.style-1 li {
  margin: 0;
}

.pagination.style-1 li .page-link {
  padding: 10px 15px;
  color: var(--title);
  font-weight: 400;
  display: inline-flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary);
  border-radius: 20px;
}

.pagination.style-1 li .page-link img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: var(--border-radius-base);
}

.pagination.style-1 li .page-link i {
  font-size: 40px;
}

.pagination.style-1 li .page-link.prev img {
  margin-right: 15px;
}

.pagination.style-1 li .page-link.prev i {
  margin-right: 5px;
}

.pagination.style-1 li .page-link.next img {
  margin-left: 15px;
}

.pagination.style-1 li .page-link.next i {
  margin-left: 5px;
}

@media only screen and (max-width: 575px) {
  .pagination.style-1 li .page-link {
    padding: 10px 12px;
  }

  .pagination.style-1 li .page-link img {
    width: 60px;
    height: 60px;
  }
}

.btn-social {
  display: inline-block;
  padding: 12px 15px;
  text-align: center;
  border-radius: var(--border-radius-base);
}

.btn-social i {
  margin-right: 8px;
}

.btn-social.facebook {
  color: #fff;
  background: #3b5998;
}

.btn-social.instagram {
  color: #fff;
  background: #c32aa3;
}

.btn-social.youtube {
  color: #fff;
  background: #e52d27;
}

.btn-social.twitter {
  color: #fff;
  background: #07acde;
}

.widget-row .grid-item {
  width: 33.33%;
}

@media only screen and (max-width: 991px) {
  .widget-row .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .widget-row .grid-item {
    width: 100%;
  }
}

.side-bar h1,
.side-bar .h1 {
  font-size: 2rem;
}

.side-bar h2,
.side-bar .h2 {
  font-size: 1.625rem;
}

.side-bar h3,
.side-bar .h3 {
  font-size: 1.5rem;
}

.side-bar h4,
.side-bar .h4 {
  font-size: 1.25rem;
}

.side-bar h5,
.side-bar .h5 {
  font-size: 1.125rem;
}

.side-bar h6,
.side-bar .h6 {
  font-size: 1rem;
}

.sidebar-widget .wp-block-woocommerce-attribute-filter>h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter>.h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter>h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter>.h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter>h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter>.h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter>h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter>.h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter>h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter>.h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter>h6,
.sidebar-widget .wp-block-woocommerce-attribute-filter>.h6,
.sidebar-widget .wp-block-group__inner-container>h1,
.sidebar-widget .wp-block-group__inner-container>.h1,
.sidebar-widget .wp-block-group__inner-container>h2,
.sidebar-widget .wp-block-group__inner-container>.h2,
.sidebar-widget .wp-block-group__inner-container>h3,
.sidebar-widget .wp-block-group__inner-container>.h3,
.sidebar-widget .wp-block-group__inner-container>h4,
.sidebar-widget .wp-block-group__inner-container>.h4,
.sidebar-widget .wp-block-group__inner-container>h5,
.sidebar-widget .wp-block-group__inner-container>.h5,
.sidebar-widget .wp-block-group__inner-container>h6,
.sidebar-widget .wp-block-group__inner-container>.h6,
.shop-widget .wp-block-woocommerce-attribute-filter>h1,
.shop-widget .wp-block-woocommerce-attribute-filter>.h1,
.shop-widget .wp-block-woocommerce-attribute-filter>h2,
.shop-widget .wp-block-woocommerce-attribute-filter>.h2,
.shop-widget .wp-block-woocommerce-attribute-filter>h3,
.shop-widget .wp-block-woocommerce-attribute-filter>.h3,
.shop-widget .wp-block-woocommerce-attribute-filter>h4,
.shop-widget .wp-block-woocommerce-attribute-filter>.h4,
.shop-widget .wp-block-woocommerce-attribute-filter>h5,
.shop-widget .wp-block-woocommerce-attribute-filter>.h5,
.shop-widget .wp-block-woocommerce-attribute-filter>h6,
.shop-widget .wp-block-woocommerce-attribute-filter>.h6,
.shop-widget .wp-block-group__inner-container>h1,
.shop-widget .wp-block-group__inner-container>.h1,
.shop-widget .wp-block-group__inner-container>h2,
.shop-widget .wp-block-group__inner-container>.h2,
.shop-widget .wp-block-group__inner-container>h3,
.shop-widget .wp-block-group__inner-container>.h3,
.shop-widget .wp-block-group__inner-container>h4,
.shop-widget .wp-block-group__inner-container>.h4,
.shop-widget .wp-block-group__inner-container>h5,
.shop-widget .wp-block-group__inner-container>.h5,
.shop-widget .wp-block-group__inner-container>h6,
.shop-widget .wp-block-group__inner-container>.h6 {
  position: relative;
  padding-left: 15px;
  margin-bottom: 30px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {

  .sidebar-widget .wp-block-woocommerce-attribute-filter>h1,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>.h1,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>h2,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>.h2,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>h3,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>.h3,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>h4,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>.h4,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>h5,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>.h5,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>h6,
  .sidebar-widget .wp-block-woocommerce-attribute-filter>.h6,
  .sidebar-widget .wp-block-group__inner-container>h1,
  .sidebar-widget .wp-block-group__inner-container>.h1,
  .sidebar-widget .wp-block-group__inner-container>h2,
  .sidebar-widget .wp-block-group__inner-container>.h2,
  .sidebar-widget .wp-block-group__inner-container>h3,
  .sidebar-widget .wp-block-group__inner-container>.h3,
  .sidebar-widget .wp-block-group__inner-container>h4,
  .sidebar-widget .wp-block-group__inner-container>.h4,
  .sidebar-widget .wp-block-group__inner-container>h5,
  .sidebar-widget .wp-block-group__inner-container>.h5,
  .sidebar-widget .wp-block-group__inner-container>h6,
  .sidebar-widget .wp-block-group__inner-container>.h6,
  .shop-widget .wp-block-woocommerce-attribute-filter>h1,
  .shop-widget .wp-block-woocommerce-attribute-filter>.h1,
  .shop-widget .wp-block-woocommerce-attribute-filter>h2,
  .shop-widget .wp-block-woocommerce-attribute-filter>.h2,
  .shop-widget .wp-block-woocommerce-attribute-filter>h3,
  .shop-widget .wp-block-woocommerce-attribute-filter>.h3,
  .shop-widget .wp-block-woocommerce-attribute-filter>h4,
  .shop-widget .wp-block-woocommerce-attribute-filter>.h4,
  .shop-widget .wp-block-woocommerce-attribute-filter>h5,
  .shop-widget .wp-block-woocommerce-attribute-filter>.h5,
  .shop-widget .wp-block-woocommerce-attribute-filter>h6,
  .shop-widget .wp-block-woocommerce-attribute-filter>.h6,
  .shop-widget .wp-block-group__inner-container>h1,
  .shop-widget .wp-block-group__inner-container>.h1,
  .shop-widget .wp-block-group__inner-container>h2,
  .shop-widget .wp-block-group__inner-container>.h2,
  .shop-widget .wp-block-group__inner-container>h3,
  .shop-widget .wp-block-group__inner-container>.h3,
  .shop-widget .wp-block-group__inner-container>h4,
  .shop-widget .wp-block-group__inner-container>.h4,
  .shop-widget .wp-block-group__inner-container>h5,
  .shop-widget .wp-block-group__inner-container>.h5,
  .shop-widget .wp-block-group__inner-container>h6,
  .shop-widget .wp-block-group__inner-container>.h6 {
    margin-bottom: 15px;
  }
}

.form-select,
select {
  display: block;
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #777777;
  border: 1px solid #ced4da;
  border-radius: var(--border-radius-base);
}

.comments-title,
.blog-title,
.comment-reply-title,
.wp-block-search .wp-block-search__label {
  position: relative;
  margin-bottom: 25px;
  text-transform: capitalize;
  color: var(--title);
  font-size: 1.125rem;
  font-family: var(--font-family-title);
  font-weight: 600;
  line-height: 1.4;
  display: block;
}

.wc-block-components-price-slider__range-input-progress {
  background: var(--rgba-primary-1);
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[data-selected] {
  background-color: var(--primary);
}

.widget_getintouch-pro-details {
  margin-bottom: 0;
}

.widget_getintouch-pro-details .pro-details {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.widget_getintouch-pro-details .pro-details .icon-box {
  background-color: #f5f5f5;
  height: 53px;
  min-width: 53px;
  width: 53px;
  color: #000;
  line-height: 53px;
  text-align: center;
  margin-right: 20px;
  font-size: 20px;
}

.widget_getintouch-pro-details .pro-details .icon-box:before {
  content: none;
}

.widget_getintouch-pro-details .pro-details .icon-box i {
  font-family: "feather" !important;
  font-style: normal;
}

.widget_getintouch-pro-details .pro-details .icon-content .dz-title {
  margin-bottom: 5px;
}

.widget_getintouch-pro-details .pro-details .icon-content p {
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .widget_getintouch-pro-details .pro-details .icon-box {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .pro-details-col-5 .col {
    width: 25%;
    flex-basis: auto;
  }
}

@media only screen and (max-width: 991px) {
  .pro-details-col-5 .col {
    width: 33.33%;
  }
}

@media only screen and (max-width: 767px) {
  .pro-details-col-5 .col {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .pro-details-col-5 .col {
    width: 100%;
  }
}

.quick-view-modal .modal-dialog {
  max-width: 1070px;
}

.quick-view-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #ffffff;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .quick-view-modal .modal-content {
    padding: 50px 30px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .quick-view-modal .modal-content {
    padding: 50px 15px 20px;
  }
}

.quick-view-modal .modal-content .modal-body {
  padding: 0;
}

.quick-view-modal .dz-product-detail .dz-media {
  height: auto;
}

.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn {
  margin-bottom: 10px;
  display: flex;
}

.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .btn,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #respond input#submit,
.woocommerce #respond .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn input#submit,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce a.button,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a.button,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce button.button,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn button.button,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce input.button,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn input.button,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #place_order,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn #place_order,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a.checkout-button,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .button,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn input,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .wp-block-button__link {
  margin-right: 10px;
}

.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .btn.btn-outline-secondary:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #respond input.btn-outline-secondary#submit:hover svg>path,
.woocommerce #respond .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn input.btn-outline-secondary#submit:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce a.btn-outline-secondary.button:hover svg>path,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a.btn-outline-secondary.button:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce button.btn-outline-secondary.button:hover svg>path,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn button.btn-outline-secondary.button:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce input.btn-outline-secondary.button:hover svg>path,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn input.btn-outline-secondary.button:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce .btn-outline-secondary#place_order:hover svg>path,
.woocommerce .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .btn-outline-secondary#place_order:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce-cart .wc-proceed-to-checkout a.btn-outline-secondary.checkout-button:hover svg>path,
.woocommerce-cart .wc-proceed-to-checkout .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a.btn-outline-secondary.checkout-button:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce-cart-form .table tr .btn-outline-secondary.button:hover svg>path,
.woocommerce-cart-form .table tr .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .btn-outline-secondary.button:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #review_form #respond .form-submit input.btn-outline-secondary:hover svg>path,
.woocommerce #review_form #respond .form-submit .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn input.btn-outline-secondary:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce .widget_shopping_cart .buttons a.btn-outline-secondary:hover svg>path,
.woocommerce .widget_shopping_cart .buttons .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a.btn-outline-secondary:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .woocommerce.widget_shopping_cart .buttons a.btn-outline-secondary:hover svg>path,
.woocommerce.widget_shopping_cart .buttons .quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn a.btn-outline-secondary:hover svg>path,
.quick-view-modal .dz-product-detail.style-2 .dz-content .cart-btn .btn-outline-secondary.wp-block-button__link:hover svg>path {
  fill: #fff;
}

.quick-view-modal .dz-product-detail.style-2 .dz-content .title {
  font-size: 28px;
  font-weight: 700;
}

@media only screen and (max-width: 575px) {
  .quick-view-modal .dz-product-detail.style-2 .dz-content .title {
    font-size: 24px;
  }
}

.quick-view-modal .dz-product-detail.style-2 .meta-content {
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 575px) {
  .quick-view-modal .dz-product-detail .meta-content {
    justify-content: space-between;
  }

  .quick-view-modal .dz-product-detail .btn-quantity .bootstrap-touchspin {
    width: 120px;
    min-width: 120px;
    height: 30px;
  }

  .quick-view-modal .dz-product-detail .btn-quantity input {
    width: 40px !important;
    height: 30px !important;
  }

  .quick-view-modal .dz-product-detail .btn-quantity .btn,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce #respond input#submit,
  .woocommerce #respond .quick-view-modal .dz-product-detail .btn-quantity input#submit,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce a.button,
  .woocommerce .quick-view-modal .dz-product-detail .btn-quantity a.button,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce button.button,
  .woocommerce .quick-view-modal .dz-product-detail .btn-quantity button.button,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce input.button,
  .woocommerce .quick-view-modal .dz-product-detail .btn-quantity input.button,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce #place_order,
  .woocommerce .quick-view-modal .dz-product-detail .btn-quantity #place_order,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .quick-view-modal .dz-product-detail .btn-quantity a.checkout-button,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .quick-view-modal .dz-product-detail .btn-quantity .button,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .quick-view-modal .dz-product-detail .btn-quantity input,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .quick-view-modal .dz-product-detail .btn-quantity a,
  .quick-view-modal .dz-product-detail .btn-quantity .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .quick-view-modal .dz-product-detail .btn-quantity a,
  .quick-view-modal .dz-product-detail .btn-quantity .wp-block-button__link {
    min-width: 30px;
    font-size: 14px;
  }
}

.quick-view-modal .btn-close {
  margin: 0px;
  position: absolute;
  right: 8px;
  top: 8px;
  color: var(--secondary);
  opacity: 1;
  font-size: 24px;
  padding: 0px;
  height: 40px;
  z-index: 1;
  width: 40px;
  border: 0px;
  background: transparent;
}

.quick-view-modal .btn-close:focus {
  box-shadow: unset;
}

.inquiry-modal {
  background: rgba(0, 0, 0, 0.6);
}

.inquiry-modal .modal-dialog {
  max-width: 800px;
  display: flex;
  min-height: auto;
  background-color: #ffffff;
  border-radius: 30px;
}

@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-dialog {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .inquiry-modal .modal-dialog {
    max-width: 350px;
    margin: 0 auto;
  }
}

.inquiry-modal .form-label {
  font-size: 13px;
  font-weight: 500;
}

.inquiry-modal .form-group {
  margin-bottom: 20px;
}

.inquiry-modal .form-control,
.inquiry-modal .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .inquiry-modal input[type=text],
.inquiry-modal .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .inquiry-modal input[type=email],
.inquiry-modal .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .inquiry-modal input[type=password],
.inquiry-modal .login input[type=text],
.login .inquiry-modal input[type=text],
.inquiry-modal .login input[type=password],
.login .inquiry-modal input[type=password],
.inquiry-modal .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .inquiry-modal input[type=text],
.inquiry-modal .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .inquiry-modal input[type=text],
.inquiry-modal .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .inquiry-modal input[type=email],
.inquiry-modal .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .inquiry-modal input[type=tel],
.inquiry-modal .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .inquiry-modal .select2-choice,
.inquiry-modal .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .inquiry-modal textarea,
.inquiry-modal .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .inquiry-modal input.input-text,
.inquiry-modal .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .inquiry-modal .select2-selection,
.inquiry-modal .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .inquiry-modal .select2-choice,
.inquiry-modal .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .inquiry-modal textarea,
.inquiry-modal .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .inquiry-modal select,
.inquiry-modal .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .inquiry-modal input,
.inquiry-modal .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .inquiry-modal input,
.inquiry-modal .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .inquiry-modal .input-text,
.inquiry-modal .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .inquiry-modal input[type=text],
.inquiry-modal .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .inquiry-modal textarea,
.inquiry-modal .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .inquiry-modal input[type=email] {
  font-size: 15px;
}

.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%;
}

@media only screen and (max-width: 575px) {
  .inquiry-modal .inquiry-adv {
    display: none;
  }
}

.inquiry-modal .inquiry-adv img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 30px 0 0 30px;
}

.inquiry-modal .modal-content {
  border: 0;
  border-radius: 30px;
  background: #fff;
  padding: 40px 60px;
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content {
    padding: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .inquiry-modal .modal-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }
}

.inquiry-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin-bottom: 30px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content .modal-header {
    margin-bottom: 15px;
  }
}

.inquiry-modal .modal-content .modal-header .title-head {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.inquiry-modal .modal-content .modal-header .modal-title {
  font-size: 28px;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.3;
  font-family: var(--font-family-title);
  width: 100%;
}

.inquiry-modal .modal-content .modal-header .text {
  font-size: 16px;
  font-family: var(--font-family-title);
  font-weight: 400;
  margin-bottom: 0;
}

.inquiry-modal .modal-content .custom-checkbox {
  display: flex;
  align-items: center;
}

.inquiry-modal .modal-content .custom-checkbox .form-check-input {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 12px;
  background-color: #D9D9D9;
  cursor: pointer;
  border-color: #D9D9D9;
}

.inquiry-modal .modal-content .custom-checkbox .form-check-input:checked {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.inquiry-modal .modal-content .custom-checkbox .form-check-input:focus {
  box-shadow: unset;
}

.inquiry-modal .modal-content .custom-checkbox .form-check-label {
  font-size: 13px;
  cursor: pointer;
  font-weight: 400;
  font-family: var(--font-family-title);
  color: var(--secondary);
}

.inquiry-modal .modal-content .modal-body {
  padding: 0;
}

.inquiry-modal .btn-close {
  margin: 0px;
  position: absolute;
  right: 0;
  background-color: var(--secondary) !important;
  top: 0;
  color: rgb(255, 255, 255);
  font-weight: 100;
  text-shadow: none;
  opacity: 1;
  font-size: 24px;
  padding: 0px;
  height: 40px;
  line-height: 1;
  z-index: 1;
  width: 40px;
  border: 0px;
  border-radius: 0px;
  background: transparent;
}

.inquiry-modal .btn-close:focus {
  box-shadow: unset;
}

.dzmove1 {
  animation: dzMove1 1s linear infinite;
}

.dzheart {
  animation: dzHeart 1s linear infinite;
}

@keyframes dzMove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }

  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes dzHeart {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
  }
}

.nav-pills {
  border: 0;
  justify-content: space-between;
}

.nav-pills .nav-link {
  padding: 0;
  width: 100%;
}

.tab-content {
  margin-top: 0px;
}

.dz-tabs .nav-tabs {
  border: 0;
  border-bottom: 1px solid var(--border-color);
}

.dz-tabs .nav-tabs .nav-link {
  width: 100%;
  padding: 10px 20px;
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid transparent;
  color: var(--secondary);
  box-shadow: unset;
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 575px) {
  .dz-tabs .nav-tabs .nav-link {
    font-size: 14px;
  }
}

.dz-tabs .nav-tabs .nav-link .badge {
  color: var(--title);
  padding: 0 2px;
  border-radius: 20px;
  margin-left: 5px;
  width: 24px;
  height: 24px;
  line-height: 25px;
}

@media only screen and (max-width: 575px) {
  .dz-tabs .nav-tabs .nav-link .badge {
    width: 20px;
    height: 20px;
    line-height: 22px;
  }
}

.dz-tabs .nav-tabs .nav-link.active {
  border-color: var(--secondary);
  color: var(--secondary);
}

.dz-tabs .nav-tabs .nav-link.active .badge {
  background: var(--title);
  color: #fff;
}

.dz-tabs .nav-tabs.left {
  justify-content: left;
}

.dz-tabs .nav-tabs.center {
  justify-content: center;
}

.dz-tabs .nav-tabs.right {
  justify-content: right;
}

.dz-tabs .tab-content {
  padding-top: 50px;
}

@media only screen and (max-width: 991px) {
  .dz-tabs .tab-content {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-tabs .tab-content {
    padding-top: 20px;
  }
}

.dz-tabs.style-1 {
  margin-bottom: 40px;
}

.dz-tabs.style-1 .nav-tabs {
  border: none;
  display: flex;
  gap: 20px;
  margin-right: -20px;
}

.dz-tabs.style-1 .nav-tabs .nav-link {
  max-width: calc(33.33% - 20px);
  flex: 0 0 calc(33.33% - 20px);
  cursor: pointer;
  padding: 15px 30px;
  font-size: 20px;
  text-align: start;
  font-weight: 400;
  background: transparent;
  color: var(--secondary);
  border: 1px solid var(--secondary);
  font-family: var(--font-family-title);
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.dz-tabs.style-1 .nav-tabs .nav-link.active,
.dz-tabs.style-1 .nav-tabs .nav-link:hover,
.dz-tabs.style-1 .nav-tabs .nav-link:active,
.dz-tabs.style-1 .nav-tabs .nav-link:focus {
  border-color: var(--primary);
}

.dz-tabs.style-1 .nav-tabs .nav-link i,
.dz-tabs.style-1 .nav-tabs .nav-link svg {
  font-size: 24px;
  color: var(--secondary);
  margin-right: 10px;
}

.dz-tabs.style-1.tab-space {
  margin-bottom: 75px;
  margin-top: 35px;
}

@media only screen and (max-width: 1680px) {
  .dz-tabs.style-1 .nav-tabs .nav-link {
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 1480px) {
  .dz-tabs.style-1 .nav-tabs .nav-link {
    max-width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
  }
}

@media only screen and (max-width: 1480px) {
  .dz-tabs.style-1 .nav-tabs .nav-link {
    max-width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
  }
}

@media only screen and (max-width: 991px) {
  .dz-tabs.style-1.tab-space {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .dz-tabs.style-1 .nav-tabs .nav-link {
    max-width: calc(33.33% - 20px);
    flex: 0 0 calc(33.33% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .dz-tabs.style-1 .nav-tabs .nav-link {
    max-width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
  }
}

@media only screen and (max-width: 575px) {
  .dz-tabs.style-1 .nav-tabs {
    gap: 15px;
    margin-right: -15px;
  }

  .dz-tabs.style-1 .nav-tabs .nav-link {
    max-width: calc(33.33% - 15px);
    flex: 0 0 calc(33.33% - 15px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 12px 0;
    font-size: 16px;
    gap: 5px;
  }

  .dz-tabs.style-1 .nav-tabs .nav-link svg {
    margin-right: 0;
  }
}

.dz-tabs.style-2 .nav-pills {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  text-align: center;
  margin-bottom: 10px;
}

.dz-tabs.style-2 .nav-pills .nav-item {
  display: inline-block;
  flex: auto;
}

.dz-tabs.style-2 .nav-pills .nav-item .nav-link {
  background-color: #fff;
  color: #000;
  padding: 15px 25px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 10px;
  transition: 0.5s;
}

.dz-tabs.style-2 .nav-pills .nav-item .nav-link:hover,
.dz-tabs.style-2 .nav-pills .nav-item .nav-link.active {
  background-color: #000;
  color: #fff;
}

@media only screen and (min-width: 991px) {
  .dz-tabs.style-2.nav-full .nav-pills {
    display: block;
  }

  .dz-tabs.style-2.nav-full .nav-pills .nav-item {
    display: block;
    margin-bottom: 5px;
  }
}

.dz-social-icon ul {
  display: flex;
}

.dz-social-icon li {
  display: inline-block;
  margin-right: 5px;
}

.dz-social-icon li a {
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-social-icon li a i {
  color: var(--title);
}

.dz-social-icon.style-1 {
  display: flex;
}

.dz-social-icon.style-1 li {
  margin-right: 10px;
  color: #000;
}

.dz-social-icon.style-1 li a {
  color: var(--primary);
  background-color: rgba(255, 255, 255, 0.1);
  width: 44px;
  height: 44px;
  color: #fff;
  line-height: 44px;
  font-size: 20px;
  display: block;
}

.dz-social-icon.style-1 li a:hover {
  background-color: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 1191px) {
  .dz-social-icon.style-1 {
    justify-content: center;
  }
}

.dz-social-icon.style-2 li {
  margin-right: 10px;
  color: #000;
}

.dz-social-icon.style-2 li a {
  color: #fff;
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 20px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  display: block;
  background: var(--secondary);
}

.dz-social-icon.style-2 li a i {
  color: #fff;
}

.dz-social-icon.style-2 li a:hover {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
}

.dz-social-icon.style-2.white {
  color: #fff;
}

.dz-social-icon.style-2.white a {
  color: #fff;
  border: 1px solid rgb(255, 255, 255);
}

.dz-social-icon.style-2.white a i {
  color: #fff;
}

.dz-social-icon.style-2.white a:hover {
  background-color: #fff;
  border-color: #fff;
  color: var(--secondary);
}

.dz-social-icon.style-2.white a:hover i {
  color: var(--secondary);
}

@media only screen and (max-width: 1191px) {
  .dz-social-icon.style-2 {
    justify-content: center;
  }
}

.dz-social-icon.style-3 li {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: var(--border-radius-base);
}

.dz-social-icon.style-3 li:nth-child(1) {
  background-color: #3b5998;
}

.dz-social-icon.style-3 li:nth-child(2) {
  background-color: #1da1f2;
}

.dz-social-icon.style-3 li:nth-child(3) {
  background-color: #25d366;
}

.dz-social-icon.style-3 li:nth-child(4) {
  background-color: #db4439;
}

.dz-social-icon.style-3 li a {
  color: #ffffff;
  font-size: 16px;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .dz-social-icon.style-3 {
    display: none;
  }
}

@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-webkit-keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToRight {
  49% {
    -moz-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-webkit-keyframes leftToRight {
  49% {
    -webkit-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes leftToRight {
  49% {
    -moz-transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes leftToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }

  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }

  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }

  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement3 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }

  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }

  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement4 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }

  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }

  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes movedelement5 {
  0% {
    -webkit-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
  }

  25% {
    -webkit-transform: translate(10px, calc(-50% + 10px));
    transform: translate(10px, calc(-50% + 10px));
  }

  50% {
    -webkit-transform: translate(5px, calc(-50% + 5px));
    transform: translate(5px, calc(-50% + 5px));
  }

  75% {
    -webkit-transform: translate(10px, calc(-50% - 5px));
    transform: translate(10px, calc(-50% - 5px));
  }

  to {
    -webkit-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@-moz-keyframes spin2 {
  100% {
    -moz-transform: translate(-50%, -50%) rotate(45deg);
  }
}

@-webkit-keyframes spin2 {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes spin2 {
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.dz-divider {
  height: 1px;
  position: relative;
  margin: 30px 0;
}

.dz-divider.divider-2px {
  height: 2px;
}

.dz-divider.divider-3px {
  height: 2px;
}

.dz-divider.divider-4px {
  height: 2px;
}

.dz-divider i {
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dz-divider.icon-left {
  margin-left: 40px;
}

.dz-divider.icon-left i {
  left: -40px;
}

.dz-divider.icon-right {
  margin-right: 40px;
}

.dz-divider.icon-right i {
  right: -40px;
}

.dz-divider.icon-center i {
  left: 50%;
  margin-left: -5px;
}

.bg-gray-dark {
  background-color: #d3d3d3;
}

.range-slider.style-1 .noUi-horizontal {
  height: 4px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--secondary);
  background-color: var(--light);
  box-shadow: none;
  top: -8px;
  right: 0;
  cursor: pointer;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle:before,
.range-slider.style-1 .noUi-horizontal .noUi-handle:after {
  content: none;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip {
  display: none;
  border: 0px;
  background: var(--primary);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 13px;
  bottom: -35px;
  font-weight: 600;
  padding: 2px 5px;
}

.range-slider.style-1 .noUi-horizontal .noUi-handle .noUi-tooltip:after {
  content: "";
  position: absolute;
  background-color: inherit;
  width: 10px;
  height: 10px;
  top: 4px;
  margin-left: -4px;
  left: 50%;
  transform: rotate(45deg) translate(-50%, -50%);
}

.range-slider.style-1 .noUi-target {
  background: #D7D7D7;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

.range-slider.style-1 .noUi-target .noUi-connect {
  background: var(--secondary);
}

.range-slider.style-1 span {
  margin-right: 30px;
}

.range-slider.style-1 .example-val {
  font-size: 14px;
  color: var(--title);
}

.dropdown-menu {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  min-width: 200px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-menu .dropdown-item {
  padding: 5px 20px;
  font-size: 14px;
}

.dropdown-menu .dropdown-footer {
  padding: 10px 20px 10px;
}

.dropdown-menu .dropdown-header {
  padding: 10px 20px 10px;
}

.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--light);
  color: var(--primary);
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}

.content-box.style-1 {
  padding: 30px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.content-box.style-1 .dz-info {
  margin-bottom: 30px;
}

.content-box.style-1 .dz-info .title {
  line-height: 1.2;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
  margin-bottom: 20px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.content-box.style-1 .dz-bottom {
  position: relative;
  z-index: 1;
}

.content-box.style-1 .dz-bottom:after {
  content: "";
  position: absolute;
  right: 0;
  background: #e9e9e9;
  height: 1px;
  width: 60%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.content-box.style-1:hover {
  background-color: var(--primary);
  color: #fff;
}

.content-box.style-1:hover .dz-info .title {
  color: #fff;
}

.content-box.style-1:hover .dz-info .title a {
  color: inherit;
}

.content-box.style-1:hover .dz-bottom a {
  color: #fff;
}

.author-bx-detail {
  display: flex;
  align-items: center;
}

.author-bx-detail .author-bx-pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  border: 2px solid #fff;
}

.author-bx-detail .author-bx-pic img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.author-bx-detail .name {
  font-size: 22px;
  color: var(--title);
  margin-bottom: 0;
}

.author-bx-detail .position {
  font-size: 16px;
  font-weight: 500;
  color: var(--title);
}

@media only screen and (max-width: 575px) {
  .author-bx-detail .author-bx-pic {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .author-bx-detail .name {
    font-size: 16px;
  }

  .author-bx-detail .position {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  #myContainer {
    height: 100vh;
    overflow-y: auto;
  }
}

.swiper-image {
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.swiper-image.swiper-bg {
  background-repeat: no-repeat !important;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .swiper-image.swiper-bg {
    height: 500px;
  }
}

.swiper-image.swiper-bg.portfolio-box.style-2 .dz-content {
  padding: 40px;
  height: auto;
}

.swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .product-tag {
  margin-bottom: 5px;
}

.swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .title {
  font-size: 35px;
  width: 75%;
}

@media only screen and (max-width: 991px) {
  .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .title {
    font-size: 24px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content {
    padding: 20px;
  }
}

.swiper-image .swiper-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  padding: 50px;
  position: relative;
  max-width: 600px;
  overflow: unset;
  margin: auto;
}

@media only screen and (max-width: 1199px) {
  .swiper-image .swiper-content {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 991px) {
  .swiper-image .swiper-content {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .swiper-image .swiper-content {
    padding: 20px;
  }

  .swiper-image .swiper-content img {
    object-fit: cover;
  }

  .swiper-image .swiper-content .portfolio-box {
    margin-left: auto;
    margin-right: auto;
  }
}

#multiscroll-nav ul li {
  margin: 15px 0;
}

#multiscroll-nav ul li a span {
  position: relative;
  display: block;
  top: unset;
  left: unset;
  width: 6px;
  height: 6px;
  background-color: rgba(36, 38, 48, 0.3);
  border: 0;
}

#multiscroll-nav ul li a span:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  border: 1px solid transparent;
  background: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#multiscroll-nav ul li a.active span:after {
  border-color: rgb(36, 38, 48);
}

#home-slider {
  height: calc(100vh - 80px);
  width: 100vw;
  position: relative;
}

@media only screen and (max-width: 767px) {
  #home-slider {
    height: 100vh;
  }
}

.split-area .swiper-pagination-two.style-1 {
  left: auto !important;
  right: 20px !important;
  z-index: 999;
  position: fixed;
  opacity: 1;
  width: auto;
  top: 50% !important;
  transform: translateY(-50%) !important;
  display: flex;
  bottom: auto !important;
  flex-direction: column;
}

@media only screen and (max-width: 1199px) {
  .split-area .swiper-pagination-two.style-1 {
    gap: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .split-area .swiper-pagination-two.style-1 {
    right: 5px !important;
  }
}

.spilt-slider-wrapper {
  height: 100%;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.spilt-slider-wrapper .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box;
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-image-left {
  -webkit-filter: sepia(0%);
  filter: sepia(0%);
}

@media only screen and (max-width: 767px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide {
    flex-direction: column;
    height: 100vh !important;
  }
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image {
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-size: cover !important;
}

@media only screen and (max-width: 767px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image {
    width: 100%;
    height: calc(50vh - 40px);
  }
}

@media only screen and (max-width: 575px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image {
    background-size: contain !important;
  }
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg {
    height: calc(50vh - 25px);
  }
}

@media only screen and (max-width: 575px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg {
    background-size: cover !important;
    background-position: top !important;
  }
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content {
  padding: 40px;
  height: auto;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content {
    bottom: 0;
  }
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .product-tag {
  margin-bottom: 5px;
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .title {
  font-size: 35px;
  width: 75%;
}

@media only screen and (max-width: 991px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .title {
    font-size: 24px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image.swiper-bg.portfolio-box.style-2 .dz-content {
    padding: 20px;
  }
}

.spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image .swiper-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  padding: 50px;
  position: relative;
  max-width: 600px;
  overflow: unset;
  margin: auto;
}

@media only screen and (max-width: 1199px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image .swiper-content {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 991px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image .swiper-content {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image .swiper-content {
    padding: 20px;
  }

  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image .swiper-content img {
    object-fit: cover;
    width: 300px;
  }

  .spilt-slider-wrapper .swiper-wrapper .swiper-slide .swiper-image .swiper-content .portfolio-box {
    margin-left: auto;
    margin-right: auto;
  }
}

.spilt-slider-wrapper .swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.spilt-slider-wrapper .swiper-container-vertical>.swiper-wrapper {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.spilt-slider-wrapper .swiper-container-android .swiper-slide,
.spilt-slider-wrapper .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.spilt-slider-wrapper .swiper-container-multirow>.swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.spilt-slider-wrapper .swiper-container-free-mode>.swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.spilt-slider-wrapper .swiper-pagination {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 99999;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.spilt-slider-wrapper .swiper-pagination span {
  display: block;
  font-size: 14px;
  color: #000;
  text-align: center;
  font-weight: 700;
  opacity: 0.3;
  padding: 5px 10px;
  text-shadow: 1px 2px 0px rgba(255, 255, 255, 0.5);
}

.spilt-slider-wrapper .swiper-pagination span:nth-child(1):after {
  content: "01";
}

.spilt-slider-wrapper .swiper-pagination span:nth-child(2):after {
  content: "02";
}

.spilt-slider-wrapper .swiper-pagination span:nth-child(3):after {
  content: "03";
}

.spilt-slider-wrapper .swiper-pagination span:nth-child(4):after {
  content: "04";
}

.spilt-slider-wrapper .swiper-pagination span:nth-child(5):after {
  content: "05";
}

.spilt-slider-wrapper .swiper-pagination span:nth-child(6):after {
  content: "06";
}

.spilt-slider-wrapper .swiper-pagination span:nth-child(7):after {
  content: "07";
}

.collection-bx {
  position: relative;
  z-index: 1;
  max-height: 700px;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .collection-bx {
    max-height: auto;
    height: auto;
  }
}

.collection-bx:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 103%;
  height: 100%;
  background-size: cover;
  z-index: -2;
  background-position: left;
}

.collection-bx .title {
  font-size: 60px;
  font-weight: 600;
  text-transform: capitalize;
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.3;
}

@media only screen and (max-width: 1199px) {
  .collection-bx .title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 991px) {
  .collection-bx .title {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .collection-bx .title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .collection-bx .title {
    font-size: 28px;
  }
}

.collection-bx .collection1,
.collection-bx .collection2,
.collection-bx .collection3,
.collection-bx .collection4,
.collection-bx .collection5 {
  position: absolute;
  z-index: -1;
}

@media only screen and (max-width: 991px) {

  .collection-bx .collection1 img,
  .collection-bx .collection2 img,
  .collection-bx .collection3 img,
  .collection-bx .collection4 img,
  .collection-bx .collection5 img {
    transform: scale(0.7);
  }
}

@media only screen and (max-width: 767px) {

  .collection-bx .collection1,
  .collection-bx .collection2,
  .collection-bx .collection3,
  .collection-bx .collection4,
  .collection-bx .collection5 {
    display: none;
  }
}

.collection-bx .collection1 {
  top: 10%;
  left: 5%;
  animation: move1 5s infinite linear;
}

.collection-bx .collection1 img {
  border-radius: 100px 0 0 0;
}

.collection-bx .collection2 {
  bottom: 10%;
  left: 17%;
  animation: move2 5s infinite linear;
}

.collection-bx .collection2 img {
  border-radius: 110px 110px 0 0;
}

.collection-bx .collection3 {
  top: 10%;
  right: 5%;
  animation: move1 5s infinite linear;
}

.collection-bx .collection3 img {
  border-radius: 0px 0px 110px 0px;
}

.collection-bx .collection4 {
  bottom: 10%;
  right: 17%;
  animation: move2 5s infinite linear;
}

.collection-bx .collection4 img {
  border-radius: 130px;
}

.collection-bx .collection5 {
  top: 15%;
  left: 45%;
  animation: move1 5s infinite linear;
}

.collection-bx .collection5 img {
  border-radius: 0px 50px 0 0;
}

@keyframes move1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }

  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }

  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }

  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }

  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.company-box.style-1 .dz-media {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  margin-bottom: 15px;
}

.company-box.style-1 .dz-media .company-img {
  transition: 0.5s;
}

.company-box.style-1 .dz-media .sale-badge {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  right: 15px;
  top: 15px;
  position: absolute;
  z-index: 9;
  background: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  color: var(--title);
}

.company-box.style-1 .dz-media:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  z-index: 0;
}

.company-box.style-1 .dz-media .logo {
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
}

.company-box.style-1 .dz-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-box.style-1 .dz-content .title {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.company-box.style-1 .dz-content .sale-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--title);
}

.company-box.style-1:hover .dz-media .company-img {
  transform: scale(1.2);
}

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.icon-bx-xl.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-xl i {
  font-size: 80px;
  vertical-align: middle;
}

.icon-bx-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.icon-bx-lg.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-lg i {
  font-size: 50px;
  vertical-align: middle;
}

.icon-bx-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 55px;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.icon-bx-md.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-md i {
  font-size: 35px;
  vertical-align: middle;
}

.icon-bx-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 40px;
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  line-height: 60px;
}

.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-sm i {
  font-size: 26px;
  vertical-align: middle;
}

.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 26px;
}

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.icon-bx-xs i {
  font-size: 30px;
  vertical-align: middle;
}

.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px;
}

.icon-xl i {
  vertical-align: middle;
  font-size: 80px;
}

.icon-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-lg {
  display: inline-block;
  text-align: center;
  line-height: 1;
}

.icon-lg i {
  vertical-align: middle;
  font-size: 60px;
}

.icon-lg img {
  vertical-align: middle;
  max-width: 100%;
  min-width: 65px;
  width: 65px;
}

.icon-md {
  display: inline-block;
  text-align: center;
}

.icon-md i {
  vertical-align: middle;
  font-size: 45px;
}

.icon-md img {
  vertical-align: middle;
  max-width: 100%;
  min-width: 45px;
  width: 45px;
}

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px;
}

.icon-sm i {
  vertical-align: middle;
  font-size: 30px;
}

.icon-sm img {
  vertical-align: middle;
  max-width: 100%;
  min-width: 30px;
  width: 30px;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
}

.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}

.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-bx-wraper {
  position: relative;
}

.icon-bx-wraper .dz-tilte {
  margin-top: 0;
}

.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
}

.icon-bx-wraper p:last-child {
  margin: 0;
}

.icon-bx-wraper.center {
  text-align: center;
}

.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 20px;
}

.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}

.icon-bx-wraper.right {
  text-align: right;
}

.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}

.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  float: right;
  margin-left: 10px;
}

.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}

.icon-bx-wraper.bx-style-2.center [class*=icon-bx-] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}

.icon-bx-wraper.bx-style-2.left [class*=icon-bx-] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.icon-bx-wraper.bx-style-2.right [class*=icon-bx-] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}

[class*=icon-bx-][class*=bg-] a,
[class*=icon-bx-][class*=bg-] span {
  color: #ffffff;
}

[class*=icon-bx-].bg-white a {
  color: inherit;
}

[class*=icon-bx-][class*=border-] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

[class*=icon-bx-][class*=border-] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative;
}

.dz-tilte-inner {
  display: inline-block;
}

.dz-box[class*=border-],
.dz-info[class*=border-] {
  border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}

.left-border:before,
.right-border:before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #CCC;
}

.right-border:before {
  right: 0;
}

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden;
}

.dz-media img,
.dz-post-media img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.icon-bx-wraper.style-1 .icon-bx {
  margin: 0 auto 10px;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  color: var(--primary);
  font-size: 65px;
  justify-content: center;
}

.icon-bx-wraper.style-1 .icon-bx i {
  display: flex;
}

.icon-bx-wraper.style-1 p {
  color: #5E626F;
  font-size: 16px;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  text-transform: capitalize;
}

.icon-bx-wraper.style-1 .dz-title {
  font-weight: 400;
  letter-spacing: 1.5px;
}

.icon-bx-wraper.style-1 .square {
  width: 8px;
  height: 8px;
  border: 1px solid var(--primary);
  transform: rotate(45deg);
  margin: 15px auto;
}

@media only screen and (max-width: 767px) {
  .icon-bx-wraper.style-1 .icon-bx {
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .icon-bx-wraper.style-1 .square {
    margin: 6px auto;
    width: 5px;
    height: 5px;
  }

  .icon-bx-wraper.style-1 .dz-title {
    font-size: 16px;
  }

  .icon-bx-wraper.style-1 p {
    font-size: 13px;
  }
}

.icon-bx-wraper.style-2 {
  display: flex;
  align-items: center;
  padding: 40px;
  overflow: hidden;
  height: 100%;
}

.icon-bx-wraper.style-2 .icon-bx {
  margin-right: 24px;
}

.icon-bx-wraper.style-2 .dz-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.icon-bx-wraper.style-2 p {
  max-width: 250px;
  font-size: 15px;
}

.icon-bx-wraper.style-2 .data-text {
  position: absolute;
  bottom: -15px;
  right: -5px;
  font-size: 100px;
  line-height: 100px;
  font-weight: 700;
  color: var(--title);
  font-family: var(--font-family-title);
  opacity: 0.1;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.icon-bx-wraper.style-2:nth-child(odd) {
  background-color: #F8F8F8;
}

@media only screen and (max-width: 1480px) {
  .icon-bx-wraper.style-2 {
    padding: 25px;
  }
}

@media only screen and (max-width: 1199px) {
  .icon-bx-wraper.style-2 {
    padding: 20px;
  }

  .icon-bx-wraper.style-2 .icon-bx {
    margin-right: 15px;
  }

  .icon-bx-wraper.style-2 .dz-title {
    margin-bottom: 5px;
    font-size: 18px;
  }

  .icon-bx-wraper.style-2 p {
    font-size: 14px;
  }

  .icon-bx-wraper.style-2 .data-text {
    font-size: 80px;
    line-height: 85px;
  }
}

@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-2 {
    padding: 25px;
  }
}

.icon-bx-wraper.style-2:hover .data-text {
  transform: scale(1.3) rotate(-15deg);
}

.icon-bx-wraper.style-3 {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.icon-bx-wraper.style-3 .icon-bx {
  margin-right: 15px;
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-bx-wraper.style-3 .icon-bx i {
  font-size: 46px;
  color: #ABABAB;
}

.icon-bx-wraper.style-3 .dz-title {
  font-weight: 500;
}

.icon-bx-wraper.style-3 span {
  font-size: 13px;
  color: var(--secondary);
}

.icon-bx-wraper.style-3 .data-text {
  position: absolute;
  bottom: -15px;
  right: -5px;
  font-size: 100px;
  line-height: 100px;
  font-weight: 700;
  color: var(--title);
  font-family: var(--font-family-title);
  opacity: 0.1;
}

@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-3 {
    padding: 25px 25px;
  }

  .icon-bx-wraper.style-3 .data-text {
    font-size: 80px;
    line-height: 85px;
  }
}

.icon-bx-wraper.style-4 {
  border: 1px solid var(--secondary);
  padding: 20px 25px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.icon-bx-wraper.style-4 .icon-bx {
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-bx-wraper.style-4 .icon-bx i {
  font-size: 46px;
  color: var(--secondary);
}

.icon-bx-wraper.style-4 .icon-content {
  margin-left: 20px;
}

.icon-bx-wraper.style-4 .icon-content p {
  font-size: 14px;
}

.dz-maximize {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-size: 24px;
  color: var(--title);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px;
}

.dz-maximize.top-left {
  position: absolute;
  top: 20px;
  left: 20px;
}

.dz-maximize.top-right {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dz-maximize.bottom-left {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.dz-maximize.bottom-right {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

@media only screen and (max-width: 575px) {
  .dz-maximize {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

  .dz-maximize.top-left {
    top: 10px;
    left: 10px;
  }

  .dz-maximize.top-right {
    top: 10px;
    right: 10px;
  }

  .dz-maximize.bottom-left {
    bottom: 10px;
    left: 10px;
  }

  .dz-maximize.bottom-right {
    bottom: 10px;
    right: 10px;
  }
}

.post-video {
  position: relative;
}

.post-video a {
  display: block;
  position: relative;
}

.post-video .post-video-icon {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  border-radius: 4px;
}

.post-video .post-video-icon:hover {
  background: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .post-video .post-video-icon {
    width: 50px;
    height: 50px;
    font-size: 24px;
    padding-left: 2px;
  }
}

.dz-meta {
  margin-bottom: 10px;
}

.dz-meta ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dz-meta ul li {
  display: inline-flex;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.dz-meta ul li i {
  font-size: 15px;
  margin-right: 2px;
  color: var(--secondary);
  position: relative;
  top: -1px;
}

@media only screen and (max-width: 575px) {
  .dz-meta ul li {
    margin-right: 10px;
    padding-right: 10px;
  }
}

.dz-card .dz-media img {
  width: 100%;
}

.dz-card .read-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dz-card .read-btn i {
  font-size: 16px;
}

.dz-card.style-1 {
  position: relative;
  margin-bottom: 30px;
}

.dz-card.style-1 .dz-media {
  border-radius: 30px;
}

.dz-card.style-1 .dz-info {
  background: var(--secondary);
  padding: 25px 30px;
  width: 440px;
  bottom: 0px;
  position: absolute;
  left: 0px;
  z-index: 1;
  border-radius: 0px 30px;
}

.dz-card.style-1 .dz-info .dz-meta ul li {
  font-size: 13px;
  color: var(--title);
  margin-bottom: 10px;
}

.dz-card.style-1 .dz-info .dz-meta ul li.post-date {
  padding: 5px 12px;
  line-height: 1.2;
  font-weight: 600;
  display: inline-block;
  border-radius: 5px;
  background: #fff;
}

.dz-card.style-1 .dz-info .dz-title a {
  color: #fff;
}

.dz-card.style-1 .dz-info .read-btn {
  color: #fff;
}

.dz-card.style-1 .dz-info .read-btn svg path {
  stroke: #fff;
}

.dz-card.style-1.light .dz-info {
  background: #FEEB9D;
}

.dz-card.style-1.light .dz-info .dz-meta ul li.post-date {
  background: var(--secondary);
  color: #fff;
}

.dz-card.style-1.light .dz-info .dz-title a {
  color: var(--title);
}

.dz-card.style-1.light .dz-info .read-btn {
  color: var(--title);
}

.dz-card.style-1.light .dz-info .read-btn svg path {
  stroke: var(--title);
}

@media only screen and (max-width: 1199px) {
  .dz-card.style-1 .dz-info {
    width: 100%;
    padding: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-1 .dz-media {
    border-radius: 30px 30px 0 0;
  }

  .dz-card.style-1 .dz-info {
    position: unset;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 30px 30px;
  }

  .dz-card.style-1 .dz-info .dz-meta ul li {
    margin-bottom: 0;
  }

  .dz-card.style-1 .dz-info .dz-title {
    font-size: 18px;
  }
}

.dz-card.style-2 {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 30px;
}

.dz-card.style-2 .dz-media .post-date {
  padding: 5px 12px;
  position: absolute;
  top: 30px;
  left: 30px;
  line-height: 1.2;
  z-index: 1;
  color: var(--secondary);
  background-color: #fff;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
  border-radius: 6px;
}

.dz-card.style-2.dark .dz-info {
  background-color: var(--secondary);
  opacity: 1;
}

.dz-card.style-2.dark .dz-info .dz-title a {
  color: #fff;
}

.dz-card.style-2.dark .dz-info .dz-meta ul li.post-date {
  color: #fff;
  background-color: var(--secondary);
}

.dz-card.style-2.dark .dz-info .dz-meta ul li a {
  color: rgba(255, 255, 255, 0.7);
}

.dz-card.style-2 .dz-info {
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: calc(100% - 40px);
  transform: translateX(-50%);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dz-card.style-2 .dz-info .dz-title {
  font-size: 24px;
  font-weight: 500;
  text-shadow: 0px 0px 30px #000;
}

.dz-card.style-2 .dz-info .dz-title a {
  color: #fff;
}

.dz-card.style-2 .dz-info .dz-meta {
  margin-bottom: 5px;
}

.dz-card.style-2 .dz-info .dz-meta ul {
  margin-right: -10px;
}

.dz-card.style-2 .dz-info .dz-meta ul li {
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  text-transform: uppercase;
  color: #0D775E;
  line-height: 1.2;
  padding: 0;
  border-radius: 50%;
}

.dz-card.style-2 .dz-info .dz-meta ul li.post-date {
  padding: 5px 12px;
  position: absolute;
  top: -278px;
  left: 0px;
  z-index: 1;
  color: var(--secondary);
  background-color: #fff;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

.dz-card.style-2 .dz-info .dz-meta ul li a {
  color: var(--primary);
}

.dz-card.style-2 .dz-info .blog-social li {
  z-index: 9999;
  position: relative;
}

.dz-card.style-2 .dz-info .blog-social li a {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  color: #fff;
  font-size: 18px;
  border-radius: 50%;
}

.dz-card.style-2 .dz-info .blog-social li a.share-btn {
  font-size: 22px;
  line-height: 45px;
}

.dz-card.style-2 .dz-info .blog-social .sub-team-social {
  position: absolute;
  bottom: 0;
  width: 40px;
  border-radius: var(--border-radius-base);
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -1;
}

.dz-card.style-2 .dz-info .blog-social .sub-team-social li {
  margin-bottom: 8px;
}

.dz-card.style-2 .dz-info .blog-social .sub-team-social li a {
  color: var(--title);
  display: inline-block;
  text-align: center;
  margin-bottom: -20px;
  background: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-card.style-2 .dz-info .blog-social:hover li a {
  background: var(--primary);
}

.dz-card.style-2 .dz-info .blog-social:hover li a svg,
.dz-card.style-2 .dz-info .blog-social:hover li a svg path {
  stroke: #fff;
}

.dz-card.style-2 .dz-info .blog-social:hover li .sub-team-social {
  opacity: 1;
  bottom: 70px;
}

.dz-card.style-2 .dz-info .blog-social:hover li .sub-team-social li a {
  margin-bottom: 0;
  background: #fff;
}

@media only screen and (min-width: 1024px) and (max-width: 1380px) {
  .dz-card.style-2 .dz-info {
    width: calc(100% - 30px);
    padding: 20px 20px;
    bottom: 15px;
  }

  .dz-card.style-2 .dz-info .dz-meta ul li.post-date {
    top: -246px;
    left: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .dz-card.style-2 .dz-info .dz-meta ul li.post-date {
    top: -330px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-2 .dz-info {
    padding: 15px;
    bottom: 15px;
    width: calc(100% - 30px);
  }

  .dz-card.style-2 .dz-info .dz-title {
    font-size: 18px;
  }

  .dz-card.style-2 .dz-info .dz-meta ul li.post-date {
    padding: 5px 12px;
    top: -213px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-card.style-2 .dz-info .dz-title {
    font-size: 20px;
  }

  .dz-card.style-2 .dz-info .dz-meta ul li.post-date {
    top: -404px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-card.style-2 .dz-info {
    padding: 12px;
    bottom: 10px;
    width: calc(100% - 20px);
  }

  .dz-card.style-2 .dz-info .dz-title {
    font-size: 18px;
  }

  .dz-card.style-2 .dz-info .dz-meta ul li.post-date {
    top: -235px;
  }
}

.dz-card.style-3 {
  position: relative;
  margin-bottom: 30px;
}

.dz-card.style-3 .dz-media {
  margin-bottom: 25px;
  border-radius: 20px;
}

.dz-card.style-3 .dz-info {
  padding: 0;
}

.dz-card.style-3 .dz-info .dz-meta ul li {
  font-size: 13px;
  color: #fff;
  padding: 5px 12px;
  margin-bottom: 0;
  border-radius: 4px;
}

.dz-card.style-3 .dz-info .dz-meta ul li.post-date {
  line-height: 1.2;
  font-weight: 500;
  display: inline-block;
  background: var(--secondary);
}

@media only screen and (max-width: 1199px) {
  .dz-card.style-3 .dz-info .dz-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-3 .dz-media {
    width: 100%;
    height: 250px;
    margin-bottom: 0;
  }

  .dz-card.style-3 .dz-media img {
    height: 100%;
    object-fit: cover;
  }

  .dz-card.style-3 .dz-info {
    padding: 20px 0 0 0;
  }

  .dz-card.style-3 .dz-info .dz-title {
    font-size: 20px;
  }

  .dz-card.style-3 .dz-info .dz-meta ul li {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .dz-card.style-3 .dz-media {
    height: auto;
  }
}

.dz-card.style-4 {
  position: relative;
  overflow: unset;
  padding-left: 25%;
  margin-bottom: 50px;
  margin-top: -20px;
}

@media only screen and (max-width: 991px) {
  .dz-card.style-4 {
    margin-bottom: 30px;
    margin-top: 0;
  }
}

.dz-card.style-4 .dz-media {
  border-radius: 20px;
}

.dz-card.style-4 .dz-info {
  padding: 0;
  max-width: 300px;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.dz-card.style-4 .dz-info .dz-meta ul li {
  font-size: 13px;
  color: #fff;
  padding: 5px 12px;
  margin-bottom: 0;
  border-radius: 4px;
}

.dz-card.style-4 .dz-info .dz-meta ul li.post-date {
  line-height: 1.2;
  font-weight: 500;
  display: inline-block;
  background: var(--secondary);
}

.dz-card.style-4 .dz-info .dz-title {
  font-size: 35px;
  text-transform: capitalize;
}

@media only screen and (max-width: 1199px) {
  .dz-card.style-4 .dz-info .dz-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-4 {
    padding-left: 0;
    overflow: hidden;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .dz-card.style-4 .dz-media {
    width: 100%;
    height: 250px;
    margin-bottom: 0;
  }

  .dz-card.style-4 .dz-info {
    max-width: 100%;
    position: unset;
    transform: unset;
    padding: 20px 0 0 0;
  }

  .dz-card.style-4 .dz-info .dz-title {
    font-size: 20px;
  }

  .dz-card.style-4 .read-btn {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .dz-card.style-4 .dz-media {
    height: auto;
  }
}

.dz-card.style-5.blog-half {
  display: flex;
}

.dz-card.style-5.blog-half .dz-media {
  width: 315px;
  min-width: 315px;
  height: auto;
  min-height: 290px;
  border-radius: 30px 0 0 30px;
}

.dz-card.style-5.blog-half .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dz-card.style-5.blog-half .dz-info {
  padding: 30px 30px 25px;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 0 30px 30px 0;
}

.dz-card.style-5.blog-half .dz-info .dz-title {
  margin-bottom: 10px;
}

.dz-card.style-5.blog-half .dz-info .read-btn {
  margin-top: auto;
}

@media only screen and (min-width: 1024px) and (max-width: 1380px) {
  .dz-card.style-5.blog-half .dz-media {
    width: 250px;
    min-width: 250px;
    min-height: 260px;
  }
}

@media only screen and (max-width: 1024px) {
  .dz-card.style-5.blog-half .dz-media {
    width: 200px;
    min-width: 200px;
    min-height: 200px;
  }

  .dz-card.style-5.blog-half .dz-info {
    padding: 20px;
  }

  .dz-card.style-5.blog-half .dz-info .dz-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-5.blog-half {
    display: block;
  }

  .dz-card.style-5.blog-half .dz-media {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .dz-card.style-5.blog-half.blog-half .dz-info {
    padding: 15px;
    border-radius: 0 0 20px 20px;
  }

  .dz-card.style-5.blog-half.blog-half .dz-media {
    border-radius: 20px 20px 0 0;
  }
}

.dz-card.style-5.light .dz-info {
  background: #FEEB9D;
}

.dz-card.style-5.light .dz-info .dz-meta ul li.post-date {
  background: var(--secondary);
  color: #fff;
}

.dz-card.style-5.light .dz-info .dz-title a {
  color: var(--title);
}

.dz-card.style-5.light .dz-info .read-btn {
  color: var(--title);
}

.dz-card.style-5.light .dz-info .read-btn svg path {
  stroke: var(--title);
}

.dz-card.style-5 .dz-media {
  border-radius: 30px 30px 0px 0px;
}

.dz-card.style-5 .dz-info {
  padding: 30px 25px 25px;
  background: var(--secondary);
  border-radius: 0px 0px 30px 30px;
}

.dz-card.style-5 .dz-info .dz-meta ul li {
  font-size: 13px;
  color: var(--title);
  padding: 5px 12px;
  margin-bottom: 3px;
}

.dz-card.style-5 .dz-info .dz-meta ul li.post-date {
  line-height: 1.2;
  font-weight: 500;
  display: inline-block;
  background: rgb(237, 237, 237);
  border-radius: 5px;
}

.dz-card.style-5 .dz-info .dz-title a {
  color: #fff;
}

.dz-card.style-5 .dz-info .read-btn {
  color: #fff;
}

.dz-card.style-5 .dz-info .read-btn svg path {
  stroke: #fff;
}

@media only screen and (max-width: 991px) {
  .dz-card.style-5 .dz-info {
    padding: 20px;
  }

  .dz-card.style-5 .dz-info .dz-meta ul li {
    padding: 4px 10px;
  }

  .dz-card.style-5 .dz-info .dz-title {
    font-size: 18px;
  }
}

.dz-card.style-6.blog-half {
  display: flex;
  border-radius: 30px;
  background: #fff;
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6.blog-half.card-1 .dz-media img {
    mask-image: none;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6.blog-half.card-2 .dz-media img {
    mask-image: none;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6.blog-half.card-3 .dz-media img {
    mask-image: none;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6.blog-half.card-4 .dz-media img {
    mask-image: none;
  }
}

.dz-card.style-6.blog-half .btn,
.dz-card.style-6.blog-half .woocommerce #respond input#submit,
.woocommerce #respond .dz-card.style-6.blog-half input#submit,
.dz-card.style-6.blog-half .woocommerce a.button,
.woocommerce .dz-card.style-6.blog-half a.button,
.dz-card.style-6.blog-half .woocommerce button.button,
.woocommerce .dz-card.style-6.blog-half button.button,
.dz-card.style-6.blog-half .woocommerce input.button,
.woocommerce .dz-card.style-6.blog-half input.button,
.dz-card.style-6.blog-half .woocommerce #place_order,
.woocommerce .dz-card.style-6.blog-half #place_order,
.dz-card.style-6.blog-half .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-card.style-6.blog-half a.checkout-button,
.dz-card.style-6.blog-half .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-card.style-6.blog-half .button,
.dz-card.style-6.blog-half .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-card.style-6.blog-half input,
.dz-card.style-6.blog-half .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-card.style-6.blog-half a,
.dz-card.style-6.blog-half .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-card.style-6.blog-half a,
.dz-card.style-6.blog-half .wp-block-button__link {
  margin-top: auto;
}

.dz-card.style-6.blog-half .dz-media {
  width: 275px;
  min-width: 275px;
  min-height: 320px;
}

@media only screen and (max-width: 1199px) {
  .dz-card.style-6.blog-half .dz-media {
    width: 200px;
    min-width: 200px;
    min-height: 260px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6.blog-half .dz-media {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
  }
}

.dz-card.style-6.blog-half .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px 0 0 30px;
  mask-size: 100% 100%;
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6.blog-half .dz-media img {
    border-radius: 30px 30px 0 0;
  }
}

.dz-card.style-6.blog-half .dz-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 0 30px 30px 0;
  justify-content: center;
}

.dz-card.style-6.blog-half .dz-info .dz-title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .dz-card.style-6.blog-half .dz-info .dz-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .dz-card.style-6.blog-half .dz-info {
    padding: 20px;
  }

  .dz-card.style-6.blog-half .dz-info .dz-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6.blog-half {
    display: block;
  }
}

.dz-card.style-6 .dz-info {
  padding: 30px 25px 25px;
  border-radius: 0px 0px 30px 30px;
}

.dz-card.style-6 .dz-info .dz-meta ul li {
  font-size: 13px;
  color: #fff;
  padding: 5px 12px;
  margin-bottom: 3px;
}

.dz-card.style-6 .dz-info .dz-meta ul li.post-date {
  line-height: 1.2;
  font-weight: 500;
  display: inline-block;
  background: var(--secondary);
  border-radius: 5px;
}

.dz-card.style-6 .dz-info .read-btn {
  color: #fff;
}

.dz-card.style-6 .dz-info .read-btn svg path {
  stroke: #fff;
}

@media only screen and (max-width: 991px) {
  .dz-card.style-6 .dz-info {
    padding: 20px;
  }

  .dz-card.style-6 .dz-info .dz-meta ul li {
    padding: 4px 10px;
  }

  .dz-card.style-6 .dz-info .dz-title {
    font-size: 18px;
  }
}

.dz-card.style-7.blog-half {
  display: flex;
  border-radius: 30px;
  background: #fff;
}

.dz-card.style-7.blog-half .dz-media {
  width: 315px;
  min-width: 315px;
  height: 290px;
}

.dz-card.style-7.blog-half .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px 0 0 30px;
}

@media only screen and (max-width: 991px) {
  .dz-card.style-7.blog-half .dz-media img {
    border-radius: 30px 30px 0 0;
  }
}

.dz-card.style-7.blog-half .dz-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 0 30px 30px 0;
  justify-content: center;
}

.dz-card.style-7.blog-half .dz-info .dz-title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
}

.dz-card.style-7.blog-half .dz-info .read-btn {
  color: var(--title);
  width: fit-content;
  font-weight: 600;
}

@media only screen and (min-width: 1024px) and (max-width: 1380px) {
  .dz-card.style-7.blog-half .dz-media {
    width: 250px;
    min-width: 250px;
    height: 260px;
  }
}

@media only screen and (max-width: 1024px) {
  .dz-card.style-7.blog-half .dz-media {
    width: 200px;
    min-width: 200px;
    height: 235px;
  }

  .dz-card.style-7.blog-half .dz-info {
    padding: 20px;
  }

  .dz-card.style-7.blog-half .dz-info .dz-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-card.style-7.blog-half {
    display: block;
  }

  .dz-card.style-7.blog-half .dz-media {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .dz-card.style-7.blog-half.blog-half .dz-info {
    padding: 15px;
  }
}

.dz-card.style-7 .dz-info {
  padding: 30px 25px 25px;
  border-radius: 0px 0px 30px 30px;
}

.dz-card.style-7 .dz-info .dz-meta ul li {
  font-size: 13px;
  color: #fff;
  padding: 5px 12px;
  margin-bottom: 3px;
}

.dz-card.style-7 .dz-info .dz-meta ul li.post-date {
  line-height: 1.2;
  font-weight: 500;
  display: inline-block;
  background: var(--secondary);
  border-radius: 5px;
}

.dz-card.style-7 .dz-info .read-btn {
  color: #fff;
}

.dz-card.style-7 .dz-info .read-btn svg path {
  stroke: #fff;
}

@media only screen and (max-width: 991px) {
  .dz-card.style-7 .dz-info {
    padding: 20px;
  }

  .dz-card.style-7 .dz-info .dz-meta ul li {
    padding: 4px 10px;
  }

  .dz-card.style-7 .dz-info .dz-title {
    font-size: 18px;
  }
}

/* DZ Shine */
.dz-card.style-1 .dz-media::before,
.dz-card.style-2 .dz-media::before,
.dz-card.style-3 .dz-media::before,
.dz-card.style-4 .dz-media::before,
.dz-card.style-5 .dz-media::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 0;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.dz-card.style-1:hover .dz-media:before,
.dz-card.style-2:hover .dz-media:before,
.dz-card.style-3:hover .dz-media:before,
.dz-card.style-4:hover .dz-media:before,
.dz-card.style-5:hover .dz-media:before {
  -webkit-animation: dzShine 0.75s;
  animation: dzShine 0.75s;
}

@-webkit-keyframes dzShine {
  100% {
    left: 125%;
  }
}

@keyframes dzShine {
  100% {
    left: 125%;
  }
}

.star-rating li {
  display: inline-block;
}

.dz-box {
  position: relative;
}

.portfolio-box {
  position: relative;
  overflow: hidden;
}

.portfolio-box.style-1 {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 20px;
}

.portfolio-box.style-1 .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio-box.style-1 .dz-content {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 20px;
  position: absolute;
  bottom: -60%;
  left: 0;
  width: 85%;
  border-radius: 0 20px;
  z-index: 1;
  background: var(--secondary);
}

.portfolio-box.style-1 .dz-content .product-tag .badge {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 9px;
}

.portfolio-box.style-1 .dz-content .title a {
  color: #fff;
}

.portfolio-box.style-1:hover .dz-content {
  bottom: 0%;
}

.portfolio-box.style-1:hover .dz-media img {
  transform: scale(1.2);
}

@media only screen and (max-width: 1199px) {
  .portfolio-box.style-1 .dz-content {
    width: 100%;
    padding: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-box.style-1 .dz-content .title {
    font-size: 16px;
  }
}

.portfolio-box.style-4 {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio-box.style-4 .dz-content {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #fff;
  padding: 25px 20px 15px;
  width: 355px;
  bottom: 0px;
  position: absolute;
  left: 0px;
  z-index: 1;
  opacity: 0;
}

.portfolio-box.style-4 .dz-content .product-tag .badge {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 9px;
}

.portfolio-box.style-4:hover .dz-content {
  opacity: 1;
}

@media only screen and (max-width: 1199px) {
  .portfolio-box.style-4 .dz-content {
    width: 100%;
    padding: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-box.style-4 .dz-content .title {
    font-size: 16px;
  }

  .portfolio-box.style-4 .dz-content .product-tag .badge {
    font-size: 12px;
  }
}

.portfolio-box.style-2 {
  border-radius: 20px;
}

.portfolio-box.style-2.rounded-0 .dz-content:after {
  border-radius: 0;
}

.portfolio-box.style-2 .dz-content {
  transition: all 0.8s ease 0s;
  padding: 25px;
  width: 355px;
  bottom: 0px;
  position: absolute;
  left: 0px;
  z-index: 1;
  opacity: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
}

.portfolio-box.style-2 .dz-content .title {
  margin-bottom: 0;
}

.portfolio-box.style-2 .dz-content .title a {
  color: #fff;
}

.portfolio-box.style-2 .dz-content:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 39.51%, rgba(0, 0, 0, 0.5) 86.14%);
  border-radius: 10px;
  z-index: 1;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 10s;
  opacity: 1;
  z-index: -1;
}

.portfolio-box.style-2 .dz-media {
  height: 100%;
}

.portfolio-box.style-2 .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  min-height: 250px;
  object-fit: cover;
}

.portfolio-box.style-2:hover .dz-media img {
  transform: scale(1.2);
}

.portfolio-box.style-3 {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio-box.style-3 .dz-media img {
  width: 100%;
  object-fit: cover;
}

.portfolio-box.style-3 .dz-content {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #fff;
  padding: 25px 20px 15px;
  width: 686px;
  bottom: 0px;
  position: absolute;
  left: 0px;
  z-index: 1;
  opacity: 0;
}

.portfolio-box.style-3 .dz-content .product-tag .badge {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 9px;
}

.portfolio-box.style-3:hover .dz-content {
  opacity: 1;
}

@media only screen and (max-width: 1199px) {
  .portfolio-box.style-3 .dz-content {
    width: 100%;
    padding: 12px;
  }

  .portfolio-box.style-3 .dz-content .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-box.style-3 .dz-content .title {
    font-size: 18px;
  }
}

.portfolio-box.style-1 .dz-media::before,
.portfolio-box.style-2 .dz-media::before,
.portfolio-box.style-3 .dz-media::before,
.portfolio-box.style-4 .dz-media::before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 0;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.portfolio-box.style-1:hover .dz-media:before,
.portfolio-box.style-2:hover .dz-media:before,
.portfolio-box.style-3:hover .dz-media:before,
.portfolio-box.style-4:hover .dz-media:before {
  -webkit-animation: dzShine 0.75s;
  animation: dzShine 0.75s;
}

@-webkit-keyframes dzShine {
  100% {
    left: 125%;
  }
}

@keyframes dzShine {
  100% {
    left: 125%;
  }
}

.portfolio-thumb .swiper-wrapper.card-columns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.portfolio-thumb .swiper-wrapper.card-columns .card-container {
  display: inline-block;
  width: 100%;
}

.gallary-list {
  padding: 4rem;
}

@media only screen and (max-width: 1199px) {
  .gallary-list {
    padding: 4rem 20px 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .gallary-list {
    padding: 20px;
  }
}

.portfolio-bx {
  position: relative;
}

.portfolio-bx.style-1 .dz-media .overlay-bx .mfp-link {
  width: 80px;
  height: 80px;
  display: block;
  font-size: 34px;
  line-height: 80px;
  text-align: center;
  background: var(--secondary);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .portfolio-bx.style-1 .dz-media .overlay-bx .mfp-link {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 25px;
  }
}

.dz-detail-box {
  border: 1px solid #BEBEBE;
  padding: 30px 30px;
  height: 100%;
  border-radius: 30px;
}

.dz-detail-box p {
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 575px) {
  .dz-detail-box {
    padding: 15px;
  }
}

.dz-detail-box.style-2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 50px;
}

@media only screen and (max-width: 767px) {
  .dz-detail-box.style-2 {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-detail-box.style-2 {
    padding: 15px;
  }
}

.portfolio-detail-1 {
  margin-top: -40px;
  z-index: 2;
  padding-top: 0;
}

@media only screen and (max-width: 575px) {
  .portfolio-detail-1 {
    margin-top: 0;
    padding-top: 40px;
  }
}

.detail-list-box.style-1 {
  padding: 50px 50px 34px;
  background-color: #fff;
  margin-top: -100px;
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.detail-list-box.style-1 ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px 0;
  font-family: var(--font-family-title);
}

.detail-list-box.style-1 ul li .title {
  color: var(--title);
  font-weight: 500;
}

.detail-list-box.style-1 ul li:last-child {
  border-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .detail-list-box.style-1 {
    padding: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .detail-list-box.style-1 {
    padding: 20px 25px;
    margin-top: 0;
  }
}

.detail-list-box.style-2 {
  padding: unset;
  background-color: unset;
  margin-top: 40px;
}

.detail-list-box.style-2 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.detail-list-box.style-2 ul li {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0;
  gap: 5px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding: 8px 40px;
  font-family: var(--font-family-title);
  color: #fff;
  white-space: nowrap;
}

.detail-list-box.style-2 ul li .title {
  color: #F6F6F6;
  font-weight: 600;
}

.detail-list-box.style-2 ul li:last-child {
  border-right: 0;
}

@media only screen and (max-width: 991px) {
  .detail-list-box.style-2 ul li {
    border-right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .detail-list-box.style-2 {
    margin-top: 20px;
  }

  .detail-list-box.style-2 ul li {
    padding: 8px 18px;
  }
}

.detail-list-box.style-3 ul {
  display: flex;
  flex-wrap: wrap;
}

.detail-list-box.style-3 ul li {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px 40px;
  font-family: var(--font-family-title);
  color: var(--title);
  white-space: nowrap;
}

.detail-list-box.style-3 ul li .title {
  color: var(--title);
  font-weight: 600;
}

.detail-list-box.style-3 ul li:last-child {
  border-right: 0;
}

@media only screen and (max-width: 767px) {
  .detail-list-box.style-3 ul {
    justify-content: left;
  }

  .detail-list-box.style-3 ul li {
    padding: 10px;
  }
}

.releted-portfolio .portfolio-box .dz-media {
  margin-bottom: 20px;
  border-radius: 20px;
}

.releted-portfolio .portfolio-box .product-tag {
  margin-bottom: 8px;
}

.releted-portfolio .portfolio-box .title {
  max-width: 290px;
}

.post-pagination.style-1 {
  display: flex;
  position: relative;
  padding: 15px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.post-pagination.style-1 .post-prev,
.post-pagination.style-1 .post-next {
  width: 50%;
  min-width: 50%;
}

.post-pagination.style-1 .post-inner {
  display: flex;
  align-items: center;
}

.post-pagination.style-1 .post-inner .inner-icon {
  width: 30px;
  height: 30px;
  font-size: 22px;
  color: #BEBEBE;
  display: flex;
  align-items: center;
  justify-content: left;
}

.post-pagination.style-1 .post-next .post-inner {
  justify-content: flex-end;
  text-align: right;
}

.post-pagination.style-1 .post-next .inner-icon {
  justify-content: right;
}

.post-pagination.style-1 .dz-media {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 20px;
}

@media only screen and (max-width: 575px) {
  .post-pagination.style-1 .dz-media {
    display: none;
  }
}

.post-pagination.style-1 .dz-info {
  padding-right: 15px;
  max-width: 270px;
}

.post-pagination.style-1 .dz-info span {
  color: var(--secondary);
  font-size: 13px;
  font-family: var(--font-family-title);
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .post-pagination.style-1 .dz-info {
    padding-left: 10px;
    padding-right: 10px;
  }

  .post-pagination.style-1 .dz-info h6,
  .post-pagination.style-1 .dz-info .h6 {
    font-size: 14px;
  }
}

.post-pagination.style-1:after {
  content: "";
  height: 70px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.section-detail .dz-content .product-tag {
  margin-bottom: 10px;
}

.section-detail .dz-content .title {
  font-size: 40px;
  line-height: 1.3;
}

@media only screen and (max-width: 575px) {
  .section-detail .dz-content {
    text-align: center;
  }

  .section-detail .dz-content .title {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

.about-section-detail {
  background-color: #FBFBFB;
}

.portfolio-detail-4 .dz-bnr-inr.dz-bnr-inr-lg {
  height: 100vh;
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 1199px) {
  .portfolio-detail-4 .dz-bnr-inr.dz-bnr-inr-lg {
    height: auto;
  }
}

.portfolio-detail-4 .portfolio-detail-inner.column {
  display: flex;
}

.portfolio-detail-4 .portfolio-detail-inner.column .detail-inner-area {
  flex: 0 0 50%;
  width: 50%;
}

.portfolio-detail-4 .portfolio-detail-inner.column .detail-inner-area.right {
  padding: 80px 70px 0;
}

@media only screen and (max-width: 1199px) {
  .portfolio-detail-4 .portfolio-detail-inner.column {
    flex-direction: column;
  }

  .portfolio-detail-4 .portfolio-detail-inner.column .detail-inner-area {
    flex: 0 0 100%;
    width: 100%;
  }

  .portfolio-detail-4 .portfolio-detail-inner.column .detail-inner-area.right {
    padding: 30px 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-detail-4 .portfolio-detail-inner.column .detail-inner-area.right {
    padding: 15px 15px 0;
  }
}

.dz-pricingtable {
  padding: 70px 0 70px;
}

@media only screen and (max-width: 991px) {
  .dz-pricingtable {
    padding: 40px 0 40px;
  }

  .dz-pricingtable .pricingtable-wraper {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .dz-pricingtable {
    padding: 40px 0 10px;
  }
}

.pricingtable-wrapper {
  overflow: hidden;
  margin-top: 25px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pricingtable-wrapper.active,
.pricingtable-wrapper:hover {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .pricingtable-wrapper {
    margin-top: 0;
  }
}

.pricingtable-wrapper.style-1 {
  padding: 42px 40px;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  border: 1px solid var(--secondary);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-title {
  margin-bottom: 15px;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-title .title {
  font-size: 20px;
  font-weight: 600;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-title .text {
  margin-bottom: 0;
  color: var(--title);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx {
  font-size: 60px;
  font-weight: 500;
  margin: 0 0 30px;
  color: var(--title);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx small,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx .small {
  font-size: 15px;
  font-weight: 500;
  color: var(--title);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button {
  margin-bottom: 40px;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .btn,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce #respond input#submit,
.woocommerce #respond .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button input#submit,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce a.button,
.woocommerce .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button a.button,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce button.button,
.woocommerce .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button button.button,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce input.button,
.woocommerce .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button input.button,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce #place_order,
.woocommerce .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button #place_order,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button a.checkout-button,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .button,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button input,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button a,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button a,
.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button .wp-block-button__link {
  width: 100%;
  color: var(--title);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .list-title {
  font-size: 16px;
  font-weight: 700;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features {
  padding: 15px 0;
  text-align: left;
  list-style: none;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features li {
  padding: 8px 0 8px 40px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: var(--title);
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features li:after {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  font-size: 12px;
  width: 20px;
  height: 20px;
  background: var(--secondary);
  display: block;
  position: absolute;
  left: 0;
  color: #fff;
  border-radius: 35px;
  text-align: center;
  top: 10px;
  line-height: 20px;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features li.disable {
  opacity: 0.5;
}

.pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features li.disable:after {
  content: "\f00d";
  background: #D23636;
}

@media only screen and (max-width: 1199px) {
  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-title {
    margin-bottom: 10px;
  }

  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-title .text {
    font-size: 14px;
  }

  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx {
    font-size: 50px;
    margin: 0 0 20px;
  }

  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-button {
    margin-bottom: 30px;
  }

  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features {
    padding: 10px 0;
  }

  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features li {
    padding: 8px 0 8px 30px;
    font-size: 15px;
  }

  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-list .pricingtable-features li:after {
    font-size: 10px;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .pricingtable-wrapper.style-1 .pricingtable-inner .pricingtable-bx {
    text-align: center;
  }
}

.pricingtable-wrapper.style-1.active,
.pricingtable-wrapper.style-1:hover {
  margin-top: 0;
  transform: translateY(0);
  background-color: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 767px) {

  .pricingtable-wrapper.style-1.active,
  .pricingtable-wrapper.style-1:hover {
    transform: unset;
  }
}

.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .btn,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce #respond input#submit,
.woocommerce #respond .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button input#submit,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce a.button,
.woocommerce .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button a.button,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce button.button,
.woocommerce .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button button.button,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce input.button,
.woocommerce .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button input.button,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce #place_order,
.woocommerce .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button #place_order,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button a.checkout-button,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .button,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button input,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button a,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button a,
.pricingtable-wrapper.style-1.active .pricingtable-inner .pricingtable-button .wp-block-button__link,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .btn,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce #respond input#submit,
.woocommerce #respond .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button input#submit,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce a.button,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button a.button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce button.button,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button button.button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce input.button,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button input.button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce #place_order,
.woocommerce .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button #place_order,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button a.checkout-button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .button,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button input,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button a,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button a,
.pricingtable-wrapper.style-1:hover .pricingtable-inner .pricingtable-button .wp-block-button__link {
  background: var(--secondary) !important;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .pricingtable-wrapper.style-1 {
    padding: 42px 35px;
  }
}

@media only screen and (max-width: 1024px) {
  .pricingtable-wrapper.style-1 {
    padding: 35px 30px;
  }
}

@media only screen and (max-width: 767px) {

  .pricingtable-wrapper.style-1.active,
  .pricingtable-wrapper.style-1:hover {
    transform: translateY(0);
  }
}

.about-style1 .section-head {
  margin-bottom: 55px;
  display: block !important;
  width: 80%;
}

.about-style1 .section-head .title {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .about-style1 .section-head {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-style1 .service-btn-2 {
    display: none;
  }
}

.about-style1 .about-content {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .about-style1 .section-head {
    margin-bottom: 12px;
  }

  .about-style1 .section-head .title {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style1 .section-head {
    margin-bottom: 6px;
  }

  .about-style1 .section-head .title {
    font-size: 30px;
  }
}

.about-style1 .about-thumb img {
  width: 100%;
  mask-size: auto 100%;
  mask-repeat: no-repeat;
  height: 100%;
  object-fit: cover;
  mask-position: right;
}

.about-style1 .about-thumb .btn,
.about-style1 .about-thumb .woocommerce #respond input#submit,
.woocommerce #respond .about-style1 .about-thumb input#submit,
.about-style1 .about-thumb .woocommerce a.button,
.woocommerce .about-style1 .about-thumb a.button,
.about-style1 .about-thumb .woocommerce button.button,
.woocommerce .about-style1 .about-thumb button.button,
.about-style1 .about-thumb .woocommerce input.button,
.woocommerce .about-style1 .about-thumb input.button,
.about-style1 .about-thumb .woocommerce #place_order,
.woocommerce .about-style1 .about-thumb #place_order,
.about-style1 .about-thumb .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .about-style1 .about-thumb a.checkout-button,
.about-style1 .about-thumb .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .about-style1 .about-thumb .button,
.about-style1 .about-thumb .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .about-style1 .about-thumb input,
.about-style1 .about-thumb .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .about-style1 .about-thumb a,
.about-style1 .about-thumb .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .about-style1 .about-thumb a,
.about-style1 .about-thumb .wp-block-button__link {
  position: absolute;
  left: 70px;
  bottom: 40px;
  z-index: 1;
}

video#video-background {
  width: 100%;
  height: 440px;
  object-fit: cover;
  vertical-align: middle;
}

.about-thumb {
  height: 100%;
}

.about-thumb img {
  width: 100%;
  height: 100%;
}

.dz-media.style-1 {
  z-index: 1;
  margin-right: 40px;
}

@media only screen and (max-width: 1199px) {
  .dz-media.style-1 {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-media.style-1 {
    margin-right: 0;
  }
}

.dz-media.style-1 img {
  border-radius: 50% 50% 0 0;
}

.dz-media.style-2 {
  z-index: 1;
  margin-right: 20px;
  overflow: visible;
}

.dz-media.style-2:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -60px;
  transform: translate(-60px, -50%);
  width: 100%;
  height: 80%;
  z-index: -1;
  background: #FFFAE4;
  border-radius: 50%;
}

@media only screen and (max-width: 1480px) {
  .dz-media.style-2:after {
    left: -35px;
    transform: translate(-35px, -50%);
  }
}

@media only screen and (max-width: 1199px) {
  .dz-media.style-2 {
    margin-right: 10px;
  }
}

.dz-media.style-2 img {
  border-radius: 30px;
}

.about-box {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 575px) {
  .about-box {
    margin-bottom: 0;
  }
}

.about-box .btn,
.about-box .woocommerce #respond input#submit,
.woocommerce #respond .about-box input#submit,
.about-box .woocommerce a.button,
.woocommerce .about-box a.button,
.about-box .woocommerce button.button,
.woocommerce .about-box button.button,
.about-box .woocommerce input.button,
.woocommerce .about-box input.button,
.about-box .woocommerce #place_order,
.woocommerce .about-box #place_order,
.about-box .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .about-box a.checkout-button,
.about-box .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .about-box .button,
.about-box .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .about-box input,
.about-box .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .about-box a,
.about-box .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .about-box a,
.about-box .wp-block-button__link {
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 1480px) {

  .about-box .btn,
  .about-box .woocommerce #respond input#submit,
  .woocommerce #respond .about-box input#submit,
  .about-box .woocommerce a.button,
  .woocommerce .about-box a.button,
  .about-box .woocommerce button.button,
  .woocommerce .about-box button.button,
  .about-box .woocommerce input.button,
  .woocommerce .about-box input.button,
  .about-box .woocommerce #place_order,
  .woocommerce .about-box #place_order,
  .about-box .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .about-box a.checkout-button,
  .about-box .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .about-box .button,
  .about-box .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .about-box input,
  .about-box .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .about-box a,
  .about-box .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .about-box a,
  .about-box .wp-block-button__link {
    font-size: 15px;
  }
}

.about-box .about-btn {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 5px;
}

.img-ho1 {
  overflow: visible;
  width: 60%;
  height: auto;

}

.img-ho1 img {
  transition: all 0.8s;
}

.img-ho1:after {
  content: "";
  width: 70%;
  height: 70%;
  position: absolute;
  left: 0px;
  top: 0;
  border: 1px solid;
  z-index: -1;
  border-color: #000 !important;
  transition: all 0.8s;
  border-radius: 50% 50% 0 0;
}

.img-ho1:hover img {
  transform: translate(10px, -10px);
}

.img-ho1:hover:after {
  left: -18px;
  top: 18px;
}

.about-box.style-1 {
  position: relative;
  z-index: 1;
  padding-right: 8rem;
}

@media only screen and (max-width: 1480px) {
  .about-box.style-1 {
    padding-right: 4rem;
  }
}

@media only screen and (max-width: 1199px) {
  .about-box.style-1 {
    padding-right: 0px;
  }
}

.about-box.style-1.right {
  padding-left: 8rem;
  padding-right: 0px;
}

@media only screen and (max-width: 1480px) {
  .about-box.style-1.right {
    padding-left: 4rem;
  }
}

@media only screen and (max-width: 1199px) {
  .about-box.style-1.right {
    padding-left: 0px;
  }
}

.about-box.style-1.right .dz-media {
  float: left;
}

.about-box.style-1.right .dz-media img {
  mask-position: left;
}

.about-box.style-1.right .animation-text {
  left: 0;
  transform: translate(10%, 90%);
}

@media only screen and (max-width: 991px) {
  .about-box.style-1.right .animation-text {
    transform: translate(10%, 70%);
  }
}

.about-box.style-1 .dz-media {
  width: 45vw;
  float: right;
}

@media only screen and (max-width: 1480px) {
  .about-box.style-1 .dz-media {
    width: 50vw;
  }
}

@media only screen and (max-width: 991px) {
  .about-box.style-1 .dz-media {
    width: 100%;
  }
}

.about-box.style-1 .dz-media img {
  width: 100%;
  mask-size: auto 100%;
  mask-repeat: no-repeat;
  height: 100%;
  object-fit: cover;
  mask-position: right;
}

@media only screen and (max-width: 991px) {
  .about-box.style-1 .dz-media img {
    mask-size: 100%;
  }
}

.about-box.style-1 .dz-media .media-contant {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
}

@media only screen and (max-width: 1680px) {
  .about-box.style-1 .dz-media .media-contant {
    width: 80%;
  }
}

.about-box.style-1 .dz-media .media-contant .title {
  color: #FFF;
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 1199px) {
  .about-box.style-1 .dz-media .media-contant .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .about-box.style-1 .dz-media .media-contant .title {
    font-size: 26px;
  }
}

.about-box.style-1 .dz-media .media-contant .sub-title {
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .about-box.style-1 .dz-media .media-contant .sub-title {
    font-size: 16px;
  }
}

.about-box.style-1 .animation-text {
  overflow: visible;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(35%, 90%);
}

@media only screen and (max-width: 991px) {
  .about-box.style-1 .animation-text {
    transform: translate(35%, 70%);
  }
}

.about-box.style-1 .animation-text text {
  text-transform: uppercase;
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #fff;
  font-size: 140px;
  font-weight: 600;
  font-family: var(--font-family-title);
  opacity: 0.5;
}

@media only screen and (max-width: 575px) {
  .about-box.style-1 .animation-text {
    display: none;
  }
}

@keyframes stroke {
  0% {
    fill: rgba(255, 255, 255, 0);
    stroke: rgb(255, 255, 255);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  70% {
    fill: rgba(255, 255, 255, 0);
    stroke: rgb(255, 255, 255);
  }

  80% {
    fill: rgba(255, 255, 255, 0);
    stroke: rgb(255, 255, 255);
    stroke-width: 3;
  }

  100% {
    fill: rgb(255, 255, 255);
    stroke: rgba(255, 255, 255, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.map-area {
  position: relative;
  z-index: 1;
  width: 1478px;
  height: 414px;
  margin-left: -9%;
  margin-top: 100px;
}

@media only screen and (max-width: 1480px) {
  .map-area {
    margin-left: -28%;
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 1400px) {
  .map-area {
    display: none;
  }
}

.map-area .map-line {
  position: absolute;
  top: 6.76%;
  width: 29.9%;
  left: 28.68%;
  height: 0;
  transition: all 2s;
  overflow: hidden;
  animation-name: maplineexample;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

@keyframes maplineexample {
  from {
    height: 0%;
  }

  to {
    height: 56.3%;
  }
}

/* .map-area .map-line.animated {
  height: 56.3%;
} */

.map-area .map-line img {
  width: 100%;
}

.map-area .loction-a {
  position: absolute;
  bottom: 39.22%;
  left: 29.55%;
  z-index: 99;
  opacity: 0;
  transition: 0.5s;
}

.map-area .loction-a:not(.animated) {
  animation: updown-2 1s ease 0.2s infinite;
  opacity: 1;
}

.map-area .loction-b {
  position: absolute;
  bottom: 92.8%;
  left: 56.4%;
  z-index: 99;
  opacity: 0;
  transition: 0.2s;
}

.map-area .loction-b:not(.animated) {
  animation: updown-2 1s ease 0.2s infinite;
  opacity: 1;
}

.map-area .loction-center {
  position: absolute;
  bottom: 71.8%;
  left: 44.5%;
  z-index: 99;
  transition: 0.4s;
}

.map-area .area-box1 {
  position: absolute;
  top: -15%;
  left: 13%;
  transform: rotate(-10.206deg) scale(1.5);
  width: 200px;
  z-index: 1;
  /* transition: 2.5s; */
  animation-name: box1example;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes box1example {
  from {
    transform: rotate(-10.206deg) scale(1.5);
  }

  to {
    transform: rotate(-10.206deg) scale(0.8);
  }
}

/* .map-area .area-box1.animated {
  transform: rotate(-10.206deg) scale(0.8);
} */

@media only screen and (max-width: 1480px) {
  .map-area .area-box1 {
    display: none;
  }
}

.map-area .area-box2 {
  position: absolute;
  top: 4%;
  left: 59%;
  transform: rotate(15.72deg) scale(1.5);
  width: 200px;
  z-index: 1;
  /* transition: 0.8s;
   */
  animation-name: box2example;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

/* .map-area .area-box2.animated {
 transform: rotate(15.72deg) scale(0.7);
} */

@keyframes box2example {
  from {
    transform: rotate(15.72deg) scale(1.5);
  }

  to {
    transform: rotate(15.72deg) scale(0.7);
  }
}

@media only screen and (max-width: 1480px) {
  .map-area .area-box2 {
    display: none;
  }
}

.map-area .area-box3 {
  position: absolute;
  bottom: 70%;
  left: 32%;
  transform: rotate(6.093deg) scale(1.5);
  width: 200px;
  z-index: 1;
  /* transition: 1s; */
  animation-name: box3example;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

/* .map-area .area-box3.animated {
  transform: rotate(6.093deg) scale(0.6);
  filter: blur(9px);
} */

@keyframes box3example {
  from {
    transform: rotate(6.093deg) scale(1.5);
  }

  to {
    transform: rotate(6.093deg) scale(0.6);
    filter: blur(9px);
  }
}

@media only screen and (max-width: 1480px) {
  .map-area .area-box3 {
    display: none;
  }
}

@keyframes updown-2 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0px);
  }
}

.testimonial-swiper .swiper-slide .about-box .about-img img {
  border-radius: 0 25% 0 0;
  transition: 0.5s;
  width: 100%;
}

.testimonial-swiper .swiper-slide.swiper-slide-active .about-box .about-img img {
  transition: 0.5s;
  border-radius: 25% 0 0 0;
}

.testimonial-swiper .swiper-slide.swiper-slide-next .about-box .about-img img {
  border-radius: 50% 50% 0 0;
  transition: 0.5s;
}

.about-style2 .section-head .title {
  margin-bottom: 27px;
  line-height: 1.3;
}

.about-style2 .section-head p {
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 1199px) {
  .about-style2 .section-head {
    margin-bottom: 25px;
  }

  .about-style2 .section-head .title {
    margin-bottom: 12px;
    font-size: 34px;
  }
}

@media only screen and (max-width: 991px) {
  .about-style2 .section-head .title {
    margin-bottom: 12px;
    font-size: 30px;
  }

  .about-style2 .section-head p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-style2 .section-head .title {
    font-size: 24px;
  }

  .about-style2 .section-head p {
    text-align: justify;
  }
}

@media only screen and (max-width: 575px) {
  .about-style2 .section-head .title {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .about-style2 .section-head p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style2 .sticky-top {
    position: unset;
  }
}

.about-style2 .about-thumb {
  text-align: center;
  position: relative;
  background: #F6F6F6;
  margin-right: 40px;
}

@media only screen and (max-width: 991px) {
  .about-style2 .about-thumb {
    margin-right: 0;
  }
}

.about-style2 .about-thumb img {
  border-radius: 20px;
}

.about-style2 .our-customer {
  position: absolute;
  bottom: 80px;
  right: 130px;
  width: 180px;
  padding: 18px 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

@media only screen and (max-width: 1199px) {
  .about-style2 .our-customer {
    bottom: 80px;
    right: 80px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .about-style2 .our-customer {
    bottom: -20px;
    right: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .about-style2 .our-customer {
    bottom: -10px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

.about-style2 .our-customer ul {
  display: flex;
  padding: 0 10px;
}

.about-style2 .our-customer .total-customer {
  background-color: #ffffff;
  border: 2px solid rgb(214, 214, 214);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title);
}

.about-style2 .our-customer .total-customer,
.about-style2 .our-customer .customer-image {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: -10px;
}

.about-style2 .our-customer .total-customer img,
.about-style2 .our-customer .customer-image img {
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.about-style2 .about-bx-detail {
  display: flex;
  align-items: center;
}

.about-style2 .about-bx-detail .about-bx-pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  border: 2px solid #fff;
}

.about-style2 .about-bx-detail .about-bx-pic img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.about-style2 .about-bx-detail .name {
  font-size: 22px;
  color: var(--title);
  margin-bottom: 0;
}

.about-style2 .about-bx-detail .position {
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  .about-style2 .about-bx-detail .about-bx-pic {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .about-style2 .about-bx-detail .name {
    font-size: 16px;
  }

  .about-style2 .about-bx-detail .position {
    font-size: 13px;
  }
}

.about-banner {
  min-height: 450px;
  position: relative;
  z-index: 1;
  background-size: cover;
}

@media only screen and (max-width: 991px) {
  .about-banner {
    min-height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .about-banner {
    min-height: 350px;
  }
}

.about-banner .about-info {
  background-color: var(--secondary);
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
  max-width: 520px;
  padding: 24px 33px 30px 30px;
  position: absolute;
  bottom: -100px;
  right: 16%;
  z-index: 999999;
  border-radius: 20px;
}

.about-banner .about-info .dz-title a {
  color: #ffffff;
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
}

.about-banner .about-info .text {
  color: #ffffff;
  font-size: 15px;
  font-weight: lighter;
}

@media only screen and (max-width: 1480px) {
  .about-banner .about-info {
    right: 10%;
  }
}

@media only screen and (max-width: 1199px) {
  .about-banner .about-info {
    position: unset;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    box-shadow: unset;
  }
}

@media only screen and (max-width: 767px) {
  .about-banner .about-info {
    padding: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .about-banner .about-info .dz-title a {
    font-size: 24px;
  }

  .about-banner .about-info .text {
    font-size: 14px;
  }
}

.about-sale .about-content .title {
  font-weight: 500;
  font-size: 80px;
  margin-bottom: 0;
  line-height: 1;
}

@media only screen and (max-width: 1199px) {
  .about-sale .about-content .title {
    font-size: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .about-sale .about-content .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .about-sale .about-content .title {
    font-size: 40px;
  }
}

.about-sale .about-content .text {
  font-weight: 500;
  font-size: 20px;
  color: var(--title);
  white-space: nowrap;
  text-transform: capitalize;
}

@media only screen and (max-width: 1199px) {
  .about-sale .about-content .text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .about-sale .about-content .text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .about-sale .about-content .text {
    font-size: 15px;
  }
}

.about-style3 .section-head {
  margin-bottom: 50px;
}

.about-style3 .section-head .title {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 700;
}

.about-style3 .section-head p {
  line-height: 1.7;
  color: #5E626F;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .about-style3 .section-head p {
    text-align: justify;
  }
}

@media only screen and (max-width: 575px) {
  .about-style3 .section-head .title {
    font-size: 24px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style3 .section-head {
    margin-bottom: 20px;
  }
}

.about-style3 .about-thumb {
  position: relative;
  overflow: hidden;
  height: auto;
  border-radius: 20px;
}

.exp-head .counter-num h2,
.exp-head .counter-num .h2 {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
}

@media only screen and (max-width: 1199px) {

  .exp-head .counter-num h2,
  .exp-head .counter-num .h2 {
    font-size: 52px;
  }
}

@media only screen and (max-width: 767px) {

  .exp-head .counter-num h2,
  .exp-head .counter-num .h2 {
    font-size: 44px;
  }

  .exp-head .counter-title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {

  .exp-head .counter-num h2,
  .exp-head .counter-num .h2 {
    font-size: 34px;
  }

  .exp-head .counter-title {
    font-size: 14px;
  }
}

.about-style4 {
  background-color: #FFEDD4;
  position: relative;
  z-index: 1;
  padding-top: 75px;
}

@media only screen and (max-width: 767px) {
  .about-style4 {
    padding-top: 40px;
  }
}

.about-style4 .section-head {
  margin-bottom: 20px;
}

.about-style4 .section-head .title {
  font-size: 45px;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 1199px) {
  .about-style4 .section-head {
    margin-bottom: 12px;
  }

  .about-style4 .section-head .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style4 .section-head {
    margin-bottom: 6px;
  }

  .about-style4 .section-head .title {
    font-size: 30px;
  }
}

.about-style4 .about-content {
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 15px 50px 0px rgba(94, 141, 118, 0.1);
  padding: 40px;
}

.about-style4 .about-content .para-text {
  color: var(--secondary);
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .about-style4 .about-content {
    padding: 25px;
  }

  .about-style4 .about-content .para-text {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style4 .about-content .para-text {
    line-height: 25px;
  }
}

.about-style4 .side-content {
  z-index: 1;
  background: transparent;
  position: relative;
  padding-right: 120px;
}

@media only screen and (max-width: 1199px) {
  .about-style4 .side-content {
    padding-right: 0px;
  }
}

.about-style4 .side-content .about-thumb {
  text-align: right;
  position: relative;
  z-index: 0;
  margin: auto;
  margin-top: -100px;
}

@media only screen and (max-width: 991px) {
  .about-style4 .side-content .about-thumb {
    margin-top: 0;
  }
}

.about-style4 .side-content:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: -14%;
  width: 68%;
  height: 68%;
  background: linear-gradient(90deg, #FFFAF3 1.69%, rgba(255, 250, 243, 0) 103.98%);
  border-radius: 50% 50% 0px 0px;
  z-index: -2;
}

.about-style4 img.line {
  position: absolute;
  top: 0;
  left: 0%;
  width: 50%;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .about-style4 img.line {
    display: none;
  }
}

.about-style4 .our-customer {
  position: absolute;
  bottom: 140px;
  right: 90px;
  width: 180px;
  padding: 18px 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1199px) {
  .about-style4 .our-customer {
    bottom: 80px;
    right: 80px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .about-style4 .our-customer {
    bottom: -20px;
    right: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .about-style4 .our-customer {
    bottom: -10px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}

.about-style4 .our-customer ul {
  display: flex;
  padding: 0 10px;
}

.about-style4 .our-customer .total-customer {
  background-color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--title);
}

.about-style4 .our-customer .total-customer,
.about-style4 .our-customer .customer-image {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: -10px;
}

.about-style4 .our-customer .total-customer img,
.about-style4 .our-customer .customer-image img {
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.about-style4 .about-bx-detail {
  display: flex;
  align-items: center;
}

.about-style4 .about-bx-detail .about-bx-pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  border: 2px solid #fff;
}

@media only screen and (max-width: 1199px) {
  .about-style4 .about-bx-detail .about-bx-pic {
    margin-right: 10px;
  }
}

.about-style4 .about-bx-detail .about-bx-pic img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.about-style4 .about-bx-detail .name {
  font-size: 22px;
  color: var(--title);
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .about-style4 .about-bx-detail .name {
    font-size: 18px;
  }
}

.about-style4 .about-bx-detail .position {
  font-size: 14px;
}

@media only screen and (max-width: 1199px) {
  .about-style4 .about-bx-detail .position {
    font-size: 12px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style4 .about-bx-detail .about-bx-pic {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .about-style4 .about-bx-detail .name {
    font-size: 14px;
  }

  .about-style4 .about-bx-detail .position {
    font-size: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .about-style4 .about-thumb {
    width: 325px;
    margin-left: auto;
    margin-right: auto;
  }

  .about-style4 .about-thumb:after {
    width: 325px;
    height: 325px;
  }
}

.dz-carticon,
.dz-wishicon {
  padding: 0;
}

.dz-carticon .dz-cart-check,
.dz-carticon .dz-heart-fill,
.dz-wishicon .dz-cart-check,
.dz-wishicon .dz-heart-fill {
  display: none;
}

.active.btn.dz-carticon,
.woocommerce #respond input.active.dz-carticon#submit,
.woocommerce a.active.dz-carticon.button,
.woocommerce button.active.dz-carticon.button,
.woocommerce input.active.dz-carticon.button,
.woocommerce .active.dz-carticon#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-carticon.checkout-button,
.woocommerce-cart-form .table tr .active.dz-carticon.button,
.woocommerce #review_form #respond .form-submit input.active.dz-carticon,
.woocommerce .widget_shopping_cart .buttons a.active.dz-carticon,
.woocommerce.widget_shopping_cart .buttons a.active.dz-carticon,
.active.dz-carticon.wp-block-button__link,
.active.btn.dz-wishicon,
.woocommerce #respond input.active.dz-wishicon#submit,
.woocommerce a.active.dz-wishicon.button,
.woocommerce button.active.dz-wishicon.button,
.woocommerce input.active.dz-wishicon.button,
.woocommerce .active.dz-wishicon#place_order,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-wishicon.checkout-button,
.woocommerce-cart-form .table tr .active.dz-wishicon.button,
.woocommerce #review_form #respond .form-submit input.active.dz-wishicon,
.woocommerce .widget_shopping_cart .buttons a.active.dz-wishicon,
.woocommerce.widget_shopping_cart .buttons a.active.dz-wishicon,
.active.dz-wishicon.wp-block-button__link {
  background-color: var(--primary) !important;
}

.active.btn.dz-carticon .dz-cart-out,
.woocommerce #respond input.active.dz-carticon#submit .dz-cart-out,
.woocommerce a.active.dz-carticon.button .dz-cart-out,
.woocommerce button.active.dz-carticon.button .dz-cart-out,
.woocommerce input.active.dz-carticon.button .dz-cart-out,
.woocommerce .active.dz-carticon#place_order .dz-cart-out,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-carticon.checkout-button .dz-cart-out,
.woocommerce-cart-form .table tr .active.dz-carticon.button .dz-cart-out,
.woocommerce #review_form #respond .form-submit input.active.dz-carticon .dz-cart-out,
.woocommerce .widget_shopping_cart .buttons a.active.dz-carticon .dz-cart-out,
.woocommerce.widget_shopping_cart .buttons a.active.dz-carticon .dz-cart-out,
.active.dz-carticon.wp-block-button__link .dz-cart-out,
.active.btn.dz-carticon .dz-heart,
.woocommerce #respond input.active.dz-carticon#submit .dz-heart,
.woocommerce a.active.dz-carticon.button .dz-heart,
.woocommerce button.active.dz-carticon.button .dz-heart,
.woocommerce input.active.dz-carticon.button .dz-heart,
.woocommerce .active.dz-carticon#place_order .dz-heart,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-carticon.checkout-button .dz-heart,
.woocommerce-cart-form .table tr .active.dz-carticon.button .dz-heart,
.woocommerce #review_form #respond .form-submit input.active.dz-carticon .dz-heart,
.woocommerce .widget_shopping_cart .buttons a.active.dz-carticon .dz-heart,
.woocommerce.widget_shopping_cart .buttons a.active.dz-carticon .dz-heart,
.active.dz-carticon.wp-block-button__link .dz-heart,
.active.btn.dz-wishicon .dz-cart-out,
.woocommerce #respond input.active.dz-wishicon#submit .dz-cart-out,
.woocommerce a.active.dz-wishicon.button .dz-cart-out,
.woocommerce button.active.dz-wishicon.button .dz-cart-out,
.woocommerce input.active.dz-wishicon.button .dz-cart-out,
.woocommerce .active.dz-wishicon#place_order .dz-cart-out,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-wishicon.checkout-button .dz-cart-out,
.woocommerce-cart-form .table tr .active.dz-wishicon.button .dz-cart-out,
.woocommerce #review_form #respond .form-submit input.active.dz-wishicon .dz-cart-out,
.woocommerce .widget_shopping_cart .buttons a.active.dz-wishicon .dz-cart-out,
.woocommerce.widget_shopping_cart .buttons a.active.dz-wishicon .dz-cart-out,
.active.dz-wishicon.wp-block-button__link .dz-cart-out,
.active.btn.dz-wishicon .dz-heart,
.woocommerce #respond input.active.dz-wishicon#submit .dz-heart,
.woocommerce a.active.dz-wishicon.button .dz-heart,
.woocommerce button.active.dz-wishicon.button .dz-heart,
.woocommerce input.active.dz-wishicon.button .dz-heart,
.woocommerce .active.dz-wishicon#place_order .dz-heart,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-wishicon.checkout-button .dz-heart,
.woocommerce-cart-form .table tr .active.dz-wishicon.button .dz-heart,
.woocommerce #review_form #respond .form-submit input.active.dz-wishicon .dz-heart,
.woocommerce .widget_shopping_cart .buttons a.active.dz-wishicon .dz-heart,
.woocommerce.widget_shopping_cart .buttons a.active.dz-wishicon .dz-heart,
.active.dz-wishicon.wp-block-button__link .dz-heart {
  display: none;
}

.active.btn.dz-carticon .dz-cart-check,
.woocommerce #respond input.active.dz-carticon#submit .dz-cart-check,
.woocommerce a.active.dz-carticon.button .dz-cart-check,
.woocommerce button.active.dz-carticon.button .dz-cart-check,
.woocommerce input.active.dz-carticon.button .dz-cart-check,
.woocommerce .active.dz-carticon#place_order .dz-cart-check,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-carticon.checkout-button .dz-cart-check,
.woocommerce-cart-form .table tr .active.dz-carticon.button .dz-cart-check,
.woocommerce #review_form #respond .form-submit input.active.dz-carticon .dz-cart-check,
.woocommerce .widget_shopping_cart .buttons a.active.dz-carticon .dz-cart-check,
.woocommerce.widget_shopping_cart .buttons a.active.dz-carticon .dz-cart-check,
.active.dz-carticon.wp-block-button__link .dz-cart-check,
.active.btn.dz-carticon .dz-heart-fill,
.woocommerce #respond input.active.dz-carticon#submit .dz-heart-fill,
.woocommerce a.active.dz-carticon.button .dz-heart-fill,
.woocommerce button.active.dz-carticon.button .dz-heart-fill,
.woocommerce input.active.dz-carticon.button .dz-heart-fill,
.woocommerce .active.dz-carticon#place_order .dz-heart-fill,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-carticon.checkout-button .dz-heart-fill,
.woocommerce-cart-form .table tr .active.dz-carticon.button .dz-heart-fill,
.woocommerce #review_form #respond .form-submit input.active.dz-carticon .dz-heart-fill,
.woocommerce .widget_shopping_cart .buttons a.active.dz-carticon .dz-heart-fill,
.woocommerce.widget_shopping_cart .buttons a.active.dz-carticon .dz-heart-fill,
.active.dz-carticon.wp-block-button__link .dz-heart-fill,
.active.btn.dz-wishicon .dz-cart-check,
.woocommerce #respond input.active.dz-wishicon#submit .dz-cart-check,
.woocommerce a.active.dz-wishicon.button .dz-cart-check,
.woocommerce button.active.dz-wishicon.button .dz-cart-check,
.woocommerce input.active.dz-wishicon.button .dz-cart-check,
.woocommerce .active.dz-wishicon#place_order .dz-cart-check,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-wishicon.checkout-button .dz-cart-check,
.woocommerce-cart-form .table tr .active.dz-wishicon.button .dz-cart-check,
.woocommerce #review_form #respond .form-submit input.active.dz-wishicon .dz-cart-check,
.woocommerce .widget_shopping_cart .buttons a.active.dz-wishicon .dz-cart-check,
.woocommerce.widget_shopping_cart .buttons a.active.dz-wishicon .dz-cart-check,
.active.dz-wishicon.wp-block-button__link .dz-cart-check,
.active.btn.dz-wishicon .dz-heart-fill,
.woocommerce #respond input.active.dz-wishicon#submit .dz-heart-fill,
.woocommerce a.active.dz-wishicon.button .dz-heart-fill,
.woocommerce button.active.dz-wishicon.button .dz-heart-fill,
.woocommerce input.active.dz-wishicon.button .dz-heart-fill,
.woocommerce .active.dz-wishicon#place_order .dz-heart-fill,
.woocommerce-cart .wc-proceed-to-checkout a.active.dz-wishicon.checkout-button .dz-heart-fill,
.woocommerce-cart-form .table tr .active.dz-wishicon.button .dz-heart-fill,
.woocommerce #review_form #respond .form-submit input.active.dz-wishicon .dz-heart-fill,
.woocommerce .widget_shopping_cart .buttons a.active.dz-wishicon .dz-heart-fill,
.woocommerce.widget_shopping_cart .buttons a.active.dz-wishicon .dz-heart-fill,
.active.dz-wishicon.wp-block-button__link .dz-heart-fill {
  display: inline-block;
}

.product-style1 .product-box {
  background-color: #eee;
}

.product-box {
  position: relative;
  overflow: hidden;
}

.product-box .product-media {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.product-box.style-1 {
  height: 590px;
}

@media only screen and (max-width: 575px) {
  .product-box.style-1 {
    height: 300px;
  }
}

.product-box .product-image {
  text-align: center;
  height: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.product-box .product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-box .product-content {
  bottom: 0;
  display: flex;
  align-items: end;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  right: 0;
  padding: 40px;
  z-index: 1;
}

.product-box .product-content .product-name {
  font-size: 2.5rem;
  font-weight: 700;
}

.product-box .product-content .offer {
  font-size: 16px;
}

@media only screen and (max-width: 1199px) {
  .product-box .product-content {
    padding: 20px 30px;
  }

  .product-box .product-content .product-name {
    font-size: 2rem;
    margin-bottom: 5px;
    line-height: 1.3;
  }

  .product-box .product-content .offer {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .product-box .product-content {
    padding: 15px 18px;
  }

  .product-box .product-content .product-name {
    font-size: 1.4rem;
    margin-bottom: 5px;
    line-height: 1.2;
  }

  .product-box .product-content .btn,
  .product-box .product-content .woocommerce #respond input#submit,
  .woocommerce #respond .product-box .product-content input#submit,
  .product-box .product-content .woocommerce a.button,
  .woocommerce .product-box .product-content a.button,
  .product-box .product-content .woocommerce button.button,
  .woocommerce .product-box .product-content button.button,
  .product-box .product-content .woocommerce input.button,
  .woocommerce .product-box .product-content input.button,
  .product-box .product-content .woocommerce #place_order,
  .woocommerce .product-box .product-content #place_order,
  .product-box .product-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .product-box .product-content a.checkout-button,
  .product-box .product-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .product-box .product-content .button,
  .product-box .product-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .product-box .product-content input,
  .product-box .product-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .product-box .product-content a,
  .product-box .product-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .product-box .product-content a,
  .product-box .product-content .wp-block-button__link {
    padding: 8px 16px;
    font-size: 14px;
  }

  .product-box .product-content .offer {
    font-size: 14px;
  }
}

.product-box.style-2 {
  height: 400px;
  border-radius: 20px;
}

@media only screen and (max-width: 575px) {
  .product-box.style-2 {
    height: 250px;
  }
}

.product-box.style-2 .product-content {
  display: flex;
  align-items: end;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  padding: 35px 40px;
  flex-direction: column;
  border-radius: 20px;
  z-index: 1;
}

@media only screen and (max-width: 1680px) {
  .product-box.style-2 .product-content {
    padding: 25px;
  }
}

.product-box.style-2 .product-content .main-content {
  width: min-content;
}

@media only screen and (max-width: 767px) {
  .product-box.style-2 .product-content .main-content {
    width: auto;
  }
}

.product-box.style-2 .product-content .product-name {
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  width: 80%;
  line-height: 1.3;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .product-box.style-2 .product-content .product-name {
    width: 100%;
    font-size: 35px;
  }
}

.product-box.style-2 .product-content .offer {
  padding: 5px 20px;
  background: #fff;
  font-size: 16px;
  font-weight: 800;
  color: var(--title);
  margin-bottom: 15px;
  display: inline-block;
  text-transform: uppercase;
  width: max-content;
  line-height: 1.2;
}

.product-box.style-2 .product-content .sub-title1 {
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 7.2px;
  text-transform: uppercase;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .product-box.style-2 .product-content .sub-title1 {
    font-size: 28px;
  }
}

.product-box.style-2 .product-content .sub-title2 {
  font-size: 35px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .product-box.style-2 .product-content .sub-title2 {
    font-size: 28px;
  }
}

.product-box.style-2 .product-content .bg-title {
  font-size: 95px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  display: block;
  color: var(--title);
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .product-box.style-2 .product-content .bg-title {
    font-size: 50px;
  }
}

.product-box.style-2 .product-content .year {
  font-size: 80px;
  font-weight: 700;
  color: var(--title);
  display: block;
  line-height: 1;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .product-box.style-2 .product-content .year {
    font-size: 50px;
  }
}

.product-box.style-3 {
  height: 280px;
}

@media only screen and (max-width: 575px) {
  .product-box.style-3 {
    height: 100%;
    min-height: 200px;
  }
}

@media only screen and (max-width: 360px) {
  .product-box.style-3 {
    min-height: 160px;
  }
}

.product-box.style-3 .product-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: absolute;
  width: 60%;
  height: 100%;
  right: 0px;
  padding: 35px 40px;
  flex-direction: column;
  z-index: 1;
}

.product-box.style-3 .product-content .product-name {
  font-size: 1.75rem;
  font-weight: 700;
  max-width: 300px;
}

@media only screen and (max-width: 1199px) {
  .product-box.style-3 .product-content {
    padding: 20px 30px;
  }

  .product-box.style-3 .product-content .product-name {
    font-size: 1.4rem;
    line-height: 1.3;
  }

  .product-box.style-3 .product-content .btn,
  .product-box.style-3 .product-content .woocommerce #respond input#submit,
  .woocommerce #respond .product-box.style-3 .product-content input#submit,
  .product-box.style-3 .product-content .woocommerce a.button,
  .woocommerce .product-box.style-3 .product-content a.button,
  .product-box.style-3 .product-content .woocommerce button.button,
  .woocommerce .product-box.style-3 .product-content button.button,
  .product-box.style-3 .product-content .woocommerce input.button,
  .woocommerce .product-box.style-3 .product-content input.button,
  .product-box.style-3 .product-content .woocommerce #place_order,
  .woocommerce .product-box.style-3 .product-content #place_order,
  .product-box.style-3 .product-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .product-box.style-3 .product-content a.checkout-button,
  .product-box.style-3 .product-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .product-box.style-3 .product-content .button,
  .product-box.style-3 .product-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .product-box.style-3 .product-content input,
  .product-box.style-3 .product-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .product-box.style-3 .product-content a,
  .product-box.style-3 .product-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .product-box.style-3 .product-content a,
  .product-box.style-3 .product-content .wp-block-button__link {
    padding: 8px 16px;
    font-size: 14px;
  }

  .product-box.style-3 .product-content .offer {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .product-box.style-3 .product-content {
    padding: 15px 18px;
  }

  .product-box.style-3 .product-content .product-name {
    font-size: 1.2rem;
    margin-bottom: 5px;
    line-height: 1.1;
    max-width: 170px;
  }

  .product-box.style-3 .product-content .product-name br {
    display: none;
  }

  .product-box.style-3 .product-content .btn,
  .product-box.style-3 .product-content .woocommerce #respond input#submit,
  .woocommerce #respond .product-box.style-3 .product-content input#submit,
  .product-box.style-3 .product-content .woocommerce a.button,
  .woocommerce .product-box.style-3 .product-content a.button,
  .product-box.style-3 .product-content .woocommerce button.button,
  .woocommerce .product-box.style-3 .product-content button.button,
  .product-box.style-3 .product-content .woocommerce input.button,
  .woocommerce .product-box.style-3 .product-content input.button,
  .product-box.style-3 .product-content .woocommerce #place_order,
  .woocommerce .product-box.style-3 .product-content #place_order,
  .product-box.style-3 .product-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .product-box.style-3 .product-content a.checkout-button,
  .product-box.style-3 .product-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .product-box.style-3 .product-content .button,
  .product-box.style-3 .product-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .product-box.style-3 .product-content input,
  .product-box.style-3 .product-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .product-box.style-3 .product-content a,
  .product-box.style-3 .product-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .product-box.style-3 .product-content a,
  .product-box.style-3 .product-content .wp-block-button__link {
    padding: 6px 14px;
    font-size: 13px;
  }

  .product-box.style-3 .product-content .offer {
    font-size: 13px;
  }
}

.product-box.style-4 {
  height: 550px;
  max-height: 550px;
}

@media only screen and (max-width: 1680px) {
  .product-box.style-4 {
    height: 500px;
    max-height: 500px;
  }
}

@media only screen and (max-width: 1199px) {
  .product-box.style-4 {
    height: 400px;
    max-height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .product-box.style-4 {
    height: 280px;
    max-height: 280px;
  }
}

.product-box.style-4 .product-content {
  display: flex;
  align-items: start;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  padding: 70px;
  flex-direction: column;
  z-index: 1;
}

@media only screen and (max-width: 1680px) {
  .product-box.style-4 .product-content {
    padding: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .product-box.style-4 .product-content {
    padding: 15px;
  }
}

.product-box.style-4 .product-content .product-name {
  font-weight: 600;
  max-width: 540px;
  line-height: 1.1;
  margin-bottom: 30px;
  font-size: 60px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1199px) {
  .product-box.style-4 .product-content .product-name {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .product-box.style-4 .product-content .product-name {
    font-size: 30px;
  }
}

.product-box.style-4 .sale-box {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translate(50%, -50%);
  fill: #fff;
  text-align: center;
  padding: 65px;
}

@media only screen and (max-width: 1199px) {
  .product-box.style-4 .sale-box {
    padding: 55px;
  }
}

@media only screen and (max-width: 1680px) {
  .product-box.style-4 .sale-box {
    right: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .product-box.style-4 .sale-box {
    padding: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .product-box.style-4 .sale-box .badge.style-1 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 575px) {

  .product-box.style-4 .sale-box .btn,
  .product-box.style-4 .sale-box .woocommerce #respond input#submit,
  .woocommerce #respond .product-box.style-4 .sale-box input#submit,
  .product-box.style-4 .sale-box .woocommerce a.button,
  .woocommerce .product-box.style-4 .sale-box a.button,
  .product-box.style-4 .sale-box .woocommerce button.button,
  .woocommerce .product-box.style-4 .sale-box button.button,
  .product-box.style-4 .sale-box .woocommerce input.button,
  .woocommerce .product-box.style-4 .sale-box input.button,
  .product-box.style-4 .sale-box .woocommerce #place_order,
  .woocommerce .product-box.style-4 .sale-box #place_order,
  .product-box.style-4 .sale-box .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .product-box.style-4 .sale-box a.checkout-button,
  .product-box.style-4 .sale-box .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .product-box.style-4 .sale-box .button,
  .product-box.style-4 .sale-box .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .product-box.style-4 .sale-box input,
  .product-box.style-4 .sale-box .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .product-box.style-4 .sale-box a,
  .product-box.style-4 .sale-box .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .product-box.style-4 .sale-box a,
  .product-box.style-4 .sale-box .wp-block-button__link {
    padding: 8px 16px;
    font-size: 13px;
  }
}

.product-box.style-4 .sale-box .sale-name {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 8px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1199px) {
  .product-box.style-4 .sale-box .sale-name {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-box.style-4 .sale-box .sale-name {
    font-size: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .product-box.style-4 .sale-box .sale-name {
    font-size: 16px;
  }
}

.product-box.style-4 .sale-box .sale-name span {
  font-size: 100px;
  font-weight: 600;
  line-height: 1;
  display: block;
  letter-spacing: 0;
}

@media only screen and (max-width: 1199px) {
  .product-box.style-4 .sale-box .sale-name span {
    font-size: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .product-box.style-4 .sale-box .sale-name span {
    font-size: 50px;
  }
}

.product-box.style-4 .sale-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
}

.shop-card {
  position: relative;
  z-index: 1;
  height: 100%;
}

.shop-card .dz-media {
  border-radius: 20px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.shop-card .dz-media img {
  border-radius: 20px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.shop-card:hover .dz-media img {
  transform: translateY(-20px);
}

.shop-card:hover .shop-meta.top {
  position: absolute;
  top: 30px;
  right: 30px;
}

.shop-card:hover .shop-meta .quick-view {
  bottom: -4px;
  border: 4px solid #fffaf3;
}

.shop-card:hover .shop-meta .btn.btn-rounded,
.shop-card:hover .shop-meta .woocommerce #respond input.btn-rounded#submit,
.woocommerce #respond .shop-card:hover .shop-meta input.btn-rounded#submit,
.shop-card:hover .shop-meta .woocommerce a.btn-rounded.button,
.woocommerce .shop-card:hover .shop-meta a.btn-rounded.button,
.shop-card:hover .shop-meta .woocommerce button.btn-rounded.button,
.woocommerce .shop-card:hover .shop-meta button.btn-rounded.button,
.shop-card:hover .shop-meta .woocommerce input.btn-rounded.button,
.woocommerce .shop-card:hover .shop-meta input.btn-rounded.button,
.shop-card:hover .shop-meta .woocommerce .btn-rounded#place_order,
.woocommerce .shop-card:hover .shop-meta .btn-rounded#place_order,
.shop-card:hover .shop-meta .woocommerce-cart .wc-proceed-to-checkout a.btn-rounded.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .shop-card:hover .shop-meta a.btn-rounded.checkout-button,
.shop-card:hover .shop-meta .woocommerce-cart-form .table tr .btn-rounded.button,
.woocommerce-cart-form .table tr .shop-card:hover .shop-meta .btn-rounded.button,
.shop-card:hover .shop-meta .woocommerce #review_form #respond .form-submit input.btn-rounded,
.woocommerce #review_form #respond .form-submit .shop-card:hover .shop-meta input.btn-rounded,
.shop-card:hover .shop-meta .woocommerce .widget_shopping_cart .buttons a.btn-rounded,
.woocommerce .widget_shopping_cart .buttons .shop-card:hover .shop-meta a.btn-rounded,
.shop-card:hover .shop-meta .woocommerce.widget_shopping_cart .buttons a.btn-rounded,
.woocommerce.widget_shopping_cart .buttons .shop-card:hover .shop-meta a.btn-rounded,
.shop-card:hover .shop-meta .btn-rounded.wp-block-button__link {
  bottom: -4px;
}

@media only screen and (max-width: 575px) {

  .shop-card:hover .shop-meta .btn.btn-rounded,
  .shop-card:hover .shop-meta .woocommerce #respond input.btn-rounded#submit,
  .woocommerce #respond .shop-card:hover .shop-meta input.btn-rounded#submit,
  .shop-card:hover .shop-meta .woocommerce a.btn-rounded.button,
  .woocommerce .shop-card:hover .shop-meta a.btn-rounded.button,
  .shop-card:hover .shop-meta .woocommerce button.btn-rounded.button,
  .woocommerce .shop-card:hover .shop-meta button.btn-rounded.button,
  .shop-card:hover .shop-meta .woocommerce input.btn-rounded.button,
  .woocommerce .shop-card:hover .shop-meta input.btn-rounded.button,
  .shop-card:hover .shop-meta .woocommerce .btn-rounded#place_order,
  .woocommerce .shop-card:hover .shop-meta .btn-rounded#place_order,
  .shop-card:hover .shop-meta .woocommerce-cart .wc-proceed-to-checkout a.btn-rounded.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .shop-card:hover .shop-meta a.btn-rounded.checkout-button,
  .shop-card:hover .shop-meta .woocommerce-cart-form .table tr .btn-rounded.button,
  .woocommerce-cart-form .table tr .shop-card:hover .shop-meta .btn-rounded.button,
  .shop-card:hover .shop-meta .woocommerce #review_form #respond .form-submit input.btn-rounded,
  .woocommerce #review_form #respond .form-submit .shop-card:hover .shop-meta input.btn-rounded,
  .shop-card:hover .shop-meta .woocommerce .widget_shopping_cart .buttons a.btn-rounded,
  .woocommerce .widget_shopping_cart .buttons .shop-card:hover .shop-meta a.btn-rounded,
  .shop-card:hover .shop-meta .woocommerce.widget_shopping_cart .buttons a.btn-rounded,
  .woocommerce.widget_shopping_cart .buttons .shop-card:hover .shop-meta a.btn-rounded,
  .shop-card:hover .shop-meta .btn-rounded.wp-block-button__link {
    bottom: -15px;
  }
}

.shop-card .dz-content {
  text-align: start;
  background-color: transparent;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.shop-card .dz-content .title {
  margin-bottom: 0;
  font-weight: 600;
  text-transform: capitalize;
  width: 70%;
}

@media only screen and (max-width: 1199px) {
  .shop-card .dz-content .title {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .shop-card .dz-content .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-card .dz-content .title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {
  .shop-card .dz-content {
    padding: 15px 12px;
  }
}

.shop-card .product-tag {
  position: absolute;
  top: 20px;
  left: 20px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .shop-card .product-tag {
    top: 10px;
    left: 10px;
  }
}

.shop-card .product-tag .badge {
  padding: 5px 15px;
  background-color: white;
  color: var(--title);
  font-family: var(--font-family-title);
  font-size: 12px;
  min-width: 22px;
  min-height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  border-radius: 30px;
  transition: 0.5s;
}

@media only screen and (max-width: 575px) {
  .shop-card .product-tag .badge {
    padding: 2px 8px;
    font-size: 10px;
  }
}

.shop-card.style-2 {
  background: linear-gradient(180deg, #FFF -30.2%, rgba(255, 255, 255, 0) 92.95%);
  filter: drop-shadow(5px -15px 30px rgba(82, 48, 0, 0.13));
  margin-top: 50px;
  border-radius: 15px 15px 0 0;
  box-shadow: 5px -20px 30px 0 rgba(82, 48, 0, 0.13);
}

.shop-card.style-2 .dz-media {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  overflow: visible;
  padding: 20px;
  padding-bottom: 0;
}

.shop-card.style-2 .dz-media img {
  margin-top: -70px;
}

.shop-card.style-2:hover .dz-media {
  transform: translateY(-10px);
}

.shop-card.style-2 .dz-content {
  padding: 25px 20px;
  align-items: center;
}

.shop-card.style-2 .dz-content .title {
  margin-bottom: 0;
  font-weight: 500;
  width: auto;
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {
  .shop-card.style-2 .dz-content .title {
    font-size: 16px;
  }
}

.shop-card.style-2 .dz-content .sale-title {
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.shop-card.style-2 .dz-content .price {
  font-size: 18px;
  font-weight: 600;
  text-align: end;
}

.shop-card.style-2 .dz-content .price del {
  font-size: 14px;
  font-weight: 400;
  display: block;
  margin: 0;
}

@media only screen and (max-width: 1199px) {
  .shop-card.style-2 .dz-content {
    padding: 15px 12px;
  }
}

.shop-card.style-3 .dz-media {
  border-radius: 20px;
}

.shop-card.style-3 .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.shop-card.style-3:hover .dz-media {
  overflow: hidden;
  transform: translateY(0px);
}

.shop-card.style-3:hover .dz-media img {
  transform: scale(1.2);
}

.shop-card.style-3 .dz-content {
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: calc(100% - 40px);
  transform: translateX(-50%);
  padding: 10px;
  align-items: center;
  border: 1px solid #000;
  border-radius: 12px;
  background: #fff;
}

.shop-card.style-3 .dz-content .title {
  margin-bottom: 0;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .shop-card.style-3 .dz-content .title {
    font-size: 16px;
  }
}

.shop-card.style-3 .dz-content .sale-title {
  color: var(--primary);
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}

.shop-card.style-3 .dz-content .price {
  font-size: 16px;
  font-weight: 600;
  text-align: end;
}

.shop-card.style-3 .dz-content .price del {
  font-size: 12px;
  font-weight: 400;
  display: block;
  margin: 0;
}

@media only screen and (max-width: 1199px) {
  .shop-card.style-3 .dz-content {
    width: calc(100% - 20px);
  }
}

.shop-card.style-3 .sale-badge {
  position: absolute;
  top: -45px;
  right: -45px;
  width: 115px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: var(--title);
  line-height: 1.2;
  font-size: 18px;
  text-align: center;
}

.shop-card.style-3 .sale-badge img {
  position: absolute;
  z-index: -1;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 767px) {
  .shop-card.style-3 .sale-badge {
    display: none;
  }
}

.shop-card.style-4 {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 15px;
  padding: 15px 17px;
  box-shadow: 5px 15px 30px 0px rgba(82, 48, 0, 0.13);
}

@media only screen and (max-width: 575px) {
  .shop-card.style-4 {
    padding: 10px;
  }
}

.shop-card.style-4 .dz-media {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 15px;
  width: 150px;
  height: 165px;
  min-width: 150px;
  margin-right: 15px;
}

.shop-card.style-4 .dz-media img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0;
}

@media only screen and (max-width: 575px) {
  .shop-card.style-4 .dz-media {
    width: 120px;
    height: 140px;
    min-width: 120px;
    margin-right: 10px;
  }
}

.shop-card.style-4:hover .dz-media img {
  transform: scale(1.2);
}

.shop-card.style-4 .dz-content {
  background: transparent;
  text-align: start;
  padding: 0;
  flex-direction: column;
}

.shop-card.style-4 .dz-content .title {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .shop-card.style-4 .dz-content .title {
    font-size: 16px;
  }
}

.shop-card.style-4 .dz-content .sale-title {
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
}

.shop-card.style-4 .dz-content .price {
  font-size: 20px;
  font-weight: 600;
}

.shop-card.style-4 .dz-content .price del {
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}

.shop-card.style-4 .dz-content .review {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 400;
}

.shop-card.style-4 .dz-content .review i {
  color: #FFA048;
  margin-right: 5px;
}

.shop-card.style-5 .dz-media {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 20px;
  margin-bottom: 15px;
}

.shop-card.style-5:hover .dz-media img {
  transform: scale(1.2);
}

.shop-card.style-5 .dz-content {
  padding: 0px;
}

.shop-card.style-5 .dz-content .title {
  margin-bottom: 0;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .shop-card.style-5 .dz-content .title {
    font-size: 16px;
  }
}

.shop-card.style-5 .dz-content .sale-title {
  color: var(--primary);
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}

.shop-card.style-5 .dz-content .price {
  font-size: 16px;
  font-weight: 600;
  text-align: end;
}

.shop-card.style-5 .dz-content .price del {
  font-size: 12px;
  font-weight: 400;
  display: block;
  margin: 0;
  padding: 5px 0;
}

@media only screen and (max-width: 991px) {
  .shop-card.style-6 {
    margin-bottom: 30px;
    height: auto;
  }
}

.shop-card.style-6 .dz-media {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 40px;
}

.shop-card.style-6:hover .dz-media img {
  transform: scale(1.2);
}

.shop-card.style-6 .dz-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  padding: 0;
}

.shop-card.style-6 .sale-badge {
  position: absolute;
  top: -30px;
  right: -45px;
  width: 115px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: var(--title);
  line-height: 1.2;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.shop-card.style-6 .sale-badge img {
  position: absolute;
  z-index: -1;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 575px) {
  .shop-card.style-6 .sale-badge {
    top: -30px;
    right: -10px;
    width: 90px;
    height: 90px;
    font-size: 15px;
  }
}

.shop-card.style-7 {
  border-radius: 15px;
  background: #fff;
  box-shadow: 6px 0px 30px 0 rgba(82, 48, 0, 0.13);
}

.shop-card.style-7 .dz-media {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 15px 15px 0 0;
}

.shop-card.style-7 .dz-media img {
  border-radius: 0;
}

.shop-card.style-7 .dz-content {
  background: #FFF;
  position: relative;
  z-index: 1;
  padding: 15px;
  border-radius: 0 0 20px 20px;
  display: block;
}

.shop-card.style-7 .dz-content .title {
  margin-bottom: 0;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  width: auto;
}

@media only screen and (max-width: 991px) {
  .shop-card.style-7 .dz-content .title {
    font-size: 16px;
  }
}

.shop-card.style-7 .dz-content .sale-title {
  color: #159E42;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.shop-card.style-7:hover .dz-media img {
  transform: scale(1.2);
}

.shop-card.style-8 .dz-media {
  background: #fff;
  width: 200px;
  height: 200px;
  margin: auto;
  margin: 30px auto 15px auto;
  border-radius: 30px;
}

.shop-card.style-8 .dz-media img {
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

@media only screen and (max-width: 991px) {
  .shop-card.style-8 .dz-media {
    width: auto;
    height: auto;
  }
}

.shop-card.style-8:hover .dz-media img {
  transform: translateY(0px);
}

.shop-card.style-8 .dz-content {
  background-color: transparent;
  flex-direction: column;
  text-align: center;
  margin-bottom: 15px;
  padding: 10px 0;
}

.shop-card.style-8 .dz-content .title {
  width: 100%;
}

.shop-card.style-8 .add-product {
  background: #BEBEBE;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 15px auto;
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {

  .shop-card.style-8 .price,
  .shop-card.style-8 .star-rating {
    display: none;
  }

  .shop-card.style-8 .dz-media {
    margin: 10px 0;
  }

  .shop-card.style-8 .dz-content {
    padding: 5px 0;
  }

  .shop-card.style-8 .dz-content .title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-card.style-8 .title {
    display: none;
  }

  .shop-card.style-8 .dz-content {
    margin: 0;
  }

  .shop-card.style-8 .dz-media {
    border-radius: 12px;
  }

  .shop-card.style-8 .dz-media img {
    border-radius: 12px;
  }
}

.shop-card.style-9 {
  border-radius: 30px;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  background-color: #fff;
}

.shop-card.style-9:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #F0F0F0;
  z-index: -1;
  border-radius: 30px;
  transition: 0.5s;
}

.shop-card.style-9 .border-shap:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="91" height="18" viewBox="0 0 91 18" fill="none"><g clip-path="url(%23clip0_798_564)"><path d="M7.07796 15.1635L-0.831122 7.23929C-1.57962 6.48937 -2 5.4731 -2 4.41356V2C-2 -0.209139 -0.209139 -2 2 -2H89.2727C91.4818 -2 93.2727 -0.209137 93.2727 2V4.41356C93.2727 5.47311 92.8523 6.48936 92.1038 7.23929L84.1947 15.1635C82.632 16.7292 80.0952 16.7292 78.5325 15.1635L72.2857 8.90471C70.7229 7.33901 68.1861 7.33902 66.6234 8.90471L60.3766 15.1635C58.8139 16.7292 56.277 16.7292 54.7143 15.1635L48.4675 8.90471C46.9048 7.33901 44.3679 7.33901 42.8052 8.90471L36.5584 15.1635C34.9957 16.7292 32.4589 16.7292 30.8961 15.1635L24.6493 8.90471C23.0866 7.33901 20.5498 7.33902 18.9871 8.90471L12.7402 15.1635C11.1775 16.7292 8.64068 16.7292 7.07796 15.1635Z" fill="white" stroke="%23222222" stroke-width="3"/></g><defs><clipPath id="clip0_798_564"><rect width="91" height="18" fill="white"/></clipPath></defs></svg>');
  position: absolute;
  width: 21.77%;
  height: 20px;
  top: -4px;
  background-size: 100%;
  right: 20px;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 999;
  opacity: 0;
  transition: 0.5s;
}

.shop-card.style-9 .border-shap:before {
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="91" height="18" viewBox="0 0 91 18" fill="none"><g clip-path="url(%23clip0_798_564)"><path d="M7.07796 15.1635L-0.831122 7.23929C-1.57962 6.48937 -2 5.4731 -2 4.41356V2C-2 -0.209139 -0.209139 -2 2 -2H89.2727C91.4818 -2 93.2727 -0.209137 93.2727 2V4.41356C93.2727 5.47311 92.8523 6.48936 92.1038 7.23929L84.1947 15.1635C82.632 16.7292 80.0952 16.7292 78.5325 15.1635L72.2857 8.90471C70.7229 7.33901 68.1861 7.33902 66.6234 8.90471L60.3766 15.1635C58.8139 16.7292 56.277 16.7292 54.7143 15.1635L48.4675 8.90471C46.9048 7.33901 44.3679 7.33901 42.8052 8.90471L36.5584 15.1635C34.9957 16.7292 32.4589 16.7292 30.8961 15.1635L24.6493 8.90471C23.0866 7.33901 20.5498 7.33902 18.9871 8.90471L12.7402 15.1635C11.1775 16.7292 8.64068 16.7292 7.07796 15.1635Z" fill="white" stroke="%23222222" stroke-width="3"/></g><defs><clipPath id="clip0_798_564"><rect width="91" height="18" fill="white"/></clipPath></defs></svg>');
  position: absolute;
  width: 21.77%;
  height: 20px;
  bottom: -4px;
  background-size: 100%;
  left: 20px;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 999;
  transform: rotate(180deg);
  opacity: 0;
  transition: 0.5s;
}

.shop-card.style-9 .dz-media {
  padding: 2px;
}

.shop-card.style-9:hover:after {
  border: 3px solid var(--secondary);
}

.shop-card.style-9:hover .dz-media img {
  transform: translateY(0px);
}

.shop-card.style-9:hover .border-shap:after,
.shop-card.style-9:hover .border-shap:before {
  opacity: 1;
}

.shop-card.style-9 .shop-meta {
  left: -70px;
  right: auto;
  transition: 0.5s;
  opacity: 0;
  align-items: start;
}

@media only screen and (max-width: 1199px) {
  .shop-card.style-9 .shop-meta {
    left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-card.style-9 .shop-meta {
    left: 15px;
    top: 15px;
    padding: 0;
  }
}

.shop-card.style-9 .dz-media {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.shop-card.style-9 .dz-media img {
  border-radius: 30px 30px 0px 0px;
}

.shop-card.style-9:hover .dz-media {
  transform: translateY(0px);
}

.shop-card.style-9:hover .shop-meta {
  opacity: 1;
  left: 10px;
  top: 10px;
}

.shop-card.style-9:hover .shop-meta.top {
  position: absolute;
  top: 30px;
  right: 30px;
}

@media only screen and (max-width: 575px) {
  .shop-card.style-9:hover .shop-meta .meta-icon {
    top: 10px;
  }
}

.shop-card.style-9:hover .shop-meta-2 {
  left: 30px;
  opacity: 1;
}

.shop-card.style-9 .dz-media {
  border-radius: 0;
}

.shop-card.style-9 .dz-content {
  text-align: start;
  padding: 20px 30px;
  border-radius: 30px;
  display: block;
}

.shop-card.style-9 .dz-content .title {
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .shop-card.style-9 .dz-content .title {
    margin-bottom: 15px;
  }
}

.shop-card.style-9 .dz-content .sub-title {
  font-size: 15px;
  font-weight: 400;
  color: var(--bs-body-color);
}

@media only screen and (max-width: 1199px) {
  .shop-card.style-9 .dz-content {
    padding: 15px 12px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-card.style-9 .dz-content {
    display: block;
  }
}

.shop-card.style-9 .dz-content .price {
  font-size: 20px;
}

.shop-card.style-9 .dz-content .price del {
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .shop-card.style-9 .dz-content .price {
    font-size: 16px;
  }

  .shop-card.style-9 .dz-content .price del {
    font-size: 12px;
  }
}

.shop-card.style-9 .product-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop-card.style-9 .product-tag .badge {
  padding: 5px 15px;
  background-color: white;
  color: var(--title);
  font-family: var(--font-family-title);
  font-size: 12px;
  min-width: 22px;
  min-height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  border-radius: 30px;
  transition: 0.5s;
}

@media only screen and (max-width: 575px) {
  .shop-card.style-9 .product-tag .badge {
    padding: 2px 8px;
    font-size: 10px;
  }
}

.shop-card.style-9 .shop-meta .meta-icon {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.05);
}

.shop-card.style-9 .shop-meta .meta-icon i {
  color: var(--title);
}

.shop-card .star-rating {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.shop-card .star-rating li {
  display: inline-block;
  font-size: 14px;
  color: #e4e5e8;
}

.shop-card .star-rating li.star-fill {
  color: #ff8a00;
}

@media only screen and (max-width: 575px) {
  .shop-card .star-rating {
    justify-content: left;
  }
}

.shop-card .price {
  margin-bottom: 0;
  color: var(--title);
  font-weight: 600;
}

.shop-card .price del {
  color: #5E626F;
  font-weight: 400;
  margin-right: 10px;
}

.shop-card .shop-meta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  gap: 5px;
  padding: 15px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

@media only screen and (max-width: 575px) {
  .shop-card .shop-meta {
    gap: 0;
    padding: 15px 10px;
  }
}

.shop-card .shop-meta .meta-icon {
  width: 45px;
  min-width: 45px;
  height: 45px;
  display: block;
  background: rgba(0, 0, 0, 0.3);
  border: 0;
  line-height: 45px;
  border-radius: 32px;
  margin-bottom: 5px;
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 575px) {
  .shop-card .shop-meta .meta-icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    line-height: 32px;
  }
}

.shop-card .shop-meta .meta-icon i {
  font-size: 18px;
  color: #fff;
}

.shop-card .shop-meta>a:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.shop-card .shop-meta .btn.btn-rounded,
.shop-card .shop-meta .woocommerce #respond input.btn-rounded#submit,
.woocommerce #respond .shop-card .shop-meta input.btn-rounded#submit,
.shop-card .shop-meta .woocommerce a.btn-rounded.button,
.woocommerce .shop-card .shop-meta a.btn-rounded.button,
.shop-card .shop-meta .woocommerce button.btn-rounded.button,
.woocommerce .shop-card .shop-meta button.btn-rounded.button,
.shop-card .shop-meta .woocommerce input.btn-rounded.button,
.woocommerce .shop-card .shop-meta input.btn-rounded.button,
.shop-card .shop-meta .woocommerce .btn-rounded#place_order,
.woocommerce .shop-card .shop-meta .btn-rounded#place_order,
.shop-card .shop-meta .woocommerce-cart .wc-proceed-to-checkout a.btn-rounded.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .shop-card .shop-meta a.btn-rounded.checkout-button,
.shop-card .shop-meta .woocommerce-cart-form .table tr .btn-rounded.button,
.woocommerce-cart-form .table tr .shop-card .shop-meta .btn-rounded.button,
.shop-card .shop-meta .woocommerce #review_form #respond .form-submit input.btn-rounded,
.woocommerce #review_form #respond .form-submit .shop-card .shop-meta input.btn-rounded,
.shop-card .shop-meta .woocommerce .widget_shopping_cart .buttons a.btn-rounded,
.woocommerce .widget_shopping_cart .buttons .shop-card .shop-meta a.btn-rounded,
.shop-card .shop-meta .woocommerce.widget_shopping_cart .buttons a.btn-rounded,
.woocommerce.widget_shopping_cart .buttons .shop-card .shop-meta a.btn-rounded,
.shop-card .shop-meta .btn-rounded.wp-block-button__link {
  padding: 12px 30px;
  font-size: 14px;
  text-transform: uppercase;
  position: absolute;
  bottom: -60px;
  border: 4px solid #fffaf3;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .shop-card:hover .shop-meta {
    bottom: 15px;
  }

  .shop-card:hover .shop-meta .meta-icon {
    bottom: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-card .btn-icon {
    padding: 0 !important;
    width: 30px;
    min-width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-card .dz-content {
    padding: 10px;
    text-align: left;
  }

  .shop-card .dz-content .title {
    font-size: 14px;
    line-height: 1.2;
  }

  .shop-card .star-rating {
    margin-bottom: 5px;
  }

  .shop-card .price {
    font-size: 13px;
  }

  .shop-card .price del {
    font-size: 15px;
    margin-right: 5px;
  }
}

.shop-card.style-10 .dz-media {
  background: #fff;
  width: 200px;
  height: 200px;
  margin: auto;
  margin: 30px auto 15px auto;
}

.shop-card.style-10 .dz-media img {
  height: 100%;
}

.shop-card.style-10 .add-product {
  background: #BEBEBE;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 15px auto;
  border-radius: 4px;
}

.offer-card {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.offer-card .offer-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}

.offer-card .offer-content .title {
  text-transform: capitalize;
}

.offer-card .offer-content .offer {
  margin-bottom: 15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offer-card .offer-content {
    padding: 18px;
  }

  .offer-card .offer-content .title {
    font-size: 22px;
  }

  .offer-card .offer-content .offer {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-card .offer-content {
    padding: 18px;
  }

  .offer-card .offer-content .title {
    font-size: 18px;
  }

  .offer-card .offer-content .btn,
  .offer-card .offer-content .woocommerce #respond input#submit,
  .woocommerce #respond .offer-card .offer-content input#submit,
  .offer-card .offer-content .woocommerce a.button,
  .woocommerce .offer-card .offer-content a.button,
  .offer-card .offer-content .woocommerce button.button,
  .woocommerce .offer-card .offer-content button.button,
  .offer-card .offer-content .woocommerce input.button,
  .woocommerce .offer-card .offer-content input.button,
  .offer-card .offer-content .woocommerce #place_order,
  .woocommerce .offer-card .offer-content #place_order,
  .offer-card .offer-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .offer-card .offer-content a.checkout-button,
  .offer-card .offer-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .offer-card .offer-content .button,
  .offer-card .offer-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .offer-card .offer-content input,
  .offer-card .offer-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .offer-card .offer-content a,
  .offer-card .offer-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .offer-card .offer-content a,
  .offer-card .offer-content .wp-block-button__link {
    padding: 8px 18px;
    font-size: 14px;
  }

  .offer-card .offer-content .offer {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .offer-card {
    margin-bottom: 30px;
  }

  .offer-card .offer-content {
    padding: 30px;
  }

  .offer-card .offer-content .title {
    font-size: 24px;
  }

  .offer-card .offer-content .btn,
  .offer-card .offer-content .woocommerce #respond input#submit,
  .woocommerce #respond .offer-card .offer-content input#submit,
  .offer-card .offer-content .woocommerce a.button,
  .woocommerce .offer-card .offer-content a.button,
  .offer-card .offer-content .woocommerce button.button,
  .woocommerce .offer-card .offer-content button.button,
  .offer-card .offer-content .woocommerce input.button,
  .woocommerce .offer-card .offer-content input.button,
  .offer-card .offer-content .woocommerce #place_order,
  .woocommerce .offer-card .offer-content #place_order,
  .offer-card .offer-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .offer-card .offer-content a.checkout-button,
  .offer-card .offer-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .offer-card .offer-content .button,
  .offer-card .offer-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .offer-card .offer-content input,
  .offer-card .offer-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .offer-card .offer-content a,
  .offer-card .offer-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .offer-card .offer-content a,
  .offer-card .offer-content .wp-block-button__link {
    padding: 12px 25px;
    font-size: 16px;
  }

  .offer-card .offer-content .offer {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.shop-box.style-1 .dz-media {
  margin-bottom: 30px;
  text-align: center;
}

.shop-box.style-1 .dz-media img {
  width: 160px;
  height: 170px;
  object-fit: contain;
}

.shop-box.style-1 .product-name {
  font-size: 16px;
  font-weight: 500;
  background: #fff;
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: 5px 20px;
  border-radius: 30px;
  border: 1px solid #000;
}

.shop-section .right-box,
.shop-section .left-box {
  position: relative;
  z-index: 1;
  padding: 80px 0;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {

  .shop-section .right-box,
  .shop-section .left-box {
    padding: 50px 0;
  }
}

.shop-section .left-box {
  padding-right: 100px;
  padding-left: 100px;
  background-color: #FEEB9D;
}

@media only screen and (max-width: 991px) {
  .shop-section .left-box {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.shop-section .left-box:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="413" height="44" viewBox="0 0 413 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.4829 37.8579L0 0.375V0H413V0.375L375.517 37.8579C367.707 45.6684 355.043 45.6684 347.233 37.8579L323.892 14.5171C316.082 6.70665 303.418 6.70665 295.608 14.5171L272.267 37.8579C264.457 45.6684 251.793 45.6684 243.983 37.8579L220.642 14.5171C212.832 6.70665 200.168 6.70665 192.358 14.5171L169.017 37.8579C161.207 45.6684 148.543 45.6684 140.733 37.8579L117.392 14.5171C109.582 6.70665 96.9184 6.70665 89.1079 14.5171L65.7671 37.8579C57.9566 45.6684 45.2934 45.6684 37.4829 37.8579Z" fill="%23FFFAF3"/></svg>');
  position: absolute;
  width: 30.77%;
  height: 50px;
  top: 0;
  background-size: 100%;
  left: 5%;
  background-position: top;
  background-repeat: no-repeat;
}

.shop-section .left-box .icon-button {
  position: absolute;
  right: -75px;
  top: 50%;
  transform: translate(-8%, -50%);
}

@media only screen and (max-width: 1480px) {
  .shop-section .left-box .icon-button {
    display: none;
  }
}

.shop-section .right-box {
  background: #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1480px) {
  .shop-section .right-box {
    padding: 80px;
  }
}

@media only screen and (max-width: 1199px) {
  .shop-section .right-box {
    padding: 40px;
    align-items: unset;
  }
}

.shop-section .right-box .title {
  color: #FFF;
  font-size: 35px;
  font-weight: 500;
  width: 80%;
}

@media only screen and (max-width: 575px) {
  .shop-section .right-box .title {
    font-size: 26px;
    width: 100%;
  }
}

.shop-section .right-box .text {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
  width: 70%;
}

.shop-section .right-box .pagination-align {
  gap: 28px;
}

.shop-section .right-box .icon-button {
  position: absolute;
  left: -65px;
  top: 50%;
  transform: translate(-10%, -50%);
}

@media only screen and (max-width: 1480px) {
  .shop-section .right-box .icon-button {
    display: none;
  }
}

.icon-button {
  width: 140px;
  padding: 10px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
}

.icon-button .button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.icon-button img {
  animation: rotate-360 20s infinite linear;
}

.section-head.style-1 .title {
  font-size: 35px;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {
  .section-head.style-1 .title {
    font-size: 28px;
  }
}

.section-head.style-1 p {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

.section-head.style-1 a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 500;
}

@media only screen and (max-width: 767px) {
  .section-head.style-1 p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .section-head.style-1 .title {
    font-size: 20px;
    margin-bottom: 6px;
  }
}

.section-head.style-2 .title {
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 1199px) {
  .section-head.style-2 .title {
    font-size: 32px;
  }
}

.section-head.style-2 p {
  font-size: 16px;
  color: var(--title);
  font-weight: 300;
}

.section-head.style-2 a {
  text-transform: capitalize;
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .section-head.style-2 .title {
    font-size: 20px;
    margin-bottom: 6px;
  }
}

.section-head.style-2 a {
  white-space: nowrap;
}

.section-head.style-3 .title {
  font-size: 28px;
}

.section-head.style-3 p {
  font-size: 16px;
  color: var(--title);
  font-weight: 300;
}

.section-head.style-3 a {
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .section-head.style-3 .title {
    font-size: 20px;
    margin-bottom: 6px;
  }
}

.section-head.style-3 a {
  white-space: nowrap;
}

.dz-team.style-1 .dz-media {
  width: 300px;
  margin-bottom: 22px;
  height: 300px;
  border-radius: 20px;
}

@media only screen and (max-width: 1199px) {
  .dz-team.style-1 .dz-media {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .dz-team.style-1 .dz-media {
    margin-bottom: 10px;
  }
}

.dz-team.style-1 .dz-media img {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: 0.5s;
}

.dz-team.style-1 .dz-media::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: 0.7s;
  z-index: 0;
}

.dz-team.style-1 .team-social-icon {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: 0.7s;
  opacity: 0;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .dz-team.style-1 .team-social-icon {
    gap: 5px;
  }
}

.dz-team.style-1 .team-social-icon li a {
  color: var(--title);
  display: inline-block;
  text-align: center;
  background: #fff;
  transition: all 0.8s;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .dz-team.style-1 .team-social-icon li a {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}

.dz-team.style-1:hover .dz-media:after {
  opacity: 0.3;
}

.dz-team.style-1:hover .dz-media img {
  transform: scale(1.2);
}

.dz-team.style-1:hover .team-social-icon {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.dz-team.style-1 .dz-content {
  text-align: center;
}

.dz-team.style-1 .dz-content .title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1.2;
}

.dz-team.style-1 .dz-content span {
  font-weight: 500;
  font-size: 14px;
  color: var(--title);
}

@media only screen and (max-width: 767px) {
  .dz-team.style-1 .dz-content .title {
    font-weight: 600;
    font-size: 16px;
  }

  .dz-team.style-1 .dz-content span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-team.style-1 .dz-content {
    text-align: left;
  }

  .dz-team.style-1 .dz-content span {
    font-size: 13px;
  }
}

/* Meta Content */
.color-name {
  margin-bottom: 10px;
}

.color-name,
.price-name {
  font-size: 14px;
  font-weight: 400;
  color: #5E626F;
}

.price-num {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-family-title);
  color: var(--secondary);
}

@media only screen and (max-width: 575px) {
  .price-num {
    font-size: 22px;
  }

  .price-num del {
    font-size: 16px;
  }
}

del {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: var(--headings-font-weight2);
  color: #5E626F;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  del {
    font-size: 16px;
  }
}

.meta-content {
  margin-right: 80px;
}

.meta-content span {
  display: block;
}

.swiper-pagination-two {
  position: absolute;
  left: 35px !important;
  z-index: 1;
  top: 50% !important;
  bottom: auto !important;
  transform: translateY(-50%);
  z-index: 99;
  width: auto !important;
}

@media only screen and (max-width: 1199px) {
  .swiper-pagination-two {
    top: auto !important;
    bottom: 10px !important;
    display: flex;
    justify-content: center;
    transform: none;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .swiper-pagination-two:not(.style-2) {
    position: unset;
  }
}

@media only screen and (max-width: 575px) {
  .swiper-pagination-two {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.swiper-pagination-two.style-1 {
  left: auto !important;
  right: 35px !important;
}

@media only screen and (max-width: 1199px) {
  .swiper-pagination-two.style-1 {
    right: 10px !important;
  }
}

.swiper-pagination-two.style-2 {
  top: auto !important;
  bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0 !important;
}

.swiper-pagination-two.style-2 .swiper-pagination-bullet {
  margin: 6px 10px !important;
}

.swiper-pagination-two .swiper-pagination-bullet {
  background-color: rgba(36, 38, 48, 0.3);
  width: 6px;
  height: 6px;
  margin: 17px 0 !important;
  display: block;
  text-align: center;
  position: relative;
}

.swiper-pagination-two .swiper-pagination-bullet:after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50px;
  border: 1px solid transparent;
  background: transparent;
  left: -6px;
  top: -6px;
}

@media only screen and (max-width: 1199px) {
  .swiper-pagination-two .swiper-pagination-bullet {
    margin: 6px 10px !important;
  }
}

@media only screen and (max-width: 575px) {
  .swiper-pagination-two .swiper-pagination-bullet {
    display: inline-block;
    margin: 0 10px !important;
  }
}

.swiper-pagination-two .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgb(36, 38, 48);
}

.swiper-pagination-two .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  border: 1px solid rgb(36, 38, 48);
}

.swiper-pagination.style-1 {
  position: unset;
}

.swiper-pagination.style-1 .swiper-pagination-bullet {
  height: auto;
  width: auto;
  transition: all 0.5s;
  margin: 0 15px;
  background-color: transparent;
  transform: scale(0.9);
  color: var(--title);
  opacity: 0.4;
  z-index: 2;
}

@media only screen and (max-width: 575px) {
  .swiper-pagination.style-1 .swiper-pagination-bullet {
    margin: 0 8px;
  }
}

.swiper-pagination.style-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  transform: scale(1.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .swiper-pagination.style-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .swiper-pagination.style-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    font-size: 12px;
    width: 35px;
    height: 35px;
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10%;
  left: 42%;
  width: 100%;
}

@media only screen and (max-width: 1199px) {

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 8%;
  }
}

@media only screen and (max-width: 991px) {

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 40%;
    left: 38%;
  }
}

@media only screen and (max-width: 575px) {

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: 20%;
    bottom: -1%;
  }
}

.extra-right-btn {
  position: absolute;
  right: 0;
  background: #fff;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .extra-right-btn {
    width: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .extra-right-btn {
    display: none;
  }
}

.pagination-align {
  display: flex;
  align-items: center;
}

.pagination-align.style-1 {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 90px;
  right: 27%;
  z-index: 99;
}

@media (max-width: 1600px) {
  .pagination-align.style-1 {
    right: 14%;
  }
}

@media (max-width: 1366px) {
  .pagination-align.style-1 {
    right: 18%;
  }
}

@media only screen and (max-width: 1199px) {
  .pagination-align.style-1 {
    right: 12%;
  }
}

@media only screen and (max-width: 575px) {
  .pagination-align.style-1 {
    right: -22px;
  }
}

.pagination-align.style-1 .swiper-button-prev {
  background-color: #ffffff;
}

.pagination-align.style-1 .swiper-button-next {
  background-color: var(--secondary);
  color: #ffffff !important;
}

.pagination-align.style-1 .swiper-button-next svg {
  width: 25px;
}

.pagination-align.style-1 .swiper-button-next svg path {
  stroke: #ffffff !important;
}

.pagination-align.style-1 .swiper-button-next:hover {
  background-color: #FFFAF3 !important;
  color: var(--secondary) !important;
}

.pagination-align.style-1 .swiper-button-next:hover svg path {
  stroke: var(--secondary) !important;
}

.pagination-align.style-1 .swiper-button-next,
.pagination-align.style-1 .swiper-button-prev {
  position: unset;
  top: 0;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 28px;
  line-height: 0;
  color: var(--secondary);
  margin-top: 0;
  background-image: none;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {

  .pagination-align.style-1 .swiper-button-next,
  .pagination-align.style-1 .swiper-button-prev {
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-width: 7px;
  }
}

@media only screen and (max-width: 575px) {

  .pagination-align.style-1 .swiper-button-next,
  .pagination-align.style-1 .swiper-button-prev {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.pagination-align.style-1 .swiper-button-next:after,
.pagination-align.style-1 .swiper-button-prev:after {
  content: "none";
}

.pagination-align.style-1 .swiper-button-next svg path,
.pagination-align.style-1 .swiper-button-prev svg path {
  stroke: var(--secondary);
}

.pagination-align.style-1 .swiper-button-next:hover,
.pagination-align.style-1 .swiper-button-prev:hover {
  background: var(--secondary);
  color: #ffffff;
}

.pagination-align.style-1 .swiper-button-next:hover svg path,
.pagination-align.style-1 .swiper-button-prev:hover svg path {
  stroke: #ffffff;
}

.pagination-align.style-1 .swiper-pagination-two.swiper-pagination-bullets {
  margin-top: 8px;
}

.pagination-align.style-2 {
  display: flex;
  align-items: start;
  gap: 50px;
  z-index: 1;
  position: relative;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

.pagination-align.style-2:after {
  content: "/";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 900;
  top: 0;
  font-family: "Font Awesome 5 Free";
}

.pagination-align.style-2 .swiper-button-next,
.pagination-align.style-2 .swiper-button-prev {
  position: unset;
  text-transform: uppercase;
  align-items: start;
  width: unset;
  height: 0;
  margin: 0 7px;
  color: #24262B;
  text-shadow: 0px 4px 44px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.2em;
  margin-top: 0;
  padding: 0px;
  background-image: none;
}

.pagination-align.style-2 .swiper-button-next:after,
.pagination-align.style-2 .swiper-button-prev:after {
  content: "none";
}

.pagination-align.style-2 .swiper-button-next:hover,
.pagination-align.style-2 .swiper-button-next.active,
.pagination-align.style-2 .swiper-button-prev:hover,
.pagination-align.style-2 .swiper-button-prev.active {
  color: var(--primary);
}

@media only screen and (max-width: 575px) {
  .pagination-align.style-2 {
    gap: 10px;
    margin: 0 -7px;
  }

  .pagination-align.style-2:after {
    left: 63px;
  }
}

.newsletter-wrapper {
  position: relative;
  z-index: 1;
}

.newsletter-wrapper:after {
  content: "";
  position: absolute;
  background-color: #24262B;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
}

.newsletter-wrapper .dz-icon i {
  font-size: 80px;
  color: #d1d1d1;
  line-height: 1;
}

.newsletter-wrapper.style-1 {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 40px;
}

@media only screen and (max-width: 575px) {
  .newsletter-wrapper.style-1 {
    padding: 40px 0;
  }
}

.newsletter-wrapper.style-1 .subscride-inner .section-head .title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 8px;
}

@media only screen and (max-width: 575px) {
  .newsletter-wrapper.style-1 .subscride-inner .section-head .title {
    font-size: 24px;
  }
}

.newsletter-wrapper.style-1 .subscride-inner .section-head .sub-title {
  margin-bottom: 0;
  text-transform: capitalize;
}

.newsletter-wrapper.style-1:after {
  background-color: var(--secondary);
}

.newsletter-wrapper.style-2 {
  padding: 185px 0;
}

.newsletter-wrapper.style-2 .subscride-inner {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 60px 120px;
  background-color: #ffffff;
}

.newsletter-wrapper.style-2 .subscride-inner .section-head {
  margin-bottom: 25px;
}

.newsletter-wrapper.style-2 .subscride-inner .section-head .title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 8px;
}

.newsletter-wrapper.style-2 .subscride-inner .section-head .sub-title {
  margin-bottom: 0;
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {
  .newsletter-wrapper.style-2 .subscride-inner .section-head {
    margin-bottom: 20px;
  }

  .newsletter-wrapper.style-2 .subscride-inner .section-head .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-wrapper.style-2 .subscride-inner .section-head {
    margin-bottom: 16px;
  }

  .newsletter-wrapper.style-2 .subscride-inner .section-head .title {
    font-size: 22px;
  }
}

.newsletter-wrapper.style-2 .subscride-inner .form-group .form-control,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=text],
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=email],
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=password],
.newsletter-wrapper.style-2 .subscride-inner .form-group .login input[type=text],
.login .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=text],
.newsletter-wrapper.style-2 .subscride-inner .form-group .login input[type=password],
.login .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=password],
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=text],
.newsletter-wrapper.style-2 .subscride-inner .form-group .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=text],
.newsletter-wrapper.style-2 .subscride-inner .form-group .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=email],
.newsletter-wrapper.style-2 .subscride-inner .form-group .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=tel],
.newsletter-wrapper.style-2 .subscride-inner .form-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .newsletter-wrapper.style-2 .subscride-inner .form-group .select2-choice,
.newsletter-wrapper.style-2 .subscride-inner .form-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .newsletter-wrapper.style-2 .subscride-inner .form-group textarea,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .newsletter-wrapper.style-2 .subscride-inner .form-group input.input-text,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .newsletter-wrapper.style-2 .subscride-inner .form-group .select2-selection,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .newsletter-wrapper.style-2 .subscride-inner .form-group .select2-choice,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper.style-2 .subscride-inner .form-group select,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper.style-2 .subscride-inner .form-group input,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .newsletter-wrapper.style-2 .subscride-inner .form-group input,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper.style-2 .subscride-inner .form-group .input-text,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=text],
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .newsletter-wrapper.style-2 .subscride-inner .form-group textarea,
.newsletter-wrapper.style-2 .subscride-inner .form-group .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .newsletter-wrapper.style-2 .subscride-inner .form-group input[type=email] {
  height: 60px !important;
  background-color: #E8E8E8;
}

.newsletter-wrapper.style-2:after {
  content: none;
}

@media screen and (max-width: 1360px) {
  .newsletter-wrapper.style-2 {
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .newsletter-wrapper.style-2 {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 991px) {
  .newsletter-wrapper.style-2 .subscride-inner {
    padding: 50px 100px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-wrapper.style-2 .subscride-inner {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-wrapper.style-2 {
    padding: 55px 0;
  }

  .newsletter-wrapper.style-2 .subscride-inner {
    padding: 30px 40px;
  }
}

.newsletter-wrapper .subscride-inner .form-group .input-group .form-control,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password],
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=text],
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=password],
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=tel],
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input.input-text,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-selection,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group select,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .newsletter-wrapper .subscride-inner .form-group .input-group input,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper .subscride-inner .form-group .input-group .input-text,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email] {
  height: 50px;
  color: #5E626F;
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .form-control::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=text]::placeholder,
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=password]::placeholder,
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=tel]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group textarea::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input.input-text::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-selection::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group select::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .newsletter-wrapper .subscride-inner .form-group .input-group input::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper .subscride-inner .form-group .input-group .input-text::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group textarea::placeholder,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=email]::placeholder,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email]::placeholder {
  color: #5E626F;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .form-control:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=text]:focus,
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=password]:focus,
.login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=tel]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout textarea:focus,
.checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group textarea:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input.input-text:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-selection:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-choice:focus,
.woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group select:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr .quantity .newsletter-wrapper .subscride-inner .form-group .input-group input:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper .subscride-inner .form-group .input-group .input-text:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text]:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group textarea:focus,
.newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=email]:focus,
.woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email]:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 119, 94, 0.65);
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit,
.woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link {
  text-align: center;
  width: 50px;
  border: 0;
  height: 50px;
  padding: 0;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit span,
.woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order span,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button span,
.woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button span,
.woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input span,
.woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a span,
.woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a span,
.woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link span {
  display: block;
}

.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit i,
.woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order i,
.woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
.woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button i,
.woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input i,
.woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a i,
.woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a i,
.woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
.newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link i {
  font-size: 22px;
  color: var(--primary);
}

@media only screen and (max-width: 575px) {
  .newsletter-wrapper .subscride-inner .form-group .input-group {
    margin-bottom: 0;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .form-control,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text],
  .woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email],
  .woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password],
  .woocommerce-EditAccountForm.edit-account .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password],
  .newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=text],
  .login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
  .newsletter-wrapper .subscride-inner .form-group .input-group .login input[type=password],
  .login .newsletter-wrapper .subscride-inner .form-group .input-group input[type=password],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
  .woocommerce-ResetPassword.lost_reset_password .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=text],
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=email],
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout input[type=tel],
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group input[type=tel],
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice,
  .newsletter-wrapper .subscride-inner .form-group .input-group .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input.input-text,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-selection,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group .select2-choice,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group select,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .newsletter-wrapper .subscride-inner .form-group .input-group input,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .newsletter-wrapper .subscride-inner .form-group .input-group input,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .newsletter-wrapper .subscride-inner .form-group .input-group .input-text,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=text],
  .woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=text],
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group textarea,
  .newsletter-wrapper .subscride-inner .form-group .input-group .woocommerce #review_form #respond input[type=email],
  .woocommerce #review_form #respond .newsletter-wrapper .subscride-inner .form-group .input-group input[type=email] {
    height: 45px;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit,
  .woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link {
    height: 45px;
    width: 45px;
    text-align: center;
    display: block;
    padding: 0;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit span,
  .woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order span,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button span,
  .woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button span,
  .woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input span,
  .woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a span,
  .woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a span,
  .woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a span,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link span {
    display: none;
  }

  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .btn i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #respond input#submit i,
  .woocommerce #respond .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input#submit i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce a.button i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce button.button i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon button.button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce input.button i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input.button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #place_order i,
  .woocommerce .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon #place_order i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
  .woocommerce-cart .wc-proceed-to-checkout .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a.checkout-button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button i,
  .woocommerce-cart-form .table tr .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .button i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input i,
  .woocommerce #review_form #respond .form-submit .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon input i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a i,
  .woocommerce .widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a i,
  .woocommerce.widget_shopping_cart .buttons .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon a i,
  .newsletter-wrapper .subscride-inner .form-group .input-group .input-group-addon .wp-block-button__link i {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .adv-area {
    padding-top: 0;
  }
}

.dzSubscribe .form-group .input-group .form-control,
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=text],
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=email],
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=password],
.dzSubscribe .form-group .input-group .login input[type=text],
.login .dzSubscribe .form-group .input-group input[type=text],
.dzSubscribe .form-group .input-group .login input[type=password],
.login .dzSubscribe .form-group .input-group input[type=password],
.dzSubscribe .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dzSubscribe .form-group .input-group input[type=text],
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=text],
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=email],
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=tel],
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group .select2-choice,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group textarea,
.dzSubscribe .form-group .input-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dzSubscribe .form-group .input-group input.input-text,
.dzSubscribe .form-group .input-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dzSubscribe .form-group .input-group .select2-selection,
.dzSubscribe .form-group .input-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dzSubscribe .form-group .input-group .select2-choice,
.dzSubscribe .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe .form-group .input-group select,
.dzSubscribe .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe .form-group .input-group input,
.dzSubscribe .form-group .input-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dzSubscribe .form-group .input-group input,
.dzSubscribe .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dzSubscribe .form-group .input-group .input-text,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group input[type=text],
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group textarea,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group input[type=email] {
  height: 50px;
  color: var(--secondary);
  border: 1px solid #ffffff;
  border-radius: 0 !important;
}

.dzSubscribe .form-group .input-group .form-control::placeholder,
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=text]::placeholder,
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=email]::placeholder,
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=password]::placeholder,
.dzSubscribe .form-group .input-group .login input[type=text]::placeholder,
.login .dzSubscribe .form-group .input-group input[type=text]::placeholder,
.dzSubscribe .form-group .input-group .login input[type=password]::placeholder,
.login .dzSubscribe .form-group .input-group input[type=password]::placeholder,
.dzSubscribe .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .dzSubscribe .form-group .input-group input[type=text]::placeholder,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=text]::placeholder,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=email]::placeholder,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=tel]::placeholder,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group .select2-choice::placeholder,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group textarea::placeholder,
.dzSubscribe .form-group .input-group .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .dzSubscribe .form-group .input-group input.input-text::placeholder,
.dzSubscribe .form-group .input-group .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .dzSubscribe .form-group .input-group .select2-selection::placeholder,
.dzSubscribe .form-group .input-group .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .dzSubscribe .form-group .input-group .select2-choice::placeholder,
.dzSubscribe .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe .form-group .input-group select::placeholder,
.dzSubscribe .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe .form-group .input-group input::placeholder,
.dzSubscribe .form-group .input-group .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .dzSubscribe .form-group .input-group input::placeholder,
.dzSubscribe .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .dzSubscribe .form-group .input-group .input-text::placeholder,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group input[type=text]::placeholder,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group textarea::placeholder,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond input[type=email]::placeholder,
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group input[type=email]::placeholder {
  color: var(--secondary);
}

.dzSubscribe .form-group .input-group .form-control:focus,
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=text]:focus,
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=email]:focus,
.dzSubscribe .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.woocommerce-EditAccountForm.edit-account .dzSubscribe .form-group .input-group input[type=password]:focus,
.dzSubscribe .form-group .input-group .login input[type=text]:focus,
.login .dzSubscribe .form-group .input-group input[type=text]:focus,
.dzSubscribe .form-group .input-group .login input[type=password]:focus,
.login .dzSubscribe .form-group .input-group input[type=password]:focus,
.dzSubscribe .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.woocommerce-ResetPassword.lost_reset_password .dzSubscribe .form-group .input-group input[type=text]:focus,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=text]:focus,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=email]:focus,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group input[type=tel]:focus,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group .select2-choice:focus,
.dzSubscribe .form-group .input-group .checkout.woocommerce-checkout textarea:focus,
.checkout.woocommerce-checkout .dzSubscribe .form-group .input-group textarea:focus,
.dzSubscribe .form-group .input-group .woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .dzSubscribe .form-group .input-group input.input-text:focus,
.dzSubscribe .form-group .input-group .woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .dzSubscribe .form-group .input-group .select2-selection:focus,
.dzSubscribe .form-group .input-group .woocommerce form .form-row .select2-choice:focus,
.woocommerce form .form-row .dzSubscribe .form-group .input-group .select2-choice:focus,
.dzSubscribe .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe .form-group .input-group select:focus,
.dzSubscribe .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe .form-group .input-group input:focus,
.dzSubscribe .form-group .input-group .woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr .quantity .dzSubscribe .form-group .input-group input:focus,
.dzSubscribe .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce-cart-form .table tr td.actions .coupon .dzSubscribe .form-group .input-group .input-text:focus,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group input[type=text]:focus,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group textarea:focus,
.dzSubscribe .form-group .input-group .woocommerce #review_form #respond input[type=email]:focus,
.woocommerce #review_form #respond .dzSubscribe .form-group .input-group input[type=email]:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 119, 94, 0.65);
}

.dzSubscribe .form-group .input-group .input-group-addon {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}

.dzSubscribe .form-group .input-group .input-group-addon .btn,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #respond input#submit,
.woocommerce #respond .dzSubscribe .form-group .input-group .input-group-addon input#submit,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce a.button,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon a.button,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce button.button,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon button.button,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce input.button,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon input.button,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #place_order,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon #place_order,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dzSubscribe .form-group .input-group .input-group-addon a.checkout-button,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dzSubscribe .form-group .input-group .input-group-addon .button,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dzSubscribe .form-group .input-group .input-group-addon input,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a,
.dzSubscribe .form-group .input-group .input-group-addon .wp-block-button__link {
  text-align: center;
  width: 50px;
  border: 0;
  height: 50px;
  padding: 0;
}

.dzSubscribe .form-group .input-group .input-group-addon .btn span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #respond input#submit span,
.woocommerce #respond .dzSubscribe .form-group .input-group .input-group-addon input#submit span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce a.button span,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon a.button span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce button.button span,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon button.button span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce input.button span,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon input.button span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #place_order span,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon #place_order span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button span,
.woocommerce-cart .wc-proceed-to-checkout .dzSubscribe .form-group .input-group .input-group-addon a.checkout-button span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button span,
.woocommerce-cart-form .table tr .dzSubscribe .form-group .input-group .input-group-addon .button span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input span,
.woocommerce #review_form #respond .form-submit .dzSubscribe .form-group .input-group .input-group-addon input span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a span,
.woocommerce .widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a span,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a span,
.woocommerce.widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a span,
.dzSubscribe .form-group .input-group .input-group-addon .wp-block-button__link span {
  display: block;
}

.dzSubscribe .form-group .input-group .input-group-addon .btn i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #respond input#submit i,
.woocommerce #respond .dzSubscribe .form-group .input-group .input-group-addon input#submit i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce a.button i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon a.button i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce button.button i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon button.button i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce input.button i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon input.button i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #place_order i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon #place_order i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
.woocommerce-cart .wc-proceed-to-checkout .dzSubscribe .form-group .input-group .input-group-addon a.checkout-button i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button i,
.woocommerce-cart-form .table tr .dzSubscribe .form-group .input-group .input-group-addon .button i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input i,
.woocommerce #review_form #respond .form-submit .dzSubscribe .form-group .input-group .input-group-addon input i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a i,
.woocommerce .widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a i,
.woocommerce.widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a i,
.dzSubscribe .form-group .input-group .input-group-addon .wp-block-button__link i {
  font-size: 22px;
  color: var(--secondary);
}

.dzSubscribe .form-group .input-group .input-group-addon .btn:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #respond input#submit:hover i,
.woocommerce #respond .dzSubscribe .form-group .input-group .input-group-addon input#submit:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce a.button:hover i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon a.button:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce button.button:hover i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon button.button:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce input.button:hover i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon input.button:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #place_order:hover i,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon #place_order:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover i,
.woocommerce-cart .wc-proceed-to-checkout .dzSubscribe .form-group .input-group .input-group-addon a.checkout-button:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button:hover i,
.woocommerce-cart-form .table tr .dzSubscribe .form-group .input-group .input-group-addon .button:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input:hover i,
.woocommerce #review_form #respond .form-submit .dzSubscribe .form-group .input-group .input-group-addon input:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a:hover i,
.woocommerce .widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a:hover i,
.woocommerce.widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .wp-block-button__link:hover i,
.dzSubscribe .form-group .input-group .input-group-addon .btn:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #respond input#submit:hover svg,
.woocommerce #respond .dzSubscribe .form-group .input-group .input-group-addon input#submit:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce a.button:hover svg,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon a.button:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce button.button:hover svg,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon button.button:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce input.button:hover svg,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon input.button:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #place_order:hover svg,
.woocommerce .dzSubscribe .form-group .input-group .input-group-addon #place_order:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover svg,
.woocommerce-cart .wc-proceed-to-checkout .dzSubscribe .form-group .input-group .input-group-addon a.checkout-button:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button:hover svg,
.woocommerce-cart-form .table tr .dzSubscribe .form-group .input-group .input-group-addon .button:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input:hover svg,
.woocommerce #review_form #respond .form-submit .dzSubscribe .form-group .input-group .input-group-addon input:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a:hover svg,
.woocommerce .widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a:hover svg,
.woocommerce.widget_shopping_cart .buttons .dzSubscribe .form-group .input-group .input-group-addon a:hover svg,
.dzSubscribe .form-group .input-group .input-group-addon .wp-block-button__link:hover svg {
  display: inline-block;
  -webkit-animation: toLeftFromRight 0.5s forwards;
  -moz-animation: toLeftFromRight 0.5s forwards;
  animation: toLeftFromRight 0.5s forwards;
}

.dzSubscribe.style-1 .input-group .form-control,
.dzSubscribe.style-1 .input-group .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dzSubscribe.style-1 .input-group input[type=text],
.dzSubscribe.style-1 .input-group .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dzSubscribe.style-1 .input-group input[type=email],
.dzSubscribe.style-1 .input-group .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dzSubscribe.style-1 .input-group input[type=password],
.dzSubscribe.style-1 .input-group .login input[type=text],
.login .dzSubscribe.style-1 .input-group input[type=text],
.dzSubscribe.style-1 .input-group .login input[type=password],
.login .dzSubscribe.style-1 .input-group input[type=password],
.dzSubscribe.style-1 .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dzSubscribe.style-1 .input-group input[type=text],
.dzSubscribe.style-1 .input-group .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dzSubscribe.style-1 .input-group input[type=text],
.dzSubscribe.style-1 .input-group .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dzSubscribe.style-1 .input-group input[type=email],
.dzSubscribe.style-1 .input-group .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dzSubscribe.style-1 .input-group input[type=tel],
.dzSubscribe.style-1 .input-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dzSubscribe.style-1 .input-group .select2-choice,
.dzSubscribe.style-1 .input-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dzSubscribe.style-1 .input-group textarea,
.dzSubscribe.style-1 .input-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dzSubscribe.style-1 .input-group input.input-text,
.dzSubscribe.style-1 .input-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dzSubscribe.style-1 .input-group .select2-selection,
.dzSubscribe.style-1 .input-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dzSubscribe.style-1 .input-group .select2-choice,
.dzSubscribe.style-1 .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe.style-1 .input-group select,
.dzSubscribe.style-1 .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSubscribe.style-1 .input-group input,
.dzSubscribe.style-1 .input-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dzSubscribe.style-1 .input-group input,
.dzSubscribe.style-1 .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dzSubscribe.style-1 .input-group .input-text,
.dzSubscribe.style-1 .input-group .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dzSubscribe.style-1 .input-group input[type=text],
.dzSubscribe.style-1 .input-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dzSubscribe.style-1 .input-group textarea,
.dzSubscribe.style-1 .input-group .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dzSubscribe.style-1 .input-group input[type=email] {
  height: 45px;
  border-radius: 10px !important;
  background: #FEEB9D;
}

.dzSearch .form-group .input-group .form-control,
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=text],
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=email],
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=password],
.dzSearch .form-group .input-group .login input[type=text],
.login .dzSearch .form-group .input-group input[type=text],
.dzSearch .form-group .input-group .login input[type=password],
.login .dzSearch .form-group .input-group input[type=password],
.dzSearch .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dzSearch .form-group .input-group input[type=text],
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=text],
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=email],
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=tel],
.dzSearch .form-group .input-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group .select2-choice,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group textarea,
.dzSearch .form-group .input-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dzSearch .form-group .input-group input.input-text,
.dzSearch .form-group .input-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dzSearch .form-group .input-group .select2-selection,
.dzSearch .form-group .input-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dzSearch .form-group .input-group .select2-choice,
.dzSearch .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSearch .form-group .input-group select,
.dzSearch .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSearch .form-group .input-group input,
.dzSearch .form-group .input-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dzSearch .form-group .input-group input,
.dzSearch .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dzSearch .form-group .input-group .input-text,
.dzSearch .form-group .input-group .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dzSearch .form-group .input-group input[type=text],
.dzSearch .form-group .input-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dzSearch .form-group .input-group textarea,
.dzSearch .form-group .input-group .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dzSearch .form-group .input-group input[type=email] {
  height: 50px;
  color: var(--title);
  border: 1px solid var(--secondary);
  border-radius: 10px !important;
}

.dzSearch .form-group .input-group .form-control::placeholder,
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text]::placeholder,
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=text]::placeholder,
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email]::placeholder,
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=email]::placeholder,
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password]::placeholder,
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=password]::placeholder,
.dzSearch .form-group .input-group .login input[type=text]::placeholder,
.login .dzSearch .form-group .input-group input[type=text]::placeholder,
.dzSearch .form-group .input-group .login input[type=password]::placeholder,
.login .dzSearch .form-group .input-group input[type=password]::placeholder,
.dzSearch .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text]::placeholder,
.woocommerce-ResetPassword.lost_reset_password .dzSearch .form-group .input-group input[type=text]::placeholder,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=text]::placeholder,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=text]::placeholder,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=email]::placeholder,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=email]::placeholder,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=tel]::placeholder,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=tel]::placeholder,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout .select2-choice::placeholder,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group .select2-choice::placeholder,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout textarea::placeholder,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group textarea::placeholder,
.dzSearch .form-group .input-group .woocommerce form .form-row input.input-text::placeholder,
.woocommerce form .form-row .dzSearch .form-group .input-group input.input-text::placeholder,
.dzSearch .form-group .input-group .woocommerce form .form-row .select2-selection::placeholder,
.woocommerce form .form-row .dzSearch .form-group .input-group .select2-selection::placeholder,
.dzSearch .form-group .input-group .woocommerce form .form-row .select2-choice::placeholder,
.woocommerce form .form-row .dzSearch .form-group .input-group .select2-choice::placeholder,
.dzSearch .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSearch .form-group .input-group select::placeholder,
.dzSearch .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input::placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSearch .form-group .input-group input::placeholder,
.dzSearch .form-group .input-group .woocommerce-cart-form .table tr .quantity input::placeholder,
.woocommerce-cart-form .table tr .quantity .dzSearch .form-group .input-group input::placeholder,
.dzSearch .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text::placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .dzSearch .form-group .input-group .input-text::placeholder,
.dzSearch .form-group .input-group .woocommerce #review_form #respond input[type=text]::placeholder,
.woocommerce #review_form #respond .dzSearch .form-group .input-group input[type=text]::placeholder,
.dzSearch .form-group .input-group .woocommerce #review_form #respond textarea::placeholder,
.woocommerce #review_form #respond .dzSearch .form-group .input-group textarea::placeholder,
.dzSearch .form-group .input-group .woocommerce #review_form #respond input[type=email]::placeholder,
.woocommerce #review_form #respond .dzSearch .form-group .input-group input[type=email]::placeholder {
  color: var(--title);
}

.dzSearch .form-group .input-group .form-control:focus,
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=text]:focus,
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=email]:focus,
.dzSearch .form-group .input-group .woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.woocommerce-EditAccountForm.edit-account .dzSearch .form-group .input-group input[type=password]:focus,
.dzSearch .form-group .input-group .login input[type=text]:focus,
.login .dzSearch .form-group .input-group input[type=text]:focus,
.dzSearch .form-group .input-group .login input[type=password]:focus,
.login .dzSearch .form-group .input-group input[type=password]:focus,
.dzSearch .form-group .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.woocommerce-ResetPassword.lost_reset_password .dzSearch .form-group .input-group input[type=text]:focus,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=text]:focus,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=email]:focus,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group input[type=tel]:focus,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group .select2-choice:focus,
.dzSearch .form-group .input-group .checkout.woocommerce-checkout textarea:focus,
.checkout.woocommerce-checkout .dzSearch .form-group .input-group textarea:focus,
.dzSearch .form-group .input-group .woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .dzSearch .form-group .input-group input.input-text:focus,
.dzSearch .form-group .input-group .woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .dzSearch .form-group .input-group .select2-selection:focus,
.dzSearch .form-group .input-group .woocommerce form .form-row .select2-choice:focus,
.woocommerce form .form-row .dzSearch .form-group .input-group .select2-choice:focus,
.dzSearch .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSearch .form-group .input-group select:focus,
.dzSearch .form-group .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .dzSearch .form-group .input-group input:focus,
.dzSearch .form-group .input-group .woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr .quantity .dzSearch .form-group .input-group input:focus,
.dzSearch .form-group .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce-cart-form .table tr td.actions .coupon .dzSearch .form-group .input-group .input-text:focus,
.dzSearch .form-group .input-group .woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond .dzSearch .form-group .input-group input[type=text]:focus,
.dzSearch .form-group .input-group .woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond .dzSearch .form-group .input-group textarea:focus,
.dzSearch .form-group .input-group .woocommerce #review_form #respond input[type=email]:focus,
.woocommerce #review_form #respond .dzSearch .form-group .input-group input[type=email]:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.65);
}

.dzSearch .form-group .input-group .input-group-addon {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
}

.dzSearch .form-group .input-group .input-group-addon .btn,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #respond input#submit,
.woocommerce #respond .dzSearch .form-group .input-group .input-group-addon input#submit,
.dzSearch .form-group .input-group .input-group-addon .woocommerce a.button,
.woocommerce .dzSearch .form-group .input-group .input-group-addon a.button,
.dzSearch .form-group .input-group .input-group-addon .woocommerce button.button,
.woocommerce .dzSearch .form-group .input-group .input-group-addon button.button,
.dzSearch .form-group .input-group .input-group-addon .woocommerce input.button,
.woocommerce .dzSearch .form-group .input-group .input-group-addon input.button,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #place_order,
.woocommerce .dzSearch .form-group .input-group .input-group-addon #place_order,
.dzSearch .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dzSearch .form-group .input-group .input-group-addon a.checkout-button,
.dzSearch .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dzSearch .form-group .input-group .input-group-addon .button,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dzSearch .form-group .input-group .input-group-addon input,
.dzSearch .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dzSearch .form-group .input-group .input-group-addon a,
.dzSearch .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dzSearch .form-group .input-group .input-group-addon a,
.dzSearch .form-group .input-group .input-group-addon .wp-block-button__link {
  text-align: center;
  width: 50px;
  border: 0;
  height: 50px;
  padding: 0;
}

.dzSearch .form-group .input-group .input-group-addon .btn span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #respond input#submit span,
.woocommerce #respond .dzSearch .form-group .input-group .input-group-addon input#submit span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce a.button span,
.woocommerce .dzSearch .form-group .input-group .input-group-addon a.button span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce button.button span,
.woocommerce .dzSearch .form-group .input-group .input-group-addon button.button span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce input.button span,
.woocommerce .dzSearch .form-group .input-group .input-group-addon input.button span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #place_order span,
.woocommerce .dzSearch .form-group .input-group .input-group-addon #place_order span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button span,
.woocommerce-cart .wc-proceed-to-checkout .dzSearch .form-group .input-group .input-group-addon a.checkout-button span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button span,
.woocommerce-cart-form .table tr .dzSearch .form-group .input-group .input-group-addon .button span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input span,
.woocommerce #review_form #respond .form-submit .dzSearch .form-group .input-group .input-group-addon input span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a span,
.woocommerce .widget_shopping_cart .buttons .dzSearch .form-group .input-group .input-group-addon a span,
.dzSearch .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a span,
.woocommerce.widget_shopping_cart .buttons .dzSearch .form-group .input-group .input-group-addon a span,
.dzSearch .form-group .input-group .input-group-addon .wp-block-button__link span {
  display: block;
}

.dzSearch .form-group .input-group .input-group-addon .btn i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #respond input#submit i,
.woocommerce #respond .dzSearch .form-group .input-group .input-group-addon input#submit i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce a.button i,
.woocommerce .dzSearch .form-group .input-group .input-group-addon a.button i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce button.button i,
.woocommerce .dzSearch .form-group .input-group .input-group-addon button.button i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce input.button i,
.woocommerce .dzSearch .form-group .input-group .input-group-addon input.button i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #place_order i,
.woocommerce .dzSearch .form-group .input-group .input-group-addon #place_order i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
.woocommerce-cart .wc-proceed-to-checkout .dzSearch .form-group .input-group .input-group-addon a.checkout-button i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce-cart-form .table tr .button i,
.woocommerce-cart-form .table tr .dzSearch .form-group .input-group .input-group-addon .button i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce #review_form #respond .form-submit input i,
.woocommerce #review_form #respond .form-submit .dzSearch .form-group .input-group .input-group-addon input i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a i,
.woocommerce .widget_shopping_cart .buttons .dzSearch .form-group .input-group .input-group-addon a i,
.dzSearch .form-group .input-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a i,
.woocommerce.widget_shopping_cart .buttons .dzSearch .form-group .input-group .input-group-addon a i,
.dzSearch .form-group .input-group .input-group-addon .wp-block-button__link i {
  font-size: 20px;
}

.service-btn {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #ffffff;
  padding: 15px 5px 15px 4px;
  position: absolute;
  bottom: 28px;
  left: 26px;
  z-index: 1;
  color: var(--title);
  display: block;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  border-radius: 10px;
}

.service-btn.btn-dark {
  background-color: #24262B !important;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .service-btn {
    display: none;
  }
}

.banner-social-media {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  z-index: 1;
}

.banner-social-media ul {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.banner-social-media ul li {
  padding: 20px 0;
  writing-mode: tb-rl;
}

.banner-social-media ul li a {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #fff;
}

.banner-social-media.left {
  left: 25px;
  right: auto;
}

.banner-social-media.right {
  left: auto;
  right: 25px;
}

.banner-social-media.style-1 ul li a {
  color: var(--title);
}

@media only screen and (max-width: 1280px) {
  .banner-social-media.style-1 {
    display: none;
  }
}

.banner-social-media.style-2 {
  top: 20px;
  transform: unset;
}

.banner-social-media.style-2 ul li a {
  color: var(--title);
}

@media only screen and (max-width: 1280px) {
  .banner-social-media {
    display: none;
  }
}

.swiper-five {
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

.swiper-five .pagination-align {
  position: absolute;
  right: 30px;
  bottom: 40px;
}

.swiper-five .pagination-align .btn-next,
.swiper-five .pagination-align .btn-prev {
  background: #FFEDD4;
}

.swiper-five .pagination-align .btn-next svg path,
.swiper-five .pagination-align .btn-prev svg path {
  stroke: var(--secondary);
}

.swiper-five .pagination-align .btn-next:hover,
.swiper-five .pagination-align .btn-prev:hover {
  background: var(--secondary);
}

.swiper-five .pagination-align .btn-next:hover svg path,
.swiper-five .pagination-align .btn-prev:hover svg path {
  stroke: #ffffff;
}

@media only screen and (max-width: 767px) {
  .swiper-five .pagination-align {
    bottom: 35px;
  }
}

.category-product {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.category-product.product-1 img {
  border-radius: 30px 30px 100px 30px;
}

@media only screen and (max-width: 767px) {
  .category-product.product-1 img {
    border-radius: 30px 30px 40px 30px;
  }
}

.category-product.product-2 img {
  border-radius: 30px 30px 30px 100px;
}

@media only screen and (max-width: 767px) {
  .category-product.product-2 img {
    border-radius: 30px 30px 30px 40px;
  }
}

.category-product.product-3 img {
  border-radius: 30px 30px 100px 30px;
}

@media only screen and (max-width: 767px) {
  .category-product.product-3 img {
    border-radius: 30px 30px 40px 30px;
  }
}

.category-product.product-4 img {
  border-radius: 100px 30px 30px 30px;
}

@media only screen and (max-width: 767px) {
  .category-product.product-4 img {
    border-radius: 40px 30px 30px 30px;
  }
}

.category-product.product-5 img {
  border-radius: 100px 30px 30px 30px;
}

@media only screen and (max-width: 767px) {
  .category-product.product-5 img {
    border-radius: 40px 30px 30px 30px;
  }
}

.category-product.product-6 img {
  border-radius: 100px 30px 30px 30px;
}

@media only screen and (max-width: 767px) {
  .category-product.product-6 img {
    border-radius: 40px 30px 30px 30px;
  }
}

.category-product img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: 4px solid #fff;
}

.category-product .category-badge {
  position: absolute;
  background-color: #fff;
  color: var(--title);
  border: 4px solid var(--White, #FFF);
  font-size: 22px;
  font-weight: 400;
  padding: 8px 20px;
  font-family: var(--font-family-title);
  bottom: 30px;
  text-transform: capitalize;
  border-radius: 30px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.category-product .category-badge:hover {
  background: var(--primary);
  color: #fff;
}

.category-product.left {
  padding-left: 40px;
}

.category-product.left .category-badge {
  left: 0;
}

.category-product.right {
  padding-right: 40px;
}

.category-product.right .category-badge {
  right: 0;
}

@media only screen and (max-width: 991px) {
  .category-product {
    margin-bottom: 0;
  }

  .category-product .category-badge {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    bottom: 15px;
  }

  .category-product.left {
    padding-left: 0;
  }

  .category-product.left .category-badge {
    left: -15px;
  }

  .category-product.right {
    padding-right: 0;
  }

  .category-product.right .category-badge {
    right: -15px;
  }
}

@media only screen and (max-width: 575px) {
  .category-product {
    margin-bottom: 0;
  }

  .category-product img {
    height: 150px;
  }

  .category-product .category-badge {
    font-size: 12px;
    font-weight: 400;
    padding: 4px 6px;
    bottom: 8px;
  }

  .category-product.left {
    padding-left: 0;
  }

  .category-product.left .category-badge {
    left: -10px;
    border-radius: 8px;
  }

  .category-product.right {
    padding-right: 0;
  }

  .category-product.right .category-badge {
    right: auto;
    left: -10px;
    border-radius: 8px;
  }
}

.insta-post img {
  width: 100%;
}

.code-copy {
  position: relative;
}

#copyButton.btn,
.woocommerce a#copyButton.button,
.woocommerce button#copyButton.button,
.woocommerce input#copyButton.button,
.woocommerce-cart .wc-proceed-to-checkout a#copyButton.checkout-button,
.woocommerce-cart-form .table tr #copyButton.button,
.woocommerce #review_form #respond .form-submit input#copyButton,
.woocommerce .widget_shopping_cart .buttons a#copyButton,
.woocommerce.widget_shopping_cart .buttons a#copyButton,
#copyButton.wp-block-button__link {
  position: absolute;
  right: 45px;
  top: 15px;
  background: #eee;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 4px 10px;
}

#copyButton.btn.active,
.woocommerce a#copyButton.active.button,
.woocommerce button#copyButton.active.button,
.woocommerce input#copyButton.active.button,
.woocommerce-cart .wc-proceed-to-checkout a#copyButton.active.checkout-button,
.woocommerce-cart-form .table tr #copyButton.active.button,
.woocommerce #review_form #respond .form-submit input#copyButton.active,
.woocommerce .widget_shopping_cart .buttons a#copyButton.active,
.woocommerce.widget_shopping_cart .buttons a#copyButton.active,
#copyButton.active.wp-block-button__link {
  background: var(--secondary);
  color: #fff;
}

/* code-box */
.code-box {
  box-shadow: none;
  margin: 0 0 25px;
  padding: 20px;
  color: var(--title);
  background-color: #F3F3F3;
  white-space: pre;
  border-radius: 5px;
  max-height: 700px;
  resize: both;
  width: 100% !important;
}

.code-box.active {
  user-select: all;
}

/* dz-features-wrapper  */
.dz-features-wrapper {
  position: relative;
  z-index: 1000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(-2deg);
}

.dz-features-wrapper .dz-features {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  animation: ticker 0s linear infinite 0s normal;
  animation-duration: 0s;
  animation-duration: 32.9528s;
}

.dz-features-wrapper .dz-features .item {
  padding: 15px 20px;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .dz-features-wrapper .dz-features .item {
    padding: 10px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-features-wrapper .dz-features .item {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-features-wrapper .dz-features .item {
    padding: 10px;
  }
}

.dz-features-wrapper:hover .dz-features {
  animation-play-state: paused;
}

.text-wrapper {
  position: relative;
  z-index: 1;
  padding: 10px 20px 10px;
}

@media only screen and (max-width: 575px) {
  .text-wrapper {
    padding: 0px 20px 0px;
  }
}

.text-wrapper .title {
  color: var(--title);
  font-size: 50px;
  font-weight: 400;
  display: flex;
  letter-spacing: 12px;
  text-transform: uppercase;
  justify-content: center;
  line-height: 1;
  width: max-content;
}

@media only screen and (max-width: 575px) {
  .text-wrapper .title {
    font-size: 30px;
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 1400px) {
  .custom-width {
    width: 100% !important;
    padding: 0 30px;
  }
}

.custom-width .section-head {
  width: 65%;
}

@media only screen and (max-width: 1480px) {
  .custom-width .section-head {
    width: 90%;
  }
}

.video-section {
  position: relative;
  z-index: 1;
}

.video-section .dz-features-wrapper {
  margin-top: -35px;
  background: var(--light-dark);
  border: 0;
}

.video-section .icon-button {
  transform: scale(1.3);
}

@media only screen and (max-width: 767px) {
  .video-section .icon-button {
    transform: scale(1);
  }
}

.video-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-top: 280px;
  padding-bottom: 280px;
  background-size: cover;
  background-position: top;
}

@media only screen and (max-width: 1199px) {
  .video-wrapper {
    padding-top: 220px;
    padding-bottom: 220px;
  }
}

@media only screen and (max-width: 991px) {
  .video-wrapper {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .video-wrapper {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

@media only screen and (max-width: 575px) {
  .video-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.image-wrapper {
  position: relative;
  z-index: 1;
}

.image-wrapper:after,
.image-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
}

.image-wrapper:after {
  bottom: 0;
  background-image: url('data:image/svg+xml,<svg width="1920" height="50" viewBox="0 0 1920 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 50C914 -16.5147 1014.5 -16.8185 1922.5 50H0Z" fill="%23FFFAF3"/></svg>');
}

.image-wrapper:before {
  top: 0;
  background-image: url('data:image/svg+xml,<svg width="1920" height="50" viewBox="0 0 1920 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0C914 66.5147 1014.5 66.8185 1922.5 0H0Z" fill="%23FFFAF3"/></svg>');
}

@media only screen and (max-width: 1480px) {

  .image-wrapper:after,
  .image-wrapper:before {
    display: none;
  }
}

.image-wrapper .instagram-link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

.image-wrapper .instagram-link .follow-link {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 15px 30px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}

.image-wrapper .instagram-link .follow-link .follow-link-icon {
  width: 28px;
  margin-right: 10px;
}

.image-wrapper .instagram-link .follow-link .follow-link-content p {
  color: var(--title);
  font-weight: 500;
}

.tag-slider {
  padding-bottom: 30px;
}

.tag-slider .item-wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 12px 0;
}

.tag-slider .item-wrap .item {
  padding: 0 12px;
}

.tag-slider .item-wrap .companies-media {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 100%;
  min-height: 100px;
  border-radius: 20px;
  width: 190px;
}

@media only screen and (max-width: 767px) {
  .tag-slider .item-wrap .companies-media {
    width: 150px;
    height: 70px;
    min-height: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .tag-slider .item-wrap .companies-media {
    width: 125px;
    height: 50px;
    min-height: 50px;
  }
}

.tag-slider .item-wrap .companies-media img {
  width: 90px;
}

@media only screen and (max-width: 575px) {
  .tag-slider .item-wrap .companies-media img {
    width: 70px;
  }
}

.tag-slider .item-wrap .companies-media:hover img {
  -webkit-animation: toBottomFromTop 0.5s forwards;
  -moz-animation: toBottomFromTop 0.5s forwards;
  animation: toBottomFromTop 0.5s forwards;
}

.tag-slider.style-1 {
  padding-top: 30px;
  padding-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .tag-slider.style-1 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .tag-slider .item-wrap {
    padding: 8px 0;
  }

  .tag-slider .item-wrap .item {
    padding: 0 8px;
  }

  .tag-slider .item-wrap .item .btn,
  .tag-slider .item-wrap .item .woocommerce #respond input#submit,
  .woocommerce #respond .tag-slider .item-wrap .item input#submit,
  .tag-slider .item-wrap .item .woocommerce a.button,
  .woocommerce .tag-slider .item-wrap .item a.button,
  .tag-slider .item-wrap .item .woocommerce button.button,
  .woocommerce .tag-slider .item-wrap .item button.button,
  .tag-slider .item-wrap .item .woocommerce input.button,
  .woocommerce .tag-slider .item-wrap .item input.button,
  .tag-slider .item-wrap .item .woocommerce #place_order,
  .woocommerce .tag-slider .item-wrap .item #place_order,
  .tag-slider .item-wrap .item .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .tag-slider .item-wrap .item a.checkout-button,
  .tag-slider .item-wrap .item .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .tag-slider .item-wrap .item .button,
  .tag-slider .item-wrap .item .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .tag-slider .item-wrap .item input,
  .tag-slider .item-wrap .item .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .tag-slider .item-wrap .item a,
  .tag-slider .item-wrap .item .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .tag-slider .item-wrap .item a,
  .tag-slider .item-wrap .item .wp-block-button__link {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.category-section {
  position: relative;
  z-index: 1;
  background-color: var(--light-dark);
  border-radius: 40px;
  margin: 0 80px;
}

@media only screen and (min-width: 991px) {
  .category-section {
    padding-top: 130px;
  }
}

@media only screen and (max-width: 1480px) {
  .category-section {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 991px) {
  .category-section {
    margin: 0 20px;
  }
}

.category-section .icon-button {
  position: absolute;
  top: -7%;
  left: 50%;
  transform: translate(-50%, -7%);
}

.category-section .icon-button:after {
  content: "";
  position: absolute;
  top: 33%;
  left: 10px;
  width: 100%;
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: scale(2.5);
  z-index: -1;
  background-image: url('data:image/svg+xml,<svg width="516" height="252" viewBox="0 0 516 252" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="257.602" cy="126" r="126" fill="%23FEEB9D"/><path d="M0 124.603C81.2 112.003 128.8 74.2031 182 25.2031L135.8 124.603H0Z" fill="%23FEEB9D"/><path d="M515.203 124.603C434.003 112.003 386.403 74.2031 333.203 25.2031L379.403 124.603H515.203Z" fill="%23FEEB9D"/></svg>');
}

@media only screen and (max-width: 991px) {
  .category-section .icon-button {
    display: none;
  }
}

.text-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background: transparent;
  border-radius: 100%;
  animation: identifier 20s linear infinite;
  text-align: center;
}

.text-row:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 50%;
}

.text-row.border-white .text__char {
  color: var(--title);
}

.text-row.border-white:after {
  border: 25px solid #fff;
}

.text-row.border-secondary .text__char {
  color: #fff;
}

.text-row.border-secondary:after {
  border: 25px solid var(--secondary);
}

.text-row.blur {
  padding: 10px;
  transform: scale(1.2);
}

.text-row.blur .text__char {
  font-size: 14px;
  font-weight: 400;
  padding-top: 5px;
}

.text-row.blur:after {
  border: 25px solid transparent;
  background: rgba(255, 255, 255, 0.7);
  filter: drop-shadow(0px 0px 100px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(17px);
}

.badge__emoji {
  display: block;
  margin: 0;
  animation: identifier 20s linear infinite;
  animation-direction: reverse;
  transition: all 0.3s;
}

.text__char {
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 70px);
  height: 70px;
  width: 140px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  transform-origin: bottom center;
  transform: rotate(var(--char-rotate));
  color: var(--title);
}

@keyframes identifier {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.animation-btn {
  position: absolute;
  top: 65%;
  left: 39%;
  z-index: 999;
  width: 140px;
}

@media only screen and (max-width: 1199px) {
  .animation-btn {
    display: none;
  }
}

.companies-section {
  position: relative;
  z-index: 1;
  background: var(--gradient2);
  margin: 0 80px;
  border-radius: 40px;
}

@media only screen and (max-width: 1480px) {
  .companies-section {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .companies-section {
    margin: 0 10px;
  }
}

.companies-section:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="413" height="44" viewBox="0 0 413 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.4829 37.8579L0 0.375V0H413V0.375L375.517 37.8579C367.707 45.6684 355.043 45.6684 347.233 37.8579L323.892 14.5171C316.082 6.70665 303.418 6.70665 295.608 14.5171L272.267 37.8579C264.457 45.6684 251.793 45.6684 243.983 37.8579L220.642 14.5171C212.832 6.70665 200.168 6.70665 192.358 14.5171L169.017 37.8579C161.207 45.6684 148.543 45.6684 140.733 37.8579L117.392 14.5171C109.582 6.70665 96.9184 6.70665 89.1079 14.5171L65.7671 37.8579C57.9566 45.6684 45.2934 45.6684 37.4829 37.8579Z" fill="%23fffaf3"/></svg>');
  position: absolute;
  width: 21.77%;
  height: 50px;
  top: 0;
  background-size: 100%;
  left: -4%;
  background-position: top;
  background-repeat: no-repeat;
}

.companies-section:before {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="413" height="44" viewBox="0 0 413 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.4829 37.8579L0 0.375V0H413V0.375L375.517 37.8579C367.707 45.6684 355.043 45.6684 347.233 37.8579L323.892 14.5171C316.082 6.70665 303.418 6.70665 295.608 14.5171L272.267 37.8579C264.457 45.6684 251.793 45.6684 243.983 37.8579L220.642 14.5171C212.832 6.70665 200.168 6.70665 192.358 14.5171L169.017 37.8579C161.207 45.6684 148.543 45.6684 140.733 37.8579L117.392 14.5171C109.582 6.70665 96.9184 6.70665 89.1079 14.5171L65.7671 37.8579C57.9566 45.6684 45.2934 45.6684 37.4829 37.8579Z" fill="%23fffaf3"/></svg>');
  position: absolute;
  width: 21.77%;
  height: 50px;
  bottom: -6px;
  background-size: 100%;
  right: -4%;
  background-position: left;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1480px) {
  .companies-section:before {
    bottom: -12px;
  }
}

@media only screen and (max-width: 991px) {
  .companies-section:before {
    bottom: -18px;
  }
}

@media only screen and (max-width: 575px) {
  .companies-section:before {
    bottom: -22px;
  }
}

.companies-section .section-head .title {
  font-size: 60px;
  font-weight: 500;
  text-transform: capitalize;
}

@media only screen and (max-width: 1199px) {
  .companies-section .section-head .title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .companies-section .section-head .title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .companies-section .section-head .title {
    font-size: 26px;
  }
}

.companies-section .icon-button {
  margin-bottom: 30px;
  transform: scale(1.5);
}

@media only screen and (max-width: 991px) {
  .companies-section .icon-button {
    transform: scale(1.1);
  }
}

.companies-section .icon-button svg {
  width: 60px;
}

.companies-section .icon-button .button {
  z-index: 1;
}

.companies-section .icon-button:after {
  content: "";
  position: absolute;
  top: -52%;
  left: -3px;
  border-radius: 0px 0px 200px 200px;
  background: rgba(255, 255, 255, 0.5);
  width: calc(100% + 25px);
  height: calc(100% + 90px);
  z-index: -1;
}

.companies-section .icon-button .text-row .text__char {
  font-size: 10px;
  padding: 10px 0;
}

:root {
  --fix-height: 100px;
}

@media only screen and (max-width: 1400px) {
  :root {
    --fix-height: 70px;
  }
}

@media only screen and (max-width: 576px) {
  :root {
    --fix-height: 45px;
  }
}

.menu-nav-btn {
  width: var(--fix-height);
  height: var(--fix-height);
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  position: relative;
  border: 0;
}

.menu-nav-btn .for-dots {
  width: 15px;
  height: 15px;
  display: block;
  position: relative;
  margin: 0;
}

.menu-nav-btn .for-dots span {
  width: 4px;
  height: 4px;
  display: block;
  background: #fff;
  position: absolute;
}

.menu-nav-btn .for-dots span:nth-child(1) {
  left: 0;
  top: 0;
}

.menu-nav-btn .for-dots span:nth-child(2) {
  right: 0;
  top: 0;
}

.menu-nav-btn .for-dots span:nth-child(3) {
  left: 0;
  bottom: 0;
}

.menu-nav-btn .for-dots span:nth-child(4) {
  right: 0;
  bottom: 0;
}

.menu-nav-btn .for-dots {
  width: 15px;
  height: 15px;
  display: block;
  position: relative;
  transform: rotate(45deg);
  opacity: 0;
  transition: all 0.5s;
}

.menu-nav-btn .dots-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s;
  transform: translate(-50%, -50%) rotate(90deg);
  opacity: 1;
}

.menu-nav-btn.collapsed .for-dots {
  transform: rotate(0);
  opacity: 1;
}

.menu-nav-btn.collapsed .dots-close {
  transform: translate(-50%, -50%) rotate(0);
  opacity: 0;
}

.header.style-3 .container-fluid,
.header.style-3 .container-sm,
.header.style-3 .container-md,
.header.style-3 .container-lg,
.header.style-3 .container-xl {
  padding: 0;
}

.header.style-3 .logo-dark {
  display: block;
}

.header.style-3 .logo-light {
  display: none;
}

.header.style-3 .is-fixed .main-bar {
  background: #fff;
}

.header.style-3 .extra-nav {
  height: var(--fix-height);
}

@media only screen and (max-width: 575px) {
  .header.style-3 .extra-nav {
    bottom: 0;
  }
}

.header.style-3 .extra-nav .extra-cell .header-right {
  margin-right: 0;
}

.header.style-3 .extra-nav .extra-cell .header-right .nav-item button,
.header.style-3 .extra-nav .extra-cell .header-right .nav-item a {
  color: var(--secondary);
  --white: #000;
}

.header.style-3 .header-nav .nav {
  position: relative;
}

.header.style-3 .header-nav .nav>li.has-mega-menu {
  position: unset;
}

.header.style-3 .rounded-search {
  width: var(--fix-height);
  justify-content: center;
  display: flex;
}

.header.style-3 .rounded-search>a {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 575px) {
  .header.style-3 .rounded-search>a {
    width: 40px;
    height: 40px;
    background-color: transparent !important;
  }
}

.header.style-3 .logo-header {
  height: var(--fix-height);
  margin-left: 40px;
}

.header.style-3 .logo-header img {
  height: var(--fix-height);
}

@media only screen and (max-width: 1199px) {
  .header.style-3 .logo-header img {
    width: 150px;
  }
}

@media only screen and (max-width: 575px) {
  .header.style-3 .logo-header img {
    width: 120px;
  }
}

@media only screen and (max-width: 1199px) {
  .header.style-3 .logo-header {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .header.style-3 .logo-header {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.header.style-3 .main-bar {
  background: transparent;
  border-bottom: 0;
}

.footer-menu {
  height: var(--fix-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFAF3;
  padding-left: var(--fix-height);
  padding-right: var(--fix-height);
}

@media only screen and (max-width: 575px) {
  .footer-menu {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    z-index: 1;
  }
}

.footer-menu ul {
  display: flex;
}

@media only screen and (max-width: 991px) {
  .footer-menu ul {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .footer-menu ul {
    line-height: 1.2;
  }
}

.footer-menu ul li {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width: 1199px) {
  .footer-menu ul li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-menu ul li {
    display: inline-block;
  }
}

@media only screen and (max-width: 575px) {
  .footer-menu ul li {
    padding-left: 2px;
    padding-right: 2px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 991px) {

  .footer-menu ul li h6,
  .footer-menu ul li .h6 {
    margin: 0;
  }
}

@media only screen and (max-width: 575px) {

  .footer-menu ul li h6,
  .footer-menu ul li .h6 {
    font-size: 13px;
  }
}

.footer-menu ul li a {
  color: #5E626F;
  font-size: 15px;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 575px) {
  .footer-menu ul li a {
    font-size: 11px;
  }
}

.right-social-menu {
  width: var(--fix-height);
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  background: #FFFAF3;
  padding-top: var(--fix-height);
  padding-bottom: var(--fix-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;
}

@media only screen and (max-width: 575px) {
  .right-social-menu {
    height: calc(100vh - 45px);
  }
}

.right-social-menu ul:first-child li:first-child {
  padding-top: 0;
}

.right-social-menu ul:last-child li:last-child {
  padding-top: 0;
}

.right-social-menu ul li {
  padding: 20px 0;
  writing-mode: tb-rl;
}

@media only screen and (max-width: 1480px) {
  .right-social-menu ul li {
    padding: 10px 0;
  }
}

.right-social-menu ul li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #5E626F;
}

@media only screen and (max-width: 1480px) {
  .right-social-menu ul li a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 575px) {
  .right-social-menu ul li a {
    font-size: 12px;
  }
}

.left-swiper-number {
  width: var(--fix-height);
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  background: #FFFAF3;
  padding-top: var(--fix-height);
  padding-bottom: var(--fix-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}

@media only screen and (max-width: 575px) {
  .left-swiper-number {
    height: calc(100vh - 45px);
  }
}

.left-swiper-number .service-btn {
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  bottom: var(--fix-height);
}

.left-swiper-number .product-swiper-pagination {
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 575px) {
  .left-swiper-number .product-swiper-pagination {
    gap: 15px;
  }
}

.left-swiper-number .product-swiper-pagination:after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  background: #000;
  left: 50%;
  z-index: -1;
  opacity: 0.1;
}

.left-swiper-number .product-swiper-pagination span {
  width: auto;
  height: auto;
  border-radius: 0;
  background: none;
  display: block;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  background: #FFFAF3;
  line-height: 24px;
  transition: all 0.5s;
  color: rgba(36, 38, 43, 0.5);
  opacity: 1;
  padding: 5px 5px;
}

@media only screen and (max-width: 575px) {
  .left-swiper-number .product-swiper-pagination span {
    font-size: 13px;
  }
}

.left-swiper-number .product-swiper-pagination span.swiper-pagination-bullet-active {
  font-size: 24px;
  opacity: 1;
  color: var(--title);
}

@media only screen and (max-width: 575px) {
  .left-swiper-number .product-swiper-pagination span.swiper-pagination-bullet-active {
    font-size: 18px;
  }
}

.fixed-page-content {
  height: calc(100vh - var(--fix-height) * 2);
  padding-left: var(--fix-height);
  padding-right: var(--fix-height);
  background: #fff;
  min-height: 600px;
}

@media only screen and (max-width: 575px) {
  .fixed-page-content {
    height: calc(100vh - 45px - var(--fix-height) * 2);
  }
}

.product-gallery {
  height: 100%;
}

.product-thumb.swiper {
  width: 100%;
  height: calc(100vh - var(--fix-height) * 2);
  min-height: 600px;
}

@media only screen and (max-width: 575px) {
  .product-thumb.swiper {
    height: calc(100vh - 45px - var(--fix-height) * 2);
  }
}

.product-thumb .swiper-slide.swiper-slide-thumb-active {
  padding: 10px;
}

.product-thumb .swiper-slide {
  height: 50% !important;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-thumb .swiper-slide img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.shop-card.shop-overlay {
  width: 100%;
}

.shop-card.shop-overlay .dz-content {
  text-align: center;
  padding: 15px 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all 0.3s;
  background: transparent;
  z-index: 1;
}

.shop-card.shop-overlay .dz-media img {
  filter: grayscale(1);
  transition: all 0.5s;
}

.shop-card.shop-overlay .dz-media .shop-meta {
  z-index: 2;
}

.shop-card.shop-overlay:hover .dz-media img {
  filter: grayscale(0);
}

.shop-card.shop-overlay:hover .dz-content {
  opacity: 0;
}

.shop-card.shop-overlay.shop-height-100 {
  height: 100%;
}

.shop-card.shop-overlay.shop-height-100 .dz-media {
  height: 100%;
}

.swiper-slide-thumb-active .shop-card.shop-overlay .dz-media img {
  filter: grayscale(0);
}

.smart-product-details .dz-media img {
  object-fit: contain;
  height: calc(100vh - (var(--fix-height) * 2 + var(--static-height)));
}

.smart-product-details .dz-content {
  background: var(--secondary);
  padding: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.smart-product-details .dz-content .title {
  color: #fff;
}

.smart-product-details .dz-content p {
  font-size: 18px;
}

@media only screen and (max-width: 1480px) {
  .smart-product-details .dz-content p {
    display: none;
  }
}

.smart-product-details .dz-content .extra-info-box {
  display: flex;
}

.smart-product-details .dz-content .price-num {
  color: #fff;
}

.smart-product-details .dz-content .swiper-meta-items {
  display: flex;
}

@media only screen and (max-width: 1600px) {
  .smart-product-details .dz-content .swiper-meta-items .meta-content {
    margin-right: 30px;
  }
}

.smart-product-details .dz-content .extra-info-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
}

@media only screen and (max-width: 1300px) {
  .smart-product-details .dz-content .extra-info-box {
    align-items: start;
    flex-direction: column;
    gap: 20px;
  }
}

.header-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  background: var(--secondary);
  padding: var(--fix-height);
  display: block !important;
  transition: all 0.5s;
}

.header-menu.collapse:not(.show) {
  top: -100%;
}

.header-menu .right-social-menu {
  left: 0;
  right: auto;
  background: transparent;
}

@media only screen and (max-width: 991px) {
  .header-menu .right-social-menu {
    display: none;
  }
}

.header-menu .footer-menu {
  justify-content: left;
  background: transparent;
  position: absolute;
  bottom: 0;
  font-size: 15px;
  left: 0;
}

@media only screen and (max-width: 991px) {
  .header-menu .footer-menu {
    display: none;
  }
}

.header-menu .mega-menu {
  left: 100% !important;
  min-width: 1140px;
  max-height: 100%;
}

.header-menu .mega-menu .line-left:after {
  content: none;
}

@media only screen and (max-width: 1680px) {
  .header-menu .mega-menu {
    min-width: 1040px;
  }
}

@media only screen and (max-width: 1480px) {
  .header-menu .mega-menu {
    min-width: 950px;
  }
}

@media only screen and (max-width: 1199px) {
  .header-menu .mega-menu {
    min-width: 750px;
  }

  .header-menu .mega-menu .row {
    --bs-gutter-x: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .header-menu .mega-menu {
    min-width: 100%;
  }
}

.header-menu {
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .header-menu {
    padding: var(--fix-height) 0 0 0;
  }
}

.header-menu .header-nav {
  position: relative;
}

@media only screen and (max-width: 991px) {
  .header-menu .header-nav {
    width: 100%;
    left: 0;
    background: transparent;
    min-height: 600px;
    height: calc(100vh - 120px) !important;
  }
}

.header-menu .header-nav:after,
.header-menu .header-nav:before {
  height: calc(100% + var(--fix-height));
  width: 1px;
  content: "";
  background: linear-gradient(white 0%, #fefeff 1%, rgba(125, 185, 232, 0) 100%);
  position: absolute;
  opacity: 0.1;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 991px) {

  .header-menu .header-nav:after,
  .header-menu .header-nav:before {
    content: none;
  }
}

.header-menu .header-nav:before {
  right: auto;
  left: 0;
}

.header-menu .header-nav .nav {
  float: none;
}

.header-menu .header-nav .nav>li {
  position: unset;
}

@media only screen and (max-width: 991px) {
  .header-menu .header-nav .nav>li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.header-menu .header-nav .nav>li>a {
  color: #fff;
  line-height: 24px;
  padding: 30px 50px;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .header-menu .header-nav .nav>li>a {
    padding: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .header-menu .header-nav .nav>li>a {
    padding: 10px 0;
  }
}

.header-menu .header-nav .nav>li.sub-menu-down>a:after {
  /* background-image: url(../images/star-white.svg); */
  float: right;
}

.header-menu .header-nav .nav>li>.mega-menu,
.header-menu .header-nav .nav>li>.sub-menu {
  margin-left: 50px;
  left: 100%;
  top: 0;
  padding: 30px 50px;
  box-shadow: none;
  background-color: transparent;
  margin-top: 0;
}

@media only screen and (max-width: 1199px) {

  .header-menu .header-nav .nav>li>.mega-menu,
  .header-menu .header-nav .nav>li>.sub-menu {
    padding: 20px;
  }
}

@media only screen and (max-width: 991px) {

  .header-menu .header-nav .nav>li>.mega-menu,
  .header-menu .header-nav .nav>li>.sub-menu {
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: 0;
  }
}

@media only screen and (max-width: 1199px) {

  .header-menu .header-nav .nav>li>.mega-menu .menu-title,
  .header-menu .header-nav .nav>li>.sub-menu .menu-title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {

  .header-menu .header-nav .nav>li>.mega-menu .menu-title,
  .header-menu .header-nav .nav>li>.sub-menu .menu-title {
    font-size: 16px;
  }
}

.header-menu .header-nav .nav>li>.mega-menu .month-deal h3,
.header-menu .header-nav .nav>li>.mega-menu .month-deal .h3,
.header-menu .header-nav .nav>li>.sub-menu .month-deal h3,
.header-menu .header-nav .nav>li>.sub-menu .month-deal .h3 {
  font-size: 20px;
}

.header-menu .header-nav .nav>li>.mega-menu li a,
.header-menu .header-nav .nav>li>.sub-menu li a {
  color: #BABABA;
  font-size: 14px;
}

@media only screen and (max-width: 1199px) {

  .header-menu .header-nav .nav>li>.mega-menu li a,
  .header-menu .header-nav .nav>li>.sub-menu li a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 991px) {

  .header-menu .header-nav .nav>li>.mega-menu li a,
  .header-menu .header-nav .nav>li>.sub-menu li a {
    font-size: 14px;
  }
}

.header-menu .header-nav .nav>li:hover>.mega-menu,
.header-menu .header-nav .nav>li:hover>.sub-menu {
  visibility: hidden;
}

.header-menu .header-nav .nav>li.active>.mega-menu,
.header-menu .header-nav .nav>li.active>.sub-menu {
  margin-left: 0;
  visibility: visible;
  opacity: 1;
}

.header-menu .header-nav .nav>li>.sub-menu {
  width: 250px;
}

.active-menu .header.style-3 .main-bar {
  background: transparent;
  transition: all 0.5s;
}

.active-menu .header.style-3 .main-bar .logo-dark {
  display: none;
  transition: all 0.5s;
}

.active-menu .header.style-3 .main-bar .logo-light {
  display: block;
  transition: all 0.5s;
}

.active-menu .header.style-3 .main-bar .extra-nav .extra-cell .header-right .nav-item button,
.active-menu .header.style-3 .main-bar .extra-nav .extra-cell .header-right .nav-item a {
  color: #fff;
  --white: #fff;
  transition: all 0.5s;
}

.active-menu .header.style-3 .main-bar .rounded-search>a {
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {
  .active-menu .header.style-3 .main-bar .extra-nav {
    background: #2b2c31;
  }
}

.active-menu .header.style-3 .main-bar .extra-nav .header-right li a i {
  color: #fff;
}

.active-menu .header.style-3 .main-bar .extra-nav .header-right li a svg rect {
  fill: #fff;
}

.main-banner.style-4 {
  padding-top: 140px;
  padding-bottom: 30px;
  position: relative;
}

@media only screen and (max-width: 1680px) {
  .main-banner.style-4 {
    padding-top: 100px;
  }
}

.single-product-media img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .single-product-media {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

.single-product-info-left {
  float: right;
  width: 400px;
}

@media only screen and (max-width: 1680px) {
  .single-product-info-left {
    width: 110%;
    float: left;
  }
}

@media only screen and (max-width: 1199px) {
  .single-product-info-left {
    width: 90%;
  }
}

@media only screen and (max-width: 991px) {
  .single-product-info-left {
    width: 100%;
    margin-bottom: 30px;
  }
}

.single-product-info-left .sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.single-product-info-left h1,
.single-product-info-left .h1 {
  font-size: 3.125rem;
  line-height: 1.2;
  margin-bottom: 35px;
}

@media only screen and (max-width: 1680px) {

  .single-product-info-left h1,
  .single-product-info-left .h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1199px) {

  .single-product-info-left h1 br,
  .single-product-info-left .h1 br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {

  .single-product-info-left h1,
  .single-product-info-left .h1 {
    font-size: 1.75rem;
  }
}

.single-product-info-left p {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 35px;
}

@media only screen and (max-width: 1680px) {
  .single-product-info-left p {
    font-size: 15px;
    line-height: 1.3;
    font-weight: 400;
    margin-bottom: 15px;
  }
}

.single-product-info-left .swiper-meta-items {
  display: flex;
  margin-bottom: 35px;
}

.single-product-info-left .btn,
.single-product-info-left .woocommerce #respond input#submit,
.woocommerce #respond .single-product-info-left input#submit,
.single-product-info-left .woocommerce a.button,
.woocommerce .single-product-info-left a.button,
.single-product-info-left .woocommerce button.button,
.woocommerce .single-product-info-left button.button,
.single-product-info-left .woocommerce input.button,
.woocommerce .single-product-info-left input.button,
.single-product-info-left .woocommerce #place_order,
.woocommerce .single-product-info-left #place_order,
.single-product-info-left .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .single-product-info-left a.checkout-button,
.single-product-info-left .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .single-product-info-left .button,
.single-product-info-left .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .single-product-info-left input,
.single-product-info-left .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .single-product-info-left a,
.single-product-info-left .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .single-product-info-left a,
.single-product-info-left .wp-block-button__link {
  font-size: 14px;
  padding: 9px 20px;
  margin-bottom: 10px;
}

.single-product-info-right {
  float: left;
  width: 450px;
  margin-right: 20px;
}

@media only screen and (max-width: 1680px) {
  .single-product-info-right {
    width: 100%;
  }
}

.single-product-info-right .dz-title {
  margin-bottom: 35px;
}

@media only screen and (max-width: 1680px) {
  .single-product-info-right .dz-title {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .single-product-info-right .dz-title {
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .single-product-info-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .single-product-info-right>ul,
  .single-product-info-right>div {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media only screen and (max-width: 767px) {

  .single-product-info-right>ul,
  .single-product-info-right>div {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .single-product-info-right .product-video-info {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {

  .single-product-info-right>ul,
  .single-product-info-right>div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.single-product-info-right .service-icon-bx {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1680px) {
  .single-product-info-right .service-icon-bx {
    align-items: start;
    gap: 10px;
    margin-bottom: 25px;
    flex-direction: column;
  }
}

.single-product-info-right .service-icon-bx li a {
  display: flex;
  align-items: center;
  gap: 15px;
}

.single-product-info-right .service-icon-bx li a .dz-icon {
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-product-info-right .service-icon-bx li a .dz-icon i {
  font-size: 48px;
  color: #ABABAB;
}

.single-product-info-right .service-icon-bx li a img {
  width: 50px;
  min-width: 50px;
}

.single-product-info-right .service-icon-bx li a .info-content span {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--primary);
}

.single-product-info-right .service-icon-bx li a .info-content .title {
  font-size: 22px;
  line-height: 1.2;
}

.single-product-info-right .service-icon-list li {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
}

@media only screen and (max-width: 1680px) {
  .single-product-info-right .service-icon-list li {
    gap: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .single-product-info-right .service-icon-list li {
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

.single-product-info-right .service-icon-list li .title {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .single-product-info-right .service-icon-list li .title {
    font-size: 16px;
  }

  .single-product-info-right .service-icon-list li .title br {
    display: none;
  }
}

.product-video-info {
  background: var(--secondary);
  display: flex;
  align-items: center;
  margin-top: 100px;
}

@media only screen and (max-width: 1680px) {
  .product-video-info {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .product-video-info {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.product-video-info .dz-media {
  height: 100%;
  min-width: 130px;
  width: 130px;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .product-video-info .dz-media {
    min-width: 80px;
    width: 80px;
  }
}

.product-video-info .dz-media img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-video-info .dz-media a {
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50px;
  font-size: 18px;
}

.product-video-info .dz-media:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
  background: var(--secondary);
  height: 100%;
  width: 100%;
}

.product-video-info .dz-info {
  padding: 20px 40px;
}

@media only screen and (max-width: 1680px) {
  .product-video-info .dz-info {
    padding: 15px;
  }
}

.product-video-info .dz-info .title {
  font-size: 22px;
  color: #fff;
  margin: 0;
  line-height: 1.2;
}

@media only screen and (max-width: 1680px) {
  .product-video-info .dz-info .title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1199px) {
  .product-video-info .dz-info .title {
    margin-top: 0;
  }
}

/* Recent search */
.product-history .product-history-inner {
  position: relative;
  padding: 0 30px;
}

.product-history .product-history-inner:nth-child(1) {
  display: flex;
}

.product-history .product-history-inner:nth-child(2) {
  border-left: 1px solid var(--border-color);
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .product-history .product-history-inner:nth-child(2) {
    border-left: 0;
    border-top: 1px solid var(--border-color);
  }
}

@media only screen and (max-width: 1199px) {
  .product-history .product-history-inner {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 991px) {
  .product-history .product-history-inner:nth-child(2) {
    border-left: 0;
    border-top: 1px solid var(--border-color);
  }
}

@media only screen and (max-width: 575px) {
  .product-history .product-history-inner {
    padding: 20px 20px 0 20px;
  }

  .product-history .product-history-inner:nth-child(1) {
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .product-history .product-history-inner .product-rewards {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.dz-products {
  display: flex;
  align-items: center;
}

.dz-products li {
  padding-right: 20px;
}

@media only screen and (max-width: 575px) {
  .dz-products {
    flex-wrap: wrap;
  }

  .dz-products li {
    padding-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .dz-products li:last-child {
    margin-bottom: 0;
  }
}

.product-rewards h3,
.product-rewards .h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.product-rewards .star-rewards {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #5E626F;
}

.product-rewards .star-rewards i,
.product-rewards .star-rewards svg {
  margin-right: 9px;
  margin-bottom: 4px;
  font-size: 18px;
}

.product-rewards p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 8px;
}

.product-rewards .join-rewards {
  position: relative;
  font-family: var(--font-family-base);
  font-size: 14px;
  font-weight: 400;
}

.product-rewards .join-rewards:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -1px;
  background: var(--primary);
}

.product-rewards:nth-child(1) {
  margin-right: 23px;
}

.searched-product {
  display: flex;
  align-items: center;
  max-width: 200px;
}

.searched-product .product-image {
  width: 65px;
  height: 65px;
  min-width: 65px;
  margin-right: 15px;
  border-radius: 4px;
  overflow: hidden;
}

.searched-product .product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.searched-product .product-content .product-deals {
  max-width: 80px;
}

.searched-product .product-content .product-deals,
.searched-product .product-content .product-name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}

.searched-product .product-content .deals-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
  font-family: var(--font-family-base);
}

.searched-product .product-content .deals-link:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--primary);
  left: 0;
  bottom: -1px;
}

.searched-product .product-content .product-date {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

@media only screen and (max-width: 575px) {
  .searched-product {
    max-width: unset;
  }

  .searched-product .product-content.dz-content-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .searched-product .product-content .product-deals {
    max-width: unset;
  }
}

/* Recent search End*/
/* Our products */
.our-products .bg-img,
.our-products .bg-img2 {
  position: absolute;
  bottom: 0;
}

.our-products .bg-img {
  left: 0;
}

.our-products .bg-img2 {
  right: 0;
}

.our-products .section-head.style-2 .title {
  font-weight: 700;
}

@media only screen and (max-width: 1199px) {

  .our-products .bg-img,
  .our-products .bg-img2 {
    height: 700px;
  }

  .our-products .section-head.style-2 {
    margin-bottom: 20px;
  }

  .our-products .section-head.style-2 .title {
    font-size: 24px;
  }

  .our-products .product-info-inner {
    margin-bottom: 24px;
  }

  .our-products .product-media .dz-media {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .our-products .section-head.style-2 {
    margin-bottom: 10px;
  }

  .our-products .section-head.style-2 .title {
    font-size: 22px;
  }

  .our-products .product-info-inner {
    margin-bottom: 14px;
  }

  .our-products .product-info-inner .info-title {
    font-size: 20px;
  }

  .our-products .product-media .dz-media {
    width: 100%;
    height: 100%;
  }

  .our-products .dz-product-media {
    width: 300px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .our-products .section-head.style-2 {
    margin-bottom: 10px;
  }

  .our-products .section-head.style-2 .title {
    font-size: 20px;
  }

  .our-products .product-info-inner {
    margin-bottom: 10px;
  }

  .our-products .product-info-inner .info-title {
    font-size: 18px;
    margin-bottom: 0;
  }

  .our-products .product-info-inner ul li:nth-child(1) {
    margin-right: 40px;
  }

  .our-products .product-info-inner p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .our-products .section-head.style-2 {
    margin-bottom: 0px;
  }

  .our-products .section-head.style-2 .title {
    font-size: 18px;
  }

  .our-products .product-info-inner {
    margin-bottom: 0px;
  }

  .our-products .product-info-inner .info-title {
    font-size: 16px;
  }

  .our-products .product-info-inner ul li:nth-child(1) {
    margin-right: 30px;
  }

  .our-products .dz-product-media {
    width: 250px;
  }
}

.product-info-inner {
  margin-bottom: 40px;
}

.product-info-inner .info-title {
  font-size: 20px;
  font-weight: 700;
}

.product-info-inner ul li h6,
.product-info-inner ul li .h6 {
  font-family: var(--font-family-base);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0px;
}

.product-info-inner ul li h6 span,
.product-info-inner ul li .h6 span {
  font-weight: 400;
}

.product-info-inner ul li:nth-child(1) {
  margin-right: 55px;
}

@media only screen and (max-width: 767px) {

  .product-info-inner ul li h6,
  .product-info-inner ul li .h6 {
    font-size: 14px;
  }
}

.product-info-inner p {
  font-family: var(--font-family-base);
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .product-info-inner {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .product-info-inner .info-title {
    font-size: 18px;
  }

  .product-info-inner p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .product-info-inner {
    margin-bottom: 10px;
  }
}

/* Our products End*/
/* About products */
.about-product-wrapper .producṭ-content {
  text-align: center;
  padding: 38px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--secondary);
  border-radius: 20px;
}

.about-product-wrapper .producṭ-content .product-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.about-product-wrapper .producṭ-content .product-text {
  font-size: 14px;
  font-weight: 400;
  color: #5E626F;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .about-product-wrapper .producṭ-content .product-title {
    font-size: 18px;
  }
}

/* About products End*/
.product-media-inner .dz-media>img {
  border-radius: 10px;
}

/* About products2 */
.about-products2 .bg-img,
.about-products2 .bg-img2 {
  position: absolute;
  bottom: 0;
}

.about-products2 .bg-img {
  left: 0;
}

.about-products2 .bg-img2 {
  right: 0;
}

.about-products2 .section-head.style-2 {
  display: block;
}

.about-products2 .section-head.style-2 .title {
  font-weight: 700;
}

.about-products2 .section-head.style-2 p {
  font-family: var(--font-family-base);
  font-size: 16px;
  font-weight: 400;
  color: #5E626F;
}

@media only screen and (max-width: 1199px) {

  .about-products2 .about-products2 .bg-img,
  .about-products2 .about-products2 .bg-img2 {
    height: 700px;
  }

  .about-products2 .section-head.style-2 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .about-products2 .dz-product-media {
    width: 300px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .about-products2 .section-head.style-2 {
    margin-bottom: 10px;
    display: block;
  }

  .about-products2 .section-head.style-2 .title {
    font-size: 24px;
  }

  .about-products2 .section-head.style-2 p {
    font-size: 14px;
  }

  .about-products2 .specification-list .list-info {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .about-products2 .dz-product-media {
    width: 250px;
    margin-bottom: 30px;
  }

  .about-products2 .section-head.style-2 .title {
    font-size: 22px;
  }
}

.product-specification {
  margin-bottom: 24.5px;
}

.product-specification .specification-title {
  font-size: 20px;
  font-weight: 700;
}

.product-specification ul li {
  font-family: var(--font-family-base);
  font-size: 16px;
  padding: 5px 0;
  font-weight: 400;
  color: #5E626F;
}

@media only screen and (max-width: 1199px) {
  .product-specification {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .product-specification {
    margin-bottom: 10px;
  }

  .product-specification .specification-title {
    font-size: 18px;
  }

  .product-specification ul li {
    font-size: 14px;
  }
}

.specification-list .list-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  color: #5E626F;
  padding: 7px 0;
  border-bottom: 1px solid var(--border-color);
}

.specification-list .list-info:last-child {
  border-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .specification-list .list-info {
    font-size: 14px;
    padding: 5px 0;
  }
}

/* About products2 End*/
.baby-products {
  padding-bottom: 210px;
}

@media only screen and (max-width: 1199px) {
  .baby-products {
    padding-bottom: 70px;
  }
}

.baby-products .section-head.style-2 {
  display: block;
  color: #fff;
  margin-bottom: 25px;
}

.baby-products .section-head.style-2 .sub-title {
  font-family: var(--font-family-base);
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  font-weight: 400;
}

.baby-products .section-head.style-2 .title {
  color: #fff;
  font-size: 35px;
}

.baby-products .section-head.style-2 p {
  max-width: 470px;
  font-size: 18px;
  font-weight: 300;
}

@media only screen and (max-width: 1199px) {

  .baby-products .section-content .btn,
  .baby-products .section-content .woocommerce #respond input#submit,
  .woocommerce #respond .baby-products .section-content input#submit,
  .baby-products .section-content .woocommerce a.button,
  .woocommerce .baby-products .section-content a.button,
  .baby-products .section-content .woocommerce button.button,
  .woocommerce .baby-products .section-content button.button,
  .baby-products .section-content .woocommerce input.button,
  .woocommerce .baby-products .section-content input.button,
  .baby-products .section-content .woocommerce #place_order,
  .woocommerce .baby-products .section-content #place_order,
  .baby-products .section-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .baby-products .section-content a.checkout-button,
  .baby-products .section-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .baby-products .section-content .button,
  .baby-products .section-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .baby-products .section-content input,
  .baby-products .section-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .baby-products .section-content a,
  .baby-products .section-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .baby-products .section-content a,
  .baby-products .section-content .wp-block-button__link {
    font-size: 14px;
    padding: 12px 25px;
  }

  .baby-products .section-head.style-2 {
    margin-bottom: 20px;
  }

  .baby-products .section-head.style-2 .sub-title {
    margin-bottom: 6px;
  }

  .baby-products .section-head.style-2 .title {
    font-size: 32px;
  }

  .baby-products .section-head.style-2 p {
    max-width: 390px;
    font-size: 16px;
  }

  .baby-products .section-content .btn,
  .baby-products .section-content .woocommerce #respond input#submit,
  .woocommerce #respond .baby-products .section-content input#submit,
  .baby-products .section-content .woocommerce a.button,
  .woocommerce .baby-products .section-content a.button,
  .baby-products .section-content .woocommerce button.button,
  .woocommerce .baby-products .section-content button.button,
  .baby-products .section-content .woocommerce input.button,
  .woocommerce .baby-products .section-content input.button,
  .baby-products .section-content .woocommerce #place_order,
  .woocommerce .baby-products .section-content #place_order,
  .baby-products .section-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .baby-products .section-content a.checkout-button,
  .baby-products .section-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .baby-products .section-content .button,
  .baby-products .section-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .baby-products .section-content input,
  .baby-products .section-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .baby-products .section-content a,
  .baby-products .section-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .baby-products .section-content a,
  .baby-products .section-content .wp-block-button__link {
    padding: 12px 25px;
  }
}

@media only screen and (max-width: 991px) {
  .baby-products {
    padding-bottom: 70px;
  }

  .baby-products .section-head.style-2 {
    margin-bottom: 10px;
  }

  .baby-products .section-head.style-2 .sub-title {
    margin-bottom: 0px;
  }

  .baby-products .section-head.style-2 .title {
    font-size: 26px;
  }

  .baby-products .section-head.style-2 p {
    max-width: 580px;
    margin: auto;
    font-size: 15px;
  }

  .baby-products .baby-product-media {
    width: 100%;
  }

  .baby-products .baby-product-media img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .baby-products {
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {

  .baby-products .section-content .btn,
  .baby-products .section-content .woocommerce #respond input#submit,
  .woocommerce #respond .baby-products .section-content input#submit,
  .baby-products .section-content .woocommerce a.button,
  .woocommerce .baby-products .section-content a.button,
  .baby-products .section-content .woocommerce button.button,
  .woocommerce .baby-products .section-content button.button,
  .baby-products .section-content .woocommerce input.button,
  .woocommerce .baby-products .section-content input.button,
  .baby-products .section-content .woocommerce #place_order,
  .woocommerce .baby-products .section-content #place_order,
  .baby-products .section-content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .baby-products .section-content a.checkout-button,
  .baby-products .section-content .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .baby-products .section-content .button,
  .baby-products .section-content .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .baby-products .section-content input,
  .baby-products .section-content .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .baby-products .section-content a,
  .baby-products .section-content .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .baby-products .section-content a,
  .baby-products .section-content .wp-block-button__link {
    padding: 10px 20px;
  }

  .baby-products .section-head.style-2 {
    margin-bottom: 10px;
  }

  .baby-products .section-head.style-2 .sub-title {
    margin-bottom: 6px;
  }

  .baby-products .section-head.style-2 .title {
    font-size: 30px;
  }

  .baby-products .section-head.style-2 p {
    max-width: 390px;
    font-size: 14px;
  }
}

.countdown-timer.style-1 {
  padding-bottom: 40px;
}

.countdown-timer.style-1 .countdown .date {
  text-align: center;
}

.countdown-timer.style-1 .countdown .date .time {
  font-size: 50px;
  color: #ffffff;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.countdown-timer.style-1 .countdown .date .text {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 400;
}

@media only screen and (max-width: 991px) {
  .countdown-timer.style-1 {
    padding-bottom: 25px;
  }

  .countdown-timer.style-1 .countdown .date .time {
    font-size: 40px;
    width: 60px;
    height: 60px;
    margin-bottom: 2px;
  }

  .countdown-timer.style-1 .countdown .date .text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .countdown-timer.style-1 .countdown {
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .countdown-timer.style-1 .countdown .date .time {
    font-size: 35px;
    width: 50px;
    height: 50px;
  }

  .countdown-timer.style-1 .countdown .date .text {
    font-size: 12px;
  }
}

/* Baby products End*/
/* Baby products list */
.dz-baby-product-list {
  margin-top: -140px;
}

@media only screen and (max-width: 1199px) {
  .dz-baby-product-list {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-baby-product-list {
    margin-top: 0;
  }
}

.dz-baby-product-list>ul {
  margin: 0 -35px;
}

.dz-baby-product-list>ul>li {
  padding: 0 35px;
}

.dz-baby-product-list>ul>li:nth-child(1),
.dz-baby-product-list>ul>li:nth-child(3) {
  margin-top: 70px;
}

@media only screen and (max-width: 1199px) {

  .dz-baby-product-list>ul>li:nth-child(1),
  .dz-baby-product-list>ul>li:nth-child(3) {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 991px) {

  .dz-baby-product-list>ul>li:nth-child(1),
  .dz-baby-product-list>ul>li:nth-child(3) {
    margin-top: 0;
  }
}

.dz-baby-product-list>ul>li:nth-child(5) {
  margin-top: -100px;
}

@media only screen and (max-width: 1199px) {
  .dz-baby-product-list>ul>li:nth-child(5) {
    align-self: end;
  }
}

@media only screen and (max-width: 991px) {
  .dz-baby-product-list>ul>li:nth-child(5) {
    margin-top: 0;
  }
}

.dz-baby-product-list>ul>li:nth-child(4),
.dz-baby-product-list>ul>li:nth-child(6) {
  align-self: end;
}

@media only screen and (max-width: 1199px) {
  .dz-baby-product-list>ul>li {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-baby-product-list>ul>li {
    padding: 10px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-baby-product-list>ul {
    margin: 0 0;
  }
}

.dz-baby-product-list .dz-baby-product .dz-media img {
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1199px) {
  .dz-baby-product-list .dz-baby-product .dz-media img {
    height: 260px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-baby-product-list .dz-baby-product .dz-media img {
    height: 160px;
  }
}

.dz-baby-product-list .dz-baby-product .dz-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 0;
}

.dz-baby-product-list .dz-baby-product .dz-content .product-name {
  font-weight: 500;
  margin-bottom: 0;
}

.dz-baby-product-list .dz-baby-product .dz-content a {
  font-family: var(--font-family-title);
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #868686;
}

@media only screen and (max-width: 575px) {
  .dz-baby-product-list .dz-baby-product .dz-content .product-name {
    font-size: 14px;
  }

  .dz-baby-product-list .dz-baby-product .dz-content a {
    font-size: 12px;
  }
}

.dz-baby-product-list .join-form {
  padding: 38px 38px 45px 38px;
  background: #fff;
  border: 1px solid #444444;
}

.dz-baby-product-list .join-form p {
  font-size: 15px;
  font-weight: 400;
  color: #5E626F;
  margin-bottom: 20px;
}

.dz-baby-product-list .join-form .dzSubscribe .input-group {
  display: block;
}

.dz-baby-product-list .join-form .dzSubscribe .input-group .form-control,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=text],
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=email],
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=password],
.dz-baby-product-list .join-form .dzSubscribe .input-group .login input[type=text],
.login .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=text],
.dz-baby-product-list .join-form .dzSubscribe .input-group .login input[type=password],
.login .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=password],
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=text],
.dz-baby-product-list .join-form .dzSubscribe .input-group .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=text],
.dz-baby-product-list .join-form .dzSubscribe .input-group .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=email],
.dz-baby-product-list .join-form .dzSubscribe .input-group .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=tel],
.dz-baby-product-list .join-form .dzSubscribe .input-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dz-baby-product-list .join-form .dzSubscribe .input-group .select2-choice,
.dz-baby-product-list .join-form .dzSubscribe .input-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dz-baby-product-list .join-form .dzSubscribe .input-group textarea,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dz-baby-product-list .join-form .dzSubscribe .input-group input.input-text,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dz-baby-product-list .join-form .dzSubscribe .input-group .select2-selection,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dz-baby-product-list .join-form .dzSubscribe .input-group .select2-choice,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-baby-product-list .join-form .dzSubscribe .input-group select,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-baby-product-list .join-form .dzSubscribe .input-group input,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dz-baby-product-list .join-form .dzSubscribe .input-group input,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dz-baby-product-list .join-form .dzSubscribe .input-group .input-text,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=text],
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dz-baby-product-list .join-form .dzSubscribe .input-group textarea,
.dz-baby-product-list .join-form .dzSubscribe .input-group .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dz-baby-product-list .join-form .dzSubscribe .input-group input[type=email] {
  width: 100%;
  margin-bottom: 24px;
}

@media only screen and (max-width: 1199px) {
  .dz-baby-product-list .join-form {
    padding: 30px 30px 35px 30px;
  }

  .dz-baby-product-list .join-form p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-baby-product-list .join-form {
    padding: 20px;
  }

  .dz-baby-product-list .join-form h2,
  .dz-baby-product-list .join-form .h2 {
    font-size: 24px;
  }
}

.about-baby-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-baby-product .product-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 0;
}

.about-baby-product a {
  font-family: var(--font-family-title);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid rgb(134, 134, 134);
  color: #868686;
}

/* Baby products list End*/
/* Our Feature */
.our-features {
  text-align: center;
  padding: 70px 0;
  min-height: 545px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-features .features-content h2,
.our-features .features-content .h2 {
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  color: #fff;
  margin-bottom: 27px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .our-features {
    min-height: 475px;
  }

  .our-features .features-content h2,
  .our-features .features-content .h2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .our-features {
    min-height: 400px;
  }

  .our-features .features-content h2,
  .our-features .features-content .h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .our-features {
    min-height: 340px;
  }
}

@media only screen and (max-width: 575px) {
  .our-features {
    padding: 40px 0;
    min-height: 255px;
  }

  .our-features .features-content h2,
  .our-features .features-content .h2 {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 10px;
  }
}

/* Our Feature End*/
/* Get In Touch */
.get-in-touch {
  background-color: var(--secondary);
  padding: 25px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.get-in-touch .dz-title {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 400;
  line-height: 1.2;
}

.get-in-touch span {
  font-size: 17px;
  color: #ffffff;
  margin-left: 18px;
}

.get-in-touch.bg-light .dz-title {
  color: var(--title);
}

.get-in-touch.bg-light span {
  color: var(--title);
}

@media only screen and (max-width: 991px) {
  .get-in-touch {
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .get-in-touch {
    flex-direction: column;
    justify-content: center;
  }

  .get-in-touch .dz-title {
    gap: 5px;
    flex-direction: column;
  }

  .get-in-touch span {
    margin-left: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .get-in-touch {
    flex-direction: column;
    justify-content: center;
  }

  .get-in-touch .dz-title {
    gap: 5px;
    flex-direction: column;
  }

  .get-in-touch span {
    font-size: 14px;
    margin-left: 0px;
    text-align: center;
  }

  .get-in-touch .btn,
  .get-in-touch .woocommerce #respond input#submit,
  .woocommerce #respond .get-in-touch input#submit,
  .get-in-touch .woocommerce a.button,
  .woocommerce .get-in-touch a.button,
  .get-in-touch .woocommerce button.button,
  .woocommerce .get-in-touch button.button,
  .get-in-touch .woocommerce input.button,
  .woocommerce .get-in-touch input.button,
  .get-in-touch .woocommerce #place_order,
  .woocommerce .get-in-touch #place_order,
  .get-in-touch .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .get-in-touch a.checkout-button,
  .get-in-touch .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .get-in-touch .button,
  .get-in-touch .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .get-in-touch input,
  .get-in-touch .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .get-in-touch a,
  .get-in-touch .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .get-in-touch a,
  .get-in-touch .wp-block-button__link {
    padding: 8px 14px;
    font-size: 14px;
  }
}

/* Get In Touch End*/
/* Trending */
.trending .section-head.style-3 .title {
  font-size: 24px;
}

.trending .dz-trending-media2 {
  margin-top: -75px;
}

@media only screen and (max-width: 1199px) {
  .trending .dz-trending-media2 {
    margin-top: -230px;
  }
}

@media only screen and (max-width: 991px) {
  .trending .dz-trending-media2 {
    margin-top: -180px;
  }
}

@media only screen and (max-width: 767px) {
  .trending .dz-trending-media2 {
    margin-top: -140px;
  }
}

@media only screen and (max-width: 575px) {
  .trending .dz-trending-media2 {
    margin-top: 0px;
  }
}

/* Companies */
.companies .section-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companies .section-inner .section-head.style-3 {
  display: block;
  max-width: 300px;
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .companies .section-inner .section-head.style-3 {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.companies .section-inner .companies-media {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 100%;
  overflow: hidden;
  width: 100%;
  float: right;
  min-height: 100px;
}

.companies .section-inner .companies-media:hover img {
  -webkit-animation: toBottomFromTop 0.5s forwards;
  -moz-animation: toBottomFromTop 0.5s forwards;
  animation: toBottomFromTop 0.5s forwards;
}

@media only screen and (max-width: 991px) {
  .companies .section-inner {
    display: unset;
  }

  .companies .section-inner .section-head.style-2 {
    display: block;
    max-width: 450px;
    margin: auto auto 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .companies .section-inner .companies-inner {
    justify-content: center;
  }
}

/* Companies End*/
.right-text-bar {
  width: 80px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

.right-text-bar ul li {
  padding: 20px 0;
  writing-mode: tb-rl;
}

@media only screen and (max-width: 1480px) {
  .right-text-bar ul li {
    padding: 10px 0;
  }
}

.right-text-bar ul li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #5E626F;
}

@media only screen and (max-width: 1480px) {
  .right-text-bar ul li a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 575px) {
  .right-text-bar ul li a {
    font-size: 12px;
  }
}

.left-text-bar {
  width: 80px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

.left-text-bar .service-btn {
  transform: translateX(-50%);
  left: 50%;
  bottom: 20px;
}

.left-text-bar ul li {
  padding: 20px 0;
  writing-mode: tb-rl;
}

@media only screen and (max-width: 1480px) {
  .left-text-bar ul li {
    padding: 10px 0;
  }
}

.left-text-bar ul li a {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #5E626F;
}

@media only screen and (max-width: 1480px) {
  .left-text-bar ul li a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 575px) {
  .left-text-bar ul li a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1480px) {

  .main-banner.style-4 .left-text-bar,
  .main-banner.style-4 .right-text-bar {
    width: 40px;
  }
}

@media only screen and (max-width: 1300px) {

  .main-banner.style-4 .left-text-bar,
  .main-banner.style-4 .right-text-bar {
    display: none;
  }
}

/*Blog single */
.blog-single {
  margin-bottom: 3.125rem;
}

@media only screen and (max-width: 575px) {
  .blog-single {
    margin-bottom: 2rem;
  }
}

.blog-single h1,
.blog-single .h1 {
  max-width: 625px;
  margin-bottom: 10px;
}

.blog-single .dz-post-meta {
  margin-bottom: 1.25rem;
}

.blog-single .dz-post-text {
  padding-bottom: 5px;
}

.blog-single .dz-post-text:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.blog-single .dz-post-tags {
  margin-top: 1.25rem;
}

.blog-single .dz-post-media {
  border-radius: 0.25rem;
}

.blog-single.dz-blog .post-tags {
  margin-bottom: 0;
}

.blog-single.style-1 {
  box-shadow: unset;
  border-radius: 0;
  overflow: unset;
  margin-bottom: 60px;
}

.blog-single.style-1 .post-header .dz-title {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.blog-single.style-1 ul {
  padding: 0 !important;
}

.blog-single.style-1 ul li:before {
  display: none !important;
}

@media only screen and (max-width: 991px) {
  .blog-single.style-1 {
    margin-bottom: 20px;
  }
}

.blog-single.style-1 .dz-media+.dz-info {
  text-align: unset;
  border: 0;
  padding: 0;
  margin-top: 0;
  padding: 30px 0 0 0;
}

.blog-single.style-1 .dz-meta {
  margin-bottom: 30px;
}

.blog-single.style-1 .dz-meta li {
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  position: relative;
  margin-right: 30px;
  font-size: 13px;
  color: #24262B;
}

@media only screen and (max-width: 575px) {
  .blog-single.style-1 .dz-meta li {
    font-size: 12px;
  }
}

.blog-single.style-1 .dz-meta li i {
  color: var(--secondary);
}

.blog-single.style-1 .dz-meta li:after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -17px;
  top: 50%;
  border-radius: 50%;
  background-color: var(--secondary);
  transform: translate(0%, -50%);
}

.blog-single.style-1 .dz-meta li:first-child:after,
.blog-single.style-1 .dz-meta li:last-child:after {
  content: none;
}

.blog-single.style-1 .dz-meta li.post-date {
  padding: 5px 12px;
  line-height: 1.2;
  font-weight: 500;
  display: inline-block;
  background: #FEEB9D;
  color: var(--title);
  font-size: 13px;
  border-radius: 5px;
}

@media only screen and (max-width: 575px) {
  .blog-single.style-1 .dz-meta li.post-date {
    font-size: 12px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-single.style-1 .dz-meta {
    margin-bottom: 20px;
  }

  .blog-single.style-1 .dz-meta li {
    gap: 4px;
    margin-right: 10px;
    padding-right: 0;
  }

  .blog-single.style-1 .dz-meta li:after {
    content: none;
  }

  .blog-single.style-1 .dz-meta li.post-date {
    padding: 5px 10px;
  }
}

.blog-single.style-1 .dz-meta.style-1 li {
  color: #fff;
}

.blog-single.style-1 .dz-meta.style-1 li a {
  color: #fff;
}

.blog-single.style-1 .dz-meta.style-1 li i {
  color: #fff;
}

.blog-single.style-1 .dz-meta.style-1 li:after {
  background-color: #fff;
}

.blog-single.style-1 .dz-meta.style-1 li.post-date {
  color: #24262B;
}

.blog-single.style-1 .dz-info {
  text-align: unset;
  border: 0;
  padding: 0;
  margin-top: 0;
}

.blog-single.style-1 .dz-info+.dz-media {
  margin-top: 25px;
}

.blog-single .dz-media,
.blog-single .dz-post-media {
  text-align: center;
}

.blog-single .dz-media img,
.blog-single .dz-post-media img {
  width: auto;
}

.blog-single.style-1 .list-check-2 {
  padding-left: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.blog-single.style-1 .list-check-2 li {
  padding: 15px 8px 15px 55px !important;
  position: relative;
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 16px !important;
  color: #222222;
  font-family: var(--font-family-title);
  width: 50%;
}

.blog-single.style-1 .list-check-2 li:after {
  position: absolute;
  left: 5px;
  top: 50%;
  content: "\e83f";
  font-family: "feather" !important;
  font-weight: 300;
  color: var(--primary);
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transform: translateY(-50%);
}

.blog-single.style-1 .list-check-2.check-circle li:after {
  border-radius: 50%;
  background-color: transparent;
  color: var(--secondary);
  border: 1px solid var(--secondary);
}

@media only screen and (max-width: 1199px) {
  .blog-single.style-1 .list-check-2 li {
    padding: 12px 8px 12px 48px !important;
    font-size: 15px !important;
  }

  .blog-single.style-1 .list-check-2 li:after {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-single.style-1 .list-check-2 li {
    width: 100%;
  }
}

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none;
}

.section-full {
  --dz-height: 600px;
}

@media only screen and (max-width: 991px) {
  .section-full {
    --dz-banner-height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .section-full {
    --dz-height: 380px;
  }
}

@media only screen and (max-width: 575px) {
  .section-full {
    --dz-height: 350px;
  }
}

.post-header {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .post-header {
    margin-bottom: 1.25rem;
  }
}

.text-content {
  max-width: 100%;
  /* Adjust the max-width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Optional: truncate text with ellipsis */
  white-space: nowrap;
}

.post-header .dz-media {
  height: var(--dz-height);
}

.post-header .dz-media img {
  min-height: 250px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-header .dz-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 100px 30px 30px 30px !important;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .post-header .dz-info {
    padding: 40px 15px 15px 15px !important;
  }
}

.post-header .dz-info .dz-title {
  color: #fff;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .post-header .dz-info .dz-title {
    font-size: 24px;
  }
}

.post-header .dz-info .dz-meta>ul {
  justify-content: center;
}

.post-header .dz-info .dz-meta>ul>li {
  color: #fff;
}

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border: 0 30px 0 0;
}

.post-link-in:hover {
  background: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .post-link-in {
    padding: 10px 30px;
    font-size: 20px;
  }
}

.side-bar {
  padding-left: 20px;
  padding-bottom: 1px;
}

.side-bar.left {
  padding-left: 0;
  padding-right: 20px;
}

@media only screen and (max-width: 1199px) {
  .side-bar.left {
    padding-right: 0;
    padding-left: 0;
  }
}

.alignnone {
  margin: 0.3125rem 0 1.563rem 0;
}

.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
  margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem;
}

.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
  margin: 0.3125rem 0 1.563rem 1.563rem;
  float: right;
}

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0;
}

.alignleftimg,
.alignleft.is-cropped,
.alignleft.wp-caption,
.alignleft.is-resized {
  margin: 0.3125rem 1.563rem 1.563rem 0;
  float: left;
}

@media only screen and (max-width: 575px) {
  .alignleft.side-img {
    width: 200px;
  }
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}

.wp-caption img[class*=wp-image-] {
  display: block;
  margin: 0;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 0.813rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.625rem 0;
  text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.25rem 2rem 1.25rem 1.875rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  font-family: var(--font-family-title);
  border: 1px solid var(--secondary);
  border-radius: 20px;
}

.wp-block-quote.is-large i,
.wp-block-quote.is-style-large i,
blockquote.wp-block-quote i,
blockquote.wp-block-pullquote i,
blockquote.wp-block-pullquote.alignright i,
blockquote.wp-block-pullquote.alignleft i,
blockquote i {
  font-size: 65px;
}

@media only screen and (max-width: 575px) {

  .wp-block-quote.is-large i,
  .wp-block-quote.is-style-large i,
  blockquote.wp-block-quote i,
  blockquote.wp-block-pullquote i,
  blockquote.wp-block-pullquote.alignright i,
  blockquote.wp-block-pullquote.alignleft i,
  blockquote i {
    font-size: 40px;
  }
}

.wp-block-quote.is-large i,
.wp-block-quote.is-large svg,
.wp-block-quote.is-style-large i,
.wp-block-quote.is-style-large svg,
blockquote.wp-block-quote i,
blockquote.wp-block-quote svg,
blockquote.wp-block-pullquote i,
blockquote.wp-block-pullquote svg,
blockquote.wp-block-pullquote.alignright i,
blockquote.wp-block-pullquote.alignright svg,
blockquote.wp-block-pullquote.alignleft i,
blockquote.wp-block-pullquote.alignleft svg,
blockquote i,
blockquote svg {
  position: absolute;
  right: 24px;
  bottom: 25px;
}

@media only screen and (max-width: 991px) {

  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1.25rem 2.25rem 0.938rem 1.25rem;
    font-size: 0.813rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 575px) {

  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1.25rem 0.25rem 0.938rem 1.25rem;
  }
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
  font-style: normal;
  position: relative;
  display: block;
  margin-top: -0.3125rem;
  font-weight: 600;
  color: var(--secondary);
  line-height: 1.3;
}

.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before,
blockquote.wp-block-quote cite:before,
blockquote.wp-block-pullquote cite:before,
blockquote.wp-block-pullquote.alignright cite:before,
blockquote.wp-block-pullquote.alignleft cite:before,
blockquote cite:before {
  content: "";
  margin-right: 0.625rem;
  width: 36px;
  height: 2px;
  background: var(--secondary);
  display: inline-block;
  vertical-align: middle;
}

.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
  color: inherit;
}

.wp-block-quote.is-large:after,
.wp-block-quote.is-style-large:after,
blockquote.wp-block-quote:after,
blockquote.wp-block-pullquote:after,
blockquote.wp-block-pullquote.alignright:after,
blockquote.wp-block-pullquote.alignleft:after,
blockquote:after {
  content: "";
  width: 4px;
  border-radius: 2px;
  height: 80%;
  left: 0;
  top: 50%;
  background: var(--secondary);
  position: absolute;
  box-shadow: 0 0 30px 0 var(--rgba-primary-2);
  transform: translateY(-50%);
}

.wp-block-quote.is-large:before,
.wp-block-quote.is-style-large:before,
blockquote.wp-block-quote:before,
blockquote.wp-block-pullquote:before,
blockquote.wp-block-pullquote.alignright:before,
blockquote.wp-block-pullquote.alignleft:before,
blockquote:before {
  content: "";
  z-index: -1;
  background-size: cover;
  left: 20px;
  background-image: var(--quote);
  background-position: center right;
  width: 90px;
  padding: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  height: 90px;
  top: 0px;
  position: absolute;
}

.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  margin: 0rem 1.563rem 1.563rem 0rem;
}

.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  margin: 0rem 0 1.563rem 1.563rem;
}

.wp-block-quote.is-large.style-1,
.wp-block-quote.is-style-large.style-1,
blockquote.wp-block-quote.style-1,
blockquote.wp-block-pullquote.style-1,
blockquote.wp-block-pullquote.alignright.style-1,
blockquote.wp-block-pullquote.alignleft.style-1,
blockquote.style-1 {
  background: #f5f5f5;
  color: var(--primary);
  padding: 25px 40px;
}

.wp-block-quote.is-large.style-1 p,
.wp-block-quote.is-style-large.style-1 p,
blockquote.wp-block-quote.style-1 p,
blockquote.wp-block-pullquote.style-1 p,
blockquote.wp-block-pullquote.alignright.style-1 p,
blockquote.wp-block-pullquote.alignleft.style-1 p,
blockquote.style-1 p {
  font-size: 18px;
  line-height: 1.5;
  color: var(--primary);
}

.wp-block-quote.is-large.style-1 h4,
.wp-block-quote.is-large.style-1 .h4,
.wp-block-quote.is-style-large.style-1 h4,
.wp-block-quote.is-style-large.style-1 .h4,
blockquote.wp-block-quote.style-1 h4,
blockquote.wp-block-pullquote.style-1 h4,
blockquote.wp-block-pullquote.alignright.style-1 h4,
blockquote.wp-block-pullquote.alignleft.style-1 h4,
blockquote.style-1 h4,
blockquote.style-1 .h4 {
  font-weight: 800;
}

.wp-block-quote.is-large.style-1:after,
.wp-block-quote.is-style-large.style-1:after,
blockquote.wp-block-quote.style-1:after,
blockquote.wp-block-pullquote.style-1:after,
blockquote.wp-block-pullquote.alignright.style-1:after,
blockquote.wp-block-pullquote.alignleft.style-1:after,
blockquote.style-1:after {
  content: none;
}

.wp-block-quote.is-large.style-1:before,
.wp-block-quote.is-style-large.style-1:before,
blockquote.wp-block-quote.style-1:before,
blockquote.wp-block-pullquote.style-1:before,
blockquote.wp-block-pullquote.alignright.style-1:before,
blockquote.wp-block-pullquote.alignleft.style-1:before,
blockquote.style-1:before {
  right: 70px;
  top: -10px;
  color: rgb(255, 255, 255);
  content: "\f11d";
  font-family: flaticon;
  height: auto;
  font-size: 180px;
  transform: rotate(180deg);
  width: auto;
  background: none;
  line-height: 1;
  opacity: 1;
}

.wp-block-quote.is-large.style-1 cite,
.wp-block-quote.is-style-large.style-1 cite,
blockquote.wp-block-quote.style-1 cite,
blockquote.wp-block-pullquote.style-1 cite,
blockquote.wp-block-pullquote.alignright.style-1 cite,
blockquote.wp-block-pullquote.alignleft.style-1 cite,
blockquote.style-1 cite {
  color: var(--title);
}

.wp-block-quote.is-large.style-1 cite:before,
.wp-block-quote.is-style-large.style-1 cite:before,
blockquote.wp-block-quote.style-1 cite:before,
blockquote.wp-block-pullquote.style-1 cite:before,
blockquote.wp-block-pullquote.alignright.style-1 cite:before,
blockquote.wp-block-pullquote.alignleft.style-1 cite:before,
blockquote.style-1 cite:before {
  background: var(--title);
}

.wp-block-quote.is-large.style-2,
.wp-block-quote.is-style-large.style-2,
blockquote.wp-block-quote.style-2,
blockquote.wp-block-pullquote.style-2,
blockquote.wp-block-pullquote.alignright.style-2,
blockquote.wp-block-pullquote.alignleft.style-2,
blockquote.style-2 {
  padding: 10px 0 10px 25px;
}

.wp-block-quote.is-large.style-2:before,
.wp-block-quote.is-style-large.style-2:before,
blockquote.wp-block-quote.style-2:before,
blockquote.wp-block-pullquote.style-2:before,
blockquote.wp-block-pullquote.alignright.style-2:before,
blockquote.wp-block-pullquote.alignleft.style-2:before,
blockquote.style-2:before {
  content: none;
}

.wp-block-quote.is-large.style-2 cite,
.wp-block-quote.is-style-large.style-2 cite,
blockquote.wp-block-quote.style-2 cite,
blockquote.wp-block-pullquote.style-2 cite,
blockquote.wp-block-pullquote.alignright.style-2 cite,
blockquote.wp-block-pullquote.alignleft.style-2 cite,
blockquote.style-2 cite {
  color: #777777;
  font-size: 18px;
  font-family: var(--font-family-title);
}

.wp-block-quote.is-large.style-2 cite:before,
.wp-block-quote.is-style-large.style-2 cite:before,
blockquote.wp-block-quote.style-2 cite:before,
blockquote.wp-block-pullquote.style-2 cite:before,
blockquote.wp-block-pullquote.alignright.style-2 cite:before,
blockquote.wp-block-pullquote.alignleft.style-2 cite:before,
blockquote.style-2 cite:before {
  background: #777777;
}

.wp-block-quote.is-large.style-3,
.wp-block-quote.is-style-large.style-3,
blockquote.wp-block-quote.style-3,
blockquote.wp-block-pullquote.style-3,
blockquote.wp-block-pullquote.alignright.style-3,
blockquote.wp-block-pullquote.alignleft.style-3,
blockquote.style-3 {
  text-align: center;
  padding: 0 0;
}

.wp-block-quote.is-large.style-3:after,
.wp-block-quote.is-style-large.style-3:after,
blockquote.wp-block-quote.style-3:after,
blockquote.wp-block-pullquote.style-3:after,
blockquote.wp-block-pullquote.alignright.style-3:after,
blockquote.wp-block-pullquote.alignleft.style-3:after,
blockquote.style-3:after {
  content: none;
}

.wp-block-quote.is-large.style-3:before,
.wp-block-quote.is-style-large.style-3:before,
blockquote.wp-block-quote.style-3:before,
blockquote.wp-block-pullquote.style-3:before,
blockquote.wp-block-pullquote.alignright.style-3:before,
blockquote.wp-block-pullquote.alignleft.style-3:before,
blockquote.style-3:before {
  background: var(--primary);
  opacity: 1;
  color: #fff;
  content: "\f10e";
  height: 60px;
  width: 60px;
  border-radius: 60px;
  position: relative;
  left: 0;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 28px;
  line-height: 60px;
  margin-bottom: 15px;
}

.wp-block-quote.is-large.style-3 cite,
.wp-block-quote.is-style-large.style-3 cite,
blockquote.wp-block-quote.style-3 cite,
blockquote.wp-block-pullquote.style-3 cite,
blockquote.wp-block-pullquote.alignright.style-3 cite,
blockquote.wp-block-pullquote.alignleft.style-3 cite,
blockquote.style-3 cite {
  font-size: 18px;
}

.wp-block-quote.is-large.style-3 cite:before,
.wp-block-quote.is-style-large.style-3 cite:before,
blockquote.wp-block-quote.style-3 cite:before,
blockquote.wp-block-pullquote.style-3 cite:before,
blockquote.wp-block-pullquote.alignright.style-3 cite:before,
blockquote.wp-block-pullquote.alignleft.style-3 cite:before,
blockquote.style-3 cite:before {
  content: none;
}

.wp-block-quote.is-large.style-4,
.wp-block-quote.is-style-large.style-4,
blockquote.wp-block-quote.style-4,
blockquote.wp-block-pullquote.style-4,
blockquote.wp-block-pullquote.alignright.style-4,
blockquote.wp-block-pullquote.alignleft.style-4,
blockquote.style-4 {
  background: var(--primary);
  padding: 30px 30px;
  border-radius: var(--border-radius-base);
}

.wp-block-quote.is-large.style-4 .title,
.wp-block-quote.is-style-large.style-4 .title,
blockquote.wp-block-quote.style-4 .title,
blockquote.wp-block-pullquote.style-4 .title,
blockquote.wp-block-pullquote.alignright.style-4 .title,
blockquote.wp-block-pullquote.alignleft.style-4 .title,
blockquote.style-4 .title {
  color: #fff;
  margin-bottom: 20px;
}

.wp-block-quote.is-large.style-4 cite,
.wp-block-quote.is-style-large.style-4 cite,
blockquote.wp-block-quote.style-4 cite,
blockquote.wp-block-pullquote.style-4 cite,
blockquote.wp-block-pullquote.alignright.style-4 cite,
blockquote.wp-block-pullquote.alignleft.style-4 cite,
blockquote.style-4 cite {
  color: #fff;
  font-size: 18px;
  font-family: var(--font-family-title);
}

.wp-block-quote.is-large.style-4 cite:before,
.wp-block-quote.is-style-large.style-4 cite:before,
blockquote.wp-block-quote.style-4 cite:before,
blockquote.wp-block-pullquote.style-4 cite:before,
blockquote.wp-block-pullquote.alignright.style-4 cite:before,
blockquote.wp-block-pullquote.alignleft.style-4 cite:before,
blockquote.style-4 cite:before {
  background: #fff;
}

.wp-block-quote.is-large.style-4:after,
.wp-block-quote.is-style-large.style-4:after,
blockquote.wp-block-quote.style-4:after,
blockquote.wp-block-pullquote.style-4:after,
blockquote.wp-block-pullquote.alignright.style-4:after,
blockquote.wp-block-pullquote.alignleft.style-4:after,
blockquote.style-4:after {
  content: none;
}

.wp-block-quote.is-large.style-4:before,
.wp-block-quote.is-style-large.style-4:before,
blockquote.wp-block-quote.style-4:before,
blockquote.wp-block-pullquote.style-4:before,
blockquote.wp-block-pullquote.alignright.style-4:before,
blockquote.wp-block-pullquote.alignleft.style-4:before,
blockquote.style-4:before {
  left: 30%;
  top: -10px;
  color: rgb(255, 255, 255);
  content: "\f11d";
  font-family: flaticon;
  height: auto;
  font-size: 180px;
  transform: rotate(180deg) translateX(-50%);
  width: auto;
  background: none;
  line-height: 1;
  opacity: 0.1;
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0;
}

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3;
}

.dz-blog.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  line-height: 1.5;
  color: var(--title);
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 22px;
  font-family: var(--font-family-title);
  font-style: normal;
}

.dz-blog.blog-single .dz-post-text blockquote p cite,
.dz-page-text blockquote p cite,
blockquote p cite {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {

  .dz-blog.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1.125rem;
  }
}

.dz-page-text {
  padding-bottom: 30px !important;
}

.dz-blog.blog-single .dz-post-text blockquote.style-1 p,
.dz-page-text blockquote.style-1 p,
blockquote.style-1 p {
  color: var(--primary);
  margin-bottom: 1.5rem;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/* Gutenberg Css */
.min-container,
.dz-page-text,
.dz-page-text+#comment-list,
.dz-page-text+.paginate-links,
.dz-page-text+#comment-list+.paginate-links {
  max-width: 53.125rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.dz-blog.blog-single.sidebar+#comment-list {
  padding: 0;
  max-width: 100%;
}

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.dz-page-text+.paginate-links,
.dz-page-text+#comment-list+.paginate-links,
.dz-page-text+#comment-list,
.dz-page-text {
  padding: 0;
}

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.dz-page-text>ol,
.dz-page-text>ul,
.dz-blog.blog-single .dz-post-text>ol,
.dz-blog.blog-single .dz-post-text>ul,
.dz-blog.blog-single .dz-post-text.text>ol,
.dz-blog.blog-single .dz-post-text.text>ul {
  padding-left: 1.25rem;
}

.dz-page-text>ol ol,
.dz-page-text>ol ul,
.dz-page-text>ul ol,
.dz-page-text>ul ul,
.dz-blog.blog-single .dz-post-text>ol ol,
.dz-blog.blog-single .dz-post-text>ol ul,
.dz-blog.blog-single .dz-post-text>ul ol,
.dz-blog.blog-single .dz-post-text>ul ul,
.dz-blog.blog-single .dz-post-text.text>ol ol,
.dz-blog.blog-single .dz-post-text.text>ol ul,
.dz-blog.blog-single .dz-post-text.text>ul ol,
.dz-blog.blog-single .dz-post-text.text>ul ul {
  padding-left: 1.563rem;
}

.dz-page-text>ol li,
.dz-page-text>ul li,
.dz-blog.blog-single .dz-post-text>ol li,
.dz-blog.blog-single .dz-post-text>ul li,
.dz-blog.blog-single .dz-post-text>ol li,
.dz-blog.blog-single .dz-post-text>ul li,
.dz-blog.blog-single .dz-post-text.text>ol li,
.dz-blog.blog-single .dz-post-text.text>ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit;
}

.dz-page-text>.wp-block-gallery,
.dz-blog.blog-single .dz-post-text>.wp-block-gallery,
.dz-blog.blog-single .dz-post-text.text>.wp-block-gallery {
  padding: 0;
  list-style: none;
}

.dz-post-text>.wp-block-gallery .wp-block-image,
.dz-page-text>.wp-block-gallery .wp-block-image,
.dz-page-text>.wp-block-gallery li,
.dz-blog.blog-single .dz-post-text>.wp-block-gallery li,
.dz-blog.blog-single .dz-post-text.text>.wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem;
}

.dz-page-text>.wp-block-gallery li:before,
.dz-blog.blog-single .dz-post-text>.wp-block-gallery li:before,
.dz-blog.blog-single .dz-post-text.text>.wp-block-gallery li:before {
  content: none;
}

.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .wp-block-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .wp-block-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem;
}

.content-full .wp-block-gallery {
  margin-left: -12.6565rem;
  margin-right: -12.6565rem;
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial;
}

.wp-block-gallery.alignwide,
.alignwide {
  margin-left: -14.5315rem;
  margin-right: -14.5315rem;
  width: calc(100% + 28.75rem + 0.313rem);
  max-width: initial;
}

.wp-block-gallery.alignfull,
.wp-block-gallery.alignfullwide,
.alignfull,
.alignfullwide {
  margin-left: calc(50% - 15px - (50vw - 30px) - var(--content-share-ml, 0px) / 2);
  width: calc(100vw - 30px);
  max-width: calc(100vw - 30px);
  box-sizing: border-box;
}

.dz-page-text h1,
.dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1 {
  margin-bottom: 1rem;
  font-weight: 600;
}

.dz-page-text h2,
.dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2 {
  margin-bottom: 1rem;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {

  .dz-page-text h2,
  .dz-page-text .h2,
  .dz-post-text h2,
  .dz-post-text .h2,
  .thm-unit-test .dz-post-text h2 {
    font-size: 1.5rem;
  }
}

.dz-page-text h3,
.dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3 {
  margin-bottom: 1rem;
  font-weight: 600;
}

.dz-page-text h4,
.dz-page-text .h4,
.dz-post-text h4,
.dz-post-text .h4,
.thm-unit-test .dz-post-text h4 {
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.dz-page-text h5,
.dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5 {
  margin-bottom: 0.75rem;
}

.dz-page-text h6,
.dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6 {
  margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem;
}

.wp-block-code>code {
  font-family: inherit;
  color: unset;
  padding: unset;
  border: unset;
  border-radius: unset;
}

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-blog.blog-single .dz-post-text,
.dz-blog.blog-single .dz-post-text ul li,
.dz-blog.blog-single .dz-post-text ol li,
.dz-blog.blog-single .dz-blog.blog-single .dz-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
  font-size: 1.125rem;
  line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
  border-right: 0;
}

.dz-page-text ul li,
.dz-page-text ol li,
.dz-blog.blog-single .dz-post-text ul li,
.dz-blog.blog-single .dz-post-text ol li {
  list-style: none;
  position: relative;
}

.dz-page-text ul>li:before,
.dz-page-text ol>li:before,
.dz-blog.blog-single .dz-post-text ul>li:before,
.dz-blog.blog-single .dz-post-text ol>li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #444444;
  left: -0.938rem;
  position: absolute;
  top: 1.063rem;
  border-radius: 4px;
}

.dz-page-text p,
.dz-blog.blog-single .dz-post-text p {
  margin-bottom: 30px;
  font-family: var(--font-family-title);
  color: #24262B;
}

@media only screen and (max-width: 575px) {

  .dz-page-text p,
  .dz-blog.blog-single .dz-post-text p {
    margin-bottom: 15px;
  }
}

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0;
}

.paginate-links>a,
.paginate-links>span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #777777;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 50px;
  min-width: 50px;
  height: 50px;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #f3f4f6;
  border-radius: var(--border-radius-base);
}

.paginate-links .current,
.paginate-links>a:hover,
.paginate-links>span:hover {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
}

.wp-block-columns {
  margin-bottom: 0;
}

/* Gutenberg Gallery */
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative;
}

.wp-block-gallery .wp-block-image figure,
.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery .wp-block-image figure,
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
}

.wp-block-gallery .wp-block-image img,
.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .wp-block-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
  border-radius: 20px;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery .wp-block-image img,
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

.wp-block-gallery .wp-block-image figcaption,
.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 60%, transparent);
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wp-block-gallery .wp-block-image figcaption img,
.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .wp-block-image a,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .wp-block-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .wp-block-image img,
  .wp-block-gallery.is-cropped .wp-block-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover;
  }
}

.wp-block-gallery .wp-block-image,
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 30px);
}

@media only screen and (max-width: 1199px) {

  .wp-block-gallery .wp-block-image,
  .wp-block-gallery .blocks-gallery-image,
  .wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 10px);
  }
}

.wp-block-gallery .wp-block-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .wp-block-image,
.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }

  .alignwide .alignleft img {
    width: 100%;
  }

  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none;
  }
}

@media (min-width: 37.5rem) {

  .wp-block-gallery.columns-2 .wp-block-image,
  .wp-block-gallery.columns-2 .blocks-gallery-image,
  .wp-block-gallery.columns-2 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(50% - 1.875rem);
  }

  .wp-block-gallery.columns-3 .wp-block-image,
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 1.875rem);
  }

  .wp-block-gallery.columns-4 .wp-block-image,
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 1.875rem);
  }

  .wp-block-gallery.columns-5 .wp-block-image,
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 1.875rem);
  }

  .wp-block-gallery.columns-6 .wp-block-image,
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 1.875rem);
  }

  .wp-block-gallery.columns-7 .wp-block-image,
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 1.875rem);
  }

  .wp-block-gallery.columns-8 .wp-block-image,
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 1.875rem);
  }

  .wp-block-gallery.columns-1 .wp-block-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .wp-block-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .wp-block-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .wp-block-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .wp-block-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .wp-block-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .wp-block-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .wp-block-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

.wp-block-gallery .wp-block-image:last-child,
.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .wp-block-image.has-add-item-button,
.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

.wp-block-image.alignfullwide img {
  border-radius: 0;
}

.wp-block-image img {
  border-radius: var(--border-radius-base);
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex;
}

.wp-block-gallery.aligncenter .wp-block-image figure,
.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-blog.sidebar .alignfullwide,
.blog-single.dz-blog.sidebar .alignwide,
.blog-single.dz-blog.sidebar .dz-post-text .wp-block-gallery {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: initial;
}

.blog-single.dz-blog.sidebar .dz-post-text .wp-block-gallery {
  width: calc(100% + 30px);
}

@media only screen and (max-width: 1199px) {
  .blog-single.dz-blog.sidebar .dz-post-text .wp-block-gallery {
    width: calc(100% + 10px);
  }
}

.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dz-blog.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin-top: 0.625rem;
}

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0;
}

.wp-block-media-text {
  margin-bottom: 30px;
}

.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.875rem;
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important;
}

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0;
}

.wp-block-pullquote cite {
  color: inherit;
}

.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}

.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
  margin: 0;
}

.dz-title-text {
  font-size: 14px;
  margin-bottom: 30px;
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff;
}

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem;
}

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
  top: 1.875rem;
}

.wp-block-quote.is-style-large cite:before {
  display: none;
}

/* Theme Unit Test */
.post-password-form label {
  font-size: 1rem;
}

.post-password-form input[type=submit] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
}

.post-password-form input[type=submit]:hover {
  background-color: var(--primary);
}

.post-password-form input[type=password] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.wp-block-search .wp-block-search__button {
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0;
  position: absolute;
  right: 0;
  height: 100%;
  width: 45px;
  padding: 0 !important;
}

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  border-radius: var(--border-radius-base) !important;
  outline: none;
  background: #f5f5f5;
  padding: 10px 55px 10px 15px !important;
}

.wp-block-search .wp-block-search__inside-wrapper {
  border: 0 !important;
  padding: 0 !important;
  position: relative;
}

.wp-block-latest-comments {
  padding: 0 !important;
}

.wp-block-latest-comments .avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
  margin-left: 4.375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  color: inherit;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
  color: inherit;
  box-shadow: none;
  text-decoration: none;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a:hover {
  color: var(--primary);
}

.wp-block-latest-comments img+article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  margin-left: 4.375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
  display: block;
  width: 100%;
  color: #9fa1a4;
  font-size: 0.813rem;
  font-weight: 400;
}

.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0.5rem !important;
}

.wp-block-latest-comments li {
  padding: 0 !important;
  border-bottom: 0.0625rem solid #eee;
  padding-bottom: 0.5rem !important;
}

.wp-block-latest-comments li:before {
  content: none !important;
}

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.comment-content.dz-page-text {
  max-width: 100%;
  padding-bottom: 0 !important;
}

.comment-content.dz-page-text> :last-child {
  margin-bottom: 0;
}

.wp-block-rss {
  padding: 0 !important;
}

.wp-block-rss .wp-block-rss__item {
  padding: 0.3125rem 0 !important;
  border-bottom: 0.0625rem solid #eee;
}

.wp-block-rss .wp-block-rss__item:before {
  content: none !important;
}

.wp-block-rss .wp-block-rss__item a {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  box-shadow: unset !important;
  font-weight: 600;
  color: var(--title);
  text-decoration: none;
}

@media only screen and (max-width: 82.5rem) {

  .dz-page-text .wp-block-gallery,
  .dz-blog.blog-single .dz-post-text .wp-block-gallery {
    margin-left: -6.5625rem;
    margin-right: -6.5625rem;
    width: calc(100% + 12.5rem + 0.625rem);
  }
}

@media only screen and (max-width: 1199px) {

  .dz-page-text .wp-block-gallery,
  .dz-blog.blog-single .dz-post-text .wp-block-gallery {
    margin-left: -5.3125rem;
    margin-right: -5.3125rem;
    width: calc(100% + 10rem + 0.625rem);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important;
  }

  .dz-page-text .wp-block-gallery,
  .dz-blog.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-blog.blog-single .dz-post-text,
  .dz-blog.blog-single .dz-post-text ul li,
  .dz-blog.blog-single .dz-post-text ol li,
  .dz-blog.blog-single .dz-post-text p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem;
  }

  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }

  .wp-block-media-text {
    display: block;
  }

  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem;
  }

  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0;
  }

  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }

  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important;
  }
}

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0;
}

.post-footer .dz-meta .tag-list {
  padding-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  display: inline;
}

.post-footer .dz-meta ul li {
  margin-right: 0.3125rem;
  padding: 0;
  display: inline-block;
  color: #333333;
  font-weight: 500;
  font-size: 0.938rem;
  font-style: italic;
}

.post-footer .post-tag a {
  text-transform: capitalize;
  font-size: 0.938rem;
  color: #999;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 0.875rem;
  font-weight: 500;
}

.share-post li {
  display: inline-block;
}

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #444444;
}

.extra-blog {
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px;
  }
}

.comment-reply-title:before {
  content: none;
}

@media only screen and (max-width: 767px) {
  .default-form {
    margin-bottom: 30px;
  }
}

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0;
}

.comments-area .comment-list>.comment .comment-body {
  position: relative;
  padding: 2px 0 30px 100px;
  margin-bottom: 30px;
  min-height: 115px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.comments-area .comment-list>.comment .comment-body .comment-author .avatar {
  position: absolute;
  left: 0;
  height: 85px;
  width: 85px;
  top: 0;
}

.comments-area .comment-list>.comment .comment-body .comment-author .fn {
  font-family: var(--font-family-title);
  font-size: 16px;
  line-height: 21px;
  color: var(--title);
  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;
  display: block;
}

.comments-area .comment-list>.comment .comment-body p {
  font-size: 14px;
  font-family: var(--font-family-base);
  margin-bottom: 10px;
  color: #666666;
}

.comments-area .comment-list>.comment .comment-body p:last-child {
  margin-bottom: 0;
}

.comments-area .comment-list>.comment .comment-body .reply {
  line-height: 1;
}

.comments-area .comment-list>.comment .comment-body .reply .comment-reply-link {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  background: var(--secondary);
  color: #fff;
  padding: 3px 10px;
  display: inline-block;
  line-height: 1.3;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  position: absolute;
  right: 0;
  top: 0;
  font-family: var(--font-family-title);
  border-radius: 4px;
}

.comments-area .comment-list>.comment .comment-body .reply .comment-reply-link i {
  margin-right: 8px;
}

.comments-area .comment-list>.comment>.children {
  padding-left: 50px;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment-list>.comment>.children {
    padding-left: 0px;
  }
}

.comments-area .comment-list .default-form {
  margin-bottom: 30px;
  margin-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #E1E1F0;
}

@media only screen and (max-width: 991px) {
  .comments-area .comment-list {
    margin-bottom: 40px;
  }
}

.comments-area.style-1 .comment-list>.comment .comment-body .comment-author .avatar {
  border-radius: 50%;
}

.comments-area.style-1 .comment-list>.comment:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.comments-area.style-2 .comment-list>.comment {
  border-bottom: 1px solid #D7D7D7;
  margin-bottom: 30px;
}

.comments-area.style-2 .comment-list>.comment .comment-body .comment-author .avatar {
  border-radius: 50%;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border: 5px solid #ffffff;
}

.comments-area.style-2 .comment-list>.comment:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.comments-area.style-3 .comment-list>.comment {
  margin-bottom: 30px;
}

.comments-area.style-3 .comment-list>.comment .comment-body .comment-author .avatar {
  border-radius: 5px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border: 5px solid #ffffff;
}

.comments-area.style-3 .comment-list>.comment:last-child {
  margin-bottom: 0;
}

.comment-form-cookies-consent {
  display: none;
}

.comment-reply-title a {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
  margin-left: 10px;
}

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.comment-respond .comment-form p {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-respond .comment-form p label {
  display: none;
}

.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  position: relative;
  font-size: 15px;
  border: 1px solid var(--secondary);
  background: transparent;
  border-radius: 10px;
}

.comment-respond .comment-form p.comment-form-comment textarea {
  height: 150px !important;
}

.comment-respond .comment-form p.form-submit {
  margin-bottom: 0;
}

.comment-respond .comment-form .comment-form-comment {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-respond.style-1 .comment-form .comment-form-author,
.comment-respond.style-1 .comment-form .comment-form-email,
.comment-respond.style-1 .comment-form .comment-form-url,
.comment-respond.style-3 .comment-form .comment-form-author,
.comment-respond.style-3 .comment-form .comment-form-email,
.comment-respond.style-3 .comment-form .comment-form-url {
  width: 50%;
}

.comment-respond.style-2 .comment-form .comment-form-author,
.comment-respond.style-2 .comment-form .comment-form-email {
  width: 50%;
}

.comment-respond.style-2 .comment-form .comment-form-url {
  width: 100%;
}

@media only screen and (max-width: 575px) {

  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important;
  }
}

.dz-page-text [class*=galleryid-],
.dz-post-text [class*=galleryid-] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box;
}

.gallery .gallery-item img {
  float: left;
  padding: 0 0rem;
  width: 100%;
  border: none !important;
}

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.post-password-form {
  position: relative;
  clear: both;
}

.post-password-form label {
  display: block;
  font-size: 1rem;
}

.post-password-form input[type=password] {
  width: 100%;
  border: 0.0625rem solid #ebedf2;
  padding: 0.625rem 6.25rem 0.625rem 0.938rem;
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.post-password-form input[type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1.25rem;
  background: var(--primary);
  color: #FFF;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  height: 2.8125rem;
}

.post-password-form input[type=submit]:hover {
  background: var(--primary-hover);
}

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset;
    padding: 0;
  }
}

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.blog-post-banner .dz-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}

.blog-post-banner .dz-meta {
  color: #fff;
}

.blog-post-banner .dz-meta ul {
  display: flex;
  opacity: 0.7;
}

.blog-post-banner .dz-meta ul li {
  margin-right: 20px;
}

.blog-post-banner .dz-meta ul li a {
  color: #fff;
}

.post-outside {
  margin-top: -120px;
}

.single-post .main-bar {
  border-bottom: 1px solid #eee;
}

.dz-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important;
}

.dz-share-post {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 60px;
}

.dz-share-post .post-tags strong {
  font-weight: 500;
  font-size: 14px;
  color: #24262B;
}

.dz-share-post .post-tags a {
  color: #5E626F;
  border-radius: var(--border-radius-base);
  margin: 0 8px;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-share-post .post-tags a:hover {
  color: var(--primary);
}

.dz-share-post .post-tags a:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 991px) {
  .dz-share-post {
    margin-bottom: 40px;
  }
}

.dz-share-post .dz-social-icon li {
  margin-right: 5px;
}

.dz-share-post .dz-social-icon li a {
  display: block;
  height: 35px;
  width: 35px;
  min-width: 35px;
  line-height: 35px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .dz-share-post .post-tags a {
    margin: 0 3px;
  }

  .dz-share-post .dz-social {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-share-post .dz-social-icon+.post-tags {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-share-post .post-tags a {
    margin: 0 5px;
  }
}

#comment-list:empty+.paginate-links {
  margin-top: -30px;
}

.post-swiper,
.post-swiper-thumb,
.service-swiper {
  position: relative;
}

.post-swiper .btn-next,
.post-swiper .btn-prev,
.post-swiper .prev-post-swiper-btn,
.post-swiper .next-post-swiper-btn,
.post-swiper .prev-service-swiper-btn,
.post-swiper .next-service-swiper-btn,
.post-swiper-thumb .btn-next,
.post-swiper-thumb .btn-prev,
.post-swiper-thumb .prev-post-swiper-btn,
.post-swiper-thumb .next-post-swiper-btn,
.post-swiper-thumb .prev-service-swiper-btn,
.post-swiper-thumb .next-service-swiper-btn,
.service-swiper .btn-next,
.service-swiper .btn-prev,
.service-swiper .prev-post-swiper-btn,
.service-swiper .next-post-swiper-btn,
.service-swiper .prev-service-swiper-btn,
.service-swiper .next-service-swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  border-radius: var(--border-radius-base);
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  background-color: #fff;
  color: var(--primary);
  z-index: 1;
  margin: 0 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.post-swiper .btn-next:hover,
.post-swiper .btn-prev:hover,
.post-swiper .prev-post-swiper-btn:hover,
.post-swiper .next-post-swiper-btn:hover,
.post-swiper .prev-service-swiper-btn:hover,
.post-swiper .next-service-swiper-btn:hover,
.post-swiper-thumb .btn-next:hover,
.post-swiper-thumb .btn-prev:hover,
.post-swiper-thumb .prev-post-swiper-btn:hover,
.post-swiper-thumb .next-post-swiper-btn:hover,
.post-swiper-thumb .prev-service-swiper-btn:hover,
.post-swiper-thumb .next-service-swiper-btn:hover,
.service-swiper .btn-next:hover,
.service-swiper .btn-prev:hover,
.service-swiper .prev-post-swiper-btn:hover,
.service-swiper .next-post-swiper-btn:hover,
.service-swiper .prev-service-swiper-btn:hover,
.service-swiper .next-service-swiper-btn:hover {
  background-color: var(--primary);
  color: #fff;
}

@media only screen and (max-width: 767px) {

  .post-swiper .btn-next,
  .post-swiper .btn-prev,
  .post-swiper .prev-post-swiper-btn,
  .post-swiper .next-post-swiper-btn,
  .post-swiper .prev-service-swiper-btn,
  .post-swiper .next-service-swiper-btn,
  .post-swiper-thumb .btn-next,
  .post-swiper-thumb .btn-prev,
  .post-swiper-thumb .prev-post-swiper-btn,
  .post-swiper-thumb .next-post-swiper-btn,
  .post-swiper-thumb .prev-service-swiper-btn,
  .post-swiper-thumb .next-service-swiper-btn,
  .service-swiper .btn-next,
  .service-swiper .btn-prev,
  .service-swiper .prev-post-swiper-btn,
  .service-swiper .next-post-swiper-btn,
  .service-swiper .prev-service-swiper-btn,
  .service-swiper .next-service-swiper-btn {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
}

@media only screen and (max-width: 575px) {

  .post-swiper .btn-next,
  .post-swiper .btn-prev,
  .post-swiper .prev-post-swiper-btn,
  .post-swiper .next-post-swiper-btn,
  .post-swiper .prev-service-swiper-btn,
  .post-swiper .next-service-swiper-btn,
  .post-swiper-thumb .btn-next,
  .post-swiper-thumb .btn-prev,
  .post-swiper-thumb .prev-post-swiper-btn,
  .post-swiper-thumb .next-post-swiper-btn,
  .post-swiper-thumb .prev-service-swiper-btn,
  .post-swiper-thumb .next-service-swiper-btn,
  .service-swiper .btn-next,
  .service-swiper .btn-prev,
  .service-swiper .prev-post-swiper-btn,
  .service-swiper .next-post-swiper-btn,
  .service-swiper .prev-service-swiper-btn,
  .service-swiper .next-service-swiper-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 18px;
  }
}

.post-swiper .btn-prev,
.post-swiper .prev-post-swiper-btn,
.post-swiper .prev-service-swiper-btn,
.post-swiper-thumb .btn-prev,
.post-swiper-thumb .prev-post-swiper-btn,
.post-swiper-thumb .prev-service-swiper-btn,
.service-swiper .btn-prev,
.service-swiper .prev-post-swiper-btn,
.service-swiper .prev-service-swiper-btn {
  left: 0;
}

.post-swiper .btn-next,
.post-swiper .next-post-swiper-btn,
.post-swiper .next-service-swiper-btn,
.post-swiper-thumb .btn-next,
.post-swiper-thumb .next-post-swiper-btn,
.post-swiper-thumb .next-service-swiper-btn,
.service-swiper .btn-next,
.service-swiper .next-post-swiper-btn,
.service-swiper .next-service-swiper-btn {
  right: 0;
}

.service-swiper {
  border-radius: var(--border-radius-base);
}

.service-swiper img {
  border-radius: var(--border-radius-base);
}

.blog-single .dz-media+.dz-info {
  padding: 30px 0 0;
}

.wp-block-code>code {
  color: #fff;
}

#comment-list:empty+.paginate-links {
  margin-top: -30px;
}

.bypostauthor {
  font-size: normal;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image) {
  margin: 0 var(--gallery-block--gutter-size, 30px) var(--gallery-block--gutter-size, 30px) 0;
}

@media only screen and (max-width: 1199px) {
  .wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image) {
    margin: 0 var(--gallery-block--gutter-size, 10px) var(--gallery-block--gutter-size, 10px) 0;
  }
}

.post-audio iframe {
  height: 400px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .post-audio iframe {
    height: 350px;
  }
}

@media only screen and (max-width: 575px) {
  .post-audio iframe {
    height: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-blog.blog-single .embed-responsive iframe {
    width: 100%;
  }
}

.wp-block-group.has-background {
  padding: 1.5em 2em;
}

:where(.wp-block-group.has-background) {
  padding: 1.5em 2em;
}

.post-btn {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ededee;
  border-bottom: 1px solid #ededee;
  padding: 15px 0;
  margin: 50px 0px;
  position: relative;
}

.post-btn:after {
  content: "";
  height: calc(100% - 30px);
  width: 1px;
  background: #ededee;
  position: absolute;
  left: 50%;
  top: 15px;
}

.post-btn .prev-post,
.post-btn .next-post {
  max-width: 50%;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  position: relative;
}

.post-btn .prev-post img,
.post-btn .next-post img {
  height: 70px;
  width: 85px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {

  .post-btn .prev-post img,
  .post-btn .next-post img {
    display: none;
  }
}

.post-btn .prev-post .start,
.post-btn .prev-post .end,
.post-btn .next-post .start,
.post-btn .next-post .end {
  color: #b5b5b5;
  cursor: not-allowed;
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {

  .post-btn .prev-post .title,
  .post-btn .next-post .title {
    font-size: 14px;
  }
}

.post-btn .prev-post .title a,
.post-btn .next-post .title a {
  display: block;
  margin-bottom: 5px;
}

.post-btn .prev-post .title .post-date,
.post-btn .next-post .title .post-date {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #666;
}

@media only screen and (max-width: 767px) {

  .post-btn .prev-post .title .post-date,
  .post-btn .next-post .title .post-date {
    font-size: 12px;
  }
}

.post-btn .next-post {
  padding-right: 30px;
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .post-btn .next-post {
    padding-right: 20px;
  }
}

.post-btn .next-post .title {
  text-align: right;
  width: 100%;
  margin-bottom: 0;
}

.post-btn .next-post .title+img {
  margin-left: 20px;
}

.post-btn .next-post .end {
  text-align: right;
}

.post-btn .next-post:after {
  content: "\f105";
  font-family: "FontAwesome";
  color: var(--primary);
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
}

.post-btn .prev-post {
  padding-left: 30px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .post-btn .prev-post {
    padding-left: 20px;
  }
}

.post-btn .prev-post .title {
  margin-bottom: 0;
  width: 100%;
}

.post-btn .prev-post img+.title {
  padding: 0 20px;
}

.post-btn .prev-post:after {
  content: "\f104";
  font-family: "FontAwesome";
  color: var(--primary);
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
}

.dz-coming-soon {
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

.dz-coming-soon .bg-img {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
}

.dz-coming-soon .logo {
  width: 170px;
}

.dz-coming-bx {
  padding: 50px 80px 80px 80px;
}

@media only screen and (max-width: 1680px) {
  .dz-coming-bx {
    padding: 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-coming-bx {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-coming-bx {
    padding: 20px;
  }
}

.dz-coming-bx .dz-content {
  margin-top: 60px;
}

@media only screen and (max-width: 1680px) {
  .dz-coming-bx .dz-content {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-coming-bx .dz-content {
    margin-top: 20px;
  }
}

.dz-coming-bx .dz-content .dz-title {
  font-family: var(--font-family-title);
  font-size: 50px;
  font-weight: 500;
  line-height: 1.15;
  text-transform: capitalize;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1680px) {
  .dz-coming-bx .dz-content .dz-title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-coming-bx .dz-content .dz-title {
    font-size: 24px;
    margin-bottom: 12px;
  }
}

.dz-coming-bx .dz-content p {
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 475px;
  color: var(--title);
  margin-bottom: 40px;
}

@media only screen and (max-width: 1680px) {
  .dz-coming-bx .dz-content p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-coming-bx .dz-content p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

.single-page .dzSubscribe {
  padding: 0;
  margin-bottom: 40px;
}

.single-page .dzSubscribe .form-control,
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=text],
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=email],
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=password],
.single-page .dzSubscribe .login input[type=text],
.login .single-page .dzSubscribe input[type=text],
.single-page .dzSubscribe .login input[type=password],
.login .single-page .dzSubscribe input[type=password],
.single-page .dzSubscribe .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .single-page .dzSubscribe input[type=text],
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=text],
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=email],
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=tel],
.single-page .dzSubscribe .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .single-page .dzSubscribe .select2-choice,
.single-page .dzSubscribe .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .single-page .dzSubscribe textarea,
.single-page .dzSubscribe .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .single-page .dzSubscribe input.input-text,
.single-page .dzSubscribe .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .single-page .dzSubscribe .select2-selection,
.single-page .dzSubscribe .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .single-page .dzSubscribe .select2-choice,
.single-page .dzSubscribe .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .single-page .dzSubscribe textarea,
.single-page .dzSubscribe .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .single-page .dzSubscribe select,
.single-page .dzSubscribe .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .single-page .dzSubscribe input,
.single-page .dzSubscribe .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .single-page .dzSubscribe input,
.single-page .dzSubscribe .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .single-page .dzSubscribe .input-text,
.single-page .dzSubscribe .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .single-page .dzSubscribe input[type=text],
.single-page .dzSubscribe .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .single-page .dzSubscribe textarea,
.single-page .dzSubscribe .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .single-page .dzSubscribe input[type=email] {
  height: 50px;
  border-radius: 10px !important;
  max-width: 362px;
  margin-right: 11px;
  border: 1px solid var(--secondary);
  font-family: var(--font-family-base);
  font-size: 15px;
  font-weight: 400;
}

.single-page .dzSubscribe .form-control:focus,
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=text]:focus,
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=email]:focus,
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=password]:focus,
.single-page .dzSubscribe .login input[type=text]:focus,
.login .single-page .dzSubscribe input[type=text]:focus,
.single-page .dzSubscribe .login input[type=password]:focus,
.login .single-page .dzSubscribe input[type=password]:focus,
.single-page .dzSubscribe .woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.woocommerce-ResetPassword.lost_reset_password .single-page .dzSubscribe input[type=text]:focus,
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=text]:focus,
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=email]:focus,
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=tel]:focus,
.single-page .dzSubscribe .checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout .single-page .dzSubscribe .select2-choice:focus,
.single-page .dzSubscribe .checkout.woocommerce-checkout textarea:focus,
.checkout.woocommerce-checkout .single-page .dzSubscribe textarea:focus,
.single-page .dzSubscribe .woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .single-page .dzSubscribe input.input-text:focus,
.single-page .dzSubscribe .woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .single-page .dzSubscribe .select2-selection:focus,
.single-page .dzSubscribe .woocommerce form .form-row .select2-choice:focus,
.woocommerce form .form-row .single-page .dzSubscribe .select2-choice:focus,
.single-page .dzSubscribe .woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .single-page .dzSubscribe select:focus,
.single-page .dzSubscribe .woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .single-page .dzSubscribe input:focus,
.single-page .dzSubscribe .woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr .quantity .single-page .dzSubscribe input:focus,
.single-page .dzSubscribe .woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce-cart-form .table tr td.actions .coupon .single-page .dzSubscribe .input-text:focus,
.single-page .dzSubscribe .woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond .single-page .dzSubscribe input[type=text]:focus,
.single-page .dzSubscribe .woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond .single-page .dzSubscribe textarea:focus,
.single-page .dzSubscribe .woocommerce #review_form #respond input[type=email]:focus,
.woocommerce #review_form #respond .single-page .dzSubscribe input[type=email]:focus {
  box-shadow: unset;
}

.single-page .dzSubscribe .form-control:placeholder,
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=text]:placeholder,
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=text]:placeholder,
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=email]:placeholder,
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=email]:placeholder,
.single-page .dzSubscribe .woocommerce-EditAccountForm.edit-account input[type=password]:placeholder,
.woocommerce-EditAccountForm.edit-account .single-page .dzSubscribe input[type=password]:placeholder,
.single-page .dzSubscribe .login input[type=text]:placeholder,
.login .single-page .dzSubscribe input[type=text]:placeholder,
.single-page .dzSubscribe .login input[type=password]:placeholder,
.login .single-page .dzSubscribe input[type=password]:placeholder,
.single-page .dzSubscribe .woocommerce-ResetPassword.lost_reset_password input[type=text]:placeholder,
.woocommerce-ResetPassword.lost_reset_password .single-page .dzSubscribe input[type=text]:placeholder,
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=text]:placeholder,
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=text]:placeholder,
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=email]:placeholder,
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=email]:placeholder,
.single-page .dzSubscribe .checkout.woocommerce-checkout input[type=tel]:placeholder,
.checkout.woocommerce-checkout .single-page .dzSubscribe input[type=tel]:placeholder,
.single-page .dzSubscribe .checkout.woocommerce-checkout .select2-choice:placeholder,
.checkout.woocommerce-checkout .single-page .dzSubscribe .select2-choice:placeholder,
.single-page .dzSubscribe .checkout.woocommerce-checkout textarea:placeholder,
.checkout.woocommerce-checkout .single-page .dzSubscribe textarea:placeholder,
.single-page .dzSubscribe .woocommerce form .form-row input.input-text:placeholder,
.woocommerce form .form-row .single-page .dzSubscribe input.input-text:placeholder,
.single-page .dzSubscribe .woocommerce form .form-row .select2-selection:placeholder,
.woocommerce form .form-row .single-page .dzSubscribe .select2-selection:placeholder,
.single-page .dzSubscribe .woocommerce form .form-row .select2-choice:placeholder,
.woocommerce form .form-row .single-page .dzSubscribe .select2-choice:placeholder,
.single-page .dzSubscribe .woocommerce .woocommerce-checkout.checkout form .form-row select:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .single-page .dzSubscribe select:placeholder,
.single-page .dzSubscribe .woocommerce .woocommerce-checkout.checkout form .form-row input:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .single-page .dzSubscribe input:placeholder,
.single-page .dzSubscribe .woocommerce-cart-form .table tr .quantity input:placeholder,
.woocommerce-cart-form .table tr .quantity .single-page .dzSubscribe input:placeholder,
.single-page .dzSubscribe .woocommerce-cart-form .table tr td.actions .coupon .input-text:placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .single-page .dzSubscribe .input-text:placeholder,
.single-page .dzSubscribe .woocommerce #review_form #respond input[type=text]:placeholder,
.woocommerce #review_form #respond .single-page .dzSubscribe input[type=text]:placeholder,
.single-page .dzSubscribe .woocommerce #review_form #respond textarea:placeholder,
.woocommerce #review_form #respond .single-page .dzSubscribe textarea:placeholder,
.single-page .dzSubscribe .woocommerce #review_form #respond input[type=email]:placeholder,
.woocommerce #review_form #respond .single-page .dzSubscribe input[type=email]:placeholder {
  color: var(--title);
}

.single-page .dzSubscribe .btn,
.single-page .dzSubscribe .woocommerce #respond input#submit,
.woocommerce #respond .single-page .dzSubscribe input#submit,
.single-page .dzSubscribe .woocommerce a.button,
.woocommerce .single-page .dzSubscribe a.button,
.single-page .dzSubscribe .woocommerce button.button,
.woocommerce .single-page .dzSubscribe button.button,
.single-page .dzSubscribe .woocommerce input.button,
.woocommerce .single-page .dzSubscribe input.button,
.single-page .dzSubscribe .woocommerce #place_order,
.woocommerce .single-page .dzSubscribe #place_order,
.single-page .dzSubscribe .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .single-page .dzSubscribe a.checkout-button,
.single-page .dzSubscribe .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .single-page .dzSubscribe .button,
.single-page .dzSubscribe .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .single-page .dzSubscribe input,
.single-page .dzSubscribe .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .single-page .dzSubscribe a,
.single-page .dzSubscribe .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .single-page .dzSubscribe a,
.single-page .dzSubscribe .wp-block-button__link {
  position: unset;
  height: 50px;
  width: unset;
  padding: 15px 32px;
  font-family: var(--font-family-title);
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.dz-coming-bx2 {
  background: #FFEDD4;
  position: relative;
  z-index: 1;
  padding: 60px 60px 0px 60px;
  overflow: hidden;
  height: 100%;
}

@media only screen and (max-width: 1199px) {
  .dz-coming-bx2 {
    padding: 30px 30px 0px 30px;
  }
}

.dz-coming-bx2:after {
  position: absolute;
  content: "";
  width: 260px;
  height: 1505px;
  background: #FEEB9D;
  z-index: -1;
  right: 170px;
  top: -22%;
  transform: rotate(45deg);
}

.dz-coming-bx2 .bg-img2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}

.dz-coming-bx2 .dz-coming-media {
  position: relative;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: end;
  width: 100%;
}

.dz-coming-bx2 .dz-coming-media img {
  width: 100%;
}

.countdown-row {
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: 70px;
}

@media only screen and (max-width: 1680px) {
  .countdown-row {
    bottom: 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .countdown-row {
    bottom: 30px;
  }
}

@media screen and (max-height: 550px) {
  .countdown-row {
    bottom: auto;
    position: unset;
  }
}

.countdown {
  display: flex;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  margin: 0 -30px;
}

@media only screen and (max-width: 1199px) {
  .countdown {
    margin: 0 -10px;
  }
}

.countdown .date {
  padding: 0 30px;
}

@media only screen and (max-width: 1199px) {
  .countdown .date {
    padding: 0 10px;
  }
}

.countdown .date .time {
  font-size: 65px;
  vertical-align: middle;
  color: var(--title);
  font-weight: 600;
  font-family: var(--font-family-title);
  line-height: 1.2;
}

@media only screen and (max-width: 1199px) {
  .countdown .date .time {
    font-size: 50px;
  }
}

.countdown .date .text {
  font-weight: 600;
  font-size: 15px;
  font-family: var(--font-family-title);
  color: var(--title);
  text-transform: uppercase;
  display: block;
}

@media only screen and (max-width: 1199px) {
  .countdown .date .text {
    font-size: 12px;
  }
}

.under-construct {
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
}

.under-construct .bg-img {
  position: absolute;
  bottom: -30px;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 575px) {
  .under-construct .dz-social-icon.style-2 li a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 18px;
  }
}

.construct-box-1 {
  padding: 90px 75px 50px;
}

@media only screen and (max-width: 1680px) {
  .construct-box-1 {
    padding: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .construct-box-1 {
    padding: 30px;
  }
}

.construct-box-1 .logo {
  width: 230px;
}

.construct-box-1 .dz-content {
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .construct-box-1 .dz-content {
    margin-top: 20px;
  }
}

.construct-box-1 .dz-content .dz-media-title {
  font-family: var(--font-family-title);
  font-size: 100px;
  font-weight: 500;
  color: var(--title);
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  line-height: 1.2;
  margin-bottom: 5px;
}

.construct-box-1 .dz-content .dz-media-title img {
  margin-right: 22px;
}

@media only screen and (max-width: 1680px) {
  .construct-box-1 .dz-content .dz-media-title img {
    width: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .construct-box-1 .dz-content .dz-media-title img {
    width: 50px;
  }
}

@media only screen and (max-width: 1680px) {
  .construct-box-1 .dz-content .dz-media-title {
    font-size: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .construct-box-1 .dz-content .dz-media-title {
    font-size: 50px;
  }
}

.construct-box-1 .dz-content .dz-title {
  font-size: 60px;
  font-weight: 600;
  color: var(--title);
  font-family: var(--font-family-title);
  line-height: 1.2;
  max-width: 575px;
  margin-bottom: 36px;
}

@media only screen and (max-width: 1680px) {
  .construct-box-1 .dz-content .dz-title {
    margin-bottom: 25px;
    font-size: 45px;
  }
}

@media only screen and (max-width: 1199px) {
  .construct-box-1 .dz-content .dz-title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 575px) {
  .construct-box-1 .dz-content .dz-title {
    font-size: 30px;
  }
}

.construct-box-1 .dz-social-icon {
  position: absolute;
  bottom: 95px;
  top: auto;
  z-index: 1;
}

@media only screen and (max-width: 1680px) {
  .construct-box-1 .dz-social-icon {
    bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .construct-box-1 .dz-social-icon {
    bottom: 30px;
  }
}

@media screen and (max-height: 500px) {
  .construct-box-1 .dz-social-icon {
    bottom: auto;
    position: unset;
  }
}

.construct-box-2 {
  background-color: #FFEDD4;
  position: relative;
  z-index: 1;
}

.construct-box-2:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 16%;
  width: 51%;
  height: 60%;
  background: white;
  border-radius: 50% 50% 0px 0px;
  z-index: -1;
}

.construct-box-2 .construct-media {
  position: relative;
  margin: auto;
  margin-top: 0;
  text-align: center;
  display: flex;
  align-items: end;
  justify-content: center;
  height: 100%;
  padding-top: 100px;
}

.construct-box-2 .construct-media img {
  width: 50%;
}

@media only screen and (max-width: 1199px) {
  .construct-box-2 .construct-media img {
    width: 80%;
  }
}

.error-page {
  position: relative;
}

.error-page .bg-img,
.error-page .bg-img2 {
  position: absolute;
  bottom: -30px;
  left: 0;
}

.error-page .bg-img2 {
  right: 0px;
  top: -50px;
  left: auto;
  bottom: auto;
}

@media only screen and (max-width: 1480px) {
  .error-page .bg-img {
    bottom: -16%;
    left: -5%;
  }

  .error-page .bg-img2 {
    right: -6%;
    top: -16%;
  }
}

@media only screen and (max-width: 991px) {

  .error-page .bg-img,
  .error-page .bg-img2 {
    display: none;
  }
}

.error-page.style-1 {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 30px;
}

.error-page.style-1 .dz-error-media {
  margin-right: 65px;
}

.error-page.style-1 .error-inner .error-head {
  font-family: var(--font-family-title);
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 37px;
  color: var(--title);
}

.error-page.style-1 .error-inner .dz_error {
  font-family: var(--font-family-title);
  font-size: 120px;
  font-weight: 600;
  color: var(--title);
  line-height: 0.7;
  margin-bottom: 20px;
}

.error-page.style-1 .error-inner .btn,
.error-page.style-1 .error-inner .woocommerce #respond input#submit,
.woocommerce #respond .error-page.style-1 .error-inner input#submit,
.error-page.style-1 .error-inner .woocommerce a.button,
.woocommerce .error-page.style-1 .error-inner a.button,
.error-page.style-1 .error-inner .woocommerce button.button,
.woocommerce .error-page.style-1 .error-inner button.button,
.error-page.style-1 .error-inner .woocommerce input.button,
.woocommerce .error-page.style-1 .error-inner input.button,
.error-page.style-1 .error-inner .woocommerce #place_order,
.woocommerce .error-page.style-1 .error-inner #place_order,
.error-page.style-1 .error-inner .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .error-page.style-1 .error-inner a.checkout-button,
.error-page.style-1 .error-inner .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .error-page.style-1 .error-inner .button,
.error-page.style-1 .error-inner .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .error-page.style-1 .error-inner input,
.error-page.style-1 .error-inner .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .error-page.style-1 .error-inner a,
.error-page.style-1 .error-inner .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .error-page.style-1 .error-inner a,
.error-page.style-1 .error-inner .wp-block-button__link {
  font-size: 16px;
}

@media only screen and (max-width: 1480px) {
  .error-page.style-1 .dz-error-media {
    margin-right: 40px;
  }

  .error-page.style-1 .error-inner .error-head {
    font-size: 28px;
  }
}

@media only screen and (max-width: 991px) {
  .error-page.style-1 .dz-error-media {
    width: 200px;
    min-width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .error-page.style-1 {
    margin-bottom: 30px;
  }

  .error-page.style-1 .dz-error-media {
    margin-right: 30px;
    width: 180px;
    min-width: 180px;
  }

  .error-page.style-1 .error-inner .dz_error {
    font-size: 100px;
  }

  .error-page.style-1 .error-inner .error-head {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .error-page.style-1 {
    display: block;
    text-align: center;
  }

  .error-page.style-1 .dz-error-media {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
  }
}

.error-page.style-2 {
  min-height: 100vh;
}

.error-page.style-2 .dz_error {
  color: #fff;
  font-weight: 700;
  font-size: 260px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  background: linear-gradient(to right, var(--light) 50%, var(--secondary) 50%);
  background-clip: border-box;
  line-height: 1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  transform-origin: center;
  writing-mode: tb;
}

@media only screen and (max-width: 767px) {
  .error-page.style-2 .dz_error {
    writing-mode: unset;
    background: linear-gradient(to bottom, white 50%, #24262b 50%);
    -webkit-background-clip: text;
  }
}

.error-page.style-2 .error-inner {
  margin: auto;
}

.error-page.style-2 .error-inner .error-head {
  font-size: 110px;
  font-weight: 700;
  line-height: 0.8;
  margin-bottom: 25px;
}

.error-page.style-2 .error-inner .error-para {
  font-family: var(--font-family-title);
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 58px;
  color: var(--title);
  line-height: 1.5;
}

@media only screen and (max-width: 1480px) {
  .error-page.style-2 .dz_error {
    font-size: 220px;
  }

  .error-page.style-2 .error-inner .error-head {
    font-size: 90px;
  }

  .error-page.style-2 .error-inner .error-para {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 991px) {
  .error-page.style-2 .dz_error {
    font-size: 180px;
  }

  .error-page.style-2 .error-inner .error-head {
    font-size: 64px;
  }

  .error-page.style-2 .error-inner .error-para {
    margin-bottom: 20px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .error-page.style-2 {
    flex-direction: column;
  }

  .error-page.style-2 .dz_error {
    font-size: 100px;
    top: 200px;
  }

  .error-page.style-2 .error-inner .error-head {
    margin-bottom: 10px;
  }

  .error-page.style-2 .error-inner .error-para {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.error-start-content {
  background-color: var(--secondary);
  padding: 80px 90px;
}

@media only screen and (max-width: 1199px) {
  .error-start-content {
    padding: 40px;
  }
}

.error-start-content .logo {
  width: 170px;
}

.error-start-content .dz-social-icon {
  position: absolute;
  bottom: 95px;
  top: auto;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .error-start-content .dz-social-icon {
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .error-start-content {
    padding: 45px 70px 40px;
    height: 200px;
  }

  .error-start-content .dz-social-icon {
    bottom: 30px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-height: 550px) {
  .error-start-content .dz-social-icon {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .error-start-content {
    padding: 30px 40px;
  }

  .error-start-content .dz-social-icon {
    left: 50%;
    transform: translateX(-50%);
  }
}

.error-end-content {
  padding: 80px 40px 80px 120px;
  display: flex;
  flex-direction: column;
  background-color: var(--light);
}

@media only screen and (max-width: 991px) {
  .error-end-content {
    padding: 80px 40px 80px 90px;
  }
}

@media only screen and (max-width: 767px) {
  .error-end-content {
    padding: 0;
    height: 50%;
    min-height: 300px;
    text-align: center;
    flex: auto;
  }
}

@media only screen and (max-width: 575px) {
  .error-end-content {
    height: 70%;
  }
}

.contact-wraper1 {
  position: relative;
}

.contact-wraper1 .contact-info {
  padding: 50px 30px;
}

.contact-wraper1 .contact-info .icon-md {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.contact-wraper1 .contact-area1.style-1 {
  position: absolute;
  bottom: -189px;
  right: 15%;
  z-index: 999;
}

.contact-wraper1 .contact-area1 .wpcf7-spinner {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .contact-wraper1 .contact-info {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-wraper1 {
    background-position: center;
    background-size: cover;
    margin-bottom: 0;
  }

  .contact-wraper1:after {
    content: none;
  }

  .contact-wraper1:before {
    height: 100%;
    width: 100%;
  }

  .contact-wraper1 .contact-area1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .contact-wraper1 .contact-info {
    padding: 50px 0px 20px;
  }
}

.contact-area1 {
  padding: 60px 55px;
  background: var(--secondary);
  max-width: 520px;
  margin-bottom: -215px;
  position: relative;
  z-index: 9;
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
}

.contact-area1.style-1 {
  margin: 0 0 -215px auto;
}

@media only screen and (max-width: 991px) {
  .contact-area1.style-1 {
    margin: 0 0 -70px auto;
  }
}

.contact-area1 .form-label {
  color: #fff;
  font-weight: 400;
}

.contact-area1 .form-control,
.contact-area1 .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .contact-area1 input[type=text],
.contact-area1 .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .contact-area1 input[type=email],
.contact-area1 .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .contact-area1 input[type=password],
.contact-area1 .login input[type=text],
.login .contact-area1 input[type=text],
.contact-area1 .login input[type=password],
.login .contact-area1 input[type=password],
.contact-area1 .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .contact-area1 input[type=text],
.contact-area1 .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .contact-area1 input[type=text],
.contact-area1 .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .contact-area1 input[type=email],
.contact-area1 .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .contact-area1 input[type=tel],
.contact-area1 .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .contact-area1 .select2-choice,
.contact-area1 .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .contact-area1 textarea,
.contact-area1 .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .contact-area1 input.input-text,
.contact-area1 .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .contact-area1 .select2-selection,
.contact-area1 .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .contact-area1 .select2-choice,
.contact-area1 .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .contact-area1 textarea,
.contact-area1 .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .contact-area1 select,
.contact-area1 .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .contact-area1 input,
.contact-area1 .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .contact-area1 input,
.contact-area1 .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .contact-area1 .input-text,
.contact-area1 .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .contact-area1 input[type=text],
.contact-area1 .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .contact-area1 textarea,
.contact-area1 .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .contact-area1 input[type=email] {
  background: transparent;
  border-radius: 10px;
  border: 1px solid #FFF;
  color: #fff;
}

.contact-area1 .form-control:placeholder,
.contact-area1 .woocommerce-EditAccountForm.edit-account input[type=text]:placeholder,
.woocommerce-EditAccountForm.edit-account .contact-area1 input[type=text]:placeholder,
.contact-area1 .woocommerce-EditAccountForm.edit-account input[type=email]:placeholder,
.woocommerce-EditAccountForm.edit-account .contact-area1 input[type=email]:placeholder,
.contact-area1 .woocommerce-EditAccountForm.edit-account input[type=password]:placeholder,
.woocommerce-EditAccountForm.edit-account .contact-area1 input[type=password]:placeholder,
.contact-area1 .login input[type=text]:placeholder,
.login .contact-area1 input[type=text]:placeholder,
.contact-area1 .login input[type=password]:placeholder,
.login .contact-area1 input[type=password]:placeholder,
.contact-area1 .woocommerce-ResetPassword.lost_reset_password input[type=text]:placeholder,
.woocommerce-ResetPassword.lost_reset_password .contact-area1 input[type=text]:placeholder,
.contact-area1 .checkout.woocommerce-checkout input[type=text]:placeholder,
.checkout.woocommerce-checkout .contact-area1 input[type=text]:placeholder,
.contact-area1 .checkout.woocommerce-checkout input[type=email]:placeholder,
.checkout.woocommerce-checkout .contact-area1 input[type=email]:placeholder,
.contact-area1 .checkout.woocommerce-checkout input[type=tel]:placeholder,
.checkout.woocommerce-checkout .contact-area1 input[type=tel]:placeholder,
.contact-area1 .checkout.woocommerce-checkout .select2-choice:placeholder,
.checkout.woocommerce-checkout .contact-area1 .select2-choice:placeholder,
.contact-area1 .checkout.woocommerce-checkout textarea:placeholder,
.checkout.woocommerce-checkout .contact-area1 textarea:placeholder,
.contact-area1 .woocommerce form .form-row input.input-text:placeholder,
.woocommerce form .form-row .contact-area1 input.input-text:placeholder,
.contact-area1 .woocommerce form .form-row .select2-selection:placeholder,
.woocommerce form .form-row .contact-area1 .select2-selection:placeholder,
.contact-area1 .woocommerce form .form-row .select2-choice:placeholder,
.woocommerce form .form-row .contact-area1 .select2-choice:placeholder,
.contact-area1 .woocommerce .woocommerce-checkout.checkout form .form-row textarea:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .contact-area1 textarea:placeholder,
.contact-area1 .woocommerce .woocommerce-checkout.checkout form .form-row select:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .contact-area1 select:placeholder,
.contact-area1 .woocommerce .woocommerce-checkout.checkout form .form-row input:placeholder,
.woocommerce .woocommerce-checkout.checkout form .form-row .contact-area1 input:placeholder,
.contact-area1 .woocommerce-cart-form .table tr .quantity input:placeholder,
.woocommerce-cart-form .table tr .quantity .contact-area1 input:placeholder,
.contact-area1 .woocommerce-cart-form .table tr td.actions .coupon .input-text:placeholder,
.woocommerce-cart-form .table tr td.actions .coupon .contact-area1 .input-text:placeholder,
.contact-area1 .woocommerce #review_form #respond input[type=text]:placeholder,
.woocommerce #review_form #respond .contact-area1 input[type=text]:placeholder,
.contact-area1 .woocommerce #review_form #respond textarea:placeholder,
.woocommerce #review_form #respond .contact-area1 textarea:placeholder,
.contact-area1 .woocommerce #review_form #respond input[type=email]:placeholder,
.woocommerce #review_form #respond .contact-area1 input[type=email]:placeholder {
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .contact-area1 {
    padding: 45px 35px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-area1 {
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .contact-area1 {
    padding: 25px;
    border-radius: 15px;
  }
}

.map-iframe {
  height: 450px;
}

@media only screen and (max-width: 575px) {
  .map-iframe {
    height: 250px;
  }
}

@media only screen and (max-width: 575px) {
  form .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

body .working-location {
  margin-bottom: 15px;
}

body .working-location img {
  border-radius: var(--border-radius-base);
}

.map {
  position: relative;
  z-index: 1;
}

.map iframe {
  width: 100%;
}

.map:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFAF3;
  z-index: 0;
  pointer-events: none;
  mix-blend-mode: soft-light;
}

.contact-info.style-1 {
  margin-bottom: 60px;
  min-height: 335px;
}

.contact-info.style-1 .title {
  font-weight: 600;
  font-size: 40px;
  color: var(--title);
}

@media only screen and (max-width: 1199px) {
  .contact-info.style-1 .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info.style-1 .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info.style-1 .title {
    font-size: 24px;
  }
}

.contact-info.style-1 .text {
  color: var(--title);
  font-weight: 400;
  font-size: 30px;
  margin-left: unset;
  margin-right: unset;
  max-width: 540px;
  margin-bottom: 50px;
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 1199px) {
  .contact-info.style-1 .text {
    font-size: 26px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info.style-1 .text {
    font-size: 22px;
    margin-bottom: 15px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info.style-1 .text {
    font-size: 20px;
  }
}

.contact-info.style-1 .text span {
  color: var(--title);
}

.contact-info.style-1 .contact-bottom {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 25px 0 0;
}

.contact-info.style-1 .contact-bottom h3,
.contact-info.style-1 .contact-bottom .h3 {
  color: var(--title);
}

@media only screen and (max-width: 767px) {

  .contact-info.style-1 .contact-bottom h3,
  .contact-info.style-1 .contact-bottom .h3 {
    font-size: 22px;
  }
}

.contact-info.style-1 .contact-bottom .contact-left {
  margin-right: 190px;
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .contact-info.style-1 .contact-bottom .contact-left {
    margin-right: 100px;
  }
}

.contact-info.style-1 .contact-bottom ul li {
  font-weight: 500;
  font-size: 18px;
  color: var(--title);
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 767px) {
  .contact-info.style-1 .contact-bottom ul li {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info.style-1 {
    margin-bottom: 40px;
    min-height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info.style-1 .contact-bottom .contact-left {
    margin-right: 0;
    flex: 1;
  }
}

.contact-heading h2,
.contact-heading .h2 {
  font-weight: 500;
  font-size: 70px;
}

@media only screen and (max-width: 1480px) {

  .contact-heading h2,
  .contact-heading .h2 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 1199px) {

  .contact-heading h2,
  .contact-heading .h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 991px) {

  .contact-heading h2,
  .contact-heading .h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {

  .contact-heading h2,
  .contact-heading .h2 {
    font-size: 24px;
  }
}

.dz-text {
  font-weight: 700;
  font-size: 150px;
  position: absolute;
  bottom: 0;
  right: 30px;
  line-height: 1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.1);
  color: transparent;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .dz-text {
    font-size: 120px;
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-text {
    font-size: 100px;
    right: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-text {
    font-size: 60px;
    right: 0px;
  }
}

.contact-bnr {
  padding-top: 100px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .contact-bnr {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-bnr {
    padding-top: 50px;
  }
}

.middle-border {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.contact-us2 .map-fixed {
  position: absolute;
  left: 48vw;
  height: 75%;
  padding-right: 0;
  top: 100px;
  width: 76vw;
}

@media only screen and (max-width: 767px) {
  .contact-us2 .map-fixed {
    position: relative;
    left: auto;
    height: auto;
    padding-right: 0;
    top: auto;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
}

.contact-us2 .map-fixed .contact-map {
  position: relative;
}

.contact-us2 .map-fixed .contact-map img {
  width: 100%;
}

.contact-us2 .map-fixed .map-point {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contact-us2 .map-fixed .map-point .point {
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--secondary);
}

.contact-us2 .map-fixed .map-point .point i {
  font-size: 18px;
  color: #fff;
  position: absolute;
  top: -8px;
  left: -3px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.contact-us2 .map-fixed .map-point .point.active {
  background-color: var(--primary);
}

.contact-us2 .map-fixed .map-point .point.active:after {
  background-color: var(--rgba-primary-4);
}

.contact-us2 .map-fixed .map-point .point:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 50%;
  animation: 2s ease 0s infinite normal none running pointZoom;
}

.contact-us2 .map-fixed .map-point .point.point-1 {
  left: 13%;
  top: 20%;
}

.contact-us2 .map-fixed .map-point .point.point-2 {
  left: 60%;
  top: 15%;
}

.contact-us2 .map-fixed .map-point .point.point-3 {
  left: 50%;
  top: 50%;
}

.contact-us2 .dz-text {
  font-weight: 700;
  font-size: 190px;
  position: absolute;
  font-family: var(--font-family-title);
  bottom: 0px;
  right: 250px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.3);
  color: transparent;
  z-index: 1;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .contact-us2 .dz-text {
    display: none;
  }
}

.contact-us2 .contact-area1 {
  margin-bottom: unset;
}

.contact-us2 .contact-info.style-1 .contact-bottom .contact-left {
  margin-right: 160px;
}

@media only screen and (max-width: 991px) {
  .contact-us2 .contact-info.style-1 .contact-bottom .contact-left {
    margin-right: 40px;
  }
}

@keyframes pointZoom {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  75% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}

.locations-box {
  position: relative;
}

.locations-box:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
  width: 1px;
  height: 100%;
  opacity: 20%;
}

@media only screen and (max-width: 991px) {
  .locations-box:after {
    top: auto;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) -1.52%, rgb(0, 0, 0) 59.42%, rgba(0, 0, 0, 0) 98.48%);
  }
}

.locations-box:last-child:after {
  content: none;
}

@media only screen and (max-width: 991px) {
  .locations-box:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .locations-box:last-child:after {
    content: none;
  }
}

.locations-box .locations-box-inner {
  padding: 0 40px;
}

@media only screen and (max-width: 1199px) {
  .locations-box .locations-box-inner {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 991px) {
  .locations-box .locations-box-inner {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .locations-box .locations-box-inner {
    padding: 0;
    padding-bottom: 15px !important;
  }
}

.locations-box .locations-box-inner .country-media {
  padding: 5px;
  background: var(--secondary);
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 40px;
  margin-bottom: 20px;
  padding-right: 30px;
}

.locations-box .locations-box-inner .country-media .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  color: #fff;
}

.locations-box .locations-box-inner .country-media img {
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #fff;
}

.locations-box .locations-box-inner .dz-content .title {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 30px;
}

.locations-box .locations-box-inner .dz-content .contact-info {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.locations-box .locations-box-inner .dz-content .contact-info ul li {
  color: var(--title);
  font-weight: 500;
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .locations-box .locations-box-inner .dz-content .title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.cart-detail {
  border-radius: 30px;
  border: 1px solid var(--secondary);
  padding: 35px 30px;
}

.cart-detail .save-text {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.cart-detail .save-text i {
  font-size: 20px;
  color: var(--title);
}

.cart-detail .save-text span {
  color: var(--title);
  font-weight: 500;
  font-size: 16px;
}

.cart-detail table {
  width: 100% !important;
}

.cart-detail table tr {
  align-items: center;
}

.cart-detail table tr.total td {
  padding: 15px 0 20px;
}

.cart-detail table tr.total td.price {
  font-family: var(--font-family-title);
  font-weight: 500;
  font-size: 24px;
  color: var(--secondary);
  text-align: end;
}

@media only screen and (max-width: 1280px) {
  .cart-detail {
    padding: 15px;
  }

  .cart-detail .icon-bx-wraper.style-4 {
    padding: 15px;
  }

  .cart-detail .icon-bx-wraper.style-4 .icon-content {
    margin-left: 10px;
  }

  .cart-detail .icon-bx-wraper.style-4 .dz-title {
    margin-bottom: 0;
  }

  .cart-detail .icon-bx-wraper.style-4 p {
    display: none;
  }

  .cart-detail .save-text {
    padding-bottom: 10px;
  }

  .cart-detail .save-text span {
    font-size: 14px;
  }

  .cart-detail table tr.total td {
    padding: 10px 0 10px;
  }

  .cart-detail table tr.total td.price {
    font-size: 20px;
  }
}

.shop-checkout .form-select,
.shop-checkout select {
  padding: 8px 20px;
  border: 1px solid var(--secondary);
  border-radius: 10px;
}

.shop-checkout .form-group .form-check-input {
  margin: 5px 5px 0 0;
  border: 1px solid var(--secondary);
}

.shop-checkout .form-group .form-check-input:checked {
  border-color: var(--primary);
}

.shop-checkout .form-group .form-check-label {
  font-weight: 500;
  font-size: 14px;
  font-family: "DM Sans";
  color: #24262B;
}

.shop-checkout .dz-accordion .accordion-item {
  margin-bottom: 15px;
}

.shop-checkout .dz-accordion .accordion-item .accordion-header .accordion-button {
  background: transparent;
  border: 1px solid var(--secondary);
  font-family: var(--font-family-title);
  border-radius: 10px;
}

.shop-checkout .dz-accordion .accordion-item .accordion-header .accordion-button .toggle-close {
  right: 10px;
  background: transparent;
  color: var(--title);
}

.shop-checkout .dz-accordion .accordion-item .accordion-header .accordion-button .toggle-close:after {
  color: var(--title);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  font-size: 16px;
  content: "\f106";
}

.shop-checkout .dz-accordion .accordion-item .accordion-header .accordion-button.collapsed .toggle-close:after {
  content: "\f107";
}

@media only screen and (max-width: 575px) {
  .shop-checkout .dz-accordion .accordion-item .accordion-header .accordion-button {
    align-items: start;
    font-size: 14px;
  }
}

.order-detail {
  padding: 30px;
  border-radius: 30px;
  border: 1px solid var(--secondary);
}

@media only screen and (max-width: 575px) {
  .order-detail {
    padding: 15px;
  }
}

.order-detail .dz-accordion .accordion-item {
  background: transparent;
}

.order-detail .dz-accordion .accordion-item .accordion-header .accordion-button {
  padding: 0;
  font-family: var(--font-family-title);
  background-color: transparent;
}

.order-detail .dz-accordion .accordion-item .accordion-header .accordion-button .form-check-input {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.order-detail .dz-accordion .accordion-item .accordion-header .accordion-button .form-check-label {
  font-family: var(--font-family-title);
  font-weight: 500;
  font-size: 16px;
}

.order-detail .dz-accordion .accordion-item .accordion-header .accordion-button img {
  margin: 0 12px;
}

.order-detail .dz-accordion .accordion-item .accordion-header .accordion-button a {
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  color: #5E626F;
}

.order-detail .dz-accordion .accordion-item .accordion-body {
  padding: 8px 0;
}

.order-detail .dz-accordion .accordion-item .accordion-body p {
  font-weight: 400;
  font-size: 13px;
  font-family: "Roboto";
}

.order-detail .text {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding-top: 15px;
  border-top: 1px solid #D7D7D7;
}

.order-detail .text a {
  text-decoration: underline;
  color: var(--primary);
}

.order-detail .form-group .form-check-input {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 10px;
}

.order-detail .form-group .form-check-label {
  font-weight: 400;
  font-size: 13px;
  font-family: "Roboto";
  color: #5E626F;
}

.order-detail table {
  width: 100%;
}

.order-detail table tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.order-detail table tr td {
  padding: 12px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #24262B;
}

.order-detail table tr td.price {
  text-align: end;
}

.order-detail table tr.title {
  border-bottom: none;
}

.order-detail table tr.title td {
  padding-bottom: 0;
}

.order-detail table tr.shipping td .custom-checkbox .form-check-label {
  font-size: 14px;
  line-height: 30px;
}

.order-detail table tr.shipping td.price {
  vertical-align: bottom;
}

.order-detail table tr.total {
  border-bottom: none;
}

.order-detail table tr.total td {
  padding: 24px 0;
}

.order-detail table tr.total td.price {
  font-size: 24px;
}

.cart-item.style-1 {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cart-item.style-1 .dz-media {
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.cart-item.style-1 .dz-content {
  margin-left: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item.style-1 .dz-content .title {
  font-weight: 500;
  font-family: var(--font-family-title);
}

.cart-item.style-1 .dz-content .price {
  font-size: 16px;
  font-weight: 500;
  color: #24262B;
}

.extm {
  z-index: 1;
}

.filter-area {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: var(--border-radius-base);
  align-items: center;
  background-color: #ffffff;
  display: flex;
  position: relative;
  z-index: 20;
}

.filter-area .grid-area {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.filter-area .grid-area .nav .nav-item {
  align-item: center;
}

.filter-area .grid-area .nav .nav-item .nav-link {
  color: var(--secondary);
  font-family: var(--font-family-title);
  font-weight: var(--headings-font-weight);
  padding: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-area .grid-area .nav .nav-item .nav-link svg {
  width: 20px;
  height: 20px;
}

.filter-area .grid-area .nav .nav-item .nav-link svg path {
  fill: var(--secondary);
}

.filter-area .grid-area .nav .nav-item .nav-link.active {
  color: var(--primary);
}

.filter-area .grid-area .nav .nav-item .nav-link.active svg path {
  fill: var(--primary);
}

.filter-area .grid-area .nav .nav-item .nav-link:hover {
  color: var(--secondary);
}

@media only screen and (max-width: 1024px) {
  .filter-area .grid-area .filter-day {
    display: none;
  }
}

.filter-area .category {
  position: relative;
  z-index: 1;
  display: flex;
}

.filter-area .category .filter-category {
  align-items: center;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.filter-area .category .filter-category a {
  border: 0;
  background: transparent;
  font-weight: 500;
  color: var(--secondary);
  font-size: 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 60px;
  outline: none !important;
  padding: 0px 20px 0px 5px;
  font-family: var(--font-family-title);
}

.filter-area .category .form-group {
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
}

.filter-area .category .form-group .btn,
.filter-area .category .form-group .woocommerce #respond input#submit,
.woocommerce #respond .filter-area .category .form-group input#submit,
.filter-area .category .form-group .woocommerce a.button,
.woocommerce .filter-area .category .form-group a.button,
.filter-area .category .form-group .woocommerce button.button,
.woocommerce .filter-area .category .form-group button.button,
.filter-area .category .form-group .woocommerce input.button,
.woocommerce .filter-area .category .form-group input.button,
.filter-area .category .form-group .woocommerce #place_order,
.woocommerce .filter-area .category .form-group #place_order,
.filter-area .category .form-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .filter-area .category .form-group a.checkout-button,
.filter-area .category .form-group .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .filter-area .category .form-group .button,
.filter-area .category .form-group .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .filter-area .category .form-group input,
.filter-area .category .form-group .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .filter-area .category .form-group a,
.filter-area .category .form-group .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .filter-area .category .form-group a,
.filter-area .category .form-group .wp-block-button__link {
  border-bottom: 0;
  padding: 0px 20px 0 0;
  border: 0;
  background: transparent;
  color: var(--secondary);
  font-size: 16px;
  font-weight: 500;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 60px;
  outline: none !important;
}

.filter-area.filter-1 {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .filter-area.filter-1 .category {
    border-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .filter-area .orderby.dropdown {
    width: 160px !important;
  }
}

.page {
  margin-top: 40px;
  align-items: center;
}

.page .page-text {
  color: var(--title);
  font-weight: 500;
  margin-bottom: 0;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .page {
    margin-top: 0;
    text-align: center;
  }

  .page .page-text {
    margin-bottom: 25px;
  }
}

.dz-shop-card {
  position: relative;
}

.dz-shop-card .dz-tags {
  display: flex;
  margin-bottom: 8px;
  line-height: 1.5;
}

.dz-shop-card .dz-tags li a {
  color: var(--title);
  margin-right: 5px;
  font-size: 14px;
}

.dz-shop-card .product-tag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dz-shop-card.style-2 {
  display: flex;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 {
    display: block;
  }
}

.dz-shop-card.style-2 .dz-media {
  margin-right: 30px;
  max-width: 290px;
  max-height: 290px;
  border-radius: 20px;
}

.dz-shop-card.style-2 .dz-media img {
  object-fit: cover;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-media {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 .dz-media {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.dz-shop-card.style-2 .product-tag {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
}

.dz-shop-card.style-2 .dz-tags {
  margin-bottom: 0;
}

.dz-shop-card.style-2 .dz-tags a {
  padding: 0 1px;
}

.dz-shop-card.style-2 .dz-content {
  flex: 1;
  align-self: center;
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 .dz-content {
    padding-top: 20px;
  }
}

.dz-shop-card.style-2 .dz-content .dz-rating {
  display: flex;
  align-items: center;
  gap: 2px;
  display: flex;
  justify-content: center;
}

.dz-shop-card.style-2 .dz-content .dz-rating li {
  display: inline-block;
  font-size: 16px;
  color: #d5d5d5;
}

.dz-shop-card.style-2 .dz-content .dz-rating li.star-fill {
  color: var(--secondary);
}

.dz-shop-card.style-2 .dz-content .review-num {
  display: flex;
  margin-left: 18px;
  flex-direction: column;
  text-align: center;
  min-width: 100px;
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-content .review-num {
    min-width: 90px;
    margin-left: 0;
  }

  .dz-shop-card.style-2 .dz-content .review-num .woocommerce-review-link {
    text-align: left;
  }
}

.dz-shop-card.style-2 .dz-content .review-num h4,
.dz-shop-card.style-2 .dz-content .review-num .h4 {
  margin-bottom: 0;
}

.dz-shop-card.style-2 .dz-content .review-num span a {
  font-size: 13px;
  font-family: var(--font-family-base);
  color: #5E626F;
}

.dz-shop-card.style-2 .dz-content .dz-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dz-shop-card.style-2 .dz-content .dz-header .title {
  font-weight: 500;
}

.dz-shop-card.style-2 .dz-content .dz-rating-box {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-content .dz-rating-box {
    display: block;
  }
}

.dz-shop-card.style-2 .dz-content .color-name,
.dz-shop-card.style-2 .dz-content .price-name {
  font-size: 14px;
  font-weight: 400;
}

.dz-shop-card.style-2 .dz-content .price {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-family-title);
  color: var(--secondary);
}

.dz-shop-card.style-2 .dz-content .dz-body {
  margin-top: 15px;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-body {
    display: block;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 .dz-content .dz-body {
    margin-top: 0;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body .dz-para {
  font-size: 15px;
  font-weight: 300;
  padding-right: 30px;
}

@media only screen and (max-width: 991px) {
  .dz-shop-card.style-2 .dz-content .dz-body .dz-para {
    font-size: 14px;
    padding-right: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-2 .dz-content .dz-body .dz-para {
    display: none;
  }
}

.dz-shop-card.style-2 .dz-content .dz-body .rate {
  display: flex;
  margin-top: 0;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .dz-shop-card.style-2 .dz-content .dz-body .rate {
    margin-top: 10px;
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-2 .dz-content .dz-body .rate {
    display: flex;
  }

  .dz-shop-card.style-2 .dz-content .dz-body .rate .meta-content {
    margin-right: 30px;
  }

  .dz-shop-card.style-2 .dz-content .dz-body .rate .color-name {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {

  .dz-shop-card.style-2 .dz-content .dz-body .rate .btn,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce #respond input#submit,
  .woocommerce #respond .dz-shop-card.style-2 .dz-content .dz-body .rate input#submit,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce a.button,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate a.button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce button.button,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate button.button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce input.button,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate input.button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce #place_order,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate #place_order,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .dz-shop-card.style-2 .dz-content .dz-body .rate a.checkout-button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .dz-shop-card.style-2 .dz-content .dz-body .rate .button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .dz-shop-card.style-2 .dz-content .dz-body .rate input,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .dz-shop-card.style-2 .dz-content .dz-body .rate a,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .dz-shop-card.style-2 .dz-content .dz-body .rate a,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .wp-block-button__link {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {

  .dz-shop-card.style-2 .dz-content .dz-body .rate .btn,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce #respond input#submit,
  .woocommerce #respond .dz-shop-card.style-2 .dz-content .dz-body .rate input#submit,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce a.button,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate a.button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce button.button,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate button.button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce input.button,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate input.button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce #place_order,
  .woocommerce .dz-shop-card.style-2 .dz-content .dz-body .rate #place_order,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .dz-shop-card.style-2 .dz-content .dz-body .rate a.checkout-button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .dz-shop-card.style-2 .dz-content .dz-body .rate .button,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .dz-shop-card.style-2 .dz-content .dz-body .rate input,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .dz-shop-card.style-2 .dz-content .dz-body .rate a,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .dz-shop-card.style-2 .dz-content .dz-body .rate a,
  .dz-shop-card.style-2 .dz-content .dz-body .rate .wp-block-button__link {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.dz-shop-card.style-3 {
  background-color: #ffffff;
  margin-bottom: 30px;
}

.dz-shop-card.style-3 .dz-media+.dz-content {
  padding-top: 15px;
}

@media only screen and (max-width: 1199px) {
  .dz-shop-card.style-3 .dz-media {
    height: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-shop-card.style-3 .dz-media {
    height: 100%;
  }
}

.dz-shop-card.style-3 .dz-content .meta-content {
  margin-right: 0;
  text-align: right;
}

.dz-shop-card.style-3 .dz-content .dz-tags {
  margin-bottom: 5px;
}

.dz-shop-card.style-3 .dz-content .dz-rating {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 2px;
  display: flex;
}

.dz-shop-card.style-3 .dz-content .dz-rating li {
  display: inline-block;
  font-size: 14px;
  color: #d5d5d5;
}

.dz-shop-card.style-3 .dz-content .dz-rating li.star-fill {
  color: var(--secondary);
}

.dz-shop-card.style-3 .dz-content .review-num {
  display: flex;
  align-items: center;
}

.dz-shop-card.style-3 .dz-content .review-num a {
  font-size: 13px;
  color: #5E626F;
  line-height: 20px;
}

.dz-shop-card.style-3 .dz-content .dz-content-footer {
  display: flex;
  margin-bottom: 28px;
}

.dz-shop-card.style-3 .dz-content .dz-content-footer .dz-content-start {
  flex: 1;
}

.dz-shop-card.style-3 .dz-content .dz-content-footer .price-name {
  line-height: 1.5;
}

.dz-shop-card.style-3 .dz-content .dz-content-footer .price {
  font-size: 18px;
  font-weight: 700;
  color: var(--secondary);
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-3 .dz-content .dz-content-footer {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-shop-card.style-3 .dz-content .bookmark-btn .form-check-label {
    width: 44px;
    height: 44px;
    line-height: 43px;
  }
}

.book-grid-row.style-4 .dz-box {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .book-grid-row.style-4 .dz-box {
    display: block;
  }
}

.book-grid-row.style-4 .dz-box .dz-media {
  min-width: 400px;
}

.book-grid-row.style-4 .dz-box .dz-media img {
  border-radius: 10px;
}

@media only screen and (max-width: 1199px) {
  .book-grid-row.style-4 .dz-box .dz-media {
    min-width: 250px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content {
  margin-left: 50px;
  padding-top: 15px;
}

@media only screen and (max-width: 1199px) {
  .book-grid-row.style-4 .dz-box .dz-content {
    margin-left: 30px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .book-grid-row.style-4 .dz-box .dz-content {
    margin-left: 0;
    padding-top: 25px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-header {
    margin-bottom: 20px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating {
  display: flex;
  justify-content: space-between;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .dz-rating {
  display: flex;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .dz-rating li {
  margin-right: 4px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area {
  display: flex;
  align-items: center;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area .review-num {
  display: flex;
  margin-right: 15px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area .review-num li a {
  font-size: 15px;
  color: #000;
  font-weight: var(--headings-font-weight);
  margin: 0 12px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-header .shop-item-rating .social-area .review-num li a svg {
  margin-right: 10px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info {
  display: flex;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li {
  color: var(--secondary);
  font-size: 18px;
  font-weight: var(--headings-font-weight);
  padding: 0 65px 0 0;
}

@media only screen and (max-width: 1199px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li {
    padding: 0 20px 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li {
    font-size: 15px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li .writer-info {
  display: flex;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li .writer-info img {
  height: 50px;
  border-radius: 11px;
  width: 50px;
  margin-right: 20px;
}

@media only screen and (max-width: 575px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li .writer-info img {
    display: none;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .book-info li span {
  display: block;
  color: #AAAAAA;
  font-size: 14px;
  font-weight: 500;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .right-info .badge {
  border: 1px solid #EBE8FE;
  color: #131045;
  font-size: 14px;
  padding: 10px 14px;
  margin: 0 7px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-detail .right-info .badge.badge-1 {
  color: #53C258;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .text-1 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .text-1 {
    margin-bottom: 20px;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media only screen and (max-width: 991px) {
  .book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer {
    display: block;
  }
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price {
  display: flex;
  align-items: baseline;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price h5,
.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price .h5 {
  font-size: 30px;
  color: var(--primary);
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price p {
  font-weight: 500;
  font-size: 18px;
  text-decoration: line-through;
  color: #636363;
  font-family: var(--font-family-title);
  margin-bottom: 0;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .price .badge {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background-color: #FF754C;
  width: 57px;
  height: 30px;
  border-radius: var(--border-radius-base);
  text-align: center;
  line-height: 20px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .product-num {
  display: flex;
  align-items: center;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .product-num a span {
  margin-left: 15px;
}

.book-grid-row.style-4 .dz-box .dz-content .dz-body .book-footer .product-num .like-btn {
  border: 1px solid #F0F0F0;
  width: 60px;
  border-radius: var(--border-radius-base);
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 22px;
  margin-left: 18px;
}

.related-img {
  border-radius: 30px;
}

.related-title {
  margin-bottom: 45px;
  margin-top: 34px;
}

@media only screen and (max-width: 1680px) {
  .related-title {
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 1480px) {
  .related-title {
    margin-bottom: 55px;
  }
}

.shop-filter aside>div>.title i {
  font-size: 18px;
}

.shop-filter .shop-xl {
  padding: 30px 50px;
  position: fixed;
  left: -445px;
  top: 0px;
  z-index: 10000;
  background: #ffffff;
  height: 100%;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  max-width: 400px;
  overflow: scroll;
}

.shop-filter .shop-xl.active {
  left: 0;
}

@media only screen and (max-width: 1199px) {
  .shop-filter {
    padding: 30px 35px;
    position: fixed;
    left: -445px;
    top: 0px;
    z-index: 10000;
    background: #ffffff;
    height: 100%;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    max-width: 400px;
    overflow: scroll;
  }

  .shop-filter.active {
    left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .shop-filter {
    left: -100%;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
  }

  .panel-close-btn {
    display: block;
    height: 40px;
    width: 0;
    background: #000;
    z-index: 10001;
    left: auto;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .panel-close-btn svg {
    position: unset;
  }

  .panel-close-btn.active {
    width: 40px;
  }
}

.dz-widget_services .form-check {
  padding-top: 5px;
  padding-bottom: 5px;
}

.book-grid-row .col-book.style-1 {
  width: 25%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
  .book-grid-row .col-book.style-1 {
    width: 33%;
  }
}

@media only screen and (max-width: 991px) {
  .book-grid-row .col-book.style-1 {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .book-grid-row .col-book.style-1 {
    width: 100%;
    margin-bottom: 0;
  }
}

.book-grid-row .col-book.style-2 {
  width: 33.33%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .book-grid-row .col-book.style-2 {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .book-grid-row .col-book.style-2 {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .book-grid-row .col-book.style-2 {
    width: 100%;
    margin-bottom: 0;
  }
}

.tabs-site-button .nav-tabs {
  margin-bottom: 32px;
}

.tabs-site-button .nav-tabs a {
  display: inline-block;
  padding: 15px 20px;
  color: var(--secondary);
  font-weight: 500;
  font-size: 16px;
  font-family: var(--font-family-title);
  position: relative;
  line-height: 1.2;
}

@media only screen and (max-width: 575px) {
  .tabs-site-button .nav-tabs li {
    margin: auto;
  }
}

.form-wizard {
  border: 1px solid transparent !important;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
}

.form-wizard .nav-wizard li {
  flex: 0 0 25%;
  max-width: 25%;
}

.form-wizard .nav-wizard li .nav-link {
  display: flex;
  align-items: center;
  padding: 0;
}

.form-wizard .nav-wizard li .nav-link span {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: 5px solid #E2E2E2;
  line-height: 3rem;
  font-size: 1.125rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.form-wizard .nav-wizard li .nav-link span i {
  opacity: 0;
}

.form-wizard .nav-wizard li .nav-link:after {
  content: "";
  border-radius: 0.25rem 0 0 0.25rem;
  flex: 1;
  height: 5px;
  background: #E1E1E1;
}

.form-wizard .nav-wizard li .nav-link.active span,
.form-wizard .nav-wizard li .nav-link.done span {
  background-color: var(--primary);
  color: #ffffff;
  border-color: var(--primary);
}

.form-wizard .nav-wizard li .nav-link.active span i,
.form-wizard .nav-wizard li .nav-link.done span i {
  opacity: 1;
}

.form-wizard .nav-wizard li .nav-link.active:after,
.form-wizard .nav-wizard li .nav-link.done:after {
  background-color: var(--primary);
}

.form-wizard .nav-wizard li .nav-link div {
  padding-right: 35px;
  color: #131045;
  font-size: 18px;
  font-weight: 600;
  padding-left: 35px;
}

.form-wizard .nav-wizard li:last-child .nav-link after {
  content: none;
}

.toolbar .btn,
.toolbar .woocommerce #respond input#submit,
.woocommerce #respond .toolbar input#submit,
.toolbar .woocommerce a.button,
.woocommerce .toolbar a.button,
.toolbar .woocommerce button.button,
.woocommerce .toolbar button.button,
.toolbar .woocommerce input.button,
.woocommerce .toolbar input.button,
.toolbar .woocommerce #place_order,
.woocommerce .toolbar #place_order,
.toolbar .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .toolbar a.checkout-button,
.toolbar .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .toolbar .button,
.toolbar .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .toolbar input,
.toolbar .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .toolbar a,
.toolbar .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .toolbar a,
.toolbar .wp-block-button__link {
  color: #ffffff;
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.shop-account .account-detail {
  padding: 30px 0px;
  background-color: var(--rgba-primary-1);
  border-radius: 6px;
  margin-bottom: 25px;
}

.shop-account .account-detail .my-image {
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  outline-offset: 3px;
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

.shop-account .account-detail .my-image img {
  border-radius: 100%;
  width: 100%;
  background-color: #fff;
  padding: 5px;
}

.shop-account .account-detail .account-title {
  margin-top: 25px;
}

.shop-account .account-list {
  list-style: none;
  margin-bottom: 0px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}

.shop-account .account-list li a {
  width: 100%;
  padding: 15px 20px;
  display: inline-block;
  border-top: 1px solid #eee;
  color: #232323;
  border-left: 3px solid rgba(0, 0, 0, 0);
}

.shop-account .account-list li a.active {
  background: rgba(234, 164, 81, 0.1019607843);
  border-left-color: var(--primary);
}

.shop-account .account-list li a:hover {
  background: rgba(234, 164, 81, 0.1019607843);
  border-left-color: var(--primary);
}

.shop-account .account-list li a i {
  color: var(--primary);
  margin-right: 10px;
}

.shop-bx {
  padding: 0 20px 30px;
  border-radius: 4px;
  background-color: var(--white);
}

.shop-bx .shop-bx-title {
  border-bottom: 1px solid var(--primary);
  padding: 10px 0;
  font-size: 22px;
  margin-bottom: 30px;
}

.woocommerce div.product span.price .woocommerce-Price-amount.amount del {
  font-weight: 400;
}

.woocommerce-Price-currencySymbol {
  font-weight: inherit !important;
}

.filter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-wrapper .filter-left-area,
.filter-wrapper .filter-right-area {
  display: flex;
  align-items: center;
}

.filter-wrapper .filter-left-area span,
.filter-wrapper .filter-right-area span {
  font-size: 14px;
  color: var(--title);
}

.filter-wrapper .filter-left-area {
  flex: 1;
}

.filter-wrapper .filter-right-area>div {
  border-left: 1px solid var(--border-color);
}

.filter-wrapper .filter-right-area>div:nth-child(3n-1) {
  border-left: 0 !important;
}

@media only screen and (max-width: 767px) {
  .filter-wrapper .filter-right-area>div {
    border: 0;
  }
}

.filter-wrapper .filter-right-area .form-group {
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
}

.filter-wrapper .filter-right-area .form-group .btn,
.filter-wrapper .filter-right-area .form-group .woocommerce #respond input#submit,
.woocommerce #respond .filter-wrapper .filter-right-area .form-group input#submit,
.filter-wrapper .filter-right-area .form-group .woocommerce a.button,
.woocommerce .filter-wrapper .filter-right-area .form-group a.button,
.filter-wrapper .filter-right-area .form-group .woocommerce button.button,
.woocommerce .filter-wrapper .filter-right-area .form-group button.button,
.filter-wrapper .filter-right-area .form-group .woocommerce input.button,
.woocommerce .filter-wrapper .filter-right-area .form-group input.button,
.filter-wrapper .filter-right-area .form-group .woocommerce #place_order,
.woocommerce .filter-wrapper .filter-right-area .form-group #place_order,
.filter-wrapper .filter-right-area .form-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .filter-wrapper .filter-right-area .form-group a.checkout-button,
.filter-wrapper .filter-right-area .form-group .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .filter-wrapper .filter-right-area .form-group .button,
.filter-wrapper .filter-right-area .form-group .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .filter-wrapper .filter-right-area .form-group input,
.filter-wrapper .filter-right-area .form-group .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .filter-wrapper .filter-right-area .form-group a,
.filter-wrapper .filter-right-area .form-group .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .filter-wrapper .filter-right-area .form-group a,
.filter-wrapper .filter-right-area .form-group .wp-block-button__link {
  border-bottom: 0;
  padding: 7px 20px 7px 20px;
  border: 0;
  background: transparent;
  color: var(--secondary);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  outline: none !important;
}

.filter-wrapper .filter-right-area .form-group .default-select,
.filter-wrapper .filter-right-area .form-group .bootstrap-select {
  width: auto !important;
}

.filter-wrapper .filter-right-area .form-group .default-select .dropdown-toggle:after,
.filter-wrapper .filter-right-area .form-group .bootstrap-select .dropdown-toggle:after {
  content: "\f107";
  font-family: "Font Awesome 6 free";
  border: 0;
  font-weight: 700;
  margin-left: 18px;
  font-size: 14px;
  color: var(--title);
}

.filter-wrapper .shop-tab .nav .nav-item .nav-link {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  color: var(--secondary);
}

.filter-wrapper .shop-tab .nav .nav-item .nav-link i {
  font-size: 20px;
}

.filter-wrapper .shop-tab .nav .nav-item .nav-link.active svg rect {
  stroke: var(--secondary);
}

.filter-wrapper .shop-tab .nav .nav-item .nav-link.active i {
  color: var(--secondary);
}

.filter-wrapper .shop-tab .nav .nav-item .nav-link.active svg {
  opacity: 1;
}

.filter-wrapper .shop-tab .nav .nav-item .nav-link svg {
  width: 20px;
  opacity: 0.35;
  height: 20px;
}

@media only screen and (max-width: 1199px) {

  .filter-wrapper .filter-left-area,
  .filter-wrapper .filter-right-area {
    padding: 8px 0;
  }

  .filter-wrapper .filter-left-area .panel-btn,
  .filter-wrapper .filter-right-area .panel-btn {
    margin-right: 16px;
  }

  .filter-wrapper .shop-tab {
    margin-left: auto;
  }
}

@media only screen and (max-width: 767px) {
  .filter-wrapper {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .filter-wrapper .filter-right-area .Category {
    display: none;
  }
}

@media only screen and (max-width: 767px) {

  .filter-wrapper .filter-right-area .shop-tab ul li:nth-child(3),
  .filter-wrapper .filter-right-area .shop-tab ul li:nth-child(4) {
    display: none;
  }
}

.filter-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter-tag li {
  padding: 5px 0;
}

.filter-tag li:last-child {
  margin-right: 30px;
}

@media only screen and (max-width: 1480px) {
  .filter-tag {
    display: none;
  }
}

.tag-btn {
  padding: 6px 10px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid var(--secondary);
  color: var(--title);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.tag-btn i {
  font-size: 18px;
  color: var(--title);
}

.tag-btn svg {
  margin-bottom: 2px;
}

/* .widget_search .form-group {
  margin-bottom: 40px;
}

.widget_search .form-group .form-control,
.widget_search .form-group .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .widget_search .form-group input[type=text],
.woocommerce-EditAccountForm.edit-account .widget_search .form-group input[type=email],
.widget_search .form-group .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .widget_search .form-group input[type=password],
.widget_search .form-group .login input[type=text],
.login .widget_search .form-group input[type=text],
.widget_search .form-group .login input[type=password],
.login .widget_search .form-group input[type=password],
.widget_search .form-group .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .widget_search .form-group input[type=text],
.widget_search .form-group .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .widget_search .form-group input[type=text],
.widget_search .form-group .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .widget_search .form-group input[type=email],
.widget_search .form-group .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .widget_search .form-group input[type=tel],
.widget_search .form-group .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .widget_search .form-group .select2-choice,
.widget_search .form-group .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .widget_search .form-group textarea,
.widget_search .form-group .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .widget_search .form-group input.input-text,
.widget_search .form-group .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .widget_search .form-group .select2-selection,
.widget_search .form-group .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .widget_search .form-group .select2-choice,
.widget_search .form-group .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_search .form-group textarea,
.widget_search .form-group .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_search .form-group select,
.widget_search .form-group .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .widget_search .form-group input,
.widget_search .form-group .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .widget_search .form-group input,
.widget_search .form-group .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .widget_search .form-group .input-text,
.widget_search .form-group .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .widget_search .form-group input[type=text],
.widget_search .form-group .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .widget_search .form-group textarea,
.widget_search .form-group .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .widget_search .form-group input[type=email] {
  background-color: var(--light);
  border: 1px solid var(--secondary);
  border-radius: 10px !important;
}

\

.widget_search .form-group .input-group-addon .btn,
.widget_search .form-group .input-group-addon .woocommerce #respond input#submit,
.woocommerce #respond .widget_search .form-group .input-group-addon input#submit,
.woocommerce .widget_search .form-group .input-group-addon a.button,
.widget_search .form-group .input-group-addon .woocommerce button.button,
.woocommerce .widget_search .form-group .input-group-addon button.button,
.widget_search .form-group .input-group-addon .woocommerce input.button,
.woocommerce .widget_search .form-group .input-group-addon input.button,
.widget_search .form-group .input-group-addon .woocommerce #place_order,
.woocommerce .widget_search .form-group .input-group-addon #place_order,
.widget_search .form-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .widget_search .form-group .input-group-addon a.checkout-button,
.widget_search .form-group .input-group-addon .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .widget_search .form-group .input-group-addon .button,
.widget_search .form-group .input-group-addon .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .widget_search .form-group .input-group-addon input,
.widget_search .form-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a,
.widget_search .form-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a,
.widget_search .form-group .input-group-addon .wp-block-button__link {
  padding: 0;
  height: 50px;
  text-align: center;
}

.widget_search .form-group .input-group-addon .btn:active,
.widget_search .form-group .input-group-addon .woocommerce #respond input#submit:active,
.woocommerce #respond .widget_search .form-group .input-group-addon input#submit:active,
.widget_search .form-group .input-group-addon .woocommerce a.button:active,
.woocommerce .widget_search .form-group .input-group-addon a.button:active,
.widget_search .form-group .input-group-addon .woocommerce button.button:active,
.woocommerce .widget_search .form-group .input-group-addon button.button:active,
.widget_search .form-group .input-group-addon .woocommerce input.button:active,
.woocommerce .widget_search .form-group .input-group-addon input.button:active,
.widget_search .form-group .input-group-addon .woocommerce #place_order:active,
.woocommerce .widget_search .form-group .input-group-addon #place_order:active,
.widget_search .form-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:active,
.woocommerce-cart .wc-proceed-to-checkout .widget_search .form-group .input-group-addon a.checkout-button:active,
.widget_search .form-group .input-group-addon .woocommerce-cart-form .table tr .button:active,
.woocommerce-cart-form .table tr .widget_search .form-group .input-group-addon .button:active,
.widget_search .form-group .input-group-addon .woocommerce #review_form #respond .form-submit input:active,
.woocommerce #review_form #respond .form-submit .widget_search .form-group .input-group-addon input:active,
.widget_search .form-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a:active,
.woocommerce .widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a:active,
.widget_search .form-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a:active,
.woocommerce.widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a:active,
.widget_search .form-group .input-group-addon .wp-block-button__link:active {
  border-color: transparent;
}

.widget_search .form-group .input-group-addon .btn i,
.widget_search .form-group .input-group-addon .woocommerce #respond input#submit i,
.woocommerce #respond .widget_search .form-group .input-group-addon input#submit i,
.widget_search .form-group .input-group-addon .woocommerce a.button i,
.woocommerce .widget_search .form-group .input-group-addon a.button i,
.widget_search .form-group .input-group-addon .woocommerce button.button i,
.woocommerce .widget_search .form-group .input-group-addon button.button i,
.widget_search .form-group .input-group-addon .woocommerce input.button i,
.woocommerce .widget_search .form-group .input-group-addon input.button i,
.widget_search .form-group .input-group-addon .woocommerce #place_order i,
.woocommerce .widget_search .form-group .input-group-addon #place_order i,
.widget_search .form-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
.woocommerce-cart .wc-proceed-to-checkout .widget_search .form-group .input-group-addon a.checkout-button i,
.widget_search .form-group .input-group-addon .woocommerce-cart-form .table tr .button i,
.woocommerce-cart-form .table tr .widget_search .form-group .input-group-addon .button i,
.widget_search .form-group .input-group-addon .woocommerce #review_form #respond .form-submit input i,
.woocommerce #review_form #respond .form-submit .widget_search .form-group .input-group-addon input i,
.widget_search .form-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a i,
.woocommerce .widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a i,
.widget_search .form-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a i,
.woocommerce.widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a i,
.widget_search .form-group .input-group-addon .wp-block-button__link i {
  font-size: 20px;
}

.widget_search .form-group .input-group-addon .btn svg path,
.widget_search .form-group .input-group-addon .woocommerce #respond input#submit svg path,
.woocommerce #respond .widget_search .form-group .input-group-addon input#submit svg path,
.widget_search .form-group .input-group-addon .woocommerce a.button svg path,
.woocommerce .widget_search .form-group .input-group-addon a.button svg path,
.widget_search .form-group .input-group-addon .woocommerce button.button svg path,
.woocommerce .widget_search .form-group .input-group-addon button.button svg path,
.widget_search .form-group .input-group-addon .woocommerce input.button svg path,
.woocommerce .widget_search .form-group .input-group-addon input.button svg path,
.widget_search .form-group .input-group-addon .woocommerce #place_order svg path,
.woocommerce .widget_search .form-group .input-group-addon #place_order svg path,
.widget_search .form-group .input-group-addon .woocommerce-cart .wc-proceed-to-checkout a.checkout-button svg path,
.woocommerce-cart .wc-proceed-to-checkout .widget_search .form-group .input-group-addon a.checkout-button svg path,
.widget_search .form-group .input-group-addon .woocommerce-cart-form .table tr .button svg path,
.woocommerce-cart-form .table tr .widget_search .form-group .input-group-addon .button svg path,
.widget_search .form-group .input-group-addon .woocommerce #review_form #respond .form-submit input svg path,
.woocommerce #review_form #respond .form-submit .widget_search .form-group .input-group-addon input svg path,
.widget_search .form-group .input-group-addon .woocommerce .widget_shopping_cart .buttons a svg path,
.woocommerce .widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a svg path,
.widget_search .form-group .input-group-addon .woocommerce.widget_shopping_cart .buttons a svg path,
.woocommerce.widget_shopping_cart .buttons .widget_search .form-group .input-group-addon a svg path,
.widget_search .form-group .input-group-addon .wp-block-button__link svg path {
  stroke: var(--primary);
}

.product-size.btn-group {
  display: flex;
  flex-wrap: wrap;
}

.product-size .btn,
.product-size .woocommerce #respond input#submit,
.woocommerce #respond .product-size input#submit,
.product-size .woocommerce a.button,
.woocommerce .product-size a.button,
.product-size .woocommerce button.button,
.woocommerce .product-size button.button,
.product-size .woocommerce input.button,
.woocommerce .product-size input.button,
.product-size .woocommerce #place_order,
.woocommerce .product-size #place_order,
.product-size .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .product-size a.checkout-button,
.product-size .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .product-size .button,
.product-size .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .product-size input,
.product-size .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .product-size a,
.product-size .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .product-size a,
.product-size .wp-block-button__link {
  padding: 0;
  height: 34px;
  width: 34px;
  line-height: 36px;
  font-size: 15px;
  font-weight: 400;
  background-color: transparent !important;
  justify-content: center;
  background: transparent;
  border: 1px solid var(--secondary);
  color: var(--title);
  margin-right: 10px !important;
  flex: unset;
  margin-bottom: 5px;
  border-radius: 50% !important;
}

.product-size .btn:hover,
.product-size .woocommerce #respond input#submit:hover,
.woocommerce #respond .product-size input#submit:hover,
.product-size .woocommerce a.button:hover,
.woocommerce .product-size a.button:hover,
.product-size .woocommerce button.button:hover,
.woocommerce .product-size button.button:hover,
.product-size .woocommerce input.button:hover,
.woocommerce .product-size input.button:hover,
.product-size .woocommerce #place_order:hover,
.woocommerce .product-size #place_order:hover,
.product-size .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
.woocommerce-cart .wc-proceed-to-checkout .product-size a.checkout-button:hover,
.product-size .woocommerce-cart-form .table tr .button:hover,
.woocommerce-cart-form .table tr .product-size .button:hover,
.product-size .woocommerce #review_form #respond .form-submit input:hover,
.woocommerce #review_form #respond .form-submit .product-size input:hover,
.product-size .woocommerce .widget_shopping_cart .buttons a:hover,
.woocommerce .widget_shopping_cart .buttons .product-size a:hover,
.product-size .woocommerce.widget_shopping_cart .buttons a:hover,
.woocommerce.widget_shopping_cart .buttons .product-size a:hover,
.product-size .wp-block-button__link:hover {
  border-color: inherit;
}

.product-size .btn-check:checked+.btn,
.product-size .woocommerce #respond .btn-check:checked+input#submit,
.woocommerce #respond .product-size .btn-check:checked+input#submit,
.product-size .woocommerce .btn-check:checked+a.button,
.woocommerce .product-size .btn-check:checked+a.button,
.product-size .woocommerce .btn-check:checked+button.button,
.woocommerce .product-size .btn-check:checked+button.button,
.product-size .woocommerce .btn-check:checked+input.button,
.woocommerce .product-size .btn-check:checked+input.button,
.product-size .woocommerce .btn-check:checked+#place_order,
.woocommerce .product-size .btn-check:checked+#place_order,
.product-size .woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .product-size .btn-check:checked+a.checkout-button,
.product-size .woocommerce-cart-form .table tr .btn-check:checked+.button,
.woocommerce-cart-form .table tr .product-size .btn-check:checked+.button,
.product-size .woocommerce #review_form #respond .form-submit .btn-check:checked+input,
.woocommerce #review_form #respond .form-submit .product-size .btn-check:checked+input,
.product-size .woocommerce .widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce .widget_shopping_cart .buttons .product-size .btn-check:checked+a,
.product-size .woocommerce.widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .product-size .btn-check:checked+a,
.product-size .btn-check:checked+.wp-block-button__link {
  background: var(--secondary) !important;
  color: #fff;
  border-color: var(--secondary);
}

.product-size .btn-check:checked+.btn.btn-light,
.product-size .woocommerce #respond .btn-check:checked+input.btn-light#submit,
.woocommerce #respond .product-size .btn-check:checked+input.btn-light#submit,
.product-size .woocommerce .btn-check:checked+a.btn-light.button,
.woocommerce .product-size .btn-check:checked+a.btn-light.button,
.product-size .woocommerce .btn-check:checked+button.btn-light.button,
.woocommerce .product-size .btn-check:checked+button.btn-light.button,
.product-size .woocommerce .btn-check:checked+input.btn-light.button,
.woocommerce .product-size .btn-check:checked+input.btn-light.button,
.product-size .woocommerce .btn-check:checked+.btn-light#place_order,
.woocommerce .product-size .btn-check:checked+.btn-light#place_order,
.product-size .woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.btn-light.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .product-size .btn-check:checked+a.btn-light.checkout-button,
.product-size .woocommerce-cart-form .table tr .btn-check:checked+.btn-light.button,
.woocommerce-cart-form .table tr .product-size .btn-check:checked+.btn-light.button,
.product-size .woocommerce #review_form #respond .form-submit .btn-check:checked+input.btn-light,
.woocommerce #review_form #respond .form-submit .product-size .btn-check:checked+input.btn-light,
.product-size .woocommerce .widget_shopping_cart .buttons .btn-check:checked+a.btn-light,
.woocommerce .widget_shopping_cart .buttons .product-size .btn-check:checked+a.btn-light,
.product-size .woocommerce.widget_shopping_cart .buttons .btn-check:checked+a.btn-light,
.woocommerce.widget_shopping_cart .buttons .product-size .btn-check:checked+a.btn-light,
.product-size .btn-check:checked+.btn-light.wp-block-button__link {
  background: --secondary !important;
  border-color: var(--secondary);
  color: #fff;
}

.shop-top-filter .shop-filter {
  display: flex;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #FFFAF3;
}

@media only screen and (min-width: 1191px) {
  .shop-top-filter .shop-filter {
    display: none;
  }
}

@media only screen and (min-width: 1191px) {
  .shop-top-filter .shop-filter aside {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 50px;
  }
}

@media only screen and (min-width: 1191px) {
  .shop-top-filter .shop-filter aside .widget {
    width: 25%;
    flex: 1;
  }

  .shop-top-filter .shop-filter aside .widget.widget_search {
    display: none;
  }
}

.shop-top-filter .shop-filter aside .reset-btn {
  display: none;
}

.shop-top-filter .shop-filter .filter-wrapper {
  display: block;
}

.filter-top-btn {
  padding-right: 20px;
}

.dz-product-detail {
  margin-bottom: 30px;
}

.dz-product-detail.static-media .dz-media {
  margin-bottom: 30px;
  border-radius: 30px;
}

@media only screen and (max-width: 1199px) {
  .dz-product-detail.static-media .dz-media {
    height: auto;
    margin-bottom: 15px;
  }
}

.dz-product-detail .price {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-family-title);
  color: var(--secondary);
}

.dz-product-detail .price del {
  font-size: 18px;
  opacity: 0.6;
}

@media only screen and (max-width: 575px) {
  .dz-product-detail .price del {
    font-size: 16px;
  }
}

.dz-product-detail .swiper-btn-center-lr+.dz-content {
  padding-top: 25px;
}

@media only screen and (max-width: 767px) {
  .dz-product-detail .dz-media {
    height: 100%;
  }
}

.dz-product-detail .dz-media .dz-maximize {
  opacity: 0;
}

.dz-product-detail .dz-media:hover .dz-maximize {
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .dz-product-detail .dz-media .dz-maximize {
    opacity: 1;
  }
}

.dz-product-detail .banner-social-media {
  transform: unset;
  top: 250px;
}

.dz-product-detail .banner-social-media ul li a {
  color: #000;
}

.dz-product-detail .btn-group .btn,
.dz-product-detail .btn-group .woocommerce #respond input#submit,
.woocommerce #respond .dz-product-detail .btn-group input#submit,
.dz-product-detail .btn-group .woocommerce a.button,
.woocommerce .dz-product-detail .btn-group a.button,
.dz-product-detail .btn-group .woocommerce button.button,
.woocommerce .dz-product-detail .btn-group button.button,
.dz-product-detail .btn-group .woocommerce input.button,
.woocommerce .dz-product-detail .btn-group input.button,
.dz-product-detail .btn-group .woocommerce #place_order,
.woocommerce .dz-product-detail .btn-group #place_order,
.dz-product-detail .btn-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-product-detail .btn-group a.checkout-button,
.dz-product-detail .btn-group .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-product-detail .btn-group .button,
.dz-product-detail .btn-group .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-product-detail .btn-group input,
.dz-product-detail .btn-group .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-product-detail .btn-group a,
.dz-product-detail .btn-group .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-product-detail .btn-group a,
.dz-product-detail .btn-group .wp-block-button__link {
  border-radius: 10px;
}

.dz-product-detail .dz-content .title {
  font-weight: 500;
}

.dz-product-detail .dz-content .meta-content {
  margin-right: 0;
  text-align: right;
}

.dz-product-detail .dz-content .dz-tags {
  margin-bottom: 5px;
}

.dz-product-detail .dz-content .dz-rating {
  display: flex;
  align-items: center;
  gap: 2px;
  display: flex;
  justify-content: left;
}

.dz-product-detail .dz-content .dz-rating li {
  display: inline-block;
  font-size: 14px;
  color: #e4e5e8;
}

.dz-product-detail .dz-content .dz-rating li.star-fill {
  color: #ff8a00;
}

.dz-product-detail .dz-content .para-text {
  font-size: 15px;
  color: var(--secondary);
}

@media only screen and (max-width: 575px) {
  .dz-product-detail .dz-content .para-text {
    font-size: 14px;
  }
}

.dz-product-detail .dz-content .dz-rating {
  display: flex;
  line-height: 18px;
}

.dz-product-detail .dz-content .review-num {
  display: flex;
}

.dz-product-detail .dz-content .review-num a,
.dz-product-detail .dz-content .review-num span {
  font-size: 13px;
  color: var(--secondary);
  line-height: 20px;
}

.dz-product-detail .dz-content .dz-content-footer {
  display: flex;
  margin-bottom: 15px;
}

.dz-product-detail .dz-content .dz-content-footer .dz-content-start {
  flex: 1;
}

.dz-product-detail .dz-content .dz-content-footer .price-name {
  line-height: 1.5;
}

.dz-product-detail .dz-content .dz-content-footer .price-num {
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-family-title);
}

.dz-product-detail .swiper-btn-center-lr .btn-next,
.dz-product-detail .swiper-btn-center-lr .btn-prev {
  background-color: transparent;
  border-radius: 0;
}

.dz-product-detail .swiper-btn-center-lr .btn-prev {
  left: 0;
}

.dz-product-detail .swiper-btn-center-lr .btn-next {
  right: 0;
}

.dz-product-detail .swiper-btn-center-lr .gallery-button-prev i,
.dz-product-detail .swiper-btn-center-lr .gallery-button-next i {
  font-size: 26px;
}

.dz-product-detail .quick-modal-swiper,
.dz-product-detail .product-gallery-swiper {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.dz-product-detail .quick-modal-swiper .swiper-slide,
.dz-product-detail .product-gallery-swiper .swiper-slide {
  width: 45px !important;
  min-width: 45px !important;
  height: 45px;
}

.dz-product-detail .quick-modal-swiper .swiper-slide img,
.dz-product-detail .product-gallery-swiper .swiper-slide img {
  border: 2px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.4);
}

.dz-product-detail .quick-modal-swiper .swiper-slide:last-child,
.dz-product-detail .product-gallery-swiper .swiper-slide:last-child {
  margin-right: 0 !important;
}

.dz-product-detail .quick-modal-swiper .swiper-slide.swiper-slide-thumb-active img,
.dz-product-detail .product-gallery-swiper .swiper-slide.swiper-slide-thumb-active img {
  border-color: var(--secondary);
}

.dz-product-detail .quick-modal-swiper.swiper-vertical,
.dz-product-detail .product-gallery-swiper.swiper-vertical {
  position: absolute;
  top: 30px;
  bottom: auto;
  left: 30px;
  transform: unset;
}

.dz-product-detail .quick-modal-swiper.swiper-vertical .swiper-slide,
.dz-product-detail .product-gallery-swiper.swiper-vertical .swiper-slide {
  margin-right: 0 !important;
  margin-bottom: 15px !important;
}

.dz-product-detail .quick-modal-swiper.swiper-vertical.vertical-center,
.dz-product-detail .product-gallery-swiper.swiper-vertical.vertical-center {
  top: 50%;
  transform: translateY(-50%);
  left: -50px;
}

.dz-product-detail .quick-modal-swiper.thumb-swiper-lg .swiper-slide,
.dz-product-detail .product-gallery-swiper.thumb-swiper-lg .swiper-slide {
  height: 70px;
  width: 70px !important;
  min-width: 70px !important;
}

.dz-product-detail .quick-modal-swiper.thumb-swiper-lg .swiper-slide img,
.dz-product-detail .product-gallery-swiper.thumb-swiper-lg .swiper-slide img {
  box-shadow: unset;
  border-color: #fff;
  border-radius: 10px;
}

.dz-product-detail .quick-modal-swiper.thumb-swiper-lg .swiper-slide.swiper-slide-thumb-active img,
.dz-product-detail .product-gallery-swiper.thumb-swiper-lg .swiper-slide.swiper-slide-thumb-active img {
  border-color: var(--secondary);
}

.dz-product-detail .quick-modal-swiper.thumb-sm,
.dz-product-detail .product-gallery-swiper.thumb-sm {
  top: 15px;
  left: 15px;
}

.dz-product-detail .quick-modal-swiper.thumb-sm .swiper-slide,
.dz-product-detail .product-gallery-swiper.thumb-sm .swiper-slide {
  height: 50px;
  width: 50px !important;
  min-width: 50px !important;
}

.dz-product-detail .quick-modal-swiper.thumb-sm .swiper-slide img,
.dz-product-detail .product-gallery-swiper.thumb-sm .swiper-slide img {
  box-shadow: unset;
  border: 1px solid var(--border-color);
  border-radius: 0;
}

.dz-product-detail .quick-modal-swiper.thumb-sm .swiper-slide.swiper-slide-thumb-active img,
.dz-product-detail .product-gallery-swiper.thumb-sm .swiper-slide.swiper-slide-thumb-active img {
  border-color: var(--secondary);
}

@media only screen and (max-width: 991px) {

  .dz-product-detail .quick-modal-swiper.swiper-vertical,
  .dz-product-detail .product-gallery-swiper.swiper-vertical {
    top: 15px;
    left: 15px;
  }

  .dz-product-detail .quick-modal-swiper.thumb-swiper-lg .swiper-slide,
  .dz-product-detail .product-gallery-swiper.thumb-swiper-lg .swiper-slide {
    height: 50px;
    width: 50px !important;
    min-width: 50px !important;
  }
}

.dz-product-detail .product-num {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.dz-product-detail .product-num>div {
  margin-right: 45px;
}

.dz-product-detail .product-num>div:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 991px) {
  .dz-product-detail .product-num>div {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.dz-product-detail .product-num a span {
  margin-left: 15px;
}

.dz-product-detail .product-num .like-btn {
  border: 1px solid #F0F0F0;
  width: 60px;
  border-radius: var(--border-radius-base);
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 22px;
  margin-left: 18px;
}

.dz-product-detail.style-2 {
  max-width: 660px;
  padding-left: 40px;
}

.dz-product-detail.style-2 .dz-content .title {
  font-size: 35px;
}

.dz-product-detail.style-2 .dz-content .meta-content {
  text-align: left;
}

.dz-product-detail.style-2 .dz-content .btn-group,
.dz-product-detail.style-2 .dz-content .product-num {
  margin-bottom: 40px;
}

.dz-product-detail.style-2 .dz-content .cart-btn {
  flex-wrap: wrap;
  margin: 0 -5px 40px;
}

.dz-product-detail.style-2 .dz-content .cart-btn .btn,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #respond input#submit,
.woocommerce #respond .dz-product-detail.style-2 .dz-content .cart-btn input#submit,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce a.button,
.woocommerce .dz-product-detail.style-2 .dz-content .cart-btn a.button,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce button.button,
.woocommerce .dz-product-detail.style-2 .dz-content .cart-btn button.button,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce input.button,
.woocommerce .dz-product-detail.style-2 .dz-content .cart-btn input.button,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #place_order,
.woocommerce .dz-product-detail.style-2 .dz-content .cart-btn #place_order,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-product-detail.style-2 .dz-content .cart-btn a.checkout-button,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-product-detail.style-2 .dz-content .cart-btn .button,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-product-detail.style-2 .dz-content .cart-btn input,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-product-detail.style-2 .dz-content .cart-btn a,
.dz-product-detail.style-2 .dz-content .cart-btn .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-product-detail.style-2 .dz-content .cart-btn a,
.dz-product-detail.style-2 .dz-content .cart-btn .wp-block-button__link {
  margin: 5px 5px;
  border-radius: 10px;
  padding: 12px 20px;
}

@media only screen and (max-width: 1199px) {
  .dz-product-detail.style-2 {
    padding-left: 0;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-product-detail.style-2 .dz-content .title {
    font-size: 30px;
  }

  .dz-product-detail.style-2 .dz-content .meta-content {
    margin-bottom: 10px;
  }

  .dz-product-detail.style-2 .dz-content .btn-group,
  .dz-product-detail.style-2 .dz-content .product-num {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-product-detail.style-2 {
    padding-top: 0;
  }

  .dz-product-detail.style-2 .dz-content .title {
    font-size: 24px;
  }

  .dz-product-detail.style-2 .dz-content .meta-content {
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .dz-product-detail.style-2 .dz-content .meta-content .price-num {
    font-size: 22px;
    margin-bottom: 0;
  }

  .dz-product-detail.style-2 .dz-content .meta-content .price-num del {
    font-size: 16px;
  }

  .dz-product-detail.style-2 .dz-content .btn-group,
  .dz-product-detail.style-2 .dz-content .product-num {
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

.dz-product-detail .dz-info {
  margin-bottom: 40px;
  border-top: 1px solid var(--border-color);
  padding-top: 20px;
}

@media only screen and (max-width: 991px) {
  .dz-product-detail .dz-info {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-product-detail .dz-info {
    margin-bottom: 0;
  }
}

.dz-product-detail .dz-info ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.dz-product-detail .dz-info ul li {
  color: var(--secondary);
  font-size: 14px;
  margin-right: 10px;
}

.dz-product-detail .dz-info ul li strong {
  font-size: 15px;
  font-family: var(--font-family-title);
}

.dz-product-detail .dz-info ul.social-icon li {
  margin-right: 20px;
  font-size: 16px;
}

.dz-product-detail .dz-info.style-2 {
  display: flex;
  flex-wrap: wrap;
}

.dz-product-detail .dz-info.style-2 ul {
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
  .dz-product-detail .dz-info.style-2 ul {
    margin-right: unset;
  }
}

.dz-product-detail .dz-info.style-2 ul.social-icon {
  margin-left: auto;
  margin-right: 0;
}

.dz-product-detail .dz-info.style-2 ul.social-icon li:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1199px) {
  .dz-product-detail .dz-info.style-2 ul.social-icon {
    margin-left: unset;
  }
}

.dz-product-detail.style-3 {
  padding: 0;
  margin: 0;
  background: transparent;
}

@media only screen and (max-width: 767px) {
  .dz-product-detail.style-3 {
    margin-bottom: 30px;
  }
}

.dz-product-detail.style-3 .swiper-btn-center-lr {
  margin-left: -80px;
  padding-left: 130px;
}

@media only screen and (max-width: 1199px) {
  .dz-product-detail.style-3 .swiper-btn-center-lr {
    margin-left: 0;
    padding-left: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-product-detail.style-3 .swiper-btn-center-lr {
    padding-left: 50px;
  }
}

.dz-product-detail.style-3 .product-gallery-swiper.swiper-vertical {
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 30px;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1199px) {
  .dz-product-detail.style-3 .product-gallery-swiper.swiper-vertical {
    left: 0px;
  }
}

.dz-product-detail.style-3 .product-gallery-swiper2:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 12%;
  width: 51%;
  height: 60%;
  background: white;
  border-radius: 50% 50% 0px 0px;
  z-index: -1;
}

.btn-quantity {
  margin-right: 45px;
}

.btn-quantity .bootstrap-touchspin {
  width: 140px;
  min-width: 140px;
  position: relative;
  height: 40px;
  display: block;
}

.btn-quantity input {
  width: auto;
  padding: 0;
  text-align: center;
  z-index: 1;
  height: 40px !important;
  border: 1px solid var(--secondary);
  background-color: transparent;
  border-radius: 20px !important;
  font-size: 15px;
  width: 40px !important;
  color: var(--title);
  margin-left: auto !important;
  margin-right: auto !important;
}

.btn-quantity input:focus {
  box-shadow: unset;
}

.btn-quantity .input-group-btn-vertical {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 0;
  margin-left: 0 !important;
}

.btn-quantity .input-group-btn-vertical .btn,
.btn-quantity .input-group-btn-vertical .woocommerce #respond input#submit,
.woocommerce #respond .btn-quantity .input-group-btn-vertical input#submit,
.btn-quantity .input-group-btn-vertical .woocommerce a.button,
.woocommerce .btn-quantity .input-group-btn-vertical a.button,
.btn-quantity .input-group-btn-vertical .woocommerce button.button,
.woocommerce .btn-quantity .input-group-btn-vertical button.button,
.btn-quantity .input-group-btn-vertical .woocommerce input.button,
.woocommerce .btn-quantity .input-group-btn-vertical input.button,
.btn-quantity .input-group-btn-vertical .woocommerce #place_order,
.woocommerce .btn-quantity .input-group-btn-vertical #place_order,
.btn-quantity .input-group-btn-vertical .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .btn-quantity .input-group-btn-vertical a.checkout-button,
.btn-quantity .input-group-btn-vertical .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .btn-quantity .input-group-btn-vertical .button,
.btn-quantity .input-group-btn-vertical .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .btn-quantity .input-group-btn-vertical input,
.btn-quantity .input-group-btn-vertical .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .btn-quantity .input-group-btn-vertical a,
.btn-quantity .input-group-btn-vertical .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .btn-quantity .input-group-btn-vertical a,
.btn-quantity .input-group-btn-vertical .wp-block-button__link {
  padding: 0px;
  min-width: 40px;
  justify-content: center;
  z-index: 1;
  border-radius: 20px;
  background-color: var(--secondary);
  position: relative;
  color: #fff;
}

.btn-quantity .input-group-btn-vertical .btn:active,
.btn-quantity .input-group-btn-vertical .woocommerce #respond input#submit:active,
.woocommerce #respond .btn-quantity .input-group-btn-vertical input#submit:active,
.btn-quantity .input-group-btn-vertical .woocommerce a.button:active,
.woocommerce .btn-quantity .input-group-btn-vertical a.button:active,
.btn-quantity .input-group-btn-vertical .woocommerce button.button:active,
.woocommerce .btn-quantity .input-group-btn-vertical button.button:active,
.btn-quantity .input-group-btn-vertical .woocommerce input.button:active,
.woocommerce .btn-quantity .input-group-btn-vertical input.button:active,
.btn-quantity .input-group-btn-vertical .woocommerce #place_order:active,
.woocommerce .btn-quantity .input-group-btn-vertical #place_order:active,
.btn-quantity .input-group-btn-vertical .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:active,
.woocommerce-cart .wc-proceed-to-checkout .btn-quantity .input-group-btn-vertical a.checkout-button:active,
.btn-quantity .input-group-btn-vertical .woocommerce-cart-form .table tr .button:active,
.woocommerce-cart-form .table tr .btn-quantity .input-group-btn-vertical .button:active,
.btn-quantity .input-group-btn-vertical .woocommerce #review_form #respond .form-submit input:active,
.woocommerce #review_form #respond .form-submit .btn-quantity .input-group-btn-vertical input:active,
.btn-quantity .input-group-btn-vertical .woocommerce .widget_shopping_cart .buttons a:active,
.woocommerce .widget_shopping_cart .buttons .btn-quantity .input-group-btn-vertical a:active,
.btn-quantity .input-group-btn-vertical .woocommerce.widget_shopping_cart .buttons a:active,
.woocommerce.widget_shopping_cart .buttons .btn-quantity .input-group-btn-vertical a:active,
.btn-quantity .input-group-btn-vertical .wp-block-button__link:active {
  border-color: transparent;
}

.btn-quantity .input-group {
  margin-bottom: 0;
}

.btn-quantity.quantity-sm .bootstrap-touchspin {
  width: 110px;
  min-width: 110px;
  height: 30px;
}

.btn-quantity.quantity-sm input {
  width: 30px !important;
  height: 30px !important;
}

.btn-quantity.quantity-sm .btn,
.btn-quantity.quantity-sm .woocommerce #respond input#submit,
.woocommerce #respond .btn-quantity.quantity-sm input#submit,
.btn-quantity.quantity-sm .woocommerce a.button,
.woocommerce .btn-quantity.quantity-sm a.button,
.btn-quantity.quantity-sm .woocommerce button.button,
.woocommerce .btn-quantity.quantity-sm button.button,
.btn-quantity.quantity-sm .woocommerce input.button,
.woocommerce .btn-quantity.quantity-sm input.button,
.btn-quantity.quantity-sm .woocommerce #place_order,
.woocommerce .btn-quantity.quantity-sm #place_order,
.btn-quantity.quantity-sm .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .btn-quantity.quantity-sm a.checkout-button,
.btn-quantity.quantity-sm .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .btn-quantity.quantity-sm .button,
.btn-quantity.quantity-sm .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .btn-quantity.quantity-sm input,
.btn-quantity.quantity-sm .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .btn-quantity.quantity-sm a,
.btn-quantity.quantity-sm .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .btn-quantity.quantity-sm a,
.btn-quantity.quantity-sm .wp-block-button__link {
  min-width: 30px;
  font-size: 14px;
}

.detail-bx {
  padding-left: 30px;
  padding-right: 30px;
}

.detail-bx .para-text {
  margin-bottom: 40px;
}

.detail-bx .feature-detail {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  flex-wrap: wrap;
}

.detail-bx .feature-detail li {
  display: flex;
  align-items: center;
  padding: 10px 40px 10px 0;
  text-align: left;
}

.detail-bx .feature-detail li:last-child {
  padding-right: 0;
}

.detail-bx .feature-detail li i {
  font-size: 22px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary);
  border-radius: 50%;
  width: 44px;
  min-width: 44px;
  margin-right: 10px;
  color: var(--secondary);
}

.detail-bx .feature-detail li h5,
.detail-bx .feature-detail li .h5 {
  font-weight: 400;
  margin-bottom: 0;
}

.detail-bx .feature-detail li svg {
  margin-right: 10px;
}

@media only screen and (max-width: 991px) {
  .detail-bx {
    padding-left: 0;
    padding-right: 0;
  }

  .detail-bx .feature-detail {
    margin-bottom: 30px;
  }

  .detail-bx .feature-detail li {
    padding: 6px 20px 6px 0;
  }

  .detail-bx .feature-detail li svg {
    width: 30px;
    height: 30px;
  }

  .detail-bx .feature-detail li h5,
  .detail-bx .feature-detail li .h5 {
    font-size: 16px;
  }

  .detail-bx .para-text {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .detail-bx .para-text {
    text-align: justify;
  }
}

@media only screen and (max-width: 600px) {
  .detail-bx .para-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .detail-bx .feature-detail {
    margin-bottom: 15px;
  }

  .detail-bx .feature-detail li h5,
  .detail-bx .feature-detail li .h5 {
    font-size: 14px;
  }
}

.category-swiper,
.category-swiper2 {
  margin-bottom: 25px;
}

.category-swiper .shop-card .dz-content,
.category-swiper2 .shop-card .dz-content {
  padding: 12px 0;
}

@media only screen and (max-width: 575px) {

  .category-swiper,
  .category-swiper2 {
    margin-bottom: 15px;
  }
}

.category-swiper2 {
  margin-bottom: 0;
}

.category-swiper2 .shop-card .dz-content {
  text-align: left;
}

.rating-filter ul li {
  display: inline-block;
}

.rating-filter ul li span {
  font-size: 16px;
  font-weight: 500;
  color: var(--title);
}

.rating-filter ul li i {
  color: #FE9F10;
  font-family: feather;
  font-style: normal;
  vertical-align: middle;
}

.rating-filter ul li:first-child {
  margin-right: 8px;
}

.rating-stars ul {
  list-style-type: none;
  padding: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.rating-stars ul>li.star {
  display: inline-block;
}

/* Idle State of the stars */
.rating-stars ul>li.star>i.fas {
  font-size: 14px;
  /* Change the size of the stars */
  color: var(--bsbody-color);
  /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul>li.star.hover>i.fas {
  color: #FFCC36;
}

/* Selected state of the stars */
.rating-stars ul>li.star.selected>i.fas {
  color: #FF912C;
}

.card-container .my-account-content {
  border: 1px solid #000;
  min-height: 307px;
  padding: 20px;
  width: 100%;
  font-size: 15px;
  line-height: 1.45;
  border-radius: 10px;
  overflow-x: auto;
}

.card-container .my-account-content p a {
  color: var(--primary);
}

.card-container .my-account-content p strong {
  color: var(--secondary);
}

.card-container .my-account-content .ecommerce-box {
  display: flex;
  justify-content: space-between;
}

.card-container .my-account-content .ecommerce-box .ecommerce-content .ecommerce-address-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-container .my-account-content .ecommerce-box .ecommerce-content .ecommerce-address-title .title {
  font-size: 18px;
}

.card-container .my-account-content .ecommerce-box .ecommerce-content .ecommerce-address-title a {
  color: var(--primary);
}

.card-container .my-account-content .woocommerce table.shop_table th {
  padding: 10px 15px;
  border: 0;
  font-family: var(--font-family-title);
  color: var(--title);
  font-size: 16px;
  font-weight: 700;
}

.card-container .my-account-content .check-tbl.style-1 tbody tr td {
  padding: 5px 18px;
}

.card-container .my-account-content .check-tbl.style-1 tbody tr td:first-child {
  padding: 10px 0;
}

.card-container .my-account-content .check-tbl.style-1 tbody tr td:last-child {
  padding: 10px 0;
}

.card-container .my-account-content .check-tbl.style-1 tbody tr td.product-item-name,
.card-container .my-account-content .check-tbl.style-1 tbody tr td.product-item-date,
.card-container .my-account-content .check-tbl.style-1 tbody tr td.product-item-Status,
.card-container .my-account-content .check-tbl.style-1 tbody tr td.product-item-price {
  font-size: 14px;
  font-weight: 400;
}

/* WooCommerce END */
.dz-box.product {
  margin-bottom: 30px !important;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  height: calc(100% - 30px);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 15px;
  border-radius: var(--border-radius-base);
}

.dz-box.product .yith-wcwl-wishlistaddedbrowse,
.dz-box.product .yith-wcwl-wishlistexistsbrowse,
.dz-box.product .yith-wcwl-add-button {
  border: 0;
}

.dz-box.product .item-box .item-img {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: var(--border-radius-base);
}

.dz-box.product .item-box .item-img>img {
  width: 100%;
}

.dz-box.product .item-box .item-img:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 0;
  background: #212529;
  top: 0;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-box.product .item-box .item-info {
  padding: 25px 20px 15px 20px;
  text-align: center;
}

.dz-box.product .item-box .item-info .item-title {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1.2;
}

.dz-box.product .item-box .item-info .item-price {
  line-height: 1.2;
  margin-bottom: 0;
}

.dz-box.product .item-box .item-info .item-price.sale-price .woocommerce-Price-amount.amount bdi {
  color: var(--primary);
  font-size: 20px;
  font-weight: 600;
}

.dz-box.product .item-box .item-info .item-price.sale-price .woocommerce-Price-amount.amount bdi .woocommerce-Price-currencySymbol {
  font-size: 20px !important;
}

.dz-box.product .item-box .item-info .item-price .price del {
  color: var(--secondary);
  font-size: 16px !important;
  font-weight: 400;
  margin-bottom: 0;
}

.dz-box.product .item-box .item-info .item-price .price ins .woocommerce-Price-amount.amount {
  color: var(--primary);
  font-size: 20px !important;
  font-weight: 600;
}

.dz-box.product .item-box .item-info .woocommerce-product-rating {
  margin: 0;
  float: unset;
  text-align: center;
}

.dz-box.product .item-box .item-info .woocommerce-product-rating .woocommerce-review-link {
  margin: 0;
}

.dz-box.product .item-box .item-info-in {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px 20px;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translateY(100px);
  -moz-transform: translateY(100px);
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  opacity: 0;
  left: 0;
}

.dz-box.product .item-box .item-info-in ul {
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

.dz-box.product .item-box .item-info-in ul li {
  list-style: none;
  display: inline-block;
  margin: 0 5px;
}

.dz-box.product .item-box .item-info-in ul li a {
  background: var(--primary);
  color: #fff;
  width: 48px;
  text-align: center;
  display: block;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  box-shadow: 0 5px 15px -10px var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: var(--border-radius-base);
}

.dz-box.product .item-box .item-info-in ul li a .fa-heart:before {
  font-weight: 500;
}

.dz-box.product .item-box .item-info-in ul li a i {
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: inline-block;
}

.dz-box.product .item-box .item-info-in ul li a:hover {
  background: var(--primary-hover);
}

.dz-box.product .item-box .item-info-in ul li a:hover i {
  transform: scale(1.25);
  -moz-transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
}

.dz-box.product .item-box .item-info-in ul li a.add_to_cart_button.added i:before {
  content: "\f217";
}

.dz-box.product .item-box .item-info-in ul li a.add_to_cart_button.loading i:before {
  content: "\f110";
}

.dz-box.product .item-box .item-info-in ul li .add_to_cart_button:before {
  content: none;
}

.dz-box.product .item-box .item-info-in ul li .yith-wcwl-add-to-wishlist {
  margin-top: 0;
}

.dz-box.product:hover {
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
}

.dz-box.product:hover .item-box .item-img:after {
  opacity: 0.7;
}

.dz-box.product:hover .item-box .item-img .item-info-in {
  transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  opacity: 1;
}

.woocommerce div.product .dz-box .woocommerce-product-rating {
  display: block;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .star-rating {
  float: none;
  position: relative;
  display: inline-block;
  font-size: 13px;
  top: 2px;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .star-rating span {
  font-size: 13px;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .star-rating:before {
  color: #bbb;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .woocommerce-review-link {
  display: inline-block;
}

.woocommerce div.product .dz-box .woocommerce-product-rating .woocommerce-review-link .count {
  margin-right: 4px;
}

.woocommerce span.onsale {
  background: var(--bs-danger);
  border-radius: 4px;
}

.woocommerce .woocommerce-ordering {
  margin: 0 0 30px;
}

.woocommerce .woocommerce-ordering .bootstrap-select {
  width: auto !important;
}

.woocommerce .woocommerce-ordering .bootstrap-select .dropdown-toggle::after {
  margin-left: 0.5em;
}

.woocommerce nav.woocommerce-pagination ul li {
  overflow: unset;
}

.woocommerce nav.woocommerce-pagination ul li span,
.woocommerce nav.woocommerce-pagination ul li a {
  height: 48px;
  width: 48px;
  line-height: 46px !important;
  border-radius: var(--border-radius-base);
  color: #777777;
  text-align: center;
  border: 0;
  padding: 0;
  transition: all 0.5s;
  border: 1px solid;
  border-color: #777777;
  font-size: 18px;
  background-color: #fff;
}

.woocommerce nav.woocommerce-pagination ul li span:focus,
.woocommerce nav.woocommerce-pagination ul li span:hover,
.woocommerce nav.woocommerce-pagination ul li span.current,
.woocommerce nav.woocommerce-pagination ul li a:focus,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li a.current {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
}

.woocommerce a.remove {
  color: #000 !important;
  display: block;
  font-size: 14px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  width: 22px;
  opacity: 1 !important;
  background: #fff;
  padding: 0;
  border: 1px solid #000;
  border-radius: 0;
}

.woocommerce ul.cart_list li a,
.woocommerce ul.product_list_widget li a {
  font-family: var(--font-family-base);
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 10px;
}

.wc-block-grid__product-image img {
  border-radius: var(--border-radius-base);
}

.woocommerce .product_list_widget ins {
  text-decoration: none;
}

.woocommerce .product_list_widget del .amount bdi {
  color: #666;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 400;
}

.woocommerce .product_list_widget .star-rating {
  position: relative;
  font-size: 15px;
  display: block;
}

.woocommerce .product_list_widget .star-rating span {
  font-size: inherit;
}

.woocommerce .widget_shopping_cart .cart_list li a.remove,
.woocommerce.widget_shopping_cart .cart_list li a.remove {
  left: auto;
  right: 0;
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
  text-align: left;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
}

.woocommerce ul.cart_list li:last-child,
.woocommerce ul.product_list_widget li:last-child {
  border: 0;
  margin-bottom: 0;
}

.woocommerce ul.cart_list li,
.woocommerce ul.product_list_widget li .amount {
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
}

.woocommerce .widget_shopping_cart .total strong,
.woocommerce.widget_shopping_cart .total strong {
  margin-right: 10px;
}

.woocommerce-mini-cart__buttons.buttons {
  justify-content: center;
}

.woocommerce .widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons a {
  padding: 10px 15px;
  text-transform: uppercase;
}

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
  padding: 15px;
}

.widget_shopping_cart_content p strong {
  margin-right: 10px;
  color: var(--title);
}

.widget_shopping_cart_content p .amount {
  color: var(--primary);
}

.woocommerce .widget_price_filter .price_slider_amount .button {
  background: var(--primary);
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  width: auto;
}

.woocommerce .widget_rating_filter ul li {
  position: relative;
}

.woocommerce .woocommerce.widget_price_filter .ui-slider .ui-slider-range {
  background-color: var(--primary) !important;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  border: 2px solid var(--primary);
}

.woocommerce .widget_price_filter .price_slider_amount .price_label {
  font-weight: 700;
  color: var(--title);
}

.widget_product_categories ul li {
  border: 0;
  color: inherit;
  font-family: inherit;
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.25rem !important;
  line-height: 1.3 !important;
}

.widget_product_categories ul li a {
  color: inherit;
  float: left;
  text-transform: capitalize;
  text-align: left;
  position: relative;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.widget_product_categories ul li a:after {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  display: block;
  left: -1.25rem;
  top: 0.063rem;
  font-size: 14px;
  font-weight: 900;
}

.widget_product_categories ul li a:hover {
  color: var(--primary);
  transform: translateX(10px);
}

.widget_product_categories ul li ul {
  margin-bottom: -0.625rem;
}

.widget_product_categories ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.woocommerce div.product p.price del {
  color: var(--secondary);
  font-size: 16px;
  font-weight: 400;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .price ins span {
  font-weight: 600;
  font-size: 20px !important;
  color: var(--primary);
  display: inline-block;
  font-family: inherit;
  line-height: 1.2;
  margin: 0 0 15px;
}

.woocommerce div.product p.price .woocommerce-Price-amount.amount,
.woocommerce div.product span.price .woocommerce-Price-amount.amount,
.woocommerce div.product .price ins span .woocommerce-Price-amount.amount {
  font-size: inherit !important;
  margin-bottom: 0;
}

.woocommerce div.product p.price .woocommerce-Price-currencySymbol,
.woocommerce div.product span.price .woocommerce-Price-currencySymbol,
.woocommerce div.product .price ins span .woocommerce-Price-currencySymbol {
  margin-bottom: 0;
  font-weight: inherit;
}

@media only screen and (max-width: 575px) {

  .woocommerce div.product p.price,
  .woocommerce div.product span.price,
  .woocommerce div.product .price ins span {
    font-size: 18px !important;
  }

  .woocommerce div.product p.price .woocommerce-Price-currencySymbol,
  .woocommerce div.product span.price .woocommerce-Price-currencySymbol,
  .woocommerce div.product .price ins span .woocommerce-Price-currencySymbol {
    font-size: 14px;
  }
}

.woocommerce div.product .price ins {
  margin-bottom: 0;
}

.woocommerce-product-details__short-description {
  margin-bottom: 20px;
  display: block;
  clear: both;
}

.product_meta {
  color: var(--title);
  margin-bottom: 20px;
  font-weight: 500;
}

.product_meta a {
  margin: 0 0px 0 4px;
  font-weight: 500;
}

.woocommerce-review-link {
  color: inherit;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  padding: 0;
  display: block;
}

.woocommerce div.product .woocommerce-product-rating {
  margin: 0 0 20px;
}

.woocommerce div.product .woocommerce-product-rating .star-rating {
  float: none;
}

@media only screen and (max-width: 575px) {
  .woocommerce div.product .woocommerce-product-rating {
    display: block;
    float: left;
    margin: 0 0 10px;
  }
}

.price-rating-area {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border: 1px solid #eee;
  border-width: 1px 0 1px 0;
  align-items: center;
  margin-bottom: 20px;
}

.price-rating-area .main-price {
  margin-bottom: 0;
}

.price-rating-area .star-rating {
  margin: 0;
  float: right;
  position: relative !important;
}

.woocommerce .woocommerce-product-rating .star-rating {
  margin: 0;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .woocommerce .woocommerce-product-rating .star-rating {
    float: left;
    position: relative;
  }
}

.woocommerce div.product form.cart .variations label {
  font-weight: 500;
  color: var(--title);
  margin: 0;
}

.cart .quantity input.input-text {
  font-size: 18px;
  height: 50px !important;
  border-radius: var(--border-radius-base) !important;
  text-align: left;
  padding: 0 20px;
}

.woocommerce.woo-variation-swatches.wvs-style-squared .variable-items-wrapper .variable-item {
  border-radius: 0;
}

.woocommerce.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item) {
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  box-shadow: none;
  padding: 0px;
  box-shadow: none;
}

.woocommerce .button-variable-item span {
  font-size: 13px;
  font-weight: 600;
}

.woocommerce-variation-add-to-cart .btn,
.woocommerce-variation-add-to-cart .woocommerce #respond input#submit,
.woocommerce #respond .woocommerce-variation-add-to-cart input#submit,
.woocommerce-variation-add-to-cart .woocommerce a.button,
.woocommerce .woocommerce-variation-add-to-cart a.button,
.woocommerce-variation-add-to-cart .woocommerce button.button,
.woocommerce .woocommerce-variation-add-to-cart button.button,
.woocommerce-variation-add-to-cart .woocommerce input.button,
.woocommerce .woocommerce-variation-add-to-cart input.button,
.woocommerce-variation-add-to-cart .woocommerce #place_order,
.woocommerce .woocommerce-variation-add-to-cart #place_order,
.woocommerce-variation-add-to-cart .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .woocommerce-variation-add-to-cart a.checkout-button,
.woocommerce-variation-add-to-cart .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .woocommerce-variation-add-to-cart .button,
.woocommerce-variation-add-to-cart .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .woocommerce-variation-add-to-cart input,
.woocommerce-variation-add-to-cart .wp-block-button__link,
.woocommerce-variation-add-to-cart .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .woocommerce-variation-add-to-cart a,
.woocommerce-variation-add-to-cart .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .woocommerce-variation-add-to-cart a {
  text-transform: uppercase;
}

.woocommerce.woo-variation-swatches .variable-items-wrapper {
  margin-bottom: 10px !important;
}

.woocommerce div.product form.cart {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

#tab-reviews,
#tab-description {
  background: transparent !important;
  margin-top: 0px;
  padding: 0;
  border: 0;
}

.woocommerce div.product .woocommerce-tabs .panel {
  padding: 0;
  background: transparent;
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
  padding: 0px !important;
  margin: 0px 0px 30px !important;
  border: none !important;
  border-bottom: 1px solid #eee !important;
  overflow: unset;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
  margin-bottom: 0;
  background: transparent !important;
  border: 0;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
  color: var(--title);
  border: 0;
  margin: 0;
  padding: 10px 20px;
  position: relative;
  background: transparent;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a:before {
  content: "";
  bottom: 0;
  background: var(--primary);
  top: auto;
  width: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:hover a,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active a {
  background: transparent;
  color: var(--primary);
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:hover a:before,
.woocommerce div.product .woocommerce-tabs ul.tabs li.active a:before {
  width: 100%;
  opacity: 1;
}

.woocommerce #review_form #respond p {
  width: 100%;
}

.woocommerce #review_form #respond p {
  margin: 0px 0px 20px !important;
  display: inline-block;
}

#review_form {
  padding: 30px;
  border: 1px solid #eee;
  border-radius: var(--border-radius-base);
  margin: 30px 0;
}

@media only screen and (max-width: 767px) {
  #review_form {
    padding: 20px;
  }
}

#review_form .comment-form-rating {
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 0 10px;
}

#review_form .comment-form-rating p {
  display: inline-block !important;
  width: auto !important;
  top: 5px;
  position: relative;
}

#review_form .comment-respond .comment-form p label {
  display: inline-block;
}

#review_form #respond .comment-form-email,
#review_form #respond .comment-form-author {
  width: 50% !important;
}

.woocommerce #review_form #respond .comment-form-cookies-consent {
  display: flex;
}

.woocommerce #review_form #respond .comment-form-cookies-consent input {
  margin-right: 10px;
}

.woocommerce #reviews #comments ol.commentlist li {
  border-bottom: 1px solid #eee;
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  width: 80px;
  border: 0;
  padding: 0;
  border-radius: var(--border-radius-base);
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin: 0 0 0 95px;
  padding: 0;
  border: 0;
  border-radius: 0;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p {
  font-size: 16px;
  font-weight: 400;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
  color: var(--primary);
  font-weight: 500;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong {
  color: var(--title);
  font-size: 20px;
  display: block;
  font-family: var(--font-family-title);
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 5px;
}

.woocommerce .star-rating {
  font-size: 18px;
  color: var(--primary);
  width: 5em;
}

.woocommerce .star-rating:before {
  color: #eee;
  content: "\f104\f104\f104\f104\f104";
  font-family: flaticon !important;
  top: 0;
  position: absolute;
  left: 0;
  font-weight: normal;
}

.woocommerce .star-rating span {
  font-size: inherit;
  color: var(--primary);
}

.woocommerce .star-rating span:before {
  color: #ffa808;
  content: "\f104\f104\f104\f104\f104";
  font-family: flaticon !important;
  left: 0;
  font-weight: normal;
}

.woocommerce #reviews #comments h2,
.woocommerce #reviews #comments .h2,
#tab-description h2,
#tab-description .h2,
.woocommerce div.product .woocommerce-tabs .panel h2,
.woocommerce div.product .woocommerce-tabs .panel .h2 {
  font-family: var(--font-family-title);
  color: var(--title);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.woocommerce-cart-form {
  overflow: auto;
}

.woocommerce-cart-form .table {
  font-size: 14px;
  font-weight: 500;
}

.woocommerce-cart-form .table tr {
  vertical-align: middle;
}

.woocommerce-cart-form .table tr td.actions .coupon .input-text {
  height: 50px;
  width: 180px;
  display: inline-block;
  margin-right: 10px;
}

.woocommerce-cart-form .table tr th {
  font-weight: 700;
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .woocommerce-cart-form .table tr th {
    font-size: 14px;
  }
}

.woocommerce-cart-form .table tr th.product-thumbnail {
  width: 100px;
}

.woocommerce-cart-form .table tr th.product-remove {
  width: 40px;
}

.woocommerce-cart-form .table tr img {
  width: 80px;
}

.woocommerce-cart-form .table tr .product-item-name {
  font-weight: 600;
  font-size: 16px;
}

.woocommerce-cart-form .table tr .quantity input {
  height: 50px;
  width: 70px;
  padding: 0 10px 0 15px;
  text-align: left;
}

.woocommerce-cart-form .table tr .button {
  padding: 14px 20px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
}

.woocommerce .cart_totals h2,
.woocommerce .cart_totals .h2 {
  font-family: var(--font-family-title);
  background: var(--primary);
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  padding: 15px 20px;
  font-weight: 500;
}

.cart_totals {
  color: var(--title);
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  padding: 13px 30px;
  display: block;
}

.woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=text],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=email],
.woocommerce .woocommerce-checkout.checkout form .form-row input[type=tel],
.woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row input.input-text,
.woocommerce .woocommerce-checkout.checkout form .form-row input {
  height: 50px;
}

.woocommerce .woocommerce-checkout.checkout .col-2,
.woocommerce .woocommerce-checkout.checkout .col-1 {
  padding-left: 15px;
  padding-right: 15px;
}

.woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account input[type=password],
.login input[type=text],
.login input[type=password],
.woocommerce-ResetPassword.lost_reset_password input[type=text],
.checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .select2-choice {
  height: 50px;
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  width: 48%;
}

.lost_reset_password .button {
  display: block;
  width: 100%;
  text-align: center;
}

.woocommerce form .form-row label {
  font-family: var(--font-family-base);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.woocommerce .col2-set,
.woocommerce-page .col2-set {
  width: auto;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.woocommerce form .form-row {
  margin: 0 0 15px;
  padding: 0;
}

.woocommerce-additional-fields h3,
.woocommerce-additional-fields .h3 {
  color: var(--title);
  font-family: var(--font-family-title);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4em;
  margin: 0 0 20px;
  text-transform: uppercase;
}

.woocommerce table.shop_table thead th {
  color: #fff;
  background: var(--primary);
  font-family: var(--font-family-title);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #place_order {
  text-transform: uppercase;
  text-align: center;
  padding: 13px 30px;
}

.woocommerce-form-coupon-toggle {
  display: none;
}

.payment_methods input+label {
  font-weight: 600;
  color: var(--title);
  padding-left: 30px;
  line-height: 1.2;
  position: relative;
}

.payment_methods input+label:after {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background: #fff;
  border: 2px solid var(--primary);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.payment_methods input:checked+label:after {
  border-width: 2px;
  background: var(--primary);
  box-shadow: inset 0px 0 0px 3px #fff;
  border-color: var(--primary);
}

.woocommerce-checkout #payment div.payment_box::after {
  content: none;
}

.yith-wcwl-wishlistexistsbrowse,
.yith-wcwl-wishlistaddedbrowse {
  position: relative;
}

.yith-wcwl-wishlistexistsbrowse .feedback,
.yith-wcwl-wishlistaddedbrowse .feedback {
  display: inline-block;
  padding: 0 !important;
  position: relative;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  line-height: 48px;
  text-align: center;
  background: var(--bs-danger);
  border-radius: var(--border-radius-base);
}

.yith-wcwl-wishlistexistsbrowse .feedback+a,
.yith-wcwl-wishlistaddedbrowse .feedback+a {
  display: none !important;
}

.yith-wcwl-wishlistexistsbrowse .yith-wcwl-icon,
.yith-wcwl-wishlistaddedbrowse .yith-wcwl-icon {
  margin-right: 0 !important;
}

.yith-wcwl-wishlistexistsbrowse .yith-wcwl-icon:before,
.yith-wcwl-wishlistaddedbrowse .yith-wcwl-icon:before {
  font-weight: 900;
}

.yith-wcwl-wishlistaddedbrowse,
.yith-wcwl-wishlistexistsbrowse,
.yith-wcwl-add-button {
  height: 48px;
  width: 48px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  line-height: 48px;
  border-radius: var(--border-radius-base);
  border: 1px solid #F0F0F0;
  background: #fff;
}

.woocommerce form.woocommerce-form.checkout_coupon,
.woocommerce-ResetPassword,
.woocommerce form.woocommerce-form.login,
.woocommerce form.woocommerce-form.register {
  border: 0;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.15);
  padding: 30px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.woocommerce-ResetPassword .form-row-first {
  width: 100% !important;
}

.woocommerce-Addresses .col-2,
.woocommerce-Addresses .col-1 {
  padding-left: 15px;
  padding-right: 15px;
}

.product-details .product_meta>span {
  display: block;
}

.pswp {
  z-index: 9999;
}

.shop-item-rating .woocommerce-product-rating .count {
  margin-left: 2px;
  margin-right: 5px;
}

.woocommerce .woocommerce-form-login .woocommerce-form-login__submit {
  float: unset;
  margin-right: 0;
  display: block;
  margin-top: 10px;
}

.woocommerce-cart-form .button:disabled {
  color: #fff !important;
}

.minicart .widget_shopping_cart .total>span {
  margin-right: 5px;
  float: unset;
  display: block;
  text-align: right;
}

/* Extra css */
.woocommerce .product-details .product form .woo-selected-variation-item-name {
  display: none !important;
}

.woocommerce .product-details .product form .reset_variations {
  display: none !important;
}

.woocommerce .product-details .product form.cart .button {
  background-color: var(--primary);
  border-color: var(--primary);
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse {
  position: unset;
  width: auto;
  margin-bottom: 30px;
  height: auto;
  border: 0;
  text-align: left;
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse .feedback {
  display: inline-block !important;
  margin-bottom: 15px;
  position: unset;
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
  width: auto;
  padding: 0 20px !important;
  margin-right: 20px;
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse .feedback .yith-wcwl-icon {
  margin-right: 0 !important;
}

.woocommerce .product-details .product .yith-wcwl-wishlistexistsbrowse a {
  display: unset !important;
}

.woocommerce .product-details .product .yith-wcwl-add-button {
  display: none;
}

.woocommerce .product-details .product .related-product .yith-wcwl-wishlistexistsbrowse {
  position: relative;
}

.woocommerce .product-details .product .related-product .yith-wcwl-wishlistexistsbrowse .feedback {
  position: absolute;
}

.woocommerce .product-details .product .related-product .yith-wcwl-wishlistexistsbrowse .feedback .yith-wcwl-icon {
  margin-right: 0 !important;
}

.woocommerce .product-details .product .related-product .yith-wcwl-add-button {
  display: block;
}

.woocommerce .woocommerce-product-rating .woocommerce-review-link .count {
  margin-right: 4px;
}

.woo-variation-swatches.wvs-style-squared .variable-items-wrapper .variable-item.button-variable-item {
  width: auto;
  padding: 0px 5px;
}

.woocommerce.single-product .product .summary-content .single_add_to_cart_button.button {
  background-color: var(--primary);
  border-color: var(--primary);
}

.woocommerce.single-product .product .summary-content .product_meta>span {
  display: block;
}

#yith-quick-view-modal .yith-wcqv-wrapper {
  height: auto !important;
}

.yith-wcqv-wrapper #yith-quick-view-close {
  border: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  background-color: var(--primary);
  color: #fff;
  top: 15px;
  right: 15px;
  border-radius: 6px;
}

/* Mini Cart Start */
.shop-cart .site-button-link {
  color: #fff;
  position: relative;
}

.shop-cart .site-button-link i {
  margin-left: 0;
}

.shop-cart .site-button-link .badge {
  padding: 2px 4px 2px 4px;
  font-size: 10px;
  top: -6px;
  position: absolute;
  right: -12px;
}

.minicart {
  background: #fff;
  position: absolute;
  right: 0;
  top: 100%;
  width: 300px;
  z-index: 99;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
  display: none;
  color: #777;
  margin-top: 0;
}

.minicart .woocommerce-mini-cart {
  max-height: 302px;
  overflow-y: auto;
  border-bottom: 1px dashed #eee;
}

.minicart .cart-content a {
  color: #10111e;
  font-size: 14px;
  background: none;
}

.minicart .cart-content a:hover {
  color: #fe4536;
  background: none;
}

.minicart .acod-title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  display: none;
}

.minicart .cart-img img {
  width: 85px;
}

.minicart ul.product_list_widget li a img,
.minicart ul.cart_list li a img {
  width: 60px;
  height: 80px;
  border-radius: 6px;
  object-fit: cover;
}

.minicart .cart-price span {
  color: #ddd;
  font-size: 13px;
}

.minicart .cart-price .new {
  font-size: 14px;
  color: #747691;
}

.minicart .cart-price span {
  color: #a5a7bc;
  font-size: 13px;
  font-weight: 500;
}

.minicart .total-price {
  border-top: 1px solid #cacadb;
  overflow: hidden;
  padding-top: 25px;
  margin-top: 10px;
}

.minicart .total-price span {
  color: #747691;
  font-weight: 500;
}

.minicart .checkout-link a {
  background: #84b77c;
  color: #fff;
  display: block;
  font-weight: 500;
  padding: 16px 30px;
  text-align: center;
  font-size: 13px;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.minicart .checkout-link a:last-child {
  margin: 0;
}

.minicart .checkout-link a:hover,
.minicart .checkout-link a.red-color {
  background: #fe4536;
}

.minicart .checkout-link a.red-color:hover {
  background: #84b77c;
}

.woocommerce-widget-layered-nav ul li,
.wc-block-product-categories ul li,
.widget_product_categories ul li {
  border: 0;
  color: inherit;
  font-family: inherit;
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.25rem !important;
  line-height: 1.3 !important;
}

.woocommerce-widget-layered-nav ul li a,
.wc-block-product-categories ul li a,
.widget_product_categories ul li a {
  color: inherit;
  float: left;
  text-transform: capitalize;
  text-align: left;
  position: relative;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.woocommerce-widget-layered-nav ul li a:after,
.wc-block-product-categories ul li a:after,
.widget_product_categories ul li a:after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  display: block;
  left: -1.25rem;
  top: 0.1825rem;
  font-size: 13px;
  font-weight: 700;
  color: var(--primary);
}

.woocommerce-widget-layered-nav ul li a:hover,
.wc-block-product-categories ul li a:hover,
.widget_product_categories ul li a:hover {
  color: var(--primary);
  transform: translateX(10px);
}

.woocommerce-widget-layered-nav ul li ul,
.wc-block-product-categories ul li ul,
.widget_product_categories ul li ul {
  margin-bottom: -0.625rem;
}

.woocommerce-widget-layered-nav ul ul,
.wc-block-product-categories ul ul,
.widget_product_categories ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.wp-block-woocommerce-product-search form {
  margin-bottom: 10px;
}

.wp-block-woocommerce-product-search .wc-block-product-search__label {
  display: none;
}

.wp-block-woocommerce-product-search .wc-block-product-search__fields {
  position: relative;
}

.wp-block-woocommerce-product-search input {
  border-radius: var(--border-radius-base) !important;
  background-color: #f5f5f5;
  border: 0;
  z-index: 0 !important;
  height: 60px;
  padding: 6px 60px 6px 20px !important;
  display: block;
  width: 100%;
}

.wp-block-woocommerce-product-search .wc-block-product-search__button {
  height: 50px;
  width: 50px;
  font-size: 24px;
  padding: 0;
  justify-content: center;
  border-radius: var(--border-radius-base);
  position: absolute;
  top: 0;
  right: 6px;
  display: flex;
  align-items: center;
  background-color: var(--primary);
  border: 0;
  color: #fff;
  transform: translateY(-50%);
  top: 50%;
}

.wc-block-grid .wc-block-grid__products .add_to_cart_button,
.wp-block-product-tag .wc-block-grid__products .add_to_cart_button,
.wp-block-products-by-attribute .wc-block-grid__products .add_to_cart_button,
.wc-block-product-category .wc-block-grid__products .add_to_cart_button {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  display: block;
  border-radius: var(--border-radius-sm);
}

.wc-block-grid .wc-block-grid__products .add_to_cart_button:before,
.wp-block-product-tag .wc-block-grid__products .add_to_cart_button:before,
.wp-block-products-by-attribute .wc-block-grid__products .add_to_cart_button:before,
.wc-block-product-category .wc-block-grid__products .add_to_cart_button:before {
  content: none;
}

.shop-widget .wp-block-group__inner-container>h5,
.shop-widget .wp-block-group__inner-container>.h5,
.shop-widget.widget.widget_block .wp-block-group__inner-container h4,
.shop-widget.widget.widget_block .wp-block-group__inner-container .h4 {
  position: relative;
  padding-left: 20px;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.shop-widget .wp-block-group__inner-container>h5:before,
.shop-widget .wp-block-group__inner-container>.h5:before,
.shop-widget.widget.widget_block .wp-block-group__inner-container h4:before,
.shop-widget.widget.widget_block .wp-block-group__inner-container .h4:before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 10px;
  background-color: var(--primary);
  position: absolute;
  left: 0;
  top: 0;
}

.pswp .pswp__bg {
  background: rgba(0, 0, 0, 0.9);
}

.pswp .pswp__caption__center {
  text-align: center;
}

.woocommerce .woocommerce-ordering select {
  border: 1px solid #999 !important;
}

body.woo-variation-swatches.wvs-style-squared .variable-items-wrapper .variable-item.button-variable-item {
  width: 35px;
  height: 35px;
}

.variable-item:not(.radio-variable-item) {
  width: 35px;
  height: 35px;
  border: 2px solid #fff;
}

body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected,
body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected:hover,
body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected,
body.woo-variation-swatches .variable-items-wrapper .variable-item:not(.radio-variable-item).selected:hover {
  border: 2px solid #fff;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3);
}

.woocommerce div.product form.cart .variations {
  margin-bottom: 15px;
}

.woocommerce div.product .woocommerce-variation-price span.price {
  font-size: 16px !important;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
  padding: 4px 2px;
}

.yith-wcwl-share ul li:before {
  content: none;
}

.woocommerce-my-account-content {
  border-radius: var(--border-radius-sm);
}

.woocommerce-my-account-content a.button {
  padding: 8px 30px;
}

.yith-wcwl-add-to-wishlist {
  margin-top: 0;
}

.yith-wcwl-add-to-wishlist .yith-wcwl-add-button>a i {
  margin-right: 0;
}

table.wishlist_table {
  font-size: 16px;
}

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
  height: 85px;
  width: 65px;
  border-radius: var(--border-radius-base);
}

.wc-block-grid__product .wc-block-grid__product-price .amount {
  font-size: 20px;
}

.woocommerce-product-gallery__wrapper {
  overflow: hidden;
  border-radius: var(--border-radius-base);
}

.woocommerce a.button.alt {
  padding: 12px 20px;
  line-height: 1.5;
  background: var(--primary);
}

.woocommerce a.button.alt:hover {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}

.woocommerce a.button.alt:before {
  content: "\f11e";
  font-family: "Flaticon";
  opacity: 1;
  visibility: unset;
  font-weight: normal;
  font-size: inherit;
  margin-right: 5px;
}

.woocommerce-mini-cart__empty-message {
  padding: 20px 20px;
  font-size: 18px;
  color: #1a1668 !important;
}

.wc-block-components-price-slider--is-input-inline .wc-block-components-price-slider__controls .wc-block-components-price-slider__amount {
  padding: 0;
  border: 0;
  color: var(--title);
  font-weight: 500;
}

.wc-block-components-price-slider__controls .wc-block-components-price-slider__amount:last-child {
  text-align: right;
}

#yith-quick-view-modal.open .yith-wcqv-main {
  border-radius: 6px;
}

#yith-quick-view-content div.summary {
  padding: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: inherit;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-size: 16px 12px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}

.select2-container--default .select2-selection--single b {
  display: none;
}

.variations {
  margin-bottom: 10px;
}

.variations td,
.variations th {
  padding: 5px 0;
  vertical-align: middle;
}

.variations .reset_variations {
  display: none;
}

.wishlist_table tr td,
.wishlist_table tr th.product-checkbox,
.wishlist_table tr th.wishlist-delete {
  text-align: left;
}

/* WooCommerce Start */
.gift-bx {
  margin-bottom: 30px;
}

.gift-bx .gift-media {
  margin-bottom: 20px;
}

.gift-bx .gift-media img {
  width: 100%;
}

.gift-bx .gift-content .title {
  font-weight: 700;
  font-size: 20px;
}

.gift-bx .gift-content .text {
  font-weight: 400;
  font-size: 14px;
}

.whatwedo-section {
  background-size: cover;
  min-height: 580px;
}

@media only screen and (max-width: 991px) {
  .whatwedo-section {
    min-height: 430px;
  }
}

@media only screen and (max-width: 575px) {
  .whatwedo-section {
    min-height: 300px;
  }
}

.whatwedo-box {
  background: var(--light);
  padding: 25px 60px;
}

.whatwedo-box .dz-title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}

.whatwedo-box .dz-media {
  width: 100%;
  max-height: 350px;
  margin-bottom: 40px;
}

.whatwedo-box .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.whatwedo-box .breadcrumb-row {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .whatwedo-box {
    padding: 20px 40px;
  }

  .whatwedo-box .dz-title {
    font-size: 30px;
    margin-bottom: auto;
  }

  .whatwedo-box .dz-media {
    margin-bottom: 20px;
  }

  .whatwedo-box .breadcrumb-row {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .whatwedo-box {
    padding: 40px;
  }

  .whatwedo-box .dz-title {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .whatwedo-box .dz-media {
    margin-bottom: 20px;
  }

  .whatwedo-box .breadcrumb-row {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .whatwedo-box {
    padding: 0;
    position: relative;
    z-index: 2;
  }

  .whatwedo-box .dz-title {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .whatwedo-box .breadcrumb-row {
    margin-bottom: 15px;
  }
}

.registration-media {
  text-align: right;
  position: relative;
  z-index: -1;
  margin: auto;
  margin-top: -90px;
}

@media (min-width: 1921px) {
  .registration-media {
    text-align: center;
  }
}

@media only screen and (max-width: 1480px) {
  .registration-media {
    margin-top: 0;
  }
}

@media only screen and (min-width: 300px) and (max-width: 991px) {
  .registration-media {
    position: absolute;
    margin-top: 0px;
    right: 10%;
    bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .registration-media {
    right: 5%;
  }
}

@media only screen and (max-width: 1480px) {
  .registration-media img {
    height: 600px;
    width: 100%;
    object-fit: contain;
    object-position: bottom;
  }
}

@media only screen and (max-width: 991px) {
  .registration-media img {
    height: auto;
  }
}

@media only screen and (min-width: 300px) and (max-width: 991px) {
  .registration-media img {
    height: 280px;
    object-fit: contain;
  }
}

@media only screen and (max-width: 767px) {
  .registration-media img {
    height: 240px;
  }
}

.start-side-content {
  padding: 120px 80px 0 80px;
  position: relative;
  background: #FFEDD4;
  z-index: 1;
}

.start-side-content:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 16%;
  width: 51%;
  height: 60%;
  background: rgb(255, 255, 255);
  border-radius: 50% 50% 0px 0px;
  z-index: -2;
}

@media only screen and (min-width: 300px) and (max-width: 991px) {
  .start-side-content {
    padding: 120px 80px 80px 80px;
    height: 400px;
    display: flex;
    align-items: center;
  }

  .start-side-content:after {
    left: auto;
    width: 200px;
    height: 250px;
    right: 12%;
  }
}

@media only screen and (max-width: 1199px) {
  .start-side-content {
    padding: 120px 20px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .start-side-content {
    padding: 120px 15px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .start-side-content {
    padding: 120px 30px 80px 30px;
    height: 330px;
  }

  .start-side-content .dz-bnr-inr-entry h1,
  .start-side-content .dz-bnr-inr-entry .h1 {
    font-size: 26px;
    line-height: 1.1;
  }

  .start-side-content:after {
    width: 160px;
    height: 218px;
    right: 4%;
  }
}

@media only screen and (max-width: 575px) {
  .start-side-content {
    padding: 120px 15px 80px 15px;
  }
}

.end-side-content {
  padding: 120px 80px 80px 80px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .end-side-content {
    padding: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .end-side-content {
    padding: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .end-side-content {
    padding: 30px 15px 30px;
  }
}

.login-area {
  padding: 80px;
  max-width: 522px;
  margin: auto;
  border-radius: 30px;
  border: 1px solid var(--secondary);
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .login-area {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .login-area {
    padding: 15px;
  }
}

.login-area form .label-title {
  margin-bottom: 5px;
  font-weight: 500;
  font-family: var(--font-family-title);
  font-size: 16px;
}

.login-area .secure-input {
  position: relative;
  z-index: 1;
}

.login-area .secure-input .show-pass {
  cursor: pointer;
  position: absolute;
  right: 0;
  height: auto;
  width: 40px;
  top: 50%;
  transform: translate(-20%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.login-area .secure-input .show-pass i {
  color: #000;
}

.login-area .secure-input .show-pass:after {
  content: "";
  height: 2px;
  width: 24px;
  background-color: #000;
  position: absolute;
  border-radius: 5px;
  transform: rotate(15deg);
  opacity: 1;
}

.login-area .secure-input .show-pass.active:after {
  opacity: 0;
}

.order-details-content li strong {
  color: #000;
}

.order-details-billing,
.order-details-address {
  border: 1px solid #000;
  padding: 20px;
  height: 100%;
}

@media only screen and (max-width: 575px) {
  .order-details-billing {
    margin-bottom: 30px;
  }
}

.order-details-content {
  display: flex;
  flex-wrap: wrap;
}

.order-details-content li {
  display: grid;
  width: 33%;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .order-details-content li {
    width: 100%;
  }
}

.ecommerce-order .check-tbl {
  border: 1px solid #000;
  border-radius: 5px;
}

.ecommerce-order .check-tbl thead th {
  padding: 15px;
  border-right: 1px solid #000;
}

.ecommerce-order .check-tbl tbody tr {
  border-bottom: 1px solid #000;
}

.ecommerce-order .check-tbl tbody tr td.product-item-name {
  padding: 15px !important;
}

.ecommerce-order .check-tbl tbody tr td:first-child {
  padding: 15px !important;
}

.ecommerce-order .check-tbl tbody tr td:last-child {
  padding: 15px !important;
}

.ecommerce-order .check-tbl tbody tr td.product-item-price {
  border-left: 1px solid #000;
}

.account-ticket .custom-control.style-1 .custom-checkbox {
  justify-content: space-between;
}

.account-ticket .custom-control.style-1 .custom-checkbox .title {
  color: #686868;
  font-size: 14px;
}

.account-ticket .custom-control.style-1 .custom-checkbox .text {
  color: #000;
  font-weight: 600;
}

.compare-box {
  margin: 0 auto 0 auto;
  margin-top: -287px;
  position: relative;
  z-index: 99;
}

.compare-table {
  margin-top: -400px;
  position: relative;
  z-index: 99;
}

@media only screen and (max-width: 1199px) {
  .compare-table {
    margin-top: -200px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table {
    margin-top: -150px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 991px) {
  .compare-table .shop-card.style-1 .dz-media {
    width: auto;
    height: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .compare-table tr {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1199px) {
  .compare-table tr.compare-product {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .compare-table tr.compare-product {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table tr.compare-product {
    padding-top: 0px;
  }
}

.compare-table tr.compare-product td:first-child {
  background: transparent;
  border: 0;
}

.compare-table tr.compare-product td:last-child {
  border: 0;
}

.compare-table tr.compare-product td:nth-child(2) {
  border-radius: 20px 0 0 0;
}

.compare-table tr.compare-product td:nth-child(5) {
  border-radius: 0 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .compare-table tr.compare-product .shop-card.style-1 .dz-media {
    margin: 0px auto 0px auto;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table tr.compare-product .shop-card.style-1 .dz-content {
    padding: 10px 0 0 0;
  }

  .compare-table tr.compare-product .shop-card.style-1 .price,
  .compare-table tr.compare-product .shop-card.style-1 .star-rating {
    display: none;
  }

  .compare-table tr.compare-product .shop-card.style-1 .title {
    font-size: 13px;
    text-align: center;
    margin-bottom: 15px;
  }
}

.compare-table tr.compare-product .shop-card.style-1 .compare-table {
  margin-top: -150px;
}

.compare-table tr td:empty {
  padding: 0 !important;
}

.compare-table tr td {
  background: #fff;
  padding: 7px 30px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: var(--title);
  font-weight: 400;
  border-right: 1px solid #D7D7D7;
}

@media only screen and (max-width: 1199px) {
  .compare-table tr td:first-child {
    border-right: 0;
  }
}

@media only screen and (max-width: 1480px) {
  .compare-table tr td {
    padding: 5px 15px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 1199px) {
  .compare-table tr td {
    flex: 0 0 25%;
    text-align: center;
    width: 25%;
    word-break: break-word;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table tr td {
    padding: 5px 8px;
  }
}

@media only screen and (max-width: 575px) {
  .compare-table tr td {
    font-size: 11px;
    line-height: 1.4;
  }
}

.compare-table tr td:first-child {
  background: #FFFAF3;
  width: 450px;
}

@media only screen and (max-width: 1199px) {
  .compare-table tr td:first-child {
    flex: 0 0 100%;
    text-align: center;
    width: 100%;
  }
}

.compare-table tr td:last-child {
  border-right: 0;
}

.compare-table tr td i {
  font-size: 10px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  background: var(--secondary);
  color: #fff;
  border-radius: 50%;
  margin-right: 15px;
}

@media only screen and (max-width: 1480px) {
  .compare-table tr td i {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1199px) {
  .compare-table tr td i {
    margin: auto;
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .compare-table tr td i {
    width: 13px;
    height: 14px;
    line-height: 14px;
    margin-bottom: 5px;
  }
}

.compare-table tr.compare-table-head td {
  padding: 15px 30px;
  background: var(--secondary);
  border: 1px solid var(--secondary);
}

@media only screen and (max-width: 1480px) {
  .compare-table tr.compare-table-head td {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .compare-table tr.compare-table-head td {
    border: 0;
  }
}

.compare-table tr.compare-table-head td h5,
.compare-table tr.compare-table-head td .h5 {
  margin: 0;
  line-height: 30px;
  color: #fff;
}

@media only screen and (max-width: 575px) {

  .compare-table tr.compare-table-head td h5,
  .compare-table tr.compare-table-head td .h5 {
    font-size: 14px;
    line-height: 1.3;
  }
}

.compare-table tr.compare-table-head td:first-child {
  border-radius: 10px 0 0 10px;
}

.compare-table tr.compare-table-head td:last-child {
  border-radius: 0 10px 10px 0;
}

.compare-table tr.compare-table-head td:first-child,
.compare-table tr.compare-table-head td:last-child {
  border-color: transparent;
}

@media only screen and (max-width: 1199px) {

  .compare-table tr.compare-table-head td:first-child,
  .compare-table tr.compare-table-head td:last-child {
    border-radius: 10px;
  }
}

.compare-table tr.compare-start td {
  padding-top: 35px;
}

@media only screen and (max-width: 1480px) {
  .compare-table tr.compare-start td {
    padding-top: 15px;
  }
}

.compare-table tr.compare-end td {
  padding-bottom: 35px;
}

@media only screen and (max-width: 1480px) {
  .compare-table tr.compare-end td {
    padding-bottom: 15px;
  }
}

.faq-head {
  margin-bottom: 60px;
}

.faq-head .input-group {
  max-width: 400px;
  margin: auto;
}

@media only screen and (max-width: 1199px) {
  .faq-head {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-head {
    margin-bottom: 20px;
  }
}

.main-faq-content {
  position: relative;
  padding-top: 55px;
}

@media only screen and (max-width: 1480px) {
  .main-faq-content {
    padding-top: 40px;
  }
}

.faq-content-box {
  position: relative;
}

.faq-content-box.style-1 {
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 305px;
  border-radius: 30px;
  border: 1px solid var(--secondary);
}

.faq-content-box.style-1.light {
  background: #F6F6F6;
}

.faq-content-box.style-1.dark {
  background: #24262B;
}

.faq-content-box.style-1.dark .dz-title {
  color: #fff;
}

.faq-content-box.style-1.dark p {
  color: #fff;
  opacity: 0.7;
}

.faq-content-box.style-1.dark .faq-link {
  color: #fff;
}

.faq-content-box.style-1 .dz-title {
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 20px;
}

.faq-content-box.style-1 p {
  font-family: var(--font-family-title);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 30px;
  color: var(--title);
}

.faq-content-box.style-1 .faq-link {
  display: flex;
  align-items: center;
  font-family: var(--font-family-title);
  font-size: 15px;
  font-weight: 500;
  color: var(--title);
  margin-top: auto;
}

.faq-content-box.style-1 .faq-link i {
  font-size: 20px;
}

.faq-content-box.style-1 .faq-link i,
.faq-content-box.style-1 .faq-link svg {
  margin-right: 8px;
}

@media only screen and (max-width: 991px) {
  .faq-content-box.style-1 {
    min-height: 250px;
    padding: 25px 20px;
  }

  .faq-content-box.style-1 .dz-title {
    font-size: 18px;
  }

  .faq-content-box.style-1 p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-content-box.style-1 .dz-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .faq-content-box.style-1 .faq-link {
    font-size: 14px;
  }
}

.faq-side-content {
  background: #FFEDD4;
  padding: 130px 75px 75px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1480px) {
  .faq-side-content {
    padding: 130px 60px 70px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .faq-side-content {
    padding: 130px 30px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-side-content {
    padding: 100px 20px 40px;
  }
}

.faq-end-content {
  padding: 120px 150px 45px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1480px) {
  .faq-end-content {
    padding: 130px 60px 70px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .faq-end-content {
    padding: 130px 30px 70px;
  }
}

@media only screen and (max-width: 991px) {
  .faq-end-content {
    padding: 50px 60px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-end-content {
    padding: 30px 20px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-end-content {
    padding: 20px 20px 10px;
  }
}

.account-sidebar {
  background: #fff;
  border-radius: 15px;
  position: relative;
  z-index: 9;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 1199px) {
  .account-sidebar {
    position: fixed;
    z-index: 999999;
    top: 0;
    margin: 0;
    left: 0;
    border-radius: 0;
    height: 100vh !important;
    overflow: scroll;
    width: 280px;
  }

  .account-sidebar:not(.show) {
    left: -300px;
  }
}

.account-sidebar .profile-head {
  padding: 25px;
  border-radius: 10px;
  text-align: center;
}

.account-sidebar .profile-head .user-thumb {
  width: 90px;
  height: auto;
  position: relative;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  margin: 0 auto 10px;
}

.account-sidebar .profile-head .user-thumb .badge {
  position: absolute;
  top: 0;
  right: -15px;
}

.account-sidebar .account-nav .nav-title {
  padding: 10px 20px;
  margin-bottom: 10px;
}

.account-sidebar .account-nav ul {
  padding-bottom: 10px;
}

.account-sidebar .account-nav ul li a {
  padding: 8px 20px;
  display: block;
}

.account-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 12px;
  min-height: 250px;
}

@media only screen and (max-width: 575px) {
  .account-card {
    padding: 20px;
  }
}

.account-wrapper {
  padding-left: 35px;
}

@media only screen and (max-width: 1199px) {
  .account-wrapper {
    padding-left: 15px;
  }
}

.toggle-info {
  display: none;
  padding: 18px 30px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .toggle-info {
    display: flex;
  }
}

@media only screen and (max-width: 575px) {
  .toggle-info {
    padding: 12px 15px;
  }
}

.toggle-btn {
  background-color: #CC0D39;
  padding: 10px 15px;
  border-radius: 6px;
  color: #fff !important;
}

.toggle-btn:hover,
.toggle-btn:focus,
.toggle-btn:active {
  color: #fff !important;
}

@media only screen and (max-width: 991px) {
  .toggle-btn {
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
  }

  .toggle-btn:after {
    content: "";
    height: 100vh;
    width: 0;
    position: fixed;
    left: -20%;
    top: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 99999;
    transform: unset;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
  }

  .toggle-btn.active:after {
    width: 100vw;
    left: 0;
  }
}

.table-style-1 tr th {
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 12px;
}

.table-style-1 tr th:last-child {
  text-align: end;
}

.table-style-1 tbody tr td {
  font-size: 14px;
  padding: 15px 12px;
}

.table-style-1 tbody tr td:last-child {
  text-align: end;
}

.table-style-1 .table {
  min-width: 600px;
}

.profile-edit {
  padding: 25px;
  margin: -30px -30px 0;
  border-radius: 8px 8px 0 0;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.profile-edit .thumb {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 50%;
  margin-right: 15px;
}

.profile-edit .thumb img {
  border-radius: 50%;
}

@media only screen and (max-width: 575px) {
  .profile-edit {
    padding: 20px;
    margin: -20px -20px 25px;
  }
}

.review-card {
  background: #fff;
  padding: 20px;
  border: 1px solid;
  border-radius: 8px;
}

.review-card .review-media {
  display: block;
  margin: 0 15px 0 0;
  width: 75px;
}

.review-card .review-media img {
  border-radius: 50%;
}

.review-card .review-head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.review-card .star-rating i {
  font-size: 14px;
}

.review-card p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .review-card .review-media {
    width: 60px;
  }
}

.address-card .account-address-box {
  border: 1px solid #000;
  border-radius: 8px 8px 0px 0px;
  padding: 15px;
}

.address-card .account-address-bottom {
  display: flex;
  border: 1px solid #000;
  border-top: 0;
  padding: 12px 15px;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
}

.address-card .account-address-bottom a i {
  font-size: 14px;
}

.account-card-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 35px;
}

.account-card-add .account-address-add {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.account-card-add .account-address-add svg {
  fill: #fff;
}

.custom-control.style-1 {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}

.custom-control.style-1 .price {
  margin-left: auto;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

.custom-control.style-1 .custom-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #c8c8c8;
  padding: 20px 40px 19px 20px;
  border-radius: 6px;
  transition: 0.4s;
  min-height: 90px;
  height: 90px;
}

.custom-control.style-1 .custom-checkbox .title {
  font-size: 16px;
  color: var(--title);
  font-weight: 500;
  display: block;
}

.custom-control.style-1 .custom-checkbox .text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  display: block;
}

.custom-control.style-1 .custom-checkbox.payment {
  min-height: 65px;
  height: 65px;
}

.custom-control.style-1 .form-check-input {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0px, -50%);
  margin: 0;
  transition: 0.4s;
}

.custom-control.style-1 .form-check-input:checked+label {
  border: 1px solid #2856F9;
  background: #EBEDF5;
}

.custom-control.style-1 .form-check-input:checked+label .price {
  color: #2856F9;
}

.custom-control.style-1 .form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232856F9'/%3e%3c/svg%3e");
  background-color: #fff;
  border: 1px solid #2856F9;
}

@media only screen and (max-width: 575px) {
  .custom-control.style-1.style-thumb .custom-checkbox {
    display: block;
    height: auto;
  }

  .custom-control.style-1.style-thumb .custom-checkbox .thumb {
    margin-bottom: 12px;
  }
}

.order-cancel-card .order-cancel-box {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.order-cancel-card .order-cancel-box .cancel-media {
  width: 90px;
}

.order-cancel-card .order-cancel-box .order-cancel-content {
  margin-left: 20px;
}

.order-cancel-card .order-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-details .order-head {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.order-details .order-head .head-thumb {
  width: 80px;
  height: auto;
  border: 1px solid var(--border-color);
  padding: 10px;
  border-radius: 8px;
}

.order-details .shiping-tracker-detail {
  margin-bottom: 15px;
}

.order-details .shiping-tracker-detail .title {
  font-weight: 500;
  font-size: 15px;
}

.dz-tabs.style-3 .nav-tabs {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dz-tabs.style-3 .nav-tabs .nav-link {
  display: inline-block;
  padding: 12px 0;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-right: 15px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  background-color: transparent;
  width: auto !important;
}

.dz-tabs.style-3 .nav-tabs .nav-link:after {
  content: "";
  background-color: var(--primary);
  width: 0;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 50%;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  transform: translateX(-50%);
}

.dz-tabs.style-3 .nav-tabs .nav-link.active,
.dz-tabs.style-3 .nav-tabs .nav-link:hover {
  color: var(--primary);
}

.dz-tabs.style-3 .nav-tabs .nav-link.active:after,
.dz-tabs.style-3 .nav-tabs .nav-link:hover:after {
  width: 100%;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 1.25rem;
  bottom: 3rem;
  position: absolute;
  content: " ";
  width: 0.1275rem;
  left: 10px;
  border-right: 2px dashed rgba(0, 0, 0, 0.15);
}

.widget-timeline .timeline>li {
  margin-bottom: 0.9375rem;
  position: relative;
}

.widget-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.widget-timeline .timeline>li:after {
  content: " ";
  display: table;
  clear: both;
}

.widget-timeline .timeline>li>.timeline-box {
  padding: 10px 10px 10px 15px;
  position: relative;
  display: block;
  margin-left: 2.5rem;
}

.widget-timeline .timeline>li>.timeline-box span {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.3125rem;
  letter-spacing: 0.0625rem;
}

.widget-timeline .timeline>li>.timeline-box p {
  font-size: 0.875rem;
  margin-bottom: 0;
  color: #000;
}

.widget-timeline .timeline>li>.timeline-badge {
  border-radius: 50%;
  height: 1.375rem;
  left: 0;
  position: absolute;
  top: 0.625rem;
  width: 1.375rem;
  border-width: 0.125rem;
  border-style: solid;
  padding: 0.25rem;
  background-color: #fff;
  border-color: #cfcfcf;
}

.widget-timeline .timeline>li>.timeline-badge:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100%;
  display: block;
  background: #cfcfcf;
}

.widget-timeline .timeline>li>.timeline-badge.primary {
  border-color: #CC0D39;
}

.widget-timeline .timeline>li>.timeline-badge.primary:after {
  background: #CC0D39;
}

.widget-timeline .timeline>li>.timeline-badge.success {
  border-color: #31A56D;
}

.widget-timeline .timeline>li>.timeline-badge.success:after {
  background: #31A56D;
}

.tracking-item-content {
  display: flex;
  justify-content: space-between;
}

.tracking-item {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.tracking-item .tracking-product {
  width: 85px;
  height: auto;
  border: 1.5px solid var(--border-color);
  padding: 10px;
  border-radius: 8px;
  margin-right: 20px;
}

.tracking-product-content .title,
.tracking-product-content p {
  margin-bottom: 0;
}

.tracking-product-content strong {
  color: #000;
}

.tracking-receiver li {
  margin-bottom: 5px;
  font-size: 14px;
}

.tracking-receiver strong {
  color: #000;
}

.confirmation-card {
  padding: 50px;
}

.confirmation-card .thumb {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 575px) {
  .confirmation-card {
    padding: 30px;
  }
}

.confirmed-request {
  padding: 50px;
}

.confirmed-request .cancel-btn {
  width: 100px;
  height: 100px;
  background: #46b95a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmed-request .cancel-btn i {
  color: #fff;
  font-size: 60px;
}

@media only screen and (max-width: 575px) {
  .confirmed-request {
    padding: 30px;
  }

  .confirmed-request .cancel-btn {
    width: 80px;
    height: 80px;
  }

  .confirmed-request .cancel-btn i {
    font-size: 35px;
  }
}

.total-contain {
  background-color: #fff;
  padding: 20px 25px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {
  .total-contain {
    display: flex;
    justify-content: space-between;
  }
}

.total-contain .total-icon {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  border: 1px solid var(--border-color);
}

@media only screen and (max-width: 767px) {
  .total-contain .total-icon {
    margin-bottom: 0;
  }
}

.total-contain .total-icon svg path {
  fill: #CC0D39;
}

.total-contain .total-detail .title {
  font-weight: 500;
  margin-bottom: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {
  .total-contain .total-detail .title {
    text-align: end;
  }
}

.total-contain .total-detail .text {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 14px;
}

.total-contain:hover {
  background-color: var(--primary);
}

.total-contain:hover .total-detail .title,
.total-contain:hover .total-detail .text {
  color: #fff;
}

.profile-edit .thumb {
  position: relative;
}

.profile-edit .thumb .edit-img {
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 35px;
  height: 35px;
  border: 1px solid #fff;
  padding: 5px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-edit .thumb .edit-img i {
  color: #fff;
}

.avatar-upload .avatar-preview>div {
  display: block;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.thumb-edit label {
  background: var(--primary);
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 0;
  left: -12px;
}

.countries-card ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--border-color);
}

.countries-card ul .thumb-detail img {
  width: 30px;
}

.countries-card ul .thumb-detail span {
  font-weight: 500;
  margin-left: 5px;
  font-size: 15px;
}

.countries-card ul .thumb-content .amount {
  margin-bottom: 0;
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  .card .card-body.sales-chart-wraper {
    padding: 0px 15px;
  }
}

.sales-chart-wraper {
  background-color: #fff;
  padding: 0 15px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 767px) {
  .account-card .table-responsive.download-table .table thead tr th {
    padding: 0 10px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .account-card .table-responsive.download-table .table tbody>tr .product-item-img img {
    min-width: 60px;
    height: 60px;
  }

  .account-card .table-responsive.download-table .table tbody>tr .product-item-name {
    font-size: 13px;
  }

  .account-card .table-responsive.download-table .table tbody>tr td .btn-md {
    padding: 8px 14px;
    font-size: 12px;
  }
}

.account-sidebar-wrapper {
  z-index: 99;
  margin-top: -170px;
}

@media only screen and (max-width: 1199px) {
  .account-sidebar-wrapper {
    margin: 0;
    z-index: 99999;
  }
}

.dz-slider1 {
  overflow: hidden;
  position: relative;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.dz-slider1 .swiper-area {
  width: 68.5%;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1680px) {
  .dz-slider1 .swiper-area {
    width: 80%;
  }
}

@media only screen and (max-width: 1480px) {
  .dz-slider1 .swiper-area {
    width: 85%;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-slider1 .swiper-area {
    width: 81%;
  }
}

@media only screen and (max-width: 991px) {
  .dz-slider1 .swiper-area {
    width: 74%;
  }
}

@media only screen and (max-width: 575px) {
  .dz-slider1 .swiper-area {
    width: 73%;
  }
}

.dz-slider1 .dz-main-swiper-thumb {
  position: relative;
  z-index: 1;
  padding-left: 40px;
}

.dz-slider1 .dz-main-swiper-thumb .swiper-wrapper {
  height: auto;
}

.dz-slider1 .dz-main-swiper-thumb .swiper-slide {
  width: 300px;
}

.dz-slider1 .star-1 {
  position: absolute;
  top: 13%;
  left: 45%;
  transform: translate(-43%, -10%);
  z-index: -1;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 991px) {
  .dz-slider1 .star-1 {
    top: 30%;
    left: 70%;
  }
}

.dz-slider1 .star-2 {
  position: absolute;
  top: 13%;
  right: 13%;
  transform: translate(15%, -10%);
  z-index: 1;
  animation: rotate-360 20s infinite linear;
}

@media only screen and (max-width: 1199px) {

  .dz-slider1 .star-1,
  .dz-slider1 .star-2 {
    display: none;
  }
}

.dz-slider1 .swiper-slide {
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.dz-slider1 .dz-main-swiper {
  z-index: 2;
  overflow: hidden;
  position: relative;
}

.dz-slider1 .dz-main-swiper .swiper-slide {
  transition: 0.5s;
  opacity: 0 !important;
}

.dz-slider1 .dz-main-swiper .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}

.dz-slider1 .swiper-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  padding-top: 70px;
  padding-left: 30px;
}

@media only screen and (max-width: 1680px) {
  .dz-slider1 .swiper-content {
    padding-top: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-slider1 .swiper-content {
    padding-top: 40px;
  }
}

.dz-slider1 .swiper-pagination-wrapper {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 20px;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 991px) {
  .dz-slider1 .swiper-pagination-wrapper {
    display: none;
  }
}

.dz-slider1 .banner-content {
  padding-top: 150px;
  padding-bottom: 60px;
  height: 100%;
}

@media only screen and (max-width: 1480px) {
  .dz-slider1 .banner-content {
    height: 860px;
  }
}

@media (max-width: 1366px) {
  .dz-slider1 .banner-content {
    height: 100%;
    min-height: 750px;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-slider1 .banner-content {
    padding-top: 100px;
    padding-bottom: 30px;
    min-height: 650px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-slider1 .banner-content {
    height: 100%;
  }
}

.dz-slider1 .banner-content .bottom-content {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: 90px;
  z-index: 99;
  padding-left: 30px;
}

@media only screen and (max-width: 1480px) {
  .dz-slider1 .banner-content .bottom-content {
    bottom: 40px;
  }
}

@media (max-width: 1366px) {
  .dz-slider1 .banner-content .bottom-content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .dz-slider1 .banner-content .bottom-content {
    position: static;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-slider1 .banner-content .bottom-content {
    display: none;
  }
}

.dz-slider1 .banner-content .bottom-content .sub-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0;
  color: var(--title);
  margin-bottom: 10px;
  display: block;
}

.dz-slider1 .banner-content .bottom-content .title {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  width: 55%;
  margin: 0;
  margin-bottom: 10px;
  display: block;
}

.dz-slider1 .banner-content .content-info {
  padding-bottom: 30px;
  z-index: 2;
  position: relative;
  height: 100%;
}

.dz-slider1 .banner-content .sub-title {
  color: #F0BBE3;
  line-height: 1.6;
  letter-spacing: 20px;
  font-weight: var(--headings-font-weight3);
}

.dz-slider1 .banner-content .title {
  color: var(--title);
  font-size: 80px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1480px) {
  .dz-slider1 .banner-content .title {
    font-size: 3.75rem;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-slider1 .banner-content .title {
    font-size: 2.5rem;
  }
}

.dz-slider1 .banner-content .text {
  color: #5E626F;
  font-size: 18px;
  font-weight: 400;
  max-width: 480px;
}

.dz-slider1 .banner-content .swiper-meta-items {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1480px) {
  .dz-slider1 .banner-content .swiper-meta-items {
    margin-bottom: 20px;
  }
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content {
  margin-right: 90px;
}

@media only screen and (max-width: 767px) {
  .dz-slider1 .banner-content .swiper-meta-items .meta-content {
    margin-right: 10px;
  }
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content span {
  display: block;
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content .color-name {
  margin-bottom: 10px;
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content .color-name,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .price-name {
  font-size: 14px;
  font-weight: 600;
  color: var(--title);
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content .price-num {
  margin-bottom: 0px;
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-family-title);
}

@media only screen and (max-width: 767px) {
  .dz-slider1 .banner-content .swiper-meta-items .meta-content .price-num {
    font-size: 28px;
  }
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .wp-block-button__link,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size a,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size a,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size input,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size a.checkout-button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #respond input#submit,
.woocommerce #respond .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size input#submit,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce a.button,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size a.button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce button.button,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size button.button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce input.button,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size input.button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #place_order,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size #place_order {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-right: 10px !important;
  background-color: #fff !important;
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+.btn,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+.wp-block-button__link,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce .widget_shopping_cart .buttons .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce.widget_shopping_cart .buttons .btn-check:checked+a,
.woocommerce.widget_shopping_cart .buttons .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #review_form #respond .form-submit .btn-check:checked+input,
.woocommerce #review_form #respond .form-submit .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+input,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart-form .table tr .btn-check:checked+.button,
.woocommerce-cart-form .table tr .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+.button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce-cart .wc-proceed-to-checkout .btn-check:checked+a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a.checkout-button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce #respond .btn-check:checked+input#submit,
.woocommerce #respond .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+input#submit,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+a.button,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+a.button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+button.button,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+button.button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+input.button,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+input.button,
.dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .woocommerce .btn-check:checked+#place_order,
.woocommerce .dz-slider1 .banner-content .swiper-meta-items .meta-content .product-size .btn-check:checked+#place_order {
  background-color: var(--secondary) !important;
  color: #fff;
}

.dz-slider1 .banner-content .swiper-meta-items .meta-content del {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: var(--headings-font-weight2);
  color: #E5CCB8;
  margin-bottom: 0;
  font-size: 18px;
  padding: 0 10px;
}

.dz-slider1 .banner-media {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.dz-slider1 .banner-media:after {
  content: attr(data-name);
  position: absolute;
  top: 62%;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  z-index: 9999;
  font-size: 130px;
  color: #fff;
  -webkit-text-stroke: 1.5px #000;
  transform: rotate(180deg) translateY(55%);
  width: auto;
  left: -65px;
  writing-mode: tb;
}

@media (max-width: 1366px) {
  .dz-slider1 .banner-media:after {
    top: 52%;
    font-size: 80px;
    left: -40px;
  }
}

@media only screen and (max-width: 1199px) {
  .dz-slider1 .banner-media:after {
    font-size: 65px;
    left: -40px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-slider1 .banner-media:after {
    font-size: 65px;
    left: -40px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-slider1 .banner-media:after {
    font-size: 45px;
    left: -20px;
  }
}

.dz-slider1 .banner-media .img-preview {
  width: 100%;
  position: relative;
}

.dz-slider1 .banner-media .img-preview:after {
  content: "";
  position: absolute;
  /* background-image: url(../images/next-btn.png); */
  right: 0;
  bottom: 40px;
  width: 41px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 1;
  opacity: 0;
}

@media only screen and (max-width: 575px) {
  .dz-slider1 .banner-media .img-preview:after {
    bottom: 32px;
    width: 30px;
  }
}

.dz-slider1 .banner-media img {
  width: 100%;
  border-radius: 40px;
}

@media only screen and (max-width: 1199px) {
  .dz-slider1 .banner-content .content-btn {
    margin-bottom: 0;
  }

  .dz-slider1 .swiper-slide:after {
    width: 320px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-slider1 .pattern {
    display: none;
  }

  .dz-slider1 .swiper-content {
    padding: 0px;
  }

  .dz-slider1 .banner-content .text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-slider1 .banner-content .text {
    padding-left: 0;
    padding-right: 0;
    border: 0;
    max-width: 270px;
  }

  .dz-slider1 .banner-content h1,
  .dz-slider1 .banner-content .h1 {
    font-size: 2rem;
  }

  .dz-slider1 .banner-content .price {
    display: none;
  }

  .dz-slider1 .banner-content .content-btn {
    margin-top: 30px;
  }

  .dz-slider1 .banner-content .swiper-meta-items {
    margin: 15px 0px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-slider1 .banner-content .sub-title {
    font-size: 14px;
  }

  .dz-slider1 .banner-content .title {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .dz-slider1 .banner-content .swiper-content {
    max-width: 100%;
  }

  .dz-slider1 .banner-content .text {
    max-width: 100%;
  }

  .dz-slider1 .banner-content .swiper-meta-items {
    margin: 15px 0px 10px;
  }

  .dz-slider1 .banner-content .content-btn {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .dz-slider1 .banner-content .content-btn .btn,
  .dz-slider1 .banner-content .content-btn .wp-block-button__link,
  .dz-slider1 .banner-content .content-btn .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .dz-slider1 .banner-content .content-btn a,
  .dz-slider1 .banner-content .content-btn .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .dz-slider1 .banner-content .content-btn a,
  .dz-slider1 .banner-content .content-btn .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .dz-slider1 .banner-content .content-btn input,
  .dz-slider1 .banner-content .content-btn .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .dz-slider1 .banner-content .content-btn .button,
  .dz-slider1 .banner-content .content-btn .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .dz-slider1 .banner-content .content-btn a.checkout-button,
  .dz-slider1 .banner-content .content-btn .woocommerce #respond input#submit,
  .woocommerce #respond .dz-slider1 .banner-content .content-btn input#submit,
  .dz-slider1 .banner-content .content-btn .woocommerce a.button,
  .woocommerce .dz-slider1 .banner-content .content-btn a.button,
  .dz-slider1 .banner-content .content-btn .woocommerce button.button,
  .woocommerce .dz-slider1 .banner-content .content-btn button.button,
  .dz-slider1 .banner-content .content-btn .woocommerce input.button,
  .woocommerce .dz-slider1 .banner-content .content-btn input.button,
  .dz-slider1 .banner-content .content-btn .woocommerce #place_order,
  .woocommerce .dz-slider1 .banner-content .content-btn #place_order {
    font-size: 13px;
  }
}

.logo-header {
  display: flex;
  align-items: center;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #EFBB20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 180px;
  height: 80px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 575px) {
  .logo-header {
    height: 65px;
  }
}

@media only screen and (max-width: 1199px) {
  .logo-header {
    margin-right: 8px !important;
  }
}

.logo-header a {
  display: table-cell;
  vertical-align: middle;
}

.logo-header img {
  height: 45px;
  max-width: 145px;
  object-fit: contain;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {
  .logo-header img {
    max-width: 180px;
    height: 90px;
  }
}

@media only screen and (max-width: 575px) {
  .logo-header img {
    max-width: 150px;
  }
}

.top-bar {
  background-color: #fff;
  color: #212529;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #d7d7d7;
}

.top-bar .dz-topbar-left {
  float: left;
}

.top-bar .dz-topbar-left li {
  padding-right: 20px;
}

.top-bar .dz-topbar-right {
  float: right;
}

.top-bar .dz-topbar-right li {
  padding-left: 10px;
}

.top-bar .dz-social li a {
  color: inherit;
  font-size: inherit;
}

.top-bar .dz-social li a:hover {
  color: var(--primary);
}

.top-bar .dz-topbar-inner {
  margin-left: -15px;
  margin-right: -15px;
}

.top-bar .dz-topbar-center,
.top-bar .dz-topbar-left,
.top-bar .dz-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
}

.top-bar .dz-topbar-center ul,
.top-bar .dz-topbar-left ul,
.top-bar .dz-topbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.top-bar .dz-topbar-center ul li,
.top-bar .dz-topbar-left ul li,
.top-bar .dz-topbar-right ul li {
  display: inline-block;
  font-size: 15px;
}

.top-bar .dz-topbar-center ul i,
.top-bar .dz-topbar-left ul i,
.top-bar .dz-topbar-right ul i {
  margin-right: 5px;
}

.top-bar .dz-topbar-center ul a,
.top-bar .dz-topbar-left ul a,
.top-bar .dz-topbar-right ul a {
  color: inherit;
}

.top-bar .dz-topbar-center ul a:hover,
.top-bar .dz-topbar-left ul a:hover,
.top-bar .dz-topbar-right ul a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.top-bar .dz-topbar-center .dz-social-icon li,
.top-bar .dz-topbar-left .dz-social-icon li,
.top-bar .dz-topbar-right .dz-social-icon li {
  padding-left: 0;
}

.top-bar .dz-topbar-right ul {
  margin-left: 15px;
}

@media only screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }
}

.text-logo .site-title {
  font-size: 25px;
  line-height: 1;
  margin-bottom: 5px;
}

.text-logo p {
  font-size: 14px;
  line-height: 1;
  margin: 0;
  color: var(--primary);
}

.site-header {
  position: relative;
  z-index: 9999;
}

.site-header .main-bar {
  background: var(--bs-body-bg);
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--light);
}

.site-header .container {
  position: relative;
}

.site-header .extra-nav {
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  height: 80px;
  padding-left: 30px;
  color: var(--title);
}

.site-header .extra-nav .extra-cell {
  display: flex;
  align-items: center;
}

.site-header .extra-nav .extra-cell>* {
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: inherit;
  margin-right: -10px;
}

@media only screen and (max-width: 991px) {
  .site-header .extra-nav .extra-cell>* {
    margin-left: 15px;
  }
}

.site-header .extra-nav .extra-cell .login-link {
  color: var(--title) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-right: 40px !important;
  text-transform: capitalize !important;
  font-family: var(--font-family-title) !important;
}

/* styles.css */
.login-link a {
  color: inherit;
  /* Inherits the color from its parent element */
  text-decoration: none;
  /* Removes the underline */
}

.login-link.open a {
  color: #000;
  /* Custom color when 'open' class is added */
}


@media only screen and (max-width: 1480px) and (min-width: 992px) {
  .site-header .extra-nav .extra-cell .login-link {
    display: none;
  }
}

@media only screen and (max-width: 1480px) {
  .site-header .extra-nav .extra-cell .login-link {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .site-header .extra-nav .extra-cell .login-link {
    margin-right: 0;
  }
}

.site-header .extra-nav .extra-cell .filte-link .filte-btn svg rect {
  fill: var(--title);
}

.site-header .extra-nav .extra-cell ul li button,
.site-header .extra-nav .extra-cell ul li a {
  min-width: 40px;
  padding: 0px 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  line-height: 1;
}

@media only screen and (max-width: 1480px) {

  .site-header .extra-nav .extra-cell ul li button,
  .site-header .extra-nav .extra-cell ul li a {
    padding: 0px 10px;
  }
}

.site-header .extra-nav .extra-cell ul li button i,
.site-header .extra-nav .extra-cell ul li a i {
  font-size: 20px;
  color: var(--title);
  font-weight: 600;
}

.site-header .extra-nav .extra-cell ul li button svg rect,
.site-header .extra-nav .extra-cell ul li a svg rect {
  fill: var(--title);
}

.site-header .extra-nav .extra-cell ul li button .badge,
.site-header .extra-nav .extra-cell ul li a .badge {
  position: absolute;
  top: -4px;
  right: 2px;
  font-size: 11px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
  min-width: 18px;
  font-weight: 700;
}

.site-header .extra-nav .extra-cell ul li.login-link a {
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3607843137);
  min-height: auto;
  padding-bottom: 2px;
}

.site-header .extra-nav .extra-cell ul li.filte-link a {
  margin-left: 30px;
}

@media only screen and (max-width: 991px) {
  .site-header .extra-nav {
    height: 80px;
  }
}

@media only screen and (max-width: 1680px) {
  .site-header .extra-nav {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .site-header .extra-nav {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: -80px;
    left: 0;
    padding: 0 20px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    height: 50px;
    transition: all 0.5s ease 0.5s;
  }

  .site-header .extra-nav.active {
    bottom: 0;
  }

  .site-header .extra-nav.bottom-end {
    bottom: -80px;
  }

  .site-header .extra-nav .extra-cell {
    width: 100%;
  }

  .site-header .extra-nav .extra-cell ul {
    width: 100%;
  }

  .site-header .extra-nav .extra-cell ul .login-link {
    margin-right: auto;
    margin-left: -25px;
  }
}

.site-header .extra-nav #quik-search-btn {
  color: #fff;
  cursor: pointer;
  margin-right: 0px;
  border: 0;
  width: 45px;
  height: 45px;
  background: var(--secondary);
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 575px) {

  .site-header .extra-nav .btn,
  .site-header .extra-nav .wp-block-button__link,
  .site-header .extra-nav .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .site-header .extra-nav a,
  .site-header .extra-nav .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .site-header .extra-nav a,
  .site-header .extra-nav .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .site-header .extra-nav input,
  .site-header .extra-nav .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .site-header .extra-nav .button,
  .site-header .extra-nav .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .site-header .extra-nav a.checkout-button,
  .site-header .extra-nav .woocommerce #respond input#submit,
  .woocommerce #respond .site-header .extra-nav input#submit,
  .site-header .extra-nav .woocommerce a.button,
  .woocommerce .site-header .extra-nav a.button,
  .site-header .extra-nav .woocommerce button.button,
  .woocommerce .site-header .extra-nav button.button,
  .site-header .extra-nav .woocommerce input.button,
  .woocommerce .site-header .extra-nav input.button,
  .site-header .extra-nav .woocommerce #place_order,
  .woocommerce .site-header .extra-nav #place_order {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.site-header .extra-nav ul li {
  display: inline-block;
}

.site-header .extra-nav ul li .icon {
  padding: 0;
  background: transparent;
  min-width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}

.adv-media {
  padding-left: 20px;
}

.adv-media img {
  width: 100%;
  border-radius: 20px;
}

.month-deal {
  border: 1px solid #eee;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-top: 25px;
}

@media only screen and (max-width: 991px) {
  .month-deal {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .month-deal {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
}

.month-deal p {
  font-size: 13px;
  color: #5E626F;
}

.month-deal .sale-countdown .countdown {
  display: flex;
  margin: 0;
}

.month-deal .sale-countdown .date {
  margin: 0 5px;
  padding: 0;
}

.month-deal .sale-countdown .date .time {
  font-size: 22px;
  background: #eee;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 3px;
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: var(--title) !important;
  font-weight: 500;
  font-family: var(--font-family-title);
}

.month-deal .sale-countdown .date .work-time {
  font-size: 12px;
  letter-spacing: 0;
  color: var(--title);
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--font-family-title);
}

a {
  color: var(--title);
}

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 40px 0 39px 15px;
  padding: 0;
  float: right;
  outline: none !important;
}

@media only screen and (max-width: 991px) {
  .navbar-toggler {
    margin: 18px 0px 16px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .navbar-toggler {
    margin: 10px 0px 10px 20px;
  }
}

.navicon {
  width: 45px;
  height: 45px;
  background: var(--secondary);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  background: #fff;
  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.navicon span:nth-child(1) {
  top: 13px;
  width: 22px;
}

.navicon span:nth-child(2) {
  top: 22px;
  width: 25px;
}

.navicon span:nth-child(3) {
  top: 32px;
  width: 20px;
}

.navicon.open span:nth-child(1) {
  top: 23px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navicon.open span:nth-child(3) {
  top: 23px;
  width: 23px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.header-tb .navicon span {
  background: #fff;
}

.header-nav {
  padding: 0;
}

.header-nav::-webkit-scrollbar {
  display: none !important;
}

@media only screen and (max-width: 991px) {
  .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    top: 0;
    background-color: #ffffff;
    margin: 0;
    z-index: 99;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav.show {
    left: -1px;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    margin: 0;
    width: 280px;
  }
}

.header-nav .logo-header {
  display: none;
}

@media only screen and (max-width: 991px) {
  .header-nav .logo-header {
    padding: 30px 20px;
    float: none;
    height: auto;
    width: 240px;
  }
}

.header-nav .nav {
  float: right;
  padding: 0;
  font-size: 0;
}

.header-nav .nav>li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}

.header-nav .nav>li.has-mega-menu {
  position: inherit;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}

.header-nav .nav>li.menu-item-has-children>a:after,
.header-nav .nav>li.sub-menu-down>a:after {
  content: "";
  margin-left: 5px;
  margin-top: -5px;
  /* background-image: url(../images/star.svg); */
  display: inline-block;
  width: 12px;
  height: 12px;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li.menu-item-has-children>a:after,
  .header-nav .nav>li.sub-menu-down>a:after {
    display: none;
  }
}

.header-nav .nav>li.menu-item-has-children>a i,
.header-nav .nav>li.sub-menu-down>a i {
  display: none;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li.menu-item-has-children>a i,
  .header-nav .nav>li.sub-menu-down>a i {
    display: block;
    font-size: 9px;
    margin-left: 5px;
    margin-top: 0;
    vertical-align: middle;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
  }
}

@media only screen and (max-width: 991px) and (max-width: 991px) {

  .header-nav .nav>li.menu-item-has-children>a i,
  .header-nav .nav>li.sub-menu-down>a i {
    background-color: var(--secondary);
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 11px;
    margin: 0;
  }

  .header-nav .nav>li.menu-item-has-children>a i:before,
  .header-nav .nav>li.sub-menu-down>a i:before {
    content: "\f054";
  }
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li.menu-item-has-children.open>a i:before,
  .header-nav .nav>li.sub-menu-down.open>a i:before {
    content: "\f078";
  }
}

/* .header-nav .nav > li > a {
  color: var(--white);
  font-size: 16px;
  padding: 28px 20px;
  font-family: var(--font-family-title);
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  position: relative;
  line-height: 24px;
} */
@media only screen and (max-width: 1199px) {
  .header-nav .nav>li>a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li>a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
}

.header-nav .nav>li>a span {
  display: inline-block;
}

.header-nav .nav>li .mega-menu,
.header-nav .nav>li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 20px 30px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  margin-top: 20px;
  text-align: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  pointer-events: none;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu,
  .header-nav .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 15px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }

  .header-nav .nav>li .mega-menu>li>a i,
  .header-nav .nav>li .sub-menu>li>a i {
    color: #fff !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: #eee;
  }
}

.header-nav .nav>li .mega-menu .menu-item-has-children>a:before,
.header-nav .nav>li .sub-menu .menu-item-has-children>a:before {
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  float: right;
  font-size: 10px;
  margin-top: 2px;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu .menu-item-has-children.open>a:before,
  .header-nav .nav>li .sub-menu .menu-item-has-children.open>a:before {
    content: "\f054";
  }
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu,
  .header-nav .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 15px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
    opacity: 1;
  }

  .header-nav .nav>li .mega-menu>li>a i,
  .header-nav .nav>li .sub-menu>li>a i {
    display: none;
  }

  .header-nav .nav>li .mega-menu .has-child>a:before,
  .header-nav .nav>li .mega-menu .menu-item-has-children>a:before,
  .header-nav .nav>li .mega-menu>li>a i,
  .header-nav .nav>li .sub-menu .has-child>a:before,
  .header-nav .nav>li .sub-menu .menu-item-has-children>a:before,
  .header-nav .nav>li .sub-menu>li>a i {
    color: #fff !important;
    font-size: 14px;
    content: "\f054";
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    font-family: "Font Awesome 5 Free";
    z-index: 3;
    margin: 0 !important;
    background: var(--primary);
    border-radius: var(--border-radius-base);
    font-weight: 900;
    float: right;
  }

  .header-nav .nav>li .mega-menu .has-child.open>a:before .menu-item-has-children.open>a:before,
  .header-nav .nav>li .sub-menu .has-child.open>a:before .menu-item-has-children.open>a:before {
    content: "\f078";
    background: #333;
  }
}

.header-nav .nav>li .mega-menu li,
.header-nav .nav>li .sub-menu li {
  position: relative;
}

.header-nav .nav>li .mega-menu li a,
.header-nav .nav>li .sub-menu li a {
  color: #5E626F;
  display: block;
  font-size: 14px;
  padding: 5px 0;
  line-height: 20px;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 400;
}

.header-nav .nav>li .mega-menu li:hover>a,
.header-nav .nav>li .sub-menu li:hover>a {
  color: var(--primary);
}

.header-nav .nav>li .mega-menu li:hover>a:after,
.header-nav .nav>li .sub-menu li:hover>a:after {
  width: 10px;
}

.header-nav .nav>li .mega-menu li .name a,
.header-nav .nav>li .sub-menu li .name a {
  font-size: 15px;
  font-weight: 500;
  color: inherit;
  padding: 0;
}

.header-nav .nav>li .mega-menu li>.sub-menu,
.header-nav .nav>li .sub-menu li>.sub-menu {
  left: 220px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu li>.sub-menu,
  .header-nav .nav>li .sub-menu li>.sub-menu {
    display: none;
  }
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li .mega-menu .mega-menu,
  .header-nav .nav>li .mega-menu .sub-menu,
  .header-nav .nav>li .sub-menu .mega-menu,
  .header-nav .nav>li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }

  .header-nav .nav>li .mega-menu .mega-menu li a:after,
  .header-nav .nav>li .mega-menu .sub-menu li a:after,
  .header-nav .nav>li .sub-menu .mega-menu li a:after,
  .header-nav .nav>li .sub-menu .sub-menu li a:after {
    width: 5px;
    content: none;
    height: 5px;
    background: #262F5A !important;
    border-radius: 4px;
    top: 13px;
  }
}

.header-nav .nav>li .sub-menu li>a>i {
  color: inherit;
  display: block;
  float: right;
  font-size: 18px;
  opacity: 1;
  margin-top: 0px;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .sub-menu li>a>i {
    display: none;
  }
}

.header-nav .nav>li .mega-menu {
  right: 0px;
  width: 100%;
  z-index: 9;
  padding: 50px;
}

.header-nav .nav>li .mega-menu>li {
  display: block;
  padding: 15px 0 15px;
  position: relative;
  vertical-align: top;
  width: 25%;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .mega-menu>li {
    width: 100%;
    padding: 0;
  }
}

.header-nav .nav>li .mega-menu>li>a {
  color: var(--title);
  display: block;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 15px;
  font-family: var(--font-family-title);
}

.header-nav .nav>li .mega-menu>li>a>i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .mega-menu>li>a {
    display: none;
  }
}

.header-nav .nav>li .mega-menu>li:last-child:after {
  display: none;
}

.header-nav .nav>li .mega-menu>li:hover>a {
  background-color: transparent;
}

.header-nav .nav>li .mega-menu>li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

@media only screen and (max-width: 991px) {
  .header-nav .nav>li .mega-menu {
    display: none;
    padding: 20px 0;
  }
}

.header-nav .nav>li.active>a {
  color: var(--primary);
}

.header-nav .nav>li:hover>a {
  color: var(--primary);
}

.header-nav .nav>li:hover>.mega-menu,
.header-nav .nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  z-index: 11;
  pointer-events: unset;
}

@media only screen and (max-width: 991px) {

  .header-nav .nav>li:hover>.mega-menu,
  .header-nav .nav>li:hover>.sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}

.header-nav .nav>li:hover>.mega-menu li:hover>.sub-menu,
.header-nav .nav>li:hover>.sub-menu li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
}

.header-nav .nav>li.open .mega-menu,
.header-nav .nav>li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.header-nav .nav>li.open .open .mega-menu,
.header-nav .nav>li.open .open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

@media only screen and (max-width: 1480px) {

  .header-nav .nav>li:nth-last-child(4) .sub-menu .sub-menu,
  .header-nav .nav>li:nth-last-child(3) .sub-menu .sub-menu,
  .header-nav .nav>li:nth-last-child(2) .sub-menu .sub-menu,
  .header-nav .nav>li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav {
    float: none;
    padding: 0 20px;
  }
}

.header-nav.nav-dark .nav>li .mega-menu,
.header-nav.nav-dark .nav>li .sub-menu {
  background-color: var(--secondary);
}

.header-nav.nav-dark .nav>li .mega-menu .menu-title,
.header-nav.nav-dark .nav>li .sub-menu .menu-title {
  color: #fff;
}

.header-nav.nav-dark .nav>li .mega-menu li a,
.header-nav.nav-dark .nav>li .sub-menu li a {
  color: #BABABA;
}

.header-nav.nav-dark .nav>li .mega-menu li a:hover,
.header-nav.nav-dark .nav>li .sub-menu li a:hover {
  color: #fff;
}

.header-nav.nav-dark .nav>li .mega-menu .name a,
.header-nav.nav-dark .nav>li .sub-menu .name a {
  color: #fff;
}

.header-nav.nav-dark .line-left::after {
  background: #fff;
  opacity: 0.1;
}

.header-nav.nav-dark .month-deal {
  border-color: rgba(255, 255, 255, 0.1);
  --title: #fff;
}

.header-nav.nav-dark .month-deal .sale-countdown .date .time {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.header-nav.nav-dark .month-deal .sale-countdown .date .work-time {
  color: rgba(255, 255, 255, 0.7);
}

.header-nav .sidebar-footer {
  display: none;
}

@media only screen and (max-width: 991px) {
  .header-nav .sidebar-footer {
    display: block;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-top: auto;
    width: 100%;
    text-align: center;
  }
}

.header-nav .sidebar-footer .dz-login-register {
  text-align: center;
  padding-bottom: 10px;
}

.header-nav .dz-social-icon {
  text-align: center;
  display: none;
}

.header-nav .dz-social-icon li {
  display: inline-block;
  margin: 0 2px;
}

.header-nav .dz-social-icon a {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 38px;
  text-align: center;
  border-radius: 0;
}

@media only screen and (max-width: 991px) {
  .header-nav .dz-social-icon {
    display: block;
    padding: 20px;
  }
}

.mega-menu {
  max-width: 1200px;
  left: 80px !important;
  height: auto;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

@media only screen and (max-width: 1300px) {
  .mega-menu {
    left: 0 !important;
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .mega-menu {
    max-height: unset;
    overflow-y: unset;
  }
}

.mega-menu ul {
  margin-bottom: 15px;
}

.mega-menu .line-left {
  position: relative;
}

.mega-menu .line-left:after {
  content: "";
  position: absolute;
  height: calc(100% + 80px);
  left: 0;
  top: -50px;
  background: #eee;
  width: 1px;
}

@media only screen and (max-width: 991px) {
  .mega-menu .line-left:after {
    content: none;
  }
}

.mega-menu.shop-menu {
  max-width: 1140px;
}

@media only screen and (max-width: 1199px) {
  .mega-menu.shop-menu {
    max-width: 100%;
  }
}

.mega-menu .menu-title {
  color: var(--title);
  display: block;
  padding: 0;
  padding-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: var(--font-family-title);
}

.mega-menu.portfolio-menu {
  padding-bottom: 20px !important;
}

.mega-menu.portfolio-menu .col {
  width: 20%;
  flex: 0 0 20%;
}

@media only screen and (max-width: 991px) {
  .mega-menu.portfolio-menu .col {
    width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .mega-menu.portfolio-menu .col {
    width: 33.33%;
    flex: 0 0 33.33%;
  }
}

@media only screen and (max-width: 575px) {
  .mega-menu.portfolio-menu .col {
    width: 50%;
    flex: 0 0 50%;
  }
}

.mega-menu.portfolio-menu .portfolio-nav-link a {
  padding: 0;
  padding: 0 !important;
  text-align: center;
  margin-bottom: 30px;
}

.mega-menu.portfolio-menu .portfolio-nav-link a img {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  max-width: 100%;
  display: block;
}

.mega-menu.demo-menu a img {
  width: 100%;
  transition: all 0.4s;
}

.mega-menu.demo-menu a span {
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
}

.mega-menu.demo-menu a:hover img {
  transform: translateY(-5px);
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header-transparent.header-text-white .extra-nav .extra-cell>* {
  --title: #fff;
  --white: #fff;
}

.header-transparent.header-text-white .extra-nav.nav-dark .extra-cell>* {
  --title: #24262B;
  --white: #24262B;
}

.header-transparent.header-text-white .extra-nav .extra-cell ul li.login-link a {
  border-bottom: 1px solid #fff;
}

@media only screen and (min-width: 991px) {
  .header-transparent.header-text-white .header-nav .nav>li>a {
    --title: #fff;
    --white: #fff;
  }
}

/* .header-transparent.header-text-white .header-nav .nav > li > a:after {
  background-image: url(../images/star-white.svg);
} */
.header-transparent.header-text-white .logo-dark {
  display: none;
}

.header-transparent.header-text-white .logo-light {
  display: block;
}

.header-transparent.header-text-white .is-fixed .logo-dark {
  display: block;
}

.header-transparent.header-text-white .is-fixed .logo-light {
  display: none;
}

.header-transparent.header-text-white .is-fixed .extra-nav .extra-cell ul li.login-link a {
  border-bottom: 1px solid var(--secondary);
}

/* .header-transparent.header-text-white .is-fixed .header-nav .nav > li > a:after {
  background-image: url(../images/star.svg);
} */
.header-transparent.header-text-white .is-fixed .extra-nav .extra-cell>* {
  --title: var(--secondary);
  --white: var(--secondary);
}

.header-transparent.header-text-white .is-fixed .header-nav .nav>li>a {
  --title: var(--secondary);
  --white: var(--secondary);
}

.header-transparent.header-text-white .is-fixed .header-nav .nav>li.active>a,
.header-transparent.header-text-white .is-fixed .header-nav .nav>li:hover>a {
  color: var(--primary);
}

.header-transparent.header-text-white .header-nav .logo-dark {
  display: none;
}

@media only screen and (max-width: 991px) {
  .header-transparent.header-text-white .header-nav .logo-dark {
    display: block;
  }
}

.header-transparent .top-bar,
.header-transparent .main-bar {
  background: transparent;
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.header-transparent .container-fluid,
.header-transparent .container-sm,
.header-transparent .container-md,
.header-transparent .container-lg,
.header-transparent .container-xl {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (max-width: 1280px) {

  .header-transparent .container-fluid,
  .header-transparent .container-sm,
  .header-transparent .container-md,
  .header-transparent .container-lg,
  .header-transparent .container-xl {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1199px) {

  .header-transparent .container-fluid,
  .header-transparent .container-sm,
  .header-transparent .container-md,
  .header-transparent .container-lg,
  .header-transparent .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.header-transparent .side-menu-btn .menu-icon-in span {
  background-color: #ffffff;
}

.header-transparent .is-fixed .side-menu-btn .menu-icon-in span {
  background-color: #444444;
}

.header-transparent .navbar-toggler span {
  background: #000000;
}

.header-transparent .is-fixed .nav>li>a {
  color: #444444;
}

.header-transparent .is-fixed .extra-nav .extra-cell .login-link {
  color: var(--title);
}

.header-transparent .is-fixed .extra-nav .extra-cell .header-right i {
  color: var(--secondary);
}

.header-transparent .is-fixed .extra-nav .extra-cell .header-right .nav-item svg path,
.header-transparent .is-fixed .extra-nav .extra-cell .header-right .nav-item circle {
  stroke: var(--secondary);
}

.header-transparent .is-fixed .extra-nav .extra-cell .header-right .nav-item .cart-btn svg path {
  stroke: unset;
  fill: var(--secondary);
}

.header-transparent .top-bar {
  border-color: #ffffff;
}

.header-transparent .top-bar .dz-topbar-left li,
.header-transparent .top-bar .dz-topbar-left i,
.header-transparent .top-bar .dz-topbar-right li,
.header-transparent .top-bar .dz-topbar-right i {
  color: #ffffff;
}

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
    left: -100%;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }

  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }

  .mo-left .header-nav.show {
    left: 0;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
    width: 100%;
  }

  .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: auto !important;
    max-width: 100%;
    padding: 30px 20px;
    width: 100% !important;
  }

  .mo-left .header-nav .logo-header img {
    max-width: unset;
    width: 180px;
    height: auto;
    vertical-align: middle;
  }

  .mo-left .header-nav li.open a {
    position: relative;
  }

  .mo-left .navbar-toggler.open {
    z-index: 999;
  }

  .mo-left .navbar-toggler.open span {
    background: #fff;
  }
}

.text-black .nav>li>a {
  color: #444444;
}

.text-black .navbar-toggler span {
  background: #444444;
}

.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

.shop-cart {
  display: inline-block;
}

.shop-cart .cart-btn:before,
.shop-cart .cart-btn:after {
  position: absolute;
  border: 2px solid var(--title);
  display: block;
}

.shop-cart .cart-btn:before {
  width: 20px;
  height: 8px;
  left: 7px;
  top: 0;
  border-width: 2px 2px 0px 2px !important;
  border-radius: 5px 5px 0 0;
}

@media only screen and (max-width: 575px) {
  .shop-cart .cart-btn:before {
    width: 15px;
    height: 8px;
    left: 5px;
  }
}

.shop-cart .cart-btn:after {
  width: 100%;
  height: 30px;
  border-radius: 0 0 6px 6px;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 575px) {
  .shop-cart .cart-btn:after {
    height: 24px;
  }
}

.browse-category-menu .category-btn {
  background-color: #f5f5f5;
  height: 80px;
  padding: 10px 15px 10px 25px;
  display: flex;
  align-items: center;
  min-width: 300px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-size: 14px;
}

.browse-category-menu .category-btn .category-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.browse-category-menu .category-btn .category-menu span {
  background-color: #fff;
  height: 1px;
  display: block;
  width: 20px;
}

.browse-category-menu .category-btn .toggle-arrow {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: rotate(0deg);
  display: flex;
  align-items: center;
}

.browse-category-menu .category-btn .toggle-arrow i {
  font-size: 22px;
}

.browse-category-menu .category-btn.active .toggle-arrow {
  transform: rotate(-180deg);
}

.browse-category-menu .category-btn.active:after {
  opacity: 1;
  visibility: visible;
}

.browse-category-menu .category-btn:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(36, 38, 43, 0.6);
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  visibility: hidden;
}

.browse-category-menu .category-menu-items {
  position: absolute;
  top: 60px;
  left: 15px;
  right: 15px;
  width: 300px;
  background-color: #fff;
  border-radius: 10px;
}

@media only screen and (max-width: 1199px) {
  .browse-category-menu .category-menu-items {
    width: 280px;
  }
}

.browse-category-menu .category-menu-items .navbar-nav {
  display: block;
  padding: 25px 0;
  float: none;
}

.browse-category-menu .category-menu-items .navbar-nav .cate-drop>a {
  position: relative;
}

.browse-category-menu .category-menu-items .navbar-nav .cate-drop>a:after {
  content: "";
  position: absolute;
  top: 40px;
  right: 5px;
  opacity: 0;
  background-color: #F6F6F6;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  z-index: 99;
  transition: all 0.5s;
}

.browse-category-menu .category-menu-items .navbar-nav .cate-drop:hover>a:after {
  opacity: 1;
  right: -5px;
}

.browse-category-menu .category-menu-items .navbar-nav .menu-icon {
  position: absolute;
  top: 50%;
  right: 25px;
  width: 25px;
  height: 25px;
  font-size: 18px;
  background-color: var(--secondary);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.browse-category-menu .category-menu-items .navbar-nav .menu-icon i {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: #fff;
}

.browse-category-menu .category-menu-items .navbar-nav .badge {
  font-weight: 500;
  min-width: auto;
  min-height: auto;
  font-size: 11px;
  padding: 4px 5px;
  text-transform: capitalize;
  margin-left: 4px;
}

.browse-category-menu .category-menu-items .navbar-nav>li {
  display: block;
}

.browse-category-menu .category-menu-items .navbar-nav>li>a {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  padding: 10px 25px 10px 25px !important;
  color: var(--title) !important;
  text-transform: capitalize;
}

.browse-category-menu .category-menu-items .navbar-nav>li>a>svg {
  margin-right: 15px;
}

.browse-category-menu .category-menu-items .navbar-nav>li>a>i {
  margin-right: 15px;
  font-size: 18px;
  color: var(--primary);
}

.browse-category-menu .category-menu-items .navbar-nav>li .mega-menu {
  width: 990px;
  top: 0;
  min-height: 100%;
}

@media only screen and (max-width: 1480px) {
  .browse-category-menu .category-menu-items .navbar-nav>li .mega-menu {
    width: 870px;
  }
}

.browse-category-menu .category-menu-items .navbar-nav>li .mega-menu,
.browse-category-menu .category-menu-items .navbar-nav>li .sub-menu {
  box-shadow: none;
  border-radius: 0;
  left: 100% !important;
  top: 0;
  border-radius: 10px;
}

.browse-category-menu .category-menu-items .navbar-nav>li .mega-menu li a,
.browse-category-menu .category-menu-items .navbar-nav>li .sub-menu li a {
  color: #5E626F;
}

.browse-category-menu .category-menu-items .navbar-nav>li .mega-menu li a:hover,
.browse-category-menu .category-menu-items .navbar-nav>li .sub-menu li a:hover {
  color: var(--primary);
}

.browse-category-menu .category-menu-items .navbar-nav>li .mega-menu>li>a {
  color: var(--title);
}

.browse-category-menu .category-menu-items .navbar-nav>li:hover>a {
  color: var(--primary) !important;
}

.browse-category-menu .category-menu-items .navbar-nav>li:hover>a .menu-icon svg path {
  stroke: var(--primary);
}

.browse-category-menu .category-menu-items .navbar-nav .menu-items {
  margin-top: -45px;
  background: #f6f6f6;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.browse-category-menu .category-menu-items .navbar-nav .menu-items i {
  font-size: 22px;
}

.browse-category-menu .category-menu-items .navbar-nav .menu-items.active {
  margin-top: 0;
}

.browse-category-menu .category-menu-items .navbar-nav .menu-items.active .menu-icon i,
.browse-category-menu .category-menu-items .navbar-nav .menu-items.active .menu-icon svg {
  transform: rotate(90deg);
}

.main-bar.dark {
  background-color: #000;
}

.main-bar.dark .browse-category-menu .category-btn {
  color: #fff;
  background-color: #3E4043;
}

@media only screen and (min-width: 991px) {
  .main-bar.dark .header-nav .nav>li>a {
    color: #fff;
  }

  .main-bar.dark .header-nav .nav>li>a:after {
    color: rgba(255, 255, 255, 0.5);
  }
}

@media only screen and (max-width: 991px) {
  .main-bar.dark {
    background-color: #ffffff;
  }
}

.header-nav.w3menu .nav {
  float: right;
  padding: 0;
  font-size: 0;
}

.header-nav.w3menu .nav>li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}

.header-nav.w3menu .nav>li.has-mega-menu {
  position: inherit;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}

.header-nav.w3menu .nav>li.menu-item-has-children .sub-menu-down>a,
.header-nav.w3menu .nav>li.sub-menu-down .sub-menu-down>a {
  position: relative;
}

.header-nav.w3menu .nav>li.menu-item-has-children .sub-menu-down>a:after,
.header-nav.w3menu .nav>li.sub-menu-down .sub-menu-down>a:after {
  content: "\f054";
  display: inline-block;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  float: right;
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li.menu-item-has-children .sub-menu-down>a:after,
  .header-nav.w3menu .nav>li.sub-menu-down .sub-menu-down>a:after {
    background-color: var(--secondary);
    opacity: 0.8;
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 11px;
    margin-top: -5px;
  }
}

.header-nav.w3menu .nav>li.menu-item-has-children .sub-menu-down.open>a:after,
.header-nav.w3menu .nav>li.sub-menu-down .sub-menu-down.open>a:after {
  content: "\f078";
}

.header-nav.w3menu .nav>li.menu-item-has-children>a:after,
.header-nav.w3menu .nav>li.sub-menu-down>a:after {
  content: "";
  margin-left: 5px;
  margin-top: -5px;
  display: inline-block;
  width: 12px;
  height: 12px;
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li.menu-item-has-children>a:after,
  .header-nav.w3menu .nav>li.sub-menu-down>a:after {
    display: none;
  }
}

.header-nav.w3menu .nav>li.menu-item-has-children>a i,
.header-nav.w3menu .nav>li.sub-menu-down>a i {
  display: none;
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li.menu-item-has-children>a i,
  .header-nav.w3menu .nav>li.sub-menu-down>a i {
    display: block;
    font-size: 9px;
    margin-left: 5px;
    margin-top: 0;
    vertical-align: middle;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
  }
}

@media only screen and (max-width: 991px) and (max-width: 991px) {

  .header-nav.w3menu .nav>li.menu-item-has-children>a i,
  .header-nav.w3menu .nav>li.sub-menu-down>a i {
    background-color: var(--secondary);
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 11px;
    margin: 0;
  }

  .header-nav.w3menu .nav>li.menu-item-has-children>a i:before,
  .header-nav.w3menu .nav>li.sub-menu-down>a i:before {
    content: "\f054";
  }
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li.menu-item-has-children.open>a i:before,
  .header-nav.w3menu .nav>li.sub-menu-down.open>a i:before {
    content: "\f078";
  }
}

/* .header-nav.w3menu .nav > li > a {
  color: var(--white);
  font-size: 16px;
  padding: 28px 15px;
  font-family: var(--font-family-title);
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  position: relative;
  line-height: 24px;
} */
@media only screen and (max-width: 1199px) {
  .header-nav.w3menu .nav>li>a {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li>a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
}

.header-nav.w3menu .nav>li>a span {
  display: inline-block;
}

.header-nav.w3menu .nav>li .mega-menu,
.header-nav.w3menu .nav>li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 20px 0;
  position: absolute;
  visibility: hidden;
  z-index: 10;
  margin-top: 20px;
  text-align: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  pointer-events: none;
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li .mega-menu,
  .header-nav.w3menu .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 15px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }

  .header-nav.w3menu .nav>li .mega-menu>li>a i,
  .header-nav.w3menu .nav>li .sub-menu>li>a i {
    color: #fff !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: #eee;
  }
}

.header-nav.w3menu .nav>li .mega-menu .menu-item-has-children>a:before,
.header-nav.w3menu .nav>li .sub-menu .menu-item-has-children>a:before {
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  float: right;
  font-size: 10px;
  margin-top: 2px;
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li .mega-menu .menu-item-has-children.open>a:before,
  .header-nav.w3menu .nav>li .sub-menu .menu-item-has-children.open>a:before {
    content: "\f054";
  }
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li .mega-menu,
  .header-nav.w3menu .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 15px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
    opacity: 1;
  }

  .header-nav.w3menu .nav>li .mega-menu>li>a i,
  .header-nav.w3menu .nav>li .sub-menu>li>a i {
    display: none;
  }

  .header-nav.w3menu .nav>li .mega-menu .has-child>a:before,
  .header-nav.w3menu .nav>li .mega-menu .menu-item-has-children>a:before,
  .header-nav.w3menu .nav>li .mega-menu>li>a i,
  .header-nav.w3menu .nav>li .sub-menu .has-child>a:before,
  .header-nav.w3menu .nav>li .sub-menu .menu-item-has-children>a:before,
  .header-nav.w3menu .nav>li .sub-menu>li>a i {
    color: #fff !important;
    font-size: 14px;
    content: "\f054";
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    font-family: "Font Awesome 5 Free";
    z-index: 3;
    margin: 0 !important;
    background: var(--primary);
    border-radius: var(--border-radius-base);
    font-weight: 900;
    float: right;
  }

  .header-nav.w3menu .nav>li .mega-menu .has-child.open>a:before .menu-item-has-children.open>a:before,
  .header-nav.w3menu .nav>li .sub-menu .has-child.open>a:before .menu-item-has-children.open>a:before {
    content: "\f078";
    background: #333;
  }
}

.header-nav.w3menu .nav>li .mega-menu li,
.header-nav.w3menu .nav>li .sub-menu li {
  position: relative;
}

.header-nav.w3menu .nav>li .mega-menu li a,
.header-nav.w3menu .nav>li .sub-menu li a {
  color: #5E626F;
  display: block;
  font-size: 14px;
  padding: 5px 0;
  line-height: 20px;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 400;
}

.header-nav.w3menu .nav>li .mega-menu li a.menu-title,
.header-nav.w3menu .nav>li .sub-menu li a.menu-title {
  color: var(--title);
  display: block;
  padding: 0;
  padding-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: var(--font-family-title);
}

.header-nav.w3menu .nav>li .mega-menu li:hover>a,
.header-nav.w3menu .nav>li .sub-menu li:hover>a {
  color: var(--primary);
}

.header-nav.w3menu .nav>li .mega-menu li .name a,
.header-nav.w3menu .nav>li .sub-menu li .name a {
  font-size: 15px;
  font-weight: 500;
  color: inherit;
  padding: 0;
}

.header-nav.w3menu .nav>li .mega-menu li>.sub-menu,
.header-nav.w3menu .nav>li .sub-menu li>.sub-menu {
  left: 220px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li .mega-menu li>.sub-menu,
  .header-nav.w3menu .nav>li .sub-menu li>.sub-menu {
    display: none;
  }
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li .mega-menu .mega-menu,
  .header-nav.w3menu .nav>li .mega-menu .sub-menu,
  .header-nav.w3menu .nav>li .sub-menu .mega-menu,
  .header-nav.w3menu .nav>li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }
}

.header-nav.w3menu .nav>li .sub-menu {
  width: 220px;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li .sub-menu {
    width: 100%;
    padding: 0;
  }
}

.header-nav.w3menu .nav>li .sub-menu li>a {
  padding: 5px 30px;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li .sub-menu li>a {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
}

.header-nav.w3menu .nav>li .sub-menu li>a>i {
  color: inherit;
  display: block;
  float: right;
  font-size: 18px;
  opacity: 1;
  margin-top: 0px;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li .sub-menu li>a>i {
    display: none;
  }
}

.header-nav.w3menu .nav>li .mega-menu {
  right: 0px;
  z-index: 9;
  padding: 50px;
}

.header-nav.w3menu .nav>li .mega-menu>li {
  display: block;
  padding: 15px 0 15px;
  position: relative;
  vertical-align: top;
  width: 25%;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li .mega-menu>li {
    width: 100%;
    padding: 0;
  }
}

.header-nav.w3menu .nav>li .mega-menu>li>a {
  color: var(--title);
  display: block;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 15px;
  font-family: var(--font-family-title);
}

.header-nav.w3menu .nav>li .mega-menu>li>a>i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li .mega-menu>li>a {
    display: none;
  }
}

.header-nav.w3menu .nav>li .mega-menu>li:last-child:after {
  display: none;
}

.header-nav.w3menu .nav>li .mega-menu>li:hover>a {
  background-color: transparent;
}

.header-nav.w3menu .nav>li .mega-menu>li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}

@media only screen and (max-width: 1199px) {
  .header-nav.w3menu .nav>li .mega-menu {
    padding: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li .mega-menu {
    display: none;
    padding: 20px 0;
  }
}

.header-nav.w3menu .nav>li.active>a {
  color: var(--primary);
}

.header-nav.w3menu .nav>li.active>a,
.header-nav.w3menu .nav>li:hover>a {
  color: var(--primary);
}

.header-nav.w3menu .nav>li.active>.mega-menu,
.header-nav.w3menu .nav>li.active>.sub-menu,
.header-nav.w3menu .nav>li:hover>.mega-menu,
.header-nav.w3menu .nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  z-index: 11;
  pointer-events: unset;
}

@media only screen and (max-width: 991px) {

  .header-nav.w3menu .nav>li.active>.mega-menu,
  .header-nav.w3menu .nav>li.active>.sub-menu,
  .header-nav.w3menu .nav>li:hover>.mega-menu,
  .header-nav.w3menu .nav>li:hover>.sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}

.header-nav.w3menu .nav>li.active>.mega-menu li.active>.sub-menu,
.header-nav.w3menu .nav>li.active>.mega-menu li:hover>.sub-menu,
.header-nav.w3menu .nav>li.active>.sub-menu li.active>.sub-menu,
.header-nav.w3menu .nav>li.active>.sub-menu li:hover>.sub-menu,
.header-nav.w3menu .nav>li:hover>.mega-menu li.active>.sub-menu,
.header-nav.w3menu .nav>li:hover>.mega-menu li:hover>.sub-menu,
.header-nav.w3menu .nav>li:hover>.sub-menu li.active>.sub-menu,
.header-nav.w3menu .nav>li:hover>.sub-menu li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
  pointer-events: unset;
}

.header-nav.w3menu .nav>li.open .mega-menu,
.header-nav.w3menu .nav>li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.header-nav.w3menu .nav>li.open .open>.mega-menu,
.header-nav.w3menu .nav>li.open .open>.sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

@media only screen and (max-width: 1480px) {

  .header-nav.w3menu .nav>li:nth-last-child(4) .sub-menu .sub-menu,
  .header-nav.w3menu .nav>li:nth-last-child(3) .sub-menu .sub-menu,
  .header-nav.w3menu .nav>li:nth-last-child(2) .sub-menu .sub-menu,
  .header-nav.w3menu .nav>li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav {
    float: none;
    padding: 0 20px;
  }
}

.w3menu .mega-menu {
  max-width: 1200px;
  width: 1200px;
  left: 80px !important;
  height: auto;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

@media only screen and (max-width: 1300px) {
  .w3menu .mega-menu {
    left: 0 !important;
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .w3menu .mega-menu {
    max-height: unset;
    overflow-y: unset;
  }
}

.w3menu .mega-menu ul {
  margin-bottom: 15px;
}

.w3menu .mega-menu>ul {
  display: flex;
  margin: 0 -15px;
}

@media only screen and (max-width: 991px) {
  .w3menu .mega-menu>ul {
    flex-wrap: wrap;
  }
}

.w3menu .mega-menu>ul>li {
  padding: 0 15px;
  flex: 1 0 0%;
}

@media only screen and (max-width: 991px) {
  .w3menu .mega-menu>ul>li {
    flex: 33.33% 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .w3menu .mega-menu>ul>li {
    width: 50%;
    flex: 0 0 50%;
  }
}

.w3menu .mega-menu .line-left {
  position: relative;
}

.w3menu .mega-menu .line-left:after {
  content: "";
  position: absolute;
  height: calc(100% + 70px);
  left: 0;
  top: -50px;
  background: #eee;
  width: 1px;
}

@media only screen and (max-width: 991px) {
  .w3menu .mega-menu .line-left:after {
    content: none;
  }
}

.w3menu .mega-menu.shop-menu {
  max-width: 1140px;
}

@media only screen and (max-width: 1199px) {
  .w3menu .mega-menu.shop-menu {
    max-width: 100%;
  }
}

.w3menu .mega-menu .portfolio-nav-link {
  display: flex;
  flex-wrap: wrap;
}

.w3menu .mega-menu .portfolio-nav-link>li {
  width: 20%;
  padding: 0 15px;
}

@media only screen and (max-width: 991px) {
  .w3menu .mega-menu .portfolio-nav-link>li {
    width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 767px) {
  .w3menu .mega-menu .portfolio-nav-link>li {
    width: 33.33%;
    flex: 0 0 33.33%;
  }
}

@media only screen and (max-width: 575px) {
  .w3menu .mega-menu .portfolio-nav-link>li {
    width: 50%;
    flex: 0 0 50%;
  }
}

.w3menu .mega-menu .portfolio-nav-link a {
  padding: 0;
  padding: 0 !important;
  text-align: center;
  margin-bottom: 20px;
}

.w3menu .mega-menu .portfolio-nav-link a img {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  max-width: 100%;
  display: block;
}

.justify-content-end.header-nav .nav>li>.mega-menu {
  right: 80px;
  left: auto !important;
}

@media only screen and (max-width: 1199px) {
  .justify-content-end.header-nav .nav>li>.mega-menu {
    right: 0;
  }
}

.header-nav.w3menu .nav>li.menu-left {
  position: relative;
}

.header-nav.w3menu .nav>li.menu-left>.sub-menu {
  left: 0 !important;
}

.header-nav.w3menu .nav>li.menu-right {
  position: relative;
}

.header-nav.w3menu .nav>li.menu-right>.sub-menu {
  right: 0;
  left: auto !important;
}

.header-nav.w3menu .nav>li.menu-right>.sub-menu .sub-menu {
  left: -220px;
}

.header-nav.w3menu .nav>li.menu-center {
  position: relative;
}

.header-nav.w3menu .nav>li.menu-center>.sub-menu {
  left: 50% !important;
  transform: translateX(-50%);
}

.header-nav.w3menu .nav>li.has-mega-menu.full-width .mega-menu {
  width: calc(100% - 160px);
  max-width: calc(100% - 160px);
}

.header-nav.w3menu .nav>li.has-mega-menu.wide-width .mega-menu {
  width: 100%;
  max-width: 100%;
  left: 0 !important;
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (max-width: 1280px) {
  .header-nav.w3menu .nav>li.has-mega-menu.wide-width .mega-menu {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li.has-mega-menu.wide-width .mega-menu {
    padding-left: 0;
    padding-right: 0;
  }
}

.header-nav.w3menu .nav>li.has-mega-menu.auto-width .mega-menu {
  width: fit-content;
  max-width: fit-content;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li.has-mega-menu.auto-width .mega-menu {
    width: 100%;
    max-width: 100%;
  }
}

.header-nav.w3menu .nav>li.has-mega-menu.auto-width .mega-menu>ul>li {
  width: 250px;
}

.header-nav.w3menu .nav>li.has-mega-menu.menu-left {
  position: relative;
}

.header-nav.w3menu .nav>li.has-mega-menu.menu-left .mega-menu {
  left: 0 !important;
}

.header-nav.w3menu .nav>li.has-mega-menu.menu-right {
  position: relative;
}

.header-nav.w3menu .nav>li.has-mega-menu.menu-right .mega-menu {
  right: 0;
  left: auto !important;
}

.header-nav.w3menu .nav>li.has-mega-menu.menu-center {
  position: relative;
}

.header-nav.w3menu .nav>li.has-mega-menu.menu-center .mega-menu {
  left: 50% !important;
  transform: translateX(-50%);
}

.header-nav.w3menu .nav>li.has-mega-menu .menu-title {
  color: var(--title);
  display: block;
  padding: 0;
  padding-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: var(--font-family-title);
}

.header-nav.w3menu .nav>li.has-mega-menu .demo-menu a img {
  width: 100%;
  transition: all 0.4s;
}

.header-nav.w3menu .nav>li.has-mega-menu .demo-menu a span {
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
}

.header-nav.w3menu .nav>li.has-mega-menu .demo-menu a:hover img {
  transform: translateY(-5px);
}

.header-nav.w3menu .nav>li.has-mega-menu .shop-menu>ul {
  flex-wrap: wrap;
}

.header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-left {
  width: 66%;
  flex: 0 0 66%;
}

@media only screen and (max-width: 767px) {
  .header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-left {
    width: 100%;
    flex: 0 0 100%;
  }
}

.header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-left>ul {
  display: flex;
  flex-wrap: wrap;
}

.header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-left>ul>li {
  width: 33.33%;
}

@media only screen and (max-width: 575px) {
  .header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-left>ul>li {
    width: 50%;
    flex: 0 0 50%;
  }
}

.header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-left>ul .month-deal {
  width: 100%;
}

.header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-right {
  width: 34%;
  flex: 0 0 34%;
}

@media only screen and (max-width: 767px) {
  .header-nav.w3menu .nav>li.has-mega-menu .shop-menu .side-right {
    display: none;
  }
}

.header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu>ul {
  flex-wrap: wrap;
  margin: 0 -15px;
}

.header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left {
  width: 80%;
  flex: 0 0 80%;
  padding-right: 50px;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left {
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left {
    width: 100%;
    flex: 0 0 100%;
  }
}

.header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left>ul {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}

.header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left>ul>li {
  width: 20%;
}

@media only screen and (max-width: 767px) {
  .header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left>ul>li {
    width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 575px) {
  .header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left>ul>li {
    width: 50%;
    flex: 0 0 50%;
  }
}

.header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-left>ul .month-deal {
  width: 100%;
}

.header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-right {
  width: 20%;
  flex: 0 0 20%;
  padding-left: 30px;
}

@media only screen and (max-width: 991px) {
  .header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-right {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header-nav.w3menu .nav>li.has-mega-menu .portfolio-menu .side-right {
    width: 100%;
    flex: 0 0 100%;
  }
}

.w3menulink a {
  --borderWidth: 2px;
  position: relative !important;
  border-radius: 50px;
  text-align: left;
  background: #fff;
  color: #000 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 10px 25px !important;
  margin-top: 10px;
}

.w3menulink a:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2) !important;
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: 50px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.w3menulink a:before {
  content: "\f054";
  display: inline-block;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  float: right;
}

.w3menulink a:hover {
  color: var(--primary) !important;
}

.w3menulink a:hover:before {
  -webkit-animation: toTopFromBottom 0.8s infinite;
  -moz-animation: toTopFromBottom 0.8s infinite;
  animation: toTopFromBottom 0.8s infinite;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes toTopFromBottom {
  49% {
    transform: translateX(100%);
  }

  50% {
    opacity: 0;
    transform: translateX(-100%);
  }

  51% {
    opacity: 1;
  }
}

.site-header.style-1 .header-nav .search-input {
  display: none;
}

.site-header.style-1 .main-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.site-header.style-1 .header-info-bar+.main-bar-wraper .main-bar .logo-header {
  display: none;
}

@media only screen and (max-width: 991px) {
  .site-header.style-1 .header-nav .search-input {
    display: flex;
    margin: 0 20px;
    margin-bottom: 15px;
    position: relative;
  }

  .site-header.style-1 .header-info-bar+.main-bar-wraper .main-bar .logo-header {
    display: flex;
  }
}

.site-header.style-1 .is-fixed .extra-nav .extra-cell>* {
  --title: var(--secondary);
  --white: var(--secondary);
}

.site-header.style-2 .header-info-bar {
  background-color: var(--light-dark);
}

.site-header.style-2 .header-info-bar .logo-header,
.site-header.style-2 .header-info-bar .extra-nav,
.site-header.style-2 .header-info-bar .header-item-search {
  height: 90px;
}

.site-header.style-2 .header-info-bar .logo-header img {
  height: 90px;
  max-width: 190px;
}

.site-header.style-2 .dz-search-area .search-input {
  border-bottom: 2px solid var(--secondary);
  border-radius: 0;
  background: var(--light);
}

.site-header.style-2 .dz-search-area .search-input .btn,
.site-header.style-2 .dz-search-area .search-input .wp-block-button__link,
.site-header.style-2 .dz-search-area .search-input .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .site-header.style-2 .dz-search-area .search-input a,
.site-header.style-2 .dz-search-area .search-input .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .site-header.style-2 .dz-search-area .search-input a,
.site-header.style-2 .dz-search-area .search-input .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .site-header.style-2 .dz-search-area .search-input input,
.site-header.style-2 .dz-search-area .search-input .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .site-header.style-2 .dz-search-area .search-input .button,
.site-header.style-2 .dz-search-area .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .site-header.style-2 .dz-search-area .search-input a.checkout-button,
.site-header.style-2 .dz-search-area .search-input .woocommerce #respond input#submit,
.woocommerce #respond .site-header.style-2 .dz-search-area .search-input input#submit,
.site-header.style-2 .dz-search-area .search-input .woocommerce a.button,
.woocommerce .site-header.style-2 .dz-search-area .search-input a.button,
.site-header.style-2 .dz-search-area .search-input .woocommerce button.button,
.woocommerce .site-header.style-2 .dz-search-area .search-input button.button,
.site-header.style-2 .dz-search-area .search-input .woocommerce input.button,
.woocommerce .site-header.style-2 .dz-search-area .search-input input.button,
.site-header.style-2 .dz-search-area .search-input .woocommerce #place_order,
.woocommerce .site-header.style-2 .dz-search-area .search-input #place_order {
  right: 0px;
}

.site-header.style-2 .search-input {
  background: #fff;
  border-radius: 10px;
  padding: 5px 0px;
  border: 0;
}

.site-header.style-2 .is-fixed .extra-nav .extra-cell>* {
  --title: var(--secondary);
  --white: var(--secondary);
}

.site-header.style-2 .mega-menu {
  left: 0 !important;
}

.site-header.style-2 .main-bar {
  box-shadow: none;
  padding: 5px 0;
  border: 0;
}

@media only screen and (min-width: 991px) {
  .site-header.style-2 .main-bar .logo-header {
    display: none;
  }
}

.site-header.style-2 .main-bar .browse-category-menu {
  margin-right: 10px;
}

.site-header.style-2 .main-bar .browse-category-menu .category-btn {
  height: 50px;
  background-color: var(--light-dark);
  border-radius: 10px;
}

.site-header.style-2 .main-bar .browse-category-menu .category-btn .category-menu span {
  background: var(--secondary);
}

.site-header.style-2 .main-bar .browse-category-menu .category-btn .category-btn-title {
  color: var(--title);
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .site-header.style-2 .main-bar .browse-category-menu {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
  .site-header.style-2 .main-bar .header-nav .nav>li>a {
    padding: 18px 8px;
  }
}

@media only screen and (min-width: 1200px) {
  .site-header.style-2 .main-bar .header-nav .nav>li>a {
    padding: 18px 12px;
  }
}

@media only screen and (min-width: 991px) {
  .site-header.style-2 .main-bar .extra-nav {
    height: 60px;
  }
}

.site-header.style-2 .extra-nav .info-box .nav-link {
  display: flex;
  gap: 10px;
  align-items: center;
}

.site-header.style-2 .extra-nav .info-box .dz-icon {
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-header.style-2 .extra-nav .info-box .dz-icon i {
  font-size: 42px;
  color: #ABABAB;
}

.site-header.style-2 .extra-nav .info-box span {
  font-size: 13px;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .site-header .main-bar .browse-category-menu .category-btn {
    min-width: 280px;
  }

  .site-header .main-bar .extra-nav .extra-cell .header-right {
    margin-left: 10px;
  }
}

.header-info-bar {
  position: relative;
  flex-wrap: nowrap;
  justify-content: flex-start;
  z-index: 99;
}

@media only screen and (max-width: 991px) {
  .header-info-bar {
    display: none;
  }
}

.header-info-bar .header-search-nav {
  flex-basis: auto;
  display: flex !important;
}

.header-item-search {
  width: 100%;
  margin-left: 120px;
  height: 80px;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .header-item-search {
    margin-left: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .header-item-search {
    display: block;
    margin-left: 0;
    padding: 0px 20px;
    height: auto;
  }

  .header-item-search .navbar-nav {
    padding: 0px;
  }
}

.header-item-search .main-menu {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}

@media only screen and (max-width: 991px) {
  .header-item-search .main-menu {
    width: 100%;
  }
}

.header-item-search .main-menu .menu-item {
  border: 0;
  padding: 15px 20px;
  background-color: #FFFAF3;
  color: var(--title);
  min-width: 135px;
  font-size: 14px;
  height: 48px;
  font-weight: 600;
  position: relative;
  font-family: var(--font-family-title);
  display: flex;
  align-items: center;
}

.header-item-search .main-menu .menu-item i {
  font-size: 12px;
  position: absolute;
  right: 20px;
  color: var(--title);
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1199px) {
  .header-item-search .main-menu .menu-item {
    padding: 15px 20px;
    min-width: 135px;
  }

  .header-item-search .main-menu .menu-item i {
    right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .header-item-search .main-menu .menu-item {
    display: none;
  }
}

.search-input {
  margin-bottom: 0;
  align-items: center;
}

.search-input .form-control,
.search-input .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .search-input input[type=text],
.search-input .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .search-input textarea,
.search-input .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .search-input input[type=email],
.search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .search-input .input-text,
.search-input .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .search-input input,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input textarea,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input select,
.search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .search-input input,
.search-input .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .search-input input[type=text],
.search-input .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .search-input input[type=email],
.search-input .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .search-input input[type=password],
.search-input .login input[type=text],
.login .search-input input[type=text],
.search-input .login input[type=password],
.login .search-input input[type=password],
.search-input .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .search-input input[type=text],
.search-input .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .search-input input[type=text],
.search-input .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .search-input input[type=email],
.search-input .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .search-input input[type=tel],
.search-input .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .search-input .select2-choice,
.search-input .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .search-input textarea,
.search-input .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .search-input input.input-text,
.search-input .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .search-input .select2-selection,
.search-input .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .search-input .select2-choice {
  border: 0;
  margin-left: 0px !important;
  border-radius: 0 !important;
  height: 45px;
}

.search-input .bootstrap-select.default-select {
  width: auto !important;
}

.search-input .bootstrap-select.default-select:after {
  position: absolute;
  content: "";
  background-color: rgba(187, 187, 187, 0.68);
  width: 1px;
  height: 22px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.search-input .bootstrap-select.default-select .default-select {
  display: none !important;
}

.search-input .bootstrap-select.default-select .dropdown-toggle {
  height: 45px;
  min-width: 185px;
  position: static;
  background-color: transparent;
  padding: 0px 20px;
  border-radius: 0;
  line-height: 48px;
  border: 0;
  outline: 0 !important;
  border-radius: 0;
}

.search-input .bootstrap-select.default-select .dropdown-toggle:after {
  border: 0;
  content: "\f078";
  font-size: 10px;
  margin-left: 5px;
  margin-top: 0;
  opacity: 0.5;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

.search-input .bootstrap-select.default-select .dropdown-menu {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  min-width: 185px !important;
  max-height: 350px !important;
}

.search-input .btn,
.search-input .wp-block-button__link,
.search-input .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .search-input a,
.search-input .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .search-input a,
.search-input .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .search-input input,
.search-input .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .search-input .button,
.search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .search-input a.checkout-button,
.search-input .woocommerce #respond input#submit,
.woocommerce #respond .search-input input#submit,
.search-input .woocommerce a.button,
.woocommerce .search-input a.button,
.search-input .woocommerce button.button,
.woocommerce .search-input button.button,
.search-input .woocommerce input.button,
.woocommerce .search-input input.button,
.search-input .woocommerce #place_order,
.woocommerce .search-input #place_order {
  background-color: transparent;
  position: absolute;
  right: 0;
  z-index: 9;
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-family-base);
  height: 48px;
  width: 48px;
  padding: 0;
  border: 0;
  text-align: center;
  line-height: 48px;
  justify-content: center;
}

.search-input .btn i,
.search-input .wp-block-button__link i,
.search-input .woocommerce .widget_shopping_cart .buttons a i,
.woocommerce .widget_shopping_cart .buttons .search-input a i,
.search-input .woocommerce.widget_shopping_cart .buttons a i,
.woocommerce.widget_shopping_cart .buttons .search-input a i,
.search-input .woocommerce #review_form #respond .form-submit input i,
.woocommerce #review_form #respond .form-submit .search-input input i,
.search-input .woocommerce-cart-form .table tr .button i,
.woocommerce-cart-form .table tr .search-input .button i,
.search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
.woocommerce-cart .wc-proceed-to-checkout .search-input a.checkout-button i,
.search-input .woocommerce #respond input#submit i,
.woocommerce #respond .search-input input#submit i,
.search-input .woocommerce a.button i,
.woocommerce .search-input a.button i,
.search-input .woocommerce button.button i,
.woocommerce .search-input button.button i,
.search-input .woocommerce input.button i,
.woocommerce .search-input input.button i,
.search-input .woocommerce #place_order i,
.woocommerce .search-input #place_order i {
  font-size: 22px;
  color: var(--primary);
}

@media only screen and (max-width: 1199px) {

  .search-input .form-control,
  .search-input .woocommerce #review_form #respond input[type=text],
  .woocommerce #review_form #respond .search-input input[type=text],
  .search-input .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .search-input textarea,
  .search-input .woocommerce #review_form #respond input[type=email],
  .woocommerce #review_form #respond .search-input input[type=email],
  .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .search-input .input-text,
  .search-input .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .search-input input,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input textarea,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input select,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input input,
  .search-input .woocommerce-EditAccountForm.edit-account input[type=text],
  .woocommerce-EditAccountForm.edit-account .search-input input[type=text],
  .search-input .woocommerce-EditAccountForm.edit-account input[type=email],
  .woocommerce-EditAccountForm.edit-account .search-input input[type=email],
  .search-input .woocommerce-EditAccountForm.edit-account input[type=password],
  .woocommerce-EditAccountForm.edit-account .search-input input[type=password],
  .search-input .login input[type=text],
  .login .search-input input[type=text],
  .search-input .login input[type=password],
  .login .search-input input[type=password],
  .search-input .woocommerce-ResetPassword.lost_reset_password input[type=text],
  .woocommerce-ResetPassword.lost_reset_password .search-input input[type=text],
  .search-input .checkout.woocommerce-checkout input[type=text],
  .checkout.woocommerce-checkout .search-input input[type=text],
  .search-input .checkout.woocommerce-checkout input[type=email],
  .checkout.woocommerce-checkout .search-input input[type=email],
  .search-input .checkout.woocommerce-checkout input[type=tel],
  .checkout.woocommerce-checkout .search-input input[type=tel],
  .search-input .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .search-input .select2-choice,
  .search-input .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .search-input textarea,
  .search-input .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .search-input input.input-text,
  .search-input .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .search-input .select2-selection,
  .search-input .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .search-input .select2-choice {
    padding: 15px 20px;
  }

  .search-input .btn i,
  .search-input .wp-block-button__link i,
  .search-input .woocommerce .widget_shopping_cart .buttons a i,
  .woocommerce .widget_shopping_cart .buttons .search-input a i,
  .search-input .woocommerce.widget_shopping_cart .buttons a i,
  .woocommerce.widget_shopping_cart .buttons .search-input a i,
  .search-input .woocommerce #review_form #respond .form-submit input i,
  .woocommerce #review_form #respond .form-submit .search-input input i,
  .search-input .woocommerce-cart-form .table tr .button i,
  .woocommerce-cart-form .table tr .search-input .button i,
  .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
  .woocommerce-cart .wc-proceed-to-checkout .search-input a.checkout-button i,
  .search-input .woocommerce #respond input#submit i,
  .woocommerce #respond .search-input input#submit i,
  .search-input .woocommerce a.button i,
  .woocommerce .search-input a.button i,
  .search-input .woocommerce button.button i,
  .woocommerce .search-input button.button i,
  .search-input .woocommerce input.button i,
  .woocommerce .search-input input.button i,
  .search-input .woocommerce #place_order i,
  .woocommerce .search-input #place_order i {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {

  .search-input .form-control,
  .search-input .woocommerce #review_form #respond input[type=text],
  .woocommerce #review_form #respond .search-input input[type=text],
  .search-input .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .search-input textarea,
  .search-input .woocommerce #review_form #respond input[type=email],
  .woocommerce #review_form #respond .search-input input[type=email],
  .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .search-input .input-text,
  .search-input .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .search-input input,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input textarea,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input select,
  .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .search-input input,
  .search-input .woocommerce-EditAccountForm.edit-account input[type=text],
  .woocommerce-EditAccountForm.edit-account .search-input input[type=text],
  .search-input .woocommerce-EditAccountForm.edit-account input[type=email],
  .woocommerce-EditAccountForm.edit-account .search-input input[type=email],
  .search-input .woocommerce-EditAccountForm.edit-account input[type=password],
  .woocommerce-EditAccountForm.edit-account .search-input input[type=password],
  .search-input .login input[type=text],
  .login .search-input input[type=text],
  .search-input .login input[type=password],
  .login .search-input input[type=password],
  .search-input .woocommerce-ResetPassword.lost_reset_password input[type=text],
  .woocommerce-ResetPassword.lost_reset_password .search-input input[type=text],
  .search-input .checkout.woocommerce-checkout input[type=text],
  .checkout.woocommerce-checkout .search-input input[type=text],
  .search-input .checkout.woocommerce-checkout input[type=email],
  .checkout.woocommerce-checkout .search-input input[type=email],
  .search-input .checkout.woocommerce-checkout input[type=tel],
  .checkout.woocommerce-checkout .search-input input[type=tel],
  .search-input .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .search-input .select2-choice,
  .search-input .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .search-input textarea,
  .search-input .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .search-input input.input-text,
  .search-input .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .search-input .select2-selection,
  .search-input .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .search-input .select2-choice {
    height: 50px;
  }

  .search-input .btn,
  .search-input .wp-block-button__link,
  .search-input .woocommerce .widget_shopping_cart .buttons a,
  .woocommerce .widget_shopping_cart .buttons .search-input a,
  .search-input .woocommerce.widget_shopping_cart .buttons a,
  .woocommerce.widget_shopping_cart .buttons .search-input a,
  .search-input .woocommerce #review_form #respond .form-submit input,
  .woocommerce #review_form #respond .form-submit .search-input input,
  .search-input .woocommerce-cart-form .table tr .button,
  .woocommerce-cart-form .table tr .search-input .button,
  .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  .woocommerce-cart .wc-proceed-to-checkout .search-input a.checkout-button,
  .search-input .woocommerce #respond input#submit,
  .woocommerce #respond .search-input input#submit,
  .search-input .woocommerce a.button,
  .woocommerce .search-input a.button,
  .search-input .woocommerce button.button,
  .woocommerce .search-input button.button,
  .search-input .woocommerce input.button,
  .woocommerce .search-input input.button,
  .search-input .woocommerce #place_order,
  .woocommerce .search-input #place_order {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}

.dzdrop-menu .nav>li.has-mega-menu {
  position: relative;
}

@media only screen and (min-width: 991px) {
  .dzdrop-menu {
    position: absolute;
    left: 0px;
    top: 100%;
    margin-top: 20px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.05);
    display: flex;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  .dzdrop-menu .navbar-nav {
    display: block;
    width: 230px;
    background-color: #fff;
    padding: 10px 0;
    border-radius: 0 0 0 var(--border-radius-base);
  }

  .dzdrop-menu .navbar-nav>li>a {
    padding: 15px 25px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
  }

  .dzdrop-menu .navbar-nav>li:hover>a {
    background-color: #F8F8F8;
  }

  .dzdrop-menu .navbar-nav>li.sub-menu-down>a:after {
    content: "\f054";
    float: right;
    font-size: 12px;
    margin-top: 2px;
    color: var(--primary);
  }

  .dzdrop-menu .navbar-nav>li .mega-menu,
  .dzdrop-menu .navbar-nav>li .sub-menu {
    left: auto;
    right: -220px;
    top: 0;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu {
    left: 230px;
    right: auto;
    top: 0px;
    min-width: 600px;
    flex-wrap: wrap;
    padding: 15px 0px;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li {
    width: 33.33%;
    float: left;
    padding: 0;
    border: 0;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li:after {
    content: none;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li a {
    display: block;
    font-size: 15px;
    padding: 10px 20px;
    line-height: 1.3;
    text-transform: capitalize;
    position: relative;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    font-weight: 400;
    border: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu {
    min-width: 500px;
  }

  .dzdrop-menu .navbar-nav>li.has-mega-menu .mega-menu li {
    width: 50%;
  }
}

@media only screen and (min-width: 991px) {
  .dzdrop-menu.show {
    opacity: 1;
    visibility: visible;
    margin-top: -15px;
  }
}

@media only screen and (max-width: 1199px) {
  .dzdrop-menu .navbar-nav>li .mega-menu {
    min-width: 450px;
  }

  .dzdrop-menu .navbar-nav>li .mega-menu ul li {
    width: 50%;
  }
}

@media only screen and (max-width: 991px) {
  .dzdrop-menu .navbar-nav>li .mega-menu {
    min-width: 100%;
  }

  .dzdrop-menu .navbar-nav>li .mega-menu ul li {
    width: 100%;
  }
}

.cart-list {
  border: 0;
  right: 0;
  left: auto;
  width: 300px;
  top: 100%;
  transition: none;
  margin-top: 0;
}

.cart-list li {
  padding: 10px 15px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  width: 100%;
}

.cart-list li .media {
  display: flex;
  align-items: center;
}

.cart-list li .media .media-left {
  width: 60px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
}

.cart-list li .media .media-left img {
  width: 100%;
  height: 100%;
}

.cart-list li .media .media-body {
  position: relative;
  padding-left: 10px;
  padding-right: 30px;
  flex: 1;
}

.cart-list li .media .media-body .dz-title {
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
}

.cart-list li .media .media-body .dz-title a {
  color: inherit;
}

.cart-list li .media .media-body .dz-price {
  font-size: 16px;
  color: var(--primary);
}

.cart-list li .media .media-body .item-close {
  background-color: var(--bs-danger);
  color: white;
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 50%;
  width: 24px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  transform: translateY(-50%);
}

.cart-list.dropdown-menu {
  width: 300px !important;
}

.extra-nav .extra-cell .header-right .cart-list.dropdown-menu {
  inset: 100% 0px auto auto !important;
  margin-top: 14px;
  position: absolute;
}

.dz-search-area {
  padding: 50px 0;
}

.dz-search-area.offcanvas {
  overflow: auto;
  height: 580px;
}

.dz-search-area .header-item-search {
  display: block;
  margin-left: 0;
  height: unset;
  padding: 0;
  margin-bottom: 50px;
}

.dz-search-area .header-item-search .form-control,
.dz-search-area .header-item-search .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dz-search-area .header-item-search input[type=text],
.dz-search-area .header-item-search .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dz-search-area .header-item-search textarea,
.dz-search-area .header-item-search .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dz-search-area .header-item-search input[type=email],
.dz-search-area .header-item-search .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dz-search-area .header-item-search .input-text,
.dz-search-area .header-item-search .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dz-search-area .header-item-search input,
.dz-search-area .header-item-search .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .header-item-search textarea,
.dz-search-area .header-item-search .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .header-item-search select,
.dz-search-area .header-item-search .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .header-item-search input,
.dz-search-area .header-item-search .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dz-search-area .header-item-search input[type=text],
.dz-search-area .header-item-search .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dz-search-area .header-item-search input[type=email],
.dz-search-area .header-item-search .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dz-search-area .header-item-search input[type=password],
.dz-search-area .header-item-search .login input[type=text],
.login .dz-search-area .header-item-search input[type=text],
.dz-search-area .header-item-search .login input[type=password],
.login .dz-search-area .header-item-search input[type=password],
.dz-search-area .header-item-search .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dz-search-area .header-item-search input[type=text],
.dz-search-area .header-item-search .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dz-search-area .header-item-search input[type=text],
.dz-search-area .header-item-search .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dz-search-area .header-item-search input[type=email],
.dz-search-area .header-item-search .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dz-search-area .header-item-search input[type=tel],
.dz-search-area .header-item-search .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dz-search-area .header-item-search .select2-choice,
.dz-search-area .header-item-search .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dz-search-area .header-item-search textarea,
.dz-search-area .header-item-search .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dz-search-area .header-item-search input.input-text,
.dz-search-area .header-item-search .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dz-search-area .header-item-search .select2-selection,
.dz-search-area .header-item-search .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dz-search-area .header-item-search .select2-choice {
  background-color: transparent;
  font-family: var(--font-family-title);
}

.dz-search-area .search-input {
  border-bottom: 2px solid var(--secondary);
  padding-bottom: 8px;
}

.dz-search-area .search-input .form-control,
.dz-search-area .search-input .woocommerce #review_form #respond input[type=text],
.woocommerce #review_form #respond .dz-search-area .search-input input[type=text],
.dz-search-area .search-input .woocommerce #review_form #respond textarea,
.woocommerce #review_form #respond .dz-search-area .search-input textarea,
.dz-search-area .search-input .woocommerce #review_form #respond input[type=email],
.woocommerce #review_form #respond .dz-search-area .search-input input[type=email],
.dz-search-area .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
.woocommerce-cart-form .table tr td.actions .coupon .dz-search-area .search-input .input-text,
.dz-search-area .search-input .woocommerce-cart-form .table tr .quantity input,
.woocommerce-cart-form .table tr .quantity .dz-search-area .search-input input,
.dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input textarea,
.dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input select,
.dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input input,
.dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=text],
.woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=text],
.dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=email],
.woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=email],
.dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=password],
.woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=password],
.dz-search-area .search-input .login input[type=text],
.login .dz-search-area .search-input input[type=text],
.dz-search-area .search-input .login input[type=password],
.login .dz-search-area .search-input input[type=password],
.dz-search-area .search-input .woocommerce-ResetPassword.lost_reset_password input[type=text],
.woocommerce-ResetPassword.lost_reset_password .dz-search-area .search-input input[type=text],
.dz-search-area .search-input .checkout.woocommerce-checkout input[type=text],
.checkout.woocommerce-checkout .dz-search-area .search-input input[type=text],
.dz-search-area .search-input .checkout.woocommerce-checkout input[type=email],
.checkout.woocommerce-checkout .dz-search-area .search-input input[type=email],
.dz-search-area .search-input .checkout.woocommerce-checkout input[type=tel],
.checkout.woocommerce-checkout .dz-search-area .search-input input[type=tel],
.dz-search-area .search-input .checkout.woocommerce-checkout .select2-choice,
.checkout.woocommerce-checkout .dz-search-area .search-input .select2-choice,
.dz-search-area .search-input .checkout.woocommerce-checkout textarea,
.checkout.woocommerce-checkout .dz-search-area .search-input textarea,
.dz-search-area .search-input .woocommerce form .form-row input.input-text,
.woocommerce form .form-row .dz-search-area .search-input input.input-text,
.dz-search-area .search-input .woocommerce form .form-row .select2-selection,
.woocommerce form .form-row .dz-search-area .search-input .select2-selection,
.dz-search-area .search-input .woocommerce form .form-row .select2-choice,
.woocommerce form .form-row .dz-search-area .search-input .select2-choice {
  border: 0;
  margin-left: 0px !important;
  border-radius: 0 !important;
  height: 45px;
  font-size: 20px;
  font-family: var(--font-family-title);
}

.dz-search-area .search-input .form-control:focus,
.dz-search-area .search-input .woocommerce #review_form #respond input[type=text]:focus,
.woocommerce #review_form #respond .dz-search-area .search-input input[type=text]:focus,
.dz-search-area .search-input .woocommerce #review_form #respond textarea:focus,
.woocommerce #review_form #respond .dz-search-area .search-input textarea:focus,
.dz-search-area .search-input .woocommerce #review_form #respond input[type=email]:focus,
.woocommerce #review_form #respond .dz-search-area .search-input input[type=email]:focus,
.dz-search-area .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text:focus,
.woocommerce-cart-form .table tr td.actions .coupon .dz-search-area .search-input .input-text:focus,
.dz-search-area .search-input .woocommerce-cart-form .table tr .quantity input:focus,
.woocommerce-cart-form .table tr .quantity .dz-search-area .search-input input:focus,
.dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input select:focus,
.dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input:focus,
.woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input input:focus,
.dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=text]:focus,
.woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=text]:focus,
.dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=email]:focus,
.woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=email]:focus,
.dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=password]:focus,
.woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=password]:focus,
.dz-search-area .search-input .login input[type=text]:focus,
.login .dz-search-area .search-input input[type=text]:focus,
.dz-search-area .search-input .login input[type=password]:focus,
.login .dz-search-area .search-input input[type=password]:focus,
.dz-search-area .search-input .woocommerce-ResetPassword.lost_reset_password input[type=text]:focus,
.woocommerce-ResetPassword.lost_reset_password .dz-search-area .search-input input[type=text]:focus,
.dz-search-area .search-input .checkout.woocommerce-checkout input[type=text]:focus,
.checkout.woocommerce-checkout .dz-search-area .search-input input[type=text]:focus,
.dz-search-area .search-input .checkout.woocommerce-checkout input[type=email]:focus,
.checkout.woocommerce-checkout .dz-search-area .search-input input[type=email]:focus,
.dz-search-area .search-input .checkout.woocommerce-checkout input[type=tel]:focus,
.checkout.woocommerce-checkout .dz-search-area .search-input input[type=tel]:focus,
.dz-search-area .search-input .checkout.woocommerce-checkout .select2-choice:focus,
.checkout.woocommerce-checkout .dz-search-area .search-input .select2-choice:focus,
.dz-search-area .search-input .checkout.woocommerce-checkout textarea:focus,
.checkout.woocommerce-checkout .dz-search-area .search-input textarea:focus,
.dz-search-area .search-input .woocommerce form .form-row input.input-text:focus,
.woocommerce form .form-row .dz-search-area .search-input input.input-text:focus,
.dz-search-area .search-input .woocommerce form .form-row .select2-selection:focus,
.woocommerce form .form-row .dz-search-area .search-input .select2-selection:focus,
.dz-search-area .search-input .woocommerce form .form-row .select2-choice:focus,
.woocommerce form .form-row .dz-search-area .search-input .select2-choice:focus {
  box-shadow: unset;
}

.dz-search-area .search-input .bootstrap-select.default-select:after {
  height: 35px;
}

.dz-search-area .search-input .bootstrap-select.default-select .dropdown-toggle {
  background-color: transparent;
  padding: 0px 25px 0px 0px;
  font-size: 20px;
  min-width: 270px;
  font-family: var(--font-family-title);
}

.dz-search-area .search-input .bootstrap-select.default-select .dropdown-toggle:after {
  content: "\f078";
  width: 28px;
  height: 28px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.dz-search-area .search-input .bootstrap-select.default-select .dropdown-menu {
  min-width: 100% !important;
}

.dz-search-area .search-input .bootstrap-select.default-select .dropdown-menu .dropdown-item.active,
.dz-search-area .search-input .bootstrap-select.default-select .dropdown-menu .dropdown-item:active,
.dz-search-area .search-input .bootstrap-select.default-select .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: var(--secondary);
}

.dz-search-area .search-input .btn,
.dz-search-area .search-input .wp-block-button__link,
.dz-search-area .search-input .woocommerce .widget_shopping_cart .buttons a,
.woocommerce .widget_shopping_cart .buttons .dz-search-area .search-input a,
.dz-search-area .search-input .woocommerce.widget_shopping_cart .buttons a,
.woocommerce.widget_shopping_cart .buttons .dz-search-area .search-input a,
.dz-search-area .search-input .woocommerce #review_form #respond .form-submit input,
.woocommerce #review_form #respond .form-submit .dz-search-area .search-input input,
.dz-search-area .search-input .woocommerce-cart-form .table tr .button,
.woocommerce-cart-form .table tr .dz-search-area .search-input .button,
.dz-search-area .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout .dz-search-area .search-input a.checkout-button,
.dz-search-area .search-input .woocommerce #respond input#submit,
.woocommerce #respond .dz-search-area .search-input input#submit,
.dz-search-area .search-input .woocommerce a.button,
.woocommerce .dz-search-area .search-input a.button,
.dz-search-area .search-input .woocommerce button.button,
.woocommerce .dz-search-area .search-input button.button,
.dz-search-area .search-input .woocommerce input.button,
.woocommerce .dz-search-area .search-input input.button,
.dz-search-area .search-input .woocommerce #place_order,
.woocommerce .dz-search-area .search-input #place_order {
  height: unset;
  width: unset;
}

.dz-search-area .search-input .btn i,
.dz-search-area .search-input .wp-block-button__link i,
.dz-search-area .search-input .woocommerce .widget_shopping_cart .buttons a i,
.woocommerce .widget_shopping_cart .buttons .dz-search-area .search-input a i,
.dz-search-area .search-input .woocommerce.widget_shopping_cart .buttons a i,
.woocommerce.widget_shopping_cart .buttons .dz-search-area .search-input a i,
.dz-search-area .search-input .woocommerce #review_form #respond .form-submit input i,
.woocommerce #review_form #respond .form-submit .dz-search-area .search-input input i,
.dz-search-area .search-input .woocommerce-cart-form .table tr .button i,
.woocommerce-cart-form .table tr .dz-search-area .search-input .button i,
.dz-search-area .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button i,
.woocommerce-cart .wc-proceed-to-checkout .dz-search-area .search-input a.checkout-button i,
.dz-search-area .search-input .woocommerce #respond input#submit i,
.woocommerce #respond .dz-search-area .search-input input#submit i,
.dz-search-area .search-input .woocommerce a.button i,
.woocommerce .dz-search-area .search-input a.button i,
.dz-search-area .search-input .woocommerce button.button i,
.woocommerce .dz-search-area .search-input button.button i,
.dz-search-area .search-input .woocommerce input.button i,
.woocommerce .dz-search-area .search-input input.button i,
.dz-search-area .search-input .woocommerce #place_order i,
.woocommerce .dz-search-area .search-input #place_order i {
  color: var(--title);
}

.dz-search-area .recent-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
  margin: 0 -15px;
}

.dz-search-area .recent-tag li {
  font-size: 15px;
  padding: 5px 15px;
  color: var(--title);
}

.dz-search-area .recent-tag li span {
  font-family: var(--font-family-title);
  color: var(--title);
}

@media only screen and (max-width: 991px) {
  .dz-search-area .search-input {
    padding-bottom: 5px;
    border-bottom-width: 1px;
  }

  .dz-search-area .search-input .bootstrap-select.default-select .dropdown-toggle {
    padding: 0px 15px 0px 0px;
    font-size: 16px;
    min-width: 180px;
  }

  .dz-search-area .search-input .form-control,
  .dz-search-area .search-input .woocommerce #review_form #respond input[type=text],
  .woocommerce #review_form #respond .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .dz-search-area .search-input textarea,
  .dz-search-area .search-input .woocommerce #review_form #respond input[type=email],
  .woocommerce #review_form #respond .dz-search-area .search-input input[type=email],
  .dz-search-area .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .dz-search-area .search-input .input-text,
  .dz-search-area .search-input .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .dz-search-area .search-input input,
  .dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input textarea,
  .dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input select,
  .dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input input,
  .dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=text],
  .woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=email],
  .woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=email],
  .dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=password],
  .woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=password],
  .dz-search-area .search-input .login input[type=text],
  .login .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .login input[type=password],
  .login .dz-search-area .search-input input[type=password],
  .dz-search-area .search-input .woocommerce-ResetPassword.lost_reset_password input[type=text],
  .woocommerce-ResetPassword.lost_reset_password .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .checkout.woocommerce-checkout input[type=text],
  .checkout.woocommerce-checkout .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .checkout.woocommerce-checkout input[type=email],
  .checkout.woocommerce-checkout .dz-search-area .search-input input[type=email],
  .dz-search-area .search-input .checkout.woocommerce-checkout input[type=tel],
  .checkout.woocommerce-checkout .dz-search-area .search-input input[type=tel],
  .dz-search-area .search-input .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .dz-search-area .search-input .select2-choice,
  .dz-search-area .search-input .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .dz-search-area .search-input textarea,
  .dz-search-area .search-input .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .dz-search-area .search-input input.input-text,
  .dz-search-area .search-input .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .dz-search-area .search-input .select2-selection,
  .dz-search-area .search-input .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .dz-search-area .search-input .select2-choice {
    font-size: 16px;
  }

  .dz-search-area .recent-tag {
    margin: 0px -10px;
  }

  .dz-search-area .recent-tag li {
    padding: 5px 10px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-search-area {
    padding: 30px 0 0;
  }

  .dz-search-area.offcanvas {
    height: 470px;
  }

  .dz-search-area .header-item-search {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-search-area.offcanvas {
    height: 440px;
  }

  .dz-search-area .search-input {
    padding-bottom: 0px;
  }

  .dz-search-area .search-input .bootstrap-select.default-select:after {
    height: 25px;
  }

  .dz-search-area .search-input .bootstrap-select.default-select .dropdown-toggle {
    padding: 0 15px 0 0;
    font-size: 14px;
    min-width: 100%;
  }

  .dz-search-area .search-input .bootstrap-select.default-select .dropdown-toggle:after {
    width: 20px;
    height: 20px;
  }

  .dz-search-area .search-input .form-control,
  .dz-search-area .search-input .woocommerce #review_form #respond input[type=text],
  .woocommerce #review_form #respond .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .woocommerce #review_form #respond textarea,
  .woocommerce #review_form #respond .dz-search-area .search-input textarea,
  .dz-search-area .search-input .woocommerce #review_form #respond input[type=email],
  .woocommerce #review_form #respond .dz-search-area .search-input input[type=email],
  .dz-search-area .search-input .woocommerce-cart-form .table tr td.actions .coupon .input-text,
  .woocommerce-cart-form .table tr td.actions .coupon .dz-search-area .search-input .input-text,
  .dz-search-area .search-input .woocommerce-cart-form .table tr .quantity input,
  .woocommerce-cart-form .table tr .quantity .dz-search-area .search-input input,
  .dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row textarea,
  .woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input textarea,
  .dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row select,
  .woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input select,
  .dz-search-area .search-input .woocommerce .woocommerce-checkout.checkout form .form-row input,
  .woocommerce .woocommerce-checkout.checkout form .form-row .dz-search-area .search-input input,
  .dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=text],
  .woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=email],
  .woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=email],
  .dz-search-area .search-input .woocommerce-EditAccountForm.edit-account input[type=password],
  .woocommerce-EditAccountForm.edit-account .dz-search-area .search-input input[type=password],
  .dz-search-area .search-input .login input[type=text],
  .login .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .login input[type=password],
  .login .dz-search-area .search-input input[type=password],
  .dz-search-area .search-input .woocommerce-ResetPassword.lost_reset_password input[type=text],
  .woocommerce-ResetPassword.lost_reset_password .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .checkout.woocommerce-checkout input[type=text],
  .checkout.woocommerce-checkout .dz-search-area .search-input input[type=text],
  .dz-search-area .search-input .checkout.woocommerce-checkout input[type=email],
  .checkout.woocommerce-checkout .dz-search-area .search-input input[type=email],
  .dz-search-area .search-input .checkout.woocommerce-checkout input[type=tel],
  .checkout.woocommerce-checkout .dz-search-area .search-input input[type=tel],
  .dz-search-area .search-input .checkout.woocommerce-checkout .select2-choice,
  .checkout.woocommerce-checkout .dz-search-area .search-input .select2-choice,
  .dz-search-area .search-input .checkout.woocommerce-checkout textarea,
  .checkout.woocommerce-checkout .dz-search-area .search-input textarea,
  .dz-search-area .search-input .woocommerce form .form-row input.input-text,
  .woocommerce form .form-row .dz-search-area .search-input input.input-text,
  .dz-search-area .search-input .woocommerce form .form-row .select2-selection,
  .woocommerce form .form-row .dz-search-area .search-input .select2-selection,
  .dz-search-area .search-input .woocommerce form .form-row .select2-choice,
  .woocommerce form .form-row .dz-search-area .search-input .select2-choice {
    font-size: 14px;
    padding: 10px 15px;
  }

  .dz-search-area .search-input .btn svg,
  .dz-search-area .search-input .wp-block-button__link svg,
  .dz-search-area .search-input .woocommerce .widget_shopping_cart .buttons a svg,
  .woocommerce .widget_shopping_cart .buttons .dz-search-area .search-input a svg,
  .dz-search-area .search-input .woocommerce.widget_shopping_cart .buttons a svg,
  .woocommerce.widget_shopping_cart .buttons .dz-search-area .search-input a svg,
  .dz-search-area .search-input .woocommerce #review_form #respond .form-submit input svg,
  .woocommerce #review_form #respond .form-submit .dz-search-area .search-input input svg,
  .dz-search-area .search-input .woocommerce-cart-form .table tr .button svg,
  .woocommerce-cart-form .table tr .dz-search-area .search-input .button svg,
  .dz-search-area .search-input .woocommerce-cart .wc-proceed-to-checkout a.checkout-button svg,
  .woocommerce-cart .wc-proceed-to-checkout .dz-search-area .search-input a.checkout-button svg,
  .dz-search-area .search-input .woocommerce #respond input#submit svg,
  .woocommerce #respond .dz-search-area .search-input input#submit svg,
  .dz-search-area .search-input .woocommerce a.button svg,
  .woocommerce .dz-search-area .search-input a.button svg,
  .dz-search-area .search-input .woocommerce button.button svg,
  .woocommerce .dz-search-area .search-input button.button svg,
  .dz-search-area .search-input .woocommerce input.button svg,
  .woocommerce .dz-search-area .search-input input.button svg,
  .dz-search-area .search-input .woocommerce #place_order svg,
  .woocommerce .dz-search-area .search-input #place_order svg {
    width: 16px;
    height: 16px;
  }

  .dz-search-area .recent-tag {
    padding: 5px 0;
  }

  .dz-search-area .recent-tag li {
    padding: 2px 10px;
    font-size: 14px;
  }
}

.dz-search-area .shop-card .dz-media {
  border-radius: 20px;
}

.dz-search-area .shop-card .dz-content {
  background-color: var(--light);
}

.dz-search-area .shop-card:hover .dz-media img {
  transform: translateY(0px);
}

.dz-offcanvas.offcanvas .btn-close {
  --bs-btn-close-bg: none;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  position: absolute;
  top: 5px;
  font-weight: 200;
  right: 5px;
  font-size: 40px;
  opacity: 0.5;
}

.dz-offcanvas.offcanvas .btn-close:focus {
  box-shadow: unset;
}

@media only screen and (max-width: 575px) {
  .dz-offcanvas.offcanvas .btn-close {
    font-size: 32px;
    top: 0;
    right: 0;
  }
}

.dz-offcanvas.offcanvas.offcanvas-end,
.dz-offcanvas.offcanvas.offcanvas-top {
  border: 0;
  border: 0;
  background-color: var(--light);
}

.dz-offcanvas.offcanvas.offcanvas-end {
  width: 485px;
}

.dz-offcanvas.offcanvas.offcanvas-end .offcanvas-body {
  padding: 10px 75px;
}

@media only screen and (max-width: 575px) {
  .dz-offcanvas.offcanvas.offcanvas-end .offcanvas-body {
    padding: 10px 15px;
  }
}

.cart-widget {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-widget .dz-media {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 20px;
}

.cart-widget .cart-content {
  margin-right: 20px;
  flex: 1;
}

.cart-widget .cart-content .title,
.cart-widget .cart-content .dz-price {
  font-weight: 500;
}

.cart-widget .dz-close {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justidy-content: center;
}

.sidebar-cart-list li {
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color);
}

.cart-total {
  width: 300px;
  margin-left: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-total h5,
.cart-total .h5 {
  font-weight: 600;
}

.shipping-time {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.shipping-time .dz-icon {
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.shipping-time .dz-icon i {
  font-size: 55px;
}

.shipping-time img {
  margin-right: 20px;
}

.shipping-time .title {
  font-weight: 500;
}

.shipping-time .progress {
  border-radius: 0;
  height: 5px;
}

.shipping-time .progress .progress-bar {
  background-color: var(--secondary);
}

.shop-sidebar-cart {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 190px);
}

.dz-bnr-inr {
  --dz-banner-height: 250px;
  min-height: var(--dz-banner-height);
  background-size: cover;
  background-position: top;
  background-color: var(--light);
  position: relative;
  z-index: 2;
  width: 100%;
  background-position: center;
}

.dz-bnr-inr.dz-banner-dark {
  background: var(--secondary);
  --title: #fff;
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr {
    --dz-banner-height: 230px;
  }
}

.dz-bnr-inr.bg-secondary h1,
.dz-bnr-inr.bg-secondary .h1 {
  color: #fff;
}

.dz-bnr-inr.bg-secondary .breadcrumb-row ul li {
  color: #fff;
}

.dz-bnr-inr.bg-secondary .breadcrumb-row ul li:before {
  color: #fff;
}

.dz-bnr-inr.bg-secondary .breadcrumb-row ul li a {
  color: #ffffff;
}

.dz-bnr-inr .banner-media {
  position: absolute;
  right: 19%;
  bottom: 0;
  left: auto;
  transform: translate(-19%, 0);
}

@media only screen and (max-width: 1680px) {
  .dz-bnr-inr .banner-media {
    right: 10%;
    transform: translate(-10%, 0);
  }
}

@media only screen and (max-width: 1199px) {
  .dz-bnr-inr .banner-media {
    right: 5%;
    transform: translate(-5%, 0);
  }
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr .banner-media {
    right: 2%;
    transform: translate(-2%, 0);
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr .banner-media {
    display: none;
  }
}

.dz-bnr-inr .container {
  display: table;
  height: 100%;
}

.dz-bnr-inr .dz-bnr-inr-entry {
  height: var(--dz-banner-height);
  vertical-align: middle;
  display: table-cell;
  padding: 90px 0 90px 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    text-align: center;
  }

  .dz-bnr-inr .dz-bnr-inr-entry .breadcrumb-row {
    display: inline-block;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    padding: 40px 0 40px 0;
    text-align: center;
    display: table-cell;
  }
}

.dz-bnr-inr h1,
.dz-bnr-inr .h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  line-height: 1.3;
  color: var(--title);
  text-transform: capitalize;
}

@media only screen and (max-width: 991px) {

  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {

  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {

  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 24px;
  }
}

.dz-bnr-inr p {
  color: #fff;
  font-size: 18px;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr p {
    font-size: 14px;
  }
}

.dz-bnr-inr.dz-bnr-inr-lg {
  --dz-banner-height: 600px;
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    --dz-banner-height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    --dz-banner-height: 380px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    --dz-banner-height: 350px;
  }
}

.dz-bnr-inr.dz-bnr-inr-md {
  --dz-banner-height: 450px;
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    --dz-banner-height: 400px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    --dz-banner-height: 300px;
  }
}

.dz-bnr-inr.dz-bnr-inr-sm {
  --dz-banner-height: 300px;
}

@media only screen and (max-width: 767px) {
  .dz-bnr-inr.dz-bnr-inr-sm {
    --dz-banner-height: 200px;
  }
}

.dz-bnr-inr.bnr-no-img:before {
  content: none;
}

.dz-bnr-inr .breadcrumb-row ul {
  margin-bottom: 0;
}

.dz-bnr-inr .kanbern-bnr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.dz-bnr-inr .kanbern-bnr .swiper-slide>div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 20s !important;
  background-position: center;
  background-size: 100%;
}

.dz-bnr-inr .kanbern-bnr .swiper-slide.swiper-slide-active>div {
  background-size: 150%;
}

.breadcrumb-row ul {
  padding: 0;
  display: inline-block;
}

.breadcrumb-row ul li {
  padding: 0;
  margin-right: 3px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--title);
}

.breadcrumb-row ul li.active {
  color: var(--title);
}

.breadcrumb-row ul li a {
  color: var(--title);
}

.breadcrumb-row ul .breadcrumb-item+.breadcrumb-item::before {
  content: "\f105";
  float: left;
  color: var(--title);
  font-weight: 700;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  padding-right: 12px;
  top: 0px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-row ul .breadcrumb-item+.breadcrumb-item::before {
    top: 2px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-row ul li {
    font-size: 14px;
  }
}

.header-transparent+.page-content .dz-bnr-inr .dz-bnr-inr-entry {
  padding: 90px 0 20px 0;
}

.dz-bnr-inr video#video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.dz-bnr-inr.style-1 h3,
.dz-bnr-inr.style-1 .h3 {
  font-weight: 700;
  font-size: 40px;
}

.dz-bnr-inr.style-1 .dz-bnr-inr-entry {
  text-align: center;
}

.dz-bnr-inr.style-2 .dz-bnr-inr-entry {
  text-align: left;
  padding: 60px;
  background: var(--light);
  margin-top: 100px;
  margin-bottom: -100px;
  display: block;
  border-radius: 30px;
}

@media only screen and (max-width: 1199px) {
  .dz-bnr-inr.style-2 .dz-bnr-inr-entry {
    padding: 40px;
  }

  .dz-bnr-inr.style-2 .dz-bnr-inr-entry .breadcrumb-row {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-2 .dz-bnr-inr-entry {
    margin-bottom: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-bnr-inr.style-2 .dz-bnr-inr-entry {
    margin-bottom: -30px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-2 .dz-bnr-inr-entry {
    margin-top: 60px;
    margin-bottom: -60px;
    padding: 20px;
  }
}

.dz-bnr-inr.style-2 .breadcrumb-row {
  margin-bottom: 30px;
}

.page-title {
  padding: 30px 0;
}

.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}

.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.page-title .page-title-content p span {
  font-weight: 500;
  color: var(--primary);
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}

.breadcrumbs li a {
  color: rgba(151, 151, 159, 0.39);
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}

.breadcrumbs li:first-child a {
  margin-left: 0px;
}

.breadcrumbs li.active a {
  color: var(--primary);
}

/* footer {
  background-image: url();
} */
@media only screen and (max-width: 767px) {
  footer .row {
    --bs-gutter-x: 10px;
  }
}

footer .widget_about p {
  margin-bottom: 20px;
}

footer .footer-logo {
  margin-bottom: 24px;
}

footer .footer-logo img {
  max-width: 180px;
}

footer .footer-title {
  margin: 0;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  line-height: 1.2;
  font-size: 20px;
}

footer .footer-top {
  padding: 70px 0px 30px;
}

footer .footer-top .widget {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding: 50px 0 0;
  }
}

footer .footer-bottom span,
footer .footer-bottom p {
  margin-bottom: 0;
  font-weight: 600;
}

footer .footer-bottom .text-start,
footer .footer-bottom .text-end {
  padding: 0;
}

footer .footer-bottom .heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  /* background: url(../images/like.png); */
  cursor: pointer;
  margin: -25px -15px;
}

footer .footer-bottom .heart.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

footer .fb-inner {
  padding: 28px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--title);
  font-weight: 500;
  font-size: 15px;
}

footer .fb-inner .copyright-text a {
  color: inherit;
  color: var(--primary);
}

@media only screen and (max-width: 575px) {
  footer .fb-inner {
    padding: 20px 0;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  footer .footer-bottom p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 991px) {
  footer .footer-top {
    padding: 50px 0px 20px;
  }

  footer .footer-bottom .text-start,
  footer .footer-bottom .text-end {
    text-align: center !important;
  }

  footer .footer-bottom .text-end {
    margin-top: 15px;
  }

  footer .footer-bottom p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  footer .footer-top {
    padding: 45px 0px 0px;
  }
}

footer .widget_services ul li a {
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

footer .widget-address {
  margin-bottom: 24px;
}

footer .widget-address li {
  padding: 4px 0;
  font-size: 15px;
  font-weight: 500;
  color: var(--title);
}

footer .widget-address li p {
  margin-bottom: 0;
  font-weight: 500;
}

footer .widget-address li span {
  color: var(--title);
  font-weight: 500;
}

footer.footer-dark {
  background: var(--secondary);
  --title: #fff;
}

footer.footer-dark .footer-bottom .fb-inner {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}

footer.footer-dark .footer-top {
  color: rgba(255, 255, 255, 0.6);
}

footer.footer-dark .icon-bx-wraper {
  --title: #24262B;
}

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 45px;
  min-height: 35px;
  display: flex;
  align-items: center;
}

.widget_getintuch ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .widget_getintuch ul li {
    margin-bottom: 20px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .widget_getintuch ul li {
    padding-left: 40px;
  }
}

.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
  font-size: 20px;
  line-height: 1.33;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1199px) {

  .widget_getintuch ul li h5,
  .widget_getintuch ul li .h5 {
    font-size: 18px;
    line-height: 1.2;
  }
}

.widget_getintuch ul li i {
  position: absolute;
  font-size: 28px;
  line-height: 1;
  left: 0;
  color: var(--primary);
}

@media only screen and (max-width: 1199px) {
  .widget_getintuch ul li i {
    font-size: 35px;
    top: 6px;
  }
}

@media only screen and (max-width: 575px) {
  .widget_getintuch ul li i {
    font-size: 24px;
  }
}

.contact-ft-1 {
  text-align: center;
  margin-bottom: 40px;
}

.contact-ft-1 i {
  color: var(--primary);
  font-size: 50px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}

.contact-ft-1 h4,
.contact-ft-1 .h4 {
  color: #ffffff;
  text-decoration: underline;
}

.contact-ft-1 h5,
.contact-ft-1 .h5 {
  color: #ffffff;
  font-weight: 400;
}

.list-column ul {
  display: flex;
  flex-wrap: wrap;
}

.list-column ul li {
  flex: 0 0 50%;
  max-width: 50%;
}

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fb-link li {
  display: inline-block;
}

.fb-link li a {
  color: #fff;
  position: relative;
}

.fb-link li a:after {
  content: "";
  background: var(--primary);
  width: 5px;
  height: 5px;
}

.footer-link li {
  display: inline-block;
  font-family: "Lufga", sans-serif;
  position: relative;
  padding: 0 25px 0 20px;
}

.footer-link li a {
  color: inherit;
}

.footer-link li:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: var(--primary);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 8px;
  transform: translateY(-50%);
}

.widget_time ul li {
  display: block;
  margin-bottom: 5px;
}

.widget_time ul li label {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  margin-right: 3px;
}

.footer-category {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f9f9f9;
}

@media only screen and (max-width: 991px) {
  .footer-category {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.footer-category .toggle-btn {
  position: relative;
  font-family: var(--font-family-title);
  color: var(--title);
  font-size: 18px;
  font-weight: 500;
}

.footer-category .toggle-btn:before {
  content: "+";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer-category .toggle-btn.active:before {
  content: "\f068";
}

.footer-category .toggle-items {
  margin-top: 22px;
  display: none;
}

.footer-category .toggle-items:after {
  display: block;
  clear: both;
  content: "";
}

.footer-category .toggle-items ul li {
  width: 25%;
  float: left;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .footer-category .toggle-items ul li {
    padding: 5px 0;
  }
}

.footer-category .toggle-items ul li a {
  color: #363848;
  display: block;
}

.footer-category .toggle-items ul li a:hover {
  color: var(--primary);
}

@media only screen and (max-width: 1199px) {
  .footer-category .toggle-items ul li {
    width: 33%;
  }
}

@media only screen and (max-width: 575px) {
  .footer-category .toggle-items ul li {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-category .toggle-items {
    margin-top: 10px;
  }
}

.footer-map .map-iframe {
  width: 50vw;
  float: right;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .footer-map .map-iframe {
    width: 100%;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-map .map-iframe {
    width: 100%;
    height: 100%;
  }
}

.footer-map .footer-top,
.footer-map .footer-bottom {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {

  .footer-map .footer-top,
  .footer-map .footer-bottom {
    padding-left: 0;
    padding-right: 0;
  }
}

.site-footer.style-1 {
  position: relative;
  z-index: 1;
  border-top: 1px solid #000;
  overflow: hidden;
  background-color: var(--light);
}

.site-footer.style-1 .footer-top {
  padding: 90px 0px 60px;
}

@media only screen and (max-width: 991px) {
  .site-footer.style-1 .footer-top {
    padding: 50px 0px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer.style-1 .footer-top {
    padding: 50px 0 0;
  }
}

.site-footer.style-3 {
  overflow: hidden;
}

.site-footer.style-3 .container.dz-custom-container {
  max-width: 740px;
}

.site-footer.style-3 .dz-social-icon {
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .site-footer.style-3 .dz-social-icon {
    justify-content: start;
    margin-top: 25px;
  }
}

.site-footer.style-3 .dz-post {
  height: 100%;
  position: relative;
  overflow: hidden;
  width: calc(50vw - 200px);
  float: right;
}

@media only screen and (max-width: 1199px) {
  .site-footer.style-3 .dz-post {
    width: calc(50vw - 160px);
  }
}

@media only screen and (max-width: 991px) {
  .site-footer.style-3 .dz-post {
    width: 100vw;
    margin-left: calc((-100vw + 720px) / 2);
    float: none;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer.style-3 .dz-post {
    width: 100vw;
    margin-left: calc((-100vw + 520px) / 2);
  }
}

@media only screen and (max-width: 575px) {
  .site-footer.style-3 .dz-post {
    width: 100vw;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.site-footer.style-3 .dz-post-media {
  height: 100%;
}

.site-footer.style-3 .dz-post-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.site-footer.style-3 .footer-bottom,
.site-footer.style-3 .footer-top {
  padding-left: 130px;
  padding-top: 100px;
}

@media only screen and (max-width: 1199px) {

  .site-footer.style-3 .footer-bottom,
  .site-footer.style-3 .footer-top {
    padding-left: 30px;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 991px) {

  .site-footer.style-3 .footer-bottom,
  .site-footer.style-3 .footer-top {
    padding-left: 0;
    padding-top: 30px;
  }
}

.site-footer.style-3 .logo-topbar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 30px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 575px) {
  .site-footer.style-3 .logo-topbar {
    margin-bottom: 50px;
  }
}

.site-footer.style-3 .instagram-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.site-footer.style-3 .instagram-link .follow-link {
  text-align: center;
  width: 240px;
  height: 240px;
  margin: auto;
  padding: 43px 20px 31px;
  display: flex;
  flex-direction: column;
}

.site-footer.style-3 .instagram-link .follow-link .follow-link-content {
  margin-top: auto;
}

.site-footer.style-3 .instagram-link .follow-link .follow-link-content h4,
.site-footer.style-3 .instagram-link .follow-link .follow-link-content .h4 {
  font-size: 16px;
  font-weight: 700;
  color: #24262B;
}

.site-footer.style-3 .instagram-link .follow-link .follow-link-content p {
  font-size: 15px;
  font-weight: 400;
  color: rgb(111, 94, 94);
  margin-bottom: 0;
}

.site-footer.style-3.right .footer-bottom,
.site-footer.style-3.right .footer-top {
  padding-left: 0;
  padding-right: 130px;
}

@media only screen and (max-width: 1199px) {

  .site-footer.style-3.right .footer-bottom,
  .site-footer.style-3.right .footer-top {
    padding-left: 0;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 991px) {

  .site-footer.style-3.right .footer-bottom,
  .site-footer.style-3.right .footer-top {
    padding-left: 0;
    padding-right: 0;
  }
}

.site-footer.style-3.right .dz-post {
  float: left;
}

.site-footer.style-4 {
  background: #fdfdfd;
}

.site-footer.style-4 .footer-top {
  border-top: 2px solid #f1f0f0;
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.main-bar-wraper.sticky-no .main-bar {
  position: unset;
}

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.boxed .footer-fixed .site-footer {
  left: 50%;
  width: 1200px;
  margin: 0 -600px;
}

.boxed .is-fixed .main-bar {
  transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -webkit-transition: none;
  -o-transition: none;
  position: unset;
}

.boxed .site-header.style-1 .extra-cell>a,
.boxed .site-header.style-1 .extra-cell>div {
  margin-left: 15px;
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-1 .extra-icon-box {
    display: none;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .extra-cell .login-btn i {
    margin: 0;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .extra-cell .login-btn {
    margin-left: 20px;
    width: 45px;
    height: 45px;
    padding: 12px;
  }

  .boxed .site-header.style-2 .extra-cell .login-btn span {
    display: none;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .social-list.style-2 {
    display: none;
  }
}

.boxed .site-header.style-2.center .header-nav .nav.navbar-left {
  margin-right: 120px;
}

.boxed .site-header.style-2.center .header-nav .nav.navbar-right {
  margin-left: 120px;
}

.boxed .site-header.style-2.center .extra-icon-box {
  padding-left: 0;
  width: 50px;
}

.boxed .site-header.style-2.center .extra-icon-box .title,
.boxed .site-header.style-2.center .extra-icon-box span {
  display: none;
}

.boxed .site-header.style-2.center .extra-nav-left,
.boxed .site-header.style-2.center .extra-nav {
  width: 120px;
  padding: 0;
}

.boxed .site-header.style-2.center .extra-cell .login-btn {
  display: none;
}

.boxed .site-header.style-2.center .extra-cell>a,
.boxed .site-header.style-2.center .extra-cell>div {
  margin-left: 15px;
}

@media only screen and (min-width: 1680px) {
  .boxed .header-nav .nav>li>a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content {
    padding: 40px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content .title {
    margin-bottom: 20px;
    font-size: 60px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content .sub-title {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1680px) {

  .boxed .container-fluid,
  .boxed .container-sm,
  .boxed .container-md,
  .boxed .container-lg,
  .boxed .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct {
    padding: 50px;
  }
}

.boxed .under-construct .dz-content .dz-title {
  font-size: 60px;
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dz-content .dz-title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dz-content p {
    font-size: 28px;
  }
}

.boxed .download-area .download-media.right {
  right: 78%;
}

.boxed .newsletter-bx {
  padding: 40px;
}

.boxed .portfolio-area2 .setResizeMargin {
  padding-right: 15px;
  margin-left: 0 !important;
  padding-left: 15px;
}

.boxed .sidenav-menu {
  position: absolute;
}

.boxed .dz-coming-soon .dz-title {
  font-size: 42px;
}

@media only screen and (max-width: 575px) {
  .boxed .dz-coming-soon .dz-title {
    font-size: 28px;
  }
}

.boxed .dz-coming-soon .countdown .date span {
  font-size: 14px;
}

.boxed .dz-coming-soon .countdown .date .time {
  font-size: 60px;
  line-height: 60px;
}

@media only screen and (max-width: 575px) {
  .boxed .dz-coming-soon .countdown .date .time {
    font-size: 35px;
  }
}

.boxed .dz-coming-soon .dz-coming-bx {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 575px) {
  .boxed .dz-coming-soon .dz-coming-bx {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.boxed .swiper-btn-center-lr {
  position: relative;
}

.boxed .swiper-btn-center-lr .btn-next,
.boxed .swiper-btn-center-lr .btn-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.boxed .swiper-btn-center-lr .btn-prev {
  left: 0px;
}

.boxed .swiper-btn-center-lr .btn-next {
  right: 0px;
}

.boxed .swiper-btn-center-lr:hover .btn-prev {
  left: 0px;
}

.boxed .swiper-btn-center-lr:hover .btn-next {
  right: 0px;
}

/* Frame */
.frame {
  padding: 30px;
}

.frame .page-wraper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  height: auto !important;
}

@media only screen and (max-width: 1680px) {
  .frame .site-header.style-1 .extra-icon-box {
    display: none;
  }
}

.frame .is-fixed .main-bar {
  left: 0;
  width: 100%;
  margin: 0;
  position: unset;
}

.frame button.scroltop {
  right: 50px;
  bottom: 50px;
}

.frame .sidenav-menu {
  position: absolute;
}

@media only screen and (max-width: 1680px) {
  .frame .dz-coming-bx {
    padding-left: 30px;
    padding-right: 30px;
  }

  .frame .countdown .date .time {
    font-size: 70px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .frame {
    padding: 0;
  }

  .frame button.scroltop {
    right: 15px;
    bottom: 15px;
  }
}

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-sharped .banner-one .banner-media .shape-1,
.theme-sharped .banner-one .banner-media .shape-2,
.theme-sharped .dz-card .dz-meta li.post-date,
.theme-sharped .progress-bx.style-2 .progress,
.theme-sharped .progress-bx.style-2 .progress .progress-bar {
  border-radius: 0;
}

.theme-rounded {
  --border-radius-base: 6px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.codearea {
  background: #fff;
}

.codezone hr {
  margin: 60px 0;
}

.dz-codebox {
  border: 1px solid #F3F3F3;
  border-radius: 6px;
}

.dz-codebox .container {
  padding-left: 30px;
  padding-right: 30px;
}

.dz-codebox .site-header .main-bar {
  border-radius: 8px;
}

.dz-codebox .dz-code-header {
  padding: 15px 30px;
  border-bottom: 1px solid #F3F3F3;
}

.dz-codebox .dz-code-header h4,
.dz-codebox .dz-code-header .h4 {
  margin-bottom: 0;
}

.dz-codebox .dz-code-header p {
  font-size: 13px;
  margin-bottom: 0;
}

.dz-codebox .dz-element {
  padding: 30px;
  background: #dfdede;
  border-bottom: 1px solid #c9c9c9;
  position: relative;
}

.dz-codebox .dz-code {
  border-top: 1px solid #F3F3F3;
}

.dz-codebox .code-box {
  font-size: 13px;
  height: 200px;
  margin: 0;
  resize: both;
}

.dz-codebox .header-nav .nav>li.has-mega-menu.auto-width .mega-menu>ul>li {
  width: 150px;
}

.buttons {
  border: 1px solid black;
  color: white;
  background-color: #000;
  width: 40px;
  height: 40px;
  border-radius: 30px;

}

/* .buttons_value {} */

.cartMessage {
  /* margin-left: 200px; */
  font-size: 25px;
  color: gray;

  height: 50px;
  align-items: center;
}


.cart_Image {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.table_single_row {

  font-size: 18px;
  font-family: var(--font-family-title);
  padding-left: 15px;
}



.table_row {
  align-items: center;
}

.form-group_coupon {
  display: flex;
  justify-content: space-evenly;
  width: 500px;
}


.table_single_row_Button {

  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 40px;
  border-radius: 20px;
  padding: 12px;
  height: 40px;

}

.table_single_row_Button_value {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 40px;
  border-radius: 20px;
  padding: 12px;
  height: 40px;
  border: 1px solid black;
}

.table_single_row_button_plus {
  display: flex;
  justify-content: space-between;
  width: 150px;
  padding: 3px;

}


.table_single_row_price {
  color: grey !important;
  font-size: 18px;
  font-family: var(--font-family-title);
  padding-left: 15px;

}

.price_details {
  padding-top: 15px;
}

.price_details_cont {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #eaeaec;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.price_details .price_details_cont>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* price filter */
}

::selection {
  color: #fff;
  background: #17a2b8;
}

.wrapper {
  width: 100%;
  margin: 10px 0 0 0;
}

.price-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
}

.price-input .field {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
}

.field input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 19px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.price-input .separator {
  width: 40px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
}

.slider {
  height: 5px;
  position: relative;
  background: #ddd;
  border-radius: 5px;
}

.slider .progress {
  height: 100%;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: #17a2b8;
}

.range-input {
  position: relative;
}

.range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #17a2b8;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

input[type="range"]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #17a2b8;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

/* Support */
.support-box {
  top: 2rem;
  position: relative;
  bottom: 0;
  text-align: center;
  display: block;
}

.b-btn {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.b-btn.paypal i {
  color: blue;
}

.b-btn:hover {
  text-decoration: none;
  font-weight: bold;
}

.b-btn i {
  font-size: 20px;
  color: yellow;
  margin-top: 2rem;
}



.price_details .price_details_cont>div i {
  font-size: 10px;
  padding: 0 2px;
}

.tot_amnt {
  font-weight: 700;
  font-size: 15px;
  color: #3e4152;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.s_p_f_f_c_par {
  position: relative;
}

.s_p_f_f_c_left {
  padding: 0;
}

.s_p_f_f_c_left .filter_head {
  font-weight: 700;
  height: 46px;
  border-bottom: 1px solid lightgray;
  margin: 0;
  padding: 0px 10px;
  line-height: 46px;
}

.s_p_f_f_c_left .filter_cont {
  border-right: 1px solid lightgray;
  width: 100%;
  position: sticky;
  top: 90px;
}

.s_p_f_f_c_left .filter_cont .sing_filter {
  padding: 20px 10px;
  border-bottom: 1px solid lightgray;
}

.s_p_f_f_c_left .filter_cont .sing_filter h6 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 0 18px;
  clear: both;
  color: var(--dark_grey);
  display: block;
}

.s_p_f_f_c_left .filter_cont .sing_filter ul {
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  height: 200px;
  overflow-y: auto;
}

.s_p_f_f_c_left .filter_cont .sing_filter.has_more_btn ul {
  height: 200px;
  overflow-y: auto;
  /* max-height: 170px;
  overflow-y: hidden; */
}

.s_p_f_f_c_left .filter_cont .sing_filter.has_more_btn ul.show {
  max-height: max-content;
  overflow-y: auto;
}

.s_p_f_f_c_left .filter_cont .sing_filter ul li {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 8px;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--dark_grey);
  font-size: 14px;
}

.s_p_f_f_c_left .filter_cont .sing_filter ul li span {
  color: #94969f;
  font-size: 11px;
}

.s_p_f_f_c_left .filter_cont .sing_filter .sing_filter_more_btn {
  color: var(--pink);
  font-size: 16px;
  padding-left: 28px;
}

.s_p_f_f_c_right {
  padding: 0;
  padding-top: 45px;
}

.sort_by {
  border: 1px solid lightgray;
  width: fit-content;
  right: 0;
  min-width: 255px;
  font-size: 14px;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 0;
}

.sort_by .sort_by_box {
  display: flex;
  gap: 10px;
  padding: 6px 10px;
}

.sort_by span:nth-child(2) {
  font-weight: 700;
}

.sort_by span:last-child {
  margin-left: auto;
}

.sort_by .sort_by_cont {
  display: none;
  transition: bottom 0.5s ease-in-out;
  -webkit-transition: bottom 0.5s ease-in-out;
  -moz-transition: bottom 0.5s ease-in-out;
  -ms-transition: bottom 0.5s ease-in-out;
  -o-transition: bottom 0.5s ease-in-out;
  opacity: 0;
  list-style: none;
  padding: 0;
}

.sort_by:hover .sort_by_cont {
  display: block;
  opacity: 1;
  margin: 0;
}

.sort_by:hover .sort_by_cont li {
  padding: 6px 10px;
}

.sort_by:hover .sort_by_cont li:hover {
  background-color: lightgray;
}

.s_p_f_f_c_right .prdct_card_par {
  border-top: 1px solid lightgray;
  padding: 20px 20px 0;
}

.clearFilterButton {
  width: 70px;
  border-radius: 20px;
  background-color: #c2bfbf;
  outline: 0;
  border: 0;
}

.clearFilterButton:hover {
  background-color: #e7e7e7;
}

/* pagination */

.pgntn_par {
  padding: 20px;
  align-items: center;
  border-top: 1px solid lightgray;
  margin: 0;
}

.pgntn_par .page_count {
  color: #535766;
  font-size: 14px;
}

.pgntn_par .page_number {
  display: flex;
  gap: 40px;
  align-items: center;
}

.pgntn_par .page_number ul {
  display: flex;
  list-style: none;
  margin: 0;
  gap: 10px;
  align-items: center;
  padding: 0;
}

.pgntn_par .page_number ul li a {
  display: inline-block;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 2px;
  color: var(--dark_grey);
  text-decoration: none;
}

.pgntn_par .page_number ul li.active a {
  background-color: var(--dark_grey);
  color: #fff;
}

.pgntn_par .page_number>a {
  height: 42px;
  border-radius: 4px;
  border: 1px solid #d4d5d9;
  text-align: center;
  padding: 10px 22px;
  font-weight: 700;
  text-decoration: none;
  color: var(--dark_grey);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pgntn_par .page_number>a:hover {
  border: 1px solid #000000;
}

.pgntn_par .page_number>.prev i {
  padding-right: 6px;
  font-size: 14px;
}

.pgntn_par .page_number>.next i {
  padding-left: 6px;
  font-size: 14px;
}

.navbar_mainMenu {
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}

.navbar_mainMenu h6 {
  margin: 0;
}

.navbar_mainMenu:hover {
  background-color: #FEEB9D;
}

.submenu-item a {
  padding: 5px;
  color: black;
  font-size: 14px;
  display: inline-block;
  /* margin-bottom: 5px; */
}

.submenu-heading {
  color: #ee5f73;
  cursor: pointer;
  margin-top: 10px;
}

.menu_content {
  height: 250px;
  overflow-y: scroll;
}

.submenu-item:hover {
  color: #ee5f73;
  cursor: pointer;
}

.modal-title {
  font-size: 15px;
  font-style: initial;
}

.addAddressButton {
  height: 40px;
  border-radius: 10px;
}


.sing_addr_par .name_home_office>div {
  font-size: 10px;
  padding: 1px 8px;
  border-radius: 20px;
  color: #03a685;
  font-weight: 700;
  border: 1px solid #03a685;
  line-height: 12px;
  position: absolute;
  right: 14px;
  top: 20px
}

.sing_addr_par {
  position: relative;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  border: 1px solid grey;
}

.name_home_office h6 {
  font-weight: 700;
  font-size: 14px;
  color: #282c3f;
  text-transform: capitalize;
}

.name_home_office h6 span {
  color: #94969f;
  font-size: 12px;
  margin-left: 4px;
  font-weight: 300;
}

.sing_addr_par p {
  margin-bottom: 2px;
}

.sing_addr_par .phone {
  margin: 8px 0 16px;
}



.modal_addr_change .add_new_add_window .modal-body .input_par {
  margin-bottom: 16px;
  position: relative;
}

.modal_addr_change .add_new_add_window .modal-body .input_par label {
  display: -webkit-box;
  position: absolute;
  top: 11px;
  left: 24px;
  color: #94969f;
  overflow: hidden;
  font-size: 13px;
}

.modal_addr_change .add_new_add_window .modal-body .input_par input {
  width: 100%;
  font-size: 14px;
  padding: 12px;
  border: 1px solid #d4d5d9;
  background-color: transparent;
  font-weight: 300;
  border-radius: 4px;
  line-height: 14px;
  appearance: none;
  -webkit-appearance: none;
  color: #282c3f;
  outline: none;
}

.modal_addr_change .add_new_add_window .modal-body .input_par input:disabled {
  background-color: #f5f5f6;
  border: 0;
}

.modal_addr_change .add_new_add_window .modal-body .input_par:has(input:focus) label,
.modal_addr_change .add_new_add_window .modal-body .input_par:has(input:valid) label {
  top: -8px;
  left: 20px;
  transition: top 0.1s ease-in-out;
  -webkit-transition: top 0.1s ease-in-out;
  -moz-transition: top 0.1s ease-in-out;
  -ms-transition: top 0.1s ease-in-out;
  -o-transition: top 0.1s ease-in-out;
  padding: 0 4px;
  background-color: #fff;
}

.modal_addr_change .add_new_add_window .modal-body .a_n_a_w_title {
  font-size: 12px;
  font-weight: 700;
  color: #282c3f;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.modal_addr_change .add_new_add_window .modal-body .home_work_addr .home_work_addr_btn {
  display: flex;
  gap: 15px;
  align-items: center;
}

.modal_addr_change .add_new_add_window .modal-body .home_work_addr .home_work_addr_btn a {
  padding: 4px 16px;
  border: 1px solid #282c3f;
  border-radius: 20px;
  font-size: 12px;
  color: #282c3f;
}

.modal_addr_change .add_new_add_window .modal-body .home_work_addr .home_work_addr_btn a.active {
  border: 1px solid #ff3f6c;
  color: #ff3f6c;
  font-weight: 700;
}

.modal-title {
  font-size: 22px;
  font-weight: 700;
}

.home_work_def_cont {
  display: flex;
  align-items: center;
  margin: 20px 0 10px;
}

.add_cat_name {
  font-size: 12px;
  font-weight: 700;
  margin: 30px 0 16px;
  color: #535766;
}

.emptyCart {
  display: flex;
  justify-content: center;
}

.emptyCartButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 40px;
  width: 110px;
  color: white;
  background-color: #ffca55;
  border: none;
  margin-top: 30px;
  margin-left: 44%;
  margin-right: 50%;

}

.section-title {
  font-size: 60px;
  font-weight: 600;
  color: #000000;
  align-items: center;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

.scrollable-container {
  max-height: 500px;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.product-description {
  height: 680px;
  margin-bottom: 0px;
}

.footerbutton {
  border: 1px solid pink;
  margin-bottom: 20px;
}

.Search_Panel {
  height: 46px;
  width: 360px;
  border-radius: 10px;
  border: 2px solid grey;
  padding-left: 10px;
  margin-top: 10px;
  margin-right: 20px;
}

.hover_Footer:hover {
  color: #2856F9;
}

.spinningWheelContainer {
  /* display: flex; */
  width: 400px;
  height: 400px;
  background: transparent;
  position: static;
  right: 300px;
  bottom: 140px;
  margin-left: 300px;

}

.wheel {
  position: absolute;
  left: -200px;
  top: 20px;
  border-radius: 100px;
  /* padding: 20px; */

}

.wheeltext {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Try_button {
  align-items: center;
  background-image: linear-gradient(to right, #8e2de2, #4a00e0);
  border: 0;
  border-radius: 30px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 20px;
  justify-content: center;
  width: 150px;
  padding: 10px;
  cursor: pointer;
}

.Try_button:hover {
  background-image: linear-gradient(144deg, #b95bfc, #4c33f3 50%, );
}

.wheeltext h1 {
  font-size: 50px;
  /* text-shadow: 2px 2px 5px white; */
  color: #ffffe6;
  font-weight: bolder;

}

.successMessageContainer {
  width: 250px;
  height: 200px;
  box-shadow: 2px 2px 5px #f0f1f138;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 200px;
  margin-top: 150px;

}

.successMessageContainer p {
  font-size: 20px;
  text-align: center;
  font-weight: 900;
  color: #c214b9;
}

.crossButton {
  border: none;
  margin-bottom: 65px;
  margin-left: 530px;
  height: 30px;
  width: 60px;
  background-color: transparent !important;
  color: #2c2b2b96;
}

.bhdLno {
  position: relative;
  width: 80vw;
  max-width: 400px !important;
  height: 400 !important;
  max-height: 400px !important;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
  pointer-events: none;
}

.link-hover {
  display: block;
  width: 100%;
  padding: 10px 15px; 
  text-decoration: none; 
  color: inherit; 
}

.link-hover:hover {
  background-color: #f0f0f0; 
  transition: background-color 0.3s; 
}


.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.no-bullets li {
  list-style-type: none;
}
