@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";


/* .swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}


.pagination-align.style-1 .swiper-button-next, .pagination-align.style-1 .swiper-button-prev {
    position: unset;
    top: 0;
    align-items: center;
    width: 60px;
    height: 60px;
    font-size: 28px;
    line-height: 0;
    color: var(--secondary);
    margin-top: 0;
    background-image: none;
    border-radius: 50%;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}
.pagination-align.style-1 .swiper-button-next {
    color: #ffffff !important;
} */


.logo-slider {
    max-width: 80%;
    margin: auto;
    padding: 0;
  }
  
  .item {
    padding: 10px;
  }
  
  .companies-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 50px;
  }
  
  .companies-media img {
    max-width: 100%;
    max-height: 100px;
  }
  .btn-white {
    background-color: #fff !important;
    color: var(--secondary) !important;
}
.text-danger {
  width: 100%;
  padding-top: 10px;
}
a {
  text-decoration: none !important;
}
.shop-card .shop-meta .meta-icon {
  padding-top: 1px;
}
.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
